import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import SalesInvoices from './table';
// import AddNewInvoice from './add-new';
// import CreateCommercialInvoiceStepOne from './add-new/commercial-invoice/create-sale-step-1';
// import CreateCommercialInvoiceStepTwo from './add-new/commercial-invoice/create-sale-step-2';
// import CreateProformaInvoiceStepOne from './add-new/proforma-invoice/create-sale-step-1';
// import CreateProformaInvoiceStepTwo from './add-new/proforma-invoice/create-sale-step-2';
// import ViewCommercialInvoice from './view-invoice/components/commercial';
// import ViewProformaInvoice from './view-invoice/components/proforma';
// import EditCommercialInvoice from './edit-invoice/commercial';
// import EditProformaInvoice from './edit-invoice/proforma';

const SalesInvoicesComponent = (props: any) => (
    <AuthenticatedLayout>
        <SalesInvoices {...props} />
    </AuthenticatedLayout>
);
const SalesInvoices = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const AddNewInvoiceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddNewInvoice {...props} />
    </AuthenticatedLayout>
);
const AddNewInvoice = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const CreateCommercialInvoiceStepOneComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateCommercialInvoiceStepOne {...props} />
    </AuthenticatedLayout>
);
const CreateCommercialInvoiceStepOne = Loadable({
    loader: () => import('./add-new/commercial-invoice/create-sale-step-1' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const CreateCommercialInvoiceStepTwoComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateCommercialInvoiceStepTwo {...props} />
    </AuthenticatedLayout>
);
const CreateCommercialInvoiceStepTwo = Loadable({
    loader: () => import('./add-new/commercial-invoice/create-sale-step-2' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const CreateProformaInvoiceStepOneComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateProformaInvoiceStepOne {...props} />
    </AuthenticatedLayout>
);
const CreateProformaInvoiceStepOne = Loadable({
    loader: () => import('./add-new/proforma-invoice/create-sale-step-1' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const UploadInvoiceOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <UploadInvoiceOrder {...props} />
    </AuthenticatedLayout>
);
const UploadInvoiceOrder = Loadable({
    loader: () => import('./add-new/upload-invoices' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const BulkUploadOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <BulkUploadOrder {...props} />
    </AuthenticatedLayout>
);
const BulkUploadOrder = Loadable({
    loader: () => import('./add-new/upload-invoices/bulk-upload-table' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const ViewCommercialInvoice = Loadable({
    loader: () => import('./view-invoice/components/commercial' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});
const ViewCommercialInvoiceComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewCommercialInvoice {...props} />
    </AuthenticatedLayout>
);
const EditCommercialInvoiceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditCommercialInvoice {...props} />
    </AuthenticatedLayout>
);

const EditCommercialInvoice = Loadable({
    loader: () => import('./edit-invoice/commercial' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});
const EditProformaInvoiceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditProformaInvoice {...props} />
    </AuthenticatedLayout>
);
const EditProformaInvoice = Loadable({
    loader: () => import('./edit-invoice/proforma' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

export {
    SalesInvoicesComponent,
    AddNewInvoiceComponent,
    CreateCommercialInvoiceStepOneComponent,
    CreateCommercialInvoiceStepTwoComponent,
    CreateProformaInvoiceStepOneComponent,
    EditProformaInvoiceComponent,
    SalesInvoices,
    ViewCommercialInvoice,
    AddNewInvoice,
    CreateCommercialInvoiceStepOne,
    CreateCommercialInvoiceStepTwo,
    CreateProformaInvoiceStepOne,
    EditCommercialInvoice,
    EditProformaInvoice,
    ViewCommercialInvoiceComponent,
    EditCommercialInvoiceComponent,
    UploadInvoiceOrderComponent,
    BulkUploadOrderComponent,
};
