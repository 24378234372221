import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    margin-top: 30px;
    .account-settings {
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
        margin-top: 10px;
        .ant-card {
            border-top-left-radius: 5px !important;
            border-top-right-radius: 5px !important;
        }
    }
    .actions {
    .add-button {
        border: none;
        outline: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
        box-shadow: none;
        background: transparent;
        .plus-circle {
            width: 15px;
            height: 15px;
        }
        .add-tier {
            padding-left: 8px;
            font-size: 13px;
            font-weight: 400;
            color: #1e87f0;
        }
    }
    .multiple {
        border: none;
        padding: 15px 10px;
        .ant-select-selection-item {
            background: rgba(57, 66, 106, 0.1);
            height: 35px;
            width: 235px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .ant-select-selector {
            border: none;
            background-color: transparent;
        }
        .ant-select-selection-item {
            width: auto;
            height: 45px;
            border-radius: 3px;
            margin-right: 10px;
        }
        .multi-select-icon {
            position: relative;
            top: 4px;
            color: #000000;
            right: 6px;
        }
        .ant-select-selection-item-content {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            border-right: 1px solid ${(props: any) => props.theme[COLORS.SECONDARY]};
            padding-right: 14px;
            margin-right: 18px;
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
            border-color: transparent;
        }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select-multiple .ant-select-selector {
        border: none;
        outline: none;
        border-right-width: 0;
        box-shadow: none;
    }
`;
export default style;
