import { Button, Modal } from 'antd';
import React, { FunctionComponent } from 'react';
import { Constant } from '../../../config';

interface IsAccountPresentProps {
    showModal: boolean;
    hideModal: (val: boolean) => void;
    onAccount: () => void;
}

const IsAccountPresent: FunctionComponent<IsAccountPresentProps> = ({
    showModal,
    hideModal,
    onAccount,
}: IsAccountPresentProps) => {
    return (
        <Modal
            title="Account Status"
            visible={showModal}
            footer={false}
            getContainer={false}
            width="40%"
            className="notes-wrapper"
            closable={false}
        >
            <div className="heading">Do you have an existing {Constant.existingBank} account?</div>
            <div className="button-wrapper">
                <Button className="action-button" onClick={() => hideModal(false)}>{`No, I don't`}</Button>
                <Button className="notes-button" style={{ marginLeft: 10 }} onClick={onAccount}>
                    Yes, I have
                </Button>
            </div>
        </Modal>
    );
};

export default IsAccountPresent;
