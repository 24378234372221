import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = ({ width, validateStatus }) => {
    let backgroundColor = (props: any) => props.theme[COLORS.DOCUMENT_UPLOADER];
    let color = (props: any) => props.theme[COLORS.PROFILE_ICON];
    let borderColor = (props: any) => props.theme[COLORS.SECOND_BUTTON];
    let cloudBackgroundColor = (props: any) => props.theme[COLORS.CLOUD_BACKGROUND_UPLOADER];
    switch (validateStatus) {
        case 'error':
            color = (props: any) => props.theme[COLORS.PROFILE_ICON_ERROR];
            backgroundColor = (props: any) => props.theme[COLORS.DOCUMENT_UPLOADER_ERROR];
            borderColor = (props: any) => props.theme[COLORS.DECLINED];
            cloudBackgroundColor = (props: any) => props.theme[COLORS.CLOUD_BACKGROUND_UPLOADER_ERROR];
            break;
        default:
            backgroundColor = (props: any) => props.theme[COLORS.DOCUMENT_UPLOADER];
            color = (props: any) => props.theme[COLORS.PROFILE_ICON];
            borderColor = (props: any) => props.theme[COLORS.SECOND_BUTTON];
            cloudBackgroundColor = (props: any) => props.theme[COLORS.CLOUD_BACKGROUND_UPLOADER];
    }

    return css`
        .download-documents {
            position: relative;
            background: ${backgroundColor};
            border: 1px dashed ${borderColor};
            box-sizing: border-box;
            border-radius: 5px;
            padding: 12px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${color};
            width: ${width || '365px'};
            max-width: 100%;
            min-height: 46px;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 85%;
                &.anticon {
                    margin-left: auto;
                    margin-right: 8px;
                    width: unset;
                }
            }
        }

        .download-documents:hover {
            cursor: pointer;
        }

        .upload-heading {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-left: 8px;
            margin-top: 20px;
        }

        .cloud {
            position: absolute;
            right: 0px;
            padding: 12px;
            top: -1px;
            background: ${cloudBackgroundColor};
            height: 46px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-left: auto;
        }
        .name-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 5px;
            span {
                color: ${borderColor};
            }
            .download-icon {
                width: 18px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                margin-left: auto;
                cursor: pointer;
            }
            .vertical-line {
                width: 2px;
                height: 15px;
                background: ${(props: any) => props.theme[COLORS.BORDER]};
                border-radius: 2px;
                margin: 0px 5px;
            }
            .del-icon {
                width: 18px;
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
                margin-left: 5px;
                cursor: pointer;
            }
        }
        .change-div-wrapper {
            display: flex;
            align-items: flex-end;
            gap: 10px;
            margin: 10px 0;
        }
        .err-messsage {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
        }
    `;
};

export default style;
