import React from 'react';
import DocsComponent from './docs';
import AuthenticatedLayout from '../../../components/Layouts/Authenticated';

const KYCSettings = (props: any) => (
    <AuthenticatedLayout>
        <DocsComponent {...props} />
    </AuthenticatedLayout>
);

export { KYCSettings };
