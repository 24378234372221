const prefix = process.env.NODE_ENV === 'development' ? 'business-accounts' : 'business-accounts';
export { prefix as business_account_prefix };
export const get_mobile_providers = `${prefix}/mobile-providers`;
export const get_banks = `${prefix}/banks`;
export const get_business_types = `${prefix}/business/account/data/types`;

export const fetch_business_details = `${prefix}/business/account`;
export const update_business_information = `${prefix}/business/account`;
export const update_contact_information = (id) => `${prefix}/business/contacts/${id}`;
export const create_contact_information = `${prefix}/business/contacts/`;
export const create_wallet = `${prefix}/business/wallets`;
export const fetch_wallets = `${prefix}/business/wallets`;
export const update_wallet = (id) => `${prefix}/business/wallets/${id}`;

//Ecosystem
export const post_accept_connection = `${prefix}/relationship/accept-connection`;
export const post_ignore_connection = `${prefix}/relationship/ignore-connection`;
export const get_connections = `${prefix}/relationship/connections`;
export const get_connection_requests = `${prefix}/relationship/connection-request`;
export const get_invites = `${prefix}/business/invite`;
export const get_single_connection = (id) => `${prefix}/relationship/connection/${id}`;
export const search_connections_by_name_or_id = (name: any) => `${prefix}/relationship/search-all?query=${name}`;
export const post_connection_request = `${prefix}/relationship/connection-request`;
export const post_invite = `${prefix}/business/invite`;

//Buyer supplier
export const create_wallet_child = (id: any) => `${prefix}/connections/wallets/${id}`;
export const update_wallet_child = (id, businessId) => `${prefix}/connections/wallets/${businessId}/${id}`;
export const create_wallet_validated = (id: any) => `${prefix}/validated-business-connections/wallets/${id}`;
export const update_wallet_validated = (id, businessId) =>
    `${prefix}/validated-business-connections/wallets/${businessId}/${id}`;

export const add_business_profile = `${prefix}/business/kyc/business-details`;
export const update_directors = `${prefix}/business/directors`;
export const update_single_director = (id) => `${prefix}/business/directors/${id}`;

export const get_business_directors = `${prefix}/business/directors`;
export const invite_director = `${prefix}/business/documents/invite`;
export const get_all_invites_director = `${prefix}/business/documents/invite`;
export const cancel_invite_director = (id) => `${prefix}/business/documents/cancel-invite/${id}`;
export const resend_invite_director = (id) => `${prefix}/business/documents/resend-invite/${id}`;
export const verify_director_data = `/verification/verify`;
export const delete_director = (id) => `${prefix}/business/documents/director/${id}`;
export const send_invite_director_documents = (inviteId, documentId) =>
    `${prefix}/business/documents/invite/director/${inviteId}/${documentId}`;
export const send_director_invite_data = (inviteId, directorId) =>
    `${prefix}/business/documents/invite/director/${inviteId}/${directorId}`;

export const create_physical_address = `${prefix}/business/address/physical`;
export const update_physical_address = (id) => `${prefix}/business/address/${id}`;

export const get_delivery_address = `${prefix}/business/address/delivery`;
export const search_delivery_address = (value) =>
    value != ''
        ? `${prefix}/business/address/delivery?page=1&limit=5`
        : `${prefix}/business/address/delivery?page=1&limit=5&search=${value}`;
export const create_delivery_address = `${prefix}/business/address/delivery`;

export const upload_documents_business_profile = `${prefix}/business/documents`;
export const unauthenticated_upload_documents_business_profile = (inviteId) =>
    `${prefix}/business/documents/invite/director/${inviteId}`;
export const get_documents_business_profile = `${prefix}/business/documents`;
export const submit_documents_business_profile = `${prefix}/business/kyc/documents`;
export const get_documents_status = `${prefix}/business/documents/status`;

export const submit_terms = `${prefix}/business/kyc/submit-terms-and-condition`;
export const mobile_indemnity = `${prefix}/business/mobile-indemnity`;
export const submit_pick_up_information = `${prefix}/business/documents/document-pickup-information`;

export const submit_existing_customer = `${prefix}/existing-customer`;
export const resend_email_existing_customer = `${prefix}/existing-customer/resend-mandate`;
export const complete_existing_customer = `${prefix}/existing-customer/complete`;
export const verify_mandate_token = `/verification/verify`;
export const approve_mandate = `${prefix}/existing-customer/approve-mandate`;

export const save_business_details = `${prefix}/business/kyc/confirm-details`;

export const search_connections = (value, type) => {
    if (value) {
        return `${prefix}/relationship/connections/search?query=${value}&type=${type}&page=1&limit=5`;
    } else {
        return `${prefix}/relationship/connections/search?type=${type}&page=1&limit=5`;
    }
};
export const delete_business_account = `${prefix}/business/account`;

//Bank APIs
export const list_all_business = `${prefix}/business/kyc/all-business`;
export const all_business_summary = `${prefix}/business/kyc/bank/summary`;
export const get_single_business = (id) => `${prefix}/business/kyc/all-business/${id}`;
export const get_single_business_analytics = (id) => `${prefix}/business/kyc/all-business/analytics/${id}`;
export const get_aml_reports = (id) => `${prefix}/business/kyc/aml-reports/${id}`;

export const add_notes_to_a_column = `${prefix}/business/kyc/notes`;
export const get_notes_of_a_column = (entityId, colName) =>
    `${prefix}/business/kyc/notes/${entityId}?colName=${colName}`;

export const approve_column = (businessId, entityId, colName) =>
    `${prefix}/business/kyc/verification/approve/${businessId}/${entityId}?colName=${colName}`;
export const decline_column = (businessId, entityId, colName) =>
    `${prefix}/business/kyc/verification/decline/${businessId}/${entityId}?colName=${colName}`;

export const return_to_customer = (accountId) => `${prefix}/business/kyc/return-to-customer/${accountId}`;

export const get_business_information = (accountId) => `${prefix}/business/account/${accountId}`;
export const verify_business_information = (accountId) => `${prefix}/business/account/verify/${accountId}`;
export const send_business_for_approval = (accountId: string) =>
    `${prefix}/business/kyc/sent-for-approval/${accountId}`;

export const return_to_verifier = (accountId) => `${prefix}/business/kyc/return-to-verifier/${accountId}`;
export const approve_business_profile = (accountId) => `${prefix}/business/kyc/approve/${accountId}`;

export const complete_verification = (accountId: string) =>
    `${prefix}/business/account/complete-verification/${accountId}`;
export const rerun_verification = `${prefix}/business/kyc/rerun-verification`;

export const get_financials = (id) => `${prefix}/business/financials/${id}`;
export const validate_business = (id, type) =>
    `${prefix}/validated-business-connections/create-bank-validated-business/${id}?type=${type}`;
export const validate_and_link_business = (id, lid, type) =>
    `${prefix}/validated-business-connections/link-business/${id}/${lid}?type=${type}`;
