const prefix = process.env.NODE_ENV === 'development' ? 'authentication' : 'authentication';
const bank_prefix = process.env.NODE_ENV === 'development' ? 'bank-authentication' : 'bank-authentication';

export const sign_in = `${prefix}/sign-in`;
export const bank_sign_in = `${bank_prefix}/sign-in`;
export const log_out = `${prefix}/logout`;
export const bank_log_out = `${bank_prefix}/logout`;
export const get_login_data = `https://geolocation-db.com/json/7a802a00-8c89-11eb-9c50-4fd4fef29257`;
export const put_complete_registration = `${bank_prefix}/user/complete/registration`;
export const bank_change_password = `${bank_prefix}/user/change/password`;
export const forgot_password = `${prefix}/forgot-password`;
export const bank_forgot_password = `${bank_prefix}/forgot/password`;
export const reset_password = `${prefix}/reset-password`;
export const bank_reset_password = `${bank_prefix}/reset-password`;
