const prefix = process.env.NODE_ENV === 'development' ? 'bank-authentication' : 'bank-authentication';

export const search_members = (name: string, groupId = null) => {
    if (name && name.trim() != '') {
        return groupId
            ? `${prefix}/user-group/users?name=${name}&groupId=${groupId}`
            : `${prefix}/user-group/users?name=${name}`;
    } else {
        return groupId ? `${prefix}/user-group/users?groupId=${groupId}` : `${prefix}/user-group/users`;
    }
};

export const get_user_groups = `${prefix}/user-group/user`;

export const create_user_group = `${prefix}/user-group`;
export const fetch_user_groups = `${prefix}/user-group`;
export const fetch_single_user_group = (groupId) => `${prefix}/user-group/single-user-group/${groupId}`;
export const add_member_to_group = () => `${prefix}/user-group/new-member`;
export const delete_single_member_from_group = (groupId, userId) =>
    `${prefix}/user-group/single-user-group/${groupId}?userId=${userId}`;
