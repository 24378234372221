import { Button, Modal } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import alertCircleIcon from '../../assets/icons/Svg/alertCircle.svg';
import getEnvTheme from '../../config/themes/getEnvTheme';

const UserConfirmation = (message, callback) => {
    const container = document.createElement('div');
    container.setAttribute('custom-confirmation-navigation', '');
    document.body.appendChild(container);

    const closeModal = (callbackState) => {
        ReactDOM.unmountComponentAtNode(container);
        document.body.removeChild(container);
        callback(callbackState);
    };
    const obj = JSON.parse(message);

    ReactDOM.render(
        <Modal
            title=""
            visible={true}
            onCancel={() => closeModal(false)}
            footer={false}
            closeIcon={() => null}
            width="40%"
            className="notes-wrapper"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: getEnvTheme()?.PRIMARY_TEXT ?? '#39426A',
                }}
            >
                <img className="icon" src={alertCircleIcon} alt="warning-icon" />
                <p className="title" style={{ marginTop: 10, fontWeight: 'bold' }}>
                    {obj?.heading}
                </p>
                <p
                    className="description"
                    style={{ color: getEnvTheme()?.SECONDARY ?? '#6C7392', textAlign: 'center' }}
                >
                    {obj?.message}
                </p>
                <div className="button-wrapper">
                    <Button
                        className="gapstack-cancel-button"
                        onClick={() => closeModal(true)}
                        style={{ marginLeft: 'auto' }}
                    >
                        {obj?.cancelText}
                    </Button>
                    <Button
                        className="gapstack-button"
                        onClick={() => closeModal(false)}
                        style={{ marginLeft: '10px', padding: '5px 20px', marginRight: 'auto' }}
                    >
                        {obj?.okText}
                    </Button>
                </div>
            </div>
        </Modal>,
        container,
    );
};
export default UserConfirmation;
