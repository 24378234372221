import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';

const ApproveLetterOfCredit = Loadable({
    loader: () => import('./letter-of-credit/approve'),
    loading() {
        return <Loader />;
    },
});

export { ApproveLetterOfCredit };
