import { merge } from 'lodash';
import { ConstantInterface } from './ConstantInterface';
import { getTheme } from '../getTheme';

class _Constant implements ConstantInterface {
    title = 'Gapstack';
    existingBank = 'Gapstack';
    logoHeight = 'auto';
    logoWidth = '180px';
    loaderIconHeight = 'auto';
    loaderIconWidth = '180px';
    customBankUrl = 'gapstack';
    landingPageBackground = undefined;
    buttonColor = undefined;
    secondaryColor = undefined;
    bankColor1 = undefined;
    bankColor2 = undefined;

    constructor() {
        const theme = getTheme('');
        const configOverride = _Constant.themeConfig(theme);
        merge(this, configOverride);
    }

    private static themeConfig(themeName: string) {
        try {
            return require(`./${themeName}.config.json`);
        } catch (e) {
            return {};
        }
    }
}

export const Constant = new _Constant();
