import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Button, notification } from 'antd';
import { appIcon } from '../../../config/appIcon';
import help from '../../../assets/Images/help.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import style from './styles';
import ContentLayout from '../../Layouts/Content';
import { useParams } from 'react-router-dom';
import { unAuthenticatedRequest } from '../../../api';
import { approve_mandate, verify_mandate_token } from '../../../apis/business-accounts';
import { goToRoute, ROUTES } from '../../../router/urls';
import Loader from '../../../utils/Loader';
const VerifyMandateWrapper = styled.div`
    ${style}
`;

const VerifyMandate = () => {
    const { businessId, verifyToken }: any = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const verifyMandate = () => {
        unAuthenticatedRequest()
            .post(verify_mandate_token, { token: verifyToken })
            .then(() => {
                unAuthenticatedRequest({ 'x-business-account-id': businessId })
                    .post(approve_mandate)
                    .then(() => {
                        setLoading(false);
                        history.push({ pathname: goToRoute(ROUTES.COMPLETE_KYC, businessId) });
                    });
            })
            .catch((err) => {
                setLoading(false);
                history.push({ pathname: goToRoute(ROUTES.COMPLETE_KYC, businessId) });
                catchErr(err);
            });
    };

    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };

    useEffect(() => {
        verifyMandate();
    }, []);
    return (
        <ContentLayout>
            <VerifyMandateWrapper>
                <div className="confirm-wrapper">
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>
                    <div className="container">{loading && <Loader size={40} />}</div>
                </div>
            </VerifyMandateWrapper>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </ContentLayout>
    );
};

export default VerifyMandate;
