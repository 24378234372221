import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import style from './styles';
import { Breadcrumb, Card, Col, notification } from 'antd';
import { Link } from 'react-router-dom';
import { goToRoute, ROUTES } from '../../../../router/urls';
import PageHeader from '../../../../utils/PageHeader/index';
import { DocumentCard } from './DocumentCard';
import { authenticatedRequest } from '../../../../api';
import { get_terms_and_condition_docs } from '../../../../apis/business-accounts/kyc_settings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
import { get_business_types } from '../../../../apis/business-accounts';
import LoadingIcon from '../../../../utils/LoadingIcon';

const DocsWrapper = styled.div`
    ${style}
`;

const DocsComponent: FunctionComponent<any> = () => {
    const [data, setData] = useState<any[]>([]);
    const [businessTypes, setBusinessTypes] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const auth: any = useSelector((state: RootState) => state.auth);

    const fetchBusinessTypes = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_business_types)
            .then((res) => {
                setLoading(false);
                setBusinessTypes(
                    (res.data || [])?.map((val) => {
                        return { label: val.name, value: val.id };
                    }),
                );
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchData = () => {
        authenticatedRequest({
            'x-bank-user-id': auth?.user?.userId,
        })
            .get(get_terms_and_condition_docs)
            .then((res) => res.data)
            .then((res) => setData(res))
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchBusinessTypes();
    }, []);

    useEffect(() => {
        if (businessTypes?.length > 0) {
            fetchData();
        }
    }, [businessTypes]);
    return (
        <DocsWrapper>
            <PageHeader>
                <Col span={12}>
                    <Breadcrumb separator="|">
                        <Breadcrumb.Item>
                            <Link to={goToRoute(ROUTES.BANK_SETTINGS)}>Settings</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={goToRoute(ROUTES.BANK_SETTINGS)}>Product Settings</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>KYC Terms & Conditions</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </PageHeader>
            <Card title="Business Types" className="card-wrapper">
                <div className="card-description">
                    Please select and add the types Terms & Conditions required by the following types of businesses
                </div>
                {loading ? (
                    <LoadingIcon size={40} style={{ marginTop: 10 }} />
                ) : (
                    <>
                        {businessTypes.map((val, index) => (
                            <DocumentCard
                                key={index}
                                title={val.label}
                                value={val.value}
                                fetchData={fetchData}
                                data={data?.filter((d) => d?.businessType == val.label?.toString())}
                            />
                        ))}
                    </>
                )}
            </Card>
        </DocsWrapper>
    );
};

export default DocsComponent;
