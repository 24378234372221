import React from 'react';
import Loadable from 'react-loadable';
import AuthenticatedLayout from '../../components/Layouts/Authenticated';
import Loader from '../../utils/Loader';

const FundMyPurchasesBankComponent = (props: any) => (
    <AuthenticatedLayout>
        <FundMyPurchasesBank {...props} />
    </AuthenticatedLayout>
);
const FundFromSalesBankComponent = (props: any) => (
    <AuthenticatedLayout>
        <FundMyPurchasesBank isBuyer={true} {...props} />
    </AuthenticatedLayout>
);
const FundMyPurchasesBank = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Fund-From-Purchases" */),
    loading() {
        return <Loader />;
    },
});

const ValidationRequestsViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <ValidationRequestsView {...props} />
    </AuthenticatedLayout>
);

const ValidationRequestsViewSalesComponent = (props: any) => (
    <AuthenticatedLayout>
        <ValidationRequestsView isBuyer={true} {...props} />
    </AuthenticatedLayout>
);

const ValidationRequestsView = Loadable({
    loader: () => import('./view-supplier-requests' /* webpackChunkName: "Fund-From-Purchases" */),
    loading() {
        return <Loader />;
    },
});

export {
    FundMyPurchasesBankComponent,
    ValidationRequestsViewComponent,
    ValidationRequestsViewSalesComponent,
    FundFromSalesBankComponent,
};
