import { Button, FormInstance, notification } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { authenticatedRequest } from '../../../../api';
import {
    add_business_profile,
    create_contact_information,
    resend_email_existing_customer,
    submit_existing_customer,
    update_business_information,
    update_contact_information,
} from '../../../../apis/business-accounts';
import { KYCStatuses } from '../../helpers';
import { CardName } from '../../types';
import { ContactInformation, BusinessInformation, PhysicalAddress, loaderData } from './export';
import { getUser, Users } from '../../../../config';
import { useParams } from 'react-router';
import { ConditionalRenderMain } from '../business-documents/components/ConditionalRender';
import ExistingAccount from './ExistingAccount';
import { CardLoader } from '../../../../utils/Loader';

interface BusinessInformationProps {
    form: FormInstance;
    fetchAccountDetails: () => void;
    activeData: any;
    completedStatuses: any;
    setCompletedStatuses: (val: any) => void;
    changeStep: (status: KYCStatuses) => void;
    isAccountPresent?: boolean;
    loading: boolean;
}

const BusinessInformationWrapper: FunctionComponent<BusinessInformationProps> = ({
    form,
    fetchAccountDetails,
    activeData,
    completedStatuses,
    setCompletedStatuses,
    changeStep,
    isAccountPresent = false,
    loading,
}: BusinessInformationProps) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchAccountDetails();
    }, []);

    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };

    const saveContact = (values: any) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        let request: any;
        const id = values?.contact?.id;
        if (id) {
            const ob = { ...values['contact'] };
            delete ob['id'];
            request = authenticatedRequest(isBank ? businessHeader : {}).put(update_contact_information(id), ob);
        } else {
            request = authenticatedRequest(isBank ? businessHeader : {}).post(
                create_contact_information,
                values['contact'],
            );
        }
        request
            .then((res) => res.data)
            .catch((err) => {
                catchErr(err);
            });
    };

    const onAccountSubmit = async () => {
        setButtonLoading(true);
        const isValidated = await form.validateFields().catch(() => {
            setButtonLoading(false);
            return false;
        });
        if (!isValidated) {
            return;
        }
        const values = form.getFieldsValue();
        saveContact(values);
        delete values?.contact;
        const submitObj = {
            accountName: values?.accountName,
            accountNumber: values?.accountNumber,
            existingCustomer: !!isAccountPresent,
        };
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_existing_customer, submitObj)
            .then(() => {
                setButtonLoading(false);
                notification.success({
                    message: 'Success',
                    description: `A mandate's approval request has been sent to your registered email addresses. Please approve the request to continue.`,
                });
                fetchAccountDetails();
            })
            .catch((err) => {
                setButtonLoading(false);
                catchErr(err);
            });
    };

    const onComplete = async (next = false, cardName: CardName) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        const values = form.getFieldsValue();
        next && setButtonLoading(true);
        delete values.countryCode;
        let request: any;
        switch (cardName) {
            case CardName.COMPLETE_CARDS:
                try {
                    const isValidated = await form.validateFields();
                    if (isValidated) {
                        request = authenticatedRequest(isBank ? businessHeader : {}).put(add_business_profile, values);
                    }
                } catch (err) {
                    next && setButtonLoading(false);
                    return;
                }
                break;
            case CardName.BUSINESS_INFORMATION:
                request = authenticatedRequest(isBank ? businessHeader : {}).put(
                    update_business_information,
                    values['business'],
                );
                break;
            case CardName.CONTACT_INFORMATION:
                const id = values?.contact?.id;
                if (id) {
                    const ob = { ...values['contact'] };
                    delete ob['id'];
                    request = authenticatedRequest(isBank ? businessHeader : {}).put(
                        update_contact_information(id),
                        ob,
                    );
                } else {
                    request = authenticatedRequest(isBank ? businessHeader : {}).post(
                        create_contact_information,
                        values['contact'],
                    );
                }
                break;
            default:
                break;
        }
        request
            .then((res) => res.data)
            .then((res) => {
                next && setButtonLoading(false);
                next &&
                    setCompletedStatuses({
                        ...completedStatuses,
                        [KYCStatuses.BUSINESS]: 'COMPLETE',
                        [KYCStatuses.DOCUMENTS]: 'ACTIVE',
                    });
                next && changeStep(KYCStatuses.DOCUMENTS);
                //set contact id on auto save for next update
                if (!next && cardName == CardName.CONTACT_INFORMATION) {
                    const id = values?.contact?.id;
                    if (!id) {
                        form.setFieldsValue({ contact: { id: res?.id } });
                    }
                }
            })
            .catch((err) => {
                next && setButtonLoading(false);
                const resp = err.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
        return;
    };

    const onResendEmail = () => {
        setButtonLoading(true);
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(resend_email_existing_customer)
            .then(() => {
                setButtonLoading(false);
                notification.success({
                    message: 'Success',
                    description: `A mandate's approval request has been sent to your registered email addresses. Please approve the request to continue.`,
                });
            })
            .catch((err) => {
                setButtonLoading(false);
                catchErr(err);
            });
        return;
    };

    const isExistingCustomer = activeData?.existingCustomer?.id && !activeData?.existingCustomer?.mandateApproved;

    return loading ? (
        <>
            {loaderData.map((cardData, key) => (
                <CardLoader
                    key={key}
                    rows={cardData?.rows}
                    title={cardData?.title}
                    loading={loading}
                    className={key > 0 ? 'card-margin-top' : undefined}
                />
            ))}
        </>
    ) : (
        <>
            <ConditionalRenderMain shouldRender={!isAccountPresent}>
                <ContactInformation form={form} onComplete={onComplete} />
                <BusinessInformation form={form} onComplete={onComplete} />
                <PhysicalAddress form={form} data={activeData} />
                <div className="button-container">
                    <Button
                        className="gapstack-button"
                        onClick={() => onComplete(true, CardName.COMPLETE_CARDS)}
                        loading={buttonLoading}
                    >
                        Next
                    </Button>
                </div>
            </ConditionalRenderMain>
            <ConditionalRenderMain shouldRender={isAccountPresent}>
                <ExistingAccount form={form} />
                <ContactInformation form={form} onComplete={onComplete} isAccountPresent={isAccountPresent} />
                <div style={{ height: 20 }} />
                {isExistingCustomer && (
                    <div className="info">
                        {`A mandate's approval request has been sent to your registered email addresses. Please approve the request to continue.`}
                    </div>
                )}
                <div className="button-container">
                    <Button
                        className="gapstack-button"
                        onClick={isExistingCustomer ? onResendEmail : onAccountSubmit}
                        loading={buttonLoading}
                    >
                        {isExistingCustomer ? 'Resend Email' : 'Next'}
                    </Button>
                </div>
            </ConditionalRenderMain>
        </>
    );
};

export default BusinessInformationWrapper;
