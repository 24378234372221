import AppIcons from '../assets/app-icons';
import { getTheme } from './getTheme';

function appIconInternal() {
    try {
        let themeName = getTheme('default');

        if (!AppIcons[themeName]) {
            themeName = 'default';
        }

        return AppIcons[themeName];
    } catch (e) {
        return import('../assets/app-icons/gapstack-logo.svg');
    }
}
export const appIcon = appIconInternal();

export default appIcon;
