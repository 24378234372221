import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .eye {
        width: 18px;
        height: 15px;
        cursor: pointer;
        color: ${(props: any) => props.theme[COLORS.ICONS]};
    }
    .content-password {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export default style();
