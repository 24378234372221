import React, { useEffect, useState } from 'react';
import { Col, Form, InputNumber, Row } from 'antd';
import styled from 'styled-components';
import { StrategyCollection } from '../add-new/hooks/useStrategiesActions';
import style from './style';
import { DependentFormFields, FormField } from '../../../../../utils/DataEditModal/EditorForm';
import RichTextEditor from '../../../../../utils/RichTextEditor';
import { get_at_values } from '../../../../../apis/bank-products';
import { authenticatedRequest } from '../../../../../api';

const Wrapper = styled.div`
    ${style}
`;

interface Props {
    actionsData: StrategyCollection;
    error?: boolean;
    formIndex?: number;
    onChangeValidate?: any;
    edit?: boolean;
    initialAction?: string;
    initialStage?: string;
}

const getNestedObject = (nestedObj, pathArr) => {
    const name = Array.isArray(pathArr) ? pathArr : [pathArr];
    return name.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};

const DependentSelectField = ({ field }: any) => {
    return (
        <DependentFormFields
            field={{
                type: 'select',
                required: true,
                className: 'gapstack-input ship-select',
                colProps: {
                    flex: 1,
                },
                ...field,
            }}
            form={{}}
        />
    );
};
// eslint-disable-next-line react/display-name
const StrategyActionForm = React.memo<Props>(
    ({ actionsData, error, formIndex, onChangeValidate, initialStage }: Props) => {
        const [operands, setOperands] = React.useState();
        const [stage, setStage] = useState<string | undefined>(initialStage);
        // const [action, setAction] = useState<string | undefined>(initialAction);
        // const [isSMS, setIsSMS] = useState<boolean>(false);
        const [atValues, setAtValues] = useState<any[]>([]);
        const singleName = typeof formIndex === 'number';
        const stages = getNestedObject(actionsData, 'stages') || [];
        useEffect(() => {
            setOperands(stages?.filter?.((a) => a.name === stage));
        }, [stage, stages]);

        // useEffect(() => {
        //     setIsSMS(actionsData?.actions?.filter?.((a) => a.name === action)?.[0]?.name === 'SMS');
        // }, [action, actionsData]);

        const fetchAtValues = () => {
            authenticatedRequest()
                .get(get_at_values)
                .then((res) => {
                    const values = [] as { id: number; value: any }[];
                    Object.values(res?.data).map((placeholder, index) => {
                        values.push({ id: index, value: placeholder });
                    });
                    setAtValues(values);
                })
                .catch((e) => {
                    console.log('error: ', e);
                });
        };

        useEffect(() => {
            fetchAtValues();
        }, []);

        return (
            <Wrapper>
                <Row gutter={20}>
                    <DependentSelectField
                        field={{
                            label: 'Stage',
                            props: singleName ? [formIndex, 'stage'] : 'stage',
                            message: 'Please select Stage!',
                            values: stages,
                            formItemProps: {
                                preserve: false,
                                style: {
                                    marginBottom: 0,
                                },
                            },
                            onChange: (val) => {
                                setStage(val);
                                onChangeValidate?.();
                            },
                        }}
                        form={{}}
                    />
                    <DependentSelectField
                        field={{
                            label: 'Operand',
                            props: singleName ? [formIndex, 'operand'] : 'operand',
                            formItemProps: {
                                preserve: false,
                                key: stage,
                                style: {
                                    marginBottom: 0,
                                },
                            },
                            message: 'Please select Operand!',
                            values:
                                getNestedObject(operands, [0, 'operands']) ||
                                getNestedObject(actionsData, ['operands']),
                            onChange: onChangeValidate,
                            dependOnField: singleName ? ['collectionStrategyActions', formIndex, 'stage'] : ['stage'],
                            checkValue: (chkVal) => {
                                return !['Disbursed', 'Days Past Due'].includes(
                                    stages?.filter?.((a) => a.name === chkVal)?.[0]?.name,
                                );
                            },
                        }}
                        form={{}}
                    />
                    <DependentFormFields
                        field={{
                            colProps: {
                                style: { marginBottom: 20 },
                                flex: 1,
                            },
                            dependOnField: singleName ? ['collectionStrategyActions', formIndex, 'stage'] : ['stage'],
                            checkValue: (chkVal) => {
                                return stages?.filter?.((a) => a.name === chkVal)?.[0]?.name !== 'Disbursed';
                            },
                            // eslint-disable-next-line react/display-name
                            render: () => (
                                <Form.Item
                                    label="When (Days)"
                                    className="label"
                                    preserve={false}
                                    name={singleName ? [formIndex, 'whenInDays'] : ('whenInDays' as any)}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter When (Days)!',
                                        },
                                    ]}
                                    style={{
                                        marginBottom: 0,
                                    }}
                                >
                                    <InputNumber
                                        min={0}
                                        className="gapstack-input"
                                        style={{ width: '100%' }}
                                        onChange={onChangeValidate}
                                    />
                                </Form.Item>
                            ),
                        }}
                        form={{}}
                    />
                    <DependentSelectField
                        field={{
                            label: 'Action',
                            props: singleName ? [formIndex, 'action'] : 'action',
                            message: 'Please select Action!',
                            values: getNestedObject(actionsData, ['actions']) || [],
                            onChange: () => {
                                // setAction(val);
                                onChangeValidate?.();
                            },
                            formItemProps: {
                                preserve: false,
                                style: {
                                    marginBottom: 0,
                                },
                            },
                        }}
                        form={{}}
                    />
                </Row>
                {error && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">Please change the selected combination!</div>
                    </div>
                )}
                <Row gutter={20}>
                    <Col span={24} style={{ marginTop: 10 }}>
                        <Form.Item
                            label={'Message'}
                            className="label"
                            name={singleName ? [formIndex, 'message'] : ('message' as any)}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please write a message!',
                                },
                            ]}
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <RichTextEditor atValues={atValues} setContent={(val) => console.log(val)} />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ marginTop: 10 }}>
                        <FormField
                            field={{
                                label: 'Description',
                                type: 'textarea',
                                props: singleName ? [formIndex, 'description'] : 'description',
                                formItemProps: {
                                    preserve: false,
                                    style: {
                                        marginBottom: 0,
                                    },
                                },
                                autoSize: undefined,
                                rows: 5,
                            }}
                            form={{}}
                        />
                    </Col>
                </Row>
            </Wrapper>
        );
    },
);
export default StrategyActionForm;
