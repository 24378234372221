import { css } from 'styled-components';
import { COLORS } from '../../config/theme';
import modalContent from '../../assets/styles/modal/modal-content';

const style = () => css`
    ${modalContent}
    .ant-modal-content > .ant-modal-header {
        .ant-modal-title {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
    }
`;

export default style;
