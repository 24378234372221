import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const modalContent = () => css`
    .ant-modal-content {
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    }
    .ant-modal-content > button.ant-modal-close {
        display: none;
    }

    .ant-modal-content > .ant-modal-header {
        padding: 18px 20px 20px;
        border-bottom-color: #e5e5e5;
    }

    .ant-modal-content > .ant-modal-body {
        padding: 5px 20px 20px;
    }

    .ant-modal-content > .ant-modal-footer {
        padding: 20px;
        border-top-color: #e5e5e5;
    }
    .ant-modal-content > .ant-modal-footer > button {
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        height: 45px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ant-modal-content > .ant-modal-footer > .cancel-button {
        background: #fff;
        border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
    }
    .ant-modal-content > .ant-modal-footer > .save-button {
        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
        color: ${(props: any) => props.theme[COLORS.POP_UP]};
    }
    .ant-modal-content > .ant-modal-footer > button + button {
        margin-left: 10px;
    }
`;

export default modalContent;
