import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Edit3, X } from 'react-feather';
import styled from 'styled-components';
import style from './compose.style';
import ComposeMessageForm from './ComposeMessageForm';

const ModalWrapper = styled(Modal)`
    ${style}
`;

const ComposeMessage = () => {
    const [visible, setVisible] = useState(false);
    return (
        <div>
            <Button type="primary" className="primary-button" block onClick={() => setVisible(true)}>
                <Edit3 size="18" /> <span className="icon-btn-text">Compose</span>
            </Button>
            <ModalWrapper
                title="Compose Email"
                transitionName=""
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                getContainer={false}
                style={{ overflowX: 'hidden' }}
                closeIcon={<X className="close-modal" />}
                width="40%"
            >
                <ComposeMessageForm onSuccess={() => setVisible(false)} onCancel={() => setVisible(false)} />
            </ModalWrapper>
        </div>
    );
};

export default ComposeMessage;
