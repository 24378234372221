import { css } from 'styled-components';

const style = () => css`
    .edit-tag {
        user-select: none;
        background: rgba(57, 66, 106, 0.1);
        border-radius: 3px;
        height: 45px;
        width: 250px;
        font-size: 13px;
        color: #39426a;
        border: none;
        display: inline-flex;
        margin-top: 10px;
        .closable-tag {
            position: relative;
            display: flex;
            align-items: center;
            height: 42px;
        }
    }
    .tag-input {
        width: 78px;
        margin-right: 8px;
        vertical-align: top;
    }
    .ant-tag-close-icon {
        margin-left: auto;
        margin-top: 17px;
        font-size: 14px;
        position: relative;
        right: 5px;
        color: #000000;
        top: -3px;
    }
`;
export default style;
