import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../config/theme';
import pin from '../../assets/icons/Svg/pin.svg';
import { AutoComplete, Form, Input, Modal, notification } from 'antd';
import { FormInstance } from 'antd/lib/form';
import gmap from '../../assets/Images/gmap.svg';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { LoadingOutlined } from '@ant-design/icons';

const AntLoadingIcon = () => (
    <div
        style={{
            position: 'absolute',
            top: '20%',
            bottom: '50%',
            right: 'calc(10% + 85px)',
        }}
    >
        <LoadingOutlined
            style={{
                color: '#7C60FF',
            }}
            spin
        />
    </div>
);

const style = css`
    width: 100%;
    .gps-container {
        width: 100%;
        .gps-heading {
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
            font-weight: normal;
        }
    }
    .input-field-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .places {
            width: calc(90% - 70px);
            position: relative;
            input {
                background: #fafafa;
                border: 1px solid #e5e5e5;
                box-sizing: border-box;
                border-radius: 5px;
                height: 45px;
                color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
        .gmap {
            width: 30px;
            height: 30px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
`;

const Wrapper = styled.div`
    ${style}
`;

interface GPSProps {
    form: FormInstance<any>;
    name: string | string[];
    initialValue?: string;
    required?: boolean;
    label?: string;
    disabled?: boolean;
}

const PlacesField: React.FC<GPSProps> = ({
    form,
    name,
    initialValue,
    required = true,
    label,
    disabled = false,
}: GPSProps) => {
    const API_KEY = 'AIzaSyC9gdtZophdhMIgX5-hGQR3rNFPwNFSyMg';
    const zoom = 10;
    const [center, setCenter] = useState<any>();
    const [locationString, setLocationString] = useState<string>();
    const [address, setAddress] = useState<string>('');
    const Marker: React.FC<any> = () => {
        return <img src={pin} alt="pin" className="marker" />;
    };

    const handleClick = (prop: any) => {
        setCenter({ lat: prop.lat, lng: prop.lng });
        const windowObj: any = window;
        const geocoder = new windowObj.google.maps.Geocoder();
        geocoder.geocode({ latLng: { lat: prop.lat, lng: prop.lng } }, function (results, status) {
            if (status === windowObj.google.maps.GeocoderStatus.OK) {
                if (Array.isArray(results) && results[0]) {
                    setAddress(results[0].formatted_address);
                }
            }
        });
    };

    useEffect(() => {
        const value = form?.getFieldValue?.(name);
        value && setLocationString(value);
    }, []);

    const setValue = (value: any) => {
        const formObject = {};
        if (typeof name == 'string') {
            formObject[name] = value;
        } else {
            let schema = formObject;
            const len = name.length;
            for (let i = 0; i < len - 1; i++) {
                const elem = name[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
            }

            schema[name[len - 1]] = value;
        }
        form.setFieldsValue(formObject);
    };

    useEffect(() => {
        if (initialValue !== undefined) {
            setLocationString(initialValue);
            setAddress(initialValue);
            setValue(initialValue);
        }
    }, [initialValue]);

    useEffect(() => {
        if (address) {
            setValue(address);
            setLocationString(address);
        }
    }, [address]);

    const [modal, showModal] = useState<boolean>(false);

    const handleSelect = (optionSelected) => {
        geocodeByAddress(optionSelected)
            .then((results) => getLatLng(results[0]))
            .then((latLng) => setCenter(latLng))
            .catch((error) => {
                notification.error({
                    message: error || 'Unable to find GPS of selected place',
                    type: 'error',
                });
            });
    };

    return (
        <Wrapper>
            <div className="gps-container">
                <Modal
                    visible={modal}
                    okText="Ok"
                    cancelText="Cancel"
                    onOk={() => showModal(false)}
                    onCancel={() => showModal(false)}
                    okButtonProps={{
                        style: {
                            background: '#7C60FF',
                            color: '#FFF',
                            fontSize: '15px',
                            borderRadius: '5px',
                            outline: 'none',
                            border: '1px solid #7C60FF',
                            width: '60px',
                            height: '45px',
                            margin: '5px',
                        },
                    }}
                    cancelButtonProps={{
                        style: {
                            background: '#FFF',
                            color: '#E74C3C',
                            fontSize: '15px',
                            borderRadius: '5px',
                            outline: 'none',
                            border: '1px solid #E74C3C',
                            width: '90px',
                            height: '45px',
                            margin: '5px',
                        },
                    }}
                    centered
                    width={'60vw'}
                    title="GPS (Pick Exact location from the map)"
                >
                    <div style={{ width: '40%', marginBottom: 20 }}>
                        <label style={{ marginBottom: 8 }}>Coordinates (latitude, longitude)</label>
                        <Input className="gapstack-input" value={locationString} />
                    </div>
                    <div className="map">
                        <GoogleMapReact
                            defaultZoom={zoom}
                            defaultCenter={{ lat: -1.289691, lng: 36.822025 }}
                            center={center}
                            zoom={zoom}
                            yesIWantToUseGoogleMapApiInternals
                            options={{ fullscreenControl: false }}
                            bootstrapURLKeys={{ key: API_KEY }}
                            onClick={(props: any) => handleClick(props)}
                        >
                            <Marker lat={center?.lat || -1.289691} lng={center?.lng || 36.822025} />
                        </GoogleMapReact>
                    </div>
                </Modal>
                <Form.Item
                    name={name}
                    className="label"
                    label={label || 'Physical Address'}
                    rules={[{ required: required, message: 'Please select GPS location' }]}
                >
                    <div className="input-field-wrapper">
                        <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                            debounce={1000}
                            searchOptions={{ types: ['establishment'] }}
                        >
                            {({ getInputProps, suggestions, loading }) => (
                                <>
                                    <AutoComplete
                                        className="places"
                                        options={suggestions.map((val) => {
                                            return { key: val?.placeId, value: val?.description };
                                        })}
                                        onSelect={(value) => {
                                            handleSelect(value);
                                            setAddress(value);
                                        }}
                                        value={address}
                                        disabled={disabled}
                                    >
                                        <Input
                                            {...getInputProps()}
                                            placeholder="Search an address"
                                            className="gapstack-input"
                                        />
                                    </AutoComplete>
                                    {loading && <AntLoadingIcon />}
                                </>
                            )}
                        </PlacesAutocomplete>
                        <img src={gmap} className="gmap" onClick={() => !disabled && showModal(true)} />
                    </div>
                </Form.Item>
            </div>
        </Wrapper>
    );
};
export default PlacesField;
