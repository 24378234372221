import React from 'react';

const BondsSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16.2" height="18" viewBox="0 0 16.2 18">
            <path
                id="bonds"
                d="M15.768,2.321,8.268.028A.573.573,0,0,0,8.1,0a.6.6,0,0,0-.165.025h0l-7.5,2.3a.6.6,0,0,0-.314.227A.654.654,0,0,0,0,2.927V9.821c0,6,7.629,8.075,7.956,8.16A.57.57,0,0,0,8.1,18a.5.5,0,0,0,.156-.022c.468-.123,7.944-2.214,7.944-8.157V2.927a.654.654,0,0,0-.12-.379.6.6,0,0,0-.312-.227ZM8.7,12v.632a.649.649,0,0,1-.176.447.579.579,0,0,1-.849,0,.649.649,0,0,1-.176-.447V12H6.9a.585.585,0,0,1-.424-.185.655.655,0,0,1,0-.893.585.585,0,0,1,.424-.185H8.7a.585.585,0,0,0,.424-.185.655.655,0,0,0,0-.893A.585.585,0,0,0,8.7,9.474H7.8a1.752,1.752,0,0,1-1.212-.5,1.934,1.934,0,0,1-.571-1.232,1.966,1.966,0,0,1,.368-1.316A1.787,1.787,0,0,1,7.5,5.713v-.66a.649.649,0,0,1,.176-.447.579.579,0,0,1,.849,0,.649.649,0,0,1,.176.447v.632h.9a.585.585,0,0,1,.424.185.655.655,0,0,1,0,.893.585.585,0,0,1-.424.185H7.8a.585.585,0,0,0-.424.185.655.655,0,0,0,0,.893A.585.585,0,0,0,7.8,8.21h.9a1.756,1.756,0,0,1,1.273.555,1.966,1.966,0,0,1,0,2.68A1.756,1.756,0,0,1,8.7,12Z"
                fill={fill}
            />
        </svg>
    );
};

const BusinessSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16.875"
            height="14.625"
            viewBox="0 0 16.875 14.625"
        >
            <g id="business" transform="translate(-0.563 -1.688)">
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M17.438,6.188a2.252,2.252,0,0,0-2.25-2.25H13.5V3.375a1.689,1.689,0,0,0-1.687-1.687H6.188A1.689,1.689,0,0,0,4.5,3.375v.563H2.813a2.252,2.252,0,0,0-2.25,2.25V7.875H17.438Zm-5.062-2.25H5.625V3.375a.562.562,0,0,1,.563-.562h5.625a.563.563,0,0,1,.563.563Z"
                    fill={fill}
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M11.813,9.281a.844.844,0,0,1-.844.844H7.031a.844.844,0,0,1-.844-.844v-.14A.141.141,0,0,0,6.047,9H.563v5.063a2.25,2.25,0,0,0,2.25,2.25H15.188a2.25,2.25,0,0,0,2.25-2.25V9H11.953a.141.141,0,0,0-.141.141Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

const ConnectedAccountsSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_3" data-name="Rectangle 3" width="18" height="18" fill={fill} />
                </clipPath>
            </defs>
            <g id="connected-accounts" clipPath="url(#clip-path)">
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M14.4,2.566H1.044A1.06,1.06,0,0,0,0,3.634v8.214a1.035,1.035,0,0,0,1.044,1.021h.541V6.17A1.98,1.98,0,0,1,3.576,4.2h11.86V3.634A1.052,1.052,0,0,0,14.4,2.566Z"
                    fill={fill}
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M15.856,12.217a.747.747,0,0,0-1.054.093.49.49,0,0,1-.07.07.467.467,0,0,1-.657-.07.751.751,0,1,0-.573,1.231.73.73,0,0,0,.578-.27.463.463,0,0,1,.359-.168.45.45,0,0,1,.359.163.647.647,0,0,0,.1.1.748.748,0,1,0,.96-1.147Z"
                    fill={fill}
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M16.937,5.129H3.576a1.048,1.048,0,0,0-1.058,1.04v.685H18V6.169a1.049,1.049,0,0,0-1.063-1.04Z"
                    fill={fill}
                />
                <path id="Path_19" data-name="Path 19" d="M18,7.789H2.518V9H18Z" fill={fill} />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M2.518,9.934v4.434a1.066,1.066,0,0,0,1.058,1.068H16.933A1.072,1.072,0,0,0,18,14.367V9.934Zm14.261,3.8a1.687,1.687,0,0,1-2.34.452,1.662,1.662,0,0,1-.942.289,1.686,1.686,0,0,1,0-3.371,1.662,1.662,0,0,1,.942.289,1.686,1.686,0,0,1,2.34,2.34Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

const DashboardSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
                id="Dashboard"
                d="M9,0a9,9,0,1,0,9,9A9,9,0,0,0,9,0ZM8.226,2.942a.774.774,0,0,1,1.548,0v.91a.774.774,0,0,1-1.548,0ZM3.832,9.774h-.91a.774.774,0,1,1,0-1.548h.91a.774.774,0,0,1,0,1.548ZM5.9,5.884A.789.789,0,0,1,4.8,5.9l-.639-.658a.78.78,0,0,1,1.1-1.1l.639.639a.771.771,0,0,1,0,1.1Zm4.084,5.168A2.16,2.16,0,0,1,9,11.264,2.269,2.269,0,0,1,7.877,7.026a2.228,2.228,0,0,1,2.09-.077l2.787-2.787a.78.78,0,1,1,1.1,1.1l-2.8,2.77a2.269,2.269,0,0,1-1.065,3.02Zm5.071-1.277h-.91a.774.774,0,0,1,0-1.548h.91a.774.774,0,0,1,0,1.548Z"
                fill={fill}
            />
        </svg>
    );
};
const DeveloperSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="18.001"
            height="18.001"
            viewBox="0 0 18.001 18.001"
        >
            <path
                id="Developer"
                d="M9,0a9,9,0,1,0,6.364,2.636A9,9,0,0,0,9,0ZM6.269,12.036l-.9.9L1.433,9,5.369,5.064l.9.9L3.233,9Zm1.979,1.982-1.224-.346,2.73-9.687,1.224.344Zm4.385-1.082-.9-.9L14.764,9,11.733,5.964l.9-.9L16.568,9Z"
                transform="translate(0.001)"
                fill={fill}
            />
        </svg>
    );
};
const EcosystemSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="17.863" height="18" viewBox="0 0 17.863 18">
            <path
                id="eco-system"
                d="M6.251,10.851l-1.9,1.94L2.3,10.7a5.993,5.993,0,0,1-.477-7.8L0,1.044,1.023,0,2.849,1.863a5.7,5.7,0,0,1,7.64.486l2.047,2.088-1.68,1.715.867.884,1.791-1.827L15.561,7.3a5.993,5.993,0,0,1,.476,7.8l1.826,1.863L16.84,18l-1.826-1.863a5.7,5.7,0,0,1-7.64-.486L5.326,13.563l1.791-1.827ZM7.274,9.807l.867.884L10.7,8.081,9.832,7.2Zm7.263-1.466L13.514,7.3l-6.14,6.265L8.4,14.607a4.281,4.281,0,0,0,6.141,0,4.494,4.494,0,0,0,0-6.265Z"
                fill={fill}
                fillRule="evenodd"
            />
        </svg>
    );
};
const ExportTradeSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_4" data-name="Rectangle 4" width="18" height="18" fill={fill} />
                </clipPath>
            </defs>
            <g id="-trade" clipPath="url(#clip-path)">
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M13.125,17.438V15.75H9.938A.939.939,0,0,1,9,14.813V12.938A.939.939,0,0,1,9.938,12h3.188V10.313a.563.563,0,0,1,.95-.408l3.75,3.562a.564.564,0,0,1,0,.816l-3.75,3.563a.563.563,0,0,1-.951-.408Z"
                    fill={fill}
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M9,0a9,9,0,1,0,2.637,17.6,2.253,2.253,0,0,1-.012-.354h-.769c-1.481,0-1.531-.088-1.531-.088a2.479,2.479,0,0,1-1.7-1.61,7.24,7.24,0,0,1-.918-1.881c.263-.036.521-.078.791-.1v-.632a2.409,2.409,0,0,1,.173-.884c-.461.033-.911.086-1.353.152a15.561,15.561,0,0,1-.3-2.454H8.25v1.435a2.423,2.423,0,0,1,1.5-.667V9.75H11.7a2.119,2.119,0,0,1,.606-.956,1.937,1.937,0,0,1,1.171-.532,2.058,2.058,0,0,1,1.623.554l.985.934h.362c-.011.1-.021.207-.036.31L17.7,11.276A8.9,8.9,0,0,0,18,9,9.01,9.01,0,0,0,9,0ZM1.538,8.25A7.454,7.454,0,0,1,2.785,4.807a11.967,11.967,0,0,0,2.044.7A17.553,17.553,0,0,0,4.518,8.25ZM3.8,3.607a7.508,7.508,0,0,1,2.246-1.5A9.5,9.5,0,0,0,5.2,4.054a11.044,11.044,0,0,1-1.4-.447ZM8.25,1.789V4.481c-.528-.023-1.044-.071-1.544-.14A5.374,5.374,0,0,1,8.25,1.789Zm1.5,0a5.375,5.375,0,0,1,1.544,2.552c-.5.069-1.016.118-1.544.14Zm2.2.316a7.527,7.527,0,0,1,2.253,1.5,10.858,10.858,0,0,1-1.4.447A9.525,9.525,0,0,0,11.947,2.1ZM6.05,15.884A7.521,7.521,0,0,1,3.8,14.392a10.98,10.98,0,0,1,1.4-.439,9.438,9.438,0,0,0,.842,1.931ZM4.829,12.5a11.98,11.98,0,0,0-2.038.691A7.43,7.43,0,0,1,1.539,9.75H4.518a17.544,17.544,0,0,0,.311,2.75ZM6.017,8.25a15.6,15.6,0,0,1,.3-2.45,18.051,18.051,0,0,0,1.934.18V8.25Zm3.733,0V5.981a18.046,18.046,0,0,0,1.934-.18,15.587,15.587,0,0,1,.3,2.45Zm3.732,0a17.441,17.441,0,0,0-.317-2.75,11.953,11.953,0,0,0,2.051-.7,7.45,7.45,0,0,1,1.247,3.443h-2.98Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const ImportTradeSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18.058" height="18" viewBox="0 0 18.058 18">
            <g id="import-trade" transform="translate(0.058 0)">
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M10.3,16.346,6.926,13.159a.562.562,0,0,1,0-.818L10.3,9.154a.563.563,0,0,1,.949.409V11.25h3.938a1.69,1.69,0,0,0,1.688-1.687.563.563,0,0,1,1.125,0v1.125a3.567,3.567,0,0,1-3.562,3.563H11.25v1.688a.563.563,0,0,1-.95.407Z"
                    fill={fill}
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M9,0a9,9,0,1,0,1.145,17.919,2.217,2.217,0,0,1-.875-.482c-3.549-3.352-3.815-3.423-3.984-4.328a2.12,2.12,0,0,1,.839-2.075c-.05-.412-.082-.844-.1-1.284h1.46A27.839,27.839,0,0,1,9.75,7.727V5.981a17.8,17.8,0,0,0,1.931-.18,15.464,15.464,0,0,1,.283,2.147,2.091,2.091,0,0,1,.786,1.8h.732c.01-.248.018-.5.018-.75a17.988,17.988,0,0,0-.329-3.5,12.046,12.046,0,0,0,2.036-.692,7.473,7.473,0,0,1,1.187,2.983A2.042,2.042,0,0,1,17.438,7.5a2.07,2.07,0,0,1,.434.048A9.006,9.006,0,0,0,9,0ZM1.538,8.25A7.454,7.454,0,0,1,2.785,4.807a11.967,11.967,0,0,0,2.044.7A17.553,17.553,0,0,0,4.518,8.25ZM3.8,3.607a7.514,7.514,0,0,1,2.246-1.5A9.516,9.516,0,0,0,5.2,4.053a10.876,10.876,0,0,1-1.4-.446ZM8.25,1.789V4.481c-.529-.023-1.044-.071-1.544-.139A5.373,5.373,0,0,1,8.25,1.789Zm-2.207,14.1a7.512,7.512,0,0,1-2.243-1.5,11.069,11.069,0,0,1,1.4-.446,9.471,9.471,0,0,0,.843,1.939ZM4.829,12.5a12.042,12.042,0,0,0-2.038.692A7.442,7.442,0,0,1,1.539,9.75H4.518a17.564,17.564,0,0,0,.311,2.75ZM6.017,8.25a15.6,15.6,0,0,1,.3-2.45,18.051,18.051,0,0,0,1.934.18V8.25ZM9.75,4.481v-2.7a5.386,5.386,0,0,1,1.543,2.556,15.031,15.031,0,0,1-1.543.144Zm2.209-2.363A7.5,7.5,0,0,1,14.194,3.61a10.865,10.865,0,0,1-1.395.444A9.484,9.484,0,0,0,11.959,2.118Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const PaymentsSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path
                id="Payments"
                d="M15.364,15.364a9,9,0,1,0-12.728,0A9,9,0,0,0,15.364,15.364ZM6.093,12.286c.076-.266.152-.551.228-.817.095-.323.19-.361.475-.209a4.623,4.623,0,0,0,1.558.475A2.077,2.077,0,0,0,9.38,11.6a.8.8,0,0,0,.19-1.4,2.276,2.276,0,0,0-.589-.342,12.062,12.062,0,0,1-1.6-.722A2.236,2.236,0,0,1,6.112,6.967a2.372,2.372,0,0,1,1.748-2.2c.418-.152.437-.152.437-.589V3.719c0-.342.057-.4.4-.4H9c.722,0,.722,0,.722.722,0,.513,0,.513.513.589a4.334,4.334,0,0,1,1.1.323.339.339,0,0,1,.209.437c-.1.3-.171.627-.266.931-.1.285-.19.323-.456.19a3.509,3.509,0,0,0-1.767-.342,1.323,1.323,0,0,0-.475.1.661.661,0,0,0-.171,1.178,3.036,3.036,0,0,0,.76.437,11.44,11.44,0,0,1,1.387.627,2.5,2.5,0,0,1,.8,3.8,2.712,2.712,0,0,1-1.387.893.388.388,0,0,0-.342.437c.019.247,0,.494,0,.741,0,.228-.114.342-.323.342H8.5a.316.316,0,0,1-.342-.361v-.532c0-.4-.019-.418-.4-.475a4.99,4.99,0,0,1-1.406-.4C6.036,12.743,6,12.648,6.093,12.286Z"
                fill={fill}
            />
        </svg>
    );
};
const ProductsSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="17.117"
            height="15.97"
            viewBox="0 0 17.117 15.97"
        >
            <g id="Products" transform="translate(0.017)">
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M17.1,1.031v2.1a.186.186,0,0,1-.178.187H.182A.186.186,0,0,1,0,3.13V1.56A2,2,0,0,1,.161.472,1,1,0,0,1,1.025,0H16.112A1.02,1.02,0,0,1,17.1,1.018Z"
                    fill={fill}
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M16.282,15V4.253a.187.187,0,0,0-.052-.133.161.161,0,0,0-.127-.056H1a.186.186,0,0,0-.178.187v10.76a1.4,1.4,0,0,0,.03.4.767.767,0,0,0,.755.559H15.5a.764.764,0,0,0,.76-.607A1.6,1.6,0,0,0,16.282,15Zm-4.73-7.731a.1.1,0,0,1-.007.024.87.87,0,0,1-.192.326.787.787,0,0,1-.57.253H6.324a.826.826,0,0,1-.8-.791V6.975a.863.863,0,0,1,.235-.56.831.831,0,0,1,.185-.146l.027-.015a.754.754,0,0,1,.358-.09h4.457a.814.814,0,0,1,.78.626.364.364,0,0,0,.011.058.336.336,0,0,0,.009.06v.021a.691.691,0,0,1,0,.086.843.843,0,0,1-.034.253Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const PurchaseFinancingSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            id="purchase-finance"
            xmlns="http://www.w3.org/2000/svg"
            width="17.1"
            height="17.811"
            viewBox="0 0 17.1 17.811"
        >
            <path
                id="Path_25"
                data-name="Path 25"
                d="M7.092,8.773a5.482,5.482,0,0,1,1.1-6.745A3.308,3.308,0,0,0,5.184,0,3.4,3.4,0,0,0,1.872,3.486,3.485,3.485,0,0,0,3.1,6.177a6.548,6.548,0,0,0-3.1,5c0,2.1.684,2.9,4.356,2.994a8.646,8.646,0,0,1,2.736-5.4Z"
                fill={fill}
            />
            <path
                id="Path_26"
                data-name="Path 26"
                d="M13.716,9.057A3.9,3.9,0,0,0,15.048,6.1,3.722,3.722,0,0,0,11.43,2.273,3.717,3.717,0,0,0,7.812,6.082,3.9,3.9,0,0,0,9.144,9.038,7.14,7.14,0,0,0,5.76,14.533c0,2.463.864,3.278,5.67,3.278S17.1,17,17.1,14.533A7.079,7.079,0,0,0,13.716,9.057Z"
                fill={fill}
            />
        </svg>
    );
};
const PurchasesSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="17.105" height="18" viewBox="0 0 17.105 18">
            <g id="Purchases" transform="translate(0)">
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M7.478,5.151a.816.816,0,0,0-.261,1.3L9.2,8.543a1.309,1.309,0,0,0,1.919,0L13.1,6.456A.8.8,0,0,0,12.563,5.1h-.836V1.725A1.1,1.1,0,0,0,10.658.6h-1a1.1,1.1,0,0,0-1.07,1.122c0,2.4,0-1.543,0,3.375C7.706,5.1,7.654,5.08,7.478,5.151Z"
                    fill={fill}
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M16.6,14.121H3.335a10.383,10.383,0,0,1-.136-1.2c6.662-.036-9.268,0,12.293,0a1.587,1.587,0,0,0,1.576-1.666V4.1a1.23,1.23,0,0,0-1.193-1.258l-3.146-.006v1.21A1.874,1.874,0,0,1,13.806,7.2L11.825,9.288a2.274,2.274,0,0,1-3.336,0L6.508,7.2A1.874,1.874,0,0,1,7.586,4.051V2.831L4.139,2.825,3.805.892A1.047,1.047,0,0,0,2.791,0H.5A.515.515,0,0,0,0,.527a.515.515,0,0,0,.5.527H2.79c.11,0,1.159,7.256,1.685,10.8l-1.282.007a1.043,1.043,0,0,0-1,1.192l.142,1.2a1.028,1.028,0,0,0,1,.929h.889A1.94,1.94,0,0,0,5.867,18a1.94,1.94,0,0,0,1.639-2.824h4.772A1.94,1.94,0,0,0,13.916,18a1.94,1.94,0,0,0,1.639-2.824H16.6a.528.528,0,0,0,0-1.055Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const ReportsSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14.626"
            height="16.875"
            viewBox="0 0 14.626 16.875"
        >
            <g id="Reports" transform="translate(-1.687 -0.563)">
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M3.656,17.438H2.531a.844.844,0,0,1-.844-.844V11.531a.844.844,0,0,1,.844-.844H3.656a.844.844,0,0,1,.844.844v5.063a.844.844,0,0,1-.844.844Z"
                    fill={fill}
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M11.531,17.438H10.406a.844.844,0,0,1-.844-.844V8.156a.844.844,0,0,1,.844-.844h1.125a.844.844,0,0,1,.844.844v8.438a.844.844,0,0,1-.844.844Z"
                    fill={fill}
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M15.469,17.438H14.344a.844.844,0,0,1-.844-.844V4.219a.844.844,0,0,1,.844-.844h1.125a.844.844,0,0,1,.844.844V16.594A.844.844,0,0,1,15.469,17.438Z"
                    fill={fill}
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M7.594,17.438H6.469a.844.844,0,0,1-.844-.844V1.406A.844.844,0,0,1,6.469.563H7.594a.844.844,0,0,1,.844.844V16.594A.844.844,0,0,1,7.594,17.438Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const SalesSVG = ({ fill }: any) => {
    return (
        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="18" height="18" fill={fill} />
                </clipPath>
            </defs>
            <g id="Sales" clipPath="url(#clip-path)">
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M9.428,1.8a.54.54,0,0,0-.856,0L5.91,5.262H7.273L9,3.016l1.728,2.246H12.09Z"
                    fill={fill}
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M17.665,6.954a1.573,1.573,0,0,0-1.249-.61H1.584A1.583,1.583,0,0,0,.049,8.314l1.736,6.9a1.582,1.582,0,0,0,1.536,1.2H14.68a1.582,1.582,0,0,0,1.536-1.2l1.736-6.9A1.573,1.573,0,0,0,17.665,6.954ZM9,13.511a2.135,2.135,0,1,1,2.135-2.135A2.135,2.135,0,0,1,9,13.511Z"
                    fill={fill}
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M9,10.32a1.055,1.055,0,1,0,1.055,1.055A1.055,1.055,0,0,0,9,10.32Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

const SettingsSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="18.01"
            height="18.005"
            viewBox="0 0 18.01 18.005"
        >
            <g id="Settings" transform="translate(0.005)">
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M9,10.878A1.879,1.879,0,1,0,7.072,9,1.9,1.9,0,0,0,9,10.878Z"
                    fill={fill}
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M17.614,10.722l-.019-.015-1.268-.969a.634.634,0,0,1-.187-.232.615.615,0,0,1-.058-.29V8.764a.611.611,0,0,1,.059-.287.628.628,0,0,1,.187-.23L17.6,7.278l.019-.015a1.039,1.039,0,0,0,.37-.624,1.021,1.021,0,0,0-.133-.709L16.135,3.039,16.13,3.03a1.074,1.074,0,0,0-.57-.451,1.106,1.106,0,0,0-.734.009h-.014l-1.491.584a.655.655,0,0,1-.581-.05q-.2-.121-.4-.229a.635.635,0,0,1-.221-.193.614.614,0,0,1-.108-.269L11.784.886V.858A1.06,1.06,0,0,0,11.41.246,1.111,1.111,0,0,0,10.718,0H7.282a1.1,1.1,0,0,0-.7.249,1.047,1.047,0,0,0-.367.627V.9L5.992,2.45a.616.616,0,0,1-.107.269.637.637,0,0,1-.22.194c-.137.072-.272.148-.4.228a.655.655,0,0,1-.58.05L3.192,2.6H3.178a1.107,1.107,0,0,0-.735-.008,1.074,1.074,0,0,0-.57.452v.009L.149,5.944a1.022,1.022,0,0,0-.133.71,1.04,1.04,0,0,0,.37.625l.019.015,1.268.968a.633.633,0,0,1,.187.232.616.616,0,0,1,.058.29v.452a.612.612,0,0,1-.059.287.629.629,0,0,1-.187.23L.4,10.722l-.019.015a1.038,1.038,0,0,0-.37.624,1.021,1.021,0,0,0,.133.709L1.86,14.962v.009a1.073,1.073,0,0,0,.57.451,1.106,1.106,0,0,0,.734-.009h.014l1.489-.584a.655.655,0,0,1,.581.05q.2.122.4.229a.635.635,0,0,1,.221.193.614.614,0,0,1,.108.269L6.2,17.119v.028a1.061,1.061,0,0,0,.375.613,1.112,1.112,0,0,0,.7.245h3.436a1.1,1.1,0,0,0,.7-.249,1.047,1.047,0,0,0,.367-.627V17.1L12,15.548a.616.616,0,0,1,.108-.269.637.637,0,0,1,.221-.193c.137-.072.272-.148.4-.228a.655.655,0,0,1,.58-.05l1.492.585.014.005a1.106,1.106,0,0,0,.735.008,1.073,1.073,0,0,0,.57-.452v-.009l1.716-2.891a1.022,1.022,0,0,0,.134-.71,1.039,1.039,0,0,0-.371-.625Zm-5.4-1.575a3.079,3.079,0,0,1-.6,1.683,3.2,3.2,0,0,1-1.448,1.09,3.3,3.3,0,0,1-1.824.143,3.241,3.241,0,0,1-1.609-.849,3.107,3.107,0,0,1-.872-1.567A3.054,3.054,0,0,1,6,7.87,3.143,3.143,0,0,1,7.121,6.46a3.273,3.273,0,0,1,3.037-.377,3.232,3.232,0,0,1,1.111.706,3.131,3.131,0,0,1,.725,1.082,3.066,3.066,0,0,1,.216,1.275Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};
const WorkingCapitalSVG = ({ fill }: any) => {
    return (
        <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="16.859"
            height="17.991"
            viewBox="0 0 16.859 17.991"
        >
            <g id="w-cap" transform="translate(0 0.001)">
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M15.981,12.082l-3.137.656-.111-.591-.212-1.124-1.628.341.212,1.123.111.591-.909.191a3.633,3.633,0,0,0-.3-.552A3.467,3.467,0,0,0,7.9,11.233a3.279,3.279,0,0,0-.6-.08L6.174,5.18A.3.3,0,0,1,6.4,4.827l3.137-.656.111.591.213,1.123,1.628-.341L11.275,4.42l-.111-.591L14.3,3.174a.287.287,0,0,1,.337.237l1.57,8.316a.3.3,0,0,1-.226.355Z"
                    fill={fill}
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M16.38,12.43l-5.927,1.239a3.785,3.785,0,0,1,.1.409,3.83,3.83,0,0,1,.054.419l5.927-1.239a.422.422,0,0,0,.315-.5.4.4,0,0,0-.469-.328Z"
                    fill={fill}
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M2.994.288A.391.391,0,0,0,2.252.249.447.447,0,0,1,1.928.5L.333.838A.423.423,0,0,0,0,1.248a.408.408,0,0,0,.479.42l1.605-.336a.428.428,0,0,1,.392.106.384.384,0,0,0,.352.107.417.417,0,0,0,.321-.429,1.093,1.093,0,0,1,1.158.913l1.757,9.314a3.25,3.25,0,0,1,.784-.176l-1.755-9.3A1.9,1.9,0,0,0,2.994.288Z"
                    fill={fill}
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M6.573,11.636a3.21,3.21,0,0,0-2.4,3.777,3.062,3.062,0,0,0,3.588,2.525,3.21,3.21,0,0,0,2.4-3.777,3.062,3.062,0,0,0-3.588-2.525Zm.821,4.349a1.166,1.166,0,0,1-1.364-.96,1.222,1.222,0,0,1,.912-1.436,1.166,1.166,0,0,1,1.364.96,1.222,1.222,0,0,1-.912,1.437Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export default {
    Dashboard: DashboardSVG,
    Business: BusinessSVG,
    Ecosystem: EcosystemSVG,
    PurchaseFinancing: PurchaseFinancingSVG,
    SalesFinancing: WorkingCapitalSVG,
    Bonds: BondsSVG,
    ImportTrade: ImportTradeSVG,
    ExportTrade: ExportTradeSVG,
    Purchases: PurchasesSVG,
    Sales: SalesSVG,
    Product: ProductsSVG,
    Payment: PaymentsSVG,
    Reports: ReportsSVG,
    Developer: DeveloperSVG,
    Settings: SettingsSVG,
    ConnectedAccounts: ConnectedAccountsSVG,
};
