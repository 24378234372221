import moment from 'moment';

export const mapInitialData = (businessData: any) => {
    let formObj = {};
    const contact =
        (businessData?.contacts &&
            Array.isArray(businessData?.contacts) &&
            businessData?.contacts.length > 0 &&
            businessData?.contacts[0]) ||
        {};
    const wallet =
        (businessData?.bankWallets &&
            Array.isArray(businessData?.bankWallets) &&
            businessData?.bankWallets.length > 0 &&
            businessData?.bankWallets[0]) ||
        {};
    formObj['contact'] = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        countryCode: contact?.countryCode,
        phone: contact?.phone,
        id: contact?.id,
        address: contact?.address || undefined,
        postalCode: contact?.postalCode || undefined,
        country: contact?.country ?? (process.env.DEFAULT_COUNTRY || 'Kenya'),
        county: contact?.county || undefined,
    };
    formObj['business'] = {
        businessName: businessData?.businessName,
        tradingName: businessData?.businessName,
        businessTypeId: businessData?.businessTypeId || undefined,
        industryId: businessData?.industryId || undefined,
        pinNumber: businessData?.pinNumber || undefined,
        vatNumber: businessData?.vatNumber || undefined,
    };
    formObj['wallet'] = {
        type: wallet?.type,
        id: wallet?.id || undefined,
        bankId: wallet?.bankId || undefined,
        bankAccountNumber: wallet?.bankAccountNumber || undefined,
        mobileProviderId: wallet?.mobileProviderId || undefined,
        walletAccountType: wallet?.walletAccountType || undefined,
        phone: wallet?.phone || undefined,
        mpesaTillNumber: wallet?.mpesaTillNumber || undefined,
        mpesaBusinessNo: wallet?.mpesaBusinessNo || undefined,
        mpesaAccountNo: wallet?.mpesaAccountNo || undefined,
    };
    formObj = {
        ...formObj,
        accountName: businessData?.existingCustomer?.accountName || undefined,
        accountNumber: businessData?.existingCustomer?.accountNumber || undefined,
    };
    return formObj;
};

export const mapConfirmData = (businessData: any) => {
    const formObj = {};
    const contact =
        (businessData?.contacts &&
            Array.isArray(businessData?.contacts) &&
            businessData?.contacts.length > 0 &&
            businessData?.contacts[0]) ||
        {};
    const physicalAddress =
        (businessData?.address &&
            Array.isArray(businessData?.address) &&
            businessData?.address.length > 0 &&
            businessData?.address[0]) ||
        {};
    const wallet =
        (businessData?.bankWallets &&
            Array.isArray(businessData?.bankWallets) &&
            businessData?.bankWallets.length > 0 &&
            businessData?.bankWallets[0]) ||
        {};
    formObj['contact'] = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        countryCode: contact?.countryCode || process.env.DEFAULT_COUNTRY_CODE || '+254',
        phone: contact?.phone,
        id: contact?.id,
        address: contact?.address || undefined,
        postalCode: contact?.postalCode || undefined,
        country: contact?.country ?? (process.env.DEFAULT_COUNTRY || 'Kenya'),
        county: contact?.county || undefined,
    };
    formObj['physicalAddress'] = {
        id: physicalAddress?.id,
        country: physicalAddress?.country ?? (process.env.DEFAULT_COUNTRY || 'Kenya'),
        county: physicalAddress?.county || undefined,
        gps: physicalAddress?.gps || undefined,
    };
    formObj['business'] = {
        businessName: businessData?.businessName,
        tradingName: businessData?.tradingName || businessData?.businessName,
        businessTypeId: businessData?.businessTypeId || undefined,
        industryId: businessData?.industryId || undefined,
        pinNumber: businessData?.pinNumber || undefined,
        vatNumber: businessData?.vatNumber || undefined,
    };
    formObj['companyRegistration'] = {
        incorporationNumber: businessData?.incorporationNumber || undefined,
        pinNumber: businessData?.pinNumber || undefined,
        vatNumber: businessData?.vatNumber || undefined,
        registrationDate: businessData?.registrationDate
            ? moment(businessData?.registrationDate, 'YYYY/MM/DD')
            : undefined,
    };
    formObj['wallet'] = {
        type: wallet?.type,
        id: wallet?.id || undefined,
        bankId: wallet?.bankId || undefined,
        bankAccountNumber: wallet?.bankAccountNumber || undefined,
        mobileProviderId: wallet?.mobileProviderId || undefined,
        walletAccountType: wallet?.walletAccountType || undefined,
        phone: wallet?.phone || undefined,
        mpesaTillNumber: wallet?.mpesaTillNumber || undefined,
        mpesaBusinessNo: wallet?.mpesaBusinessNo || undefined,
        mpesaAccountNo: wallet?.mpesaAccountNo || undefined,
    };
    formObj['submitbutton'] = {
        countryCode: contact?.countryCode,
        phone: contact?.phone,
    };
    return formObj;
};

export const mapConfirmDataErrors = (businessData: any) => {
    const formObj = {};
    const contact =
        (businessData?.contacts &&
            Array.isArray(businessData?.contacts) &&
            businessData?.contacts.length > 0 &&
            businessData?.contacts[0]) ||
        {};
    const physicalAddress =
        (businessData?.address &&
            Array.isArray(businessData?.address) &&
            businessData?.address.length > 0 &&
            businessData?.address[0]) ||
        {};
    const wallet =
        (businessData?.bankWallets &&
            Array.isArray(businessData?.bankWallets) &&
            businessData?.bankWallets.length > 0 &&
            businessData?.bankWallets[0]) ||
        {};
    formObj['contact'] = {
        firstName: contact?.verificationData?.firstName?.returnForChangeReason,
        lastName: contact?.verificationData?.lastName?.returnForChangeReason,
        email: contact?.verificationData?.email?.returnForChangeReason,
        countryCode: contact?.verificationData?.countryCode?.returnForChangeReason,
        phone: contact?.verificationData?.phone?.returnForChangeReason,
        address: contact?.verificationData?.address?.returnForChangeReason,
        postalCode: contact?.verificationData?.postalCode?.returnForChangeReason,
        country: contact?.verificationData?.country?.returnForChangeReason,
        county: contact?.verificationData?.county?.returnForChangeReason,
    };
    formObj['physicalAddress'] = {
        id: physicalAddress?.id,
        country: physicalAddress?.verificationData?.country?.returnForChangeReason || undefined,
        county: physicalAddress?.verificationData?.county?.returnForChangeReason || undefined,
        gps: physicalAddress?.verificationData?.gps?.returnForChangeReason || undefined,
    };
    formObj['business'] = {
        businessName: businessData?.verificationData?.businessName?.returnForChangeReason,
        tradingName: businessData?.verificationData?.tradingName?.returnForChangeReason,
        pinNumber: businessData?.verificationData?.pinNumber?.returnForChangeReason || undefined,
        vatNumber: businessData?.verificationData?.vatNumber?.returnForChangeReason || undefined,
    };
    formObj['companyRegistration'] = {
        incorporationNumber: businessData?.verificationData?.incorporationNumber?.returnForChangeReason || undefined,
        pinNumber: businessData?.verificationData?.pinNumber?.returnForChangeReason || undefined,
        vatNumber: businessData?.verificationData?.vatNumber?.returnForChangeReason || undefined,
        registrationDate: businessData?.verificationData?.registrationDate?.returnForChangeReason,
    };
    formObj['wallet'] = {
        bankAccountNumber: wallet?.verificationData?.bankAccountNumber?.returnForChangeReason || undefined,
        phone: wallet?.verificationData?.phone?.returnForChangeReason || undefined,
        mpesaTillNumber: wallet?.verificationData?.mpesaTillNumber?.returnForChangeReason || undefined,
        mpesaBusinessNo: wallet?.verificationData?.mpesaBusinessNo?.returnForChangeReason || undefined,
        mpesaAccountNo: wallet?.verificationData?.mpesaAccountNo?.returnForChangeReason || undefined,
    };
    return formObj;
};

interface ErrorType {
    name: string | (string | number)[];
    errors: any[];
}

export const getErrorsList = (obj) => {
    const tempArr: ErrorType[] = [];
    Object.keys(obj).map((val) => {
        if (obj[val]) {
            tempArr.push({ errors: [obj[val]], name: val });
        }
    });
    return tempArr;
};
export const getErrorsListDirectors = (directors: any[]) => {
    const tempArr: ErrorType[] = [];
    directors.map((director, index) => {
        const obj = director?.verificationData || {};
        Object.keys(obj).map((val) => {
            if (obj[val]?.returnForChangeReason) {
                tempArr.push({ errors: [obj[val]?.returnForChangeReason], name: ['directors', index, val] });
            }
        });
    });

    return tempArr;
};

export const getDirectors = (directors: any[]) => {
    const tempDirectors: any[] = [];
    directors.map((val) => {
        const tempObj: any = {};
        [
            'firstName',
            'lastName',
            'docType',
            'docNumber',
            'countryCode',
            'phone',
            'email',
            'address',
            'postalCode',
            'country',
            'county',
            'id',
        ].map((keyValue) => {
            if (keyValue == 'countryCode') {
                tempObj[keyValue] = val[keyValue] || process.env.DEFAULT_COUNTRY_CODE || '+254';
            } else if (keyValue == 'country') {
                tempObj[keyValue] = val[keyValue] ?? (process.env.DEFAULT_COUNTRY || 'Kenya');
            } else {
                tempObj[keyValue] = val[keyValue] || undefined;
            }
        });
        tempDirectors.push(tempObj);
    });
    return tempDirectors;
};
