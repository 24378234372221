import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Input, Button, Divider, Dropdown } from 'antd';
import { Bell, Search } from 'react-feather';
// import { UserOutlined } from '@ant-design/icons';
import { ChevronDown } from 'react-feather';
import LogoutComponent from '../logout';
import QuestionMarkIcon from '../../../../../assets/icons/Svg/Inactive/question-mark.svg';
import style from './styles';
import UserAvatar from '../../../../../utils/Avatar';
// import { Link } from 'react-router-dom';
// import { goToRoute, ROUTES } from '../../../../../router/urls';
import NotificationPanel from './notification-panel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { authenticatedLayoutActions } from '../../store/actions';

const TopBarWrapper = styled.div`
    ${style}
`;

interface Props {
    user;
    position?: 'sticky' | 'relative' | 'fixed';
}

const TopBar = ({ user, position }: Props) => {
    const [showNotification, setShowNotification] = useState(false);
    const [showUserDropdown, setShowUserDropDown] = useState(false);
    const dispatch = useDispatch();
    const notificationSeen = useSelector((state: RootState) => state.authenticatedLayoutReducer.notificationSeen);
    return (
        <TopBarWrapper style={position ? { position, top: 0, zIndex: 1000 } : {}}>
            <Row className="top-bar-container">
                <Col span={12} className="search-container">
                    <Input
                        prefix={<Search size="16px" className="search-icon" />}
                        placeholder="Search anything here..."
                        className="search-input "
                    />
                </Col>
                <Col span={12} className="actions-container">
                    <Button className="help-button">
                        <img src={QuestionMarkIcon} alt="Question Mark" />
                        <span>Need Help?</span>
                    </Button>
                    {/* <Button className="icon-button" shape="circle">
                        <Link to={goToRoute(ROUTES.MESSAGES)}>
                            <MessageSquare className="icon" />
                        </Link>
                    </Button>
                    <Button className="icon-button icon-second" shape="circle">
                        <Link to={goToRoute(ROUTES.TRACKER)}>
                            <Layers className="icon" />
                        </Link>
                    </Button> */}
                    <Dropdown
                        overlay={<NotificationPanel />}
                        visible={showNotification}
                        trigger={['click']}
                        onVisibleChange={(visible) => {
                            setShowNotification(visible);
                            visible && dispatch(authenticatedLayoutActions.seenNotifications());
                        }}
                    >
                        <Button className="icon-button icon-third" shape="circle">
                            <Bell className="icon" />
                            {!notificationSeen && <div className="red-dot" />}
                        </Button>
                    </Dropdown>
                    <Divider type="vertical" className="divider" />
                    <Dropdown
                        overlay={<LogoutComponent user={user} showDropDown={showUserDropdown} />}
                        // visible={showUserDropdown}
                        trigger={['click']}
                        onVisibleChange={(visible) => setShowUserDropDown(visible)}
                    >
                        <div className="user-container" onClick={() => setShowUserDropDown(true)}>
                            <UserAvatar type="topbar" />
                            &nbsp;&nbsp;
                            <ChevronDown size={18} />
                        </div>
                    </Dropdown>
                </Col>
            </Row>
        </TopBarWrapper>
    );
};

export default TopBar;
