import React, { cloneElement, createElement, PropsWithChildren, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { ArrowLeft, X } from 'react-feather';

import PageBreadcrumbItem from './PageBreadcrumbItem';
import { ColorType } from '../../config/theme';
import styles from './PageBreadcrumb.styles';
import { getRoutePath, RouteKey } from '../Helper';

const PageBreadcrumbWrapper = styled.div<any>`
    ${styles}
`;

interface Path {
    name: RouteKey;
    params: Record<string, string>;
}

export interface PageBreadcrumbProps {
    activeColor?: ColorType;
    linkColor?: ColorType;
    separator?: string;
    closePath?: Path | RouteKey | 'GO_BACK';
    backPath?: Path | RouteKey;
    onBackPress?: () => void;
    routes?: Array<PageBreadcrumbItem>;
}

type PageBreadcrumb = React.FC<PageBreadcrumbProps> & { Item: typeof PageBreadcrumbItem };

const PageBreadcrumb: PageBreadcrumb = ({
    closePath,
    backPath,
    children,
    onBackPress: _onBackPress,
    ...props
}: PropsWithChildren<PageBreadcrumbProps>) => {
    const history = useHistory();

    const onBackPress = useCallback(() => {
        if (_onBackPress) {
            return _onBackPress();
        }
        if (backPath) {
            if (typeof backPath === 'object') {
                return history.push(getRoutePath(backPath.name, backPath.params));
            }
            return history.push(getRoutePath(backPath));
        }
        history.goBack();
    }, [_onBackPress]);

    const onClosePress = useCallback(() => {
        if (closePath) {
            if (typeof closePath === 'object') {
                return history.push(getRoutePath(closePath.name, closePath.params));
            }
            if (closePath === 'GO_BACK') {
                return history.goBack();
            }
            history.push(getRoutePath(closePath));
        }
    }, [closePath]);

    const renderRoutes = useCallback(() => {
        return props.routes
            ?.map((element, index) => {
                if (!element.title) {
                    return null;
                }

                return createElement(PageBreadcrumbItem, {
                    ...element,
                    key: element.key || index,
                    separator: props.separator,
                });
            })
            .filter((element) => !!element);
    }, [props]);

    const renderChildren = useCallback(() => {
        return React.Children.map(children, (element) => {
            if (!element) {
                return element;
            }

            return cloneElement(element as any, {
                separator: props.separator,
            });
        });
    }, [children, props.separator]);

    return (
        <PageBreadcrumbWrapper {...props}>
            <ArrowLeft className="back" onClick={onBackPress} data-testid="page-breadcrumb-back" />
            <Breadcrumb separator={props.separator}>{children ? renderChildren() : renderRoutes()}</Breadcrumb>
            {closePath ? <X className="close" onClick={onClosePress} data-testid="page-breadcrumb-close" /> : null}
        </PageBreadcrumbWrapper>
    );
};

PageBreadcrumb.defaultProps = {
    separator: '|',
};

PageBreadcrumb.Item = PageBreadcrumbItem;

export default PageBreadcrumb;
