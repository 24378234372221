import { Button } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import style from './styles';

import Timer, { VideoElement } from '../cards/business-documents/components/livenessHelper';
import { unAuthenticatedRequest } from '../../../api';
import { document_manager_url, upload_url_without_base, verify_live_image } from '../../../apis/uploads';
import { DocumentNameAndType, DocumentPageType } from '../cards/business-documents/types';

const LivenessDetectionWrapper = styled.div`
    ${style}
`;

interface LivenessDetectionProps {
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        pageType: DocumentPageType,
        fileId: string,
        docType?: string,
    ) => void;
    data?: any;
}

const TimerButton = ({ startCamera }: any) => {
    return <Timer initialSeconds={6} onComplete={() => startCamera()} />;
};

const LivenessDetection: FunctionComponent<LivenessDetectionProps> = ({
    uploadFilesToBusinessAccount,
    data,
}: LivenessDetectionProps) => {
    const [openCamera, setOpenCamera] = useState<string>('initial');
    const [imageBlob, setImageBlob] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [livenessResponse, setLivenessResponse] = useState<any>({});
    const [startTimer, setStartTimer] = useState<boolean>(false);

    const uploadToDocumentManager = async () => {
        setLoading(true);
        const formData = new FormData();
        const blob = await fetch(imageBlob).then((r) => r.blob());
        formData.append('document', blob, 'out.gif');

        unAuthenticatedRequest({ 'x-business-account-id': data?.businessAccountId })
            .post(upload_url_without_base, formData)
            .then((res) => res.data)
            .then((resDoc) => {
                unAuthenticatedRequest({}, false)
                    .post(verify_live_image, {
                        documentType: 'facelivedetect',
                        documentURL: document_manager_url(resDoc?.downloadURl),
                    })
                    .then((res) => res.data)
                    .then((res) => {
                        setLivenessResponse(res);
                        uploadFilesToBusinessAccount(
                            resDoc?.documentId,
                            resDoc?.downloadURl,
                            DocumentPageType.STANDARD,
                            resDoc?.fileId,
                            DocumentNameAndType.LIVE_ID,
                        );
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const changeStatus = () => {
        if (openCamera == 'start') {
            setOpenCamera('timer');
            return;
        }
        if (openCamera == 'timer') {
            setOpenCamera('retry');
            setStartTimer(false);
            return;
        }
        if (openCamera == 'retry') {
            setOpenCamera('retry-initial');
            return;
        }
        if (openCamera == 'initial' || openCamera == 'retry-initial') {
            setOpenCamera('start');
            return;
        }
    };

    return (
        <LivenessDetectionWrapper>
            <div className="card-description-wrapper" style={{ marginBottom: 10 }}>
                <div className="card-description">To scan your face, please follow these instructions.</div>
            </div>
            <ul className="instructions-list">
                <li className="card-description">
                    Please turn your head from left to right then tilt your head to the left then to the right..
                </li>
            </ul>
            <VideoElement
                livenessResponse={livenessResponse}
                imageBlob={imageBlob}
                setImageBlob={setImageBlob}
                openCamera={openCamera}
                setLivenessResponse={setLivenessResponse}
                setStartTimer={setStartTimer}
            />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {openCamera == 'timer' ? (
                    <Button className="gapstack-button gapstack-primary-button">
                        {startTimer ? <TimerButton key={openCamera} startCamera={changeStatus} /> : 'Starting...'}
                    </Button>
                ) : (
                    <>
                        <Button className="gapstack-button gapstack-primary-button" onClick={changeStatus}>
                            {openCamera == 'start' ? 'Start' : openCamera == 'retry' ? 'Retry' : `I'm Ready`}
                        </Button>
                        {openCamera == 'retry' && !livenessResponse?.facelivedetect && (
                            <Button
                                className="gapstack-button gapstack-primary-button"
                                onClick={() => uploadToDocumentManager()}
                                style={{ marginLeft: 15 }}
                                loading={loading}
                                disabled={!imageBlob}
                            >
                                Verify
                            </Button>
                        )}
                    </>
                )}
            </div>
        </LivenessDetectionWrapper>
    );
};

export default LivenessDetection;
