import React from 'react';
import styled from 'styled-components';
import style from './styles';

const TitleWrapper = styled.div`
    ${style}
`;

interface Props {
    title: string;
}

const ModalTitle = ({ title }: Props) => {
    return (
        <TitleWrapper>
            <span className="title">{title}</span>
        </TitleWrapper>
    );
};

export default ModalTitle;
