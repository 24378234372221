import { RoutesType } from './routes';
import { Constant } from '../config';

export const goToRoute = (route_name: string, businessId?: string) => {
    if (businessId) {
        return route_name.replace(':businessId', businessId);
    } else {
        let return_route = '/sign-in';
        try {
            const base_url = window.location.host;
            const temp_business_id = window.location.pathname
                .replace(base_url, '')
                .replace('https://', '')
                .split('/')[1];
            // console.log(temp_business_id);
            return_route = route_name.replace(':businessId', temp_business_id);
        } catch (err) {
            console.log(err);
        }
        // console.log(return_route);
        return return_route;
    }
};

export const getBusinessId = () => {
    let temp_business_id = '';
    const base_url = window.location.host;
    temp_business_id = window.location.pathname.replace(base_url, '').split('/')[1];
    return temp_business_id;
};

const bankName = Constant.customBankUrl;

export const ROUTES: { [key in RoutesType]: string } = {
    // Custom Bank
    CUSTOM_BANK_SIGN_IN: `/${bankName}/sign-in`,
    CUSTOM_BANK_SIGN_UP: `/${bankName}/sign-up`,
    CUSTOM_BANK_ACTIVATE_ACCOUNT: `/${bankName}/activate/account/:userId`,
    CUSTOM_BANK_FORGOT_PASSWORD: `/${bankName}/forgot-password`,
    EXTERNAL_VERIFY_BOND: '/verify-bond',
    EXTERNAL_VERIFY_BOND_RESULTS: '/verify-bond-results/:id',

    SIGN_IN: '/sign-in',
    BOT_SIGN_IN: '/bot-sign-in/:id/:permission/channel/:channel',
    SIGN_UP: '/sign-up',
    LANDING_PAGE: '/home',
    NEW_USER_SIGN_UP: '/accept/new/collaboration/:token/user/:userId/business/:businessAccountId',
    FORGOT_PASSWORD: '/forgot-password',
    CONFIRM_USER: '/welcome',
    ADD_ACCOUNT: '/add-account',
    CONFIRM_ACCOUNT: '/confirm-account',
    VERIFY_DOC: '/verify-documents',
    ACTIVATE_ACCOUNT: '/activate/account/:userId',
    VERIFY_PHONE: '/verify/phone/:userId',
    RESET_PASSWORD: '/reset/password/:token/user/:userId',
    TWO_FACTOR_LOGIN: '/two-factor/login',
    ACCEPT_BUSINESS_INVITATION: '/complete/business/invite/:token/user/:userId',
    ACCEPT_COLLABORATION_REQUEST: '/accept/collaboration/:token/user/:userId',
    COMPLETE_SIGN_UP: '/complete-sign-up/password/:token/user/:userId',
    SET_UP_USSD_PIN: '/set-up-ussd-pin/:id',
    VALIDATE_DOCUMENT: '/validate-document/:token',

    //Business
    INVITE_DIRECTORS: '/complete/director/:id',
    COMPLETE_KYC: '/:businessId/complete/business/kyc',
    VERIFY_MANDATE: '/approve-mandate/:businessId/:verifyToken',

    //Bank auths
    VERIFY_ACCOUNT: '/verify/token/:token/user/:userId',
    COMPLETE_REGISTRATION: '/complete/registration',

    //Bank - Business accounts
    BUSINESS: '/businesses',
    BUSINESS_ONBOARD: '/businesses/onboard',
    BUSINESS_ACTIVATE_ACCOUNT: '/businesses/activate/account/:userId',
    BUSINESS_COMPLETE_KYC: '/businesses/complete-kyc/:businessId',
    VERIFY_BUSINESS_ACCOUNT: '/verify/business/account/:accountId',
    UNAUTHORIZED: '/:businessId/unauthorized',
    MESSAGES: '/:businessId/messages',
    MESSAGES_AUTH: '/auth/google/callback',
    TRACKER: '/:businessId/tracker',
    NOTIFICATIONS: '/:businessId/notifications',

    //Initialization
    INITIALIZATION: '/:businessId/initializing',
    INITIALIZATION_BANK: '/initializing',
    INITIALISE_ANOTHER_ACCOUNT: '/:businessId/initializing/:id',

    //Business - Products
    PRODUCTS: '/:businessId/products',
    VIEW_PRODUCT: '/:businessId/products/view-product/:id',
    ADD_NEW_PRODUCT: '/:businessId/products/create',
    EDIT_PRODUCT: '/:businessId/products/edit/:id',
    CONFIRM_BULK_UPLOAD_PRODUCTS: '/:businessId/products/confirm/bulk/upload',

    //Supplier central
    SUPPLIER_CENTRAL: '/:businessId/purchases/supplier-central',
    ADD_PURCHASE_SUPPLIER: '/:businessId/purchases/supplier-central/create',
    INVITE_PURCHASE_SUPPLIER: '/:businessId/purchases/supplier-central/create/invite',
    INVITE_BULK_STEP_TWO: '/:businessId/purchases/supplier-central/create/invite/step-2',
    PENDING_SUPPLIERS: '/:businessId/purchases/supplier-central/create/pending', // TODO removed
    ONBOARD_SUPPLIERS: '/:businessId/purchases/supplier-central/create/onboard',
    VIEW_SUPPLIER: '/:businessId/purchases/supplier-central/view-supplier/:id',

    //Purchase Order
    PURCHASE_ORDER: '/:businessId/purchases/purchase-order',
    VIEW_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/view-purchase-order/:id',
    VIEW_UPLOADED_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/view-uploaded-purchase-order/:id',
    ADD_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/add',
    CREATE_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/create',
    UPLOAD_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/upload',
    CREATE_PURCHASE_ORDER_STEP_2: '/:businessId/purchases/purchase-order/step-2/:id',
    BULK_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/upload/bulk-purchase-order',
    EDIT_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/edit/:id',
    COPY_PURCHASE_ORDER: '/:businessId/purchases/purchase-order/copy/:id',
    CREATE_PURCHASE_ORDER_FROM_QUOTATION: '/:businessId/purchases/purchase-order/create-from-quote/:id',

    //Received quotations
    RECEIVED_QUOTATIONS: '/:businessId/purchases/received-quotations',
    VIEW_RECEIVED_QUOTATION: '/:businessId/purchases/received-quotation/:id',
    VIEW_UPLOADED_RECEIVED_QUOTATION: '/:businessId/purchases/received-quotations/uploaded-received-quotation/:id',
    UPLOAD_RECEIVED_QUOTATION: '/:businessId/purchases/upload-received-quotation',
    VALIDATE_RECEIVED_QUOTATION: '/:businessId/purchases/received-quotations/validate-quotation/:id',

    //Purchase Invoices
    INVOICES: '/:businessId/purchases/purchase-invoices',
    VIEW_INVOICE: '/:businessId/purchases/purchase-invoices/view-invoice/:id',
    VIEW_UPLOADED_RECEIVED_INVOICE: '/:businessId/purchases/purchase-invoices/view-uploaded-invoice/:id',
    VALIDATE_INVOICE: '/:businessId/purchases/purchase-invoices/validate-invoice/:id',
    BULK_PURCHASE_INVOICES_TABLE: '/:businessId/purchases/purchase-invoices/create/table',
    ADD_INVOICE: '/:businessId/purchases/purchase-invoices/create',

    //Business - Buyer
    BUYER_CENTRAL: '/:businessId/sales/buyer-central',
    ADD_SALES_BUYER: '/:businessId/sales/buyer-central/create',
    INVITE_SALES_BUYER: '/:businessId/sales/buyer-central/create/invite',
    INVITE_BULK_STEP_TWO_BUYER: '/:businessId/sales/buyer-central/create/invite/step-2',
    PENDING_BUYERS: '/:businessId/sales/buyer-central/create/pending', // TODO remove
    ONBOARD_BUYERS: '/:businessId/sales/buyer-central/create/onboard',
    VIEW_BUYER: '/:businessId/sales/buyer-central/view-buyer/:id',

    //Sales Order
    SALES_ORDER: '/:businessId/sales/sales-order',
    ADD_SALES_ORDER: '/:businessId/sales/sales-order/add',
    ADD_SALES_ORDER_TABLE: '/:businessId/sales/sales-order/create/table',
    VIEW_SINGLE_RECEIVED_PURCHASE_ORDER: '/:businessId/sales/sales-order/view-received-purchase-order/:id',
    VIEW_UPLOADED_RECEIVED_PURCHASE_ORDER: '/:businessId/sales/sales-order/view-uploaded-purchase-order/:id',
    VALIDATE_PURCHASE_ORDER: '/:businessId/sales/sales-order/validate-order/:id',

    //Sales Invoices
    SALES_INVOICES: '/:businessId/sales/invoices',
    VIEW_COMMERCIAL_INVOICE: '/:businessId/sales/invoices/view-commercial-invoice/:id',
    VIEW_PROFORMA_INVOICE: '/:businessId/sales/invoices/view-proforma-invoice/:id',
    ADD_SALES_INVOICES: '/:businessId/sales/invoices/add',
    ADD_SALES_INVOICES_FROM_PURCHASE_ORDER: '/:businessId/sales/invoices/add/create-from-purchase-order/:id',
    CREATE_COMMERCIAL_INVOICE_STEP_ONE: '/:businessId/sales/invoices/commercial/create',
    CREATE_COMMERCIAL_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER:
        '/:businessId/sales/invoices/commercial/create-from-purchase-order/:id',
    CREATE_COMMERCIAL_INVOICE_STEP_TWO: '/:businessId/sales/invoices/commercial/step-two/:id',
    CREATE_PROFORMA_INVOICE_STEP_ONE: '/:businessId/sales/invoices/proforma/create',
    CREATE_PROFORMA_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER:
        '/:businessId/sales/invoices/proforma/create-from-purchase-order/:id',
    CREATE_PROFORMA_INVOICE_STEP_TWO: '/:businessId/sales/invoices/proforma/step-two/:id',
    EDIT_COMMERCIAL_INVOICE: '/:businessId/sales/invoices/commercial/edit/:id',
    COPY_COMMERCIAL_INVOICE: '/:businessId/sales/invoices/commercial/copy/:id',
    VIEW_UPLOADED_INVOICE: '/:businessId/sales/invoices/view-uploaded-invoice/:id',
    EDIT_PROFORMA_INVOICE: '/:businessId/sales/invoices/proforma/edit/:id',
    COPY_PROFORMA_INVOICE: '/:businessId/sales/invoices/proforma/copy/:id',
    UPLOAD_BULK_INVOICE: '/:businessId/sales/invoices/upload',
    BULK_UPLOAD_INVOICES: '/:businessId/sales/invoices/upload/bulk-invoice',
    EDIT_UPLOADED_INVOICE: '/:businessId/sales/invoices/edit/:id',

    // sales quotation
    QUOTATIONS: '/:businessId/sales/quotations',
    CREATE_QUOTATION_STEP_ONE: '/:businessId/sales/quotation/create',
    CREATE_QUOTATION_STEP_TWO: '/:businessId/sales/quotation/step-two/:id',
    VIEW_QUOTATION: '/:businessId/sales/quotations/view/:id',
    EDIT_QUOTATION: '/:businessId/sales/quotations/edit/:id',
    COPY_QUOTATION: '/:businessId/sales/quotations/copy/:id',
    VIEW_UPLOADED_QUOTATION: '/:businessId/sales/quotations/uploaded-quotation/:id',
    ADD_SALES_QUOTATIONS: '/:businessId/sales/quotations/add',
    UPLOAD_BULK_QUOTATION: '/:businessId/sales/quotations/upload',
    BULK_UPLOAD_QUOTATIONS: '/:businessId/sales/invoices/quotations/bulk-quotations',

    //Business - Dashboard
    DASHBOARD: '/:businessId/dashboard',
    DASHBOARD_BANK: '/dashboard',

    // Business - Ecosystem
    ECOSYSTEM: '/:businessId/connections',
    SYNCHRONIZE: '/:businessId/synchronize',
    VIEW_CONNECTION: '/:businessId/connections/view/:id',

    // Connected Accounts
    CONNECTED_ACCOUNTS: '/:businessId/connected-accounts',
    ADD_NEW_ACCOUNT: '/:businessId/connected-accounts/new/account',
    VIEW_CONNECTED_ACCOUNT: '/:businessId/connected-accounts/:id',
    VIEW_CONNECTED_ACCOUNT_TRANSACTIONS: '/:businessId/connected-accounts/transactions/:transactionId',
    EDIT_CONNECTED_ACCOUNT: '/:businessId/connected-accounts/:id/edit',
    MAKE_PAYMENTS_ACCOUNT: '/:businessId/connected-accounts/make-payment/new',
    RECEIVE_PAYMENTS_ACCOUNT: '/:businessId/connected-accounts/receive-payment/new',
    RECONCILE_CONNECTED_ACCOUNT_TRANSACTION: '/:businessId/connected-accounts/transactions/reconcile/:transactionId',
    ACCOUNT_STATEMENT: '/:businessId/account-statement',
    RECONCILED_TRANSACTIONS: '/:businessId/transactions/reconciled',

    //Business - Fund My Purchases
    FUND_MY_PURCHASES: '/:businessId/fund-my-purchases',
    VIEW_FINANCING_REQUEST: '/:businessId/fund-my-purchases/view-financing-request/:id',
    GET_FINANCING: '/:businessId/fund-my-purchases/get-financing',
    FINANCE_INVOICES: '/:businessId/fund-my-purchases/get-financing/finance-invoices',
    WORKING_CAPITAL: '/:businessId/fund-my-purchases/get-financing/working-capital',
    DISTRIBUTOR_ECOSYSTEM: '/:businessId/fund-my-purchases/get-financing/distributor-ecosystem',
    EDIT_FINANCE_INVOICES: '/:businessId/fund-my-purchases/get-financing/finance-invoices/:id/',
    EDIT_WORKING_CAPITAL: '/:businessId/fund-my-purchases/get-financing/working-capital/:id',
    EDIT_DISTRIBUTOR_ECOSYSTEM: '/:businessId/fund-my-purchases/get-financing/distributor-ecosystem/:id',

    //Bank - Fund My Purchases
    FUND_MY_PURCHASES_BANK: '/fund-my-purchases',
    VIEW_FINANCING_REQUEST_BANK: '/fund-my-purchases/:businessId/view-financing-request/:id',
    VIEW_VALIDATION_REQUEST_BANK: '/fund-my-purchases/view-validation-request/:id',

    // Profile
    PROFILE: '/:businessId/profile',
    BANK_PROFILE: '/profile',
    //Business - Fund From Sales
    FUND_FROM_SALES: '/:businessId/fund-from-sales',
    VIEW_FINANCING_ORDER: '/:businessId/fund-from-sales/view-financing-order/:id',
    GET_FINANCING_SALES: '/:businessId/fund-from-sales/get-financing',
    FINANCE_INVOICES_SALES: '/:businessId/fund-from-sales/get-financing/finance-invoices',
    WORKING_CAPITAL_SALES: '/:businessId/fund-from-sales/get-financing/working-capital',
    DISCOUNT_INVOICES: '/:businessId/fund-from-sales/get-financing/discount-invoices',
    EDIT_FINANCE_INVOICES_SALES: '/:businessId/fund-from-sales/get-financing/finance-invoices/:id',
    EDIT_WORKING_CAPITAL_SALES: '/:businessId/fund-from-sales/get-financing/working-capital/:id',
    EDIT_DISCOUNT_INVOICES: '/:businessId/fund-from-sales/get-financing/discount-invoices/:id',
    APPLICATION_SUMMARY: '/:businessId/financing/:id',

    //Bank - Fund From Sales
    FUND_FROM_SALES_BANK: '/fund-from-sales',
    VIEW_FINANCING_REQUEST_BANK_SALES: '/fund-from-sales/:businessId/view-financing-request/:id',
    VIEW_VALIDATION_REQUEST_BANK_SALES: '/fund-from-sales/view-validation-request/:id',

    //Business - Payments
    PAYMENTS: '/:businessId/payments',
    VIEW_PAYMENTS: '/:businessId/payments/view-payment/:id',
    RECEIVE_PAYMENTS: '/:businessId/payments/receive-payment/new',
    MAKE_PAYMENTS: '/:businessId/payments/make-payment/new',
    PAY_INVOICE: '/:businessId/payments/make-payment/pay-invoice',
    PAY_BILL: '/:businessId/payments/make-payment/pay-bill',
    REPAY_LOAN: '/:businessId/payments/make-payment/repay-loan',

    //Accounts
    ACCOUNTS: '/:businessId/accounts',

    //Business - Guarantees/Bonds
    // GUARANTEE_BONDS: '/:businessId/guarantees-bonds',
    BONDS: '/:businessId/guarantees-bonds/bonds',
    VIEW_BOND: '/:businessId/guarantees-bonds/bonds/view-bonds/:id',
    EDIT_BOND: '/:businessId/guarantees-bonds/bonds/edit-bonds/:id',
    BONDS_FINANCING: '/:businessId/guarantees-bonds/bonds-financing',
    ADD_NEW_GUARANTEE: '/:businessId/guarantees-bonds/bonds/new',
    CREATE_NEW_GUARANTEE: '/:businessId/guarantees-bonds/bonds/create',
    VERIFY_BOND: '/:businessId/guarantees-bonds/bonds/verify-bond/:id',

    // Custom Bank
    NEW_GUARANTEE: '/express-bonds/create',
    NEW_GUARANTEE_SUBMIT: '/:businessId/guarantees-bonds/submit',

    //Developer
    DEVELOPER: '/:businessId/developer',

    //Settings
    SETTINGS: '/:businessId/business/settings',

    //Business - Team and security settings
    TEAM_SETTINGS: '/:businessId/business/settings/team-settings',
    SECURITY_HISTORY: '/:businessId/business/settings/security-history',
    CONNECTED_APPLICATIONS: '/:businessId/business/settings/connected-applications',

    //Business - Business Settings
    ACCOUNT_SETTINGS: '/:businessId/business/settings/account-settings',
    BUSINESS_INFORMATION: '/:businessId/business/settings/business-information',
    BRAND_SETTINGS: '/:businessId/business/settings/brand-settings',
    FINANCIAL_INFORMATION: '/:businessId/business/settings/financial-information',
    CREDIT_REPORT: '/:businessId/business/settings/credit-report',
    TERMS_CONDITIONS: '/:businessId/business/settings/terms-conditions',

    //Fraud Central
    BLACKLIST: '/:businessId/business/settings/fraud-central/blacklist',

    //Bank Settings
    BANK_SETTINGS: '/bank-settings',
    TEAMS: '/bank-settings/users',
    USER_GROUPS: '/bank-settings/user-groups',
    CREATE_USER_GROUPS: '/bank-settings/user-groups/create',
    UPLOAD_USER_GROUPS: '/bank-settings/user-groups/upload',
    VIEW_USER_GROUPS: '/bank-settings/user-groups/view/:id',
    ADD_MEMBER: '/bank-settings/user-groups/add-member/:id',
    KYC_SETTINGS: '/bank-settings/kyc-settings',
    TERMS_AND_CONDITIONS_DOCS: '/bank-settings/terms-and-condition-docs',
    COLLECTION_ACCOUNT_SETTINGS: '/bank-settings/product-settings/accounts',

    VALIDATED_BUYERS: '/bank-settings/validated-buyers',
    VIEW_VALIDATED_BUYER: '/bank-settings/validated-buyers/:id',
    VALIDATED_SUPPLIERS: '/bank-settings/validated-suppliers',
    VIEW_VALIDATED_SUPPLIER: '/bank-settings/validated-suppliers/:id',
    SUPPLIER_GROUPS: '/bank-settings/supplier-groups',

    //Product Settings
    COLLECTION_STRATEGY: '/bank-settings/product-settings/collection-strategy',
    ADD_STRATEGY: '/bank-settings/product-settings/add-new-strategy',
    VIEW_COLLECTION_STRATEGY: '/bank-settings/product-settings/view-collection-strategy',
    CREDIT_LIMITS: '/bank-settings/product-settings/credit-limits',
    ADD_CREDIT_UPLOAD: '/bank-settings/product-settings/add-new-credit',
    GUARANTEE_SETTINGS: '/bank-settings/guarantee-settings',
    CHARGES_FINANCING_PRODUCTS: '/bank-settings/product-settings/financing-products',

    //add new template
    ADD_NEW_TEMPLATE: '/bank-settings/guarantee-settings/add-new-template',
    EDIT_TEMPLATE: '/bank-settings/guarantee-settings/edit-template/:id',
    DUPLICATE_TEMPLATE: '/bank-settings/guarantee-settings/duplicate-template/:id',
    VIEW_TEMPLATE: '/bank-settings/guarantee-settings/view-template/:id',

    // Applied Charges
    APPLIED_CHARGES: '/bank-settings/product-settings/applied-charges',
    ADD_APPLIED_CHARGES: '/bank-settings/product-settings/apply-new-charge',
    VIEW_APPLIED_CHARGES: '/bank-settings/product-settings/applied-charges/view-applied-charges',

    // Financial product
    ADD_NEW_FINANCE_PRODUCT: '/bank-settings/product-settings/new-finance-product',
    VIEW_FINANCING_PRODUCTS: '/bank-settings/product-settings/view-financing-products/:id',

    //INSIGHTS
    INSIGHTS: '/:businessId/insights',

    //Import Trade
    MAKE_IMPORT_PAYMENTS: '/:businessId/import-trade/make-payments',
    ADD_PERFORMA_INVOICE: '/:businessId/import-trade/add-performa-invoice',
    UPLOAD_INVOICE: '/:businessId/import-trade/upload-invoice',
    RECEIVED_BILLS_ON_COLLECTION: '/:businessId/import-trade/received-bills-on-collection/:id',
    VIEW_RECEIVED_BILLS_ON_COLLECTION: '/:businessId/import-trade/view-bill/:id',
    // IMPORT_TRADE: '/:businessId/import-trade',
    IMPORT_LOANS: '/:businessId/import-trade/import-loans',
    VIEW_TRADE_LOAN: '/:businessId/import-trade/import-loans/view-import-loan/:id',
    GET_TRADE_FINANCING: '/:businessId/import-trade/import-loans/get-finacing',
    FINANCE_IMPORT_ORDERS: '/:businessId/import-trade/import-loans/finance-import-orders',
    EDIT_FINANCE_IMPORT_ORDERS: '/:businessId/import-trade/import-loans/finance-import-orders/:id',
    POST_SHIPMENT_FINANCEING: '/:businessId/import-trade/import-loans/post-shipment-financing',
    EDIT_POST_SHIPMENT_FINANCING: '/:businessId/import-trade/import-loans/post-shipment-financing/:id',
    IMPORT_SERVICES: '/:businessId/import-trade/import-services',
    SHIPMENT_TRACKING: '/:businessId/import-trade/import-services/shipment-tracking',
    TRACK_SHIPMENT: '/:businessId/import-trade/import-services/shipment-tracking/track/:id',
    SECURE_IMPORTS: '/:businessId/import-trade/secure-imports',
    TRACK_SECURE_IMPORT: '/:businessId/import-trade/secure-imports/track/:id',
    ESCROW: '/:businessId/import-trade/escrow',
    NEW_ESCROW: '/:businessId/import-trade/escrow/add-new',
    VIEW_ESCROW: '/:businessId/import-trade/escrow/view-escrow/:id',
    TRACK_ESCROW: '/:businessId/import-trade/escrow/track-escrow/:id',

    // Import Trade --> Trade Finance
    TRADE_FINANCE: '/:businessId/import-trade/trade-finance',
    ADD_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/new-import-order',
    BULK_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/bulk-import-order',
    LETTER_OF_CREDIT: '/:businessId/import-trade/trade-finance/letter-of-credit',
    VIEW_LETTER_OF_CREDIT: '/:businessId/import-trade/trade-finance/view-letter-of-credit/:id',
    EDIT_LETTER_OF_CREDIT: '/:businessId/import-trade/trade-finance/edit-letter-of-credit/:id',
    APPROVE_LETTER_OF_CREDIT: '/:businessId/import-trade/trade-finance/letter-of-credit/approve/:id',
    CLOSE_LETTER_OF_CREDIT: '/:businessId/import-trade/trade-finance/close-letter-of-credit',
    VIEW_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/view-import-order/:id',
    TRACK_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/track-import-order/:id',
    ADD_NEW_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/add-new',
    BULK_UPLOAD_IMPORT_ORDER: '/:businessId/import-trade/trade-finance/add-new/upload-import-order',

    //Export Trade
    EXPORT_TRADE_FINANCE: '/:businessId/export-trade/trade-finance',
    EXPORT_BILLS_ON_COLLECTION: '/:businessId/export-trade/trade-finance/register-bills-on-collection',
    EDIT_EXPORT_BILLS_ON_COLLECTION: '/:businessId/export-trade/trade-finance/register-bills-on-collection/:id',

    VIEW_EXPORT_BILLS_ON_COLLECTION:
        '/:businessId/export-trade/trade-finance/view-export-order-bills-on-collection/:id',

    //Bank - Register bills on collection
    VIEW_ALL_REGISTER_BILLS: '/import-trade/bills-on-collection',
    ADD_BILLS_ON_COLLECTION: '/import-trade/bills-on-collection/create',
    EDIT_BILLS_ON_COLLECTION: '/import-trade/bills-on-collection/create/:id',
    VIEW_SINGLE_BILLS_ON_COLLECTION: '/import-trade/bills-on-collection/view/:id',

    TRACK_EXPORT_ORDER: '/:businessId/export-trade/trade-finance/track-export-order/:id',
    // ADD_PERFORMA_INVOICE: '/:businessId/export-trade/add-performa-invoice',
    // EXPORT_TRADE: '/:businessId/export-trade',
    // FUND_EXPORTS: '/:businessId/export-trade/fund-exports',
    EXPORT_LOANS: '/:businessId/export-trade/export-loans',
    VIEW_EXPORT_TRADE_LOAN: '/:businessId/export-trade/export-loans/view-export-loan/:id',
    GET_EXPORT_TRADE_FINANCING: '/:businessId/export-trade/export-loans/get-finacing',
    FINANCE_EXPORT_ORDERS: '/:businessId/export-trade/export-loans/finance-export-orders',
    EDIT_FINANCE_EXPORT_ORDERS: '/:businessId/export-trade/export-loans/finance-export-orders/:id',
    LC_DISCOUNTING: '/:businessId/export-trade/export-loans/lc-discounting',
    GET_WORKING_CAPITAL: '/:businessId/export-trade/export-loans/get-working-capital',
    EDIT_GET_WORKING_CAPITAL: '/:businessId/export-trade/export-loans/get-working-capital/:id',
    COLLECTION_DISCOUNTING: '/:businessId/export-trade/export-loans/collection-discounting',
    EDIT_COLLECTION_DISCOUNTING: '/:businessId/export-trade/export-loans/collection-discounting/:id',

    //Export invoice
    ADD_EXPORT_INVOICE: '/:businessId/export-trade/export-loans/add',
    VIEW_EXPORT_COMMERCIAL_INVOICE: '/:businessId/export-trade/export-loans/view-commercial-invoice/:id',
    VIEW_EXPORT_PROFORMA_INVOICE: '/:businessId/export-trade/export-loans/view-proforma-invoice/:id',
    CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_ONE: '/:businessId/export-trade/export-loans/commercial/create',
    CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_TWO: '/:businessId/export-trade/export-loans/commercial/step-two/:id',
    CREATE_EXPORT_PROFORMA_INVOICE_STEP_ONE: '/:businessId/export-trade/export-loans/proforma/create',
    CREATE_EXPORT_PROFORMA_INVOICE_STEP_TWO: '/:businessId/export-trade/export-loans/proforma/step-two/:id',
    EDIT_EXPORT_COMMERCIAL_INVOICE: '/:businessId/export-trade/export-loans/commercial/edit/:id',
    COPY_EXPORT_COMMERCIAL_INVOICE: '/:businessId/export-trade/export-loans/commercial/copy/:id',
    VIEW_EXPORT_UPLOADED_INVOICE: '/:businessId/export-trade/export-loans/view-uploaded-invoice/:id',
    EDIT_EXPORT_PROFORMA_INVOICE: '/:businessId/export-trade/export-loans/proforma/edit/:id',
    COPY_EXPORT_PROFORMA_INVOICE: '/:businessId/export-trade/export-loans/proforma/copy/:id',

    EXPORT_SERVICES: '/:businessId/export-trade/export-services',
    EXPORT_SHIPMENT_TRACKING: '/:businessId/export-trade/export-services/shipment-tracking',
    TRACK_EXPORT_SHIPMENT: '/:businessId/export-trade/export-services/shipment-tracking/track/:id',
    // SECURE_EXPORTS: '/:businessId/export-trade/secure-exports',
    // TRACK_SECURE_EXPORT: '/:businessId/export-trade/secure-exports/track/:id',

    EXPORT_ESCROW: '/:businessId/export-trade/escrow',
    VIEW_EXPORT_ESCROW: '/:businessId/export-trade/escrow/view-escrow/:id',
    TRACK_EXPORT_ESCROW: '/:businessId/export-trade/escrow/track-escrow/:id',
};

export type RouteKey = keyof typeof ROUTES;
