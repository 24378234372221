import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';
import { Constant } from '../../../../config';

const style = () => css`
    min-height: 100vh;
    max-width: 360px;
    margin: auto;

    .logo-container {
        display: flex;
        .logo {
            margin: 70px auto 32px;
            width: ${Constant.logoWidth};
            height: ${Constant.logoHeight};
        }
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 20px 20px;
        margin-top: -10px;
        overflow-x: hidden;
        .welcome {
            padding: 0 0px;
            font-weight: 600;
            font-size: 24px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .description {
            font-size: 14px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .top-divider {
            width: 88px;
            max-width: 88px;
            margin: 20px 0 0px 0px;
            height: 3px;
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            overflow: hidden;
            border-radius: 1.5px;
        }

        .ant-form-item {
            margin-bottom: 20px;
        }

        .ant-form-item-label {
            padding-bottom: 0 !important;
        }

        .button-forgot {
            margin: 0px auto 5px;
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            cursor: pointer;
            font-size: 13px;
            padding: 0;
        }

        .button-container {
            padding: 0 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin: 20px 0;
            .gapstack-button {
                font-size: 15px;
            }
        }
    }
    .form-divider-sign-in {
        margin: 15px 10px 15px -30px;
        width: 120%;
    }
    .sign-in-err-wrapper {
        display: flex;
        align-items: center;
        margin: -5px 0px 15px 0px;
        justify-content: center;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        .icon {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
            width: 18px;
            margin-right: 5px;
        }
        span {
            font-size: 13px;
        }
    }

    .sign-up-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        .normal {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-size: 14px;
            padding-right: 2px;
        }
        .button-sign {
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            padding-left: 2px;
        }
    }

    .sign-in-successful-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
            font-weight: 700;
            font-size: 14px;
            line-height: 15px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
        .countdown {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.EDIT_BUTTON]};
        }
    }
`;

export default style();
