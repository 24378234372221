import { Button, Col, DatePicker, Divider, Form, Input, Modal, notification, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { X } from 'react-feather';
import { authenticatedRequest } from '../../../../api';
import { submit_pick_up_information } from '../../../../apis/business-accounts';
import { PlacesField } from '../../../helpers';
import moment from 'moment';
import { useParams } from 'react-router';
import { getUser, Users } from '../../../../config';
interface PickUpModalProps {
    showModal: boolean;
    hideModal: (val: boolean) => void;
    data: any;
    setPickUp?: (val: any) => void;
}
export function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}
const PickUpModal: FunctionComponent<PickUpModalProps> = ({
    showModal,
    hideModal,
    data,
    setPickUp,
}: PickUpModalProps) => {
    const [form] = Form.useForm();

    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    const [loading, setLoading] = useState<boolean>(false);
    const onFinish = (values: any) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };

        const submitObj = { ...values, dropOff: false };
        setLoading(true);
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_pick_up_information, submitObj)
            .then(() => {
                setLoading(false);
                hideModal(false);
                setPickUp?.(1);
                notification.success({
                    message: 'Success',
                    description: 'Pick up details recorded successfully',
                });
            })
            .catch((err) => {
                setLoading(false);
                const resp = err.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        const dateFormat = 'YYYY/MM/DD, hh:mm:ss a';
        if (!data) return;
        form.setFieldsValue({
            gps: data?.documentPickUp?.gps,
            instruction: data?.documentPickUp?.instruction,
            preferredDateAndTime: data?.documentPickUp?.preferredDateAndTime
                ? moment(data?.documentPickUp?.preferredDateAndTime, dateFormat)
                : undefined,
        });
    }, [data]);
    return (
        <Modal
            title="Request Document Pickup"
            visible={showModal}
            onCancel={() => hideModal(false)}
            footer={false}
            getContainer={false}
            closeIcon={<X className="close-modal" />}
            width="40%"
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish}>
                <div className="info" style={{ marginBottom: '15px' }}>
                    Please ensure the following documents are ready for pick-up.
                </div>
                <div className="info">1. Board Resolution</div>
                <div className="info">2. Signed T&Cs</div>
                <div className="info" style={{ marginBottom: '15px' }}>
                    3. Mobile Indemnity Form
                </div>
                <Row gutter={20}>
                    <Col span={24}>
                        <PlacesField
                            form={form}
                            label={'Enter Document Pickup Location'}
                            name="gps"
                            initialValue={data?.documentPickUp?.gps}
                        />
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label="Prefred Pickup Date & Time"
                            name="preferredDateAndTime"
                            className="label"
                            rules={[{ required: true, message: 'Please select date!' }]}
                        >
                            <DatePicker showTime className="gapstack-input" disabledDate={disabledDate} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item label="Message/Instructions " name="instruction" className="label">
                            <Input.TextArea
                                className="gapstack-input"
                                autoSize={{ minRows: 4, maxRows: 6 }}
                                placeholder="Message/Instructions"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider style={{ width: '120%', marginLeft: '-20px' }} />
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => hideModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" loading={loading} htmlType="submit">
                        Request Pickup
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default PickUpModal;
