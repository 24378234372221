import { css } from 'styled-components';
import { COLORS } from '../../config/theme';
import editorStyles from '../../assets/styles/forms/editor-form';

const style = () => css`
    ${editorStyles}
    .read-only {
        pointer-events: none;
    }

    .gapstack-input {
        height: 45px;
    }
    .gapstack-input.number {
        width: 100%;
        .ant-input-number-input-wrap > input {
            height: 45px;
        }
    }

    .gapstack-input.auto {
        .ant-select-selector {
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 45px;
            }
            .ant-select-selection-placeholder {
                line-height: 45px;
            }
            .ant-select-selection-search-input {
                height: 45px;
            }
        }
    }
    .form-select {
        .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            & + .ant-select-arrow {
                width: unset;
                height: unset;
                top: 50%;
                margin-top: 0;
                transform: translateY(-30%);
            }
            .ant-select-selection-placeholder {
                line-height: 45px;
            }
            .ant-select-selection-search-input {
                height: 45px;
            }
        }
    }
`;

export default style();
