import React, { useImperativeHandle } from 'react';
import styled from 'styled-components';
import { Form, Modal, notification } from 'antd';
import style from './styles';
import EditorForm from './EditorForm';
import { CountryProvider } from './CountryInput';
import { authenticatedRequest } from '../../api';

const EditModalWrapper = styled(Modal)`
    ${style}
`;

interface PropType {
    data: { [key: string]: any };
    columnList?: any;
    selected?: string | null;
    onCancel: () => void;
    afterClosed: () => void;
    onSuccess?: (data: any) => any;
}

type RefType = {
    openModal?: (callback?: CallableFunction) => void;
    closeModal?: () => void;
};

function getErrorMessage(message?: string | Array<any>) {
    if (Array.isArray(message)) {
        return message[0]?.message;
    }
    return message;
}

// eslint-disable-next-line react/display-name
const DataEditModal = React.forwardRef<RefType, PropType>((props: PropType, ref) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const callbackRef = React.useRef<any>(null);

    const [form] = Form.useForm();

    const { selected, columnList, data, onSuccess } = props;

    const columnData = columnList[selected as string];

    const title = columnData?.title;
    const columns = columnData?.data;

    useImperativeHandle(
        ref,
        () => ({
            openModal: (callback) => {
                if (callbackRef.current) callbackRef.current = null;
                if (callback) callbackRef.current = callback;
                setVisible(true);
            },
            closeModal: () => {
                setVisible(false);
                setLoading(false);
            },
        }),
        [],
    );

    React.useEffect(() => {
        form.setFieldsValue(columnData?.responseMap?.(data, callbackRef.current));
    }, [columnData, data, form, selected, visible]);

    const submitRequest = React.useCallback(
        (_column, prevData, values) => {
            return new Promise((resolve, reject) => {
                const config = _column.getConfig ? _column.getConfig() : {};
                const API = _column.generateApi?.(_column.api, data) || _column.api;
                if (_column.method?.toUpperCase() === 'POST') {
                    authenticatedRequest(config).post(`${API}`, values).then(resolve).catch(reject);
                } else if (_column.method?.toUpperCase() === 'PATCH') {
                    authenticatedRequest(config).patch(`${API}${prevData.id}`, values).then(resolve).catch(reject);
                } else {
                    authenticatedRequest(config).put(`${API}${prevData.id}`, values).then(resolve).catch(reject);
                }
            });
        },
        [data],
    );

    const onFinish = React.useCallback(() => {
        form.validateFields()
            .then(async (values) => {
                setLoading(true);
                try {
                    const prevData = columnData?.responseMap?.(data, callbackRef.current);
                    const formData = columnData?.formDataMap ? columnData?.formDataMap(values) : values;
                    const res: any = await submitRequest(columnData, prevData, formData);
                    notification.success({
                        message: 'Success',
                        description: res?.data?.message || 'Successfully Updated',
                    });
                    setVisible(false);
                    onSuccess?.(res?.data);
                } catch (err: any) {
                    const resp = err.response;
                    notification.error({
                        message: 'Error',
                        description: getErrorMessage(resp?.data?.message) || 'Oops! An unexpected error occurred.',
                    });
                } finally {
                    setLoading(false);
                }
            })
            .catch();
    }, [columnData, data, submitRequest]);

    const afterClosedCalled = React.useCallback(() => {
        callbackRef.current = null;
        props.afterClosed?.();
        form.resetFields?.();
    }, []);

    if (!title || !columns) return null;

    const _renderForm = () => {
        return columnData.renderForm ? (
            columnData.renderForm(form)
        ) : (
            <CountryProvider>
                <EditorForm data={columns} form={form} />
            </CountryProvider>
        );
    };

    return (
        <EditModalWrapper
            width={'50vw'}
            style={{ minWidth: 800 }}
            visible={visible}
            title={title}
            okText="Save"
            okButtonProps={{ className: 'save-button' }}
            cancelButtonProps={{ className: 'cancel-button' }}
            maskClosable={false}
            destroyOnClose
            onCancel={props.onCancel}
            afterClose={afterClosedCalled}
            confirmLoading={loading}
            onOk={onFinish}
            {...columnData.modalProps}
        >
            <Form form={form} name={title} layout="vertical">
                {_renderForm()}
            </Form>
        </EditModalWrapper>
    );
});

export default DataEditModal;
