import {
    ON_SWITCH_MENU,
    READ_NOTIFICATION_FETCHED,
    UNREAD_NOTIFICATION_FETCHED,
    CLEAR_UNREAD_NOTIFICATION,
    NEW_NOTIFICATION,
    CLEAR_UNREAD_NOTIFICATIONS,
    SEEN_NOTIFICATION,
    DELETE_READ_NOTIFICATION,
    DELETE_UNREAD_NOTIFICATION,
} from '../types';

const onSwitchMenu = (menuType) => ({
    type: ON_SWITCH_MENU,
    menuType,
});
const onFetchReadNotifications = (readNotifications: any[]) => ({
    type: READ_NOTIFICATION_FETCHED,
    readNotifications,
});
const onFetchUnreadNotifications = (unreadNotifications: any[]) => ({
    type: UNREAD_NOTIFICATION_FETCHED,
    unreadNotifications,
});
const onNewNotification = (notification: any) => ({
    type: NEW_NOTIFICATION,
    notification,
});
const onClearUnReadNotifications = () => ({
    type: CLEAR_UNREAD_NOTIFICATIONS,
    notifications: [],
});

const onMarkReadNotification = (id) => ({
    type: CLEAR_UNREAD_NOTIFICATION,
    id,
});

const onDeleteReadNotification = (id) => ({
    type: DELETE_READ_NOTIFICATION,
    id,
});

const onDeleteUnreadNotification = (id) => ({
    type: DELETE_UNREAD_NOTIFICATION,
    id,
});

const onSeenNotifications = () => ({
    type: SEEN_NOTIFICATION,
    notificationSeen: true,
});

//Actions
const switchMenu = (menuType) => {
    return (dispatch) => {
        dispatch(onSwitchMenu(menuType));
    };
};

const fetchReadNotifications = (readNotifications: any[]) => {
    return (dispatch) => {
        dispatch(onFetchReadNotifications(readNotifications));
    };
};
const fetchUnreadNotifications = (unreadNotifications: any[]) => {
    return (dispatch) => {
        dispatch(onFetchUnreadNotifications(unreadNotifications));
    };
};
const newNotifications = (notification: any) => {
    return (dispatch) => {
        dispatch(onNewNotification(notification));
    };
};
const clearUnreadNotifications = () => {
    return (dispatch) => {
        dispatch(onClearUnReadNotifications());
    };
};
const seenNotifications = () => {
    return (dispatch) => {
        dispatch(onSeenNotifications());
    };
};

const readNotification = (id: any) => {
    return (dispatch) => {
        dispatch(onMarkReadNotification(id));
    };
};

const deleteReadNotification = (id: any) => {
    return (dispatch) => {
        dispatch(onDeleteReadNotification(id));
    };
};

const deleteUnreadNotification = (id: any) => {
    return (dispatch) => {
        dispatch(onDeleteUnreadNotification(id));
    };
};

export const authenticatedLayoutActions = {
    switchMenu,
    fetchReadNotifications,
    fetchUnreadNotifications,
    newNotifications,
    clearUnreadNotifications,
    seenNotifications,
    readNotification,
    deleteReadNotification,
    deleteUnreadNotification,
};
