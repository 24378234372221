import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { COMPONENTS } from '../router/components';
import { goToRoute, ROUTES } from '../router/urls';
import { useSelector } from 'react-redux';

import { Permissions } from '../router/permissions';
import { checkRoutePermission } from './checkRoutePermission';

interface Props extends RouteProps {
    routeName: string;
}

const AuthorizedRoute = ({ routeName, ...rest }: Props) => {
    const user = useSelector<any>(({ auth }) => auth.user);
    return (
        <Route
            exact
            key={routeName}
            path={ROUTES[routeName]}
            render={(props) => {
                const RoutePermission = Permissions[routeName];
                if (RoutePermission) {
                    const canAccess = checkRoutePermission(user, RoutePermission);
                    if (!canAccess) return <Redirect to={goToRoute(ROUTES['DASHBOARD'])} />;
                }
                const Component = COMPONENTS[routeName];
                return <Component {...props} />;
            }}
            {...rest}
        />
    );
};

export default AuthorizedRoute;
