import AppIcons from '../assets/app-icons';
import { getTheme } from './getTheme';

function appIconInternal() {
    try {
        let themeName = getTheme('default');

        if (AppIcons[themeName.concat('-secondary')]) {
            themeName = themeName.concat('-secondary');
        } else if (!AppIcons[themeName]) {
            themeName = 'default';
        }

        return AppIcons[themeName];
    } catch (e) {
        return import('../assets/app-icons/gapstack-logo.svg');
    }
}
export const secondaryAppIcon = appIconInternal();

export default secondaryAppIcon;
