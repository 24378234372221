export enum FundingType {
    FundMyPurchase = 'Finance Purchases',
    FundFromSales = 'Finance Sales',
    ImportLoan = 'Import Trade',
    ExportLoan = 'Export Trade',
}

export enum ServiceType {
    WorkingCapital = 'Working Capital',
    PurchaseOrder = 'PO Financing',
    InvoiceOrPo = 'PO/Invoice Financing',
    Distributor = 'Distributor/Retail Financing',
    Discounting = 'Discounting',
    ExportOrderOrInvoice = 'Export Orders/Invoice',
    ImportOrder = 'Import Orders',
    PostShipment = 'Post Shipment Financing',
    LCDiscounting = 'LC Discounting',
    BillsOnCollectionDiscounting = 'Bills on Collection Discounting',
}
