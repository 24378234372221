import React, { useEffect, useState } from 'react';
import { authenticatedRequest } from '../../../api';
import { get_banks } from '../../../components/business-accounts/api';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { titleCase } from '../../TitleCase';

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    hasFeedback?: boolean;
    onChange?: (e, formName) => void;
    valueItem?: 'id' | 'name';
    returnType?: 'id' | 'object';
    formItemClassName?: string;
    disabled?: boolean;
}

const { Option } = Select;

const BankWrapper = styled.div`
    ${style}
`;

const SelectBank = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    hasFeedback = false,
    onChange,
    valueItem = 'id',
    returnType = 'id',
    formItemClassName,
    disabled,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [banks, setBanks] = useState<any>([]);

    const fetchBanks = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_banks)
            .then((res) => {
                setLoading(false);
                setBanks(res.data.sort((a, b) => a.name > b.name));
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onBankChanged = (e, formItemName) => {
        const foundIndex = banks.findIndex((bank) => bank[valueItem] === e);

        if (returnType === 'id') {
            onChange ? onChange(e, formItemName) : null;
        } else {
            onChange ? onChange(banks[foundIndex], formItemName) : null;
        }
    };

    useEffect(() => {
        fetchBanks();
        // return () => {
        //     setBanks([]); // cleanup
        // };
    }, []);

    return (
        <BankWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className={formItemClassName || 'label'}
                rules={[{ required, message }]}
                initialValue={initialValue}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    loading={loading}
                    className="form-select select"
                    allowClear
                    showSearch
                    disabled={disabled}
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    defaultValue={initialValue}
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(e) => (onChange ? onBankChanged(e, formItemName) : null)}
                    placeholder="Select Banks"
                    data-testid="select_bank"
                >
                    {banks.map((bank) => (
                        <Option key={bank.id} value={bank[valueItem]}>
                            {titleCase(bank.name, ' ')}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </BankWrapper>
    );
};

export default SelectBank;
