import moment from 'moment';

const convertDate = (date: string) => {
    return moment(date).format('MMMM, DD YYYY');
};

const columns_list = [
    {
        key: '1',
        title: 'Date Created',
        dataIndex: 'createdAt',
        render: (val) => convertDate(val),
    },
    {
        key: '2',
        title: 'Business ID',
        dataIndex: 'businessId',
    },
    {
        key: '3',
        title: 'Business Name',
        dataIndex: 'businessName',
    },
    {
        key: '4',
        title: 'Contact Name',
        dataIndex: ['contacts', 0],
        render: (contact) => {
            if (contact === undefined) return '-';
            return contact?.firstName ? `${contact.firstName} ${contact.lastName}` : '-';
        },
    },
    {
        key: '5',
        title: 'Email',
        dataIndex: ['contacts', 0],
        render: (contact) => {
            if (contact === undefined) return '-';
            return contact?.email ? contact.email : '-';
        },
    },
    {
        key: '6',
        title: 'Phone',
        dataIndex: ['contacts', 0],
        render: (contact) => {
            if (contact === undefined) return '-';
            return (contact?.countryCode ? contact.countryCode : '') + contact?.phone;
        },
    },
];

const businessStatusMapper = (status: string) => {
    switch (status) {
        case 'sent-for-verification':
            return {
                color: '#6C7392',
                status: 'Sent For Verification',
            };
        case 'sent-for-approval':
            return {
                color: '#FE9902',
                status: 'Sent For Approval',
            };
        case 'in-progress':
            return {
                color: '#6C7392',
                status: 'In Progress',
            };
        case 'un-verified':
            return {
                color: '#6C7392',
                status: 'Unverified',
            };
        case 'on-hold':
            return {
                color: '#FE9902',
                status: 'On Hold',
            };
        case 'verified':
            return {
                color: '#2CCC71',
                status: 'Verified',
            };
        case 'returned-for-changes':
            return {
                color: '#E74C3C',
                status: 'Returned For Changes',
            };
        default:
            return {
                color: '#A3A3B4',
                status,
            };
    }
};
export enum BusinessStatuses {
    Unverified = 'UN-VERIFIED',
    SentForVerification = 'SENT-FOR-VERIFICATION',
    InProgress = 'IN-PROGRESS',
    OnHold = 'ON-HOLD',
    Verified = 'VERIFIED',
    ReturnedForChanges = 'RETURNED-FOR-CHANGES',
    SentForApproval = 'SENT-FOR-APPROVAL',
}
export const NormalStatuses = [
    BusinessStatuses.InProgress,
    BusinessStatuses.Unverified,
    BusinessStatuses.OnHold,
    BusinessStatuses.Verified,
    BusinessStatuses.ReturnedForChanges,
];

const businessSummaryData = (summary: any) => {
    return [
        { value: summary?.totalBusiness, description: 'All Businesses' },
        { value: summary?.verifiedBusiness, description: 'Verified Businesses' },
        { value: summary?.onHold, description: 'Businesses on hold' },
        { value: summary?.unVerified, description: 'Unverified Businesses' },
    ];
};

const filterOptions = [
    {
        name: 'Status',
        dataIndex: 'status',
        type: 'multiple',
        returnType: 'array',
        options: [
            {
                label: 'Unverified',
                value: BusinessStatuses.Unverified as string,
            },
            {
                label: 'In Progress',
                value: BusinessStatuses.InProgress as string,
            },
            {
                label: 'On Hold',
                value: BusinessStatuses.OnHold as string,
            },
            {
                label: 'Verified',
                value: BusinessStatuses.Verified as string,
            },
            {
                label: 'Returned For Changes',
                value: BusinessStatuses.ReturnedForChanges as string,
            },
            {
                label: 'Sent For Approval',
                value: BusinessStatuses.SentForApproval as string,
            },
        ],
    },
];

export { columns_list, businessStatusMapper, businessSummaryData, filterOptions };
