import { Card, Col, Form, Input, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import SelectBankAndTelco from '../../../../utils/FormSelects/SelectBanksAndTelco';
import SelectWalletAccountType from '../../../../utils/FormSelects/WalletAccountTypes';
import Loader from '../../../../utils/Loader';
import { create_wallet, update_wallet } from '../../../../apis/business-accounts';
import { getErrorsList, mapConfirmData } from '../../helpers/mapData';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;
interface PaymentsInformationProps {
    data: any;
    cardName?: string;
    errorData?: any;
}

const PaymentsInformation: FunctionComponent<PaymentsInformationProps> = ({
    data,
    cardName,
    errorData,
}: PaymentsInformationProps) => {
    const [group, setGroup] = useState<string>('bank');
    const [selectedWalletAccountType, setSelectedWalletAccountType] = useState<string>('PhoneNumber');
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const handleAccountTypeChange = (value) => {
        setSelectedWalletAccountType(value);
    };
    useEffect(() => {
        const wallet =
            (data?.bankWallets &&
                Array.isArray(data?.bankWallets) &&
                data?.bankWallets.length > 0 &&
                data?.bankWallets[0]) ||
            {};
        setGroup(wallet?.type);
        setSelectedWalletAccountType(wallet?.walletAccountType);
        data && form.setFieldsValue(mapConfirmData(data)?.['wallet']);
        errorData && form.setFields(getErrorsList(errorData?.wallet || {}));
        setLoading(false);
    }, [data]);

    const onComplete = () => {
        const values = form.getFieldsValue();
        let request: any;
        const id = values?.id;
        if (id) {
            const ob = { ...values };
            delete ob['id'];
            request = authenticatedRequest().put(update_wallet(id), ob);
        } else {
            request = authenticatedRequest().post(create_wallet, values);
        }
        request
            .then((res) => res.data)
            .then((res) => {
                const id = values?.id;
                if (!id) {
                    form.setFieldsValue({ id: res?.id });
                }
            })
            .catch((err) => {
                console.log(err);
                // const resp = err?.response;
                // notification.error({
                //     message: 'Error',
                //     description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                // });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);
    return (
        <BusinessProfileWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form name="wallet" form={form}>
                    <Card
                        title={cardName || 'Create Invoices, POs and Receive Payments'}
                        className="card-wrapper card-margin-top"
                    >
                        <Row gutter={20}>
                            <Form.Item name={'type'} initialValue={'bank'} hidden />
                            <Form.Item name={'id'} hidden />
                            <Col span={12}>
                                <SelectBankAndTelco
                                    formItemLabel="Select Bank / Telco"
                                    formItemName={[group == 'bank' ? 'bankId' : 'mobileProviderId']}
                                    required
                                    onChange={(value, __, group) => {
                                        setGroup(group);
                                        form.setFieldsValue({
                                            wallet: {
                                                type: group,
                                                [group == 'bank' ? 'bankId' : 'mobileProviderId']: value,
                                            },
                                        });
                                    }}
                                    message="Please select bank/telco type"
                                />
                            </Col>
                            {group == 'bank' ? (
                                <Col span={12}>
                                    <Form.Item
                                        label={'Account Number'}
                                        name={['bankAccountNumber']}
                                        className="label"
                                        rules={[{ required: true, message: 'Please input account number!' }]}
                                    >
                                        <Input className="gapstack-input" placeholder="9049034" />
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={12}>
                                    <SelectWalletAccountType
                                        formItemLabel="Select wallet type"
                                        formItemName={['walletAccountType']}
                                        required
                                        message="Please select an account type"
                                        initialValue={selectedWalletAccountType}
                                        onChange={handleAccountTypeChange}
                                    />
                                </Col>
                            )}
                        </Row>
                        {group != 'bank' && (
                            <Row gutter={20}>
                                {selectedWalletAccountType === 'PhoneNumber' ? (
                                    <Col span={12} style={{ marginBottom: '45px' }} className="disabled-phone-select">
                                        <PhoneNumber disableCountryCode={true} inputFieldName={['phone']} />
                                    </Col>
                                ) : selectedWalletAccountType === 'TillNumber' ? (
                                    <Col span={12}>
                                        <Form.Item
                                            label="Till Number"
                                            name={['mpesaTillNumber']}
                                            rules={[{ required: true, message: 'Please input the till number' }]}
                                        >
                                            <Input className="gapstack-input" />
                                        </Form.Item>
                                    </Col>
                                ) : selectedWalletAccountType === 'PayBill' ? (
                                    <Col span={12}>
                                        <Form.Item
                                            label="Pay Bill No."
                                            name={['mpesaBusinessNo']}
                                            rules={[{ required: true, message: 'Please input the till number' }]}
                                        >
                                            <Input className="gapstack-input" />
                                        </Form.Item>
                                    </Col>
                                ) : null}
                                {selectedWalletAccountType === 'PayBill' && (
                                    <Col span={12}>
                                        <Form.Item
                                            label="Account No."
                                            name={['mpesaAccountNo']}
                                            rules={[{ required: true, message: 'Please input the account number' }]}
                                        >
                                            <Input className="gapstack-input" />
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                        )}{' '}
                    </Card>
                </Form>
            )}
        </BusinessProfileWrapper>
    );
};

export default PaymentsInformation;
