import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const richTextEditorStyles = () => css`
    .ql-toolbar.ql-snow {
        border-radius: 5px 5px 0px 0px;
        border: 1px solid #e5e5e5;
    }

    .ql-container.ql-snow {
        border-radius: 0px 0px 5px 5px;
        border: 1px solid #e5e5e5;
    }

    .ql-container {
        min-height: 100px;
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
    }
`;

export default richTextEditorStyles;
