import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorker from './serviceWorker';
import './pollyfill';

Sentry.init({
    dsn: 'https://2a2f34597f044a9b92b06c9399e30151@o684265.ingest.sentry.io/5771470',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    beforeSend: (event) => {
        // Only send metrics and errors fir staging and production
        if (['development', 'test'].includes(process.env.NODE_ENV)) {
            return null;
        }
        return event;
    },
    enabled: process.env.NODE_ENV !== 'development',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
