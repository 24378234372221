import React from 'react';
import { Card, Table } from 'antd';
import styled from 'styled-components';
import { data } from './data';
import { Extra } from '../../data/card-header-data';
import { columnsData } from '../../data/table-columns-data';

const Card1Wrapper = styled.div`
    /* styles to be added later */
`;
const tableData: Array<any> = [
    {
        createdDate: '08/05/2018 11:21 AM',
        organization: 'Facebook',
        organizationType: 'Technology',
        connectionType: 'Technology',
        expiryDate: '07/30/2019 6:46 AM',
        lastUsed: '07/30/2019 6:46 AM',
        status: 'InActive',
    },
    {
        createdDate: '08/05/2018 11:21 AM',
        organization: 'Facebook',
        organizationType: 'Technology',
        connectionType: 'Technology',
        expiryDate: '07/30/2019 6:46 AM',
        lastUsed: '07/30/2019 6:46 AM',
        status: 'Active',
    },
];
const Card3 = () => {
    const [selectedRowKeys] = React.useState([]);
    const action = () => {
        // console.log(val);
    };
    const rowSelection = {
        selectedRowKeys,
    };
    return (
        <Card1Wrapper>
            {data.map((value) => (
                <div className="account-settings" key={value.id}>
                    <Card
                        title={value.card}
                        className={value.class}
                        bordered={false}
                        extra={<Extra name={value.card} onClick={action} />}
                    >
                        <div>
                            <Table
                                style={{ width: '100%', marginTop: 0 }}
                                rowSelection={rowSelection}
                                columns={columnsData(value.card)}
                                dataSource={tableData}
                                pagination={false}
                                className="datatable table-wrapper"
                            />
                        </div>
                    </Card>
                </div>
            ))}
        </Card1Wrapper>
    );
};
export default Card3;
