import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const purchaseStyles = () => css`
    .purchaseHead {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        margin: 18px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .purchase-order {
        .ant-table-wrapper {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border-radius: 5px;
            border-radius: 5px;
        }
        .datatable .ant-table-tbody > tr {
            background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
        }
        .datatable .ant-table-thead > tr > th {
            background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
            padding: 22px 40px !important;
            border-top: 1px solid #f0f0f0;
            width: 15%;
        }
        .ant-table-tbody > tr > td {
            border-bottom: none !important;
            padding: 22px 40px !important;
        }
        .ant-table tfoot > tr > td {
            padding: 10px 45px;
        }
        .ant-table tfoot > tr > td:last-child {
            padding: 10px 45px;
            text-align: right;
        }
        .summary {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .text-summary {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-table tfoot > tr > th,
        .ant-table tfoot > tr > td {
            border-bottom: none !important;
        }
        .ant-card-body {
            box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]} !important;
            border-radius: 5px;
            padding: 0px;
        }
        .main-heading {
            font-weight: 700;
            font-size: 1.9rem;
            line-height: 31px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .header {
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .main-header {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
    }
`;

export default purchaseStyles;
