import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

const Settings = Loadable({
    loader: () => import('./home' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const SettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <Settings {...props} />
    </AuthenticatedLayout>
);

const BusinessInformation = Loadable({
    loader: () => import('./business-settings/business-information' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const BusinessInformationComponent = (props: any) => (
    <AuthenticatedLayout>
        <BusinessInformation {...props} />
    </AuthenticatedLayout>
);

const AccountSettings = Loadable({
    loader: () => import('./business-settings/account-settings' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const AccountSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <AccountSettings {...props} />
    </AuthenticatedLayout>
);

const FinancialInformation = Loadable({
    loader: () => import('./business-settings/financial-information' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const FinancialInformationComponent = (props: any) => (
    <AuthenticatedLayout>
        <FinancialInformation {...props} />
    </AuthenticatedLayout>
);

const CreditReport = Loadable({
    loader: () => import('./business-settings/credit-report' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const CreditReportComponent = (props: any) => (
    <AuthenticatedLayout>
        <CreditReport {...props} />
    </AuthenticatedLayout>
);

const TermsAndConditions = Loadable({
    loader: () => import('./business-settings/terms-and-conditions' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const TermsAndConditionsComponent = (props: any) => (
    <AuthenticatedLayout>
        <TermsAndConditions {...props} />
    </AuthenticatedLayout>
);

const TeamSettings = Loadable({
    loader: () => import('./team-security-settings/team-settings' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const TeamSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <TeamSettings {...props} />
    </AuthenticatedLayout>
);

const SecurityHistory = Loadable({
    loader: () => import('./team-security-settings/security-history' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const SecurityHistoryComponent = (props: any) => (
    <AuthenticatedLayout>
        <SecurityHistory {...props} />
    </AuthenticatedLayout>
);

const ConnectedApplications = Loadable({
    loader: () => import('./team-security-settings/connected-applications' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const ConnectedApplicationsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ConnectedApplications {...props} />
    </AuthenticatedLayout>
);

const BrandSettings = Loadable({
    loader: () => import('./business-settings/brand-settings/BrandElements' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const BrandSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <BrandSettings {...props} />
    </AuthenticatedLayout>
);
export {
    SettingsComponent,
    BusinessInformationComponent,
    AccountSettingsComponent,
    FinancialInformationComponent,
    CreditReportComponent,
    TermsAndConditionsComponent,
    TeamSettingsComponent,
    SecurityHistoryComponent,
    ConnectedApplicationsComponent,
    BrandSettingsComponent,
    Settings,
};
