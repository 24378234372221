import React from 'react';
import { Trash2, Edit } from 'react-feather';
import refresh from '../../../assets/Images/refresh.svg';
import AuthorizationWrapper from '../../../Authorization/AuthorizationWrapper';

interface StatusProps {
    onClick: (any) => void;
    name: string;
}

const Status: React.FC<StatusProps> = ({ onClick, name }: StatusProps) => {
    switch (name) {
        case 'General API Keys':
            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={onClick} className="status">
                        <img src={refresh} alt="" className="edit-icon" style={{ color: '#1890ff', top: 0 }} />
                        <span style={{ color: '#6C7392', marginRight: 12 }}>Update</span>
                    </div>

                    <AuthorizationWrapper restrictedRoles={['Developer', 'View', 'Analyst']}>
                        <div onClick={onClick} className="status-border">
                            <Edit size="14" className="edit-icon" style={{ color: '#0B95FA' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Edit</span>
                        </div>
                        <div onClick={onClick} className="status-border">
                            <Trash2 size="14" className="edit-icon" style={{ color: '#E74C3C' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Delete</span>
                        </div>
                    </AuthorizationWrapper>
                </div>
            );
        case 'Restricted API Keys':
            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={onClick} className="status">
                        <img src={refresh} alt="" className="edit-icon" style={{ color: '#1890ff', top: 0 }} />
                        <span style={{ color: '#6C7392', marginRight: 12 }}>Update</span>
                    </div>
                    <AuthorizationWrapper restrictedRoles={['Developer', 'View', 'Analyst']}>
                        <div onClick={onClick} className="status-border">
                            <Edit size="14" className="edit-icon" style={{ color: '#0B95FA' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Edit</span>
                        </div>
                        <div onClick={onClick} className="status-border">
                            <Trash2 size="14" className="edit-icon" style={{ color: '#E74C3C' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Delete</span>
                        </div>
                    </AuthorizationWrapper>
                </div>
            );
        case '3rd Party API Keys':
            return (
                <div style={{ display: 'flex' }}>
                    <div onClick={onClick} className="status">
                        <img src={refresh} alt="" className="edit-icon" style={{ color: '#1890ff', top: 0 }} />
                        <span style={{ color: '#6C7392', marginRight: 12 }}>Update</span>
                    </div>

                    <AuthorizationWrapper restrictedRoles={['Developer', 'View', 'Analyst']}>
                        <div onClick={onClick} className="status-border">
                            <Edit size="14" className="edit-icon" style={{ color: '#0B95FA' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Edit</span>
                        </div>
                        <div onClick={onClick} className="status-border">
                            <Trash2 size="14" className="edit-icon" style={{ color: '#E74C3C' }} />
                            <span style={{ color: '#6C7392', marginRight: 12 }}>Delete</span>
                        </div>
                    </AuthorizationWrapper>
                </div>
            );
        default:
            return null;
    }
};

export { Status };
