import { createGlobalStyle } from 'styled-components';
import { COLORS, DEVICE } from '../../config/theme';
import { Constant } from '../../config';
import globalButton from './forms/button';
import globalInput from './forms/input';
import globalTable from './table/table';
import globalSelect from './forms/select';
import modalStyles from './modal/modalStyles';
import paginationStyle from './table/pagination';
import formItemStyles from './forms/form-item';
import globalList from './extras/listStyles';
import topBarStyles from './extras/top-bar';
import purchaseStyles from './table/purchase-order';
import richTextEditorStyles from './forms/rich-text-editor';

const GlobalStyle = createGlobalStyle`
${globalButton}
${globalInput}
${globalTable}
${globalSelect}
${modalStyles}
${paginationStyle}
${formItemStyles}
${globalList}
${topBarStyles}
${purchaseStyles}
${richTextEditorStyles}
    body {
        user-select: none;
    }
    .top-bar,
    .logo-container {
        img,
        .logo {
            height: ${Constant.logoHeight};
        }
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 14px 15px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        top: 50%;
        padding: 0;
        width: 15px;
        color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        height: 100%;
    }


    .ant-layout {
        background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    }
    .ant-layout-sider {
        background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    }
    .ant-layout-header {
        background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
        padding: 0;
    }
    


    .gapstack-divider {
        min-width: 15%;
        width: 15%;
        border-bottom: 4px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
        border-radius: 2px;
    }

    label {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PARAGRAPH]} !important;
        margin-bottom: 10px !important;
    }

    .ant-picker {
        width: 100%;
    }

    .heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 37px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        display: flex;
        justify-content: center;
    }
    .sub-heading {
        font-size: 14px;
        line-height: 22px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        display: flex;
        justify-content: center;
    }
    .ant-breadcrumb {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.SECONDARY]} !important;
    }

    span.ant-radio + * {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        position: relative;
        top: 1px;
    }

    .circle-rh {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }

    .ant-breadcrumb > span:last-child {
        color: ${(props: any) => props.theme[COLORS.PRIMARY]} !important;
    }

    .feather-icon {
        position: relative;
        top: 3px;
    }

    .ant-drawer {
        transition: transform 0.4s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
    }
    .ant-drawer > * {
        transition: transform 0.4s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.6s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
    }
    .ant-drawer-open {
        transition: transform 0.4s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.6s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
    }
    .active {
        transition: transform 0.3s cubic-bezier(0.5, 0.14, 0.15, 0.86) 0s,
            width 0.3s cubic-bezier(0.5, 0.14, 0.15, 0.86) 0s !important;
    }

    .purchase-invoice-container {
        top: 10%;
        left: 25%;
        //right: 40%;
        width: 50%;
        text-align: center;
        position: absolute;
        text-align: center;
        transform: rotate(-30deg);
        z-index: 10;
        .purchase-invoice-status {
            color: ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
            //font-family: OldStamper;
            font-size: 2.2rem;
            margin-bottom: -14px;
        }
        .date {
            color: ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
            font-weight: 600;
            font-size: 15px;
        }
    }

    .map {
        width: 100%;
        height: 60vh;
        border-radius: 5px;
        margin: 0px 0px 20px;
        overflow: hidden;
    }
    .marker {
        width: 20px;
        height: 40px;
        margin-top: -40px;
        margin-left: -10px;
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
    }

    .ant-popover-inner {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
    .ant-popover-arrow {
        display: block;
    }
    .ant-popover-inner-content {
        padding: 15px;
    }

    .popup-icon {
        position: relative;
        top: 6px;
        left: 10px;
    }

    .date-range-container {
        display: flex;
        flex-direction: row;
        height: 35px;
        border: 0.5px solid ${(props: any) => props.theme[COLORS.BORDER]};
        border-radius: 5px;
        .date-text {
            display: flex;
            justify-content: center;
            padding-right: 10px;
            padding-left: 10px;
            align-items: center;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-size: 13px;
        }
    }

    .ant-spin-nested-loading {
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
        padding: 24px;
    }

    .ai-wrapper {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        .message-box {
            width: 14px;
            height: 14px;
            color: ${(props: any) => props.theme[COLORS.ICONS]};
        }
        .dot {
            width: 10px;
            height: 10px;
            min-width: 10px;
            min-height: 10px;
            max-width: 10px;
            max-height: 10px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.AI_VALIDATION]};
        }
    }
    .row-dragging {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        td {
            flex: 1;
        }
        td:first-child {
            max-width: 0px;
        }
        td:nth-child(2) {
            max-width: 30px;
        }
        td:last-child {
            max-width: 70px;
        }
    }
    .submit-docs-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 25px;
        .docs-heading {
            font-size: 18px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-weight: bold;
        }
        .docs-image {
            width: 40%;
            height: auto;
            margin-top: 15px;
        }
        .document-details {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 10px;
            .document-text {
                font-size: 14px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
    }
    .primary-loader{
        color: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }
    .a4 {
        width: 100%;
        @media ${DEVICE.laptop} {
            width: 22cm;
        }
        @media ${DEVICE.laptopL} {
            width: 25cm;
        }
        @media ${DEVICE.desktop} {
            width: 25cm;
        }
    }

    .account-settings {
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        margin-top: 20px;
        background: #fff;
        .add-icon {
            width: 20px;
            position: relative;
            top: -2px;
            right: 4px;
        }
        .edit-icon {
            position: relative;
            top: 2px;
            right: 5px;
        }
        .ant-list-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;
          border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
          .list-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
          }
          .list-action {
            button + button {
             margin-left: 10px;
            }
          }
        }
        .ant-list-item:last-child {
            padding: 15px;
            border-bottom: none !important;
        }
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-extra {
            padding: 0 !important;
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-card-head {
            padding: 0 15px !important;
        }
        .ant-card-body {
            padding: 0 !important;
            border-radius: 5px;
        }
        .normal-row {
            padding: 7px 25px;
        }
        .full-row {
            padding: 0 5px;
        }
        .full-row-table {
            padding: 0 15px;
        }
        .full-margin .ant-card-body {
            margin: 0px;
        }
        
        .section .ant-card-body {
            background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
        }
        .full-row-margin {
            margin: 12px 12px 0 12px;
        }
        .half-row-margin {
            margin: 0 12px;
        }
        .half-bottom {
            padding: 0 12px 12px 16px;
        }
        .col-wrapper {
            display: flex;
            flex-direction: row;
            margin: 5px 0;
        }
        .col-right {
            padding-left: 15px;
            margin: 5px 0;
        }
        .col-title {
            font-weight: 500;
            font-size: 13px;
            min-width: 160px;
            margin-right: 5px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .col-value {
            font-weight: 400;
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
        .products-desc {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            padding: 18px;
            border-top: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            align-items: center;
            .product-image {
                width: 75px;
                height: 75px;
                border-radius: 5px;
                margin: 0px 7px;
            }
            .heading {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                margin-left: 3px;
            }
        }
        .actions {
            height: 35px;
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border: none;
            box-sizing: border-box;
            box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
            border-radius: 5px;
            padding: 8px 20px;
            text-align: center;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            cursor: pointer;
            img {
                position: relative;
                top: -2px;
                right: 8px;
            }
        }
    .line {
        border-left: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }

    .full-margin .ant-card-body {
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
    }

    .full-margin-new {
        margin: 8px;
    }
    .col-title2 {
        font-weight: 500;
        font-size: 13px;
        min-width: 430px;
        margin-right: 5px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }
    .pending-status {
        color: #fe9902;
    }
    .line1 {
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }
    .line2 {
        border-top: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }

    .header-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 20px 0px 0px;

        .icons-header {
            width: 15px;
            height: 15px;
            cursor: pointer;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
            margin-right: 10px;
        }

        .del {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
        }

        .line-sep {
            width: 1px;
            height: 15px;
            margin-right: 10px;
            background-color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
        .action-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
        }
    }

    .supplier-data {
        height: 120px;
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
        border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        text-align: center;
        padding: 18px;
        h4 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
        }
        span {
            font-weight: 600;
            font-size: 44px;
            line-height: 28px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .danger {
            color: ${(props: any) => props.theme[COLORS.DECLINED]} !important;
        }
    }
    .financing-data {
        height: 71px;
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
        border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 18px;
        display: flex;
        justify-content: center;
        h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 31px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 31px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            margin-left: 10px;
        }
    }
    .ant-table-thead > tr > th {
        padding: 10px 8px !important;
        line-height: 18px !important;
        border-top: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }
    .ant-table-tbody > tr > td {
        padding: 10px 8px !important;
        line-height: 18px !important;
    }
    .datatable .ant-table-tbody > tr:nth-child(even) {
        background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
    }
    .ant-checkbox-wrapper {
        margin-bottom: 0px !important;
    }
    .circle-rh {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin: 5px 5px 0 0;
    }
    .status {
        min-width: 90px;
    }
    .plus-icon {
        width: 15px;
        position: relative;
        top: 1px;
        right: 5px;
    }
    .status-border {
        border-left: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        padding-left: 10px;
        min-width: 90px;
    }
    .space {
        padding-bottom: 10px;
    }
    .card-background {
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
    }
    .last-border {
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }
    .datatable {
        box-shadow: none !important;
        margin-top: none !important;
    }
`;

export default GlobalStyle;
