import React from 'react';
import { BuyerCentralComponent, ViewBuyerComponent } from '../../../../components/sales/buyer-central';

const ValidatedBuyers = () => {
    return <BuyerCentralComponent isValidated={true} />;
};

const ViewValidatedBuyers = () => {
    return <ViewBuyerComponent isValidated={true} />;
};
export { ValidatedBuyers, ViewValidatedBuyers };
