import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';

const DashboardLoadable = Loadable({
    loader: () => import('../index'),
    loading() {
        return <Loader />;
    },
});

export default DashboardLoadable;
