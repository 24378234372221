import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Button, Spin, notification } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import help from '../../../assets/Images/help.svg';
import { Link } from 'react-router-dom';
import style from './styles';
import { LoadingOutlined } from '@ant-design/icons';
import { unAuthenticatedRequest } from '../../../api';
import { ROUTES } from '../../../router/urls';
import UnauthenticatedLayout from '../../../components/Layouts/Unauthenticated';
import { RiErrorWarningLine } from 'react-icons/ri';
import { appIcon } from '../../../config/appIcon';
const AcceptBusinessInviteWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ fontSize: 50, color: '#7C60FF' }} spin />;

const AcceptInvitation: React.FC = () => {
    const history = useHistory();
    const { token, userId } = useParams<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [heading, setHeading] = useState<string>('Accepting invitation!');
    const [description, setDescription] = useState<string>('This will only take a short while.');

    const acceptInvite = () => {
        setLoading(true);
        unAuthenticatedRequest()
            .post('/bank-authentication/verify/account', { token, userId })
            .then((res) => {
                if (res?.data?.message) {
                    notification.success({
                        description: 'Success',
                        message: 'Invitation accepted successfully. Redirecting to complete sign up',
                    });
                    const data = {
                        email: res.data.data.email,
                        id: userId,
                    };
                    setTimeout(() => {
                        history.push({
                            pathname: ROUTES.COMPLETE_REGISTRATION,
                            state: { user: data },
                        });
                    }, 2000);
                    return;
                }
                setLoading(false);
                setHeading('Error');
                setDescription(res?.data?.message);
            })
            .catch((error) => {
                setLoading(false);
                // on error show the error image apart from the loading one
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        acceptInvite();
    }, []);

    return (
        <UnauthenticatedLayout>
            <AcceptBusinessInviteWrapper>
                <div className="confirm-wrapper">
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>
                    <div className="container">
                        <Row
                            style={{
                                padding: '0px 85px',
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            {loading ? (
                                <Spin style={{ marginTop: 20 }} indicator={antIcon} />
                            ) : (
                                <RiErrorWarningLine size={50} color="red" />
                            )}
                        </Row>

                        <Row
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                            }}
                        >
                            <h3 className="heading">{heading}</h3>
                        </Row>
                        <Row
                            style={{
                                flex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 10,
                            }}
                        >
                            <p className="sub-heading">{description}</p>
                        </Row>
                    </div>
                </div>
            </AcceptBusinessInviteWrapper>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </UnauthenticatedLayout>
    );
};

export default AcceptInvitation;
