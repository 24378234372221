import { Button, Card, Col, Form, FormInstance, notification, Row } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import style from './styles';
import { DocumentPageType, DocumentNameAndType } from './types';
import InviteDirectorModal from './components/InviteDirectorModal';
import { authenticatedRequest } from '../../../../api';
import { cancel_invite_director, delete_director, resend_invite_director } from '../../../../apis/business-accounts';

import SingleDirector from './components/SIngleDirector';
import add_icon from '../../../../assets/icons/Svg/add-row.svg';
import AddDirectorModal from './components/AddDirector';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const BusinessDocumentsWrapper = styled.div`
    ${style}
`;
interface DirectorProps {
    directors: any[];
    setDirectors: (val: any[]) => void;
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType,
        pageType: DocumentPageType,
        fileId: string,
        documentLabel?: string,
        docType?: string,
    ) => void;
    errorObj: any[];
    sectionErrorObj: any;
    activeData?: any;
    form?: FormInstance;
    businessType: string;
    docsArray: any[];
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType,
        documentLabel?: string,
    ) => void;
    documentErrObj: any;
}

const Directors: FunctionComponent<DirectorProps> = ({
    directors,
    setDirectors,
    uploadFilesToBusinessAccount,
    errorObj,
    activeData,
    form,
    businessType,
    sectionErrorObj,
    handleErrorFromDocsManager,
    documentErrObj,
}: DirectorProps) => {
    const [directorModal, setDirectorModal] = useState<boolean>(false);
    const [addDirectorModal, setAddDirectorModal] = useState<boolean>(false);
    const [inviteDirectorData, setInviteData] = useState<any>({});

    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };
    const cancelInvite = (id, index, name = '') => {
        authenticatedRequest()
            .put(cancel_invite_director(id))
            .then(() => {
                const tempArr = [...directors];
                tempArr[index] = { ...tempArr[index], inviteId: undefined, email: undefined, firstName: undefined };
                setDirectors([...tempArr]);
                notification.success({
                    message: 'Success',
                    description: 'The invite link is cancelled for ' + name,
                });
            })
            .catch((err) => catchErr(err));
    };

    const resendInvite = (id, name = '', index) => {
        authenticatedRequest()
            .put(resend_invite_director(id))
            .then((res) => res.data)
            .then(() => {
                const tempArr = [...directors];
                tempArr[index] = { ...tempArr[index], status: 'Pending' };
                setDirectors([...tempArr]);
                notification.success({
                    message: 'Success',
                    description: 'A new invitation is sent to ' + name + ' for uploading their personal details',
                });
            })
            .catch((err) => catchErr(err));
    };

    const deleteDirector = (director, index) => {
        if (director?.documentId) {
            authenticatedRequest()
                .delete(delete_director(director?.documentId))
                .then((res) => res.data)
                .then(() => {
                    const tempDirs = form?.getFieldValue('directors');
                    if (Array.isArray(tempDirs) && tempDirs[index]) {
                        tempDirs.splice(index, 1);
                        form?.setFieldsValue({ directors: [...tempDirs] });
                    }
                })
                .catch((err) => {
                    catchErr(err);
                });
        } else {
            const tempDirs = form?.getFieldValue('directors');
            if (Array.isArray(tempDirs) && tempDirs[index]) {
                tempDirs.splice(index, 1);
                form?.setFieldsValue({ directors: [...tempDirs] });
            }
        }
    };

    return (
        <BusinessDocumentsWrapper>
            <Card
                title={
                    businessType == 'Company' ? 'Directors' : businessType == 'Partnership' ? 'Partners' : 'Individual'
                }
                className="card-wrapper  card-margin-top"
            >
                {sectionErrorObj['DIRECTOR'] && (
                    <div className="section-err-message">{sectionErrorObj['DIRECTOR']?.error ?? '-'}</div>
                )}
                <div className="card-description-wrapper">
                    <div className="card-description">
                        {businessType == 'Company'
                            ? `Upload the front and back IDs/Passports/Alien IDs of the business directors.`
                            : businessType == 'Partnership'
                            ? `Upload the front and back IDs/Passports/Alien IDs of the business partners.`
                            : `Upload the front and back of National Id.`}
                    </div>
                    <AiOutlineInfoCircle size={16} className="info-icon" />
                </div>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.List name="directors" initialValue={directors}>
                            {(fields) => (
                                <>
                                    {fields.map((field, index) => {
                                        return (
                                            <SingleDirector
                                                key={field.key}
                                                index={index}
                                                director={directors[index]}
                                                setDirectorModal={setDirectorModal}
                                                setDirectors={setDirectors}
                                                setInviteData={setInviteData}
                                                directors={directors}
                                                field={field}
                                                uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                                                resendInvite={resendInvite}
                                                cancelInvite={cancelInvite}
                                                errorObj={errorObj}
                                                deleteDirector={deleteDirector}
                                                form={form}
                                                businessType={activeData?.businessType?.name || 'Individual'}
                                                handleErrorFromDocsManager={handleErrorFromDocsManager}
                                                documentErrObj={documentErrObj}
                                                disabled={activeData?.status == BusinessStatuses.OnHold}
                                            />
                                        );
                                    })}
                                    {!(businessType == 'Company' || businessType == 'Partnership') &&
                                    directors.length > 0 ? null : (
                                        <Button
                                            className="add-button"
                                            style={{ marginLeft: 0 }}
                                            onClick={() => setAddDirectorModal(true)}
                                            disabled={activeData?.status == BusinessStatuses.OnHold}
                                        >
                                            <img src={add_icon} alt="add" className="plus-circle" />
                                            <span className="add-tier">
                                                Add{' '}
                                                {businessType == 'Company'
                                                    ? 'director'
                                                    : businessType == 'Partnership'
                                                    ? 'partner'
                                                    : 'National ID'}
                                            </span>
                                        </Button>
                                    )}
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>
            </Card>
            {directorModal && (
                <InviteDirectorModal
                    showModal={directorModal}
                    hideModal={() => setDirectorModal(false)}
                    inviteDirectorData={inviteDirectorData}
                    activeData={activeData}
                    setDirectors={setDirectors}
                    directors={directors}
                    businessType={activeData?.businessType?.name || 'Individual'}
                />
            )}
            {addDirectorModal && (
                <AddDirectorModal
                    showModal={addDirectorModal}
                    hideModal={() => setAddDirectorModal(false)}
                    setDirectors={setDirectors}
                    directors={directors}
                    businessType={activeData?.businessType?.name || 'Individual'}
                />
            )}
        </BusinessDocumentsWrapper>
    );
};

export default Directors;
