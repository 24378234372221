const prefix = process.env.NODE_ENV === 'development' ? 'business-accounts' : 'business-accounts';

export const post_contact_information = `${prefix}/business/contacts`;
export const get_contact_information = `${prefix}/business/contacts`;
export const get_business_information = `${prefix}/business/account`;
export const put_business_information = `${prefix}/business/account`;
export const post_business_brand_information = `${prefix}/business/brandElement`;
export const get_business_brand_information = `${prefix}/business/brandElement`;
export const post_business_mobile_indemnity = `${prefix}/business/mobile-indemnity`;
export const get_business_mobile_indemnity = `${prefix}/business/mobile-indemnity`;
export const post_business_director = `${prefix}/business/directors`;
export const get_business_directors = `${prefix}/business/directors`;
export const post_business_financial = `${prefix}/business/financials`;
export const get_business_financial = `${prefix}/business/financials`;
export const post_business_certificates = `${prefix}/business/documents`;
export const get_business_certificates = `${prefix}/business/documents/`;
export const get_business_wallets = `${prefix}/business/wallets`;
export const get_banks = `${prefix}/banks`;
export const get_industries = `${prefix}/industries`;
export const post_bank_or_wallet_information = `${prefix}/business/wallets`;
export const edit_bank_or_wallet_information = `${prefix}/business/wallets`;
export const get_terms_and_conditions = `${prefix}/business/documents/terms-and-conditions`;
export const post_business_terms_and_conditions = `${prefix}/business/documents/terms-and-conditions`;
export const send_KYC_for_verification = `${prefix}/business/account/send-for-verification`;
