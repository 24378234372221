import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .card-wrapper {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07) !important;
        border-radius: 5px !important;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]} !important;
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-head {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-card-body {
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .doodle {
            width: 250px;
            height: 150px;
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .info {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-bottom: 5px;
        }
        .statuses-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 90%;
            margin-top: 20px;
            column-gap: 10px;
            .status-wrapper {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                overflow: hidden;
                .background-line {
                    width: 100%;
                    height: 5px;
                    background: ${(props: any) => props.theme[COLORS.BORDER]};
                    border-radius: 3px;
                }
                .progress-line {
                    width: 0%;
                    height: 5px;
                    background: transparent;
                    border-radius: 3px;
                    margin-top: -5px;
                    margin-bottom: 10px;

                    &.progress {
                        width: 0%;

                        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                        align-self: flex-start;
                        position: relative;
                        overflow: hidden;
                    }
                    &.active {
                        animation: progress 2s ease infinite;
                    }
                    &.complete {
                        width: 100%;

                        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                    }
                }
                .verification-status {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                    &.active {
                        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                    }
                }
            }
        }
    }
    .card-margin-top {
        margin-top: 20px;
    }

    @keyframes progress {
        0% {
            width: 0%;
        }
        50% {
            width: 100%;
        }
        99% {
            width: 0%;
            background: #e5e5e5;
        }
        100% {
            width: 0%;
        }
    }
`;

export default style();
