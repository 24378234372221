import React, { useEffect } from 'react';
import SinglePagePDF from '../../PDFViewer/SinglePage';

interface Props {
    url: string;
}
const ViewUploadedDoc = ({ url }: Props) => {
    const [fileType, setFileType] = React.useState(null);
    const getDocumentTypeFromUrl = (url) => {
        setFileType(url.split('.').pop().split(/\#|\?/)[0].toLowerCase());
    };
    useEffect(() => {
        if (url) {
            getDocumentTypeFromUrl(url);
        }
    }, [url]);

    if (fileType === 'pdf') {
        return <SinglePagePDF url={url} />;
    }

    if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'svg') {
        return (
            <div style={{ width: '100%', marginTop: 10 }}>
                <img
                    src={`${process.env.REACT_APP_API_BASE_URL}/document-manager${url}`}
                    alt="document"
                    style={{ maxWidth: 150, height: 'auto' }}
                />
            </div>
        );
    }

    return null;
};
const ViewUploadedDocument = React.memo(ViewUploadedDoc);
export default ViewUploadedDocument;
