import { Card, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../../api';
import { get_invoice } from '../../../../../apis/invoices';
import Loader from '../../../../../utils/Loader';
import { PageBreadcrumb } from '../../../../../utils/PageBreadcrumb';
import AuthenticatedLayout from '../../../../Layouts/Authenticated';
import ContentLayout from '../../../../Layouts/Content';
import SingleUploadComponent from '../../add-new/upload-invoices/single-upload';
import styles from './styles';
const InvoiceWrapper = styled.div`
    ${styles}
`;

export const EditSingleUploadedInvoice = () => {
    const [loading, setFetchingInvoice] = useState<boolean>(false);
    const [invoice, setInvoice] = useState<any>({});
    const { id }: any = useParams();
    const getInvoice = () => {
        setFetchingInvoice(true);
        authenticatedRequest()
            .get(get_invoice(id))
            .then((res) => {
                setInvoice(res.data);
                setFetchingInvoice(false);
            })
            .catch((error) => {
                setFetchingInvoice(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        getInvoice();
    }, []);

    return (
        <AuthenticatedLayout isContentLayout>
            <InvoiceWrapper>
                <div className="container">
                    <PageBreadcrumb closePath={'SALES_INVOICES'}>
                        <PageBreadcrumb.Item title={'Edit Invoice'} />
                    </PageBreadcrumb>
                    {loading ? (
                        <Loader size={25} height="300px" />
                    ) : (
                        <ContentLayout>
                            <Card className="card-wrapper">
                                <SingleUploadComponent invoice={invoice} />
                            </Card>
                        </ContentLayout>
                    )}
                </div>
            </InvoiceWrapper>
        </AuthenticatedLayout>
    );
};
