import { Button, Card, Col, Row, Select } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import style from './styles';

import Timer, { VideoElement } from './components/livenessHelper';
import { authenticatedRequest, unAuthenticatedRequest } from '../../../../api';
import { document_manager_url, upload_url_without_base, verify_live_image } from '../../../../apis/uploads';
import { DocumentNameAndType, DocumentPageType } from './types';
import { ChevronDown } from 'react-feather';

const LivenessDetectionWrapper = styled.div`
    ${style}
`;

interface LivenessDetectionProps {
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType,
        fileId: string,
        documentLabel?: string,
    ) => void;
    directors: any[];
    activeData: any;
    documents: any;
}

const TimerButton = ({ startCamera }: any) => {
    return <Timer initialSeconds={6} onComplete={() => startCamera()} />;
};

const LivenessDetection: FunctionComponent<LivenessDetectionProps> = ({
    uploadFilesToBusinessAccount,
    directors,
    activeData,
    documents,
}: LivenessDetectionProps) => {
    const [openCamera, setOpenCamera] = useState<string>('initial');
    const [imageBlob, setImageBlob] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [livenessResponse, setLivenessResponse] = useState<any>({});
    const [startTimer, setStartTimer] = useState<boolean>(false);
    const [activeDirector, setActiveDirector] = useState<any>();

    const isCompany = activeData?.businessType?.name == 'Company';
    const options: any[] = directors
        .map((director, index) => {
            if (!director?.inviteId && director?.status != 'Completed') {
                return { value: director?.name, label: director?.name, id: index };
            }
            return null;
        })
        .filter((val) => val != null);

    useEffect(() => {
        if (options.length > 0 && !activeDirector) {
            setActiveDirector(options[0]?.value);
        }
    }, [directors]);

    const uploadToDocumentManager = async () => {
        setLoading(true);
        const formData = new FormData();
        const blob = await fetch(imageBlob).then((r) => r.blob());
        formData.append('document', blob, 'out.gif');

        authenticatedRequest()
            .post(upload_url_without_base, formData)
            .then((res) => res.data)
            .then((resDoc) => {
                unAuthenticatedRequest({}, false)
                    .post(verify_live_image, {
                        documentType: 'facelivedetect',
                        documentURL: document_manager_url(resDoc?.downloadURl),
                    })
                    .then((res) => res.data)
                    .then((res) => {
                        setLivenessResponse(res);
                        if (res?.facelivedetect) {
                            uploadFilesToBusinessAccount(
                                resDoc?.documentId,
                                resDoc?.downloadURl,
                                DocumentNameAndType.LIVE_ID,
                                DocumentPageType.STANDARD,
                                resDoc?.fileId,
                                activeDirector,
                            );
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const changeStatus = () => {
        if (openCamera == 'start') {
            setOpenCamera('timer');
            return;
        }
        if (openCamera == 'timer') {
            setOpenCamera('retry');
            setStartTimer(false);
            return;
        }
        if (openCamera == 'retry') {
            setOpenCamera('retry-initial');
            return;
        }
        if (openCamera == 'initial' || openCamera == 'retry-initial') {
            setOpenCamera('start');
            return;
        }
    };

    useEffect(() => {
        if (documents[`${activeDirector}_${DocumentNameAndType.LIVE_ID}`]) {
            setOpenCamera('retry');
            setLivenessResponse({ facelivedetect: true, headposemsgs: 'Verified' });
        } else {
            setOpenCamera('initial');
            setLivenessResponse({});
        }
    }, [activeDirector]);

    return (
        <LivenessDetectionWrapper>
            <Card title="Liveness Detection" className="card-wrapper  card-margin-top">
                {isCompany && options.length > 0 && (
                    <Row gutter={20} style={{ marginBottom: 10 }}>
                        <Col span={12}>
                            <div className="card-description-wrapper" style={{ marginBottom: 10 }}>
                                <div className="card-description">Select director</div>
                            </div>
                            <Select
                                options={options}
                                value={activeDirector}
                                onChange={(value) => setActiveDirector(value)}
                                className="select"
                                suffixIcon={<ChevronDown className="select-icon" />}
                            />
                        </Col>
                    </Row>
                )}
                {options.length == 0 ? (
                    <div className="card-description-wrapper" style={{ marginBottom: 10 }}>
                        <div className="card-description">
                            Please add {`${isCompany ? 'a director' : 'an individual'}`} to continue with liveness
                            detection.
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="card-description-wrapper" style={{ marginBottom: 10 }}>
                            <div className="card-description">To scan your face, please follow these instructions.</div>
                        </div>
                        <ul className="instructions-list">
                            <li className="card-description">
                                Please turn your head from left to right then tilt your head to the left then to the
                                right..
                            </li>
                        </ul>
                        <VideoElement
                            livenessResponse={livenessResponse}
                            imageBlob={imageBlob}
                            setImageBlob={setImageBlob}
                            openCamera={openCamera}
                            setLivenessResponse={setLivenessResponse}
                            setStartTimer={setStartTimer}
                            url={
                                documents[`${activeDirector}_${DocumentNameAndType.LIVE_ID}`]
                                    ? documents[`${activeDirector}_${DocumentNameAndType.LIVE_ID}`]?.url
                                    : undefined
                            }
                        />
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                            {openCamera == 'timer' ? (
                                <Button className="gapstack-button gapstack-primary-button">
                                    {startTimer ? (
                                        <TimerButton key={openCamera} startCamera={changeStatus} />
                                    ) : (
                                        'Starting...'
                                    )}
                                </Button>
                            ) : (
                                <>
                                    <Button className="gapstack-button gapstack-primary-button" onClick={changeStatus}>
                                        {openCamera == 'start'
                                            ? 'Start'
                                            : openCamera == 'retry'
                                            ? 'Retry'
                                            : `I'm Ready`}
                                    </Button>
                                    {openCamera == 'retry' && !livenessResponse?.facelivedetect && (
                                        <Button
                                            className="gapstack-button gapstack-primary-button"
                                            onClick={() => uploadToDocumentManager()}
                                            style={{ marginLeft: 15 }}
                                            loading={loading}
                                            disabled={!imageBlob}
                                        >
                                            Verify
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
            </Card>
        </LivenessDetectionWrapper>
    );
};

export default LivenessDetection;
