import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import BuyerCentral from './table';
// import AddSalesBuyer from './add-new';
// import InviteSalesBuyer from './add-new/invite-new';
// import InviteStepTwoBuyer from './add-new/single-invite-2';
// import PendingBuyers from './add-new/pending-buyers';
// import OnboardComponentBuyer from './add-new/onboard';
// import ViewBuyer from './view-buyer/components';

const BuyerCentralComponent = (props: any) => (
    <AuthenticatedLayout>
        <BuyerCentral {...props} />
    </AuthenticatedLayout>
);
const BuyerCentral = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});

const AddSalesBuyerComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddSalesBuyer {...props} />
    </AuthenticatedLayout>
);
const AddSalesBuyer = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});

const InviteSalesBuyerComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <InviteSalesBuyer {...props} />
    </AuthenticatedLayout>
);
const InviteSalesBuyer = Loadable({
    loader: () => import('./add-new/invite-new' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});

const InviteStepTwoBuyerComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <InviteStepTwoBuyer {...props} />
    </AuthenticatedLayout>
);
const InviteStepTwoBuyer = Loadable({
    loader: () => import('./add-new/single-invite-2' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});
const OnboardComponentBuyerComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <OnboardComponentBuyer {...props} />
    </AuthenticatedLayout>
);
const OnboardComponentBuyer = Loadable({
    loader: () => import('./add-new/NewOnboardBuyer' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});
const PendingBuyersComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <PendingBuyers {...props} />
    </AuthenticatedLayout>
);
const PendingBuyers = Loadable({
    loader: () => import('./add-new/pending-buyers' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});

const ViewBuyerComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewBuyer {...props} />
    </AuthenticatedLayout>
);
const ViewBuyer = Loadable({
    loader: () => import('./view-buyer/components' /* webpackChunkName: "Buyer-Central" */),
    loading() {
        return <Loader />;
    },
});

export {
    ViewBuyerComponent,
    BuyerCentralComponent,
    AddSalesBuyerComponent,
    InviteSalesBuyerComponent,
    PendingBuyersComponent,
    InviteStepTwoBuyerComponent,
    OnboardComponentBuyerComponent,
    BuyerCentral,
};
