import { Quill } from 'react-quill';
const Parchment = Quill.import('parchment');

const config = {
    scope: Parchment.Scope.INLINE,
    whitelist: null,
};

const lineHeightStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', config);

export default lineHeightStyle;
