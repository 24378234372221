interface StrategiesFormType {
    id: string | number;
    name: string;
    type: string;
    props: string;
    required?: boolean;
    message: string;
    values?: string | any[] | { [key: string]: any };
    dataProps?: string | string[];
    [key: string]: any;
}

const data: StrategiesFormType[] = [
    {
        id: 1,
        name: 'Stage:',
        type: 'select',
        props: 'stageId',
        required: true,
        message: 'Please select Stage!',
        dataProps: 'stages',
        className: 'gapstack-input form-select',
    },
    {
        id: 2,
        name: 'Operand:',
        type: 'select',
        props: 'operandId',
        required: true,
        message: 'Please select Operand!',
        dataProps: ['stages', 'operands'],
        depProps: 'stageId',
    },
    {
        id: 3,
        name: 'When (Days):',
        type: 'number',
        props: 'whenInDays',
        required: true,
        message: 'Please enter When (Days)!',
        keyboard: false,
        min: 0,
        style: { width: '100%' },
    },
    {
        id: 4,
        name: 'Action:',
        type: 'select',
        props: 'actionId',
        required: true,
        message: 'Please select Action!',
        dataProps: 'actions',
        className: 'gapstack-input form-select',
    },
    // {
    //   id: 7,
    //   name: 'Message',
    //   type: 'textarea',
    //   props: 'message',
    //   required: true,
    //   message: 'Please write a message!',
    // },
];

const strategyData = [
    {
        id: 5,
        name: 'Collection Strategy Name:',
        type: 'text',
        props: 'name',
        required: true,
        message: 'Please enter Strategy Name!',
    },
    {
        id: 6,
        name: 'Collection Strategy Description:',
        type: 'textarea',
        props: 'description',
        required: true,
        message: 'Please enter Strategy Description!',
    },
];

const formData = {
    stage: '',
    operand: '',
    days: '',
    action: '',
};

export { data, formData, strategyData };
