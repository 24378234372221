import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

const InCompleteProfile = Loadable({
    loader: () => import('./incomplete'),
    loading() {
        return <Loader />;
    },
});
const Dashboard = Loadable({
    loader: () => import('./complete'),
    loading() {
        return <Loader />;
    },
});

const InCompleteProfileDashboard = (props: any) => {
    const auth: any = useSelector((state: RootState) => state.auth);
    if (auth?.user?.lastLoginAt) {
        return <Dashboard />;
    }
    return (
        <AuthenticatedLayout>
            <InCompleteProfile {...props} />
        </AuthenticatedLayout>
    );
};

export default InCompleteProfileDashboard;
