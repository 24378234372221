import React from 'react';

import styled from 'styled-components';
import style from './styles/progress-bar';
import { stringifyNumber } from '../../products/add-new/single-upload/components/data';

const ProgressWrapper = styled.div`
    ${style}
`;

interface ProgressProps {
    step: number;
    handleStep: (val: number) => void;
    stepState: string[];
    setStepState: (arr: string[]) => void;
}

const steps = ['Registration', 'Liveness detection', 'Confirmation'];

const ProgressBar: React.FC<ProgressProps> = (props: ProgressProps) => {
    return (
        <ProgressWrapper>
            <div className="progress-bar-names">
                {steps.map((value, ind) => (
                    <div
                        className={`step-name ${props.stepState[ind]}`}
                        key={ind}
                        onClick={() => {
                            const step_state = [...props.stepState];
                            if (step_state[ind] == 'Incomplete') return;
                            if (step_state[props.step - 1] !== 'Completed') {
                                step_state[props.step - 1] = 'Incomplete';
                            }
                            if (step_state[ind] !== 'Completed') {
                                step_state[ind] = 'Active';
                            }
                            props.setStepState(step_state);
                            props.handleStep(ind + 1);
                        }}
                    >
                        {value}
                    </div>
                ))}
            </div>
            <div className="progress-bar">
                {steps.map((_, ind) => (
                    <div className={`step ${stringifyNumber(ind)}`} key={ind}>
                        <div className={`line ${props.step >= ind + 1 ? 'active' : ''}`} />
                        {props.setStepState[ind] !== 'Incomplete' ? (
                            <div className={`dot ${props.stepState[ind]}`}>
                                <div className="circle" />
                            </div>
                        ) : (
                            <div className={`dot`} />
                        )}
                    </div>
                ))}
                <div className={`step fourth`}>
                    <div className={`line`} />
                </div>
            </div>
        </ProgressWrapper>
    );
};

export default ProgressBar;
