import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectCounty from '../../../../utils/FormSelects/Counties';
import SelectCountry from '../../../../utils/FormSelects/Countries';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import { CardName } from '../../types';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;
interface ContactInformationProps {
    form: FormInstance;
    onComplete: (next: boolean, cardName: CardName) => void;
    isAccountPresent?: boolean;
}

const ContactInformation: FunctionComponent<ContactInformationProps> = ({
    form,
    onComplete,
    isAccountPresent = false,
}: ContactInformationProps) => {
    const [countrySelected, setSelectedCountry] = useState<any>({});
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(['contact'], false)) {
                onComplete(false, CardName.CONTACT_INFORMATION);
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);
    return (
        <BusinessProfileWrapper>
            <Card title="Contact Information" className={`card-wrapper ${isAccountPresent ? 'card-margin-top' : ''}`}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name={['contact', 'id']} hidden />
                        <Form.Item
                            label="First Name"
                            name={['contact', 'firstName']}
                            className="label"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="John" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Last Name"
                            name={['contact', 'lastName']}
                            className="label"
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="Doe" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <PhoneNumber
                            countryFieldName={['contact', 'countryCode']}
                            inputFieldName={['contact', 'phone']}
                        />
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={['contact', 'email']}
                            label="Email"
                            className="label"
                            rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
                        >
                            <Input className="gapstack-input" placeholder="john@gapstack.com" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label="Address"
                            name={['contact', 'address']}
                            className="label"
                            rules={[{ required: true, message: 'Please input your address!' }]}
                        >
                            <Input className="gapstack-input" placeholder="P.O Box 777" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Postal Code"
                            name={['contact', 'postalCode']}
                            className="label"
                            rules={[{ required: true, message: 'Please input postal code!' }]}
                        >
                            <Input className="gapstack-input" placeholder="POA777" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <SelectCountry
                            formItemLabel="Country"
                            formItemName={['contact', 'country']}
                            required
                            onChange={() => true}
                            message="Please select country"
                            onCountrySelected={(country) => setSelectedCountry(country)}
                        />
                    </Col>
                    <Col span={12}>
                        <SelectCounty
                            formItemLabel="County"
                            formItemName={['contact', 'county']}
                            required
                            onChange={() => true}
                            message="Please select county"
                            countryId={countrySelected?.country_id}
                            form={form}
                        />
                    </Col>
                </Row>
            </Card>
        </BusinessProfileWrapper>
    );
};

export default ContactInformation;
