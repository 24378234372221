import React from 'react';
interface ExtraProps {
    onClick: (any) => void;
    name: string;
}

const Extra: React.FC<ExtraProps> = ({ onClick, name }: ExtraProps) => {
    switch (name) {
        case 'General API Keys':
            return (
                <span className="actions" onClick={onClick}>
                    CTA Placeholder
                </span>
            );
        case 'Restricted API Keys':
            return (
                <span className="actions" onClick={onClick}>
                    CTA Placeholder
                </span>
            );
        case '3rd Party API Keys':
            return (
                <span className="actions" onClick={onClick}>
                    CTA Placeholder
                </span>
            );
        default:
            return null;
    }
};

export { Extra };
