import { css } from 'styled-components';
// import { DEVICE } from '../../../config/theme';

const style = () => css`
    height: 100vh;
    flex-direction: column;
    background-color: #f4f8f9;
    .results-container {
        background-color: #ffffff;
        border-radius: 5px;
        //padding: 20px;
        .title-container {
            border-bottom: 1px solid #39426a12;
            div {
                padding: 20px;
                .results-title {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #007aff;
                }
                .normal-title {
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 20px;
                    color: #39426a;
                }
            }
        }
        .results-content-container {
            padding: 20px;
            .title {
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #39426a;
                margin-bottom: 8px;
            }
            .text {
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #6c7392;
            }
        }
    }
`;

export default style;
