import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

// import ConnectedAccount from './table';
// import ViewConnectedAccount from './view-account';
// import EditAccount from './edit-account';
// import AddNewAccount from './add-account';

const ConnectedAccountComponent = () => (
    <AuthenticatedLayout>
        <ConnectedAccount />
    </AuthenticatedLayout>
);
const ConnectedAccount = Loadable({
    loader: () => import('./table' /* webpackChunkName: "ConnectedAccounts" */),
    loading() {
        return <Loader />;
    },
});
const ViewConnectedAccountComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewConnectedAccount {...props} />
    </AuthenticatedLayout>
);
const ViewConnectedAccount = Loadable({
    loader: () => import('./view-account' /* webpackChunkName: "ViewConnectedAccount" */),
    loading() {
        return <Loader />;
    },
});
const EditAccountComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditAccount {...props} />
    </AuthenticatedLayout>
);
const EditAccount = Loadable({
    loader: () => import('./edit-account' /* webpackChunkName: "EditAccount" */),
    loading() {
        return <Loader />;
    },
});
const AddNewAccountComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddNewAccount {...props} />
    </AuthenticatedLayout>
);
const AddNewAccount = Loadable({
    loader: () => import('./add-account' /* webpackChunkName: "AddNewAccount" */),
    loading() {
        return <Loader />;
    },
});

const MakeAccountPaymentComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <MakeAccountPayment {...props} />
    </AuthenticatedLayout>
);

const MakeAccountPayment = Loadable({
    loader: () => import('./make-account-payments' /* webpackChunkName: "MakeAccountPayment" */),
    loading() {
        return <Loader />;
    },
});

const ViewAccountTransactionComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewAccountTransaction {...props} />
    </AuthenticatedLayout>
);
const ViewAccountTransaction = Loadable({
    loader: () => import('./view-transaction' /* webpackChunkName: "ViewAccountTransaction" */),
    loading() {
        return <Loader />;
    },
});

const ReconcileTransactionComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <ReconcileTransaction {...props} />
    </AuthenticatedLayout>
);
const ReconcileTransaction = Loadable({
    loader: () => import('./reconcile-transaction' /* webpackChunkName: "ReconcileTransaction" */),
    loading() {
        return <Loader />;
    },
});

const AccountStatementComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AccountStatement {...props} />
    </AuthenticatedLayout>
);
const AccountStatement = Loadable({
    loader: () => import('./account-statement' /* webpackChunkName: "AccountStatement" */),
    loading() {
        return <Loader />;
    },
});

const ReconciledTransactionsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ReconciledTransactions {...props} />
    </AuthenticatedLayout>
);
const ReconciledTransactions = Loadable({
    loader: () => import('./reconciled-transactions' /* webpackChunkName: "ReconciledTransactions" */),
    loading() {
        return <Loader />;
    },
});

export {
    AccountStatementComponent,
    AddNewAccountComponent,
    ConnectedAccount,
    ConnectedAccountComponent,
    EditAccountComponent,
    MakeAccountPaymentComponent,
    ReconcileTransactionComponent,
    ReconciledTransactionsComponent,
    ViewAccountTransactionComponent,
    ViewConnectedAccountComponent,
};
