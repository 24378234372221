import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Divider, Row, Col, Form, Input, Button } from 'antd';
import help from '../../../assets/Images/help.svg';
import success from '../../../assets/Images/Success.svg';
import { Link } from 'react-router-dom';
import style from './styles';
import ContentLayout from '../../Layouts/Content';
import { ArrowLeft, HelpCircle } from 'react-feather';
import { appIcon } from '../../../config/appIcon';
const AddAccountWrapper = styled.div`
    ${style}
`;

const AddAccount = () => {
    const history = useHistory();

    const onFinish = () => {
        history.push('/confirm-account');
    };

    // const onFinishFailed = () => {};

    return (
        <ContentLayout>
            <AddAccountWrapper>
                <div className="add-wrapper">
                    <Button className="back" onClick={() => history.goBack()}>
                        <ArrowLeft className="back-arrow" />
                        Back
                    </Button>
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>
                    <div className="container">
                        <Row style={{ padding: '0px 58px' }}>
                            <img className="success-logo" src={success} alt="" />
                        </Row>

                        <Row style={{ margin: '15px auto' }}>
                            <h3 className="heading">Account Validated Successfully</h3>
                        </Row>
                        <Row style={{ margin: 'auto' }}>
                            <p
                                className="sub-heading"
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                            >
                                Please add your bank account to continue:
                                <HelpCircle color="#1E87F0" size="15" style={{ marginLeft: '10px' }} />
                            </p>
                        </Row>
                        <Row>
                            <Form name="sign-in-form" layout="vertical" onFinish={onFinish} style={{ width: '100%' }}>
                                <Row gutter={20} style={{ padding: '0px 40px' }}>
                                    <Col span={24} style={{ marginTop: '10px' }}>
                                        <Form.Item
                                            label="Enter bank account number"
                                            name="bankAccount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Bank Account Number!',
                                                },
                                            ]}
                                        >
                                            <Input className="gapstack-input" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Divider style={{ borderTop: '1px solid #e5e5e5', margin: 0 }} />

                                <Row className="button-container">
                                    <Button
                                        type="link"
                                        className="button-link"
                                        onClick={() => history.push('/business/account/complete/registration')}
                                    >
                                        Skip
                                    </Button>
                                    <Button type="primary" htmlType="submit" className="gapstack-button right-button">
                                        Submit
                                    </Button>
                                </Row>
                            </Form>
                        </Row>
                    </div>
                </div>
            </AddAccountWrapper>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </ContentLayout>
    );
};

export default AddAccount;
