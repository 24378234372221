import React, { useState } from 'react';
import styled from 'styled-components';
import ContentLayout from '../../Layouts/Content';
import style from '../sign-in/styles';
import { appIcon } from '../../../config/appIcon';
import CustomSignInForm from '../../custom-bank/auth/sign-in/CustomSignInForm';
import { useForm } from 'antd/es/form/Form';
import { useHistory, useParams } from 'react-router-dom';
import { notification } from 'antd';
import qs from 'qs';
import { unAuthenticatedRequest } from '../../../api';
import { bot_sign_in } from '../../../apis/authentication';
import { goToRoute } from '../../../router/urls';
import InMemoryJWTManager from '../../../utils/InMemoryJWT';
import { actions } from '../sign-in/store/actions';
import { useDispatch } from 'react-redux';
import { businessAccountActions } from '../../business-accounts/store/actions';
import { createSearchParams, useSearchParam } from '../../../router/routeHooks';

const SignInWrapper = styled.div`
    ${style}
`;

const BotSignIn = () => {
    const [form] = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id }: any = useParams();
    const { next, ...rest } = useSearchParam<{ next: string }>();
    const [signingIn, setSigningIn] = useState<boolean>(false);

    const goToForgotPassword = () => {
        history.push('/forgot-password');
    };

    const goToSignUp = () => {
        history.push('/sign-up');
    };

    const onFinish = async (values) => {
        setSigningIn(true);
        unAuthenticatedRequest()
            .post(bot_sign_in(id), qs.stringify(values))
            .then((res) => res.data)
            .then((response) => {
                setSigningIn(false);
                InMemoryJWTManager.setToken(
                    response.accessToken.token,
                    parseInt(response.accessToken.expiresIn ?? 5) * 60,
                );
                dispatch(actions.onUserSignInSuccess(response.user));
                dispatch(
                    businessAccountActions.fetchBusinessInformation(
                        history,
                        true,
                        response?.user?.businessAccountId,
                        next,
                    ),
                );
                history.push({
                    pathname: goToRoute(next, response?.user?.businessAccountId),
                    search: createSearchParams(rest),
                });
            })
            .catch((error: any) => {
                setSigningIn(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <ContentLayout>
            <SignInWrapper>
                <div className="logo-container">
                    <img className="logo" src={appIcon} alt="Logo" />
                </div>
                <div className="container">
                    <span className="welcome">Welcome Back</span>
                    <div className="top-divider" />
                    <CustomSignInForm
                        title={''}
                        onFinish={onFinish}
                        signingIn={signingIn}
                        form={form}
                        goToForgotPassword={goToForgotPassword}
                        goToSignUp={goToSignUp}
                        passwordLabel="Password/Pin"
                    />
                </div>
            </SignInWrapper>
        </ContentLayout>
    );
};

export default BotSignIn;
