import React from 'react';
import { ValidatedSuppliers, ViewValidatedSuppliers } from './validated-suppliers';
import SupplierGroupsComponent from './groups';
import AuthenticatedLayout from '../../../components/Layouts/Authenticated';

const SupplierGroups = (props: any) => (
    <AuthenticatedLayout>
        <SupplierGroupsComponent {...props} />
    </AuthenticatedLayout>
);

export { ValidatedSuppliers, ViewValidatedSuppliers, SupplierGroups };
