import axios from 'axios';
import { getBusinessId } from '../router/urls';
import InMemoryJWTManager from '../utils/InMemoryJWT';

const authenticatedRequest = (headers = {}, https = false, baseUrl = process.env.REACT_APP_API_BASE_URL) => {
    if (https) {
        baseUrl = '';
    }

    const options = {
        baseURL: baseUrl,
        // withCredentials: true,
        headers: {
            'Access-Control-Allow-Headers':
                'Authorization,Origin,access-control-allow-origin,Content-Type,x-bank-user-id,x-user-id,x-business-account-id,x-requested-with',
            'Content-Type': 'application/json',
        },
    };
    const temp_business_id = getBusinessId();
    if (temp_business_id !== null && temp_business_id !== undefined) {
        options.headers['x-business-account-id'] = temp_business_id;
    }
    options.headers = {
        ...options.headers,
        ...headers,
        'Content-Type': headers['Content-Type'] ?? options.headers['Content-Type'],
    };
    Object.keys(options.headers).forEach((key) =>
        options.headers[key] === undefined ? delete options.headers[key] : {},
    );

    if (!InMemoryJWTManager.getToken()) {
        InMemoryJWTManager.getRefreshedToken(temp_business_id);
    }

    const axiosRequest = axios.create(options);
    axiosRequest.interceptors.request.use(
        async (config) => {
            await InMemoryJWTManager.waitForRefreshToken().then(() => {
                return InMemoryJWTManager.getToken() ? Promise.resolve() : Promise.reject();
            });
            config.headers = {
                ...config.headers,
                ...{
                    Authorization: `Bearer ${InMemoryJWTManager.getToken()}`,
                },
            };
            return config;
        },
        (error) => {
            console.log(error);
            Promise.reject(error);
        },
    );

    return axiosRequest;
};

const unAuthenticatedRequest = (headers = {}, withCredentials = true) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // if (process.env.NODE_ENV === 'production') {
    //     baseUrl = 'http://localhost:8000';
    // }

    const options = {
        baseURL: baseUrl,
        withCredentials,
        headers: {
            ...headers,
        },
    };
    return axios.create(options);
};

const mockRequest = () => {
    const baseUrl = 'https://bea993ab-8737-41a2-aa61-ef60f0361013.mock.pstmn.io';

    const options = {
        baseURL: baseUrl,
    };
    return axios.create(options);
};

export { authenticatedRequest, mockRequest, unAuthenticatedRequest };
