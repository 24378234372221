import React from 'react';
import Loadable from 'react-loadable';
import AuthenticatedLayout from '../../components/Layouts/Authenticated';
import Loader from '../../utils/Loader';

const BillsOnCollection = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Register bills" */),
    loading() {
        return <Loader />;
    },
});

const BillsOnCollectionComponent = (props: any) => (
    <AuthenticatedLayout>
        <BillsOnCollection {...props} />
    </AuthenticatedLayout>
);
const RegisterBillsOnCollection = Loadable({
    loader: () => import('./register' /* webpackChunkName: "Register bills" */),
    loading() {
        return <Loader />;
    },
});

const RegisterBillsOnCollectionComponent = (props: any) => (
    <AuthenticatedLayout>
        <RegisterBillsOnCollection {...props} />
    </AuthenticatedLayout>
);
const ViewBillsOnCollection = Loadable({
    loader: () => import('./view-trade-finance' /* webpackChunkName: "Register bills" */),
    loading() {
        return <Loader />;
    },
});

const ViewBillsOnCollectionComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewBillsOnCollection {...props} />
    </AuthenticatedLayout>
);
export {
    BillsOnCollectionComponent,
    RegisterBillsOnCollectionComponent,
    ViewBillsOnCollectionComponent,
    RegisterBillsOnCollection as BankRegisterBillsOnCollection,
};
