import React, { FC } from 'react';
import styled from 'styled-components';
import style from '../sign-in/styles';
import ContentLayout from '../../Layouts/Content';
import { appIcon } from '../../../config/appIcon';

const SignInWrapper = styled.div`
    ${style}
`;

const CookiesDisabled: FC = () => {
    return (
        <ContentLayout>
            <SignInWrapper>
                <div className="logo-container">
                    <img className="logo" src={appIcon} alt="Logo" />
                </div>
                <div className="container">
                    <span className="welcome">We can&apos;t sign you in</span>
                    <div className="top-divider" />
                    <p className="description">
                        Your browser is currently set to block cookies. You need to allow cookies to use this service.
                    </p>
                    <p className="description">
                        Cookies are small text files stored on your computer that tell us when you&apos;re signed in. To
                        learn how to allow cookies, check the online help in your web browser.
                    </p>
                </div>
            </SignInWrapper>
        </ContentLayout>
    );
};

export default CookiesDisabled;
