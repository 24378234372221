import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import style from './styles';
import { Button, Col, Divider, notification, Row, Table } from 'antd';
import AuthenticatedLayout from '../Layouts/Authenticated';
import { LoadingOutlined } from '@ant-design/icons';
import UserAvatar from '../../utils/Avatar';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';
import EnableTwoFactorAuthentication from './enable-two-factor-authentication';
import DisableTwoFactorAuthentication from './disable-two-factor-authentication';
import EditPassword from './edit-password';
import { authenticatedRequest } from '../../api';
import { get_sessions } from './api';
import moment from 'moment';
import { list_user_businesses } from '../../apis/authentication';
import { COLORS, generateTheme } from '../../config/theme';
import { BusinessWrapper, getUser } from '../../config';

const ProfileWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ color: `${generateTheme()[COLORS.PRIMARY]}` }} spin />;

const columns = [
    {
        key: 1,
        title: 'Location',
        dataIndex: 'location',
        render: (_, record) => {
            if (!record.countryName || !record.state) return '-';
            return `${record?.countryName}, ${record?.state}`;
        },
    },
    {
        key: 2,
        title: 'Device',
        dataIndex: 'device',
        render: () => '-',
    },
    {
        key: 3,
        title: 'IP Address',
        dataIndex: 'ip',
    },
    {
        key: 4,
        title: 'Time',
        dataIndex: 'time',
        render: (_, record) => (record.time ? moment(record).format('DD MMM YYYY H:mm A') : '-'),
    },
];

const accountColumns = [
    {
        key: 1,
        title: 'Account',
        dataIndex: 'businessName',
    },
    {
        key: 2,
        title: 'Roles',
        dataIndex: 'roles',
    },
];

const Profile = () => {
    const [showEnableTwoFactorAuthDialog, setShowEnableTwoFactorDialog] = useState(false);
    const [showDisableTwoFactorAuthDialog, setShowDisableTwoFactorDialog] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [showEditPassword, setEditPasswordVisibility] = useState<boolean>(false);
    const auth: any = useSelector((state: RootState) => state.auth);
    const user = auth.user;
    const isBank = getUser() == 'BANK';
    const [loading] = useState(false);
    const [loadingBusiness, setLoadingBusinesses] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    const [twoStepEnabled, setTwoStepEnabled] = useState(user?.twoStep);

    const enableTwoFactorAuthentication = (val) => {
        setShowEnableTwoFactorDialog(val);
    };

    const disableTwoFactorAuthentication = (val) => {
        setShowDisableTwoFactorDialog(val);
    };

    const setTwoStep = (val) => {
        setTwoStepEnabled(val);
    };

    const getSessions = () => {
        if (isBank) {
            return;
        }
        authenticatedRequest()
            .get(get_sessions)
            .then((res) => {
                setSessions(res.data);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const getBusinesses = () => {
        if (isBank) {
            return;
        }
        authenticatedRequest({ 'x-user-id': auth?.user?.userId })
            .get(list_user_businesses)
            .then((res) => {
                setBusinesses(res.data);
                setLoadingBusinesses(false);
            })
            .catch((err) => {
                setLoadingBusinesses(false);
                const resp = err.response;
                if (resp && resp.data) {
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Oops! An unexpected error occurred.',
                    });
                }
            });
    };

    const onEditPassword = () => {
        setEditPasswordVisibility(false);
    };

    useEffect(() => {
        getSessions();
        getBusinesses();
    }, []);

    return (
        <AuthenticatedLayout>
            {!twoStepEnabled ? (
                <EnableTwoFactorAuthentication
                    visible={showEnableTwoFactorAuthDialog}
                    handleCancel={() => enableTwoFactorAuthentication(false)}
                    setTwoStep={setTwoStep}
                />
            ) : (
                <DisableTwoFactorAuthentication
                    visible={showDisableTwoFactorAuthDialog}
                    handleCancel={() => disableTwoFactorAuthentication(false)}
                    setTwoStep={setTwoStep}
                />
            )}
            <EditPassword
                onEditPassword={onEditPassword}
                visible={showEditPassword}
                handleCancel={() => setEditPasswordVisibility(false)}
            />
            <ProfileWrapper>
                <Row className="card">
                    <Col span={24} className="card-header">
                        <div className="title-container">
                            <span className="title">Profile</span>
                        </div>
                        <div className="action-container">
                            <Button className="gapstack-default-button" onClick={() => setEditPasswordVisibility(true)}>
                                Edit Password
                            </Button>
                        </div>
                    </Col>
                    <Col span={24} className="card-body">
                        <Col span={11}>
                            <Row className="content-row">
                                <Col span={4}>
                                    <UserAvatar type="profile" />
                                </Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={2}>
                                            <p className="title">Email:</p>
                                        </Col>
                                        <Col>
                                            <p className="description">{user?.email}</p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={2}>
                                            <p className="title">Name:</p>
                                        </Col>
                                        <Col>
                                            <p className="description">
                                                {user?.firstName} {user?.lastName}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Divider type="vertical" style={{ height: 'inherit' }} />
                        <Col span={11}>
                            <Row className="content-row">
                                <Col span={3} className="title">
                                    Password:
                                </Col>
                                <Col span={12} className="description">
                                    *******
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>

                <Row className="card">
                    <Col span={24} className="card-header">
                        <div className="title-container">
                            <p className="title">Two Step Authentication</p>
                            <p className="sub-title">
                                Keep your account extra secure with a second authentication step.
                            </p>
                        </div>
                        <div className="action-container">
                            {!twoStepEnabled ? (
                                <Button
                                    className="gapstack-default-button"
                                    onClick={() => enableTwoFactorAuthentication(true)}
                                    disabled={isBank}
                                >
                                    Add Authentication
                                </Button>
                            ) : (
                                <Button
                                    className="gapstack-default-button"
                                    onClick={() => disableTwoFactorAuthentication(true)}
                                    disabled={isBank}
                                >
                                    Disable Authentication
                                </Button>
                            )}
                        </div>
                    </Col>
                    <Col span={24} className="card-body">
                        <Col span={24}>
                            <Row className="content-row">
                                <Col span={21}>
                                    <p className="description">
                                        Once you enable either SMS or authenticator app, you will be able to add
                                        security keys.
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
                <BusinessWrapper>
                    <Row className="card">
                        <Col span={24} className="card-header">
                            <div className="title-container">
                                <p className="title">Login Sessions</p>
                            </div>
                            <div className="action-container">
                                <Button className="gapstack-default-button">Sign out all other sessions</Button>
                            </div>
                        </Col>
                        <Col span={24} className="card-body" style={{ padding: 0 }}>
                            <Table
                                style={{ width: '100%', marginTop: 0 }}
                                className="datatable"
                                columns={columns}
                                dataSource={sessions}
                                pagination={false}
                                loading={{
                                    spinning: loading,
                                    indicator: antIcon,
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="card">
                        <Col span={24} className="card-header">
                            <div className="title-container">
                                <p className="title">Accounts</p>
                                <p className="sub-title">List of accounts to which you are a member of</p>
                            </div>
                        </Col>
                        <Col span={24} className="card-body" style={{ padding: 0 }}>
                            <Table
                                style={{ width: '100%', marginTop: 0 }}
                                className="datatable"
                                columns={accountColumns}
                                dataSource={businesses}
                                pagination={false}
                                onRow={(record: any) => ({
                                    onClick: () => console.log(record),
                                })}
                                loading={{
                                    spinning: loadingBusiness,
                                    indicator: antIcon,
                                }}
                            />
                        </Col>
                    </Row>
                </BusinessWrapper>
            </ProfileWrapper>
        </AuthenticatedLayout>
    );
};

export default Profile;
