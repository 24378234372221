export const FETCH_ACCOUNTS_REQUEST = 'FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_SUCCESS = 'FETCH_ACCOUNTS_SUCCESS';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';

export interface Account {
    id: string;
    type: string;
    country: string;
    accountNo: string;
    bankName: string;
    currency: string;
    balance: string;
    availableBalance: string;
    valid?: string;
}

interface FetchAccountsRequestAction {
    type: typeof FETCH_ACCOUNTS_REQUEST;
}

interface FetchAccountsSuccessAction {
    type: typeof FETCH_ACCOUNTS_SUCCESS;
    payload: Account[];
}

interface FetchAccountsFailureAction {
    type: typeof FETCH_ACCOUNTS_FAILURE;
    payload: string;
}

export type AccountActionTypes = FetchAccountsRequestAction | FetchAccountsSuccessAction | FetchAccountsFailureAction;
