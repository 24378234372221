import React, { FunctionComponent } from 'react';
import { FormInstance, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { DocType } from '../../../components/business-accounts/cards/business-documents/components/SIngleDirector';
import { updateObject } from '../../Helper';

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
    disabled?: boolean;
    value?: string;
    form?: FormInstance;
}

const BusinessTypeWrapper = styled.div`
    ${style}
`;

const SelectIdDocType: FunctionComponent<Props> = ({
    formItemName,
    onChange,
    disabled,
    formItemLabel,
    form,
    value,
}: Props) => {
    const onChangeValue = (e) => {
        onChange?.(e, formItemName);
        const formObject = form?.getFieldsValue() || {};
        updateObject(formObject, formItemName, e);
        form?.setFieldsValue(formObject);
    };
    return (
        <BusinessTypeWrapper>
            <div className="id-label">{formItemLabel}</div>
            <Select
                className="form-select select"
                placeholder="National ID"
                suffixIcon={() => <ChevronDown className="select-icon" />}
                onChange={(e) => onChangeValue(e)}
                disabled={disabled}
                // defaultValue={DocType.NATIONAL_ID}
                data-testid="select-document"
                aria-label="select-doc"
                options={[
                    { id: DocType.NATIONAL_ID, value: DocType.NATIONAL_ID },
                    { id: DocType.PASSPORT, value: DocType.PASSPORT },
                ]}
                style={{ marginBottom: 10 }}
                value={value}
            />
        </BusinessTypeWrapper>
    );
};

export default SelectIdDocType;
