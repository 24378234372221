import React, { useEffect, useState } from 'react';
import { Form, FormInstance, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import { unAuthenticatedRequest } from '../../../api';
import styled from 'styled-components';
import style from './styles';

const { Option } = Select;

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    onCountrySelected?: (country) => void;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
    formItemClassName?: string;
    form?: FormInstance;
    disabled?: boolean;
    optionValue?: string;
}

const CountryWrapper = styled.div`
    ${style}
`;

const SelectCountry = ({
    formItemLabel,
    formItemName,
    required,
    message,
    onCountrySelected,
    initialValue,
    validateStatus,
    onChange,
    hasFeedback = false,
    formItemClassName,
    form,
    disabled,
    optionValue = 'countryName',
}: Props) => {
    const ref = React.useRef<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<any>([]);
    const fetchCountries = () => {
        setLoading(true);
        unAuthenticatedRequest()
            .get(`data-provider/data-provider`)
            .then((res) => {
                setCountries(res.data);
                // const defaultCountry = res.data?.filter(
                //     (val) => val.countryName == (process.env.DEFAULT_COUNTRY || 'Kenya'),
                // );
                // if (Array.isArray(defaultCountry) && defaultCountry.length == 1) {
                //     console.log(defaultCountry);
                //     onSelect('Kenya');
                // }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onSelect = (value) => {
        const selectedCountryIndex = countries.findIndex((item) => item?.[optionValue] === value);
        setValue(value);

        if (onCountrySelected) {
            if (selectedCountryIndex !== -1) {
                onCountrySelected(countries[selectedCountryIndex]);
                return;
            }
            onCountrySelected({});
        }
    };
    const setValue = (value: string) => {
        const formObject = {};
        if (typeof formItemName == 'string') {
            formObject[formItemName] = value;
        } else {
            let schema = formObject;
            const len = formItemName.length;
            for (let i = 0; i < len - 1; i++) {
                const elem = formItemName[i];
                if (!schema[elem]) schema[elem] = {};
                schema = schema[elem];
            }

            schema[formItemName[len - 1]] = value;
        }
        form?.setFieldsValue(formObject);
    };

    const onClear = () => {
        if (onCountrySelected) {
            onCountrySelected({});
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    React.useEffect(() => {
        const _val = ref.current?.props?.value;
        _val && ref.current?.props?.onSelect(_val);
    }, [countries]);

    return (
        <CountryWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className={`label ${formItemClassName}`}
                rules={[{ required, message }]}
                initialValue={initialValue ?? (process.env.DEFAULT_COUNTRY || 'Kenya')}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    ref={ref}
                    showSearch
                    loading={loading}
                    className="form-select select"
                    onSelect={onSelect}
                    onClear={onClear}
                    onChange={(e) => (onChange ? onChange(e, formItemName) : null)}
                    allowClear
                    disabled={disabled}
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    placeholder="Select country"
                    data-testid="select-countries"
                >
                    {countries.map((country) => (
                        <Option key={country?.country_id} value={country?.[optionValue]}>
                            {country?.countryName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </CountryWrapper>
    );
};

export default SelectCountry;
