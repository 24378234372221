import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import unAuthImg from '../../../assets/Images/unauth.svg';

import style from './styles';
import { goToRoute, ROUTES } from '../../../router/urls';

const UnauthorizedWrapper = styled.div`
    ${style}
`;

const index = () => {
    return (
        <UnauthorizedWrapper>
            <div>
                <img src={unAuthImg} alt="401" />

                <h2>Unauthorizated!</h2>
                <p>
                    The page you are looking for might be unavailable or you don’t have acces due to invalid
                    credentials.
                </p>
                <span>
                    <Link to={goToRoute(ROUTES.DASHBOARD)}>Go back home</Link> or you may also try reloading the page.
                </span>
            </div>
        </UnauthorizedWrapper>
    );
};

export default index;
