import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    margin-top: 20px;
    .card-container {
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 20px;
        min-height: 600px;
    }
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
            margin: 40px 0px 10px 0px;
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .description {
            margin-bottom: 10px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            text-align: center;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            width: 45%;
        }
    }
`;

export default style;
