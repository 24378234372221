import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import SideBar from './components/sidebar';
import style from './styles';
import { Row, Col, Layout, notification } from 'antd';
import TopBar from './components/topbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import InMemoryJWTManager from '../../../utils/InMemoryJWT';
import SessionExpired from '../../../utils/Session/Expired';
import { getBusinessId, ROUTES } from '../../../router/urls';
import ContentLayout from '../Content';
import { Redirect, useLocation } from 'react-router-dom';
import { authenticatedRequest } from '../../../api';
import { get_live_notifications, get_unread_notifications } from '../../../apis/notifications';
import { authenticatedLayoutActions } from './store/actions';
import { getUser, Users } from '../../../config';
import ChangePassword from '../../../utils/Modals/ChangePassword';
import { actions } from '../../authentication/sign-in/store/actions';
import useMediaQuery from '../../../utils/ResponsiveTable/useMediaQuery';
import { DEVICE } from '../../../config/theme';
import MobileBar from './components/topbar/MobileBar';
import EnableTwoFactorAuthentication from '../../profile/enable-two-factor-authentication';
const AuthenticatedWrapper = styled.div`
    ${style}
`;

interface IProps {
    children: ReactNode;
    position?: 'sticky' | 'relative' | 'fixed';
    isContentLayout?: boolean;
    isFullScreen?: boolean;
}

class NewLayout extends React.PureComponent<IProps> {
    render() {
        return (
            <Row>
                <Col span={24}>{this.props.children}</Col>
            </Row>
        );
    }
}
export const exceptions = [
    'dashboard',
    'businesses',
    'verify',
    'reports',
    'developer',
    'settings',
    'import-trade',
    'bank-settings',
    'fund-my-purchases',
    'fund-from-sales',
    'profile',
];

const AuthenticatedLayout = ({ children, position, isContentLayout, isFullScreen }: IProps) => {
    // timeout in milliseconds (20 mins)
    const timeout = 1200000;
    const auth: any = useSelector((state: RootState) => state.auth);
    const businessAccount: any = useSelector((state: RootState) => state.businessAccount);
    const [collapsed, setCollapsed] = useState<boolean>((window as any)?.innerWidth < 1200);
    const [visible, setVisibility] = useState<boolean>(false);
    const [bankTwoFactorVisible, setBankTwoFactorVisible] = useState<boolean>(false);
    const location = useLocation();
    const path = location.pathname.split('/');
    const activePath = path[1] ? path[1] : '';
    const isBank = getUser() == Users.BANK;

    //Set notifications
    const dispatch = useDispatch();
    const userId = auth?.user?.userId;

    const isWebDevice = useMediaQuery(DEVICE.laptop);

    const onCloseChangePassword = (success) => {
        if (success) {
            const user = auth?.user;
            user.passwordExpired = false;
            dispatch(actions.onUserSignInSuccess(user));
            setVisibility(false);
        }
    };

    const handleOnIdle = () => {
        InMemoryJWTManager.sessionExpired(businessAccount?.business?.id);
    };
    useEffect(() => {
        window.name = getBusinessId();
        if (auth?.user?.passwordExpired) {
            setVisibility(true);
        }
    }, []);

    useEffect(() => {
        window.onunload = () => window.localStorage.removeItem(getBusinessId());
    }, []);

    useEffect(() => {
        if (isBank) return;
        const event = new EventSource(get_live_notifications(getBusinessId(), auth?.user?.userId));
        fetchNotifications();
        event.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (data?.name == 'KEEP_ALIVE') return;
            dispatch(authenticatedLayoutActions.newNotifications(data));
        };
        event.onerror = (e) => {
            console.log(e, 'error');
        };
        return () => {
            event.close();
        };
    }, []);

    // useEffect(() => {
    //     setBankTwoFactorVisible(isBank && !auth?.user?.twoStep);
    // }, []);

    useIdleTimer({
        timeout,
        onIdle: handleOnIdle,
    });

    const { Header, Content, Sider } = Layout;
    if (businessAccount?.business?.businessName === 'Admin') {
        if (exceptions.indexOf(activePath) < 0 && activePath != 'dashboard') {
            return <Redirect to={ROUTES.BUSINESS} />;
        }
    }
    const fetchNotifications = () => {
        if (isBank) return;
        authenticatedRequest({ 'x-user-id': userId })
            .get(get_unread_notifications(), { params: { page: 1 } })
            .then((res) => res.data)
            .then((res) => {
                dispatch(authenticatedLayoutActions.fetchUnreadNotifications(res?.data));
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <AuthenticatedWrapper>
            <SessionExpired />
            <ChangePassword userId={userId} visible={visible} onClose={(success) => onCloseChangePassword(success)} />
            <EnableTwoFactorAuthentication
                visible={bankTwoFactorVisible}
                handleCancel={() => setBankTwoFactorVisible(false)}
                closable={false}
            />
            {isContentLayout ? (
                <ContentLayout isFullScreen={isFullScreen}>{children}</ContentLayout>
            ) : isWebDevice ? (
                <Layout>
                    <Sider
                        width={250}
                        collapsed={collapsed}
                        collapsedWidth={80}
                        breakpoint="xl"
                        onBreakpoint={(broken) => {
                            setCollapsed(broken);
                        }}
                        style={{
                            overflowY: 'auto',
                            height: '100vh',
                            position: 'fixed',
                        }}
                    >
                        <SideBar businessName={businessAccount?.business?.businessName} collapsed={collapsed} />
                    </Sider>
                    <Layout style={{ marginLeft: collapsed ? 80 : 250, padding: '0px 15px' }}>
                        <Header>
                            <TopBar user={auth.user} position={position} />
                        </Header>
                        <Content style={{ padding: '0 0 20px' }}>
                            <NewLayout>{children}</NewLayout>
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <Layout style={{ marginLeft: 0, padding: '0px 15px' }}>
                    <Header>
                        <MobileBar businessName={businessAccount?.business?.businessName} />
                    </Header>
                    <Content style={{ padding: '0 0 20px' }}>
                        <NewLayout>{children}</NewLayout>
                    </Content>
                </Layout>
            )}
        </AuthenticatedWrapper>
    );
};

export default AuthenticatedLayout;
