import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

const Products = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Products" */),
    loading() {
        return <Loader />;
    },
});
const ProductsComponent = (props: any) => (
    <AuthenticatedLayout>
        <Products {...props} />
    </AuthenticatedLayout>
);

const ViewProduct = Loadable({
    loader: () => import('./view-product' /* webpackChunkName: "Products" */),
    loading() {
        return <Loader />;
    },
});
const ViewProductComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewProduct {...props} />
    </AuthenticatedLayout>
);

const AddNewProductComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddNewProduct {...props} />
    </AuthenticatedLayout>
);
const AddNewProduct = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Products" */),
    loading() {
        return <Loader />;
    },
});
const EditProductComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditProduct {...props} />
    </AuthenticatedLayout>
);
const EditProduct = Loadable({
    loader: () => import('./edit-product' /* webpackChunkName: "Products" */),
    loading() {
        return <Loader />;
    },
});

const ConfirmBulkUploadProducts = Loadable({
    loader: () => import('./add-new/confirm-bulk-upload-products' /* webpackChunkName: "Products" */),
    loading() {
        return <Loader />;
    },
});

export {
    ProductsComponent,
    ViewProductComponent,
    AddNewProductComponent,
    EditProductComponent,
    ConfirmBulkUploadProducts,
    Products,
};
