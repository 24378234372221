import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const notesStyles = () => css`
    .notes-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .heading {
            font-size: 13px;
            justify-content: flex-start;
            line-height: 20px;
            font-weight: 400;
        }
        .all-notes-div {
            display: flex;
            flex-direction: column;
            .note {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            .notes-footer {
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;
                width: 100%;
                .user-name {
                    font-weight: 400;
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                }
                .date {
                    font-weight: 400;
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                    margin-left: auto;
                }
            }
        }
        .return-field {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;
            .ant-form-item-control {
                width: 100%;
            }
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            margin-top: 15px;
            width: 100%;
            .notes-button,
            .action-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 10px 20px;
                background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                border: none;
                border-radius: 5px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.POP_UP]};
                margin-left: auto;
            }
            .cancel-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 10px 20px;
                background: ${(props: any) => props.theme[COLORS.POP_UP]};
                border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
                border-radius: 5px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
                margin-left: auto;
            }
            .action-button {
                margin-left: 0;
                background: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            }
        }
    }
`;

export default notesStyles;
