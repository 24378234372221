import { Modal, notification } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import style from '../styles/submitDocs';
import Lottie from 'react-lottie';
import searchLottie from '../../../../../assets/lotties/search.json';
import { authenticatedRequest } from '../../../../../api';
import { submit_documents_business_profile } from '../../../../../apis/business-accounts';
import { useParams } from 'react-router';
import { getUser, Users } from '../../../../../config';

interface SubmitDocsModalProps {
    showModal: boolean;
    hideModal: (val: boolean) => void;
    moveToNextStep: () => void;
}

const SubmitDocsWrapper = styled.div`
    ${style}
`;

const SubmitDocsModal: FunctionComponent<SubmitDocsModalProps> = ({
    showModal,
    hideModal,
    moveToNextStep,
}: SubmitDocsModalProps) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;
    useEffect(() => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_documents_business_profile, {})
            .then((res) => res.data)
            .then(() => {
                moveToNextStep();
            })
            .catch((err) => {
                hideModal(false);
                const resp = err?.response;
                if (resp?.data?.ERR_CODE == 'KYC_ERROR') {
                    moveToNextStep();
                } else {
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                }
            });
    }, []);

    return (
        <SubmitDocsWrapper>
            <Modal
                visible={showModal}
                footer={false}
                closeIcon={() => <div style={{ display: 'none' }} />}
                width="40%"
                closable={false}
                centered
            >
                <div className="submit-docs-wrapper">
                    <div className="docs-heading">Verifying Your Documents</div>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: searchLottie,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice',
                            },
                        }}
                        height={'90%'}
                        width={'90%'}
                    />
                    {/*<div className="document-details">*/}
                    {/*    <div className="document-text">This will take a few minutes</div>*/}
                    {/*</div>*/}
                </div>
            </Modal>
        </SubmitDocsWrapper>
    );
};

export default SubmitDocsModal;
