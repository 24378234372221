import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Form, Input, Modal, notification, Row } from 'antd';
import style from './styles';
import { authenticatedRequest } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import { actions } from '../../authentication/sign-in/store/actions';
import { post_disable_two_factor } from '../api';

const DisableTwoFactorAuthenticationWrapper = styled.div`
    ${style}
`;

interface Props {
    visible: boolean;
    handleCancel: () => void;
    setTwoStep: (value) => void;
}

const DisableTwoFactorAuthentication = ({ visible, handleCancel, setTwoStep }: Props) => {
    const dispatch = useDispatch();
    const [disablingTwoFactor, setDisablingTwoFactor] = useState(false);
    const auth: any = useSelector((state: RootState) => state.auth);

    const userId = auth?.user?.userId;
    const [type, setType] = useState(null);
    const selectType = (val) => {
        setType(val);
    };

    const onFinish = (values) => {
        const url =
            type != 'back-up-codes'
                ? `${post_disable_two_factor}/${userId}`
                : `${post_disable_two_factor}/${userId}?type=back-up-code`;
        setDisablingTwoFactor(true);
        authenticatedRequest()
            .post(url, values)
            .then(() => {
                setDisablingTwoFactor(false);
                notification.success({
                    description: 'Success',
                    message: 'Two factor has been disabled for your account',
                });
                const data = Object.assign({}, auth.user);
                data.user.twoStep = false;
                dispatch(actions.updateTwoStep(data));
                setTwoStep(false);
                handleCancel();
            })
            .catch((error) => {
                setDisablingTwoFactor(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <DisableTwoFactorAuthenticationWrapper>
            <Modal
                title="Disable Two Factor Authentication"
                transitionName=""
                visible={visible}
                onCancel={handleCancel}
                footer={false}
                width="30%"
            >
                <p>To disable 2FA, enter the 6-digit verification code generated by your authenticator app below. </p>
                <Form name="Disable-2FA" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="code"
                        label={type != 'back-up-codes' ? 'Enter 6 digit code' : 'Enter back up code'}
                        rules={[
                            { required: true, message: 'Please input the TOTP generated by the authentication app!' },
                        ]}
                    >
                        <Input className="gapstack-input" />
                    </Form.Item>

                    {type != 'back-up-codes' ? (
                        <div className="use-code-button-container">
                            <Button type="link" className="button-forgot" onClick={() => selectType('back-up-codes')}>
                                Use Back Up Codes
                            </Button>
                        </div>
                    ) : (
                        <Row className="use-code-button-container">
                            <Button type="link" className="button-forgot" onClick={() => selectType(null)}>
                                Use 6 digit code
                            </Button>
                        </Row>
                    )}
                    <Divider type="horizontal" />

                    <Row className="button-container">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="gapstack-button"
                            loading={disablingTwoFactor}
                        >
                            Disable
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </DisableTwoFactorAuthenticationWrapper>
    );
};

export default DisableTwoFactorAuthentication;
