import { Card, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;

const STKCharges = () => {
    return (
        <BusinessProfileWrapper>
            <Card
                title="Payment (CR12 Search Charges -  KES. 650.00)"
                className="card-wrapper card-margin-top"
                style={{ paddingBottom: 60 }}
            >
                <div className="heading stk-heading">
                    Enter the mobile number you wish to pay from and we will send you an STK push.
                </div>
                <Row>
                    <Col span={12}>
                        <PhoneNumber countryFieldName="countryCode" inputFieldName="phone" />
                    </Col>
                </Row>
            </Card>
        </BusinessProfileWrapper>
    );
};

export default STKCharges;
