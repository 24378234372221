import React from 'react';
import { useSelector } from 'react-redux';

import Roles from './Roles';
import { checkRoutePermission } from './checkRoutePermission';
import Disabled from './disabled';

export type RolesType = keyof typeof Roles;

interface Props {
    allowedRoles?: RolesType | RolesType[];
    restrictedRoles?: RolesType | RolesType[];
    renderNoAccess?: () => React.ReactNode;
    children?: React.ReactNode;
    disabled?: boolean;
}

type AuthorizationWrapperType = React.FunctionComponent<Props> & {
    Roles: { [key in RolesType]: string };
};

const defaultProps: Props = {
    allowedRoles: [],
    restrictedRoles: [],
};

export function checkPermittedRoles(userRoles: RolesType | RolesType[], allowedRoles: RolesType[]): boolean {
    if (allowedRoles.length === 0) return true;
    const _userRoles = Array.isArray(userRoles) ? userRoles : [userRoles];
    return _userRoles.some((role) => allowedRoles.includes(role));
}

export function checkRestrictedRoles(userRoles: RolesType | RolesType[], restrictedRoles: RolesType[]): boolean {
    if (restrictedRoles.length === 0) return false;
    const _userRoles = Array.isArray(userRoles) ? userRoles : [userRoles];
    return _userRoles.some((role) => restrictedRoles.includes(role));
}

const AuthorizationWrapper: AuthorizationWrapperType = (props: Props): any => {
    const { allowedRoles, renderNoAccess, restrictedRoles, children, disabled } = props;
    if (!allowedRoles && !restrictedRoles) return children;
    const user: any = useSelector<any>((state) => state.auth.user);

    const canAccess = checkRoutePermission(user, {
        restrictedRoles: restrictedRoles || [],
        allowedRoles: allowedRoles || [],
    });
    if (canAccess) return children;

    if (disabled) return <Disabled>{children}</Disabled>;

    return renderNoAccess ? renderNoAccess() : null;

    // if (restrictedRoles && restrictedRoles.length > 0) {
    //     console.log(props);
    //     const _restrictedRoles: RolesType[] = Array.isArray(restrictedRoles) ? restrictedRoles : [restrictedRoles];
    //
    //     const restricted: boolean = checkRestrictedRoles(userRoles, _restrictedRoles);
    //
    //     if (restricted) return renderNoAccess ? renderNoAccess() : <span />;
    // } else if (allowedRoles && allowedRoles.length > 0) {
    //     const _allowedRoles: RolesType[] = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
    //
    //     const permitted: boolean = checkPermittedRoles(userRoles, _allowedRoles);
    //
    //     return permitted ? children : renderNoAccess ? renderNoAccess() : null;
    // }
    // return children;

    // if (allowedRoles.length === 0 && restrictedRoles.length === 0) return children;

    // const userRoles: RolesType | RolesType[] = state.roles || ['Administrator'];

    // const _restrictedRoles: RolesType[] = Array.isArray(restrictedRoles) ? restrictedRoles : [restrictedRoles];

    // const restricted: boolean = checkRestrictedRoles(userRoles, _restrictedRoles);

    // if (restricted) renderNoAccess ? renderNoAccess() : null;

    // const _allowedRoles: RolesType[] = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];
    //
    // const permitted: boolean = checkPermittedRoles(userRoles, _allowedRoles);
    //
    // return permitted ? children : renderNoAccess ? renderNoAccess() : null;
};

AuthorizationWrapper.Roles = Roles;

AuthorizationWrapper.defaultProps = defaultProps;

export default AuthorizationWrapper;
