import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import SelectBusinessType from '../../../../utils/FormSelects/BusinessTypes';
import SelectIndustry from '../../../../utils/FormSelects/Industries';
import { CardName } from '../../types';
// import { PlacesField } from '../../../helpers';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;

interface BusinessInformationProps {
    form: FormInstance;
    onComplete: (next: boolean, cardName: CardName) => void;
}

const BusinessInformation: FunctionComponent<BusinessInformationProps> = ({
    form,
    onComplete,
}: BusinessInformationProps) => {
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(['business'], false)) {
                onComplete(false, CardName.BUSINESS_INFORMATION);
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);
    return (
        <BusinessProfileWrapper>
            <Card title="Business Information" className="card-wrapper card-margin-top">
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label="Business Name"
                            name={['business', 'businessName']}
                            className="label"
                            rules={[{ required: true, message: 'Please input your business name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="Crowdstage Enterprises" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Trading Name"
                            name={['business', 'tradingName']}
                            className="label"
                            rules={[{ required: true, message: 'Please input trading name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="Crowdstage Enterprises LLC." />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <SelectBusinessType
                            formItemLabel="Business Type"
                            formItemName={['business', 'businessTypeId']}
                            required
                            onChange={() => true}
                            message="Please select business type"
                            valueType="value"
                        />
                    </Col>
                    <Col span={12}>
                        <SelectIndustry
                            formItemLabel="Industry(What does your business do?)"
                            formItemName={['business', 'industryId']}
                            required
                            onChange={() => true}
                            valueType="value"
                            message="Please select industry type"
                        />
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label="PIN Number"
                            name={['business', 'pinNumber']}
                            className="label"
                            rules={[{ required: true, message: 'Please input your PIN number!' }]}
                        >
                            <Input className="gapstack-input" placeholder="9049034" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="VAT Number (Optional)"
                            name={['business', 'vatNumber']}
                            className="label"
                            rules={[{ required: false, message: 'Please input your VAT number!' }]}
                        >
                            <Input className="gapstack-input" placeholder="9049034" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={20}>
                    <Col span={24}>
                        <PlacesField form={form} name="gps" />
                    </Col>
                </Row> */}
            </Card>
        </BusinessProfileWrapper>
    );
};

export default BusinessInformation;
