import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import InMemoryJWTManager from '../utils/InMemoryJWT';
import { ROUTES } from './urls';
import { COMPONENTS } from './components';
import {
    AuthenticatedRoutes,
    onlyBankRoutes,
    onlyBusinessRoutes,
    UnAuthenticatedBankRoutes,
    UnAuthenticatedRoutes,
} from './routes';
import AuthorizedRoute from '../Authorization/AuthorizedRoute';
import Loader from '../utils/Loader';
import { getUser, Users } from '../config';
import NotFound from '../utils/NotFound';
import _baseDifference from 'lodash/difference';
import { getDisabledPaths } from '../components/Layouts/Authenticated/components/sidebar/data';
import CookiesDisabled from '../components/authentication/cookies-disabled';

const RequireAuth = ({ children }: any) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [twoFactor, setTwoFactor] = useState(false);

    useEffect(() => {
        const x = InMemoryJWTManager.checkForLogout();
        if (x) {
            setAuthenticated(false);
            setLoading(false);
        } else {
            const checkAuth = async () => {
                setLoading(true);
                if (!InMemoryJWTManager.getToken() && !InMemoryJWTManager.getTwoFactorToken()) {
                    await InMemoryJWTManager.getRefreshedToken()
                        .then((res) => {
                            setAuthenticated(res);
                            setLoading(false);
                        })
                        .catch(() => {
                            setAuthenticated(false);
                            setLoading(false);
                        });
                } else if (InMemoryJWTManager.getTwoFactorToken()) {
                    setLoading(false);
                    setTwoFactor(true);
                } else {
                    setAuthenticated(true);
                    setLoading(false);
                }
            };

            checkAuth();
        }
    }, []);

    if (!loading && authenticated) {
        return children;
    }

    if (!loading && twoFactor) {
        return children;
    }

    if (!loading && !authenticated) {
        return <Redirect to={ROUTES.SIGN_IN} />;
    }

    return <Loader />;
};

const AppRouter = () => {
    const isBank = getUser() == Users.BANK;
    const UnAuthRoutes = isBank ? UnAuthenticatedBankRoutes : UnAuthenticatedRoutes;
    const differenceUnAuthRoutes = isBank
        ? _baseDifference(UnAuthenticatedRoutes, UnAuthenticatedBankRoutes)
        : _baseDifference(UnAuthenticatedBankRoutes, UnAuthenticatedRoutes);

    const AuthRoutes = isBank
        ? [...AuthenticatedRoutes, ...onlyBankRoutes]
        : [...AuthenticatedRoutes, ...onlyBusinessRoutes];
    const differenceAuthRoutes = isBank ? onlyBusinessRoutes : onlyBankRoutes;

    if (!navigator.cookieEnabled) {
        return <Route component={CookiesDisabled} />;
    }

    return (
        <Switch>
            <Route exact path="/" component={isBank ? COMPONENTS.SIGN_IN : COMPONENTS.LANDING_PAGE} />
            {/* Unauthenticated routes of bank not be accessed by business or vice-versa */}
            {differenceUnAuthRoutes.map((route) => (
                <Route exact path={ROUTES[route]} component={NotFound} key={route} />
            ))}
            {UnAuthRoutes.map((route) => (
                <Route exact path={ROUTES[route]} component={COMPONENTS[route]} key={route} />
            ))}

            <RequireAuth>
                {/* Paths disabled by env */}
                {getDisabledPaths(getUser()).map((route) => (
                    <Route exact path={ROUTES[route]} component={NotFound} key={route} />
                ))}

                {/* Authenticated routes of bank not be accessed by business or vice-versa */}
                {differenceAuthRoutes.map((route) => (
                    <Route exact path={ROUTES[route]} component={NotFound} key={route} />
                ))}
                {AuthRoutes.map((route) => (
                    <AuthorizedRoute routeName={route} key={route} />
                ))}
            </RequireAuth>
            <Route component={NotFound} />
        </Switch>
    );
};

export default AppRouter;
