import React from 'react';

import VerifyPhoneComponent from './VerifyPhoneComponent';
import { ROUTES } from '../../../router/urls';
import { appIcon } from '../../../config/appIcon';

const VerifyPhone = (props: any) => {
    return <VerifyPhoneComponent {...props} signInPath={ROUTES.SIGN_IN} logo={appIcon} />;
};

export default VerifyPhone;
