import React from 'react';
import styled from 'styled-components';
import style from './styles';

const StatusTagWrapper = styled.div`
    ${style}
`;
interface Props {
    value: string;
    color: string;
}

const StatusTag = ({ value, color }: Props) => {
    return (
        <StatusTagWrapper color={color}>
            <div className="status-tag">
                <span className="text">{value}</span>
            </div>
        </StatusTagWrapper>
    );
};

export default StatusTag;
