import React from 'react';
import styled from 'styled-components';
// import { useHistory } from 'react-router-dom';
import { Row, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import style from '../styles';
import { columns_list } from './data';
import TrackerLayout from './trackerLayout';
// import { authenticatedRequest } from '../../../api';
// import { goToRoute, ROUTES } from '../../../router/urls';
import { Header, Footer } from '../../../utils/Table/table-components';

import tableData from './data.json';
import { COLORS, generateTheme } from '../../../config/theme';

const TrackerWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ color: `${generateTheme()[COLORS.PRIMARY]}` }} spin />;

const Tracker = () => {
    // const history = useHistory();

    const [loading] = React.useState<boolean>(false);
    const [data] = React.useState<any[]>([...tableData]);
    const [pageSize, setPageSize] = React.useState(10);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [total] = React.useState<number>(0);
    const [selectedRowKeys] = React.useState([]);

    const rowSelection = {
        selectedRowKeys,
    };

    const onSearch = () => {
        // console.log(value);
    };

    // const fetchTrackers = () => {
    //     setLoading(true);
    //     let url = '';
    //     if (currentPage && pageSize) {
    //         url += `?page=${currentPage}&limit=${pageSize}`;
    //     }

    //     authenticatedRequest()
    //         .get(url)
    //         .then((res) => {
    //             setData(res.data.data);
    //             setTotal(res.data.total);
    //             setLoading(false);
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             const resp = err?.response;
    //             notification.error({
    //                 message: 'Error',
    //                 description: resp?.data?.message || 'Oops! An unexpected error occurred.',
    //             });
    //         });
    // };

    // React.useEffect(() => {
    //     fetchTrackers();
    // }, []);

    // React.useEffect(() => {
    //     fetchTrackers();
    // }, [pageSize, currentPage]);

    const onPageSizeChange = (size: number) => {
        setPageSize(size);
    };
    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <TrackerLayout>
            <TrackerWrapper>
                <Row className="products-container">
                    <Table
                        title={() => <Header handleChange={onSearch} />}
                        style={{ width: '100%' }}
                        rowSelection={rowSelection}
                        columns={columns_list}
                        dataSource={data}
                        onRow={(record: any) => ({
                            onClick: () => console.log(record),
                        })}
                        loading={{
                            spinning: loading,
                            indicator: antIcon,
                        }}
                        footer={() => (
                            <Footer
                                onPageChange={onPageChange}
                                setPageSize={onPageSizeChange}
                                pageSize={pageSize}
                                total={total}
                                currentPage={currentPage}
                            />
                        )}
                        pagination={false}
                        className="datatable"
                    />
                </Row>
            </TrackerWrapper>
        </TrackerLayout>
    );
};

export default Tracker;
