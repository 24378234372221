import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import style from './styles';
const AlertWrapper = styled.div`
    ${style}
`;
const DeleteAlert = ({ onClick, onCancel, loading }: any) => {
    return (
        <AlertWrapper>
            <div className="alert-wrapper">
                <div className="alert-text">Are you sure you want to delete the selected document ?</div>
                <div className="button-wrapper">
                    <Button className="gapstack-button no-button" onClick={onCancel}>
                        No
                    </Button>
                    <Button className="gapstack-button gapstack-primary-button" loading={loading} onClick={onClick}>
                        Yes
                    </Button>
                </div>
            </div>
        </AlertWrapper>
    );
};

export default DeleteAlert;
