export const titleCase = (str, separator = ' ') => {
    if (str === undefined || str === '' || str === null) return;
    return str
        .toLowerCase()
        .split(separator)
        .map(function (word) {
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
};

export const isUpperCase = (str) => {
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalized;
};
