import { css } from 'styled-components';
import { COLORS, DEVICE } from '../../../../config/theme';

const style = () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    flex-direction: column;
    @media ${DEVICE.laptop || DEVICE.laptopL || DEVICE.desktop || DEVICE.desktopL} {
        padding: 40px;
    }
    .icon {
        height: 80px;
        margin-bottom: 30px;
    }
    .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .description {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }
`;

export default style;
