import React from 'react';
import { Inbox, Send, FileText, Trash } from 'react-feather';
import styled from 'styled-components';

import style from './styles';
import { ComposeMessage } from '../create';

const MessagesSideBarWrapper = styled.div`
    ${style}
`;

const Icons = {
    inbox: <Inbox size="15" />,
    sent: <Send size="15" />,
    draft: <FileText size="15" />,
    trash: <Trash size="15" />,
};

interface Props {
    menuItems: any[];
    messages: any;
    onClick: (key: number) => void;
    active: string;
}

const MessagesSideBar = ({ active, menuItems, onClick, messages }: Props) => {
    return (
        <MessagesSideBarWrapper>
            <div className="sidebar-header">
                <ComposeMessage />
            </div>
            <div className="sidebar-body">
                {menuItems.map((item, key) => (
                    <div
                        className={item.dataIndex === active ? 'active' : ''}
                        key={item.dataIndex}
                        onClick={() => onClick(key)}
                        onKeyPress={(e) => e.key === 'Enter' && onClick(key)}
                        tabIndex={0}
                    >
                        {Icons?.[item.dataIndex]}
                        <span>{item.name}</span>
                        <span>{messages?.[item?.dataIndex]?.length}</span>
                    </div>
                ))}
            </div>
        </MessagesSideBarWrapper>
    );
};

export default MessagesSideBar;
