import React, { useState } from 'react';
import { Button, Col, Form, Modal, notification, Row } from 'antd';
import ModalTitle from '../components/title';
import ModalClose from '../components/close';
import { authenticatedRequest } from '../../../api';
import PasswordInput from '../../FormInputs/Password';
import { bank_change_password } from '../../../components/authentication/sign-in/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';

interface Props {
    userId: string;
    visible: boolean;
    onClose: (success) => void;
}
const ChangePassword = ({ userId, visible, onClose }: Props) => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const onFinish = (values) => {
        values['userId'] = userId;
        setLoading(true);
        authenticatedRequest({ 'x-bank-user-id': auth?.user?.userId })
            .post(bank_change_password, values)
            .then((res) => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: res?.data?.message || 'Password changed successfully',
                });
                onClose(true);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <Modal
            visible={visible}
            title={<ModalTitle title="Change Password" />}
            width="40%"
            footer={null}
            closeIcon={<ModalClose />}
            onCancel={() => onClose(false)}
            closable={false}
        >
            <p>Your password has expired, please change it to proceed</p>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={20}>
                    <Col span={24}>
                        <PasswordInput
                            required
                            formItemName="currentPassword"
                            formItemLabel="Current Password"
                            passwordType="password"
                            message="Please input your current password"
                            disableAutoFill
                            checkPasswordStrength={false}
                        />
                    </Col>
                    <Col span={24}>
                        <PasswordInput
                            required
                            formItemName="newPassword"
                            formItemLabel="New Password"
                            passwordType="password"
                            disableAutoFill
                        />
                    </Col>
                    <Col span={24}>
                        <PasswordInput
                            required
                            formItemName="confirmPassword"
                            formItemLabel="Confirm Password"
                            passwordType="confirmPassword"
                            message="Please confirm your password"
                            confirmPasswordOn="newPassword"
                            dependencies={['newPassword']}
                            disableAutoFill
                        />
                    </Col>
                    <Col>
                        <Button htmlType="submit" className="gapstack-button" loading={loading}>
                            Change Password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ChangePassword;
