import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = () => css`
    .container {
        flex: 1;
        padding-top: 5px;
    }
    .card-wrapper {
        .ant-card-body {
            padding: 0px 0px 10px 0px;
        }
    }

    .save-purchase {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 20px;
        .save-button {
            background: ${(props) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props) => props.theme[COLORS.PRIMARY]};
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            color: ${(props) => props.theme[COLORS.POP_UP]};
            margin-left: 10px;
            height: 45px;
            width: 150px;
        }
    }
`;

export default style();
