import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';
// import Bonds from './bonds/data';
// import BondsFinancing from './bond-financing/data';
// import ViewGuaranteeBond from './bonds/view-bond/components';
// import NewGuarantee from './bonds/add-new';

const BondsComponent = (props: any) => (
    <AuthenticatedLayout>
        <Bonds {...props} />
    </AuthenticatedLayout>
);
const Bonds = Loadable({
    loader: () => import('./bonds/table' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const BondsFinancingComponent = (props: any) => (
    <AuthenticatedLayout>
        <BondsFinancing {...props} />
    </AuthenticatedLayout>
);
const BondsFinancing = Loadable({
    loader: () => import('./bond-financing/table' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const ViewGuaranteeBondComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewGuaranteeBond {...props} />
    </AuthenticatedLayout>
);
const ViewGuaranteeBond = Loadable({
    loader: () => import('./bonds/view-bond/components' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const EditGuaranteeBondComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <EditGuaranteeBond {...props} />
    </AuthenticatedLayout>
);
const EditGuaranteeBond = Loadable({
    loader: () => import('./bonds/edit' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const NewGuaranteeComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <NewGuarantee {...props} />
    </AuthenticatedLayout>
);
const NewGuarantee = Loadable({
    loader: () => import('./bonds/add-new' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const CreateNewGuarantee = Loadable({
    loader: () => import('./bonds/add-new/create' /* webpackChunkName: "Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const CreateNewGuaranteeComponent = () => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <CreateNewGuarantee />
    </AuthenticatedLayout>
);

export {
    BondsComponent,
    ViewGuaranteeBondComponent,
    BondsFinancingComponent,
    NewGuaranteeComponent,
    EditGuaranteeBondComponent,
    CreateNewGuaranteeComponent,
    Bonds,
};
