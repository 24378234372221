import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';
const style = () => css`
    /* For pdf */
    .react-pdf__Page {
        margin-top: 10px;
    }
    .react-pdf__Page__textContent {
        //border: 1px solid darkgrey;
        //box-shadow: 5px 5px 5px 1px #ccc;
        //border-radius: 5px;
    }

    .react-pdf__Page__annotations.annotationLayer {
        padding: 0px;
    }

    .react-pdf__Page__canvas {
        //margin: 0 auto;
        border-radius: 5px;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
    }

    /* For all pages */
    .all-page-container {
        height: 100%;
        max-height: 500px;
        overflow: auto;
    }

    .ant-pagination-item {
        background-color: transparent;
    }
`;

export default style;
