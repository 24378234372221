import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const styles = () => css`
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 620px;
        max-width: 100%;
        overflow: hidden;
        background: ${(props) => props.theme[COLORS.POP_UP]};
        border: 0.5px solid ${(props) => props.theme[COLORS.BORDER]};
        box-shadow: ${(props) => props.theme[COLORS.CARD_SHADOW]};
        border-radius: 5px;
        padding: 20px;
        .heading {
            justify-content: flex-start;
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }
        .divider {
            background: ${(props) => props.theme[COLORS.PRIMARY]};
            border: none;
            height: 3px;
            max-width: 100px;
            min-width: 100px;
            width: 100px;
            border-radius: 2px;
            margin: 20px 0px 10px;
        }
        .normal-text {
            font-size: 13px;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-top: 10px;
            font-weight: 400;
            .resend-code {
                color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                cursor: pointer;
                text-decoration: underline;
            }
        }
        .heading-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            .create-pin {
                font-size: 13px;
                font-weight: 700;
                margin-right: 10px;
            }
            .line {
                flex: 1;
                height: 1px;
                width: 100%;
                background: ${(props) => props.theme[COLORS.BORDER]};
                margin-top: 10px;
            }
        }
        .divider-2 {
            background: ${(props) => props.theme[COLORS.BORDER]};
            border: none;
            height: 1px;
            max-width: 120%;
            min-width: 120%;
            width: 120%;
            margin: 20px 0px 20px -20px;
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            .gapstack-button {
                margin-left: auto;
            }
        }
    }
`;
export default styles();
