import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    padding: 5px 0px;
    .header-table {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 15px;
        .add-product-button {
            height: 35px;
            width: 137px;
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border: none;
            box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            .icon {
                width: 14px;
                margin-right: 5px;
                color: ${(props: any) => props.theme[COLORS.ICONS]};
            }
            span {
                font-size: 13px;
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                font-weight: 600;
            }
        }
    }

    .datatable .ant-table-tbody > tr:nth-child(even) {
        background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
    }
    .datatable .ant-table-tbody > tr {
        cursor: pointer;
    }
    .datatable .ant-table-pagination.ant-pagination {
        margin: 5px !important;
        box-shadow: none !important;
        padding: 15px;
    }
    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;
        .select-wrapper {
            span {
                font-size: 13px;
                margin: 0px 10px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                line-height: 40px !important;
            }
            .ant-select-selector {
                border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
                border-radius: 5px;
                height: 40px;
                width: 100px;
            }
            .ant-select-selection-item {
                color: ${(props: any) => props.theme[COLORS.ICONS]};
            }

            .ant-select-arrow {
                margin-top: 0px;
                top: 14%;
            }
        }
        .footer-span {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-left: auto;
        }
        .ant-pagination {
            margin: 5px !important;
            box-shadow: none !important;
        }
    }
    .edit-icon {
        position: relative;
        left: -3px;
        top: 2px;
        margin-right: 5px;
        cursor: pointer;
        color: ${(props: any) => props.theme[COLORS.EDIT_BUTTON]};
    }

    .datatable {
        margin-top: 0;
    }
    .example {
        text-align: center;
        background: ${(props: any) => props.theme[COLORS.SECONDARY_BACKGROUND]};
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 30px 50px;
        margin: 20px 0;
    }
    .ant-checkbox-wrapper {
        margin-bottom: 0 !important;
    }
    .status {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .circle-rh {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
`;

export default style;
