import AppIcons from '../assets/app-icons';
import { getTheme } from './getTheme';

function appIconInternal() {
    try {
        let themeName = getTheme('default');

        if (AppIcons[themeName.concat('-landing')]) {
            themeName = themeName.concat('-landing');
        } else if (!AppIcons[themeName]) {
            themeName = 'default';
        }

        return AppIcons[themeName];
    } catch (e) {
        return import('../assets/Images/app-merchant.webp');
    }
}
export const landingAppImage = appIconInternal();

export default landingAppImage;
