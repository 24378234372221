import { css } from 'styled-components';

const style = () => css`
    h6 {
        font-size: 13px;
        line-height: 20px;
        color: #6c7392;
        margin-left: -10px;
        padding: 20px 0px;
    }
    .business-container {
        background: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding-bottom: 50px;
        .business-title {
            margin-top: 30px;
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            padding-left: 20px;

            h4 {
                font-weight: 600;
                font-size: 20px;
                line-height: 31px;
                color: #39426a;
                margin: 0;
            }
        }

        .menu-name {
            padding-left: 20px !important;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #1e87f0;
            display: flex;
            margin: 0;
            height: auto;
            align-items: center;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .ant-divider-horizontal {
        margin: 0 0 24px 0;
    }
    .header-icon {
        position: relative;
        padding-right: 10px;
    }
`;

export default style;
