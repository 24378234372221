import { css } from 'styled-components';
import { DEVICE } from '../../../config/theme';
import { Constant } from '../../../config';
import landingAppImage from '../../../config/landingAppImage';

const style = () => css`
    .container {
        flex: 1;
        height: 100%;
        @media ${DEVICE.laptop} {
            height: 100vh;
        }
        @media ${DEVICE.laptopL} {
            height: 100vh;
        }
        @media ${DEVICE.desktop} {
            height: 100vh;
        }
        background: url(${landingAppImage}) center;
        background-size: cover;
        position: relative;
        .overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            padding-top: 160px;
            background: ${Constant.landingPageBackground};
        }
        .top-bar {
            display: flex;
            align-items: center;
            min-height: 80px;
            border-bottom: 1px solid #f4f8f9;
            img {
                height: ${Constant.logoHeight};
                width: ${Constant.logoWidth};
                cursor: pointer;
            }
        }
        .content {
            .landing-title-container {
                padding: 0px 20px;
                margin-top: 50px;
                @media ${DEVICE.laptop} {
                    padding: 0px;
                    margin-top: 150px;
                }
                @media ${DEVICE.laptopL} {
                    padding: 0px;
                    margin-top: 150px;
                }
                @media ${DEVICE.desktop} {
                    padding: 0px;
                    margin-top: 150px;
                }
                .title {
                    font-weight: 600;
                    font-size: 42px;
                    line-height: 50px;
                    color: #ffffff;
                }
                .verify-bond-title {
                    font-weight: 800;
                    font-size: 43px;
                    line-height: 50px;
                    text-align: center;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                }
                @media ${DEVICE.laptop} {
                    font-size: 60px;
                    line-height: 40px;
                }
                @media ${DEVICE.laptopL} {
                    font-size: 60px;
                    line-height: 40px;
                }
                @media ${DEVICE.desktop} {
                    font-size: 60px;
                    line-height: 40px;
                }
            }
            .button-container {
                justify-content: flex-start;
                margin: 0px 20px 10px 20px;
                @media ${DEVICE.laptop} {
                    margin: 0px;
                    justify-content: center;
                }
                @media ${DEVICE.desktop} {
                    margin: 0px;
                    justify-content: center;
                }
                @media ${DEVICE.laptopL} {
                    margin: 0px;
                    justify-content: center;
                }
            }
            .signin-form-container {
                padding-top: 50px;
                @media ${DEVICE.laptop} {
                    padding-top: 120px;
                }
                @media ${DEVICE.laptopL} {
                    padding-top: 120px;
                }
                @media ${DEVICE.desktop} {
                    padding-top: 120px;
                }
            }
        }
    }

    .content-container {
        display: flex;
        margin-bottom: 15px;
        .icon-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .icon {
                position: relative;
                .round-container {
                    position: absolute;
                    top: 6px;
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    opacity: 0.25;
                }
            }
        }
        .item-container {
            flex: 5;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .title {
                color: #39426a;
                margin-bottom: 0;
            }
            .description {
                color: #a3a3b4;
                margin-bottom: 0;
            }
        }
    }

    .ant-popover-content {
        width: 580px !important;
    }
`;

export default style;
