import { FunctionComponent } from 'react';

export const getUser: () => Users = () => {
    try {
        let user = (process.env.REACT_APP_USER as Users) || Users.BANK;

        if (process.env.NODE_ENV === 'development') {
            user = (localStorage?.getItem('REACT_APP_USER_TYPE') as Users) || user;
        }

        return user;
    } catch (e) {
        console.log('Can not access local storage');
        return Users.BUSINESS;
    }
};

export enum Users {
    BANK = 'BANK',
    BUSINESS = 'BUSINESS',
}

interface Props {
    children: any;
}

export const BankWrapper: FunctionComponent<Props> = ({ children }: Props) => {
    if (getUser() == Users.BANK) {
        return children;
    } else {
        return null;
    }
};

export const BusinessWrapper: FunctionComponent<Props> = ({ children }: Props) => {
    if (getUser() == Users.BUSINESS) {
        return children;
    } else {
        return null;
    }
};
