import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Divider, Row, Col, Select, notification, Input, Checkbox } from 'antd';
import { authenticatedRequest } from '../../../../api';
import {
    add_terms_and_condition_docs,
    update_terms_and_condition_docs,
} from '../../../../apis/business-accounts/kyc_settings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
import { useForm } from 'antd/es/form/Form';
import { ChevronDown, X } from 'react-feather';
import FormUploads from '../../../../utils/FormUploads';

const ModalComponent = ({ fetchData, setShowModal, value, label, showModal, editData, setEditData }: any) => {
    const [loading, setLoading] = useState<boolean>(false);

    const [form] = useForm();
    const auth: any = useSelector((state: RootState) => state.auth);

    const onFinish = (values: any) => {
        const submitObj: any = {
            ...values,
            isRequired: values?.isRequired ?? false,
        };
        if (submitObj.documentURL) {
            let imgURL;
            submitObj.documentURL.map((val) => {
                imgURL = val.response.downloadURl;
            });
            submitObj.documentURL = imgURL;
        } else if (!!editData?.id) {
            submitObj.documentURL = editData?.documentURL;
        }
        setLoading(true);
        let request: any;
        if (editData?.id) {
            delete submitObj?.name;
            request = authenticatedRequest({
                'x-bank-user-id': auth?.user?.userId,
            })
                .put(update_terms_and_condition_docs(editData?.id), submitObj)
                .then((res) => res.data);
        } else {
            request = authenticatedRequest({
                'x-bank-user-id': auth?.user?.userId,
            })
                .post(add_terms_and_condition_docs, { ...submitObj, businessType: label?.toString() })
                .then((res) => res.data);
        }
        request
            .then(() => {
                setLoading(false);
                setShowModal(false);
                fetchData();
                setEditData({});
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        Object.values(editData).length > 0 &&
            form.setFieldsValue({
                isRequired: editData?.isRequired,
                name: editData?.name,
                description: editData?.description,
                physicalDocumentRequired: editData?.physicalDocumentRequired,
            });
    }, [editData]);

    const options = [
        { id: 1, value: 'Yes' },
        { id: 2, value: 'No' },
    ];

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <Modal
            title={editData?.id ? 'Update document' : 'Add new Document'}
            visible={showModal}
            onCancel={() => {
                setEditData({});
                setShowModal(false);
            }}
            footer={false}
            getContainer={document.getElementById(`card-wrapper-${value}`)}
            closeIcon={<X className="close-modal" />}
            width="40%"
            style={{ height: 'auto' }}
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish}>
                <Row gutter={20} style={{ alignItems: 'flex-end' }}>
                    <Col span={12}>
                        <Form.Item
                            label="Document Name"
                            name="name"
                            className="label"
                            rules={[{ required: true, message: 'Select document type' }]}
                        >
                            <Input className="gapstack-input" placeholder="Custom document" disabled={!!editData?.id} />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ height: '25px' }}>
                        <Form.Item
                            label=""
                            name="isRequired"
                            className="label"
                            valuePropName="checked"
                            rules={[{ required: false, message: 'Check if the document is required' }]}
                        >
                            <Checkbox>Is Required?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Form.Item
                            label="Label/Description"
                            name="description"
                            className="label"
                            rules={[{ required: false, message: 'Please add the label for the document' }]}
                        >
                            <Input.TextArea
                                className="gapstack-input"
                                autoSize={{ minRows: 4, maxRows: 6 }}
                                placeholder="e.g Upload the signed Title Deed"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={20} style={{ marginTop: 10 }}>
                    <Col span={24} className="upload-column">
                        <FormUploads
                            formItemName="documentURL"
                            label="Upload document"
                            initialValue={editData?.documentURL}
                        />
                    </Col>
                </Row>
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label="Physical Documents Needed?"
                            name="physicalDocumentRequired"
                            className="label"
                            rules={[{ required: true, message: 'Select if physical documents are required or not' }]}
                        >
                            <Select
                                className="select"
                                suffixIcon={<ChevronDown className="select-icon" />}
                                options={options}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider style={{ width: '120%', marginLeft: '-20px' }} />
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" loading={loading} htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
export { ModalComponent };
