import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = () => css`
    .top-bar-container {
        background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
        width: 100%;
        height: 52px;
        margin-top: 10px;

        .search-container {
            display: flex;
            flex: 1;
            align-items: center;

            .search-input {
                background: ${(props: any) => props.theme[COLORS.POP_UP]};
                box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
                border-radius: 5px;
                border: none;
                height: 35px;
                width: 80%;
                font-size: 13px;
                font-weight: normal;
                padding-left: 10px;
                input {
                    font: 400 13px 'sofia-pro', sans-serif !important;
                }
            }

            .search-icon {
                color: ${(props: any) => props.theme[COLORS.ICONS]};
                margin-right: 8px;
            }
        }

        .actions-container {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-end;

            .help-button {
                background: ${(props: any) => props.theme[COLORS.POP_UP]};
                box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
                border: none;
                border-radius: 5px;
                height: 35px;
                min-width: 140px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                img {
                    margin-right: 8px;
                }
                span {
                    font-size: 13px;
                    font-weight: normal;
                    color: ${(props: any) => props.theme[COLORS.PLACEHOLDER]};
                }
            }

            .icon-button {
                background: rgba(99, 113, 236, 0.3);
                border: none;
                margin-right: 15px;
                align-items: center;
                height: 22px;
                width: 22px;
                min-height: 22px;
                min-width: 22px;
                max-height: 22px;
                max-width: 22px;
                justify-content: center;
                display: flex;
                flex-direction: row;
                .icon {
                    height: 11px;
                    color: #6371ec;
                }
            }
            .icon-second {
                background: rgba(124, 96, 255, 0.3);
                .icon {
                    color: #7c60ff;
                }
            }
            .icon-third {
                background: rgba(30, 135, 240, 0.3);
                .icon {
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                }
            }

            .divider {
                height: 1.9em;
                border-left: 1px solid ${(props: any) => props.theme[COLORS.ICONS]};
                margin-right: 20px;
            }

            .user-container {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .username {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
            }
        }
        .red-dot {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: ${(props: any) => props.theme[COLORS.DECLINED]};
            position: absolute;
            top: -2px;
            right: -2px;
        }
    }
`;

export default style();
