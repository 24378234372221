import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import { check_account_number } from '../../../../apis/external-service';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;
interface ExistingAccountProps {
    cardName?: string;
    form: FormInstance;
}

const ExistingAccount: FunctionComponent<ExistingAccountProps> = ({ cardName, form }: ExistingAccountProps) => {
    const checkIfAccountExists = async (values, field) => {
        if (!values?.accountNumber || !values?.accountName) {
            return Promise.resolve();
        }
        const resp = await authenticatedRequest()
            .get(check_account_number(values?.accountNumber))
            .then((res) => res.data)
            .catch(() => {
                return Promise.resolve();
            });
        if (resp?.accountNumber) {
            if (field == 'accountName' && values?.accountName?.toLowerCase?.() != resp?.accountName?.toLowerCase?.()) {
                return Promise.reject(['Account name does not match']);
            }
            return Promise.resolve();
        } else if (field == 'accountNumber') {
            return Promise.reject(['Account number does not exist']);
        } else {
            return Promise.reject(['Account name does not match']);
        }
    };

    return (
        <BusinessProfileWrapper>
            <Card title={cardName || 'Account Details'} className="card-wrapper card-margin-top">
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            label={'Account Name'}
                            name={'accountName'}
                            className="label"
                            rules={[
                                { required: true, message: 'Please input account name!', validateTrigger: 'onChange' },
                                {
                                    validateTrigger: 'onSubmit',
                                    validator: () => checkIfAccountExists(form.getFieldsValue(), 'accountName'),
                                },
                            ]}
                        >
                            <Input className="gapstack-input" placeholder="John Doe" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'Account Number'}
                            name={'accountNumber'}
                            className="label"
                            rules={[
                                {
                                    validator: () => checkIfAccountExists(form.getFieldsValue(), 'accountNumber'),
                                    validateTrigger: 'onSubmit',
                                },
                                {
                                    required: true,
                                    message: 'Please input account number!',
                                    validateTrigger: 'onChange',
                                },
                            ]}
                        >
                            <Input className="gapstack-input" placeholder="9049034" />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </BusinessProfileWrapper>
    );
};

export default ExistingAccount;
