import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .ant-upload.ant-upload-drag {
        border-radius: 5px;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 24px 0px;
    }

    .upload-container {
        padding: 10px 0;
    }

    .upload-item {
        margin: 10px 0 0;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .ant-form-item-control {
            width: 100%;
        }
    }

    .form-label {
        font-size: 13px !important;
        font-weight: 400;
        color: ${(props) => props.theme[COLORS.PARAGRAPH]};
        margin-bottom: 0 !important;
        display: block;
        line-height: 22px;
    }

    .form-col {
        margin-top: 10px;
        padding: 0px 20px;
    }

    .drag-text {
        font-size: 13px;
        font-weight: 400;
        color: ${(props) => props.theme[COLORS.PARAGRAPH]};
        margin-left: 10px;
    }

    .drag-link {
        font-size: 13px;
        font-weight: 400;
        color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
        cursor: pointer;
    }
    .required label:after {
        color: #e74c3c;
        content: ' *';
        display: inline;
    }
    .required-label:after {
        color: #e74c3c;
        content: ' *';
        display: inline;
    }
`;

export default style;
