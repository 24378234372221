import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { style } from './styles';
import { Button, Divider, notification, Spin } from 'antd';
import close from '../../../../../assets/Images/close.svg';
import addCircleOutline from '../../../../../assets/Images/add-circle-outline.svg';
// import editOutline from '../../../../../assets/Images/edit-outline.svg';
import greenTick from '../../../../../assets/Images/green-tick.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { authenticatedRequest } from '../../../../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { getBusinessId, goToRoute, ROUTES } from '../../../../../router/urls';
import CreateBusinessModal from './createBusiness';
const BusinessDropdownWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#7C60FF' }} spin />;
interface Props {
    showDropDown: boolean;
    closeDropdown: () => void;
    businessId: string;
    email: string;
}
const BusinessDropdown = ({ showDropDown, closeDropdown, businessId, email }: Props) => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState<boolean>(true);
    const [businesses, setBusinesses] = useState<any[]>([]);
    const [showCreateBusiness, setShowCreateBusiness] = useState<boolean>(false);

    const fetchBusinesses = () => {
        authenticatedRequest({ 'x-user-id': auth?.user?.userId })
            .get('/authentication/users/business')
            .then((res) => {
                setBusinesses(res.data);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                const resp = err?.response;
                if (resp && resp?.data) {
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Oops! An unexpected error occurred.',
                    });
                }
            });
    };
    useEffect(() => {
        fetchBusinesses();
    }, []);

    const signInWithBusinessId = (businessId: string) => {
        window.localStorage.setItem(getBusinessId(), 'available');
        const isActiveWindow = localStorage.getItem(businessId);
        if (isActiveWindow) {
            window.open('', businessId);
        } else {
            const win = window.open(goToRoute(ROUTES.INITIALISE_ANOTHER_ACCOUNT).replace(':id', businessId), '_blank');
            win?.focus();
        }
        closeDropdown();
    };

    return (
        <BusinessDropdownWrapper showDropDown={showDropDown}>
            <div className="business-dropdown-container">
                {loading ? (
                    <Spin style={{ margin: '20px auto' }} indicator={antIcon} />
                ) : (
                    <>
                        <div className="container">
                            <div className="title-container">
                                <span className="title">Your Businesses</span>
                            </div>
                            <div className="action-container">
                                <Button type="link" icon={<img src={close} />} onClick={closeDropdown} />
                            </div>
                        </div>
                        <div className="business-container">
                            {businesses.map((business, id) => (
                                <div
                                    className={`business-item ${
                                        business.businessAccountId == businessId ? 'active' : ''
                                    }`}
                                    key={id}
                                    onClick={() => {
                                        if (business?.businessAccountId == businessId) {
                                            return;
                                        } else {
                                            signInWithBusinessId(business?.businessAccountId);
                                        }
                                    }}
                                >
                                    <span className="name">{business?.businessName}</span>
                                    {business?.businessAccountId == businessId && (
                                        <img className="tick" src={greenTick} />
                                    )}
                                </div>
                            ))}
                            <div className="business-item">
                                <Button type="link" className="link-button" onClick={() => setShowCreateBusiness(true)}>
                                    <img src={addCircleOutline} />
                                    Create a new business
                                </Button>
                            </div>
                        </div>
                        <Divider type="horizontal" style={{ margin: '10px 0px' }} />
                        <div className="account-container">
                            <span className="title">You are signed in as</span>&nbsp;
                            <span className="user-email">{email}</span>
                        </div>
                        {/* <div className="account-container" style={{ marginTop: 15 }}>
                            <Button type="link" className="link-button">
                                <img src={editOutline} />
                                Manage your businesses
                            </Button>
                        </div> */}
                        <Divider type="horizontal" style={{ margin: '10px 0px' }} />
                        <div>
                            <span className="terms-privacy">Terms</span>
                            <Divider type="vertical" />
                            <span className="terms-privacy">Privacy</span>
                        </div>
                    </>
                )}
            </div>
            {showCreateBusiness && (
                <CreateBusinessModal
                    showModal={showCreateBusiness}
                    hideModal={setShowCreateBusiness}
                    signInWithBusinessId={signInWithBusinessId}
                    fetchBusinesses={fetchBusinesses}
                />
            )}
        </BusinessDropdownWrapper>
    );
};

export default BusinessDropdown;
