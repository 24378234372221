import React, { useState } from 'react';
import { Input, Popover, Form } from 'antd';
import { Check, EyeOff, Eye } from 'react-feather';
import styled from 'styled-components';
import style from './styles';

const PasswordWrapper = styled.div`
    ${style}
`;

interface Props {
    formItemName: string;
    formItemLabel: string;
    passwordType: 'password' | 'confirmPassword';
    dependencies?: Array<string>;
    required?: boolean;
    message?: string;
    placeholder?: string;
    confirmPasswordOn?: string;
    disableAutoFill?: boolean;
    checkPasswordStrength?: boolean;
}

const PasswordInput = ({
    formItemName,
    formItemLabel,
    required = true,
    message = 'Please input your password!',
    dependencies = [],
    placeholder,
    passwordType,
    confirmPasswordOn = 'password',
    disableAutoFill = false,
    checkPasswordStrength = true,
}: Props) => {
    const [showPasswordTooltip, setShowPasswordTooltip] = useState<boolean>(false);
    const [containsUpperCaseLetter, setContainsUpperCaseLetter] = useState(false); // uppercase letter
    const [containsLowerCaseLetter, setContainsLowerCaseLetter] = useState(false); // lowercase letter
    const [containsNumber, setContainsNumber] = useState(false); // number
    const [containsSpecialCharacter, setContainsSpecialCharacter] = useState(false); // special character
    const [contains8Characters, setContainsMinimum8Characters] = useState(false); // min 8 characters

    const onPasswordInputChange = (value) => {
        const password = value.currentTarget.value;
        // const minMaxLength = new RegExp(`^[sS]{${process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH},32}$`);
        const minMaxLength = new RegExp(`^.{${process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH || 8},32}$`);
        const upperCaseLetter = /[A-Z]/;
        const lowerCaseLetter = /[a-z]/;
        const number = /[0-9]/;
        const specialCharacter = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

        setContainsMinimum8Characters(minMaxLength.test(password));
        setContainsUpperCaseLetter(upperCaseLetter.test(password));
        setContainsLowerCaseLetter(lowerCaseLetter.test(password));
        setContainsNumber(number.test(password));
        setContainsSpecialCharacter(specialCharacter.test(password));
    };

    const getPasswordStrength = () => {
        return !(
            !containsSpecialCharacter ||
            !containsNumber ||
            !contains8Characters ||
            !containsLowerCaseLetter ||
            !containsUpperCaseLetter
        );
    };

    const content = (
        <div>
            <p
                style={{
                    fontWeight: 'normal',
                    fontSize: 13,
                    color: '#39426A',
                }}
            >
                1. At least 1 upper case letter
                {containsUpperCaseLetter && <Check className="popup-icon" color="#2CCC71" size="20" strokeWidth="3" />}
            </p>
            <p
                style={{
                    fontWeight: 'normal',
                    fontSize: 13,
                    color: '#39426A',
                }}
            >
                2. At least 1 lower case letter
                {containsLowerCaseLetter && <Check className="popup-icon" color="#2CCC71" size="20" strokeWidth="3" />}
            </p>
            <p
                style={{
                    fontWeight: 'normal',
                    fontSize: 13,
                    color: '#39426A',
                }}
            >
                3. At least 1 number
                {containsNumber && <Check className="popup-icon" color="#2CCC71" size="20" strokeWidth="3" />}
            </p>
            <p
                style={{
                    fontWeight: 'normal',
                    fontSize: 13,
                    color: '#39426A',
                }}
            >
                4. At least 1 special character (eg., @*$)
                {containsSpecialCharacter && <Check className="popup-icon" color="#2CCC71" size="20" strokeWidth="3" />}
            </p>
            <p
                style={{
                    fontWeight: 'normal',
                    fontSize: 13,
                    color: '#39426A',
                    marginBottom: 0,
                }}
            >
                5. At least {process.env.REACT_APP_MINIMUM_PASSWORD_LENGTH || 8} total characters
                {contains8Characters && <Check className="popup-icon" color="#2CCC71" size="20" strokeWidth="3" />}
            </p>
        </div>
    );

    return (
        <PasswordWrapper>
            <Popover
                trigger="focus"
                visible={passwordType === 'password' && checkPasswordStrength ? showPasswordTooltip : false}
                placement="leftTop"
                content={content}
                className="popup"
            >
                <Form.Item
                    label={formItemLabel}
                    name={formItemName}
                    className="label"
                    dependencies={dependencies}
                    rules={
                        passwordType === 'password'
                            ? [
                                  { required, message },
                                  () => ({
                                      validator(_rule, value) {
                                          if (!checkPasswordStrength) return Promise.resolve();
                                          // check password strength
                                          if (value && getPasswordStrength()) {
                                              return Promise.resolve();
                                          }
                                          return Promise.reject('Password is not strong!');
                                      },
                                  }),
                              ]
                            : [
                                  { required, message },
                                  ({ getFieldValue }) => ({
                                      validator(_rule, value) {
                                          if (!value || getFieldValue(confirmPasswordOn) === value) {
                                              return Promise.resolve();
                                          }
                                          return Promise.reject('The two passwords that you entered do not match!');
                                      },
                                  }),
                              ]
                    }
                >
                    <Input.Password
                        onChange={onPasswordInputChange}
                        className="gapstack-input"
                        placeholder={placeholder}
                        onFocus={() => setShowPasswordTooltip(true)}
                        onBlur={() => setShowPasswordTooltip(false)}
                        iconRender={(visible) => (visible ? <Eye size="14px" /> : <EyeOff size="14px" />)}
                        autoComplete={disableAutoFill ? 'new-password' : undefined}
                    />
                </Form.Item>
            </Popover>
        </PasswordWrapper>
    );
};

export default PasswordInput;
