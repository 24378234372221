import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .search-container {
        display: flex;
        flex: 1;
        align-items: center;

        .search-input {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
            border-radius: 5px;
            height: 35px;
            width: 237px;
            margin-right: 15px;
            input {
                font: 400 13px 'sofia-pro', sans-serif !important;
            }
            &:hover {
                border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            }
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        }
        .search-icon {
            color: ${(props: any) => props.theme[COLORS.ICONS]};
            margin-right: 5px;
        }
    }

    .export {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: 20px;
        align-self: center;
    }

    .filter-table {
        height: 35px;
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border: none;
        box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 96px;
        cursor: pointer;
        &:hover {
            border: 0.5px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        }
        img {
            width: 15px;
            height: 16px;
            margin-right: 10px;
        }
        // span {
        //     font-size: 13px;
        //     line-height: 20px;
        //     color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        //     font-weight: 400;
        // }
    }
    .left {
        margin-left: 0px !important;
    }
    .show-more {
        margin-left: auto;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        display: flex;
        align-items: center;
        font-size: 13px;
        cursor: pointer;
        .icon {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }
    }
`;

export default style();
