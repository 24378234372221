import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const paginationStyle = () => css`
    .ant-pagination {
        background: white;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        justify-content: flex-end;
        display: flex;
    }
    .ant-pagination-item {
        border: none;
        min-width: auto;
        padding: 0px;
        line-height: 34px;
        a {
            padding: 0px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        &.ant-pagination-item-active {
            a {
                color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            }
        }
    }
    .ant-pagination-options {
        display: none;
    }
`;

export default paginationStyle;
