import { getTheme } from '../getTheme';

export default function getEnvTheme() {
    try {
        const themeName = getTheme();

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        return require(`./${themeName}.theme.json`);
    } catch (e) {
        return {};
    }
}
