import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const addMember = () => css`
    .add-member {
        max-width: 90vw;

        .ant-modal-footer .ant-btn-primary {
            padding: 10px 25px !important;
            background: ${(props: any) => props.theme[COLORS.PRIMARY]} !important;
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]} !important;
            box-sizing: border-box !important;
            border-radius: 5px !important;
            color: white !important;
        }
        .roles-column {
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]} !important;
        }

        .ant-modal-header {
            border-bottom: none !important;
        }

        .ant-modal-title {
            font-weight: 600 !important;
            font-size: 13px !important;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        }

        .ant-modal-body {
            padding: 0 !important;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .tag {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border: 0.5px solid ${(props: any) => props.theme[COLORS.BORDER]};
            box-sizing: border-box;
            border-radius: 5px;
            padding: 8px;
            margin: 0 24px;
        }
        .ant-checkbox + span {
            padding-left: 15px;
        }

        .ant-tag {
            background: rgba(57, 66, 106, 0.2) !important;
            border-radius: 5px !important;
            width: 189px !important;
            height: 25px !important;
        }

        .content {
            background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
            margin-top: 15px;
            table {
                border-collapse: collapse;
                width: 100%;
            }
            td,
            th {
                text-align: left;
                padding: 7px 15px;
            }
            tr {
                background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
                border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            }
            tr:nth-child(even) {
                background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
            }
            td {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }

        .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .alert-icon {
            position: relative;
            top: -5px;
        }
    }
`;

export default addMember;
