import React, { FunctionComponent } from 'react';
import { Form, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { DocType } from '../../../components/business-accounts/cards/business-documents/components/SIngleDirector';

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
    disabled?: boolean;
}

const { Option } = Select;

const BusinessTypeWrapper = styled.div`
    ${style}
`;

const SelectIdDocType: FunctionComponent<Props> = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    onChange,
    hasFeedback = false,
    disabled,
}: Props) => {
    return (
        <BusinessTypeWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className="label"
                initialValue={initialValue || DocType.NATIONAL_ID}
                rules={[{ required, message }]}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    className="form-select select"
                    placeholder="National ID"
                    suffixIcon={() => <ChevronDown className="select-icon" />}
                    onChange={(e) => (onChange ? onChange(e, formItemName) : null)}
                    disabled={disabled}
                    // defaultValue={DocType.NATIONAL_ID}
                    data-testid="select-document"
                >
                    <Option value={DocType.NATIONAL_ID}>{DocType.NATIONAL_ID}</Option>
                    <Option value={DocType.PASSPORT}>{DocType.PASSPORT}</Option>
                </Select>
            </Form.Item>
        </BusinessTypeWrapper>
    );
};

export default SelectIdDocType;
