import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .description {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        text-align: center;
    }
    .content-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .action-container {
        margin-top: 10px;
    }
`;

export default style;
