import React from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { Offline } from 'react-detect-offline';
import style from './styles';
import HourClock from '../../assets/Images/hour-clock.svg';
const NetworkDetectorWrapper = styled.div`
    ${style}
`;

const NetworkDetector = () => {
    const onRefresh = () => {
        window.location.reload();
    };

    return (
        <Offline
            polling={{
                enabled: process.env.NODE_ENV == 'development' ? false : true,
                interval: 5000,
                timeout: 5000,
            }}
        >
            <Modal visible={false} footer={null} title={null} closable={false} closeIcon={false} centered>
                <NetworkDetectorWrapper>
                    <img src={HourClock} alt="Alert" width={30} />
                    <div className="content-container">
                        <p className="title">No Internet Connection</p>
                        <span className="description">Check your connection, then refresh the page.</span>
                    </div>
                    <div className="action-container">
                        <Button onClick={onRefresh} className="gapstack-button">
                            Refresh
                        </Button>
                    </div>
                </NetworkDetectorWrapper>
            </Modal>
        </Offline>
    );
};

export default NetworkDetector;
