import { transform, isEqual, isArray, isObject, isEmpty } from 'lodash';
import moment from 'moment';

export function updateObject(object, key: string | (number | string)[], value) {
    const result = object;
    const _key = Array.isArray(key) ? key : [key];
    let currentIndex = 0;
    const size = _key.length;
    while (currentIndex < size - 1) {
        if (object[_key[currentIndex]]) {
            if (typeof object[_key[currentIndex]] !== 'object') {
                object[_key[currentIndex]] = {};
            }
            object = object[_key[currentIndex]];
            ++currentIndex;
        } else {
            const K = _key[currentIndex + 1] as any;

            if (typeof K == 'number' || (typeof K == 'string' && (K as any) % 1 === 0)) {
                object[_key[currentIndex]] = [];
            } else {
                object[_key[currentIndex]] = {};
            }
        }
    }
    object[_key[size - 1]] = value;
    return result;
}

export function differenceObject(originalObject, newObject) {
    function changes(newObj, origObj) {
        let arrayIndexCounter = 0;
        return transform(newObj, function (result: any, value, key) {
            if (!isEqual(value, origObj[key])) {
                const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
                if (isObject(value) && isObject(origObj[key])) {
                    result[resultKey] = changes(value, origObj[key]);
                } else {
                    if (moment.isMoment(value) && moment(origObj[key]).isSame(value)) {
                    } else {
                        result[resultKey] = value;
                    }
                }
            }
        });
    }

    return changes(newObject, originalObject);
}

export function cleanObject(object) {
    return JSON.parse(
        JSON.stringify(object, function replaceUndefinedOrNull(_, value) {
            if (typeof value === 'number') {
                return value;
            }
            if (value === null || value === undefined || isEmpty(value)) {
                return undefined;
            }
            return value;
        }),
    );
}

export function getNestedObject(nestedObj, pathArr) {
    const name = Array.isArray(pathArr) ? pathArr : [pathArr];
    return name.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
}
