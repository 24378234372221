import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .ant-form-item {
        margin-bottom: 0px;
    }
    .auto-complete {
        input {
            background: #fafafa;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 5px;
            height: 45px;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        &:focus {
            background: #ffffff;
            box-shadow: none;
        }
        .ant-select-selector {
            width: 100%;
        }
    }
    .loading-icon {
        position: absolute;
        right: 10px;
        top: 15px;
    }
    .search-icon {
        position: absolute;
        left: 8px;
        top: 11px;
        width: 15px;
    }
`;
export default style;
