import React, { useEffect, useState } from 'react';
import { Button, Form, notification, Upload } from 'antd';
import upload from '../../assets/Images/cloud-upload.svg';
import style from './styles';
import styled from 'styled-components';
import { getBusinessId } from '../../router/urls';
import DownloadFile from '../DownloadFile';
import {
    DocumentName,
    DocumentType,
    DocumentPageType,
} from '../../components/business-accounts/cards/business-documents/types';
import { upload_url } from '../../apis/uploads';
import { useParams } from 'react-router';
import { getUser, Users } from '../../config';
import { DEFAULT_COLORS } from '../../config/defaultColors';

const UploadWrapper = styled.div`
    ${style}
`;

interface configType {
    documentName?: DocumentName;
    documentType?: DocumentType;
    pageType?: DocumentPageType;
    documentId?: string;
}
interface Props {
    formItemName: string | (number | string)[];
    label?: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    setUrl?: (val?: any) => void; // Returns URL
    readOnly?: boolean; // Disabled but can be downloaded
    documentConfig?: configType | any; // Config object to pass while uploading
    getResponse?: (val: any) => void; // Return full object of response
    disabled?: boolean;
    getFileStatus?: (status: boolean) => void; // File uploading state
    err?: string;
    height?: any; // Height of the image
    showImage?: boolean; // If the file type is image, show preview
    api?: string; // Upload to any other API than Env API
    fileName?: string; // File name to upload the file, default - document
    required?: boolean; //File is required
    message?: string; // Message if file is not selected
    businessAccountId?: string;
}

const FormUploads = ({
    formItemName,
    label,
    initialValue,
    validateStatus,
    setUrl,
    readOnly,
    documentConfig,
    getResponse,
    disabled,
    getFileStatus,
    err,
    height,
    showImage,
    api,
    fileName,
    required,
    message,
    businessAccountId,
}: Props) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    const [fileList, setFileList] = useState<any[]>([]);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [changeUploadedFile, setUploadedFile] = useState<boolean>(false);
    const [isFileUploading, setFileUploading] = useState<boolean>(false);

    const addFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const uploadFile = {
        name: fileName ?? 'document',
        action: api || upload_url,
    };

    const handleChange = (info) => {
        // info.file.response.response;
        let fileList = [...info.fileList];

        fileList = fileList.slice(-1);
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(fileList);

        if (fileList[0]?.response) {
            setFileUploading(false);
            getResponse?.(fileList[0]?.response);
            setUrl?.(fileList[0]?.response?.path || fileList[0]?.response?.downloadURl);
            setFileUploaded(true);
            setUploadedFile(false);
        } else {
            setFileUploaded(false);
            setUploadedFile(true);
        }
    };

    useEffect(() => {
        getFileStatus?.(isFileUploading);
    }, [isFileUploading]);

    const beforeUpload = (file) => {
        setFileUploading(true);
        const isLt25M = file.size / 1024 / 1024 < 25;
        if (!isLt25M) {
            setFileUploading(false);
            notification.error({
                message: 'Error',
                description: 'Document must smaller than 25 MB!',
            });
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (initialValue !== undefined) {
            setFileUploaded(true);
        } else {
            setFileUploaded(false);
        }
    }, [initialValue]);

    return (
        <UploadWrapper>
            {fileUploaded && !changeUploadedFile ? (
                <>
                    <label className={`form-label ${label?.includes('*') ? 'required-label' : ''}`}>
                        {label?.replaceAll('*', '')}
                    </label>
                    <DownloadFile
                        name={(fileList[0]?.response?.path || fileList[0]?.response?.downloadURl || initialValue)
                            ?.split('/')
                            ?.slice(-1)
                            ?.pop()
                            ?.split('-')
                            ?.pop()}
                        downloadFileLink={
                            fileList[0]?.response?.path || fileList[0]?.response?.downloadURl || initialValue
                        }
                        validateStatus={validateStatus}
                        err={err}
                        showImage={showImage}
                        height={height}
                        onClick={() => setUploadedFile(true)}
                        readOnly={readOnly}
                    />
                    <Form.Item name={formItemName} hidden />
                </>
            ) : (
                <>
                    <Form.Item label={label?.replaceAll('*', '')} className={label?.includes('*') ? 'required' : ''}>
                        <Form.Item
                            name={formItemName}
                            label={label?.replaceAll('*', '')}
                            valuePropName="file_list"
                            getValueFromEvent={addFile}
                            rules={[{ required: required ?? true, message: message ?? 'Please select file!' }]}
                            noStyle
                            className={label?.includes('*') ? 'required' : ''}
                        >
                            <Upload.Dragger
                                {...uploadFile}
                                fileList={fileList}
                                multiple={false}
                                onChange={handleChange}
                                headers={{
                                    'x-business-account-id': businessAccountId
                                        ? businessAccountId
                                        : isBank
                                        ? businessId
                                        : getBusinessId(),
                                }}
                                beforeUpload={beforeUpload}
                                data={() => {
                                    return {
                                        ...documentConfig,
                                    };
                                }}
                                disabled={disabled}
                                style={{
                                    minHeight: height,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={upload} alt="upload" width={25} style={{ marginRight: 10 }} />
                                <span className="drag-text">
                                    Drag your file here or <span className="drag-link">browse</span> to upload
                                </span>
                                {initialValue || fileList[0]?.response?.path || fileList[0]?.response?.downloadURl ? (
                                    <Button
                                        type="link"
                                        style={{
                                            paddingLeft: 0,
                                            color: DEFAULT_COLORS.DECLINED,
                                            display: 'block',
                                            margin: 'auto',
                                        }}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setUploadedFile(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                ) : null}
                            </Upload.Dragger>
                        </Form.Item>
                    </Form.Item>
                </>
            )}
        </UploadWrapper>
    );
};

export default FormUploads;
