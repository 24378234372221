import React from 'react';
import BraftEditor, { HooksType } from 'braft-editor';
import 'braft-editor/dist/index.css';

interface MessageInputProps {
    hooks?: HooksType;
    value?: any;
    onChange?: any;
}

class MessageInput extends React.Component<MessageInputProps, any> {
    state = {
        editorState: BraftEditor.createEditorState(this.props.value),
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(editorState) {
        const { onChange } = this.props;
        this.setState({ editorState, length: editorState.toText()?.trim().length }, () => {
            const data = Object.assign(editorState.toRAW(true), {
                text: editorState.toText(),
                html: editorState.toHTML(),
            });
            onChange?.(JSON.stringify(data));
        });
    }

    render() {
        const { editorState } = this.state;

        const controls: any = [
            'bold',
            'italic',
            'underline',
            'text-color',
            'list-ol',
            'text-indent',
            'link',
            'media',
            'emoji',
        ];

        return (
            <BraftEditor
                media={{
                    externals: {},
                }}
                value={editorState}
                language={'en'}
                extendControls={[]}
                controls={controls}
                className="gapstack-input"
                style={{ height: 'auto' }}
                contentStyle={{ height: '200px' }}
                onChange={this.handleChange}
                placeholder={'Compose message'}
                hooks={this.props.hooks}
            />
        );
    }
}
export default MessageInput;
