import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

// import Payments from './table';
// import MakePayments from './make-payments';
// import ReceivePayments from './receive-payments';
// import ViewPayments from './view-payments';
// import PayInvoice from './make-payments/new/pay-invoice';
// import PayBill from './make-payments/new/make-transfer';
// import RepayLoan from './make-payments/new/repay-loan';

const Payments = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});

const PaymentsComponent = (props: any) => (
    <AuthenticatedLayout>
        <Payments {...props} />
    </AuthenticatedLayout>
);

const MakePaymentsComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <MakePayments {...props} />
    </AuthenticatedLayout>
);

const MakePayments = Loadable({
    loader: () => import('./make-payments' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});
const ReceivePaymentsComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <ReceivePayments {...props} />
    </AuthenticatedLayout>
);
const ReceivePayments = Loadable({
    loader: () => import('./receive-payments' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});

const ViewPayments = Loadable({
    loader: () => import('./view-payments' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});
const ViewPaymentsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewPayments {...props} />
    </AuthenticatedLayout>
);
const PayInvoiceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <PayInvoice {...props} />
    </AuthenticatedLayout>
);
const PayInvoice = Loadable({
    loader: () => import('./make-payments/new/pay-invoice' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});
const PayBillComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <PayBill {...props} />
    </AuthenticatedLayout>
);
const PayBill = Loadable({
    loader: () => import('./make-payments/new/make-transfer' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});
const RepayLoanComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <RepayLoan {...props} />
    </AuthenticatedLayout>
);
const RepayLoan = Loadable({
    loader: () => import('./make-payments/new/repay-loan' /* webpackChunkName: "Payments" */),
    loading() {
        return <Loader />;
    },
});

export {
    PaymentsComponent,
    MakePaymentsComponent,
    ReceivePaymentsComponent,
    ViewPaymentsComponent,
    PayInvoiceComponent,
    PayBillComponent,
    RepayLoanComponent,
    Payments,
};
