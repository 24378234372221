import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const topBarStyles = () => css`
    .top-bar {
        margin: 30px 0px 20px;
        align-items: center;

        .back {
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
        }
        .heading {
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .close {
            margin-left: auto;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
`;

export default topBarStyles;
