import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import { FundingType } from '../fund-from-sales/types';
import AuthenticatedLayout from '../Layouts/Authenticated';

const FundMyPurchasesViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <FundMyPurchasesView {...props} />
    </AuthenticatedLayout>
);
const FundMyPurchasesView = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});

const ViewFinancingRequestComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewFinancingRequest title={'Fund My Purchases'} fundingType={FundingType.FundMyPurchase} {...props} />
    </AuthenticatedLayout>
);
const ViewFinancingRequest = Loadable({
    loader: () => import('./view-financing-request' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});

const GetFinancingComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <GetFinancing {...props} />
    </AuthenticatedLayout>
);
const GetFinancing = Loadable({
    loader: () => import('./add-new-financing' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});
const WorkingCapitalComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <WorkingCapital {...props} />
    </AuthenticatedLayout>
);
const WorkingCapital = Loadable({
    loader: () => import('./add-new-financing/working-capital' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});
const Distributor_EcosystemComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <Distributor_Ecosystem {...props} />
    </AuthenticatedLayout>
);
const Distributor_Ecosystem = Loadable({
    loader: () => import('./add-new-financing/distributor-ecosystem' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});
const FinanceInvoicingComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <FinanceInvoicing {...props} />
    </AuthenticatedLayout>
);
const FinanceInvoicing = Loadable({
    loader: () => import('./add-new-financing/finance-invoicing' /* webpackChunkName: "Fund-my-purchases" */),
    loading() {
        return <Loader />;
    },
});

export {
    FundMyPurchasesViewComponent,
    ViewFinancingRequestComponent,
    GetFinancingComponent,
    WorkingCapitalComponent,
    Distributor_EcosystemComponent,
    FinanceInvoicingComponent,
    FundMyPurchasesView,
};
