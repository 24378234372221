import React, { ReactNode } from 'react';
import style from '../styles';
import styled from 'styled-components';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const PurchasesWrapper = styled.div`
    ${style}
`;
interface IProps {
    children: ReactNode;
}
const PurchasesLayout = ({ children }: IProps) => {
    return (
        <AuthenticatedLayout>
            <PurchasesWrapper>{children}</PurchasesWrapper>
        </AuthenticatedLayout>
    );
};

export default PurchasesLayout;
