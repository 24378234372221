import React, { useEffect } from 'react';
import { Row, Col, Input, Select, Pagination } from 'antd';
import download from '../../assets/Images/download.svg';
import { Search, ChevronDown, ArrowRight } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import TableFilter from '../TableFilter';
import { LoadingOutlined } from '@ant-design/icons';
import useMediaQuery from '../ResponsiveTable/useMediaQuery';
import { DEVICE } from '../../config/theme';

const HeaderWrapper = styled.div`
    ${style}
`;
const antIcon = <LoadingOutlined style={{ color: '#7C60FF', marginLeft: 15 }} spin size={26} />;

interface HeaderProps {
    handleChange: (value: any) => void;
    filter?: boolean;
    search?: string;
    onApplyFilters?: (options: any) => void;
    onClear?: () => void;
    filterOptions?: any;
    onClickExport?: () => void;
    exporting?: boolean;
    isExportCSV?: boolean;
    filtersLength?: number;
}

const Header: React.FC<HeaderProps> = ({
    handleChange,
    filter,
    search,
    onApplyFilters,
    filterOptions,
    onClear,
    onClickExport,
    exporting,
    isExportCSV,
    filtersLength,
}: HeaderProps) => {
    let timeOut: any = setTimeout(() => {
        return;
    });
    const [val, setVal] = React.useState<string | undefined>(search);
    const [header, setHeader] = React.useState<boolean>(false);

    useEffect(() => {
        timeOut = setTimeout(() => {
            if (header) {
                handleChange(val);
            }
            setHeader(false);
        }, 500);
        return () => clearTimeout(timeOut);
    }, [val]);

    return (
        <HeaderWrapper>
            <Row style={{ display: 'flex' }}>
                <Col style={{ flex: 1, display: 'flex' }} className="search-container">
                    <div style={{ width: '237px' }}>
                        <Input
                            prefix={<Search size="14px" className="search-icon" />}
                            placeholder="Search the data"
                            className="search-input"
                            value={val}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setVal(e.currentTarget.value);
                                setHeader(true);
                            }}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                setVal(e.currentTarget.value);
                                setHeader(true);
                            }}
                        />
                    </div>
                </Col>
                {!filter && (
                    <Col style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        {filterOptions && filterOptions.length ? (
                            <TableFilter
                                filterOptions={filterOptions}
                                onApply={(filters) => onApplyFilters?.(filters)}
                                clear={onClear}
                                filtersLength={filtersLength}
                            />
                        ) : null}
                        {isExportCSV && (
                            <div
                                onClick={onClickExport}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                }}
                            >
                                {exporting ? antIcon : <img src={download} alt="export" className="export" />}
                            </div>
                        )}
                    </Col>
                )}
            </Row>
        </HeaderWrapper>
    );
};

interface FooterProps {
    setPageSize: (value: number) => void;
    pageSize: number;
    total: number;
    onPageChange: (value: number) => void;
    currentPage: number;
}

const renderPagination = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a className="table-prev">Previous</a>;
    }
    if (type === 'next') {
        return <a>Next</a>;
    }
    return originalElement;
};

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
    const start = props.pageSize * (props.currentPage - 1) + 1;
    const end = props.pageSize * props.currentPage;
    const isWebDevice = useMediaQuery(DEVICE.laptop);
    return (
        <HeaderWrapper>
            {isWebDevice ? (
                <div className="footer">
                    <div className="select-wrapper">
                        <span>Show</span>
                        <Select
                            defaultValue="10"
                            suffixIcon={<ChevronDown color="#8D8D8D" size="16" />}
                            onChange={(value: string) => props.setPageSize(parseInt(value))}
                        >
                            <Select.Option value="10">10</Select.Option>
                            <Select.Option value="20">20</Select.Option>
                            <Select.Option value="50">50</Select.Option>
                            <Select.Option value="100">100</Select.Option>
                        </Select>
                        <span>Entries</span>
                    </div>
                    <span className="footer-span">
                        Showing {Math.min(start, props.total)} to {Math.min(props.total, end)} of {props.total} entries
                    </span>
                    <Pagination
                        total={props.total}
                        pageSize={props.pageSize}
                        current={props.currentPage}
                        onChange={(page: number) => props.onPageChange(page)}
                        itemRender={(current, type, originalElement) =>
                            renderPagination(current, type, originalElement)
                        }
                    />
                </div>
            ) : (
                <div className="footer">
                    <span className="footer-span left">
                        {Math.min(start, props.total)} to {Math.min(props.total, end)} of {props.total} entries
                    </span>
                    <div
                        className="show-more"
                        onClick={() => {
                            if (props.pageSize + 10 <= props.total) {
                                props.setPageSize(props.pageSize + 10);
                            } else {
                                props.setPageSize(props.total);
                            }
                        }}
                    >
                        Show More <ArrowRight className="icon" />
                    </div>
                </div>
            )}
        </HeaderWrapper>
    );
};

export { Header, Footer };
