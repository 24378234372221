import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

const ExportTradeFinanceComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportTradeFinance {...props} />
    </AuthenticatedLayout>
);
const ExportTradeFinance = Loadable({
    loader: () => import('./trade-finance/table' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});

export const RegisterBillsOnCollection = Loadable({
    loader: () => import('./trade-finance/bills-on-collection' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});

const ViewExportOrderComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewExportOrder {...props} />
    </AuthenticatedLayout>
);
const ViewExportOrder = Loadable({
    loader: () => import('./trade-finance/view-trade-finance' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});

export const TrackExportOrder = Loadable({
    loader: () => import('./components/view-track-trade' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});

const ExportServicesComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportServices {...props} />
    </AuthenticatedLayout>
);
const ExportServices = Loadable({
    loader: () => import('./export-services' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});

const ExportShipmentTrackingComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportShipmentTracking {...props} />
    </AuthenticatedLayout>
);
const ExportShipmentTracking = Loadable({
    loader: () => import('./export-services/shipment-tracking' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});
const SecureExportsComponent = (props: any) => (
    <AuthenticatedLayout>
        <SecureExports {...props} />
    </AuthenticatedLayout>
);
const SecureExports = Loadable({
    loader: () => import('./secure-imports' /* webpackChunkName: "Export trade" */),
    loading() {
        return <Loader />;
    },
});
export {
    ExportTradeFinanceComponent,
    ViewExportOrderComponent,
    SecureExportsComponent,
    ExportServicesComponent,
    ExportShipmentTrackingComponent,
    ExportTradeFinance,
};
