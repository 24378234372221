import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../utils/Loader';

const AcceptBusinessInviteLoadable = Loadable({
    loader: () => import('../index' /* webpackChunkName: "Authentication" */),
    loading() {
        return <Loader />;
    },
});

export default AcceptBusinessInviteLoadable;
