import creditBankLanding from '../Images/Book-Keeper.png';
import appleMerchant from '../Images/app-merchant.webp';
import absaLogo from './absa-logo.png';
import absaSecondary from './absa-secondary.png';
import boaLogo from './boa-logo.png';
import coopBankSecondary from './coop-bank-secondary.png';
import coopBankLogo from './coop-bank.png';
import crdbLogo from './crdb-logo.svg';
import creditBankSecondary from './credit-bank-secondary.png';
import creditBank from './credit-bank.svg';
import discountCapital from './discount-capital.png';
import dtbLogo from './dtb-logo.png';
import dtbLogoSecondary from './dtb-secondary.png';
import gapstack from './gapstack-logo.svg';
import gapstackSecondary from './gapstack-white.svg';
import kcbBank from './kcb-bank.jpg';
import ncbaLogo from './ncba-logo.png';
import ncbaSecondaryLogo from './ncba-secondary-logo.png';
import nmb from './nmb.png';
import premierBankLogoSecondary from './premier-bank-logo-secondary.png';
import premierBankLogo from './premier-logo.png';
import smbBankLogo from './sbm-logo.png';
import sidianBankLogoSecondary from './sidian-bank-logo-secondary.png';
import sidianBankLogo from './sidian-bank-logo.png';
import stanbicSecondary from './stanbic-secondary.png';
import stanbic from './stanbic.png';
import ubaLogo from './uba-logo.svg';
import umbLogo from './umb-logo.webp';
import { default as victoriaBankLogo, default as victoriaBankSecondary } from './victoria-commercial-bank-logo.png';
import zenithLogo from './zenith-logo.png';
import imLogo from './IM-Logo.png';
import IMSecondary from './premier-bank-logo-secondary.png';
import { default as primeBankLogo, default as primeBankSecondary } from './primebank-logo.png';
/**
 * Add secondary app icon also
 *  Example
 *  {
 *      'abc-bank': abcImage,
 *      'abc-bank-secondary': abcImageSecondary,
 *      'abc-bank-landing': abcImageLanding,
 *  }
 */

export default {
    default: gapstack,
    gapstack: gapstack,
    'gapstack-secondary': gapstackSecondary,
    'gapstack-landing': appleMerchant,
    'kcb-bank': kcbBank,
    'kcb-bank-landing': appleMerchant,
    stanbic: stanbic,
    'stanbic-secondary': stanbicSecondary,
    'stanbic-landing': appleMerchant,
    'credit-bank': creditBank,
    'credit-bank-secondary': creditBankSecondary,
    'credit-bank-landing': creditBankLanding,
    nmb: nmb,
    'nmb-secondary': nmb,
    'nmb-landing': creditBankLanding,
    absa: absaLogo,
    'absa-secondary': absaSecondary,
    'absa-landing': appleMerchant,
    'victoria-bank': victoriaBankLogo,
    'victoria-bank-secondary': victoriaBankSecondary,
    'victoria-bank-landing': appleMerchant,
    'discount-capital': discountCapital,
    dtb: dtbLogo,
    'dtb-secondary': dtbLogoSecondary,
    'dtb-landing': appleMerchant,
    uba: ubaLogo,
    'uba-secondary': ubaLogo,
    'uba-landing': appleMerchant,
    zenith: zenithLogo,
    'zenith-secondary': zenithLogo,
    'zenith-landing': appleMerchant,
    crdb: crdbLogo,
    'crdb-secondary': crdbLogo,
    'crdb-landing': appleMerchant,
    ncba: ncbaLogo,
    'ncba-secondary': ncbaSecondaryLogo,
    'ncba-landing': appleMerchant,
    boa: boaLogo,
    'boa-secondary': boaLogo,
    'boa-landing': appleMerchant,
    umb: umbLogo,
    'umb-secondary': umbLogo,
    'umb-landing': appleMerchant,
    'co-operative': coopBankLogo,
    'co-operative-secondary': coopBankSecondary,
    'co-operative-landing': appleMerchant,
    sidian: sidianBankLogo,
    'sidian-secondary': sidianBankLogoSecondary,
    'sidian-landing': appleMerchant,
    premier: premierBankLogo,
    'premier-secondary': premierBankLogoSecondary,
    'premier-landing': appleMerchant,
    sbm: smbBankLogo,
    'sbm-secondary': smbBankLogo,
    'sbm-landing': appleMerchant,
    'i&m': imLogo,
    'i&m-secondary': imLogo,
    'i&m-landing': appleMerchant,
    primebank: primeBankLogo,
    'primebank-secondary': primeBankSecondary,
    'primebank-landing': appleMerchant,
};
