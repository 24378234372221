import { Card, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import FormUploads from '../../../../utils/FormUploads';
import style from './styles';
import { DocumentNameAndType, DocumentPageType, DocumentType } from './types';
import { ConditionalRenderDocs, getCustomDocs } from './components/ConditionalRender';
import { titleCase } from '../../../../utils/TitleCase';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const BusinessDocumentsWrapper = styled.div`
    ${style}
`;
interface CustomDocsProps {
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: string,
        pageType: DocumentPageType,
        fileId: string,
    ) => void;
    documents: any;
    errorObj: any;
    docTypes: any[];
    docsArray: any[];
    setUploadingObj: (val: any) => void;
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType?: DocumentPageType,
    ) => void;
    documentErrObj: any;
    activeData?: any;
}

const CustomDocs: FunctionComponent<CustomDocsProps> = ({
    uploadFilesToBusinessAccount,
    documents,
    errorObj,
    docTypes,
    docsArray,
    setUploadingObj,
    handleErrorFromDocsManager,
    documentErrObj,
    activeData,
}: CustomDocsProps) => {
    const hasYears = (val) => {
        let years: any[] = [];
        const docArr = docsArray.filter((doc) => doc.documentType == val);
        if (docArr.length == 1) {
            years = docArr[0]?.years || [];
        }
        return years;
    };
    const getErr = (documentName) => {
        return (
            errorObj[documentName]?.error ||
            documentErrObj[documentName]?.error ||
            documents[documentName]?.reasonForReturn
        );
    };

    const docsObj: any = {};
    docsArray?.map((val) => {
        docsObj[val?.documentType] = val;
    });
    return (
        <BusinessDocumentsWrapper>
            <Card title="Other Documents" className="card-wrapper card-margin-top">
                <div className="card-description-wrapper">
                    <div className="card-description">Please upload the other required documents.</div>
                    <AiOutlineInfoCircle size={16} className="info-icon" />
                </div>
                {getCustomDocs(docTypes).map((val, ind) => (
                    <>
                        {hasYears(val).length == 0 ? (
                            <Row gutter={20} key={ind}>
                                <ConditionalRenderDocs shouldRender={docTypes.includes(val)}>
                                    <FormUploads
                                        label={`Upload ${titleCase(val)}${docsObj[val]?.isRequired ? '*' : ''}`}
                                        formItemName={val}
                                        documentConfig={{
                                            documentName: val,
                                            documentType: DocumentType.KYC,
                                            pageType: DocumentPageType.STANDARD,
                                        }}
                                        getResponse={(response) => {
                                            if (response?.documentId) {
                                                uploadFilesToBusinessAccount(
                                                    response?.documentId,
                                                    response?.downloadURl,
                                                    val,
                                                    DocumentPageType.STANDARD,
                                                    response?.fileId,
                                                );
                                            } else {
                                                handleErrorFromDocsManager(response, val, DocumentPageType.STANDARD);
                                            }
                                        }}
                                        getFileStatus={(status) => {
                                            setUploadingObj((previousObj: any) => {
                                                if (status) {
                                                    previousObj[val] = true;
                                                } else {
                                                    delete previousObj[val];
                                                }
                                                return { ...previousObj };
                                            });
                                        }}
                                        initialValue={documents[val]?.['url']}
                                        err={getErr(val)}
                                        validateStatus={getErr(val) ? 'error' : 'success'}
                                        required={docsObj[val]?.isRequired ?? false}
                                        disabled={!getErr(val) && activeData?.status == BusinessStatuses.OnHold}
                                    />
                                </ConditionalRenderDocs>
                            </Row>
                        ) : (
                            hasYears(val).map((year, indd) => (
                                <Row gutter={20} key={indd}>
                                    <ConditionalRenderDocs shouldRender={docTypes.includes(val)}>
                                        <FormUploads
                                            label={`Upload ${titleCase(val)} (${year})${
                                                docsObj[val]?.isRequired ? '*' : ''
                                            }`}
                                            formItemName={`${val}_${year}`}
                                            documentConfig={{
                                                documentName: val,
                                                documentType: DocumentType.KYC,
                                                pageType: DocumentPageType.STANDARD,
                                            }}
                                            getResponse={(response) => {
                                                uploadFilesToBusinessAccount(
                                                    response?.documentId,
                                                    response?.downloadURl,
                                                    `${val}_${year}`,
                                                    DocumentPageType.STANDARD,
                                                    response?.fileId,
                                                );
                                            }}
                                            getFileStatus={(status) => {
                                                setUploadingObj((previousObj: any) => {
                                                    if (status) {
                                                        previousObj[`${val}_${year}`] = true;
                                                    } else {
                                                        delete previousObj[`${val}_${year}`];
                                                    }
                                                    return { ...previousObj };
                                                });
                                            }}
                                            initialValue={documents[`${val}_${year}`]?.['url']}
                                            err={getErr(`${val}_${year}`)}
                                            validateStatus={getErr(`${val}_${year}`) ? 'error' : 'success'}
                                            required={docsObj[val]?.isRequired ?? false}
                                            disabled={
                                                !getErr(`${val}_${year}`) &&
                                                activeData?.status == BusinessStatuses.OnHold
                                            }
                                        />
                                    </ConditionalRenderDocs>
                                </Row>
                            ))
                        )}
                    </>
                ))}
            </Card>
        </BusinessDocumentsWrapper>
    );
};

export default CustomDocs;
