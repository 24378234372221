import { AutoComplete, Form, Input, notification } from 'antd';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { PlusCircle, Search } from 'react-feather';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../api';
import style from './styles';
import { LoadingOutlined } from '@ant-design/icons';
import { goToRoute, ROUTES } from '../../../router/urls';
import { titleCase } from '../../TitleCase';
import { search_connections } from '../../../apis/business-accounts';

const ModalWrapper = styled.div`
    ${style}
`;

interface AddNewBusinessProps {
    text?: string;
    onClick?: (...args: any) => void;
}

interface ModalProps {
    onComplete: (value: any) => void;
    type: string;
    formName: string;
    formLabel: string;
    placeholder: string;
    newBusinessText: string;
    value?: string;
    disabled?: boolean;
    prefix?: any;
}

export const AddNewBusinessButton: React.FC<AddNewBusinessProps> = ({
    text = 'Add new buyer',
    onClick,
}: AddNewBusinessProps) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                padding: `8px 12px`,
            }}
            onClick={onClick}
        >
            <PlusCircle style={{ width: '16px', color: '#1E87F0' }} />
            <span style={{ color: '#1E87F0', fontSize: '13px', marginLeft: '10px' }}>{text}</span>
        </div>
    );
};

const AddBusinessFormItem: FunctionComponent<ModalProps> = (props: ModalProps) => {
    const [options, setOptions] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const winOpenedRef = React.useRef<any>();
    const winRefInterval = React.useRef<any>();
    const [value, setValue] = useState<string>(props.value ? props.value : '');

    const cancelRef = useRef<CancelTokenSource>();

    useEffect(() => {
        if (props.value && props.value != '') {
            setValue(props.value);
        }
    }, [props.value]);

    const updateNewSupplier = React.useCallback((responseData: { [key: string]: any }) => {
        const data = responseData?.detail?.data?.data || responseData?.detail?.data;
        if (data) {
            const business = {
                address: {
                    address: data?.contact?.address,
                    postalCode: data?.contact?.postalCode,
                    county: data?.contact?.county,
                    country: data?.contact?.country,
                },
                pinNumber: data?.business?.pinNumber,
                businessName: data?.business?.businessName,
                [props.type + 'Id']: data?.business?.id,
                contact: data?.contact,
                connectionId: data?.id,
                id: data?.business?.id,
                logoUrl: data?.business?.logoUrl,
            };
            setValue(data?.business?.businessName);
            props.onComplete(business);
        }
    }, []);

    const addNewBusiness = async () => {
        const time = Date.now();
        const random = Math.floor(Math.random() * 10000);
        const sessionKey = `${time}-${random}`;

        let url = goToRoute(ROUTES.ONBOARD_SUPPLIERS);
        if (props.type === 'buyer') {
            url = goToRoute(ROUTES.ONBOARD_BUYERS);
        }
        const href = `${url}?session=${sessionKey}`;
        const win = window.open(window.location.origin + href, '_blank');

        if (winRefInterval.current) clearInterval(winRefInterval.current);
        if (winOpenedRef.current) winOpenedRef.current.close();
        winOpenedRef.current = win;

        winRefInterval.current = setInterval(function () {
            if (win?.closed) {
                clearInterval(winRefInterval.current);
                // setTabOpened('');
                console.log('Child window closed');
            }
        }, 500);

        new Promise<any>((resolve) => window.addEventListener(sessionKey, resolve)).then(updateNewSupplier).catch(null);
    };

    const searchBusiness = (name: string) => {
        setLoading(true);

        if (cancelRef.current) {
            cancelRef.current.cancel();
        }
        cancelRef.current = axios.CancelToken.source();

        authenticatedRequest()
            .get(search_connections(name, titleCase(props.type)), {
                cancelToken: cancelRef.current?.token,
            })
            .then((res) => {
                setLoading(false);
                let mainBusinessesData = [{}];

                // TODO Value Should be unique in select list

                if (res.data && Array.isArray(res.data)) {
                    mainBusinessesData = res.data.map((val) => {
                        return {
                            ...val,
                            value: val?.business?.businessName, // TODO this cause the throttle of the list
                            contact: val?.contact,
                            label: `${val?.business?.businessName}`,
                            type: 'mainBusiness',
                            international: false,
                        };
                    });
                }

                setOptions(mainBusinessesData);
            })
            .catch((err) => {
                if (!axios.isCancel(err)) {
                    setLoading(false);
                    setOptions([]);
                    const error = err?.response?.message;
                    notification.error({
                        message: error || 'Unexpected error while searching buyer',
                        type: 'error',
                    });
                }
            });
    };

    const onSelect = (_, selectedOption) => {
        const business = {
            address: {
                address: selectedOption?.contact?.address,
                postalCode: selectedOption?.contact?.postalCode,
                county: selectedOption?.contact?.county,
                country: selectedOption?.contact?.country,
            },
            pinNumber: selectedOption?.business?.pinNumber,
            businessName: selectedOption?.business?.businessName,
            [props.type + 'Id']: selectedOption?.business?.id,
            contact: selectedOption?.contact,
            connectionId: selectedOption?.id,
            id: selectedOption?.business?.id,
        };
        setValue(selectedOption?.business?.businessName);
        props.onComplete(business);
    };

    const onChange = (value: string) => {
        setValue(value);
        if (!value) return;
        searchBusiness(value);
    };

    useEffect(() => {
        searchBusiness('');
    }, []);

    return (
        <ModalWrapper>
            <Form.Item name={props.formName} label={props.formLabel}>
                <AutoComplete
                    allowClear
                    className="auto-complete"
                    onFocus={() => searchBusiness('')}
                    options={options}
                    onSelect={onSelect}
                    onChange={onChange}
                    onClear={() => props.onComplete(null)}
                    value={value}
                    disabled={props.disabled}
                    dropdownRender={(menu) => (
                        <React.Fragment>
                            {menu}
                            <AddNewBusinessButton text={props?.newBusinessText} onClick={addNewBusiness} />
                        </React.Fragment>
                    )}
                >
                    <Input placeholder={props.placeholder} prefix={props.prefix} style={{ paddingLeft: '30px' }} />
                </AutoComplete>
                {props.prefix ?? <Search className="search-icon" />}
                {loading ? <LoadingOutlined className="loading-icon" /> : null}
            </Form.Item>
        </ModalWrapper>
    );
};

export default AddBusinessFormItem;
