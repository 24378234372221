import moment from 'moment';

const convertDate = (date: string) => {
    return moment(date).format('D/M/YYYY');
};

export const mappedData = (data) => {
    let mappedData = [{}];
    mappedData = data.map((msg) => {
        return {
            user: {
                name: msg?.from?.value[0]?.name,
                email: msg?.from?.value[0]?.address,
            },
            body: {
                title: msg?.subject,
                text: msg?.message,
            },
            createdAt: convertDate(msg?.date),
            attachment: !!msg?.attachments.length,
        };
    });
    console.log('func', mappedData);
};

export const MessageSource = {
    inbox: 'INBOX',
    sent: '[Gmail]/Sent Mail',
    draft: '[Gmail]/Drafts',
    trash: '[Gmail]/Bin',
};

export interface MailAttachment {
    name: string;
    content: string | any;
    contentType: string;
}

export interface MailMessage {
    id: string | number;
    source: string;
    colorScheme?: {
        color: string;
        fgColor: string;
    };
    user?: {
        name?: string;
        email?: string;
    };
    body: {
        title: string;
        text: string;
    };
    createdAt: string;
    attachment: boolean;
    files?: Array<MailAttachment>;
}

const getRandomColor = () => {
    const color: number[] = [];
    for (let i = 0; i < 3; i++) {
        color.push(Math.floor(Math.random() * 255));
    }
    return color.join(', ');
};

export const setMailMessageColor = (mailMessages: Array<MailMessage>) => {
    return (
        mailMessages?.map((message) => {
            const color = getRandomColor();
            const bg = `rgba(${color}, 0.4)`;
            const textClr = `rgba(${color})`;
            message.colorScheme = {
                color: bg,
                fgColor: textClr,
            };
            return message;
        }) || []
    );
};

export function mapMailData(data: Array<any>, actionType: string): Array<MailMessage> {
    return data?.map((message) => {
        const color = getRandomColor();
        const bg = `rgba(${color}, 0.4)`;
        const textClr = `rgba(${color})`;
        return {
            id: message?.uid,
            source: MessageSource[actionType],
            user: {
                name: message?.from?.value[0]?.name,
                email: message?.from?.value[0]?.address,
            },
            body: {
                title: message?.subject,
                text: message?.message,
            },
            createdAt: convertDate(new Date(message?.date).toISOString()),
            attachment: !!message?.attachments.length,
            files: message?.attachments?.map((file) => {
                return {
                    name: file.filename,
                    content: file.content,
                    contentType: file.contentType,
                };
            }),
            colorScheme: {
                color: bg,
                fgColor: textClr,
            },
        };
    });
}
