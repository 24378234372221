import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';
import { Constant } from '../../../../config';

const style = () => css`
    .logo-container {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        .logo {
            width: ${Constant.logoWidth};
            height: ${Constant.logoHeight};
        }
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        flex: 1;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 42px 0px 10px 0px;
        justify-content: center;

        .button-container {
            padding: 20px 40px 0;
            display: flex;
            .left-button-container {
                display: flex;
                .button-link {
                    padding: 0;
                }
                .link-icon {
                    margin-right: 10px;
                }
            }
            .right-button-container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    .resend-code {
        margin-top: 10px;
        font-size: 13px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        .refresh {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        }
    }
`;

export default style();
