import { Card, Col, Form, FormInstance, notification, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import SelectCounty from '../../../../utils/FormSelects/Counties';
import SelectCountry from '../../../../utils/FormSelects/Countries';
import Loader from '../../../../utils/Loader';
import { create_physical_address, update_physical_address } from '../../../../apis/business-accounts';
import { PlacesField } from '../../../helpers';
import { mapConfirmData } from '../../helpers/mapData';
import style from './styles';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;
interface PhysicalAddressProps {
    data: any;
    form: FormInstance;
}

const PhysicalAddress: FunctionComponent<PhysicalAddressProps> = ({ data, form }: PhysicalAddressProps) => {
    const [countrySelected, setSelectedCountry] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const physicalAddress =
        (data?.address && Array.isArray(data?.address) && data?.address.length > 0 && data?.address[0]) || {};
    const onComplete = () => {
        const values = form.getFieldValue('physicalAddress');
        let request: any;
        const id = values?.id;
        if (id) {
            const ob = { ...values };
            delete ob['id'];
            request = authenticatedRequest().put(update_physical_address(id), ob);
        } else {
            request = authenticatedRequest().post(create_physical_address, values);
        }
        request
            .then((res) => res.data)
            .then((res) => {
                const id = values?.id;
                if (!id) {
                    form.setFieldsValue({ physicalAddress: { id: res?.id } });
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(['physicalAddress'], false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    useEffect(() => {
        data && form.setFieldsValue({ physicalAddress: mapConfirmData(data)?.['physicalAddress'] });
        setLoading(false);
    }, [data]);
    return (
        <ConfirmDetailsWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Card title="Physical Address" className="card-wrapper card-margin-top">
                    <Form.Item name={['physicalAddress', 'id']} hidden />
                    <Row gutter={20}>
                        <Col span={12}>
                            <SelectCountry
                                formItemLabel="Country"
                                formItemName={['physicalAddress', 'country']}
                                required
                                onChange={() => true}
                                message="Please select country"
                                onCountrySelected={(country) => setSelectedCountry(country)}
                            />
                        </Col>
                        <Col span={12}>
                            <SelectCounty
                                formItemLabel="County"
                                formItemName={['physicalAddress', 'county']}
                                required
                                onChange={() => true}
                                message="Please select county"
                                countryId={countrySelected?.country_id}
                                form={form}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <PlacesField
                                form={form}
                                label={'GPS'}
                                initialValue={physicalAddress?.gps}
                                name={['physicalAddress', 'gps']}
                                required
                            />
                        </Col>
                    </Row>
                </Card>
            )}
        </ConfirmDetailsWrapper>
    );
};

export default PhysicalAddress;
