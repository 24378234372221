import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const editorStyles = () => css`
    .label {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 0;
        .ant-form-item-label {
            width: 100%;
            text-align: left;
        }
        .ant-form-item-control {
            width: 100%;
        }
        .ant-row {
            width: 100%;
        }
    }
    .gapstack-input {
        height: 45px;
    }
    .form-select {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 45px;
            }
        }
    }
    .select {
        width: 100%;
        height: 43px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 43px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 43px;
            }
        }
    }
    .ant-form-item-label > label {
        font-size: 13px;
        height: auto;
    }
`;

export default editorStyles;
