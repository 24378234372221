import {
    FETCH_ACCOUNTS_REQUEST,
    FETCH_ACCOUNTS_SUCCESS,
    FETCH_ACCOUNTS_FAILURE,
    AccountActionTypes,
    Account,
} from '../types';

interface AccountsState {
    loading: boolean;
    accounts: Account[];
    error: string | null;
}

const initialState: AccountsState = {
    loading: false,
    accounts: [],
    error: null,
};

const accountsReducer = (state = initialState, action: AccountActionTypes): AccountsState => {
    switch (action.type) {
        case FETCH_ACCOUNTS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_ACCOUNTS_SUCCESS:
            return { ...state, loading: false, accounts: action.payload };
        case FETCH_ACCOUNTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default accountsReducer;
