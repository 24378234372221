import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import PurchaseSupplierTable from './table';
// import AddPurchaseSupplier from './add-new';
// import InvitePurchaseSupplier from './add-new/invite-new';
// import InviteStepTwo from './add-new/single-invite-2';
// import PendingSuppliers from './add-new/pending-suppliers';
// import OnboardComponent from './add-new/onboard';
// import ViewPurchase from './view-supplier/components';

const PurchaseSupplierTableComponent = (props: any) => (
    <AuthenticatedLayout>
        <PurchaseSupplierTable {...props} />
    </AuthenticatedLayout>
);
const PurchaseSupplierTable = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});
const AddPurchaseSupplierComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddPurchaseSupplier {...props} />
    </AuthenticatedLayout>
);
const AddPurchaseSupplier = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});
const InvitePurchaseSupplierComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <InvitePurchaseSupplier {...props} />
    </AuthenticatedLayout>
);
const InvitePurchaseSupplier = Loadable({
    loader: () => import('./add-new/invite-new' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});
const InviteStepTwoComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <InviteStepTwo {...props} />
    </AuthenticatedLayout>
);
const InviteStepTwo = Loadable({
    loader: () => import('./add-new/single-invite-2' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});
const PendingSuppliersComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <PendingSuppliers {...props} />
    </AuthenticatedLayout>
);
const PendingSuppliers = Loadable({
    loader: () => import('./add-new/pending-suppliers' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});
const OnboardComponentComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <OnboardComponent {...props} />
    </AuthenticatedLayout>
);
const OnboardComponent = Loadable({
    loader: () => import('./add-new/NewOnboardSupplier' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});

const ViewPurchaseComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewPurchase {...props} />
    </AuthenticatedLayout>
);
const ViewPurchase = Loadable({
    loader: () => import('./view-supplier/components' /* webpackChunkName: "Supplier-Central" */),
    loading() {
        return <Loader />;
    },
});

export {
    PurchaseSupplierTableComponent,
    AddPurchaseSupplierComponent,
    InvitePurchaseSupplierComponent,
    InviteStepTwoComponent,
    PendingSuppliersComponent,
    OnboardComponentComponent,
    ViewPurchaseComponent,
    PurchaseSupplierTable,
};
