import { Quill } from 'react-quill';
const Block = Quill.import('blots/block/embed');

class QRCode extends Block {
    static blotName: string;
    static tagName: string;

    static create(value) {
        const node = super.create(value);
        node.contentEditable = false;
        node.setAttribute('style', 'text-align: left;');
        this.addQRCode(node, value);
        return node;
    }

    static value(node) {
        return node.getAttribute(QRCode.blotName);
    }

    static addQRCode(node, value) {
        node.setAttribute(QRCode.blotName, value);

        const img = document.createElement('img');
        img.src = `https://api.staging.gapstack.com/document-manager/storage/817449af-8447-4f34-a40d-17236f07f51d/ee3946c4d5410f8408d1884a3af6cbfb-qr-code.png`;
        img.setAttribute('data-value', 'qr-code');
        img.setAttribute('width', '10%');
        node.appendChild(img);
    }
}

QRCode.blotName = 'qr-code';
QRCode.tagName = 'p';

export default QRCode;
