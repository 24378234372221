import { notification } from 'antd';
import qs from 'querystring';
import queryString from 'query-string';
import { authenticatedRequest } from '../../../../api';
import {
    get_business_information,
    post_contact_information,
    put_business_information,
    post_business_brand_information,
    post_business_mobile_indemnity,
    post_business_director,
    post_business_financial,
    post_business_certificates,
    post_business_terms_and_conditions,
    get_industries,
} from '../../api';
import { goToRoute, ROUTES } from '../../../../router/urls';
import {
    IS_FETCHING_BUSINESS_INFORMATION,
    FETCH_BUSINESS_INFORMATION_SUCCESS,
    FETCH_BUSINESS_INFORMATION_FAILURE,
    IS_UPDATING_BUSINESS_INFORMATION,
    ON_UPDATE_BUSINESS_INFORMATION_SUCCESS,
    ON_UPDATE_BUSINESS_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION,
    ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION,
    ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE,
    UPDATE_REGISTRATION_STATUS,
    IS_UPDATING_TERMS_AND_CONDITIONS,
    ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
    ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE,
    IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION,
    ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION,
    ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION,
    ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE,
    UPDATE_WALLET,
    IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES,
    ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE,
    IS_FETCHING_INDUSTRIES,
    FETCH_INDUSTRIES_SUCCESS,
    FETCH_INDUSTRIES_FAILURE,
} from '../types';
import { KYCStatuses } from '../../helpers';
import { getUser, Users } from '../../../../config';
import { History } from 'history';
import { createSearchParams } from '../../../../router/routeHooks';

const isFetchingBusinessInformation = () => ({
    type: IS_FETCHING_BUSINESS_INFORMATION,
});

const onFetchBusinessInformationSuccess = (data) => ({
    type: FETCH_BUSINESS_INFORMATION_SUCCESS,
    data,
});

const onFetchBusinessInformationFailure = () => ({
    type: FETCH_BUSINESS_INFORMATION_FAILURE,
});

const isUpdatingBusinessInformation = () => ({
    type: IS_UPDATING_BUSINESS_INFORMATION,
});

const onUpdateBusinessInformationSuccess = (data) => ({
    type: ON_UPDATE_BUSINESS_INFORMATION_SUCCESS,
    data,
});

const onUpdateBusinessInformationFailure = () => ({
    type: ON_UPDATE_BUSINESS_INFORMATION_FAILURE,
});

const isCreatingOrUpdatingBusinessContactInformation = () => ({
    type: IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION,
});

const onCreateOrUpdateBusinessContactInformationSuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS,
    data,
});

const onCreateOrUpdateBusinessContactInformationFailure = () => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE,
});

const isCreatingOrUpdatingBusinessBrandInformation = () => ({
    type: IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION,
});

const onCreateOrUpdateBusinessBrandInformationSuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS,
    data,
});

const onCreateOrUpdateBusinessBrandInformationFailure = () => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE,
});

const isUpdatingTermsAndCondotions = () => ({
    type: IS_UPDATING_TERMS_AND_CONDITIONS,
});

const onUpdateTermsAndConditionsSuccess = (data) => ({
    type: ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
    data,
});

const onUpdateTermsAndConditionsFailure = () => ({
    type: ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE,
});

const updateRegistrationStatus = (data) => ({
    type: UPDATE_REGISTRATION_STATUS,
    data,
});

const isCreatingOrUpdatingMobileIndemnity = () => ({
    type: IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION,
});

const onCreateOrUpdateMobileIndemnitySuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS,
    data,
});

const onCreateOrUpdateMobileIndemnityFailure = () => ({
    type: ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE,
});

const isCreatingOrUpdatingDirector = () => ({
    type: IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION,
});

const onCreateOrUpdateDirectorSuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS,
    data,
});

const onCreateOrUpdateDirectorFailure = () => ({
    type: ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE,
});

const isCreatingOrUpdatingFinancialInformation = () => ({
    type: IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION,
});

const onCreateOrUpdateFinancialInformationSuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS,
    data,
});

const onCreateOrUpdateFinancialInformationFailure = () => ({
    type: ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE,
});

const onUpdateWallet = (data) => ({
    type: UPDATE_WALLET,
    data,
});

const isCreatingOrUpdatingBusinessCertificates = () => ({
    type: IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES,
});

const onCreateOrUpdateBusinessCertificatesSuccess = (data) => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS,
    data,
});

const onCreateOrUpdateBusinessCertificatesFailure = () => ({
    type: ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE,
});

const isFetchingIndustries = () => ({
    type: IS_FETCHING_INDUSTRIES,
});

const onFetchIndustriesSuccess = (data) => ({
    type: FETCH_INDUSTRIES_SUCCESS,
    data,
});

const onFetchIndustriesFailure = () => ({
    type: FETCH_INDUSTRIES_FAILURE,
});

const fetchBusinessInformation = (history: History<unknown>, redirect = true, businessId = '', passedNext = '') => {
    const isBank = getUser() == Users.BANK;

    function pushRoute(pathname: string) {
        const { next, ...restSearch } = queryString.parse(history.location.search) || {};

        if (next) {
            history.push({
                pathname: goToRoute(next as string),
                search: createSearchParams(restSearch),
            });
            return;
        }
        return history.push({
            pathname,
        });
    }

    return (dispatch) => {
        dispatch(isFetchingBusinessInformation());
        let request: any;
        if (businessId != '') {
            request = authenticatedRequest({ 'x-business-account-id': businessId });
        } else if (!isBank && businessId == '') {
            request = authenticatedRequest();
        } else {
            dispatch(
                onFetchBusinessInformationSuccess({
                    business: { businessName: 'Admin' },
                    kycCompletionPercentage: 100,
                    profilePercentage: 100,
                }),
            );
            pushRoute(ROUTES.BUSINESS);
            return;
        }

        return request
            .get(get_business_information)
            .then((res) => {
                // save the information to redux
                const data = res.data;
                const business = {
                    id: data.id,
                    businessName: data.businessName,
                    tradingName: data.tradingName,
                    pinNumber: data.pinNumber,
                    status: data.status,
                    businessTypeId: data.businessTypeId,
                    industryId: data.industryId,
                    industry: data.industry,
                };
                const registrationStatus = data?.registrationStatus;
                dispatch(
                    onFetchBusinessInformationSuccess({
                        business: business,
                        registrationStatus: registrationStatus,
                        businessType: data?.businessType,
                        kycCompletionPercentage: calculateProfilePercentage(registrationStatus),
                        profilePercentage: calculateProfilePercentage(registrationStatus),
                    }),
                );

                if (redirect) {
                    if (businessId != '') {
                        if (passedNext != '') {
                            pushRoute(passedNext?.replace?.(':businessId', businessId));
                        } else {
                            pushRoute(ROUTES.DASHBOARD.replace(':businessId', businessId));
                        }
                    } else {
                        pushRoute(goToRoute(ROUTES.DASHBOARD));
                    }
                }
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
                return dispatch(onFetchBusinessInformationFailure());
            });
    };
};

const updateBusinessInformation = (businessAccountId, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isUpdatingBusinessInformation());

        return authenticatedRequest(businessAccountId)
            .put(put_business_information, data)
            .then((res) => {
                const data = res.data;
                const business = {
                    id: businessAccountId,
                    businessName: data.businessName,
                    tradingName: data.tradingName,
                    pinNumber: data.pinNumber,
                    vatNumber: data.vatNumber,
                    incorporationNumber: data.incorporationNumber,
                    termsAndConditionsFile: data.termsAndConditionsFile,
                    businessTypeId: data.businessTypeId,
                    industryId: data.industryId,
                };
                const address = {
                    building: data?.address?.building,
                    city: data?.address?.city,
                    countryId: data?.address?.countryId,
                    countyId: data?.address?.countyId,
                    gps: data?.address?.gps,
                    id: data?.address?.id,
                    road: data?.address?.road,
                };
                dispatch(
                    onUpdateBusinessInformationSuccess({
                        business,
                        address,
                    }),
                );
                registrationStatus.businessInformation = true;
                dispatch(updateRegistrationStatus(registrationStatus));
                notification.success({
                    message: 'Success',
                    description: 'Business Information Updated Successfully',
                });
            })
            .catch((error) => {
                const resp = error?.response;
                dispatch(onUpdateBusinessInformationFailure());
                notification.error({
                    message: 'Error',
                    description: resp.data.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
};

const createOrUpdateContactInformation = (contactId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingBusinessContactInformation());
        if (contactId !== null) {
            /** TODO: Update contact **/
            authenticatedRequest()
                .put(`${post_contact_information}/${contactId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Contact Information Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = contactId;
                    dispatch(onCreateOrUpdateBusinessContactInformationSuccess(newData));
                    const registrationInfo = { ...registrationStatus, contactInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessContactInformationFailure());
                });
        } else {
            /** TODO: Create contact **/
            authenticatedRequest()
                .post(post_contact_information, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Contact Information Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateBusinessContactInformationSuccess(res.data.data));
                    const registrationInfo = { ...registrationStatus, contactInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessContactInformationFailure());
                });
        }
    };
};

const createOrUpdateBrandInformation = (businessAccountId, brandId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingBusinessBrandInformation());
        if (brandId !== null) {
            authenticatedRequest(businessAccountId)
                .put(`${post_business_brand_information}/${brandId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Brand elements Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = brandId;
                    dispatch(onCreateOrUpdateBusinessBrandInformationSuccess(newData));
                    // update registration status
                    const registrationInfo = { ...registrationStatus, brandElements: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessBrandInformationFailure());
                });
        } else {
            authenticatedRequest(businessAccountId)
                .post(post_business_brand_information, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Brand elements Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateBusinessBrandInformationSuccess(res.data.data));
                    const registrationInfo = { ...registrationStatus, brandElements: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp.data.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessBrandInformationFailure());
                });
        }
    };
};

const updateTermsAndConditions = (data, registrationStatus, business) => {
    return (dispatch) => {
        dispatch(isUpdatingTermsAndCondotions());
        authenticatedRequest()
            .put(`${post_business_terms_and_conditions}`, qs.stringify(data))
            .then(() => {
                // display notification to the user
                notification.success({
                    message: 'Success',
                    description: 'Terms and Conditions updated successfully',
                });
                dispatch(onUpdateTermsAndConditionsSuccess(business));
                // update registration status
                const registrationInfo = { ...registrationStatus, termsAndConditionsInformation: true };
                dispatch(updateRegistrationStatus(registrationInfo));
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp.data.message || 'Oops! An unexpected error occurred.',
                });
                dispatch(onUpdateTermsAndConditionsFailure());
            });
    };
};

const createOrUpdateMobileIndemnity = (mobileIndemnityId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingMobileIndemnity());
        if (mobileIndemnityId !== null) {
            authenticatedRequest()
                .put(`${post_business_mobile_indemnity}/${mobileIndemnityId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Mobile Indemnity Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = mobileIndemnityId;
                    dispatch(onCreateOrUpdateMobileIndemnitySuccess(newData));
                    // update registration status
                    const registrationInfo = { ...registrationStatus, mobileIndemnityInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp.data.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateMobileIndemnityFailure());
                });
        } else {
            authenticatedRequest()
                .post(post_business_mobile_indemnity, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Mobile Indemnity Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateMobileIndemnitySuccess(res.data));
                    const registrationInfo = { ...registrationStatus, mobileIndemnityInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp.data.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateMobileIndemnityFailure());
                });
        }
    };
};

const createOrUpdateDirector = (directorId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingDirector());
        if (directorId !== null) {
            authenticatedRequest()
                .put(`${post_business_director}/${directorId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Director Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = directorId;
                    dispatch(onCreateOrUpdateDirectorSuccess(newData));
                    // update registration status
                    const registrationInfo = { ...registrationStatus, directorInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp.data.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateDirectorFailure());
                });
        } else {
            authenticatedRequest()
                .post(post_business_director, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Director Created Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateDirectorSuccess(res.data));
                    const registrationInfo = { ...registrationStatus, directorInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp.data.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateDirectorFailure());
                });
        }
    };
};

const createOrUpdateFinancialInformation = (financialId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingFinancialInformation());
        if (financialId !== null) {
            authenticatedRequest()
                .put(`${post_business_financial}/${financialId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Financial Information Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = financialId;
                    dispatch(onCreateOrUpdateFinancialInformationSuccess(newData));
                    // update registration status
                    const registrationInfo = { ...registrationStatus, financialInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateFinancialInformationFailure());
                });
        } else {
            authenticatedRequest()
                .post(post_business_financial, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Financial Information Created Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateFinancialInformationSuccess(res.data));
                    const registrationInfo = { ...registrationStatus, financialInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateFinancialInformationFailure());
                });
        }
    };
};

const createOrUpdateBusinessCertificates = (documentId = null, data, registrationStatus) => {
    return (dispatch) => {
        dispatch(isCreatingOrUpdatingBusinessCertificates());
        if (documentId !== null) {
            authenticatedRequest()
                .put(`${post_business_certificates}/${documentId}`, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Business Certificate Updated Successfully',
                    });
                    const newData = res.data.data;
                    newData.id = documentId;
                    dispatch(onCreateOrUpdateBusinessCertificatesSuccess(newData));
                    // update registration status
                    const registrationInfo = { ...registrationStatus, businessCertificateInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessCertificatesFailure());
                });
        } else {
            authenticatedRequest()
                .post(post_business_certificates, qs.stringify(data))
                .then((res) => {
                    // display notification to the user
                    notification.success({
                        message: 'Success',
                        description: 'Business Certificate Created Saved Successfully',
                    });
                    dispatch(onCreateOrUpdateBusinessCertificatesSuccess(res.data));
                    const registrationInfo = { ...registrationStatus, businessCertificateInformation: true };
                    dispatch(updateRegistrationStatus(registrationInfo));
                })
                .catch((error) => {
                    const resp = error?.response;
                    notification.error({
                        message: 'Error',
                        description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                    });
                    dispatch(onCreateOrUpdateBusinessCertificatesFailure());
                });
        }
    };
};

const updateWallet = (data, registrationStatus) => {
    return (dispatch) => {
        dispatch(onUpdateWallet(data));
        const registrationInfo = { ...registrationStatus, bankWalletInformation: true };
        dispatch(updateRegistrationStatus(registrationInfo));
    };
};

const fetchIndustries = () => {
    return (dispatch) => {
        dispatch(isFetchingIndustries());
        authenticatedRequest()
            .get(get_industries)
            .then((res) => {
                dispatch(onFetchIndustriesSuccess(res.data));
            })
            .catch(() => {
                dispatch(onFetchIndustriesFailure());
            });
    };
};

const calculateProfilePercentage = (registrationStatus) => {
    let tempActiveStatus: KYCStatuses = KYCStatuses.BUSINESS;
    if (registrationStatus?.businessProfile) {
        tempActiveStatus = KYCStatuses.DOCUMENTS;
    }
    if (registrationStatus?.documents) {
        tempActiveStatus = KYCStatuses.CONFIRM_DETAILS;
    }
    if (registrationStatus?.confirmDetails) {
        tempActiveStatus = KYCStatuses.TERMS_AND_CONDITIONS;
    }
    if (registrationStatus?.termsAndCondition) {
        tempActiveStatus = KYCStatuses.VERIFICATION_PENDING;
    }

    return tempActiveStatus != KYCStatuses.VERIFICATION_PENDING ? 0 : 100;
};

export const businessAccountActions = {
    fetchBusinessInformation,
    updateBusinessInformation,
    createOrUpdateContactInformation,
    createOrUpdateBrandInformation,
    updateTermsAndConditions,
    createOrUpdateMobileIndemnity,
    createOrUpdateDirector,
    createOrUpdateFinancialInformation,
    createOrUpdateBusinessCertificates,
    fetchIndustries,
    updateWallet,
};
