import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import { FundingTypeEnum } from '../../fund-my-purchase/types';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const ExportLoanTableComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportLoanTable {...props} />
    </AuthenticatedLayout>
);
const ExportLoanTable = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const ExportLoanViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportLoanView title={'Export Loans'} fundingType={FundingTypeEnum.EXPORT_TRADE} {...props} />
    </AuthenticatedLayout>
);
const ExportLoanView = Loadable({
    loader: () => import('../../fund-my-purchase/view-financing-request' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const GetExportTradeFinancing = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <GetExportTrade {...props} />
    </AuthenticatedLayout>
);
const GetExportTrade = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const FinanceExportOrders = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <FinanceExportOrdersComponent {...props} />
    </AuthenticatedLayout>
);
const FinanceExportOrdersComponent = Loadable({
    loader: () => import('./add-new/export-orders' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const LCDiscounting = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <LCDiscountingComponent {...props} />
    </AuthenticatedLayout>
);
const LCDiscountingComponent = Loadable({
    loader: () => import('./add-new/lc-discounting' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const GetWorkingCapital = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <GetWorkingCapitalComponent {...props} />
    </AuthenticatedLayout>
);
const GetWorkingCapitalComponent = Loadable({
    loader: () => import('./add-new/get-working-capital' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});
const CollectionDiscounting = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CollectionDiscountingComponent {...props} />
    </AuthenticatedLayout>
);
const CollectionDiscountingComponent = Loadable({
    loader: () => import('./add-new/collection-discounting' /* webpackChunkName: "Export-loans" */),
    loading() {
        return <Loader />;
    },
});

export {
    ExportLoanTableComponent,
    ExportLoanViewComponent,
    GetExportTradeFinancing,
    FinanceExportOrders,
    LCDiscounting,
    GetWorkingCapital,
    CollectionDiscounting,
};
