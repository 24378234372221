import { PURGE } from 'redux-persist';
import {
    IS_USER_SIGNING_IN,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_FAILURE,
    USER_LOG_OUT,
    UPDATE_USER_PROFILE_PICTURE,
    ON_TWO_STEP_JWT_TOKEN_RECEIVED,
    UPDATE_TWO_STEP_AUTHENTICATION,
    ADD_USER_GROUPS,
    CHANGE_ACTIVE_USER_GROUP,
    UPDATE_USSD_STATUS,
} from '../types';

const initialState = {
    isSigningIn: false,
    user: {},
    twoStep: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_USER_SIGNING_IN:
            return {
                isSigningIn: true,
            };
        case USER_SIGN_IN_SUCCESS:
            return {
                isSigningIn: false,
                user: action.data,
            };
        case ADD_USER_GROUPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    userGroups: action.data,
                },
            };
        case CHANGE_ACTIVE_USER_GROUP:
            return {
                ...state,
                user: {
                    ...state.user,
                    activeUserGroup: action.data,
                },
            };
        case USER_SIGN_IN_FAILURE:
            return {
                isSigningIn: false,
                user: {},
            };
        case UPDATE_USER_PROFILE_PICTURE:
            return {
                ...state,
                user: action.data,
            };
        case ON_TWO_STEP_JWT_TOKEN_RECEIVED:
            return {
                twoStep: action.data,
            };
        case UPDATE_TWO_STEP_AUTHENTICATION:
            return {
                ...state,
                user: action.data,
            };
        case USER_LOG_OUT:
            return {
                ...initialState,
            };
        case UPDATE_USSD_STATUS:
            return {
                user: {
                    ...state.user,
                    hasSetupUSSDPin: true,
                },
            };
        case PURGE:
            return {};
        default:
            return state;
    }
};
