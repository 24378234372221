import React, { useEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import style from './styles';
import { notification, Pagination } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { COLORS, generateTheme } from '../../config/theme';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SinglePDFWrapper = styled.div`
    ${style}
`;
interface Props {
    url: string;
    zoom?: any;
    page?: number;
}

const antIcon = (
    <div
        style={{
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <LoadingOutlined style={{ color: `${generateTheme()[COLORS.PRIMARY]}`, fontSize: 30 }} spin />
    </div>
);

const renderPagination = (_, type, originalElement) => {
    if (type === 'prev') {
        return <a className="table-prev">Previous</a>;
    }
    if (type === 'next') {
        return <a>Next</a>;
    }
    return originalElement;
};

const SinglePagePDF = ({ url, zoom, page }: Props) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(Math.min(numPages, page ?? 1) == page ? page : 1);
    };

    useEffect(() => {
        setPageNumber(Math.min(numPages, page ?? 1) == page ? page : 1);
    }, [page]);

    const onPageChange = (page) => {
        setPageNumber(page);
    };
    const onLoadError = () => {
        notification.error({
            message: 'Error',
            description: `Document wasn't loaded`,
        });
    };

    if (!url) return null;

    return (
        <SinglePDFWrapper>
            <SizeMe monitorHeight refreshRate={128} refreshMode="debounce">
                {({ size }) => (
                    <Document
                        loading={antIcon}
                        file={`${process.env.REACT_APP_API_BASE_URL}/document-manager${url}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={onLoadError}
                    >
                        <Page pageNumber={pageNumber} width={size.width} scale={zoom} />
                        {numPages > 1 ? (
                            <Pagination
                                style={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}
                                onChange={onPageChange}
                                total={numPages}
                                pageSize={1}
                                current={pageNumber}
                                itemRender={(current, type, originalElement) =>
                                    renderPagination(current, type, originalElement)
                                }
                            />
                        ) : null}
                    </Document>
                )}
            </SizeMe>
        </SinglePDFWrapper>
    );
};

export default SinglePagePDF;
