import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .confirm-wrapper {
        min-height: 100vh;
        max-width: 750px;
        margin: auto;
    }
    .logo {
        margin: 60px auto 30px;
        width: 232px;
        height: 60px;
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        height: 150px;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 40px 30px;
        justify-content: center;
        .success-logo {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }

        .link-heading {
            font-size: 14px;
            line-height: 22px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            display: flex;
            justify-content: center;
            text-align: center;
            margin-bottom: 0;
        }
        .activate {
            font-size: 14px;
            line-height: 22px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin: 0 auto 1rem auto;
        }
    }
`;

export default style();
