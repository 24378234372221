export const IS_USER_SIGNING_IN = 'IS_USER_SIGNING_IN';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const ADD_USER_GROUPS = 'ADD_USER_GROUPS';
export const CHANGE_ACTIVE_USER_GROUP = 'CHANGE_ACTIVE_USER_GROUP';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';
export const USER_LOG_OUT = 'USER_LOG_OUT';
export const UPDATE_USER_PROFILE_PICTURE = 'UPDATE_USER_PROFILE_PICTURE';
export const ON_TWO_STEP_JWT_TOKEN_RECEIVED = 'ON_TWO_STEP_JWT_TOKEN_RECEIVED';
export const UPDATE_TWO_STEP_AUTHENTICATION = 'UPDATE_TWO_STEP_AUTHENTICATION';
export const UPDATE_USSD_STATUS = 'UPDATE_USSD_STATUS';
