import React from 'react';
import { ROUTES } from '../../router/urls';
import { COLORS } from '../theme';
import Icons from '../../assets/icons/Svg/sidebar-active/icons';
import { colorTheme, enabledRouteArray } from './getMenu';
import { getUser } from '../user';
const isBank = getUser() == 'BANK';

export const menuOne = {
    firstItemsForBank: [
        {
            key: 'dashboard',
            activeImage: <Icons.Dashboard fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Dashboard fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Dashboard',
            link: ROUTES.DASHBOARD_BANK,
            pathNames: ['dashboard'],
            canDisable: false,
        },
        {
            key: 'businesses',
            activeImage: <Icons.Business fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Business fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Businesses',
            link: ROUTES.BUSINESS,
            pathNames: ['businesses', 'verify', 'complete-kyc'],
            canDisable: false,
            enabled: true,
            onhover: ['BusinessViewComponent'],
        },
        {
            key: 'connections',
            activeImage: <Icons.Ecosystem fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Ecosystem fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Connections',
            link: ROUTES.ECOSYSTEM,
            pathNames: ['connections'],
            canDisable: true,
            disabledImage: <Icons.Ecosystem fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['EcosystemComponent'],
            enabled: !!Number(enabledRouteArray[1]) ?? true,
        },
    ],
    firstItems: [
        {
            key: 'dashboard',
            activeImage: <Icons.Dashboard fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Dashboard fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Dashboard',
            link: ROUTES.DASHBOARD,
            pathNames: ['dashboard'],
            canDisable: false,
            enabled: !!Number(enabledRouteArray[0]) ?? true,
        },
        {
            key: 'connections',
            activeImage: <Icons.Ecosystem fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Ecosystem fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Connections',
            link: ROUTES.ECOSYSTEM,
            pathNames: ['connections'],
            canDisable: false,
            onhover: ['EcosystemComponent'],
            disabledImage: '<Icons.Ecosystem fill={colorTheme[COLORS.BACKGROUND_STEPS]} />',
            enabled: !!Number(enabledRouteArray[1]) ?? true,
        },
    ],
    secondItems: [
        {
            key: 'purchase-financing',
            activeImage: <Icons.PurchaseFinancing fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.PurchaseFinancing fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Fund My Purchases',
            link: isBank ? ROUTES.FUND_MY_PURCHASES_BANK : ROUTES.FUND_MY_PURCHASES,
            pathNames: ['fund-my-purchases'],
            canDisable: false,
            disabledImage: <Icons.PurchaseFinancing fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['FundMyPurchasesViewComponent'],
            enabled: !!Number(enabledRouteArray[3]) ?? true,
        },
        {
            key: 'fund-from-sales',
            activeImage: <Icons.SalesFinancing fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.SalesFinancing fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Invoice Discounting',
            link: isBank ? ROUTES.FUND_FROM_SALES_BANK : ROUTES.FUND_FROM_SALES,
            pathNames: ['fund-from-sales'],
            canDisable: false,
            disabledImage: <Icons.SalesFinancing fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['FundFromSalesViewComponent'],
            enabled: !!Number(enabledRouteArray[4]) ?? true,
        },
        {
            key: 'guarantees-bonds',
            activeImage: <Icons.Bonds fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Bonds fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Bid Bonds',
            link: ROUTES.BONDS,
            pathNames: ['guarantees-bonds'],
            canDisable: false,
            subMenu: true,
            disabledImage: <Icons.Bonds fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['BondsComponent'],
            enabled: !!Number(enabledRouteArray[5]) ?? true,
        },
        {
            key: 'import-trade',
            activeImage: <Icons.ImportTrade fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.ImportTrade fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Import Trade',
            link: isBank ? ROUTES.VIEW_ALL_REGISTER_BILLS : ROUTES.TRADE_FINANCE,
            pathNames: ['import-trade'],
            canDisable: false,
            subMenu: true,
            disabledImage: <Icons.ImportTrade fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['TradeFinanceComponent', 'BillsOnCollectionComponent'],
            enabled: !!Number(enabledRouteArray[6]) ?? true,
            subMenuItems: [
                {
                    title: 'Trade Finance',
                    key: 'trade-finance',
                    pathNames: isBank ? ['bills-on-collection'] : ['trade-finance'],
                    canDisable: false,
                    link: isBank ? ROUTES.VIEW_ALL_REGISTER_BILLS : ROUTES.TRADE_FINANCE,
                    enabled: !!Number(enabledRouteArray[7]) ?? true,
                },
                {
                    title: 'Import Loans',
                    key: 'import-loans',
                    pathNames: ['import-loans'],
                    canDisable: true,
                    link: ROUTES.IMPORT_LOANS,
                    enabled: !!Number(enabledRouteArray[8]) ?? true,
                },
                {
                    title: 'Import Services',
                    key: 'import-services',
                    pathNames: ['import-services'],
                    canDisable: true,
                    link: ROUTES.IMPORT_SERVICES,
                    enabled: !!Number(enabledRouteArray[9]) ?? true,
                },
                {
                    title: 'Global Accounts',
                    key: 'secure-imports',
                    pathNames: ['secure-imports'],
                    canDisable: true,
                    link: ROUTES.SECURE_IMPORTS,
                    enabled: !!Number(enabledRouteArray[10]) ?? true,
                },
            ],
        },
        {
            key: 'export-trade',
            activeImage: <Icons.ExportTrade fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.ExportTrade fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Export Trade',
            link: ROUTES.EXPORT_TRADE_FINANCE,
            pathNames: ['export-trade'],
            canDisable: true,
            subMenu: true,
            disabledImage: <Icons.ExportTrade fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['ExportTradeFinanceComponent'],
            enabled: !!Number(enabledRouteArray[11]) ?? true,
            subMenuItems: [
                {
                    title: 'Trade Finance',
                    key: 'trade-finance',
                    pathNames: ['trade-finance'],
                    canDisable: true,
                    link: ROUTES.EXPORT_TRADE_FINANCE,
                    enabled: !!Number(enabledRouteArray[12]) ?? true,
                },
                {
                    title: 'Export Loans',
                    key: 'export-loans',
                    pathNames: ['export-loans'],
                    canDisable: true,
                    link: ROUTES.EXPORT_LOANS,
                    enabled: !!Number(enabledRouteArray[13]) ?? true,
                },
                {
                    title: 'Export Services',
                    key: 'export-services',
                    pathNames: ['export-services'],
                    canDisable: true,
                    link: ROUTES.EXPORT_SERVICES,
                    enabled: !!Number(enabledRouteArray[14]) ?? true,
                },
                {
                    title: 'Global Accounts',
                    key: 'escrow',
                    pathNames: ['escrow'],
                    canDisable: true,
                    link: ROUTES.ESCROW,
                    enabled: !!Number(enabledRouteArray[15]) ?? true,
                },
            ],
        },
    ],
    thirdItems: [
        {
            key: 'purchases',
            activeImage: <Icons.Purchases fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Purchases fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Purchases',
            link: ROUTES.SUPPLIER_CENTRAL,
            pathNames: ['purchases'],
            canDisable: true,
            subMenu: true,
            disabledImage: <Icons.Purchases fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: [
                'PurchaseSupplierTableComponent',
                'PurchaseOrderTableComponent',
                'ReceivedQuotationsComponent',
                'PurchaseInvoiceTableComponent',
            ],
            enabled: !!Number(enabledRouteArray[17]) ?? true,
            subMenuItems: [
                {
                    title: 'Supplier Central',
                    key: 'supplier-central',
                    pathNames: ['supplier-central'],
                    canDisable: true,
                    link: ROUTES.SUPPLIER_CENTRAL,
                    enabled: !!Number(enabledRouteArray[18]) ?? true,
                },
                {
                    title: 'Purchase Orders',
                    key: 'purchase-order',
                    pathNames: ['purchase-order'],
                    canDisable: true,
                    link: ROUTES.PURCHASE_ORDER,
                    enabled: !!Number(enabledRouteArray[19]) ?? true,
                },
                {
                    title: 'Received Quotations',
                    key: 'received-quotations',
                    pathNames: ['received-quotations', 'received-quotation'],
                    canDisable: true,
                    link: ROUTES.RECEIVED_QUOTATIONS,
                    enabled: !!Number(enabledRouteArray[20]) ?? true,
                },
                {
                    title: 'Invoices',
                    key: 'purchase-invoices',
                    pathNames: ['purchase-invoices'],
                    canDisable: true,
                    link: ROUTES.INVOICES,
                    enabled: !!Number(enabledRouteArray[21]) ?? true,
                },
            ],
        },
        {
            key: 'sales',
            activeImage: <Icons.Sales fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Sales fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Sales',
            link: ROUTES.BUYER_CENTRAL,
            pathNames: ['sales'],
            canDisable: true,
            subMenu: true,
            disabledImage: <Icons.Sales fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            enabled: !!Number(enabledRouteArray[22]) ?? true,
            onhover: ['BuyerCentralComponent', 'QuotationsComponent', 'SalesOrderComponent', 'SalesInvoicesComponent'],
            subMenuItems: [
                {
                    title: 'Buyer Central',
                    key: 'buyer-central',
                    pathNames: ['buyer-central'],
                    canDisable: true,
                    link: ROUTES.BUYER_CENTRAL,
                    enabled: !!Number(enabledRouteArray[23]) ?? true,
                },
                {
                    title: 'Quotations',
                    key: 'quotations',
                    pathNames: ['quotations'],
                    canDisable: true,
                    link: ROUTES.QUOTATIONS,
                    enabled: !!Number(enabledRouteArray[24]) ?? true,
                },
                {
                    title: 'Received Orders',
                    key: 'sales-order',
                    pathNames: ['sales-order'],
                    canDisable: true,
                    link: ROUTES.SALES_ORDER,
                    enabled: !!Number(enabledRouteArray[25]) ?? true,
                },
                {
                    title: 'Invoices',
                    key: 'sales-invoices',
                    pathNames: ['sales-invoices', 'invoices'],
                    canDisable: true,
                    link: ROUTES.SALES_INVOICES,
                    enabled: !!Number(enabledRouteArray[26]) ?? true,
                },
            ],
        },
        {
            key: 'products',
            activeImage: <Icons.Product fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Product fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Inventory',
            link: ROUTES.PRODUCTS,
            pathNames: ['products'],
            canDisable: true,
            disabledImage: <Icons.Product fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['ProductsComponent'],
            enabled: !!Number(enabledRouteArray[27]) ?? true,
        },
    ],
    fourthItems: [
        {
            key: 'payments',
            activeImage: <Icons.Payment fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Payment fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Payments',
            link: ROUTES.PAYMENTS,
            pathNames: ['payments'],
            canDisable: !isBank,
            disabledImage: <Icons.Payment fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['PaymentsComponent'],
            enabled: !!Number(enabledRouteArray[29]) ?? true,
        },
    ],
    fifthItems: [
        {
            key: 'reports',
            activeImage: <Icons.Reports fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Reports fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Insights',
            link: ROUTES.INSIGHTS,
            pathNames: ['insights'],
            canDisable: false,
            disabledImage: <Icons.Reports fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            enabled: !!Number(enabledRouteArray[31]) ?? true,
        },
        {
            key: 'developer',
            activeImage: <Icons.Developer fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Developer fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Developer',
            link: ROUTES.DEVELOPER,
            pathNames: ['developer'],
            canDisable: false,
            disabledImage: <Icons.Developer fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            enabled: !!Number(enabledRouteArray[32]) ?? true,
        },
        {
            key: 'settings',
            activeImage: <Icons.Settings fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Settings fill={colorTheme[COLORS.PARAGRAPH]} />,
            disabledImage: <Icons.Settings fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            title: 'Settings',
            link: isBank ? ROUTES.BANK_SETTINGS : ROUTES.SETTINGS,
            pathNames: ['business', 'settings', 'bank-settings'],
            canDisable: false,
            onhover: ['SettingsComponent', 'BankSettingsComponent'],
            enabled: !!Number(enabledRouteArray[33]) ?? true,
        },
    ],
    getFinanceRoute: ROUTES.DISCOUNT_INVOICES,
    newGuaranteeRoute: `${ROUTES.CREATE_NEW_GUARANTEE}?guaranteeType=BID_BOND`,
};
