import { ACTIONS } from '../types';

const initialState: ConnectionState = {
    connections: null,
    fetchingConnections: true,
    newConnections: null,
    fethingNewConnections: true,
    invites: null,
    fetchingInvites: true,
};

interface ConnectionState {
    connections: any[] | null;
    fetchingConnections: boolean;
    newConnections: any[] | null;
    fethingNewConnections: boolean;
    invites: any[] | null;
    fetchingInvites: boolean;
}

export default (state: ConnectionState = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCH_CONNECTION_REQUEST_START:
            return {
                ...state,
                fetchingConnections: true,
            };
        case ACTIONS.FETCH_CONNECTION_REQUEST:
            return {
                ...state,
                connections: action.data,
                fetchingConnections: false,
            };
        case ACTIONS.FETCH_CONNECTION_REQUEST_FAILURE:
            return {
                ...state,
                connections: null,
                fetchingConnections: false,
            };
        case ACTIONS.FETCH_NEW_CONNECTION_REQUEST_START:
            return {
                ...state,
                fethingNewConnections: true,
            };
        case ACTIONS.FETCH_NEW_CONNECTION_REQUEST:
            return {
                ...state,
                newConnections: action.data,
                fethingNewConnections: false,
            };
        case ACTIONS.FETCH_NEW_CONNECTION_REQUEST_FAILURE:
            return {
                ...state,
                newConnections: null,
                fethingNewConnections: false,
            };
        case ACTIONS.FETCH_INVITES_START:
            return {
                ...state,
                fetchingInvites: true,
            };
        case ACTIONS.FETCH_INVITES:
            return {
                ...state,
                invites: action.data,
                fetchingInvites: false,
            };
        case ACTIONS.FETCH_INVITES_FAILURE:
            return {
                ...state,
                invites: null,
                fetchingInvites: false,
            };
        case ACTIONS.IGNORE_CONNECTION_REQUEST:
            return {
                ...state,
                newConnections: state.newConnections?.filter((val) => val.id != action.id),
            };
        case ACTIONS.ACCEPT_CONNECTION_REQUEST:
            const newData = state.newConnections?.filter((val) => val.id == action.id)[0];
            newData['status'] = 'Accepted';
            return {
                ...state,
                connections: [...state.connections, newData],
                newConnections: state.newConnections?.filter((val) => val.id != action.id),
            };
        default:
            return state;
    }
};
