import React, { FC, useEffect, useState } from 'react';
// import moment from 'moment';
import Avatar from 'react-avatar';
import { Divider, Input, Empty } from 'antd';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Paperclip, Search } from 'react-feather';

import style from './styles';

const MessagesListWrapper = styled.div`
    ${style}
`;

export const UserIcon = (props: any) => {
    return <Avatar {...props} textSizeRatio={11} />;
};

interface Props {
    data: any;
    setMessageView: (m: any) => void;
    loading: boolean;
    activeTab: string;
    selectedMessage?: any;
}

const MessagesList: FC<Props> = ({
    data: messages = [],
    setMessageView,
    loading,
    activeTab,
    selectedMessage,
}: Props) => {
    const [active, setActive] = useState(null);

    useEffect(() => {
        setActive(null);
    }, [activeTab]);

    useEffect(() => {
        if (!selectedMessage.id) {
            setActive(null);
        }
    }, [selectedMessage.id]);

    const viewMessage = (msg, key) => {
        setMessageView(msg);
        setActive(key);
    };

    // const todayDate = moment().format('D/MM/YY');

    if (messages.length === 0) {
        return (
            <MessagesListWrapper>
                <div className="empty">
                    <Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
                </div>
            </MessagesListWrapper>
        );
    }
    if (loading) {
        return (
            <MessagesListWrapper
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '95vh',
                }}
            >
                <LoadingOutlined style={{ fontSize: 30, color: '#7C60FF' }} spin />
            </MessagesListWrapper>
        );
    }
    return (
        <MessagesListWrapper>
            <div className="list-header">
                <Input
                    prefix={<Search size="16" className="search-icon" />}
                    placeholder="Search messages..."
                    className="search-input "
                />
            </div>
            <Divider />
            <div className="list-body">
                {messages.map((message, key) => (
                    <div key={key}>
                        <div
                            className={`message ${key === active ? 'active' : ''}`}
                            tabIndex={0}
                            onClick={() => viewMessage(message, key)}
                            onKeyPress={(e) => e.key === 'Enter' && viewMessage(message, key)}
                        >
                            <UserIcon
                                size="40"
                                name={message?.user?.name || message?.user?.email}
                                className="avatar"
                                round
                                {...message.colorScheme}
                            />

                            <div className="message-body">
                                <div className="user-name">{message?.user?.name}</div>
                                <div className="title">{message.body?.title}</div>
                                <div className="body">{message.body?.text?.substr(0, 69)}...</div>
                            </div>

                            <div className="message-extra">
                                <span className="time">
                                    {message.createdAt}
                                    {/* {todayDate === moment(message.createdAt).format('D/MM/YY')
                                        ? moment(message.createdAt).format('HH:mm')
                                        : moment(message.createdAt).format('D/MM/YY')} */}
                                </span>
                                {message?.attachment && (
                                    <span className="attachment">
                                        <Paperclip size="12" />
                                    </span>
                                )}
                            </div>
                        </div>
                        <Divider />
                    </div>
                ))}
            </div>
        </MessagesListWrapper>
    );
};

export default React.memo(MessagesList);
