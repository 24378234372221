import { css } from 'styled-components';

const style = () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .avatar {
        border-radius: 3px;
        width: 70%;
    }

    .avatar-container {
        display: flex;
        width: 100%;
        align-items: center;

        .full-name {
            padding-left: 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
        }
    }

    .top-bar-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-weight: 600 !important;
    }
`;

export default style;
