import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    flex: 1;
    .ant-breadcrumb {
        line-height: 26px;
    }
    padding: 5px 0px;
    .ant-spin-nested-loading {
        padding: 0;
    }
    .account-settings {
        .ant-list-pagination {
            .ant-pagination-next,
            .ant-pagination-prev {
                a {
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                }
            }

            .ant-pagination-item {
                margin: 0;
                width: auto;
                min-width: unset;
                & + .ant-pagination-next {
                    margin-left: 4px;
                }
            }

            .ant-pagination-total-text {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
        .half-bottom {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        }

        .actions {
            border: 0.5px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
    }
`;
export default style;
