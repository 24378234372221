interface FieldData {
    id: number;
    name: string;
    type: string;
    props: string;
    required: boolean;
    message: string;
}
interface data_array {
    id: string;
    fields: FieldData[];
}
const data: data_array[] = [
    {
        id: 'A',
        fields: [
            {
                id: 1,
                name: 'Invoice Date',
                type: 'date',
                props: 'documentDate',
                required: true,
                message: 'Please enter invoice date',
            },
            {
                id: 2,
                name: 'Invoice Number',
                type: 'text',
                props: 'shortId',
                required: true,
                message: 'Please enter invoice number',
            },
        ],
    },
    {
        id: 'B',
        fields: [
            {
                id: 5,
                name: 'P.O Number',
                type: 'text',
                props: 'parentNumber',
                required: true,
                message: 'Please enter P.O number',
            },

            {
                id: 4,
                name: 'Buyer',
                type: 'AddBusinessFormItem',
                props: 'buyerName',
                required: true,
                message: 'Please enter supplier name',
            },
        ],
    },
    {
        id: 'C',
        fields: [
            {
                id: 7,
                name: 'Estimated Payment Date',
                type: 'date',
                props: 'paymentDate',
                required: true,
                message: 'Please enter payment date',
            },
            {
                id: 6,
                name: 'Invoice Amount',
                type: 'number',
                props: 'totalAmount',
                required: true,
                message: 'Please enter invoice amount',
            },
        ],
    },
];

export { data };
