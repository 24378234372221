import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    min-height: 100vh;
    background-color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    flex: 1;
    flex-direction: column;
    display: flex;
    padding-top: 50px;
    // justify-content: center;
`;

export default style();
