import { PURGE } from 'redux-persist';
import { IS_USER_SIGNING_UP, USER_SIGN_UP_SUCCESS, USER_SIGN_UP_FAILURE } from '../types';

const initialState = {
    isSigningUp: false,
    user: {
        businessName: '',
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '',
        phoneNumber: '',
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_USER_SIGNING_UP:
            return {
                isSigningUp: true,
            };
        case USER_SIGN_UP_SUCCESS:
            return {
                isSigningUp: false,
                user: action.data,
            };
        case USER_SIGN_UP_FAILURE:
            return {
                isSigningUp: false,
            };
        case PURGE:
            return {};
        default:
            return state;
    }
};
