import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .card-wrapper {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-head {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-card-body {
            border-radius: 5px;
        }
        .director-panel-heading {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            display: flex;
            align-items: flex-end;
            .line {
                height: 1px;
                flex: 0.95;
                background: ${(props: any) => props.theme[COLORS.BORDER]};
                margin-left: 10px;
            }
        }
        .card-description {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .businesses-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 15px;
            margin-top: 20px;
            .business-wrapper {
                color: ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
                border: 1px solid ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
                margin-right: 10px;
                padding: 0px 15px;
                border-radius: 25px;
                height: 30px;
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                display: flex;
                align-items: center;
                cursor: pointer;
                &.active {
                    color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
                    background: ${(props: any) => props.theme[COLORS.SECONDARY]};
                    border: 1px solid ${(props: any) => props.theme[COLORS.SECONDARY]};
                }
            }
        }
        .about-business-label {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin: 20px 10px;
        }
        .add-button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 10px 20px;
            background: ${(props) => props.theme[COLORS.PRIMARY]};
            border: none;
            border-radius: 5px;
            color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
        }

        .ant-select-selection-placeholder {
            line-height: 42px;
        }
    }
    .card-margin-top {
        margin-top: 20px;
    }
    .stk-heading {
        font-size: 13px;
        margin-left: 0;
        justify-content: flex-start;
        font-weight: 400;
        background: ${(props) => props.theme[COLORS.BACKGROUND]};
        border-radius: 5px;
        padding-left: 8px;
        margin-bottom: 10px;
    }
`;

export default style();
