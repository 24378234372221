import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = ({ collapsed }) => css`
    //position: fixed;
    //width: 100%;
    .gapstack-menu {
        //height: 100vh;
        background-color: ${(props: any) => props.theme[COLORS.TRANSPARENT]};
        padding: 20px 20px;
        //overflow-y: auto;
        border-right: none;
        //max-width: 260px;
        scrollbar-color: #e5e5e5 #f4f8f9;
    }

    .tools-container {
        position: absolute;
        bottom: 0px;
        .top-divider {
            margin-left: ${collapsed ? '0px' : '25px'};
            width: 80%;
            max-width: 80%;
            min-width: 80%;
        }
    }
    .account-container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        .account-button {
            background: ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            border-radius: 25px;
            width: ${collapsed ? '50px' : '230px'};
            height: ${collapsed ? '50px' : '50px'};
            max-width: 230px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            .account-button-content-container {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                max-width: 100%;
                .profile-icon {
                    background: ${(props: any) => props.theme[COLORS.PROFILE_ICON]};
                    box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
                    height: 30px;
                    width: 30px;
                    min-width: 30px;
                    min-height: 30px;
                    border-radius: 15px;
                    margin-right: ${collapsed ? '0px' : '10px'};
                }

                .business-name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 70%;
                    display: ${collapsed ? 'none' : 'block'};
                }
            }
        }
    }

    .account-button:hover {
        cursor: pointer;
    }

    .ant-menu-submenu-title {
        height: 32px !important;
        line-height: 32px !important;
    }

    .switch-business {
        margin-top: 24px;
        margin-bottom: 10px;
        padding-left: ${collapsed ? '0px' : '24px'};
        display: flex;
        align-items: center;
        justify-content: ${collapsed ? 'center' : 'normal'};
        .title {
            margin-left: 10px;
            color: #a3a3b4;
            font-size: 14px;
            font-weight: 800;
        }
    }

    .title-container {
        // display: flex;
        flex: 1;
        margin-bottom: -13px;
        margin-top: 20px;
        margin-left: 24px;
        display: ${collapsed ? 'none' : 'flex'};
        .lock-image {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            width: 15px;
            height: 15px;
            margin-top: 3px;
            margin-right: 5px;
        }
        .title {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            text-transform: uppercase;
            &.disabled {
                color: ${(props) => props.theme[COLORS.BACKGROUND_STEPS]} !important;
                font-weight: 500;
            }
        }

        .title-light {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.2px;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
            text-transform: uppercase;
        }
    }

    .menu-name {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        .icon {
            margin-right: 15px;
            min-width: 16px;
            min-height: 16px;
        }
        &.active {
            color: ${(props) => props.theme[COLORS.PRIMARY]};
            font-weight: 500;
            a {
                color: ${(props) => props.theme[COLORS.PRIMARY]};
            }
        }
        &.disabled {
            color: ${(props) => props.theme[COLORS.BACKGROUND_STEPS]} !important;
            a {
                color: ${(props) => props.theme[COLORS.BACKGROUND_STEPS]} !important;
            }
        }
    }

    .menu-name-light {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
        .icon {
            margin-right: 15px;
            min-width: 16px;
            min-height: 16px;
        }
        &.active {
            color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            font-weight: 500;
            a {
                color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            }
        }
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
        border-right: none;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
        background: none;
    }

    .ant-menu-inline > .ant-menu-item {
        height: 32px;
    }

    .ant-menu-submenu > .ant-menu {
        background-color: transparent;
    }

    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        align-items: center;
        display: flex;
        margin-bottom: -2px;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
        height: 32px;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        padding-left: 58px !important;
    }

    .ant-menu-inline .ant-menu-item {
        margin-bottom: -5px !important;
    }

    .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
        display: none;
    }
    .ant-menu-item.menu-name.active {
        color: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }

    .ant-switch {
        background-color: transparent;
        border: 1px solid #c4c4c4;
    }
    .ant-switch-checked {
        background-color: transparent;
        border: 1px solid #c4c4c4;
    }
    .ant-switch-handle {
        width: 14px;
        height: 14px;
        top: 0px;
        left: 0px;
    }
    .ant-switch-handle::before {
        background-color: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }
    .ant-switch:focus {
        box-shadow: none;
    }
    .side-action {
        color: ${(props: any) => props.theme[COLORS.ORANGE]};
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .side-action-dot {
        margin-left: 8px;
        margin-top: 3px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: ${(props: any) => props.theme[COLORS.DECLINED]};
    }
`;

export default style;
