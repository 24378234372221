import {
    ON_SWITCH_MENU,
    READ_NOTIFICATION_FETCHED,
    UNREAD_NOTIFICATION_FETCHED,
    NEW_NOTIFICATION,
    CLEAR_UNREAD_NOTIFICATIONS,
    SEEN_NOTIFICATION,
    CLEAR_UNREAD_NOTIFICATION,
    DELETE_READ_NOTIFICATION,
    DELETE_UNREAD_NOTIFICATION,
} from '../types';

type InitialState = {
    menuType: string;
    readNotifications: any[];
    unreadNotifications: any[];
    notificationSeen?: boolean;
    lastId?: string;
    isNewFromSocket?: boolean;
};

const initialState: InitialState = {
    menuType: 'business',
    readNotifications: [],
    unreadNotifications: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ON_SWITCH_MENU:
            return {
                ...state,
                menuType: action.menuType,
            };
        case UNREAD_NOTIFICATION_FETCHED:
            const lastId = state.lastId;
            const newId = (action?.unreadNotifications || ([] as any[]))?.[0]?.id;
            return {
                ...state,
                unreadNotifications: action?.unreadNotifications || [],
                notificationSeen: state.isNewFromSocket ? false : lastId && newId && lastId != newId ? false : true,
                lastId,
            };
        case READ_NOTIFICATION_FETCHED:
            return {
                ...state,
                readNotifications: action?.readNotifications || [],
            };
        case CLEAR_UNREAD_NOTIFICATION:
            const tempUnreadNotifications: any[] = [...state.unreadNotifications];
            let findIndex = 10000000;
            tempUnreadNotifications.forEach((val, index) => {
                if (val.id == action.id) {
                    findIndex = index;
                }
            });
            if (findIndex != 10000000) {
                const tempReadNotifications = [
                    { ...tempUnreadNotifications[findIndex], read: true },
                    ...state.readNotifications,
                ];
                return {
                    ...state,
                    readNotifications: tempReadNotifications || [],
                    unreadNotifications: [...state.unreadNotifications.filter((val) => val.id != action.id)] || [],
                    notificationSeen: true,
                    lastId: undefined,
                    isNewFromSocket: false,
                };
            } else {
                return state;
            }
        case NEW_NOTIFICATION:
            return {
                ...state,
                unreadNotifications: [action.notification, ...state.unreadNotifications],
                notificationSeen: false,
                lastId: action.notification?.id,
                isNewFromSocket: true,
            };
        case CLEAR_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                readNotifications: [
                    ...state.unreadNotifications.map((val) => {
                        return { ...val, read: true };
                    }),
                    ...state.readNotifications,
                ],
                unreadNotifications: [],
                notificationSeen: true,
                lastId: undefined,
                isNewFromSocket: false,
            };
        case DELETE_UNREAD_NOTIFICATION:
            return {
                ...state,
                unreadNotifications: [...state.unreadNotifications.filter((val) => val.id != action.id)] || [],
            };
        case DELETE_READ_NOTIFICATION:
            return {
                ...state,
                readNotifications: [...state.readNotifications.filter((val) => val.id != action.id)] || [],
            };
        case SEEN_NOTIFICATION:
            return {
                ...state,
                notificationSeen: true,
                lastId: undefined,
                isNewFromSocket: false,
            };
        default:
            return state;
    }
};
