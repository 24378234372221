import React from 'react';
import styled from 'styled-components';
import { IoPlayCircleOutline } from 'react-icons/io5';
import style from './styles';
import SittingLady from '../../assets/Images/sitting-lady.svg';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router';
import { goToRoute, ROUTES } from '../../router/urls';

const NoKYCPlaceholderWrapper = styled.div`
    ${style}
`;

interface Props {
    type: 'inline' | 'modal';
    accountStatus: string;
    visible?: boolean;
}

const NoKYCPlaceholder = ({ type, visible, accountStatus }: Props) => {
    const history = useHistory();
    const goToCompleteProfile = () => {
        history.push(goToRoute(ROUTES.COMPLETE_KYC));
    };

    let title = 'Hey, looks like you have not completed your KYC';
    let buttonName = 'Fill KYC Form';
    if (accountStatus === 'returned-for-changes') {
        title = 'Hey, your KYC has been returned for changes. Please update and send it back!';
        buttonName = 'Update KYC Form';
    }

    if (type === 'modal') {
        return (
            <Modal visible={visible} width="40%" footer={null} closeIcon={<div></div>}>
                <NoKYCPlaceholderWrapper>
                    <div className="container">
                        <img src={SittingLady} width={350} alt="sitting_lady" />
                        <span className="title">{title}</span>
                        <span className="description">
                            Through participatory design techniques like workshops and design-lead research, successful
                            design has become more about co-creating with the user throughout the entire design process.
                        </span>
                        <Button
                            type="link"
                            className="gapstack-button-link"
                            style={{
                                marginLeft: 0,
                                textAlign: 'center',
                                marginBottom: 20,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IoPlayCircleOutline size={16} style={{ marginRight: 10 }} />
                            How to request for financing
                        </Button>
                        <Button className="gapstack-button" onClick={goToCompleteProfile}>
                            {buttonName}
                        </Button>
                    </div>
                </NoKYCPlaceholderWrapper>
            </Modal>
        );
    }
    return (
        <NoKYCPlaceholderWrapper>
            <div className="card-container">
                <div className="container">
                    <img src={SittingLady} alt="sitting_lady" />
                    <span className="title" data-testid="inline_title">
                        {title}
                    </span>
                    <span className="description" data-testid="inline_description">
                        Through participatory design techniques like workshops and design-lead research, successful
                        design has become more about co-creating with the user throughout the entire design process.
                    </span>
                    <Button
                        type="link"
                        className="gapstack-button-link"
                        style={{
                            marginLeft: 0,
                            textAlign: 'center',
                            marginBottom: 20,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <IoPlayCircleOutline size={16} style={{ marginRight: 10 }} />
                        How to request for financing
                    </Button>
                    <Button className="gapstack-button" onClick={goToCompleteProfile}>
                        {buttonName}
                    </Button>
                </div>
            </div>
        </NoKYCPlaceholderWrapper>
    );
};

export default NoKYCPlaceholder;
