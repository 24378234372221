import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    background: ${(props) => props.theme[COLORS.BACKGROUND_LIGHT]};
    box-shadow: ${(props) => props.theme[COLORS.SHADOW_LIGHT]};
    min-height: 40vh;
    width: 32%;
    min-width: 400px;

    .list-header {
        padding: 30px 30px 20px;

        .search-input {
            background: #ffffff;
            box-shadow: ${(props) => props.theme[COLORS.SHADOW_LIGHT]};
            border-radius: 5px;
            border: none;
            height: 35px;
            width: 100%;
            font-weight: normal;
            padding-left: 14px;

            .ant-input-prefix {
                margin-right: 13px;
            }

            input.ant-input {
                font-size: 13px;
            }
        }
    }
    .ant-divider-horizontal {
        margin: 0;
    }

    .list-body {
        height: calc(100% - 85px);
        overflow-y: auto;
        overflow-x: hidden;

        .message {
            padding: 15px 20px 15px 15px;
            display: grid;
            grid-template-columns: 40px 1fr auto;
            grid-gap: 10px;
            font-size: 13px;
            line-height: 20px;
            color: ${(props) => props.theme[COLORS.SECONDARY]};
            cursor: pointer;

            &:hover,
            &:focus {
                background: ${(props) => props.theme[COLORS.BACKGROUND]};
                outline: 0.5px dotted #f0edff;
            }

            &.active {
                padding-left: 13px;
                background: #ffffff;
                border-left: 2px solid ${(props) => props.theme[COLORS.PRIMARY]};
            }

            .user-name {
                margin-bottom: 3px;
            }

            .title {
                color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
                font-weight: 600;
                font-size: 14px;
                margin-bottom: 10px;
                line-height: 22px;
            }

            .message-extra {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .avatar span {
                font-size: 11px;
            }
        }
    }
`;

export default style();
