import { Button, Drawer, Layout } from 'antd';
import React, { FC, PropsWithChildren, useState } from 'react';
import { RiMenuLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { COLORS, DEVICE } from '../../../config/theme';

import { Constant } from '../../../config';
import appIcon from '../../../config/appIcon';
import { ROUTES } from '../../../router/urls';

const { Header, Content } = Layout;

const style = () => css`
    .ant-layout-header {
        height: unset;
    }

    .header {
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        .header-logo {
            height: 22px;
            cursor: pointer;
            transition: all 0.15s ease-in-out;
        }

        .desktop-actions {
            display: none;
            transition: all 0.15s ease-in-out;
        }

        .mobile-actions {
            .menu-icon {
                color: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }

        & > div {
            display: flex;
            flex-direction: row;
        }

        .crete-business {
            background-color: ${Constant.buttonColor};
            border-color: ${Constant.buttonColor};
        }
    }

    @media ${DEVICE.tablet} {
        .header {
            padding-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};

            .desktop-actions {
                display: flex;
            }

            .mobile-actions {
                display: none;
            }

            .header-logo {
                height: 40px;
            }
        }
    }
`;

const Wrapper = styled.div`
    ${style}
`;

const DrawerWrapper = styled(Drawer)`
    .ant-drawer-content {
        .ant-drawer-body {
            background-color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
        }

        .header-logo-con {
            margin-bottom: 10px;
            .header-logo {
                width: 100%;
            }
        }
        .ant-btn {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
`;

const CustomLayout: FC = ({ children }: PropsWithChildren<any>) => {
    const history = useHistory();
    const [visible, setVisible] = useState(false);

    return (
        <Wrapper>
            <Layout>
                <DrawerWrapper
                    closeIcon={null}
                    placement={'left'}
                    visible={visible}
                    width="220"
                    onClose={() => setVisible(false)}
                >
                    <div className={'header-logo-con'}>
                        <img
                            src={appIcon}
                            className={'header-logo'}
                            alt="app-icon"
                            onClick={() => history.push(ROUTES.LANDING_PAGE)}
                        />
                    </div>
                    <Button type={'text'} onClick={() => history.push(ROUTES.CUSTOM_BANK_SIGN_IN)} block>
                        Sign In
                    </Button>
                    <Button
                        className={'gapstack-button gapstack-button-primary crete-business'}
                        onClick={() => history.push(ROUTES.CUSTOM_BANK_SIGN_UP)}
                        block
                    >
                        Create Your Business
                    </Button>
                </DrawerWrapper>
                <Header>
                    <div className={'header'}>
                        <div>
                            <img
                                src={appIcon}
                                className={'header-logo'}
                                alt="app-icon"
                                onClick={() => history.push(ROUTES.LANDING_PAGE)}
                            />
                        </div>
                        <div className={'desktop-actions'}>
                            <Button type={'text'} onClick={() => history.push(ROUTES.CUSTOM_BANK_SIGN_IN)}>
                                Sign In
                            </Button>
                            <Button
                                className={'gapstack-button gapstack-button-primary crete-business'}
                                onClick={() => history.push(ROUTES.CUSTOM_BANK_SIGN_UP)}
                            >
                                Create Your Business
                            </Button>
                        </div>
                        <div className={'mobile-actions'}>
                            <RiMenuLine className={'menu-icon'} size={20} onClick={() => setVisible(true)} />
                        </div>
                    </div>
                </Header>
                <Content>{children}</Content>
            </Layout>
        </Wrapper>
    );
};

export default CustomLayout;
