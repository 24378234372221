const prefix = process.env.NODE_ENV === 'development' ? 'authentication' : 'authentication';

export const sign_up = `${prefix}/sign-up`;
export const create_new_business = `${prefix}/users/business`;
export const reset_phone_password = `${prefix}/forgot-password-phone`;
export const verify_phone_otp_from_reset = `/verification/verify`;

export const onboard_business = `${prefix}/onboard-business`;
export const complete_onboard = `${prefix}/onboard-business/complete`;

export const verify_email = `${prefix}/verify-email`;
export const resend_code = (id) => `${prefix}/resend-code/${id}`;

export const get_users = (page = 1, pageSize = 10) => `${prefix}/users?page=${page}&limit=${pageSize}`;
export const deactivate_user = (userId) => `${prefix}/users/${userId}`;
export const resend_invite = (userId) => `${prefix}/users/resend/invite/${userId}`;
export const list_user_businesses = `${prefix}/users/business`;
export const change_password = `${prefix}/users/change-password`;

export const bot_sign_in = (id) => `${prefix}/ussd/bot-sign-in/${id}`;

export const send_email_for_ussd_pin = `${prefix}/ussd `;
export const set_up_ussd_pin = `${prefix}/ussd/setup-pin`;
export const resend_otp_for_pin = `${prefix}/ussd/resend-otp`;
