import { Col, Row, Form, Input, Button, Checkbox, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import style from './styles';
import { useForm } from 'antd/lib/form/Form';
import SelectIdDocType from '../../../utils/FormSelects/IdDocTypes';
import PhoneNumber from '../../../utils/FormSelects/PhoneNumber';
import SelectCountry from '../../../utils/FormSelects/Countries';
import SelectCounty from '../../../utils/FormSelects/Counties';
import FormUploads from '../../../utils/FormUploads';
import { DocumentNameAndType, DocumentPageType, DocumentType } from '../cards/business-documents/types';
import ProgressBar from './progress-bar';
import { useHistory, useParams } from 'react-router-dom';
import { authenticatedRequest, unAuthenticatedRequest } from '../../../api';
import {
    send_director_invite_data,
    send_invite_director_documents,
    unauthenticated_upload_documents_business_profile,
    verify_director_data,
} from '../../../apis/business-accounts';
import Loader from '../../../utils/Loader';
import { DocType } from '../cards/business-documents/components/SIngleDirector';
import { ROUTES } from '../../../router/urls';
import { appIcon } from '../../../config/appIcon';
import { Constant } from '../../../config';
import LivenessDetection from './LivenessDetection';
const InviteWrapper = styled.div`
    ${style}
`;

const InviteDirectors = () => {
    const [form] = useForm();
    const [countrySelected, setSelectedCountry] = useState<any>({});
    const [docType, setDocType] = useState<string>('National Id');
    const documentId = useRef();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [stepState, setStepState] = useState<string[]>(['Active', 'Incomplete', 'Incomplete']);
    const { id }: any = useParams();
    const [data, setData] = useState<any>({});
    const history = useHistory();

    const uploadFilesToBusinessAccount = (
        documentId: string,
        url: string,
        pageType: DocumentPageType = DocumentPageType.STANDARD,
        fileId: string,
        docType?: string,
    ) => {
        unAuthenticatedRequest({ 'x-business-account-id': data?.businessAccountId }, false)
            .post(unauthenticated_upload_documents_business_profile(data?.inviteId), {
                documentId,
                url,
                pageType,
                fileId,
                documentLabel: data?.documentLabel,
                documentType: docType ? docType : undefined,
            })
            .then((res) => res.data)
            .then((res) => console.log(res))
            .catch((err) => {
                catchErr(err);
            });
    };
    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };

    useEffect(() => {
        unAuthenticatedRequest()
            .post(verify_director_data, { token: id })
            .then((res) => res.data?.data?.data)
            .then((res) => {
                res && setData(JSON.parse(res));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                catchErr(err);
            });
    }, []);

    const changeDocType = (type: DocType) => {
        setDocType(type);
        if (documentId.current) {
            form.setFieldsValue({
                front: undefined,
                back: undefined,
                passport: undefined,
            });
            documentId.current = undefined;
        }
    };

    const submitDocs = () => {
        if (step == 1) {
            setButtonLoading(true);
            unAuthenticatedRequest({ 'x-business-account-id': data?.businessAccountId })
                .post(send_invite_director_documents(data?.inviteId, documentId.current))
                .then((res) => res.data)
                .then((res) => {
                    setButtonLoading(false);
                    setStep(2);
                    setStepState(['Completed', 'Active', 'Incomplete']);
                    res && form.setFieldsValue({ ...res });
                })
                .catch((err) => {
                    setButtonLoading(false);
                    catchErr(err);
                });
        } else if (step == 2) {
            setStep(3);
            setStepState(['Completed', 'Completed', 'Active']);
        } else if (step === 3) {
            setButtonLoading(true);
            const submitObj = form.getFieldsValue();
            authenticatedRequest({ 'x-business-account-id': data?.businessAccountId })
                .put(send_director_invite_data(data?.inviteId, documentId.current), submitObj)
                .then((res) => res.data)
                .then(() => {
                    setButtonLoading(false);
                    notification.success({
                        message: 'Success',
                        description: 'Personal details uploaded successfully',
                    });
                    history.push({ pathname: ROUTES.SIGN_IN });
                })
                .catch((err) => {
                    setButtonLoading(false);
                    catchErr(err);
                });
        }
    };

    return (
        <InviteWrapper>
            <Row className="invite-body" justify="center">
                <Col xs={{ span: 24 }} sm={{ span: 22 }} md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 14 }}>
                    <Col span={24} className="form-wrapper">
                        <img src={appIcon} alt="Logo" className="logo" />
                        <ProgressBar
                            setStepState={setStepState}
                            handleStep={setStep}
                            step={step}
                            stepState={stepState}
                        />
                        {loading ? (
                            <Loader />
                        ) : (
                            <Form form={form} layout="vertical">
                                <div className="container">
                                    <div className="heading">
                                        {step == 1
                                            ? `Welcome ${data?.firstName}!`
                                            : step == 2
                                            ? 'Liveness Detection'
                                            : 'Confirm Personal Details'}
                                    </div>
                                    {step == 1 && (
                                        <div className="info">
                                            {data?.userName} is setting up an account for the business{' '}
                                            {data?.businessName} and has invited you as a director. Please upload the
                                            front and back of your ID card.
                                        </div>
                                    )}
                                    <div className="line" />
                                    {step == 1 ? (
                                        <>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <SelectIdDocType
                                                        formItemLabel="Select ID Doc Type"
                                                        formItemName={'docType'}
                                                        required
                                                        onChange={(value) => {
                                                            changeDocType(value);
                                                        }}
                                                        message="Please select document type"
                                                    />
                                                </Col>
                                            </Row>
                                            {docType == 'National Id' ? (
                                                <Row gutter={20} style={{ width: '100%' }}>
                                                    <Col span={12}>
                                                        <FormUploads
                                                            key={docType}
                                                            label={'Front of the ID'}
                                                            formItemName={'front'}
                                                            height="170px"
                                                            showImage
                                                            businessAccountId={data?.businessAccountId}
                                                            documentConfig={
                                                                documentId.current
                                                                    ? {
                                                                          documentId: documentId.current,
                                                                          documentName: DocumentNameAndType.DIRECTOR_ID,
                                                                          documentType: DocumentType.KYC,
                                                                          pageType: DocumentPageType.FRONT,
                                                                      }
                                                                    : {
                                                                          documentName: DocumentNameAndType.DIRECTOR_ID,
                                                                          documentType: DocumentType.KYC,
                                                                          pageType: DocumentPageType.FRONT,
                                                                      }
                                                            }
                                                            getResponse={(response) => {
                                                                documentId.current = response?.documentId;
                                                                uploadFilesToBusinessAccount(
                                                                    response?.documentId,
                                                                    response?.downloadURl,
                                                                    DocumentPageType.FRONT,
                                                                    response?.fileId,
                                                                    DocType.NATIONAL_ID,
                                                                );
                                                            }}
                                                            getFileStatus={setIsUploading}
                                                            disabled={isUploading || false}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <FormUploads
                                                            key={docType}
                                                            label={'Back of the ID'}
                                                            formItemName={'back'}
                                                            height="170px"
                                                            showImage
                                                            businessAccountId={data?.businessAccountId}
                                                            documentConfig={
                                                                documentId.current
                                                                    ? {
                                                                          documentId: documentId.current,
                                                                          documentName: DocumentNameAndType.DIRECTOR_ID,
                                                                          documentType: DocumentType.KYC,
                                                                          pageType: DocumentPageType.BACK,
                                                                      }
                                                                    : {
                                                                          documentName: DocumentNameAndType.DIRECTOR_ID,
                                                                          documentType: DocumentType.KYC,
                                                                          pageType: DocumentPageType.BACK,
                                                                      }
                                                            }
                                                            getResponse={(response) => {
                                                                documentId.current = response?.documentId;
                                                                uploadFilesToBusinessAccount(
                                                                    response?.documentId,
                                                                    response?.downloadURl,
                                                                    DocumentPageType.BACK,
                                                                    response?.fileId,
                                                                    DocType.NATIONAL_ID,
                                                                );
                                                            }}
                                                            getFileStatus={setIsUploading}
                                                            disabled={isUploading || false}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row gutter={20} style={{ width: '100%' }}>
                                                    <Col span={24}>
                                                        <FormUploads
                                                            key={docType}
                                                            label={'Passport'}
                                                            formItemName={'passport'}
                                                            height="170px"
                                                            showImage
                                                            businessAccountId={data?.businessAccountId}
                                                            getResponse={(response) => {
                                                                documentId.current = response?.documentId;
                                                                uploadFilesToBusinessAccount(
                                                                    response?.documentId,
                                                                    response?.downloadURl,
                                                                    DocumentPageType.STANDARD,
                                                                    response?.fileId,
                                                                    DocType.PASSPORT,
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}

                                            <Row className="remember-me">
                                                <Form.Item name="subscribe" valuePropName="checked" className="check">
                                                    <Checkbox />
                                                </Form.Item>
                                                <div className="get-email">
                                                    <span className="email">
                                                        Get emails from {Constant.title} and its partner about latest
                                                        product updates, industry  news and events
                                                    </span>
                                                    <span className="unsubs">
                                                        If you change your mind, you can unsubscribe at any time.
                                                    </span>
                                                    <span className="privacy">Privacy policy</span>
                                                </div>
                                            </Row>
                                        </>
                                    ) : step == 3 ? (
                                        <>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <Form.Item name={'id'} hidden />
                                                    <Form.Item
                                                        label="First Name"
                                                        name={'firstName'}
                                                        className="label"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please input your first name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input className="gapstack-input" placeholder="John" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Last Name"
                                                        name={'lastName'}
                                                        className="label"
                                                        rules={[
                                                            { required: true, message: 'Please input your last name!' },
                                                        ]}
                                                    >
                                                        <Input className="gapstack-input" placeholder="Doe" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <SelectIdDocType
                                                        formItemLabel="Select ID Doc Type"
                                                        formItemName={'docType'}
                                                        required
                                                        onChange={() => true}
                                                        message="Please select document type"
                                                        disabled
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={'docNumber'}
                                                        label="ID Doc Number"
                                                        className="label"
                                                        rules={[
                                                            { required: true, message: 'Please input your ID number!' },
                                                        ]}
                                                    >
                                                        <Input className="gapstack-input" placeholder="PAS1234" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <PhoneNumber
                                                        countryFieldName={'countryCode'}
                                                        inputFieldName={'phone'}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        name={'email'}
                                                        label="Email"
                                                        className="label"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                type: 'email',
                                                                message: 'Please input your email!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            className="gapstack-input"
                                                            placeholder="john@gapstack.com"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Address"
                                                        name={'address'}
                                                        className="label"
                                                        rules={[{ required: true, message: 'Please input address!' }]}
                                                    >
                                                        <Input className="gapstack-input" placeholder="P.O Box 777" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label="Post Code"
                                                        name={'postalCode'}
                                                        className="label"
                                                        rules={[
                                                            { required: true, message: 'Please input postal code!' },
                                                        ]}
                                                    >
                                                        <Input className="gapstack-input" placeholder="012345" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={20} style={{ width: '100%' }}>
                                                <Col span={12}>
                                                    <SelectCountry
                                                        formItemLabel="Country"
                                                        formItemName={'country'}
                                                        required
                                                        onChange={() => true}
                                                        message="Please select country"
                                                        onCountrySelected={(country) => setSelectedCountry(country)}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <SelectCounty
                                                        formItemLabel="County"
                                                        formItemName={'county'}
                                                        required
                                                        onChange={() => true}
                                                        message="Please select county"
                                                        countryId={countrySelected?.country_id}
                                                        form={form}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    ) : step == 2 ? (
                                        <LivenessDetection
                                            uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                                            data={data}
                                        />
                                    ) : null}
                                </div>
                                <Button
                                    className="gapstack-button"
                                    style={{ float: 'right' }}
                                    onClick={submitDocs}
                                    loading={buttonLoading}
                                >
                                    {step != 3 ? 'Save & Continue' : 'Submit'}
                                </Button>
                            </Form>
                        )}
                    </Col>
                </Col>
            </Row>
        </InviteWrapper>
    );
};
export default InviteDirectors;
