import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import style from './styles';
import { Button, notification, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { authenticatedRequest } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';
import { actions } from '../../components/authentication/sign-in/store/actions';
import { getBusinessId } from '../../router/urls';
import { BusinessWrapper } from '../../config';

const AvatarWrapper = styled.div`
    ${style}
`;

const topBar = (profilePicture, name) => {
    if (profilePicture !== null && profilePicture !== '' && profilePicture !== undefined) {
        return (
            <div className="avatar-container">
                <img
                    className="top-bar-avatar"
                    width={50}
                    src={`${process.env.REACT_APP_API_BASE_URL}/document-manager${profilePicture}`}
                    alt={'avatar-top-bar'}
                />
                <span className="full-name">{name}</span>
            </div>
        );
    }
    return <Avatar size="30" name={name} className="top-bar-avatar" />;
};

const profileAvatar = (profilePicture, uploadPic, name) => {
    if (profilePicture !== null && profilePicture !== '' && profilePicture !== undefined) {
        return (
            <>
                <img
                    src={`${process.env.REACT_APP_API_BASE_URL}/document-manager${profilePicture}`}
                    className="avatar"
                    alt={'avatar'}
                />
                <BusinessWrapper>
                    <Button type="link" style={{ paddingLeft: 0, paddingRight: 0, fontSize: 12 }} onClick={uploadPic}>
                        Change Avatar
                    </Button>
                </BusinessWrapper>
            </>
        );
    }
    return (
        <>
            <Avatar size="70" name={name} className="avatar" />
            <BusinessWrapper>
                <Button type="link" style={{ paddingLeft: 0, paddingRight: 0, fontSize: 12 }} onClick={uploadPic}>
                    Change Avatar
                </Button>
            </BusinessWrapper>
        </>
    );
};

interface Props {
    type?: 'topbar' | 'profile';
}

const UserAvatar = ({ type = 'topbar' }: Props) => {
    const dispatch = useDispatch();
    const auth: any = useSelector((state: RootState) => state.auth);

    const user = auth.user;

    const [changeProfilePic, setChangeProfilePic] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = React.useState(user?.profilePicture || '');
    const [loading, setLoading] = React.useState(false);
    const name = `${user?.firstName} ${user?.lastName}`;
    const uploadPic = () => {
        setChangeProfilePic(true);
    };

    const cancelUploadPic = () => {
        setChangeProfilePic(false);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            notification.error({
                message: 'You can only upload JPG/PNG file!',
            });
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            notification.error({
                message: 'Image must smaller than 2MB!',
            });
        }
        return isJpgOrPng && isLt2M;
    };

    const handleImgChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, () => {
                const image = info.fileList.slice(-1).pop()?.response.downloadURl;
                setUploadedImageUrl(image);
                saveProfilePicture(image);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const uploadLogo = {
        name: 'document',
        action: `${process.env.REACT_APP_API_BASE_URL}/document-manager/storage`,
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const saveProfilePicture = (image) => {
        authenticatedRequest()
            .put(`/authentication/users/profile/picture/${auth?.user?.userId}`, {
                profilePicture: image,
            })
            .then(() => {
                setChangeProfilePic(false);
                setLoading(false);
                user.user.profilePicture = image;
                dispatch(actions.updateUserProfilePicture(user));
                notification.success({
                    message: 'Success',
                    description: 'Profile Picture updated successfully',
                });
            })
            .catch((error) => {
                setChangeProfilePic(false);
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    return (
        <AvatarWrapper>
            {type === 'topbar' ? (
                topBar(uploadedImageUrl, name)
            ) : (
                <>
                    {!changeProfilePic ? (
                        profileAvatar(uploadedImageUrl, uploadPic, name)
                    ) : (
                        <>
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                {...uploadLogo}
                                beforeUpload={beforeUpload}
                                onChange={handleImgChange}
                                headers={{ 'x-business-account-id': getBusinessId() }}
                                data-testid="upload-profile"
                            >
                                {uploadButton}
                            </Upload>
                            <Button
                                type="link"
                                style={{ paddingLeft: 0, paddingRight: 0, fontSize: 12 }}
                                onClick={cancelUploadPic}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </>
            )}
        </AvatarWrapper>
    );
};

export default UserAvatar;
