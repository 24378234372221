import { css } from 'styled-components';
import { COLORS } from '../../config/theme';

const style = ({ activeColor, linkColor }) => css`
    display: flex;
    align-items: center;
    margin: 20px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme[COLORS.BORDER]};

    .back {
        height: 25px;
        cursor: pointer;
        color: ${(props) => props.theme[COLORS.PARAGRAPH]};
        margin-right: 10px;
    }

    .close {
        margin-left: auto;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }

    .ant-breadcrumb {
        .ant-breadcrumb-separator {
            margin: 0 6px;
        }

        .ant-breadcrumb-link {
            font-size: 14px;
            &[path] {
                cursor: pointer;
                transition: color 0.3s;
                touch-action: manipulation;
                &:hover {
                    color: ${(props) => {
                        if (linkColor) {
                            return props.theme[linkColor];
                        }
                        return props.theme[COLORS.ANCHOR];
                    }};
                }
            }
        }
        > span:last-child {
            color: ${(props) => {
                if (activeColor) {
                    return props.theme[activeColor];
                }
                return props.theme[COLORS.PRIMARY_TEXT];
            }} !important;
        }
    }
`;

export default style;

/*  border-top: 1px solid
     ${type == 'info' ? '#1890ff' : type === 'error' ? '#E96D60' : type === 'success' ? '#2CCC71' : '#e5e5e5'};
 margin-bottom: ${showMessage ? '0px' : '24px'};*/
