export const DEFAULT_COLORS = {
    PRIMARY: '#7C60FF',
    PRIMARY_TEXT: '#39426A',
    SECONDARY: '#6C7392',
    PARAGRAPH: '#777C93',
    BACKGROUND: '#F4F8F9',
    BACKGROUND_LIGHT: '#FBFCFD',
    SECOND_BUTTON: '#1E87F0',
    EDIT_BUTTON: '#0B95FA',
    BACKGROUND_STEPS: '#C4C4C4',

    //FORMS
    ICONS: '#8D8D8D',
    POP_UP: '#FFFFFF',
    FORM_COLOR: '#FAFAFA',
    BORDER: '#E5E5E5',
    PLACEHOLDER: '#A3A3B4',
    SECOND_BORDER: '#f3f4f8',
    UPLOAD_COLOR: '#333333',
    SHADOW: '0px 2px 6px rgba(0, 0, 0, 0.07)',
    SHADOW_LIGHT: '0px 0px 4px rgba(0, 0, 0, 0.07)',
    ACTION_BOX_SHADOW: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    DOCUMENT_UPLOADER: 'rgba(30, 135, 240, 0.2)',
    DOCUMENT_UPLOADER_ERROR: 'rgba(231, 76, 60, 0.1)',
    CLOUD_BACKGROUND_UPLOADER: 'rgba(30, 135, 240, 0.6)',
    CLOUD_BACKGROUND_UPLOADER_ERROR: 'rgba(231, 76, 60, 0.6)',
    TRANSPARENT: 'transparent',
    PROFILE_ICON: '#0B95FA',
    PROFILE_ICON_ERROR: 'rgba(231, 76, 60, 0.6)',
    COLOR_PICKER: '#415058',
    COLOR_PICKER_BORDER: '#e5e8e8',
    COLOR_PICKER_SHADOW: 'rgba(0, 0, 0, 0.12)',
    TERMS_AND_CONDITIONS_COLOR: '#1890ff',
    TERMS_AND_CONDITIONS_BORDER: '#1e87f0',
    CARD_CONTENT_COLOR: 'rgba(243, 244, 248, 0.3)',
    CARD_SHADOW: '0px 1px 4px rgba(0, 0, 0, 0.07)',
    SECONDARY_BACKGROUND: 'rgba(0, 0, 0, 0.05)',
    ANCHOR: '#1890ff',
    TABLE_HEADER_BACKGROUND: '#f1f1f1',

    AI_VALIDATION: 'rgba(30, 135, 240, 0.3)',
    AI_YELLOW: '#EDAB37',

    //STATES
    DECLINED: '#E74C3C',
    SUCCESS: '#2CCC71',
    APPROVED: '#6C7392',
    BLACK: '#000000',

    ORANGE: '#FE9902',
};
