import React from 'react';
import { Divider, Row } from 'antd';
import { ArrowLeft, X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import style from './styles';
import { goToRoute } from '../../router/urls';

interface Props {
    lightTitle?: string;
    pageTitle: string | undefined;
    pathName?: string;
    type?: 'error' | 'success' | 'info' | 'default';
    message?: string | null;
    link?: any;
    headingType?: 'light' | 'normal' | 'bold';
}

const PageTopBarWrapper = styled.div`
    ${style}
`;
const PageTopBar = ({ pageTitle, lightTitle, pathName, message, type, link, headingType = 'bold' }: Props) => {
    const history = useHistory();

    const goTo = (path) => {
        path ? history.push({ pathname: goToRoute(path) }) : history.goBack();
    };

    return (
        <PageTopBarWrapper type={type} showMessage={!!message}>
            <Row className="top-bar">
                <ArrowLeft className="back" onClick={() => goTo(pathName)} data-testid="back" />
                <span className="light-heading">{lightTitle}</span>
                <span
                    className={
                        headingType === 'normal'
                            ? 'normal-heading'
                            : headingType === 'light'
                            ? 'light-heading'
                            : 'bold-heading'
                    }
                >
                    {pageTitle}
                </span>
                <X className="close" onClick={() => goTo(pathName)} data-testid="close" />
            </Row>
            <Divider className="divider" />
            {message ? (
                <div className="message-container">
                    <span className="message" onClick={() => goTo(link)} data-testid="goto_route">
                        {message}
                    </span>
                </div>
            ) : null}
        </PageTopBarWrapper>
    );
};

export default PageTopBar;
