import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import success from '../../assets/icons/Svg/sucess.svg';
import { COLORS } from '../../config/theme';

const style = () => css`
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .success {
            width: 50px;
            height: 50px;
        }
        .product-line {
            font-weight: 600;
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-top: 15px;
        }
        .product-name {
            font-weight: normal;
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
            margin-top: 10px;
            text-align: center;
        }
        .done-button {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            box-sizing: border-box;
            border-radius: 5px;
            height: 45px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 15px;
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
        .add-product-container {
            flex: 1;
            flex-direction: row;
            justify-content: flex-start;
            display: flex;
            width: 100%;
            margin-top: 30px;
        }
    }
`;

const ModalWrapper = styled.div`
    ${style()}
`;

interface ModalProps {
    visible: boolean;
    name: string;
    onCancel: () => void;
    onComplete?: () => void;
    heading: string;
    description: string;
    closeText?: string;
}

const SuccessModal: FC<ModalProps> = ({
    heading,
    description,
    visible,
    name,
    onCancel,
    closeText,
    onComplete,
}: ModalProps) => {
    return (
        <Modal visible={visible} centered footer={null} onCancel={onCancel}>
            <ModalWrapper className="wrapper">
                <div className="wrapper">
                    <img src={success} alt="Success" className="success" data-testid="success" />
                    <span className="product-line">{heading}</span>
                    <span className="product-name" data-testid="description">
                        {description || name} {!description && name && 'was created successfully'}
                    </span>
                    <Button className="done-button" onClick={() => onComplete?.()}>
                        {closeText || 'Close'}
                    </Button>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

export default SuccessModal;
