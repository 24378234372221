import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .ant-modal-header {
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: none !important;
        border-radius: 2px 2px 0 0;
    }

    .qr-code-container {
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 20px 0px;
    }

    .use-code-button-container {
        display: flex;
        .button-forgot {
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            cursor: pointer;
            font-size: 12px;
            padding-left: 0px;
        }
    }
`;

export default style;
