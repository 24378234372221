import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { style } from './styles';
import greenTick from '../../../../../assets/Images/green-tick.svg';
import { AutoComplete, notification } from 'antd';
import { RefreshCcw } from 'react-feather';
import { authenticatedRequest } from '../../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { businessAccountActions } from '../../../../business-accounts/store/actions';
import { useHistory } from 'react-router-dom';
const BankDropDownWrapper = styled.div`
    ${style}
`;

interface Props {
    showDropDown: boolean;
    closeDropDown: (visible: boolean) => void;
}
const { Option } = AutoComplete;
const BankDropDown = ({ showDropDown, closeDropDown }: Props) => {
    const business: any = useSelector((state: RootState) => state.businessAccount);
    const [visible, setVisible] = useState<boolean>(business?.showAutoComplete);
    const [options, setOptions] = useState<any[]>([]);
    const dispatch = useDispatch();
    const isAdmin = business?.business?.businessName === 'Admin';
    const history = useHistory();

    const selectBusiness = (_: any, option: any) => {
        if (business) {
            dispatch(businessAccountActions.fetchBusinessInformation(history, true, option.key));
        }
        setVisible(false);
        closeDropDown(false);
    };

    const clearBusiness = () => {
        if (business) {
            dispatch(businessAccountActions.fetchBusinessInformation(history));
        }
        setVisible(true);
        closeDropDown(false);
    };

    const searchBusiness = (value: string) => {
        authenticatedRequest()
            .get(`/business-accounts/business/all-business/search?queryName=${value}`)
            .then((res) => {
                if (res.data && Array.isArray(res.data)) {
                    setOptions([
                        ...res.data.map((val) => {
                            return { ...val, value: val.businessName, key: val.id };
                        }),
                    ]);
                }
            })
            .catch((err) => {
                err = err?.response;
                notification.error({
                    message: err?.message || 'Oops! Unexpected error searching business',
                    type: 'error',
                });
            });
    };

    useEffect(() => {
        searchBusiness('');
    }, []);

    return (
        <BankDropDownWrapper showDropDown={showDropDown}>
            <div className="business-dropdown-container">
                <div className="business-container">
                    <div className={`business-item ${isAdmin ? 'active' : ''}`} onClick={clearBusiness}>
                        <span className="name">{'Admin'}</span>
                        {isAdmin && <img className="tick" src={greenTick} alt="" />}
                    </div>
                    {!visible && !isAdmin ? (
                        <>
                            <div className="business-item active">
                                <span className="name">{business?.business?.businessName}</span>
                                <img className="tick" src={greenTick} alt="" />
                            </div>
                            <div
                                className="change-business"
                                onClick={() => {
                                    setVisible(true);
                                    searchBusiness('');
                                }}
                            >
                                <RefreshCcw className="refresh" />
                                <span>Change business</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="business-item">
                                <span className="name">Find a business</span>
                            </div>
                            <div className="business-item">
                                <AutoComplete
                                    className="autocomp"
                                    allowClear
                                    autoFocus
                                    onSelect={selectBusiness}
                                    onChange={searchBusiness}
                                    onBlur={() => {
                                        setVisible(false);
                                    }}
                                >
                                    {options.map((option) => (
                                        <Option key={option?.id} value={option?.id}>
                                            {option?.businessName}
                                        </Option>
                                    ))}
                                </AutoComplete>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </BankDropDownWrapper>
    );
};

export default BankDropDown;
