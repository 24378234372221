import React from 'react';
import styled from 'styled-components';
import { Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import { GPSField } from '../../components/helpers';
import style from './edit-form-style';
import SelectCurrency from '../FormSelects/Currencies';
import { CountryInput, CountyInput } from './CountryInput';
import SelectBank from '../FormSelects/Banks';
import PhoneNumber from '../FormSelects/PhoneNumber';
import FormUploads from '../FormUploads';

const EditFormWrapper = styled.div`
    ${style}
`;

interface FormFieldProps {
    field:
        | {
              type?: any;
              name?: any;
              props?: any;
              required?: any;
              message?: any;
              placeHolder?: string;
              values?: any[];
              dependOnField?: string | string[];
              initialValue?: any;
              checkValue?: (...args: any) => boolean;
              render?: ({ field, form }: any) => any;
              radioGroupProps?: any;
              formItemProps?: any;
              colProps?: any;
              [key: string]: any;
          }
        | any;
    form: any;
    onChange?: any;
}

const getNestedObject = (nestedObj, pathArr) => {
    const name = Array.isArray(pathArr) ? pathArr : [pathArr];
    return name.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};

export const FormField = ({ field, form, onChange }: FormFieldProps) => {
    const {
        type,
        label,
        name,
        initialValue,
        placeHolder,
        props,
        required,
        message,
        rules,
        values,
        formItemProps,
        radioGroupProps,
        ...rest
    } = field;

    if (type === 'phone') return <PhoneNumber />;

    if (type === 'gps') return <GPSField name={props} form={form} />;

    if (type === 'bank') {
        return <SelectBank formItemLabel={label || name} formItemName={props} required={required} message={message} />;
    }

    if (type === 'currency') {
        return (
            <SelectCurrency
                formItemLabel="Currency"
                formItemName="currency"
                required={true}
                message="Please select currency"
            />
        );
    }

    if (type === 'country' || (type === 'select' && name === 'Country')) {
        return (
            <CountryInput formItemLabel={label || name} formItemName={props} required={required} message={message} />
        );
    }

    if (type === 'county' || (type === 'select' && name === 'County')) {
        return (
            <CountyInput
                formItemLabel={label || name}
                formItemName={props}
                required={required}
                message={message}
                form={form}
            />
        );
    }

    const rule = {
        required: required,
        message: message,
    };

    if (type === 'upload') {
        return <FormUploads formItemName={props} label={label || name} />;
    }

    if (type === 'radio') {
        return (
            <Form.Item
                label={label || name}
                name={props}
                className="label"
                rules={[rule, ...(rules || [])]}
                {...(formItemProps || {})}
            >
                <Radio.Group onChange={onChange} style={{ display: 'flex' }} {...(radioGroupProps || {})}>
                    {values?.map((item) => (
                        <Radio
                            key={item.value}
                            style={{ display: 'flex', flex: 1, alignItems: 'center' }}
                            value={item.value}
                        >
                            {item.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
        );
    }

    if (type === 'email') rule['type'] = 'email';

    if (type === 'textarea') {
        return (
            <Form.Item
                label={label || name}
                name={props}
                className="label"
                rules={[rule, ...(rules || [])]}
                {...(formItemProps || {})}
            >
                <Input.TextArea className="gapstack-input" autoSize={{ minRows: 3, maxRows: 3 }} {...rest} />
            </Form.Item>
        );
    }

    if (type === 'hidden') {
        return (
            <Form.Item label={label || name} name={props} initialValue={initialValue} hidden {...(formItemProps || {})}>
                <Input />
            </Form.Item>
        );
    }

    if (type === 'number') {
        return (
            <Form.Item
                label={label || name}
                name={props}
                className="label"
                rules={[rule, ...(rules || [])]}
                {...(formItemProps || {})}
            >
                <InputNumber className="gapstack-input number" placeholder={placeHolder} {...rest} />
            </Form.Item>
        );
    }
    return (
        <Form.Item
            label={label || name}
            name={props}
            className="label"
            rules={[rule, ...(rules || [])]}
            {...(formItemProps || {})}
        >
            {values ? (
                <Select
                    className="form-select"
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    onChange={onChange}
                    placeholder={placeHolder}
                    {...rest}
                >
                    {values?.map((item) => (
                        <Select.Option value={item.value || item} key={item.value || item}>
                            {item.label || item}
                        </Select.Option>
                    ))}
                </Select>
            ) : (
                <Input className="gapstack-input" autoComplete="off" placeholder={placeHolder} />
            )}
        </Form.Item>
    );
};

const FormFieldWrapper = ({ children, form, field }: FormFieldProps & { children?: any }) => {
    const renderForm = () => (
        <Col span={field?.span ?? 12} {...field.colProps}>
            {field.render ? field.render?.({ field, form }) : children}
        </Col>
    );

    if (field.dependOnField) {
        const name = field.dependOnField;
        return (
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) => {
                    const prev = getNestedObject(prevValues, name);
                    const next = getNestedObject(curValues, name);
                    return prev != next;
                }}
            >
                {(instance) => {
                    if (field.checkValue?.(instance.getFieldValue(name))) {
                        return renderForm();
                    }
                    return null;
                }}
            </Form.Item>
        );
    }

    return renderForm();
};

export const DependentFormFields = ({ field, form }: FormFieldProps) => {
    return (
        <FormFieldWrapper field={field} form={form}>
            <FormField field={field} form={form} />
        </FormFieldWrapper>
    );
};

interface Props {
    data: any[];
    form: any;
}

const EditorForm = ({ data = [], form }: Props) => {
    return (
        <EditFormWrapper>
            {data.map((row) => (
                <Row gutter={20} key={row.id} style={{ marginTop: '15px' }}>
                    {row.fields.map((field) => (
                        <DependentFormFields key={field.id} field={field} form={form} />
                    ))}
                </Row>
            ))}
        </EditFormWrapper>
    );
};

export default EditorForm;
