import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { X } from 'react-feather';
interface Props {
    showModal: boolean;
    hideModal: (val: boolean) => void;
    setDirectors: (val: any[]) => void;
    directors: any[];
    businessType: string;
}

const AddDirectorModal: FunctionComponent<Props> = ({
    showModal,
    hideModal,
    directors,
    setDirectors,
    businessType,
}: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        const tempArr = [...(directors || [])];
        tempArr.push({ name: values?.documentLabel });
        setDirectors([...tempArr]);
        setLoading(false);
        hideModal(false);
    };
    return (
        <Modal
            title={`Add ${
                businessType == 'Company' ? 'Director' : businessType == 'Partnership' ? 'Partner' : 'Person'
            }`}
            visible={showModal}
            onCancel={() => hideModal(false)}
            footer={false}
            getContainer={false}
            closeIcon={<X className="close-modal" />}
            width="40%"
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish}>
                <div className="info" style={{ marginBottom: '15px' }}>
                    Please enter the name of{' '}
                    {businessType == 'Company' ? 'director' : businessType == 'Partnership' ? 'partner' : 'person'} you
                    would like to add
                </div>
                <Row gutter={20}>
                    <Col span={24}>
                        <Form.Item
                            label={`${
                                businessType == 'Company'
                                    ? 'Director'
                                    : businessType == 'Partnership'
                                    ? 'Partner'
                                    : 'Person'
                            }'s Name`}
                            name="documentLabel"
                            className="label"
                            rules={[{ required: true, message: 'Please enter name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="John Doe" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => hideModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" htmlType="submit" loading={loading}>
                        Add{' '}
                        {businessType == 'Company' ? 'Director' : businessType == 'Partnership' ? 'Partner' : 'Person'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddDirectorModal;
