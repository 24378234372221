const prefix = process.env.NODE_ENV === 'development' ? 'sales-invoices' : 'sales-invoices';
const new_prefix = process.env.NODE_ENV === 'development' ? 'quotation-po-invoice' : 'quotation-po-invoice';

export const get_invoices = (currentPage, pageSize) =>
    `${new_prefix}/invoice/supplier?page=${currentPage}&limit=${pageSize}`;
export const create_invoice = `${new_prefix}/invoice`;
export const edit_invoice = (id) => `${new_prefix}/invoice/supplier/${id}`;
export const get_invoice = (id) => `${new_prefix}/invoice/supplier/${id}`;
export const delete_invoice = (id) => `${new_prefix}/invoice/supplier/${id}`;
export const upload_discount_invoice = `${new_prefix}/invoice/discounting`;

export const get_invoice_summary = `${new_prefix}/invoice/summary/supplier`; //UPDATED

export const download_bulk_order_invoices = `/document-manager/storage/020d6252-dbe0-4310-94c1-9b46b983b2a8/07dcbf5e65d3bfc47e8bb9727f588412-Invoice_Bulk_Upload.xls`;

// send bulk invoice validation
export const send_bulk_invoice_validation = `${prefix}/invoices/bulk-upload`;

export const change_invoice_status_supplier = (id) => `${new_prefix}/invoice/status/supplier/${id}`; //UPDATED
export const send_invoice_via_email = (type) => `${prefix}/send-email/${type}`; //UPDATED
export const resend_invoice_email = (id) => `${prefix}/sale-invoice/resend-email/${id}`;
export const buyer_invoice_status_change = (id) => `${new_prefix}/invoice/status/buyer/${id}`; //UPDATED

export const validate_received_invoice = (id) => `${prefix}/invoices/validate-invoice/${id}`;
export const upload_received_invoice = `${new_prefix}/invoice/upload`; //UPDATED

export const get_received_invoices = `${new_prefix}/invoice/buyer`; //UPDATED

export const get_received_invoice_summary = `${new_prefix}/invoice/summary/buyer`; //UPDATED

export const get_received_invoice = (id) => `${new_prefix}/invoice/buyer/${id}`; //UPDATED

export const fetch_received_invoices_from_supplier = (id) => `${new_prefix}/invoice/buyer?supplierId=${id}`;
export const fetch_invoices_for_buyer = `${new_prefix}/invoice/supplier`;
