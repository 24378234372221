import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const PurchaseOrderTableComponent = (props: any) => (
    <AuthenticatedLayout>
        <PurchaseOrderTable {...props} />
    </AuthenticatedLayout>
);
const PurchaseOrderTable = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const AddPurchaseOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddPurchaseOrder {...props} />
    </AuthenticatedLayout>
);
const AddPurchaseOrder = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

const CreatePurchaseOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreatePurchaseOrder {...props} />
    </AuthenticatedLayout>
);
const CreatePurchaseOrder = Loadable({
    loader: () => import('./add-new/create-purchase-step-1' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const UploadPurchaseOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <UploadPurchaseOrder {...props} />
    </AuthenticatedLayout>
);
const UploadPurchaseOrder = Loadable({
    loader: () => import('./add-new/upload-purchase' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const CreatePurchaseOrderStep2Component = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreatePurchaseOrderStep2 {...props} />
    </AuthenticatedLayout>
);
const CreatePurchaseOrderStep2 = Loadable({
    loader: () => import('./add-new/create-purchase-step-2' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

const BulkPurchaseOrder = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <BulkPurchaseOrderComponent {...props} />
    </AuthenticatedLayout>
);
const BulkPurchaseOrderComponent = Loadable({
    loader: () => import('./add-new/upload-purchase/bulk-upload-table' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

const PurchaseOrderViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <PurchaseOrderView {...props} />
    </AuthenticatedLayout>
);
const PurchaseOrderView = Loadable({
    loader: () => import('./view-purchase-order/components' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const EditPurchaseOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditPurchaseOrder {...props} />
    </AuthenticatedLayout>
);
const EditPurchaseOrder = Loadable({
    loader: () => import('./edit-purchase-order' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

export {
    PurchaseOrderTableComponent,
    PurchaseOrderViewComponent,
    AddPurchaseOrderComponent,
    CreatePurchaseOrderComponent,
    UploadPurchaseOrderComponent,
    CreatePurchaseOrderStep2Component,
    BulkPurchaseOrder,
    EditPurchaseOrderComponent,
    PurchaseOrderTable,
};
