import React, { FC, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import fileDownload from 'js-file-download';
import { notification } from 'antd';
import axios from 'axios';

import download from '../../../../assets/Images/cloud-download.svg';
import { COLORS, generateTheme } from '../../../../config/theme';

const antIcon = <LoadingOutlined style={{ color: `${generateTheme()[COLORS.PRIMARY]}` }} spin />;

interface IProps {
    name: string;
    content: string;
    contentType?: string;
}

const DownloadAttachment: FC<IProps> = ({ name, content, contentType = 'base64' }: IProps) => {
    const [downloading, setDownloading] = useState(false);

    const binaryToBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: Array<any> = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    const downloadFile = () => {
        const fileName: string = name;
        const blob = binaryToBlob(content, contentType);
        const blobUrl = URL.createObjectURL(blob);
        axios
            .get(blobUrl, {
                responseType: 'blob',
            })
            .then((res) => {
                fileDownload(res.data, fileName);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    return (
        <div className="download-attachment" onClick={downloadFile}>
            <span className={'filename'}>{name}</span>
            {downloading ? antIcon : <img src={download} className="cloud" alt={'download'} />}
        </div>
    );
};

export default DownloadAttachment;
