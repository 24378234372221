import React from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 73px;

    & > * {
        width: 100%;
        align-items: center;

        & > div:last-child {
            display: flex;
            justify-content: flex-end;
        }
        & > div:only-child {
            display: block;
        }
    }
`;

const PageHeader = ({ children }: any) => {
    return <HeaderWrapper>{children}</HeaderWrapper>;
};

export default PageHeader;
