import React, { useEffect, useState } from 'react';
// import { DownloadCloud } from 'react-feather';
import download from '../../assets/Images/cloud-download.svg';
import styled from 'styled-components';
import fileDownload from 'js-file-download';
import style from './styles';
import { authenticatedRequest } from '../../api';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { Download, Trash2 } from 'react-feather';
import { titleCase } from '../TitleCase';
import { COLORS, generateTheme } from '../../config/theme';

const DownloadFileWrapper = styled.div`
    ${style}
`;

interface Props {
    name?: string;
    downloadFileLink?: string;
    width?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    err?: string;
    showImage?: boolean;
    height?: any;
    onClick?: () => void;
    readOnly?: boolean;
}

const antIcon = <LoadingOutlined style={{ color: `${generateTheme()[COLORS.PRIMARY]}` }} spin />;

const DownloadFile = ({
    name,
    downloadFileLink,
    width,
    validateStatus,
    err,
    showImage,
    height,
    onClick,
    readOnly,
}: Props) => {
    const [downloading, setDownloading] = useState(false);
    const [fileType, setFileType] = useState(null);

    const getDocumentTypeFromUrl = (url) => {
        setFileType(url?.split('.').pop().split(/\#|\?/)[0].toLowerCase());
    };

    const downloadLink = React.useMemo((): string => {
        const link = downloadFileLink as string;
        const regex = new RegExp(
            '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
        );
        if (regex.test(link)) {
            return link;
        }
        return `/document-manager/${link}`.replace('//', '/');
    }, [downloadFileLink]);

    const downloadFile = () => {
        const downloadFileName = downloadLink?.split('/').slice(-1).pop() || '';
        setDownloading(true);
        return authenticatedRequest()
            .get(downloadLink, {
                responseType: 'blob',
            })
            .then((res) => {
                setDownloading(false);
                fileDownload(res.data, downloadFileName);
            })
            .catch((error) => {
                setDownloading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        if (downloadFileLink) {
            getDocumentTypeFromUrl(downloadFileLink);
        }
    }, [downloadFileLink]);

    return (
        <DownloadFileWrapper width={width || '365px'} style={{ maxWidth: '100%' }} validateStatus={validateStatus}>
            {showImage && (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'svg') ? (
                <div style={{ position: 'relative' }}>
                    <img
                        src={
                            downloadFileLink?.startsWith('http')
                                ? downloadFileLink
                                : `${process.env.REACT_APP_API_BASE_URL}/document-manager${downloadFileLink}`
                        }
                        alt=""
                        style={{ width: 'auto', height, objectFit: 'contain', borderRadius: '5px' }}
                    />
                    <div className="name-wrapper">
                        <span>{titleCase(name)}</span>
                        {downloading ? (
                            <LoadingOutlined style={{ color: '#7C60FF' }} className="download-icon" spin />
                        ) : (
                            <Download className="download-icon" onClick={downloadFile} />
                        )}
                        <div className="vertical-line" />
                        <Trash2 className="del-icon" onClick={onClick} />
                    </div>
                    {err && (
                        <div className="err-messsage" style={{ marginBottom: 10 }}>
                            {err}
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div className="change-div-wrapper">
                        <div className="download-documents" onClick={downloadFile}>
                            <span>{name}</span>
                            {downloading ? antIcon : <img src={download} className="cloud" alt={'download'} />}
                        </div>
                        {!readOnly && (
                            <Button type="link" style={{ paddingLeft: 0 }} onClick={onClick}>
                                Change
                            </Button>
                        )}
                    </div>
                    {err && <div className="err-messsage">{err}</div>}
                </>
            )}
        </DownloadFileWrapper>
    );
};

export default DownloadFile;
