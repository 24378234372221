import React from 'react';
import Icons from '../../assets/icons/Svg/sidebar-active/icons';
import { colorTheme, enabledRouteArray } from './getMenu';
import { COLORS } from '../theme';
import { ROUTES } from '../../router/urls';
import { getUser } from '../user';
const isBank = getUser() == 'BANK';

export const tsc = {
    firstItemsForBank: [
        {
            key: 'dashboard',
            activeImage: <Icons.Dashboard fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Dashboard fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Dashboard',
            link: ROUTES.DASHBOARD_BANK,
            pathNames: ['dashboard'],
            canDisable: false,
        },
        {
            key: 'businesses',
            activeImage: <Icons.Business fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Business fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Businesses',
            link: ROUTES.BUSINESS,
            pathNames: ['businesses', 'verify', 'complete-kyc'],
            canDisable: false,
            enabled: true,
            onhover: ['BusinessViewComponent'],
        },
    ],
    firstItems: [
        {
            key: 'dashboard',
            activeImage: <Icons.Dashboard fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Dashboard fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Dashboard',
            link: ROUTES.DASHBOARD,
            pathNames: ['dashboard'],
            canDisable: false,
            enabled: !!Number(enabledRouteArray[0]) ?? true,
        },
    ],
    secondItems: [
        {
            key: 'import-loans',
            activeImage: <Icons.ImportTrade fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.ImportTrade fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Import loans',
            link: ROUTES.IMPORT_LOANS,
            pathNames: ['import-trade', 'import-loans'],
            canDisable: false,
            disabledImage: <Icons.ImportTrade fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['TradeFinanceComponent'],
            enabled: !!Number(enabledRouteArray[5]) ?? true,
        },
    ],
    thirdItems: [
        {
            key: 'purchases',
            activeImage: <Icons.Purchases fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Purchases fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Purchases',
            link: ROUTES.SUPPLIER_CENTRAL,
            pathNames: ['purchases'],
            canDisable: true,
            subMenu: true,
            disabledImage: <Icons.Purchases fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: [
                'PurchaseSupplierTableComponent',
                'PurchaseOrderTableComponent',
                'ReceivedQuotationsComponent',
                'PurchaseInvoiceTableComponent',
            ],
            enabled: !!Number(enabledRouteArray[17]) ?? true,
            subMenuItems: [
                {
                    title: 'Supplier Central',
                    key: 'supplier-central',
                    pathNames: ['supplier-central'],
                    canDisable: true,
                    link: ROUTES.SUPPLIER_CENTRAL,
                    enabled: !!Number(enabledRouteArray[18]) ?? true,
                },
                {
                    title: 'Purchase Orders',
                    key: 'purchase-order',
                    pathNames: ['purchase-order'],
                    canDisable: true,
                    link: ROUTES.PURCHASE_ORDER,
                    enabled: !!Number(enabledRouteArray[19]) ?? true,
                },
                // {
                //     title: 'Received Quotations',
                //     key: 'received-quotations',
                //     pathNames: ['received-quotations', 'received-quotation'],
                //     canDisable: true,
                //     link: ROUTES.RECEIVED_QUOTATIONS,
                //     enabled: !!Number(enabledRouteArray[20]) ?? true,
                // },
                {
                    title: 'Invoices',
                    key: 'purchase-invoices',
                    pathNames: ['purchase-invoices'],
                    canDisable: true,
                    link: ROUTES.INVOICES,
                    enabled: !!Number(enabledRouteArray[21]) ?? true,
                },
            ],
        },
        {
            key: 'products',
            activeImage: <Icons.Product fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Product fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Inventory',
            link: ROUTES.PRODUCTS,
            pathNames: ['products'],
            canDisable: true,
            disabledImage: <Icons.Product fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['ProductsComponent'],
            enabled: !!Number(enabledRouteArray[27]) ?? true,
        },
    ],
    fourthItems: [
        // {
        //     key: 'accounts',
        //     activeImage: <Icons.Payment fill={colorTheme[COLORS.PRIMARY]} />,
        //     inactiveImage: <Icons.Payment fill={colorTheme[COLORS.PARAGRAPH]} />,
        //     title: 'Accounts & Wallets',
        //     link: ROUTES.ACCOUNTS,
        //     pathNames: ['accounts'],
        //     canDisable: !isBank,
        //     disabledImage: <Icons.Payment fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
        //     onhover: ['PaymentsComponent'],
        //     enabled: !!Number(enabledRouteArray[29]) ?? true,
        // },
        {
            key: 'payments',
            activeImage: <Icons.Payment fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Payment fill={colorTheme[COLORS.PARAGRAPH]} />,
            title: 'Payments',
            link: ROUTES.PAYMENTS,
            pathNames: ['payments'],
            canDisable: !isBank,
            disabledImage: <Icons.Payment fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            onhover: ['PaymentsComponent'],
            enabled: !!Number(enabledRouteArray[30]) ?? true,
        },
    ],
    fifthItems: [
        // {
        //     key: 'reports',
        //     activeImage: <Icons.Reports fill={colorTheme[COLORS.PRIMARY]} />,
        //     inactiveImage: <Icons.Reports fill={colorTheme[COLORS.PARAGRAPH]} />,
        //     title: 'Insights',
        //     link: ROUTES.INSIGHTS,
        //     pathNames: ['insights'],
        //     canDisable: false,
        //     disabledImage: <Icons.Reports fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
        //     enabled: !!Number(enabledRouteArray[32]) ?? true,
        // },
        // {
        //     key: 'developer',
        //     activeImage: <Icons.Developer fill={colorTheme[COLORS.PRIMARY]} />,
        //     inactiveImage: <Icons.Developer fill={colorTheme[COLORS.PARAGRAPH]} />,
        //     title: 'Developer',
        //     link: ROUTES.DEVELOPER,
        //     pathNames: ['developer'],
        //     canDisable: false,
        //     disabledImage: <Icons.Developer fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
        //     enabled: !!Number(enabledRouteArray[33]) ?? true,
        // },
        {
            key: 'settings',
            activeImage: <Icons.Settings fill={colorTheme[COLORS.PRIMARY]} />,
            inactiveImage: <Icons.Settings fill={colorTheme[COLORS.PARAGRAPH]} />,
            disabledImage: <Icons.Settings fill={colorTheme[COLORS.BACKGROUND_STEPS]} />,
            title: 'Settings',
            link: isBank ? ROUTES.BANK_SETTINGS : ROUTES.SETTINGS,
            pathNames: ['business', 'settings', 'bank-settings'],
            canDisable: false,
            onhover: ['SettingsComponent', 'BankSettingsComponent'],
            enabled: !!Number(enabledRouteArray[34]) ?? true,
        },
    ],
    getFinanceRoute: ROUTES.GET_FINANCING_SALES,
    newGuaranteeRoute: ROUTES.ADD_NEW_GUARANTEE,
};
