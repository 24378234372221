import React from 'react';
import styled from 'styled-components';
import { Row, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { IoCloseCircleOutline } from 'react-icons/io5';
import ContentLayout from '../../components/Layouts/Content';
import help from '../../assets/Images/help.svg';
import style from './styles';
import { appIcon } from '../../config/appIcon';
import getEnvTheme from '../../config/themes/getEnvTheme';

const VerifyWrapper = styled.div`
    ${style}
`;

const antIcon = <LoadingOutlined style={{ fontSize: 50, color: getEnvTheme()?.PRIMARY ?? '#7C60FF' }} spin />;

interface VerifyProps {
    text: string;
    description?: string | null;
    loading?: boolean;
    status?: 'success' | 'error' | null;
}

const Verify: React.FC<VerifyProps> = ({
    text,
    description = 'This will only take a short while.',
    loading = true,
    status = null,
}: VerifyProps) => {
    return (
        <ContentLayout>
            <VerifyWrapper>
                <div className="confirm-wrapper">
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>
                    <div className="container">
                        <Row style={{ padding: '0px 85px', flex: 1, display: 'flex', justifyContent: 'center' }}>
                            {loading ? (
                                <Spin style={{ marginTop: 20 }} indicator={antIcon} />
                            ) : !loading && status === 'error' ? (
                                <IoCloseCircleOutline size={60} color="#F44336" />
                            ) : null}
                        </Row>

                        <Row style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                            <h3 className="link-heading">{text}</h3>
                        </Row>
                        <Row style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                            <p className="sub-heading">{description}</p>
                        </Row>
                    </div>
                </div>
            </VerifyWrapper>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </ContentLayout>
    );
};

export default Verify;
