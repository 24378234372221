export const header = [
    {
        id: 1,
        title: '',
        dataIndex: 'label',
        editable: false,
        width: '20%',
        disabled: false,
    },
    {
        id: 2,
        title: 'First Unit',
        dataIndex: 'firstUnit',
        editable: true,
        disabled: true,
    },
    {
        id: 3,
        title: 'Last Unit',
        dataIndex: 'lastUnit',
        editable: true,
        disabled: false,
    },
    {
        id: 4,
        title: 'Per Unit',
        dataIndex: 'perUnit',
        editable: true,
        disabled: false,
    },
    {
        id: 5,
        title: 'Flat Fee',
        dataIndex: 'flatFee',
        editable: true,
        disabled: false,
    },
];

export const newData = {
    key: '1',
    label: 1,
    firstUnit: 1,
    lastUnit: 2,
    perUnit: 0,
    flatFee: 0,
};
export const lastData = {
    key: '2',
    label: 1,
    firstUnit: 3,
    lastUnit: '∞',
    perUnit: 0,
    flatFee: 0,
};

const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelvth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
];
const deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

export function stringifyNumber(n: number) {
    if (n < 20) return special[n];
    if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
    return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}
