import { css } from 'styled-components';
import { COLORS } from '../../../../../../../../config/theme';

const style = () => css`
    .sync {
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .sync-check {
            font-weight: 500;
            font-size: 13px;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        }
        .sync-text {
            font-weight: 500;
            font-size: 13px;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        }
    }
`;
export default style();
