import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ArrowRight, Paperclip, Trash } from 'react-feather';
import { Button, Divider, Empty, Form, Upload, Input } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { UserIcon } from '../messages-list';
import DownloadAttachment from './DownloadAttachment';

import style from './styles';
import { authenticatedRequest } from '../../../../api';
import { delete_mail } from '../../apis';

const MessagesViewWrapper = styled.div`
    ${style}
`;

interface Props {
    data: any;
    getEmailData?: () => void;
    loading: boolean;
    onDelete: (message: any) => void;
}

const MessagesView = ({ data, getEmailData, loading, onDelete }: Props) => {
    const { businessId }: any = useParams();
    const authUser = useSelector((state: any) => state.auth?.user || {});
    const [deleting, setDeleting] = useState(false);

    const EmptyMessageView = () => {
        return (
            <MessagesViewWrapper>
                <div className="empty">
                    <Empty
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        description="Open a mail to view"
                    />
                </div>
            </MessagesViewWrapper>
        );
    };

    if (loading) {
        return (
            <MessagesViewWrapper
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '95vh',
                }}
            >
                <LoadingOutlined style={{ fontSize: 30, color: '#7C60FF' }} spin />
            </MessagesViewWrapper>
        );
    }

    const moveToTrash = (data) => {
        const obj = {
            uid: data.id,
            source: data.source,
            target: '[Gmail]/Bin',
        };
        const header = {
            'x-business-account-id': businessId,
            'x-user-id': authUser.userId,
        };
        setDeleting(true);
        authenticatedRequest(header)
            .put(delete_mail, obj)
            .then(() => {
                onDelete(data);
                getEmailData?.();
            })
            .finally(() => setDeleting(false));
    };

    const onFinish = (values: any) => {
        console.log(values);
        // authenticatedRequest({ 'x-business-account-id': businessId, 'x-user-id': authUser })
    };

    if (!data?.id) {
        return <EmptyMessageView />;
    }

    return (
        <MessagesViewWrapper>
            <div className="view-header">
                <div>
                    <UserIcon
                        size="40"
                        name={data?.user?.name || data?.user?.email}
                        className="avatar"
                        round
                        {...data.colorScheme}
                    />
                    <div className="message-details">
                        <span>{data?.user?.name}</span>
                        <span className="light-text">{data?.user?.email}</span>
                    </div>
                    <span className="light-text">
                        {data?.createdAt}
                        {/* {todayDate === moment(data?.createdAt).format('D/MM/YY')
                            ? moment(data?.createdAt).format('HH:mm')
                            : moment(data?.createdAt).format('D/MM/YY')} */}
                    </span>
                </div>
            </div>
            <div className="view-body">
                <p className="title">{data?.body?.title}</p>
                <div>
                    {data?.body?.text?.split('\n').map((t, key) => (
                        <p key={key}>{t}</p>
                    ))}
                </div>

                {data.files?.map?.((file, index) => (
                    <DownloadAttachment
                        key={index}
                        name={file.name}
                        content={file.content}
                        contentType={file.contentType}
                    />
                ))}

                <div className="actions">
                    <Button type={'text'}>
                        <ArrowRight size="16" />
                        <span>Forward</span>
                    </Button>
                    <Button type={'text'} loading={deleting} onClick={() => moveToTrash(data)}>
                        <Trash size="16" />
                        <span>Delete</span>
                    </Button>
                </div>
                <Divider />

                <div className="reply-mail">
                    <Form name="send-reply" layout="vertical" onFinish={onFinish}>
                        <Form.Item name="reply-mail" rules={[{ required: false }]}>
                            <Input.TextArea
                                className="reply-input"
                                placeholder="Reply mail..."
                                autoSize={{ minRows: 1 }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className="reply-actions">
                                <Upload>
                                    <Paperclip size="16" />
                                    <span className="attach">Attach Files</span>
                                </Upload>
                                <div>
                                    <Button type="primary" className="primary-button">
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </MessagesViewWrapper>
    );
};
export default React.memo(MessagesView);
