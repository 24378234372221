import { DatePicker, Form, Input, Row, Col, Button, FormInstance } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import style from './styles/poDetails';
import { data } from './data';
import AddBusinessFormItem from '../../../../../../../utils/Document/AddBusinessFormItem';
import moment from 'moment';
const FormWrapper = styled.div`
    ${style}
`;

interface Props {
    setBuyer: (buyer) => void;
    buyer?: string;
    className?: string;
    form: FormInstance;
}
const PODetails = ({ setBuyer, className, buyer, form }: Props) => {
    const dateFormat = 'YYYY-MM-DD';
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
    const date = moment(new Date()).format(dateFormat);
    const disabledDate = (current) => {
        // Can not select days before today and today
        const invoiceDate = form.getFieldValue('documentDate');
        return current && current < moment(invoiceDate).endOf('day');
    };
    const onPredefinedDateSelected = (numberOfDays) => {
        const invoiceDate = form.getFieldValue('documentDate');
        const paymentDate = moment(invoiceDate).add(numberOfDays, 'days');
        form.setFieldsValue({ paymentDate: paymentDate });
        setOpenDatePicker(false);
    };
    const customDatePicker = (originalPanel) => {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                {originalPanel}
                <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
                    <Button type="link" onClick={() => onPredefinedDateSelected(15)}>
                        Within 15 days
                    </Button>
                    <Button type="link" onClick={() => onPredefinedDateSelected(30)}>
                        Within 30 days
                    </Button>
                    <Button type="link" onClick={() => onPredefinedDateSelected(45)}>
                        Within 45 days
                    </Button>
                    <Button type="link" onClick={() => onPredefinedDateSelected(60)}>
                        Within 60 days
                    </Button>
                    <Button type="link" onClick={() => onPredefinedDateSelected(90)}>
                        Within 90 days
                    </Button>
                </div>
            </div>
        );
    };
    return (
        <FormWrapper className={className}>
            <div className="form-container">
                {data.map((row) => (
                    <Row gutter={20} key={row.id} style={{ marginTop: '15px' }}>
                        {row.fields.map((field) => (
                            <Col span={12} key={field.id}>
                                {field.props == 'paymentDate' ? (
                                    <Form.Item
                                        label="Payment Date"
                                        name="paymentDate"
                                        dependencies={['documentDate']}
                                        className="label"
                                        rules={[
                                            { required: true, message: 'Please select payment date' },
                                            ({ getFieldValue }) => ({
                                                validator(_rule, value) {
                                                    if (value < getFieldValue('documentDate')) {
                                                        return Promise.reject("Date can't be less than invoice date!");
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker
                                            onClick={() => setOpenDatePicker(true)}
                                            className="gapstack-input"
                                            placeholder={date}
                                            showToday={false}
                                            panelRender={customDatePicker}
                                            open={openDatePicker}
                                            onChange={() => setOpenDatePicker(false)}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                ) : field.type == 'date' ? (
                                    <Form.Item
                                        label={field.name}
                                        name={field.props}
                                        className="label"
                                        rules={[
                                            {
                                                required: field.required,
                                                message: field.message,
                                            },
                                        ]}
                                    >
                                        <DatePicker className="gapstack-input" />
                                    </Form.Item>
                                ) : field.type == 'AddBusinessFormItem' ? (
                                    <AddBusinessFormItem
                                        type="buyer"
                                        value={buyer}
                                        formName="buyer"
                                        formLabel={field.name}
                                        placeholder="Search for buyer by name or ID"
                                        newBusinessText="Add new buyer"
                                        onComplete={(selectedBuyer) => {
                                            setBuyer(selectedBuyer);
                                        }}
                                    />
                                ) : (
                                    <Form.Item
                                        label={field.name}
                                        name={field.props}
                                        className="label"
                                        rules={[
                                            {
                                                required: field.required,
                                                message: field.message,
                                            },
                                        ]}
                                    >
                                        <Input className="gapstack-input" type={field.type} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                ))}
            </div>
        </FormWrapper>
    );
};

export default PODetails;
