import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useSearchParam = <Params extends { [K in keyof Params]?: string } = any>(): Params => {
    const location = useLocation();
    return queryString.parse(location.search) as Params;
};

export const useFullPath = () => {
    const location = useLocation();
    return location.pathname + location.search;
};

export const createSearchParams = (data: Record<string, any>, prefix = true): string => {
    return (prefix ? '?' : '') + queryString.stringify(data);
};
