import { notification } from 'antd';
import qs from 'qs';
import { unAuthenticatedRequest } from '../../../../../api';
import { sign_in } from '../../api';
import {
    IS_USER_SIGNING_IN,
    USER_SIGN_IN_SUCCESS,
    USER_SIGN_IN_FAILURE,
    USER_LOG_OUT,
    UPDATE_USER_PROFILE_PICTURE,
    ON_TWO_STEP_JWT_TOKEN_RECEIVED,
    UPDATE_TWO_STEP_AUTHENTICATION,
    ADD_USER_GROUPS,
    CHANGE_ACTIVE_USER_GROUP,
    UPDATE_USSD_STATUS,
} from '../types';
import { goToRoute, ROUTES } from '../../../../../router/urls';
const isUserSigningIN = () => ({
    type: IS_USER_SIGNING_IN,
});

const onUserSignInSuccess = (data) => ({
    type: USER_SIGN_IN_SUCCESS,
    data,
});

const onUserSignInFailure = () => ({
    type: USER_SIGN_IN_FAILURE,
});

const onUserLogout = () => ({
    type: USER_LOG_OUT,
});

const onUpdateUserProfilePicture = (data) => ({
    type: UPDATE_USER_PROFILE_PICTURE,
    data,
});

const onTwoStepJwtTokenReceived = (data) => ({
    type: ON_TWO_STEP_JWT_TOKEN_RECEIVED,
    data,
});

const onUpdateTwoStepAuthentication = (data) => ({
    type: UPDATE_TWO_STEP_AUTHENTICATION,
    data,
});
const onFetchUserGroups = (data) => ({
    type: ADD_USER_GROUPS,
    data,
});
const onChangeActiveUserGroup = (data) => ({
    type: CHANGE_ACTIVE_USER_GROUP,
    data,
});
const changeUserPinStatus = (data) => ({
    type: UPDATE_USSD_STATUS,
    data,
});

const userSignIn = (data, history) => {
    return (dispatch) => {
        dispatch(isUserSigningIN());

        return unAuthenticatedRequest()
            .post(sign_in, qs.stringify(data))
            .then((res) => {
                // save the businessName, firstName, lastName, email, countryCode, phoneNumber to redux
                if (res.data.data.twoStep) {
                    dispatch(onTwoStepJwtTokenReceived(res.data.data));
                    history.push(ROUTES.TWO_FACTOR_LOGIN);
                    return;
                }
                dispatch(onUserSignInSuccess(res.data.data));
                history.push(goToRoute(ROUTES.INITIALIZATION, res.data.user?.businessAccountId));
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
                return dispatch(onUserSignInFailure());
            });
    };
};

const twoFactorSuccess = (data, history) => {
    return (dispatch) => {
        dispatch(onUserSignInSuccess(data));
        history.push(ROUTES.INITIALIZATION);
    };
};

const updateUserProfilePicture = (data) => {
    return (dispatch) => {
        dispatch(onUpdateUserProfilePicture(data));
    };
};

const userLogout = () => {
    return (dispatch) => {
        return dispatch(onUserLogout());
    };
};

const updateTwoStep = (data) => {
    return (dispatch) => {
        dispatch(onUpdateTwoStepAuthentication(data));
    };
};

const onChangePinStatus = (data) => {
    return (dispatch) => {
        dispatch(changeUserPinStatus(data));
    };
};

export const actions = {
    userSignIn,
    userLogout,
    twoFactorSuccess,
    updateUserProfilePicture,
    updateTwoStep,
    onUserSignInSuccess,
    onTwoStepJwtTokenReceived,
    onFetchUserGroups,
    onChangeActiveUserGroup,
    onChangePinStatus,
};
