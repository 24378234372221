import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import success from '../../../../../../../assets/icons/Svg/sucess.svg';
import { COLORS } from '../../../../../../../config/theme';
interface ModalProps {
    visible: boolean;
    onClose: () => void;
    _id: string;
    businessName: string;
    type: string;
    isEdit?: any;
}
const style = () => css`
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .success {
            width: 50px;
            height: 50px;
        }
        .product-line {
            font-weight: 600;
            font-size: 13px;
            color: #333333;
            margin-top: 15px;
        }
        .product-name {
            font-weight: normal;
            font-size: 13px;
            color: #6c7392;
            margin-top: 10px;
        }
        .done-button {
            background: ${(props) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props) => props.theme[COLORS.PRIMARY]};
            box-sizing: border-box;
            border-radius: 5px;
            height: 45px;
            margin-top: 20px;
            font-weight: 500;
            font-size: 15px;
            color: #ffffff;
        }
        .add-product-container {
            flex: 1;
            flex-direction: row;
            justify-content: flex-start;
            display: flex;
            width: 100%;
            margin-top: 30px;
        }
    }
`;

const ModalWrapper = styled.div`
    ${style()}
`;

const SuccessModal: FC<ModalProps> = ({ visible, _id, onClose, isEdit }: ModalProps) => {
    return (
        <Modal visible={visible} centered footer={null} onCancel={onClose}>
            <ModalWrapper className="wrapper">
                <div className="wrapper">
                    <img src={success} alt="Success" className="success" />
                    <span className="product-line">
                        {isEdit ? 'Invoice Updated Successfully' : 'New Invoice Uploaded Successfully'}
                    </span>
                    <span className="product-name">
                        Your invoice #: {_id} was {isEdit ? 'updated' : 'created'} successfully
                    </span>
                    <Button className="done-button" onClick={onClose}>
                        Complete
                    </Button>
                </div>
            </ModalWrapper>
        </Modal>
    );
};

export default SuccessModal;
