import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import HourClock from '../../assets/Images/hour-clock.svg';
import style from './styles';
import { useHistory } from 'react-router';
import { getBusinessId, ROUTES } from '../../router/urls';
import { Constant, getUser, Users } from '../../config';

const SessionExpiredWrapper = styled.div`
    ${style}
`;

const SessionExpired = () => {
    const [visible, setVisible] = useState(false);
    const businessId = getBusinessId();
    const user: Users = getUser();

    const history = useHistory();

    useEffect(() => {
        // This listener allows to disconnect another sessions
        window.addEventListener('storage', (event) => {
            if (event.key === 'gs-session-expired-' + (user == Users.BANK ? '' : businessId)) {
                setVisible(true);
            }
        });

        window.addEventListener(
            'sessionExpired' + (user == Users.BANK ? '' : businessId),
            () => {
                setVisible(true);
            },
            false,
        );
        // cleanup this component
        return () => {
            window.removeEventListener('keydown', () => {
                console.log('Here');
            });
        };
    }, []);

    const onCancel = () => {
        history.push(`${ROUTES.SIGN_IN}?next=${history.location.pathname}`);
    };

    return (
        <Modal visible={visible} width={600} onCancel={onCancel} closable={false} footer={null} centered>
            <SessionExpiredWrapper>
                <img src={HourClock} alt="Alert" width={30} />
                <div className="content-container">
                    <p className="title">Session Expired</p>
                    <span className="description">You have been logged out of your session due to inactivity.</span>
                    <p className="description">Sign in to continue using {Constant.title}.</p>
                </div>
                <div className="action-container">
                    <Button onClick={onCancel} className="gapstack-button">
                        Sign In
                    </Button>
                </div>
            </SessionExpiredWrapper>
        </Modal>
    );
};

export default SessionExpired;
