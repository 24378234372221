import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    display: flex;
    justify-content: center;
    height: 100vh;
    weight: 100vw;

    & > div {
        margin-top: 20.8vh;
        max-width: 520px;
        text-align: center;
        color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        font-size: 13px;
        h2 {
            margin-top: 26px;
            font-weight: bold;
            font-size: 24px;
            line-height: 140%;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        a {
            text-decoration: underline;
        }
    }
`;

export default style();
