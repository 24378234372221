import { getUser, Users } from '../config';

const BusinessRoles = {
    Administrator: 'Administrator',
    View: 'View',
    'Financing Approver': 'Financing Approver',
    'Financing Requestor': 'Financing Requestor',
    'Operations Peers': 'Operations Peers',
    'Financing Peers': 'Financing Peers',
    Analyst: 'Analyst',
    'Operations Requestor': 'Operations Requestor',
    'Operations Approver': 'Operations Approver',
    Developer: 'Developer',
};

const BankRoles = {
    Administrator: 'Administrator',
    View: 'View',
    'Customer care': 'Customer care',
    'Product manager': 'Product manager',
    'Product owner': 'Product owner',
    'Credit operations': 'Credit operations',
    'Credit manager': 'Credit manager',
    'Risk manager': 'Risk manager',
    Analyst: 'Analyst',
    'Guarantees Approver': 'Guarantees Approver',
    'KYC Verifier': 'KYC Verifier',
    'Guarantees Verifier': 'Guarantees Verifier',
    'KYC Approver': 'KYC Approver',
};

export default getUser() === Users.BANK ? BankRoles : BusinessRoles;

// export default {
//     ADMINISTRATOR: 'Administrator',
//     VIEW: 'View',
//     FINANCING_APPROVER: 'Financing Approver',
//     FINANCING_REQUESTOR: 'Financing Requestor',
//     OPERATIONS_PEERS: 'Operations Peers',
//     FINANCING_PEERS: 'Financing Peers',
//     ANALYST: 'Analyst',
//     OPERATIONS_REQUESTOR: 'Operations Requestor',
//     OPERATIONS_APPROVER: 'Operations Approver',
//     DEVELOPER: 'Developer',
// };
