import { business_account_prefix } from '.';

const prefix = business_account_prefix;

export const get_kyc_settings = `${prefix}/kyc-setting`;
export const add_kyc_settings = `${prefix}/kyc-setting`;
export const update_kyc_settings = (id) => `${prefix}/kyc-setting/${id}`;
export const delete_kyc_settings = (id) => `${prefix}/kyc-setting/${id}`;
export const get_kyc_doc_types = `${prefix}/kyc-setting/document-types`;
export const get_kyc_doc_types_2 = `${prefix}/kyc-setting/documents`;

//business
export const get_kyc_docs_for_business = `${prefix}/kyc-setting/business`;

//Terms and conditions
export const add_terms_and_condition_docs = `${prefix}/terms-and-condition`;
export const get_terms_and_condition_docs = `${prefix}/terms-and-condition`;
export const delete_terms_and_condition_docs = (id) => `${prefix}/terms-and-condition/${id}`;
export const update_terms_and_condition_docs = (id) => `${prefix}/terms-and-condition/${id}`;
export const get_terms_for_business = (businessType) => `${prefix}/terms-and-condition?businessType=${businessType}`;
