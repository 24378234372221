import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .invite-body {
        margin: 0;
        padding: 0;
        height: 100vh;
        background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]};
        overflow: hidden;
    }
    .container {
        width: 100%;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        padding: 40px 30px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .heading {
            margin-bottom: 10px;
        }
        .info {
            font-size: 14px;
            margin-bottom: 10px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .line {
            width: 110px;
            height: 3px;
            border-radius: 2px;
            overflow: hidden;
            margin-bottom: 10px;
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        }
        .remember-me {
            display: flex;
            flex-direction: row;
            padding: 0px 0px;
            margin-top: 10px;
            .check {
                margin-bottom: 0px;
                width: 20px;
                flex-flow: nowrap;
                margin-right: 15px;
            }
            .get-email {
                display: flex;
                flex-direction: column;
                flex: 1;
                .email {
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                .unsubs {
                    font-size: 12px;
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                }
                .privacy {
                    font-size: 13px;
                    margin-top: 10px;
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                }
            }
        }
    }
    .form-wrapper {
        padding: 20px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 30px;
        .logo {
            width: 200px;
            margin-bottom: 20px;
        }
        .ant-form {
            width: 100%;
        }
        .select .ant-select-selector .ant-select-selection-item {
            line-height: 43px;
        }
        .select .ant-select-selector .ant-select-selection-placeholder {
            line-height: 43px;
        }
    }
    .camera-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 15px;
        position: relative;
        min-height: 400px;
        .dummy-wrapper {
            width: 200px;
            height: 300px;
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .err-message {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
            margin: 5px 0px;
        }
        .success-message {
            color: ${(props: any) => props.theme[COLORS.SUCCESS]};
            margin: 5px 0px;
        }
        .primary-message {
            color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            margin: 5px 0px;
        }
        .inside-video {
            position: absolute;
        }
    }
`;

export default style();
