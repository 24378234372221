import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = ({ showDropDown }) => css`
    .business-dropdown-container {
        flex: 1;
        flex-direction: column;
        display: ${showDropDown ? 'flex' : 'none'};
        border-radius: 5px;
        position: absolute;
        top: -60px;
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        min-width: 330px;
        min-height: 300px;
        padding: 15px;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        z-index: 10;
        .container {
            display: flex;
            .title-container {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .title {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
            }
            .action-container {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: flex-end;
            }
        }

        .business-container {
            margin-top: 10px;
            .business-item {
                padding: 6px 0px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .name {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                button {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                }
            }
            .active {
                background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
                border-radius: 3px;
                margin: 0px -5px;
                padding: 5px;
                display: flex;
                justify-content: space-between;
                .tick {
                    margin-right: 10px;
                }
            }
        }

        .account-container {
            padding: 4px 0px;
            .title {
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            .user-email {
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: ${(props: any) => props.theme[COLORS.EDIT_BUTTON]};
            }
            button {
                display: flex;
                align-items: center;
                font-size: 13px;
            }
        }

        .link-button {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            justify-content: 'center';
            img {
                margin-right: 10px;
            }
        }

        .terms-privacy {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
    }
`;

export { style };
