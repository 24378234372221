import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = ({ showDropDown }) => css`
    .business-dropdown-container {
        flex: 1;
        flex-direction: column;
        display: ${showDropDown ? 'flex' : 'none'};
        border-radius: 5px;
        position: absolute;
        top: -60px;
        background-color: ${(props) => props.theme[COLORS.POP_UP]};
        min-width: 330px;
        min-height: 150px;
        padding: 10px 10px;
        width: auto;
        box-shadow: ${(props) => props.theme[COLORS.SHADOW]};
        z-index: 10;

        .business-container {
            .business-item {
                height: 40px;
                padding: 4px 5px;
                display: flex;
                align-items: center;
                .name {
                    font-weight: normal;
                    cursor: pointer;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
                }
            }
            .active {
                background: ${(props) => props.theme[COLORS.BACKGROUND]};
                border-radius: 3px;
                padding: 5px 10px;
                display: flex;
                .tick {
                    margin-left: auto;
                }
            }
            .autocomp {
                width: 100%;
                height: 45px;
                line-height: 45px;
                .ant-select-selector {
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 5px;
                }
            }
            .change-business {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background: ${(props) => props.theme[COLORS.DOCUMENT_UPLOADER]};
                padding: 5px 10px;
                border-radius: 5px;
                cursor: pointer;
                width: 150px;
                margin: 10px 0px;
                height: 40px;
                .refresh {
                    width: 15px;
                    height: 16px;
                    color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                }
                span {
                    color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                    font-size: 13px;
                    margin-left: 5px;
                }
            }
        }
    }
`;

export { style };
