import React, { useEffect, useState } from 'react';
import { authenticatedRequest } from '../../../api';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { titleCase } from '../../TitleCase';
import { get_mobile_providers, get_banks } from '../../../apis/business-accounts';

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    hasFeedback?: boolean;
    onChange?: (e, formName, group) => void;
    valueItem?: 'id' | 'name';
    returnType?: 'id' | 'object';
    telcoUniqueAttribute?: string;
}

const { Option, OptGroup } = Select;

const BankAndTelcoWrapper = styled.div`
    ${style}
`;

const SelectBankAndTelco = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    hasFeedback = false,
    onChange,
    valueItem = 'id',
    returnType = 'id',
    telcoUniqueAttribute = 'id',
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [banks, setBanks] = useState<any>([]);
    const [mobileProviders, setMobileProviders] = useState<any>([]);

    const fetchData = () => {
        setLoading(true);
        const banks = authenticatedRequest()
            .get(get_banks)
            .then((res) => res.data);
        const telcoProviders = authenticatedRequest()
            .get(get_mobile_providers)
            .then((res) => res.data);
        Promise.all([banks, telcoProviders])
            .then((res) => {
                setLoading(false);
                res[0] && setBanks(res[0].sort((a, b) => a.name > b.name));
                res[1] && setMobileProviders(res[1]);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onValueChanged = (e) => {
        let group = 'bank';
        let foundIndex = banks.findIndex((bank) => bank[valueItem] === e);
        if (foundIndex < 0) {
            group = 'mobile';
            foundIndex = mobileProviders.findIndex((bank) => bank[valueItem] === e);
        }

        if (returnType === 'id') {
            onChange ? onChange(e, formItemName, group) : null;
        } else {
            onChange ? onChange(banks[foundIndex], formItemName, group) : null;
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <BankAndTelcoWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className="label"
                rules={[{ required, message }]}
                initialValue={initialValue}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    loading={loading}
                    className="form-select select"
                    allowClear
                    showSearch
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    defaultValue={initialValue}
                    optionFilterProp="children"
                    filterOption={(input, option) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={(e) => (onChange ? onValueChanged(e) : null)}
                    data-testid="select-banktelco"
                    placeholder="Select Bank/Telco"
                >
                    <OptGroup label="Telco Providers">
                        {mobileProviders.map((mobileProvider) => (
                            <Option
                                key={mobileProvider[telcoUniqueAttribute]}
                                value={mobileProvider[telcoUniqueAttribute]}
                            >
                                {mobileProvider.name}
                            </Option>
                        ))}
                    </OptGroup>
                    <OptGroup label="Banks">
                        {banks.map((bank) => (
                            <Option key={bank.id} value={bank[valueItem]}>
                                {titleCase(bank.name, ' ')}
                            </Option>
                        ))}
                    </OptGroup>
                </Select>
            </Form.Item>
        </BankAndTelcoWrapper>
    );
};

export default SelectBankAndTelco;
