import { Form, FormInstance, notification } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { authenticatedRequest } from '../../../../api';
import { save_business_details, submit_terms } from '../../../../apis/business-accounts';
import { KYCStatuses } from '../../helpers';
import {
    BusinessDetails,
    ContactInformation,
    CompanyRegistration,
    PhysicalAddress,
    ConfirmDirectors,
    AboutBusiness,
    loaderData,
} from './export';
import { useParams } from 'react-router';
import { getUser, Users } from '../../../../config';
import SuccessModal from '../../../../utils/SuccessModal';
import { CardLoader } from '../../../../utils/Loader';

interface ConfirmDetailsProps {
    form: FormInstance;
    fetchAccountDetails: () => void;
    activeData: any;
    completedStatuses: any;
    setCompletedStatuses: (val: any) => void;
    changeStep: (status: KYCStatuses) => void;
    errorData?: any;
    isTermsRequired: boolean;
    cardLoading: boolean;
}
const ConfirmDetails: FunctionComponent<ConfirmDetailsProps> = ({
    fetchAccountDetails,
    activeData,
    completedStatuses,
    setCompletedStatuses,
    changeStep,
    errorData,
    isTermsRequired,
    cardLoading,
}: ConfirmDetailsProps) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    useEffect(() => {
        fetchAccountDetails();
    }, []);

    const validateForms = async (forms: any[]) => {
        const arr: any[] = [];
        forms.map((form) => {
            arr.push(form?.validateFields());
        });
        return Promise.all(arr)
            .then((res) => {
                res = res.filter((val) => !!val);
                return res.length > 0;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    };
    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };

    const onComplete = () => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_terms, {})
            .then(() => true)
            .catch((err) => catchErr(err));
    };

    const onFormFinish = async (name, { values, forms }) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };

        if (name == 'submitbutton') {
            const submitObj = {};
            const { business, contact, physicalAddress, companyRegistration, directors, submitbutton } = forms;
            const isValidated = await validateForms([
                business,
                contact,
                physicalAddress,
                companyRegistration,
                directors,
                submitbutton,
            ]);
            if (!isValidated) {
                return;
            }
            submitObj['business'] = business?.getFieldsValue();
            submitObj['contact'] = contact?.getFieldsValue();
            submitObj['physicalAddress'] = physicalAddress?.getFieldsValue();
            submitObj['companyRegistration'] = companyRegistration?.getFieldsValue();
            submitObj['directors'] = directors?.getFieldsValue()?.['directors'];
            submitObj['aboutBusiness'] = values?.aboutBusiness;
            if (activeData?.businessType?.name == 'Company') {
                submitObj['phoneNumber'] =
                    submitbutton?.getFieldsValue()?.['countryCode'] + submitbutton?.getFieldsValue()?.['phone'];
            }
            setButtonLoading(true);
            authenticatedRequest(isBank ? businessHeader : {})
                .put(save_business_details, submitObj)
                .then((res) => res.data)
                .then(() => {
                    setButtonLoading(false);
                    if (isTermsRequired) {
                        setCompletedStatuses({
                            ...completedStatuses,
                            [KYCStatuses.CONFIRM_DETAILS]: 'COMPLETE',
                            [KYCStatuses.TERMS_AND_CONDITIONS]: 'ACTIVE',
                        });
                        changeStep(KYCStatuses.TERMS_AND_CONDITIONS);
                    } else {
                        setCompletedStatuses({
                            ...completedStatuses,
                            [KYCStatuses.CONFIRM_DETAILS]: 'COMPLETE',
                            [KYCStatuses.VERIFICATION_PENDING]: 'ACTIVE',
                        });
                        setShowSuccess(true);
                    }
                })
                .catch((err) => {
                    setButtonLoading(false);
                    catchErr(err);
                });
        }
    };
    return (
        <Form.Provider onFormFinish={onFormFinish}>
            {cardLoading ? (
                <>
                    {loaderData.map((cardData, key) => (
                        <CardLoader
                            key={key}
                            rows={cardData?.rows}
                            title={cardData?.title}
                            loading={cardLoading}
                            className={key > 0 ? 'card-margin-top' : undefined}
                        />
                    ))}
                </>
            ) : (
                <>
                    <BusinessDetails data={activeData} errorData={errorData} />
                    <div style={{ height: '20px' }} />
                    <ContactInformation data={activeData} errorData={errorData} />
                    {activeData?.businessType?.name == 'Company' && (
                        <CompanyRegistration data={activeData} errorData={errorData} />
                    )}
                    <PhysicalAddress data={activeData} errorData={errorData} />
                    <ConfirmDirectors data={activeData} businessType={activeData?.businessType?.name || 'Individual'} />
                    <AboutBusiness data={activeData} buttonLoading={buttonLoading} />
                </>
            )}

            {showSuccess && (
                <SuccessModal
                    visible={showSuccess}
                    onCancel={() => setShowSuccess(false)}
                    heading="KYC Completed Successfully"
                    name=""
                    closeText="Complete"
                    onComplete={() => {
                        onComplete();
                        changeStep(KYCStatuses.VERIFICATION_PENDING);
                    }}
                    description="The KYC has been sent to the bank for approval. We will notify you once the approval is complete."
                />
            )}
        </Form.Provider>
    );
};

export default ConfirmDetails;
