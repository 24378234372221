import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';
import { Constant } from '../../../../config';

const style = () => css`
    .sign-up-wrapper {
        min-height: 100vh;
        max-width: 750px;
        margin: auto;
    }
    .logo {
        margin: 60px auto 30px;
        width: ${Constant.logoWidth};
        height: ${Constant.logoWidth};
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        flex: 1;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 40px 0px 10px 0px;
        justify-content: center;

        .welcome {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 37px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .info {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .top-divider {
            width: 88px;
            max-width: 88px;
            margin: 20px 0px 20px 85px;
            height: 3px;
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            overflow: hidden;
            border-radius: 1.5px;
        }
        .ant-form-item {
            margin-bottom: 20px;
        }
        .ant-form-item-label {
            padding-bottom: 0 !important;
        }
        .gapstack-input {
            height: 45px;
        }
        .select {
            width: 100%;
            height: 43px;
            border-radius: 5px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            top: 32px;
            .ant-select-selector {
                width: 100%;
                height: 43px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                border-radius: 5px;
                background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
                .ant-select-selection-item {
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                    line-height: 43px;
                }
            }
        }
        .pass {
            position: relative;
            .eye {
                position: absolute;
                width: 20px;
                height: 15px;
                right: 25px;
                top: 47px;
                cursor: pointer;
                color: ${(props: any) => props.theme[COLORS.ICONS]};
            }
        }
        .remember-me {
            display: flex;
            flex-direction: row;
            padding: 0px 85px 20px 85px;
            .check {
                margin-bottom: 0px;
                width: 20px;
                flex-flow: nowrap;
                margin-right: 15px;
            }
            .get-email {
                display: flex;
                flex-direction: column;
                flex: 1;
                .email {
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                .unsubs {
                    font-size: 12px;
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                }
                .privacy {
                    font-size: 13px;
                    margin-top: 10px;
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                }
            }
        }

        .button-container {
            padding: 0px 85px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 20px 0px;
            .left-button-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .normal {
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                    font-size: 14px;
                }
                .button-sign {
                    color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                    font-weight: 600;
                }
            }
            .gapstack-button {
                margin-left: auto;
                text-shadow: none;
            }
        }
    }
    .button-link {
        border: none;
        outline: none;
        background: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 50px;
        cursor: pointer;
        img {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }
`;

export default style();
