import { Button, Card, Dropdown, notification, Table } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { Edit, Trash2 } from 'react-feather';
import { RiAddFill } from 'react-icons/ri';
import { ModalComponent } from './DocumentModal';
import DeleteAlert from './deleteAlert';
import { authenticatedRequest } from '../../../../api';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
import { delete_terms_and_condition_docs } from '../../../../apis/business-accounts/kyc_settings';

interface DocumentCardProps {
    title: string;
    value: number;
    fetchData: () => void;
    data: any[];
}

const columns = [
    {
        key: 1,
        dataIndex: 'name',
        title: 'Document Title',
    },
    {
        key: 2,
        dataIndex: 'isRequired',
        title: 'Required',
        render: (val) => (val ? 'Yes' : 'No'),
        width: '20%',
    },
    {
        key: 3,
        dataIndex: 'physicalDocumentRequired',
        title: 'Physical Documents Required',
        render: (val) => val ?? '-',
        width: '40%',
    },
];

const DocumentCard: FunctionComponent<DocumentCardProps> = ({ title, value, fetchData, data }: DocumentCardProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>({});
    const [activeId, setActiveId] = useState<any>('');
    const [deleting, setDeleting] = useState<any>('');
    const auth: any = useSelector((state: RootState) => state.auth);

    const onEdit = (val) => {
        setEditData({ ...val });
        setShowModal(true);
    };

    const onDelete = (id) => {
        setDeleting(true);
        authenticatedRequest({
            'x-bank-user-id': auth?.user?.userId,
        })
            .delete(delete_terms_and_condition_docs(id))
            .then((res) => res.data)
            .then(() => {
                setDeleting(false);
                setActiveId('');
                fetchData();
            })
            .catch((error) => {
                setDeleting(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const columsFinal = [
        ...columns,
        {
            key: '5',
            title: 'Action',
            dataIndex: 'action',
            width: 70,
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div className={record?.isBase ? 'status' : 'status-border'}>
                            <Edit size="14" className="edit-icon" onClick={() => onEdit(record)} />
                        </div>
                        {!record?.isBase && (
                            <Dropdown
                                overlay={
                                    <DeleteAlert
                                        onClick={() => onDelete(record?.id)}
                                        onCancel={() => setActiveId('')}
                                        loading={deleting}
                                    />
                                }
                                placement="bottomRight"
                                trigger={['click']}
                                visible={activeId == record?.id}
                                onVisibleChange={(visible) => {
                                    setActiveId(visible ? record?.id : '');
                                }}
                            >
                                <div className="status">
                                    <Trash2 size="14" className="edit-icon del-icon" />
                                </div>
                            </Dropdown>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card
                title={title}
                id={`card-wrapper-${value}`}
                className="card-wrapper margin-top"
                extra={
                    <Button className="action-button" onClick={() => setShowModal(true)}>
                        <RiAddFill className="add-icon" />
                        Add New Document
                    </Button>
                }
            >
                <Table
                    dataSource={data}
                    columns={columsFinal}
                    className="datatable document-modified"
                    pagination={false}
                />
            </Card>
            {showModal && (
                <ModalComponent
                    fetchData={fetchData}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    value={value}
                    label={title}
                    editData={editData}
                    setEditData={setEditData}
                />
            )}
        </>
    );
};
export { DocumentCard };
