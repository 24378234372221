import React, { useState } from 'react';
import { Button, notification, Row } from 'antd';
import { useParams } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-feather';

import { reset_phone_password, verify_phone_otp_from_reset } from '../../../apis/authentication';
import { unAuthenticatedRequest } from '../../../api';
import ContentLayout from '../../Layouts/Content';
import OTPInput from '../../../utils/OTPInput';
import Loader from '../../../utils/Loader';

import success from '../../../assets/icons/Svg/sucess.svg';
import { appIcon } from '../../../config/appIcon';
import refresh from '../../../assets/Images/refresh.svg';
import help from '../../../assets/Images/help.svg';

import style from './styles';
import { replaceUrlParams } from '../../../utils/Random';
import { ROUTES } from '../../../router/urls';

const OtpWrapper = styled.div`
    ${style}
`;

const inputLength = 6;

const VerifyPhoneComponent = () => {
    const location = useLocation();
    const history = useHistory();
    const { userId }: any = useParams();
    const [otpDisabled, setOtpDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [sendingOtp, setSendingOtp] = useState<boolean>(false);

    const phone = (location?.state as any)?.user?.phone;

    const onOtpChanged = (otp) => {
        if (otp.length === inputLength) {
            completePhoneVerification(otp);
        }
    };

    const completePhoneVerification = (otp) => {
        const submitObj = {
            token: otp,
            userId,
            type: 'otp',
        };
        setLoading(true);
        setOtpDisabled(true);
        unAuthenticatedRequest()
            .post(verify_phone_otp_from_reset, submitObj)
            .then((res) => res.data)
            .then(() => {
                setLoading(false);
                setOtpDisabled(false);
                history.push({
                    pathname: replaceUrlParams(ROUTES.RESET_PASSWORD, [
                        { param: ':token', value: otp },
                        { param: ':userId', value: userId },
                    ]),
                    state: {
                        type: 'otp',
                    },
                });
            })
            .catch((err) => {
                setLoading(false);
                setOtpDisabled(false);
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const resendCode = () => {
        setSendingOtp(true);
        unAuthenticatedRequest()
            .post(reset_phone_password, { phone: phone })
            .then((res) => {
                setSendingOtp(false);
                notification.success({
                    description: res.data.message,
                    message: 'Success',
                });
            })
            .catch(() => {
                setSendingOtp(false);
                notification.error({
                    message: 'Error',
                    description: 'Unable to send otp',
                });
            });
    };

    return (
        <ContentLayout>
            <OtpWrapper>
                <div className="confirm-wrapper">
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>
                    <div className="container">
                        {loading ? (
                            <div className="overlay">
                                <Loader logo={false} />
                            </div>
                        ) : null}
                        <Row className="tick-container">
                            <img src={success} alt="Success" className="success" />
                        </Row>
                        <Row style={{ flex: 1, display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                            <h3 className="heading">OTP Sent Successfully</h3>
                        </Row>
                        <Row style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            <p className="sub-heading">
                                We have sent an SMS with your verification code to {phone?.replace(/.(?=.{3,}$)/g, '*')}
                                . Please enter the code below
                            </p>
                        </Row>
                        <Row>
                            <OTPInput
                                autoFocus
                                isNumberInput
                                length={inputLength}
                                className="otpContainer"
                                inputClassName="otpInput"
                                onChangeOTP={(otp) => onOtpChanged(otp)}
                                disabled={otpDisabled}
                            />
                        </Row>
                        <Row className="tick-container">
                            <Button type="link" loading={sendingOtp} className="resend" onClick={resendCode}>
                                <img
                                    src={refresh}
                                    alt="refresh"
                                    style={{ marginRight: 5, position: 'relative', top: -1 }}
                                />
                                Resend Code
                            </Button>
                        </Row>
                    </div>
                </div>
            </OtpWrapper>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </ContentLayout>
    );
};

export default VerifyPhoneComponent;
