import { Card, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
import FormUploads from '../../../../utils/FormUploads';
import { ConditionalRenderDocs } from './components/ConditionalRender';
import style from './styles';
import { DocumentPageType, DocumentType, DocumentNameAndType } from './types';
const BusinessDocumentsWrapper = styled.div`
    ${style}
`;
interface TaxInformationProps {
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType,
        pageType: DocumentPageType,
        fileId: string,
        documentLabel?: string,
    ) => void;
    documents: any;
    errorObj: any;
    docTypes: any[];
    docsArray: any[];
    setUploadingObj: (val: any) => void;
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType?: DocumentPageType,
    ) => void;
    documentErrObj: any;
    activeData?: any;
}

const TaxInformation: FunctionComponent<TaxInformationProps> = ({
    uploadFilesToBusinessAccount,
    documents,
    errorObj,
    docTypes,
    docsArray,
    setUploadingObj,
    handleErrorFromDocsManager,
    documentErrObj,
    activeData,
}: TaxInformationProps) => {
    const getErr = (documentName) => {
        return (
            errorObj[documentName]?.error ||
            documentErrObj[documentName]?.error ||
            documents[documentName]?.reasonForReturn
        );
    };

    const docsObj: any = {};
    docsArray?.map((val) => {
        docsObj[val?.documentType] = val;
    });

    return (
        <BusinessDocumentsWrapper>
            <Card title="Tax Information" className="card-wrapper  card-margin-top">
                <div className="card-description-wrapper">
                    <div className="card-description">Upload the tax documents of the business.</div>
                    <AiOutlineInfoCircle size={16} className="info-icon" />
                </div>
                <Row gutter={20}>
                    <ConditionalRenderDocs shouldRender={docTypes.includes(DocumentNameAndType.PIN_CERTIFICATE)}>
                        <FormUploads
                            label={`Upload PIN Certificate ${
                                docsObj[DocumentNameAndType.PIN_CERTIFICATE]?.isRequired ? '*' : ''
                            }`}
                            formItemName="pin"
                            documentConfig={{
                                documentName: DocumentNameAndType.PIN_CERTIFICATE,
                                documentType: DocumentType.KYC,
                                pageType: DocumentPageType.STANDARD,
                            }}
                            getResponse={(response) => {
                                if (response?.metadata?.id || response?.documentId) {
                                    uploadFilesToBusinessAccount(
                                        response?.metadata?.id || response?.documentId,
                                        response?.metadata?.id ? response?.path : response?.downloadURl,
                                        DocumentNameAndType.PIN_CERTIFICATE,
                                        DocumentPageType.STANDARD,
                                        response?.metadata?.id || response?.fileId,
                                    );
                                } else {
                                    handleErrorFromDocsManager(
                                        response,
                                        DocumentNameAndType.PIN_CERTIFICATE,
                                        DocumentPageType.STANDARD,
                                    );
                                }
                            }}
                            initialValue={documents[DocumentNameAndType.PIN_CERTIFICATE]?.['url']}
                            err={getErr(DocumentNameAndType.PIN_CERTIFICATE)}
                            validateStatus={getErr(DocumentNameAndType.PIN_CERTIFICATE) ? 'error' : 'success'}
                            required={docsObj[DocumentNameAndType.PIN_CERTIFICATE]?.isRequired ?? false}
                            getFileStatus={(status) => {
                                setUploadingObj((previousObj: any) => {
                                    if (status) {
                                        previousObj[DocumentNameAndType.PIN_CERTIFICATE] = true;
                                    } else {
                                        delete previousObj[DocumentNameAndType.PIN_CERTIFICATE];
                                    }
                                    return previousObj;
                                });
                            }}
                            disabled={
                                !getErr(DocumentNameAndType.PIN_CERTIFICATE) &&
                                activeData?.status == BusinessStatuses.OnHold
                            }
                        />
                    </ConditionalRenderDocs>
                </Row>
                <Row gutter={20}>
                    <ConditionalRenderDocs shouldRender={docTypes.includes(DocumentNameAndType.TAX_COMPLIANCE)}>
                        <FormUploads
                            label={`Upload Tax Compliance Certificate ${
                                docsObj[DocumentNameAndType.TAX_COMPLIANCE]?.isRequired ? '*' : ''
                            }`}
                            formItemName="comp"
                            documentConfig={{
                                documentName: DocumentNameAndType.TAX_COMPLIANCE,
                                documentType: DocumentType.KYC,
                                pageType: DocumentPageType.STANDARD,
                            }}
                            getResponse={(response) => {
                                if (response?.metadata?.id || response?.documentId) {
                                    uploadFilesToBusinessAccount(
                                        response?.metadata?.id || response?.documentId,
                                        response?.metadata?.id ? response?.path : response?.downloadURl,
                                        DocumentNameAndType.TAX_COMPLIANCE,
                                        DocumentPageType.STANDARD,
                                        response?.metadata?.id || response?.fileId,
                                    );
                                } else {
                                    handleErrorFromDocsManager(
                                        response,
                                        DocumentNameAndType.TAX_COMPLIANCE,
                                        DocumentPageType.STANDARD,
                                    );
                                }
                            }}
                            initialValue={documents[DocumentNameAndType.TAX_COMPLIANCE]?.['url']}
                            err={getErr(DocumentNameAndType.TAX_COMPLIANCE)}
                            validateStatus={getErr(DocumentNameAndType.TAX_COMPLIANCE) ? 'error' : 'success'}
                            required={docsObj[DocumentNameAndType.TAX_COMPLIANCE]?.isRequired ?? false}
                            getFileStatus={(status) => {
                                setUploadingObj((previousObj: any) => {
                                    if (status) {
                                        previousObj[DocumentNameAndType.TAX_COMPLIANCE] = true;
                                    } else {
                                        delete previousObj[DocumentNameAndType.TAX_COMPLIANCE];
                                    }
                                    return previousObj;
                                });
                            }}
                            disabled={
                                !getErr(DocumentNameAndType.TAX_COMPLIANCE) &&
                                activeData?.status == BusinessStatuses.OnHold
                            }
                        />
                    </ConditionalRenderDocs>
                </Row>
                <Row gutter={20}>
                    <ConditionalRenderDocs shouldRender={docTypes.includes(DocumentNameAndType.MANAGEMENT_ACCOUNTS)}>
                        <FormUploads
                            label={`Management Accounts ${
                                docsObj[DocumentNameAndType.MANAGEMENT_ACCOUNTS]?.isRequired ? '*' : ''
                            }`}
                            formItemName="comp"
                            documentConfig={{
                                documentName: DocumentNameAndType.MANAGEMENT_ACCOUNTS,
                                documentType: DocumentType.KYC,
                                pageType: DocumentPageType.STANDARD,
                            }}
                            getResponse={(response) => {
                                if (response?.metadata?.id || response?.documentId) {
                                    uploadFilesToBusinessAccount(
                                        response?.metadata?.id || response?.documentId,
                                        response?.metadata?.id ? response?.path : response?.downloadURl,
                                        DocumentNameAndType.MANAGEMENT_ACCOUNTS,
                                        DocumentPageType.STANDARD,
                                        response?.metadata?.id || response?.fileId,
                                    );
                                } else {
                                    handleErrorFromDocsManager(
                                        response,
                                        DocumentNameAndType.MANAGEMENT_ACCOUNTS,
                                        DocumentPageType.STANDARD,
                                    );
                                }
                            }}
                            initialValue={documents[DocumentNameAndType.MANAGEMENT_ACCOUNTS]?.['url']}
                            err={getErr(DocumentNameAndType.MANAGEMENT_ACCOUNTS)}
                            validateStatus={getErr(DocumentNameAndType.MANAGEMENT_ACCOUNTS) ? 'error' : 'success'}
                            getFileStatus={(status) => {
                                setUploadingObj((previousObj: any) => {
                                    if (status) {
                                        previousObj[DocumentNameAndType.MANAGEMENT_ACCOUNTS] = true;
                                    } else {
                                        delete previousObj[DocumentNameAndType.MANAGEMENT_ACCOUNTS];
                                    }
                                    return previousObj;
                                });
                            }}
                            required={docsObj[DocumentNameAndType.MANAGEMENT_ACCOUNTS]?.isRequired ?? false}
                            disabled={
                                !getErr(DocumentNameAndType.MANAGEMENT_ACCOUNTS) &&
                                activeData?.status == BusinessStatuses.OnHold
                            }
                        />
                    </ConditionalRenderDocs>
                </Row>
            </Card>
        </BusinessDocumentsWrapper>
    );
};

export default TaxInformation;
