export const IS_FETCHING_BUSINESS_INFORMATION = 'IS_FETCHING_BUSINESS_INFORMATION';
export const FETCH_BUSINESS_INFORMATION_SUCCESS = 'FETCH_BUSINESS_INFORMATION_SUCCESS';
export const FETCH_BUSINESS_INFORMATION_FAILURE = 'FETCH_BUSINESS_INFORMATION_FAILURE';
export const IS_UPDATING_BUSINESS_INFORMATION = 'IS_UPDATING_BUSINESS_INFORMATION';
export const ON_UPDATE_BUSINESS_INFORMATION_SUCCESS = 'ON_UPDATE_BUSINESS_INFORMATION_SUCCESS';
export const ON_UPDATE_BUSINESS_INFORMATION_FAILURE = 'ON_UPDATE_BUSINESS_INFORMATION_FAILURE';
export const IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION =
    'IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION';
export const ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS =
    'ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS';
export const ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE =
    'ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE';
export const IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION = 'IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION';
export const ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS =
    'ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS';
export const ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE =
    'ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE';
export const UPDATE_REGISTRATION_STATUS = 'UPDATE_REGISTRATION_STATUS';
export const ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS = 'ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS';
export const ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE = 'ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE';
export const IS_UPDATING_TERMS_AND_CONDITIONS = 'IS_UPDATING_TERMS_AND_CONDITIONS';
export const IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION =
    'IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION';
export const ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS =
    'ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS';
export const ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE =
    'ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE';
export const IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION = 'IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION';
export const ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS = 'ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS';
export const ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE = 'ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE';
export const IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION = 'IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION';
export const ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS = 'ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS';
export const ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE = 'ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE';
export const UPDATE_WALLET = 'UPDATE_WALLET';
export const IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES = 'IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES';
export const ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS = 'ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS';
export const ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE = 'ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE';
export const IS_FETCHING_INDUSTRIES = 'IS_FETCHING_INDUSTRIES';
export const FETCH_INDUSTRIES_SUCCESS = 'FETCH_INDUSTRIES_SUCCESS';
export const FETCH_INDUSTRIES_FAILURE = 'FETCH_INDUSTRIES_FAILURE';
