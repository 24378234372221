import React, { useState, useRef, useEffect } from 'react';
import { AutoComplete, Button, Collapse, Dropdown, Form, InputNumber, Select, notification, DatePicker } from 'antd';
import filter from '../../assets/Images/filter.svg';
import styled from 'styled-components';
import { style } from './styles';
import { Plus } from 'react-feather';
import { X } from 'react-feather';
import { authenticatedRequest } from '../../api';
import moment from 'moment';

const convertDate = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
};

const TableFilterWrapper = styled.div`
    ${style}
`;

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

interface Props {
    filterOptions: any;
    onApply: (options) => void;
    clear?: () => void;
    filtersLength?: number;
}

function resolve(path, obj = self, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

const AutoCompleteWrapper = (props: any) => {
    const [options, setOptions] = useState<any[]>([]);

    const fetchOptions = (value) => {
        authenticatedRequest()
            .get(props?.api?.(value))
            .then((res) => res.data)
            .then((res) => {
                const dataArr = props?.searchResultItem ? resolve(props?.searchResultItem, res) : res;
                if (Array.isArray(dataArr) && dataArr.length > 0) {
                    setOptions(
                        [...dataArr].map((val) => {
                            return {
                                ...val,
                                value: props?.valueProp ? resolve(props?.valueProp, val) : val?.value,
                                id: props?.idProp ? resolve(props?.idProp, val) : val?.id,
                            };
                        }),
                    );
                } else {
                    setOptions([]);
                }
            })
            .catch((err) => {
                console.log(err);
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.message || 'Oops! An unexpected error occured',
                });
            });
    };
    useEffect(() => {
        fetchOptions('');
    }, []);

    return (
        <Form.Item name={`${props?.type}:${props.dataIndex}`} noStyle>
            <AutoComplete
                allowClear
                showSearch
                options={options}
                optionFilterProp={props?.optionFilterProp}
                className="gapstack-input autocomp"
                onSearch={(value) => fetchOptions(value)}
            />
        </Form.Item>
    );
};

export function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
}

const TableFilter = ({ filterOptions, onApply, clear, filtersLength }: Props) => {
    const [form] = Form.useForm();
    const selectRef = useRef<any>(null);
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    /**
     * Clear
     */
    const onClear = () => {
        // Step 1: Clear the state
        form.resetFields();
        // Step 2: Close
        onClose();
        // Step 3: Trigger reload
        clear?.();
    };

    const onFinish = (values) => {
        const filterByData = {};
        Object.keys(values).forEach((formValue) => {
            // Step 1: Split the value by :
            const splitValues = formValue.split(':');
            // Step 2: Get the first item in the array which is the type
            const type = splitValues[0];
            // Step 3: If type is multiple, add the value to the object without any processing
            if (type === 'multiple' && Array.isArray(values[formValue])) {
                const returnType = splitValues[2] ?? undefined;
                if (values[formValue].join(',') != '' && returnType == 'array') {
                    filterByData[splitValues[1]] = values[formValue];
                } else if (values[formValue].join(',') != '') {
                    filterByData[splitValues[1]] = values[formValue].join(',');
                }
            }
            // Step 4: If type is numberRange, get the last value in the split array, concatenate it with the value and colon then add it to the object
            if (type === 'numberRange' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type === 'single' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type == 'autocomplete' && values[formValue]) {
                filterByData[splitValues[1]] = values[formValue];
            }
            if (type == 'dateRange' && values[formValue]) {
                filterByData[`${splitValues[1]}From`] = convertDate(values[formValue][0]);
                filterByData[`${splitValues[1]}To`] = convertDate(values[formValue][1]);
            }
        });

        onApply(filterByData);
        onClose();
    };

    const onOpen = () => {
        setShowDropDown(true);
    };

    const onClose = () => {
        setShowDropDown(false);
    };

    const Overlay = () => (
        <TableFilterWrapper showDropDown={showDropDown}>
            <Form form={form} onFinish={onFinish}>
                <div className="table-filter-dropdown-container">
                    <div className="container">
                        <div className="action-container">
                            <div className="left">
                                <Button
                                    className="clear-button"
                                    style={{ minWidth: 120, height: 40, color: '#fff' }}
                                    onClick={onClear}
                                >
                                    Clear
                                </Button>
                            </div>
                            <div className="right">
                                <Button
                                    data-testid="filter"
                                    htmlType="submit"
                                    className="gapstack-button"
                                    style={{ minWidth: 120, height: 40 }}
                                >
                                    Filter
                                </Button>
                            </div>
                        </div>
                        <Collapse
                            accordion
                            ghost
                            className="accordion"
                            defaultActiveKey={[0]}
                            expandIconPosition={'right'}
                            destroyInactivePanel
                            style={{ padding: 15 }}
                            expandIcon={({ isActive }) =>
                                !isActive ? (
                                    <Plus size={18} rotate={90} className="plus" />
                                ) : (
                                    <X size={18} rotate={90} />
                                )
                            }
                        >
                            {filterOptions.map((filterOption, index) => (
                                <Panel key={index} header={filterOption.name} forceRender>
                                    <div ref={selectRef} className="option-container">
                                        {filterOption.type === 'multiple' ? (
                                            <Form.Item style={{ minWidth: 300, marginBottom: 0 }}>
                                                <Form.Item
                                                    name={`${filterOption.type}:${filterOption.dataIndex}${
                                                        filterOption.returnType ? `:${filterOption.returnType}` : ''
                                                    }`}
                                                    noStyle
                                                >
                                                    <Select
                                                        allowClear
                                                        options={filterOption.options}
                                                        showArrow={false}
                                                        dropdownStyle={{ minWidth: 200 }}
                                                        className={`select`}
                                                        mode="multiple"
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                        ) : filterOption.type === 'single' ? (
                                            <Form.Item name={`${filterOption.type}:${filterOption.dataIndex}`} noStyle>
                                                <Select
                                                    allowClear
                                                    options={filterOption.options}
                                                    showArrow={false}
                                                    dropdownStyle={{ minWidth: 200 }}
                                                    className={`select single`}
                                                    data-testid="single_type"
                                                />
                                            </Form.Item>
                                        ) : filterOption.type === 'numberRange' ? (
                                            <>
                                                <Form.Item
                                                    style={{ flex: 1, marginBottom: 0 }}
                                                    name={`${filterOption.type}:${filterOption.dataIndex}From`}
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        className="gapstack-input"
                                                        placeholder="From"
                                                    />
                                                </Form.Item>
                                                <span style={{ margin: '0px 10px' }}>To</span>
                                                <Form.Item
                                                    style={{ flex: 1, marginBottom: 0 }}
                                                    name={`${filterOption.type}:${filterOption.dataIndex}To`}
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        className="gapstack-input"
                                                        placeholder="To"
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : filterOption.type === 'autocomplete' ? (
                                            <AutoCompleteWrapper {...filterOption} />
                                        ) : filterOption.type === 'dateRange' ? (
                                            <>
                                                <Form.Item
                                                    style={{ flex: 1, marginBottom: 0 }}
                                                    name={`${filterOption.type}:${filterOption.dataIndex}`}
                                                >
                                                    <RangePicker
                                                        format="YYYY-MM-DD"
                                                        className="gapstack-input date-range-container"
                                                        data-testid="date-range"
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : null}
                                    </div>
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </Form>
        </TableFilterWrapper>
    );

    return (
        <TableFilterWrapper showDropDown={showDropDown}>
            <Dropdown
                overlay={<Overlay />}
                visible={showDropDown}
                trigger={['click']}
                onVisibleChange={(visible) => setShowDropDown(visible)}
                placement="bottomLeft"
            >
                <Button className="filter-button" onClick={onOpen}>
                    <img src={filter} alt={'filter'} />
                    Filter
                    {filtersLength !== 0 ? (
                        <>
                            {/*<Divider type="vertical" style={{ backgroundColor: '#E5E5E5' }} />*/}
                            {filtersLength}
                        </>
                    ) : null}
                </Button>
            </Dropdown>
        </TableFilterWrapper>
    );
};

export default TableFilter;
