import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Divider, Row, Col, Select, notification, Input, Checkbox } from 'antd';
import { authenticatedRequest } from '../../../../api';
import {
    add_kyc_settings,
    get_kyc_doc_types_2,
    update_kyc_settings,
} from '../../../../apis/business-accounts/kyc_settings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
// import { useForm } from 'antd/es/form/Form';
import { ChevronDown, X } from 'react-feather';
import { AddNewDocumentButton } from './components/AddDocumentButton';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const ModalComponent = ({ fetchData, setShowModal, value, label, showModal, editData, setEditData, data }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isSelect, setIsSelect] = useState<boolean>(true);
    const [hasValidityPeriod, setValidityPeriod] = useState<boolean>(false);
    const [docTypes, setDocTypes] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>({});

    const [form] = Form.useForm();
    const auth: any = useSelector((state: RootState) => state.auth);

    const onFinish = (values: any) => {
        let submitObj: any = {
            documentType: values?.documentType,
            hasExpiryPeriod: hasValidityPeriod,
            isRequired: values?.isRequired ?? false,
        };
        if (hasValidityPeriod) {
            submitObj = {
                ...submitObj,
                expiryPeriod: Number(values?.expiryPeriod),
                expiryPeriodUnit: values?.expiryPeriodUnit || editData?.expiryPeriodUnit || 'Months',
            };
        }
        if (values?.previousDocumentRequiredDuration) {
            submitObj = {
                ...submitObj,
                previousDocumentRequiredDuration: Number(values?.previousDocumentRequiredDuration),
                previousDocumentRequiredDurationUnit:
                    values?.previousDocumentRequiredDurationUnit ||
                    editData?.previousDocumentRequiredDurationUnit ||
                    'Months',
            };
        }
        if (values?.uploadRecurrencePeriod) {
            submitObj = {
                ...submitObj,
                uploadRecurrencePeriod: Number(values?.uploadRecurrencePeriod),
                uploadRecurrencePeriodUnit:
                    values?.uploadRecurrencePeriodUnit || editData?.uploadRecurrencePeriodUnit || 'Months',
            };
        }
        setLoading(true);
        let request: any;
        if (editData?.id) {
            delete submitObj?.documentType;
            delete submitObj?.hasExpiryPeriod;
            delete submitObj?.expiryPeriod;
            delete submitObj?.expiryPeriodUnit;
            // delete submitObj?.isRequired;
            request = authenticatedRequest({
                'x-bank-user-id': auth?.user?.userId,
            })
                .patch(update_kyc_settings(editData?.id), submitObj)
                .then((res) => res.data);
        } else {
            request = authenticatedRequest({
                'x-bank-user-id': auth?.user?.userId,
            })
                .post(add_kyc_settings, { ...submitObj, businessTypeId: value?.toString() })
                .then((res) => res.data);
        }
        request
            .then(() => {
                setLoading(false);
                setShowModal(false);
                fetchData();
                setEditData({});
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        Object.values(editData).length > 0 &&
            form.setFieldsValue({
                documentType: editData?.documentType,
                expiryPeriod: editData?.expiryPeriod,
                expiryPeriodUnit: editData?.expiryPeriodUnit,
                uploadRecurrencePeriod: editData?.uploadRecurrencePeriod,
                uploadRecurrencePeriodUnit: editData?.uploadRecurrencePeriodUnit,
                previousDocumentRequiredDuration: editData?.previousDocumentRequiredDuration,
                previousDocumentRequiredDurationUnit: editData?.previousDocumentRequiredDurationUnit,
                isRequired: editData?.isRequired,
            });
        setValidityPeriod(!!editData?.hasExpiryPeriod);
    }, [editData]);

    const fetchDocTypes = () => {
        authenticatedRequest({
            'x-bank-user-id': auth?.user?.userId,
            'x-business-account-id': undefined,
        })
            .get(get_kyc_doc_types_2, { params: { businessType: label } })
            .then((res) => res.data)
            .then((res) => setDocTypes(res))
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const addCustomDocument = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        setIsSelect(false);
        form.setFieldsValue({ documentType: undefined });
    };

    const getOptions = () => {
        let arr: any[] = [];
        const existingDocTypes: any[] = (Array.isArray(data) && data?.map((val) => val?.documentType)) || [];
        const tempDocTypes: any[] = docTypes?.filter((val) => existingDocTypes.indexOf(val?.documentType) < 0);
        arr = tempDocTypes.map((val, index) => {
            return {
                key: index,
                id: index,
                value: val?.documentType,
                previousYearDataRequired: val?.previousYearDataRequired,
                hasExpiryPeriod: val?.hasExpiryPeriod,
            };
        });
        arr.push({
            key: arr.length,
            id: arr.length,
            previousYearDataRequired: false,
            value: <AddNewDocumentButton onClick={addCustomDocument} />,
        });
        return arr;
    };

    useEffect(() => {
        fetchDocTypes();
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <Modal
            title={editData?.id ? 'Update document' : 'Add new Document'}
            visible={showModal}
            onCancel={() => {
                setEditData({});
                setShowModal(false);
            }}
            footer={false}
            getContainer={document.getElementById(`card-wrapper-${value}`)}
            closeIcon={<X className="close-modal" />}
            width="40%"
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish}>
                <Row gutter={20} style={{ alignItems: 'flex-end' }}>
                    <Col span={12}>
                        {isSelect ? (
                            <Form.Item
                                label="Document Type"
                                name="documentType"
                                className="label"
                                rules={[{ required: true, message: 'Select document type' }]}
                            >
                                <Select
                                    className="select"
                                    suffixIcon={<ChevronDown className="select-icon" />}
                                    options={getOptions()}
                                    disabled={!!editData?.id}
                                    onChange={(_, option) => {
                                        setSelectedOption(option);
                                        setValidityPeriod(!!(option as any)?.hasExpiryPeriod);
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            <Form.Item
                                label="Document Type"
                                name="documentType"
                                className="label"
                                rules={[{ required: true, message: 'Select document type' }]}
                            >
                                <Input className="gapstack-input" placeholder="Custom document" />
                            </Form.Item>
                        )}
                    </Col>
                    {!isSelect && (
                        <Col span={12} style={{ height: '25px' }}>
                            <Checkbox
                                value={hasValidityPeriod}
                                disabled={!!editData?.id}
                                onChange={(e: CheckboxChangeEvent) => setValidityPeriod(e.target.checked)}
                            >
                                Has Validity Period
                            </Checkbox>
                        </Col>
                    )}
                </Row>
                {(editData?.hasExpiryPeriod || hasValidityPeriod || selectedOption?.hasExpiryPeriod) && (
                    <>
                        <Row style={{ marginTop: 10 }}>
                            <div className="heading" style={{ borderWidth: 1 }}>
                                Validity
                            </div>
                            <div className="line" />
                        </Row>
                        <Row gutter={20} className="custom-select">
                            <Col span={12}>
                                <Form.Item
                                    label="Validity Period"
                                    name="expiryPeriod"
                                    className="label"
                                    rules={[{ required: true, message: 'Select validity period' }]}
                                >
                                    <Input className="gapstack-input" placeholder="12" disabled={!!editData?.id} />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="custom-col">
                                <Form.Item
                                    label=""
                                    name="expiryPeriodUnit"
                                    className="label inside-number"
                                    rules={[{ required: true, message: 'Select validity period unit' }]}
                                >
                                    <Select
                                        className="select"
                                        disabled={!!editData?.id}
                                        suffixIcon={<ChevronDown className="select-icon" />}
                                        options={['Months'].map((val, index) => {
                                            return { key: index, id: index, value: val };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {(editData?.previousDocumentRequiredDuration || selectedOption?.previousYearDataRequired) && (
                    <>
                        <Row style={{ marginTop: isSelect ? 0 : 10 }}>
                            <div className="heading" style={{ borderWidth: 1 }}>
                                Frequency
                            </div>
                            <div className="line" />
                        </Row>
                        <Row gutter={20} className="custom-select">
                            <Col span={12}>
                                <Form.Item
                                    label="For the last?"
                                    name="previousDocumentRequiredDuration"
                                    className="label"
                                    rules={[{ required: true, message: 'Select validity period' }]}
                                >
                                    <Input className="gapstack-input" placeholder="12" />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="custom-col">
                                <Form.Item
                                    label=""
                                    name="previousDocumentRequiredDurationUnit"
                                    className="label inside-number"
                                    rules={[{ required: true, message: 'Select validity period unit' }]}
                                >
                                    <Select
                                        className="select"
                                        suffixIcon={<ChevronDown className="select-icon" />}
                                        options={['Months'].map((val, index) => {
                                            return { key: index, id: index, value: val };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {(editData?.uploadRecurrencePeriod || selectedOption?.hasExpiryPeriod) && (
                    <>
                        <Row style={{ marginTop: isSelect ? 0 : 10 }}>
                            <div className="heading" style={{ borderWidth: 1 }}>
                                Upload frequency
                            </div>
                            <div className="line" />
                        </Row>
                        <Row gutter={20} className="custom-select">
                            <Col span={12}>
                                <Form.Item
                                    label="Upload Frequency"
                                    name="uploadRecurrencePeriod"
                                    className="label"
                                    rules={[{ required: true, message: 'Select validity period' }]}
                                >
                                    <Input className="gapstack-input" placeholder="12" />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="custom-col">
                                <Form.Item
                                    label=""
                                    name="uploadRecurrencePeriodUnit"
                                    className="label inside-number"
                                    rules={[{ required: true, message: 'Select validity period unit' }]}
                                >
                                    <Select
                                        className="select"
                                        suffixIcon={<ChevronDown className="select-icon" />}
                                        options={['Months'].map((val, index) => {
                                            return { key: index, id: index, value: val };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                <Col span={12} style={{ marginTop: 10 }}>
                    <Form.Item
                        label=""
                        name="isRequired"
                        className="label"
                        valuePropName="checked"
                        rules={[{ required: false, message: 'Check if the document is required' }]}
                    >
                        <Checkbox>Is Required?</Checkbox>
                    </Form.Item>
                </Col>
                <Divider style={{ width: '120%', marginLeft: '-20px' }} />
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" loading={loading} htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};
export { ModalComponent };
