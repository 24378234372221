import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { notification } from 'antd';

import MessageLoading from './message-loading';
import EmailMessages from './messages';

import style from './styles';
import AuthenticatedLayout from '../Layouts/Authenticated';
import { authenticatedRequest } from '../../api';

import { authenticate } from './apis';
import Loader from '../../utils/Loader';

const MessagesWrapper = styled.div`
    ${style}
`;

const Messages = () => {
    const { businessId }: any = useParams();
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);

    const authUser = useSelector((state: any) => state.auth?.user || {});

    const checkAutStatus = useCallback(async () => {
        try {
            const header = {
                'x-business-account-id': businessId,
                'x-user-id': authUser.userId,
            };
            const { data } = await authenticatedRequest(header).get(authenticate);
            setAuthenticated(data.loggedIn);
        } catch (err) {
            const resp = err?.response;
            notification.error({
                message: 'Error',
                description: resp?.data?.message || 'Unexpected Error Occurred',
            });
        } finally {
            setLoading(false);
        }
    }, [businessId, authUser.userId]);

    useEffect(() => {
        checkAutStatus().catch(null);
    }, [checkAutStatus]);

    return (
        <AuthenticatedLayout>
            <MessagesWrapper>
                {loading ? (
                    <div className={'auth-cont'}>
                        <Loader />
                    </div>
                ) : authenticated ? (
                    <EmailMessages />
                ) : (
                    <MessageLoading checkAutStatus={checkAutStatus} />
                )}
            </MessagesWrapper>
        </AuthenticatedLayout>
    );
};

export default Messages;
