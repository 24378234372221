import { Card, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import SelectCounty from '../../../../utils/FormSelects/Counties';
import SelectCountry from '../../../../utils/FormSelects/Countries';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import Loader from '../../../../utils/Loader';
import { create_contact_information, update_contact_information } from '../../../../apis/business-accounts';
import { getErrorsList, mapConfirmData } from '../../helpers/mapData';
import style from './styles';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const BusinessProfileWrapper = styled.div`
    ${style}
`;
interface ContactInformationProps {
    data: any;
    errorData?: any;
}

const ContactInformation: FunctionComponent<ContactInformationProps> = ({
    data,
    errorData,
}: ContactInformationProps) => {
    const [countrySelected, setSelectedCountry] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [form] = useForm();

    const disabled = data?.status == BusinessStatuses?.OnHold;
    const onComplete = () => {
        const values = form.getFieldsValue();
        let request: any;
        const id = values?.id;
        if (id) {
            const ob = { ...values };
            delete ob['id'];
            request = authenticatedRequest().put(update_contact_information(id), ob);
        } else {
            request = authenticatedRequest().post(create_contact_information, values);
        }
        request
            .then((res) => res.data)
            .then((res) => {
                const id = values?.id;
                if (!id) {
                    form.setFieldsValue({ id: res?.id });
                }
            })
            .catch((err) => {
                console.log(err);
                // const resp = err?.response;
                // notification.error({
                //     message: 'Error',
                //     description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                // });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    useEffect(() => {
        data && form.setFieldsValue(mapConfirmData(data)?.['contact']);
        errorData && form.setFields(getErrorsList(errorData?.contact || {}));
        setLoading(false);
    }, [data]);

    return (
        <BusinessProfileWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form name="contact" form={form}>
                    <Card title="Contact Information" className="card-wrapper">
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item name={'id'} hidden />
                                <Form.Item
                                    label="First Name"
                                    name={'firstName'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input your first name!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="John" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Last Name"
                                    name={'lastName'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input your last name!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="Doe" disabled={disabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <PhoneNumber
                                    countryFieldName={'countryCode'}
                                    inputFieldName={'phone'}
                                    readOnly={disabled}
                                />
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'email'}
                                    label="Email"
                                    className="label"
                                    rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
                                >
                                    <Input
                                        className="gapstack-input"
                                        placeholder="john@gapstack.com"
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Address"
                                    name={'address'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input your address!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="P.O Box 777" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Postal Code"
                                    name={'postalCode'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input postal code!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="POA777" disabled={disabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <SelectCountry
                                    formItemLabel="Country"
                                    formItemName={'country'}
                                    required
                                    onChange={() => true}
                                    message="Please select country"
                                    onCountrySelected={(country) => setSelectedCountry(country)}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col span={12}>
                                <SelectCounty
                                    formItemLabel="County"
                                    formItemName={'county'}
                                    required
                                    onChange={() => true}
                                    message="Please select county"
                                    countryId={countrySelected?.country_id}
                                    form={form}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </BusinessProfileWrapper>
    );
};

export default ContactInformation;
