import moment from 'moment';

const getFieldStatus = (fieldName, data): 'error' | 'success' | undefined | 'validating' | 'warning' | '' => {
    if (data === undefined || !Object.keys(data).length) return '';
    const foundItem = Object.keys(data).find((item) => item === fieldName);

    if (foundItem === undefined) return undefined;
    if (!data[foundItem]) return 'error';
    if (data[foundItem]) return 'success';
    return '';
};

const getFieldStatusV2 = (data, setStatus: (status) => void) => {
    const statuses = {} as any;
    Object.keys(data).forEach((item) => {
        if (item.toLowerCase() === 'reason') return;
        const currentStatus = !data[item] ? 'error' : 'success';
        statuses[item] = currentStatus;
    });

    setStatus(statuses);
};

const updateFieldStatusOnChangeFormItem = (
    verificationStatus,
    data,
    value,
    formItemName,
    setStatus: (status) => void,
) => {
    const newVerificationStatus = { ...verificationStatus };
    if (value !== data[formItemName]) {
        newVerificationStatus[formItemName] = '';
    } else {
        newVerificationStatus[formItemName] = 'error';
    }
    setStatus(newVerificationStatus);
};

const showHasFeedback = (status: 'success' | 'error') => {
    if (status === 'success') return true;
    return false;
};

const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
};

function convertToInternationalCurrencySystem(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
        : Math.abs(Number(labelValue));
}

const disableFutureDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().add(1, 'day').startOf('day');
};

const disablePastDate = (current) => {
    return current && current <= moment().subtract(1, 'day').endOf('day');
};

const replaceUrlParams = (url: string, params: Array<{ param: string; value: string }>) => {
    params.forEach((param) => {
        url = url.replace(param.param, param.value);
    });

    return url;
};

// American Numbering System
const th = ['', 'thousand', 'million', 'billion', 'trillion'];
// uncomment this line for English Number System
// var th = ['','thousand','million', 'milliard','billion'];

const dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const tn = [
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
];
const tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const toWords = (s) => {
    s = s?.toString();
    s = s?.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    let x = s?.indexOf('.');
    if (x == -1) x = s.length;
    if (x > 15) return 'too big';
    const n = s?.split('');
    let str = '';
    let sk = 0;
    for (let i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
            if (n[i] == '1') {
                str += tn[Number(n[i + 1])] + ' ';
                i++;
                sk = 1;
            } else if (n[i] != 0) {
                str += tw[n[i] - 2] + ' ';
                sk = 1;
            }
        } else if (n[i] != 0) {
            str += dg[n[i]] + ' ';
            if ((x - i) % 3 == 0) str += 'hundred ';
            sk = 1;
        }
        if ((x - i) % 3 == 1) {
            if (sk) str += th[(x - i - 1) / 3] + ' ';
            sk = 0;
        }
    }
    return str;
    // if (x != s.length) {
    //     let y = s.length;
    //     str += 'point ';
    //     // for (var i=x+1; str.replace(/\s+/g,' '));
    // }
};

export {
    getFieldStatus,
    getFieldStatusV2,
    updateFieldStatusOnChangeFormItem,
    showHasFeedback,
    hex2rgba,
    convertToInternationalCurrencySystem,
    disableFutureDate,
    disablePastDate,
    replaceUrlParams,
    toWords,
};
