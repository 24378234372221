import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';
// import Quotations from './table';
// import CreateQuotationStepOne from './add-new/create-quotation-step-1';
// import CreateQuotationStepTwo from './add-new/create-quotation-step-2';
// import ViewQuotation from './view-quotation/components';
// import EditQuotation from './edit';

const QuotationsComponent = (props: any) => (
    <AuthenticatedLayout>
        <Quotations {...props} />
    </AuthenticatedLayout>
);
const Quotations = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Quotations" */),
    loading() {
        return <Loader />;
    },
});
const CreateQuotationStepOneComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateQuotationStepOne {...props} />
    </AuthenticatedLayout>
);
const AddNewQuotationComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddNewQuotations {...props} />
    </AuthenticatedLayout>
);
const AddNewQuotations = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});
const CreateQuotationStepOne = Loadable({
    loader: () => import('./add-new/create-quotation-step-1' /* webpackChunkName: "Quotations" */),
    loading() {
        return <Loader />;
    },
});
const CreateQuotationStepTwoComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateQuotationStepTwo {...props} />
    </AuthenticatedLayout>
);
const CreateQuotationStepTwo = Loadable({
    loader: () => import('./add-new/create-quotation-step-2' /* webpackChunkName: "Quotations" */),
    loading() {
        return <Loader />;
    },
});

const UploadQuotationComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <UploadQuotation {...props} />
    </AuthenticatedLayout>
);
const UploadQuotation = Loadable({
    loader: () => import('./add-new/upload-quotations' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});
const BulkUploadQuotationComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <BulkUploadQuotations {...props} />
    </AuthenticatedLayout>
);
const BulkUploadQuotations = Loadable({
    loader: () => import('./add-new/upload-quotations/bulk-upload-table' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

const ViewQuotationComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewQuotation {...props} />
    </AuthenticatedLayout>
);
const ViewQuotation = Loadable({
    loader: () => import('./view-quotation/components' /* webpackChunkName: "Quotations" */),
    loading() {
        return <Loader />;
    },
});
const EditQuotationComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <EditQuotation {...props} />
    </AuthenticatedLayout>
);

const EditQuotation = Loadable({
    loader: () => import('./edit' /* webpackChunkName: "Quotations" */),
    loading() {
        return <Loader />;
    },
});

export {
    QuotationsComponent,
    CreateQuotationStepOneComponent,
    CreateQuotationStepTwoComponent,
    ViewQuotationComponent,
    EditQuotationComponent,
    Quotations,
    AddNewQuotationComponent,
    UploadQuotationComponent,
    BulkUploadQuotationComponent,
};
