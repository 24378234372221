import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .card-wrapper {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-head {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-card-body {
            border-radius: 5px;
        }
        .card-description-wrapper {
            display: flex;
            flecx-direction: row;
            align-items: center;
            margin-bottom: 20px;
            .card-description {
                font-size: 13px;
                line-height: 20px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            .info-icon {
                color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
                margin-left: 6px;
            }
        }
        .main-label {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-bottom: 6px;
        }
        .heading-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .ant-card-extra {
            margin-left: -10px;
            width: 120px;
        }
        .add-button {
            border: none;
            outline: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 0px;
            box-shadow: none;
            background: transparent;
            padding: 0;
            margin-left: auto;
            .plus-circle {
                width: 15px;
                height: 15px;
            }
            .add-tier {
                padding-left: 8px;
                font-size: 13px;
                font-weight: 400;
                color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
            }
        }
    }
    .section-err-message {
        font-size: 13px;
        font-weight: 400;
        color: ${(props) => props.theme[COLORS.DECLINED]};
        margin-bottom: 10px;
    }
    .card-margin-top {
        margin-top: 20px;
    }

    .delete-icon {
        width: 16px;
        height: 16px;
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
        cursor: pointer;
        margin-left: 20px;
    }
    .director-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .details-wrapper {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex: 1;
            .name {
                font-size: 13px;
            }
            .email {
                font-size: 12px;
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            }
        }
        .more-menu {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .more-menu-icon {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                cursor: pointer;
                height: 16px;
            }
        }
    }
    .instructions-list {
        margin-top: 0;
        margin-bottom: 20px;
        .card-description {
            font-size: 13px;
            line-height: 20px;
            font-weight: 400;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
    }
    .gapstack-button {
        margin: 0px 0px 15px;
    }
    .camera-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        margin-bottom: 15px;
        position: relative;
        min-height: 400px;
        .dummy-wrapper {
            width: 200px;
            height: 300px;
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .err-message {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
            margin: 5px 0px;
        }
        .success-message {
            color: ${(props: any) => props.theme[COLORS.SUCCESS]};
            margin: 5px 0px;
        }
        .primary-message {
            color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            margin: 5px 0px;
        }
        .inside-video {
            position: absolute;
        }
    }

    .select {
        width: 100%;
        margin-bottom: 0px !important;
        height: 45px;
        margin-right: 15px;
        .ant-select-selector {
            height: 45px !important;
            background: #fafafa !important;
            border-radius: 5px !important;
            border: 1px solid ${(props) => props.theme[COLORS.BORDER]};
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
        .ant-select-arrow {
            top: 40% !important;
        }
        .select-icon {
            color: ${(props) => props.theme[COLORS.ICONS]};
            width: 15px;
        }
    }
`;

export default style();
