import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import PurchaseInvoiceTable from './table';
// import AddPurchaseInvoice from './add-new';
// import BulkUploadPurchaseInvoice from './add-new/upload-table';
// import PurchaseInvoiceView from './view-invoice/components';
// import UploadedInvoiceView from './uploaded-invoice';
// import ValidatePurchaseInvoice from './components/validate';

const PurchaseInvoiceTableComponent = (props: any) => (
    <AuthenticatedLayout>
        <PurchaseInvoiceTable {...props} />
    </AuthenticatedLayout>
);
const PurchaseInvoiceTable = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});
const AddPurchaseInvoiceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddPurchaseInvoice {...props} />
    </AuthenticatedLayout>
);
const AddPurchaseInvoice = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});

const BulkUploadPurchaseInvoice = Loadable({
    loader: () => import('./add-new/upload-table' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});

const ViewPurchaseInvoiceComponent = (props: any) => (
    <AuthenticatedLayout>
        <PurchaseInvoiceView {...props} />
    </AuthenticatedLayout>
);
const PurchaseInvoiceView = Loadable({
    loader: () => import('./view-invoice/components' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});

const UploadedInvoiceViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <UploadedInvoiceView {...props} />
    </AuthenticatedLayout>
);
const UploadedInvoiceView = Loadable({
    loader: () => import('./uploaded-invoice' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});

const ValidatePurchaseInvoice = Loadable({
    loader: () => import('./components/validate' /* webpackChunkName: "Received-Invoices" */),
    loading() {
        return <Loader />;
    },
});

export {
    PurchaseInvoiceTableComponent,
    ViewPurchaseInvoiceComponent,
    AddPurchaseInvoiceComponent,
    BulkUploadPurchaseInvoice,
    UploadedInvoiceViewComponent,
    ValidatePurchaseInvoice,
    PurchaseInvoiceTable,
};
