import AcceptBusinessInvitation from '../components/authentication/accept-business-invite/loadable';
import AddAccount from '../components/authentication/add-account';
import BankAccountConfirmation from '../components/authentication/bank-account-confirmation';
import BusinessConfirmation from '../components/authentication/business-confirmation/loadable';
import ForgotPassword from '../components/authentication/forgot-password/loadable';
import SignIn from '../components/authentication/sign-in/loadable';
import SignUp from '../components/authentication/sign-up/loadable';
import TwoFactorLogin from '../components/authentication/two-factor-login/loadable';
import VerifyDocuments from '../components/authentication/verify-documents';
import { CompleteProfileWithLayout } from '../components/business-accounts';
import LandingPage from '../components/landing-page/loadable';
import { RoutesType } from './routes';
// import { CompleteBusinessAccount } from '../components/business-accounts';
import {
    BusinessView,
    BusinessViewComponent,
    OnboardBusinessComponent,
    VerifyBusiness,
} from '../bank-components/business';
import ResetPassword from '../components/authentication/reset-password/loadable';
import VerifyEmail from '../components/authentication/verify-email/loadable';
import {
    AccountStatementComponent,
    AddNewAccountComponent,
    ConnectedAccount,
    ConnectedAccountComponent,
    EditAccountComponent,
    MakeAccountPaymentComponent,
    ReconciledTransactionsComponent,
    ReconcileTransactionComponent,
    ViewAccountTransactionComponent,
    ViewConnectedAccountComponent,
} from '../components/connected-accounts';
import Dashboard from '../components/dashboard/loadable';
import { Ecosystem, EcosystemComponent, SynchronizeData, ViewConnectionComponent } from '../components/ecosystem';
import {
    ApplicationSummaryComponent,
    DiscountInvoicesComponent,
    FinanceInvoicingSalesComponent,
    FundFromSalesView,
    FundFromSalesViewComponent,
    GetFinancingSalesComponent,
    ViewFinancingOrderComponent,
    WorkingCapitalSalesComponent,
} from '../components/fund-from-sales';
import {
    Distributor_EcosystemComponent,
    FinanceInvoicingComponent,
    FundMyPurchasesView,
    FundMyPurchasesViewComponent,
    GetFinancingComponent,
    ViewFinancingRequestComponent,
    WorkingCapitalComponent,
} from '../components/fund-my-purchase';
import Initialization from '../components/initialization/loadable';
import {
    AddNewProductComponent,
    ConfirmBulkUploadProducts,
    EditProductComponent,
    Products,
    ProductsComponent,
    ViewProductComponent,
} from '../components/products';
import Profile from '../components/profile';
import {
    AddPurchaseInvoiceComponent,
    BulkUploadPurchaseInvoice,
    PurchaseInvoiceTable,
    PurchaseInvoiceTableComponent,
    UploadedInvoiceViewComponent,
    ValidatePurchaseInvoice,
    ViewPurchaseInvoiceComponent,
} from '../components/purchases/invoices';
import {
    AddPurchaseOrderComponent,
    BulkPurchaseOrder,
    CreatePurchaseOrderComponent,
    CreatePurchaseOrderStep2Component,
    EditPurchaseOrderComponent,
    PurchaseOrderTable,
    PurchaseOrderTableComponent,
    PurchaseOrderViewComponent,
    UploadPurchaseOrderComponent,
} from '../components/purchases/purchase-order';
import {
    AddPurchaseSupplierComponent,
    InvitePurchaseSupplierComponent,
    InviteStepTwoComponent,
    OnboardComponentComponent,
    PendingSuppliersComponent,
    PurchaseSupplierTable,
    PurchaseSupplierTableComponent,
    ViewPurchaseComponent,
} from '../components/purchases/supplier-central';
import {
    AddSalesBuyerComponent,
    BuyerCentral,
    BuyerCentralComponent,
    InviteSalesBuyerComponent,
    InviteStepTwoBuyerComponent,
    OnboardComponentBuyerComponent,
    PendingBuyersComponent,
    ViewBuyerComponent,
} from '../components/sales/buyer-central';
import {
    AddNewInvoiceComponent,
    BulkUploadOrderComponent,
    CreateCommercialInvoiceStepOneComponent,
    CreateCommercialInvoiceStepTwoComponent,
    CreateProformaInvoiceStepOneComponent,
    EditCommercialInvoiceComponent,
    EditProformaInvoiceComponent,
    SalesInvoices,
    SalesInvoicesComponent,
    UploadInvoiceOrderComponent,
    ViewCommercialInvoiceComponent,
} from '../components/sales/invoices';
import {
    AddSalesOrderComponent,
    BulkSaleOrderTable,
    SalesOrder,
    SalesOrderComponent,
    UploadPurchaseOrderViewComponent,
    ValidatePurchaseOrder,
    ViewPurchaseOrderComponent,
} from '../components/sales/sales-order';
import {
    AccountSettingsComponent,
    BrandSettingsComponent,
    BusinessInformationComponent,
    ConnectedApplicationsComponent,
    CreditReportComponent,
    FinancialInformationComponent,
    SecurityHistoryComponent,
    Settings,
    SettingsComponent,
    TeamSettingsComponent,
    TermsAndConditionsComponent,
} from '../components/settings';

// payments
import {
    MakePaymentsComponent,
    PayBillComponent,
    PayInvoiceComponent,
    Payments,
    PaymentsComponent,
    ReceivePaymentsComponent,
    RepayLoanComponent,
    ViewPaymentsComponent,
} from '../components/payments';

import { Developer } from '../components/developer';
import {
    Bonds,
    BondsComponent,
    BondsFinancingComponent,
    CreateNewGuaranteeComponent,
    EditGuaranteeBondComponent,
    NewGuaranteeComponent,
    ViewGuaranteeBondComponent,
} from '../components/guarantee-bonds';
import Insights from '../components/insights';
import {
    CreateReceivedQuotationComponent,
    ReceivedQuotations,
    ReceivedQuotationsComponent,
    ValidateReceivedQuotation,
    ViewQuotationsComponent,
    ViewUploadedReceivedQuotationComponent,
} from '../components/purchases/quotations';
import {
    AddNewQuotationComponent,
    BulkUploadQuotationComponent,
    CreateQuotationStepOneComponent,
    CreateQuotationStepTwoComponent,
    EditQuotationComponent,
    Quotations,
    QuotationsComponent,
    UploadQuotationComponent,
    ViewQuotationComponent,
} from '../components/sales/quotations';
import Blacklist from '../components/settings/fraud-central/blacklist';

import Unauthorized from '../components/authentication/unauthorized';
import Messages from '../components/messages';
import Notifications from '../components/notifications';
import { TrackerTable } from '../components/tracker';

// import trade
import {
    AddNewImportOrderComponent,
    // ImportTadeLayout,
    AddProformaInvoice,
    BulkImportOrder,
    CloseLetterOfCredit,
    EditLetterOfCredit,
    ImportServicesComponent,
    LetterOfCredit,
    MakePaymentsFinanceComponent,
    ReceivedBillsComponent,
    SecureImportsComponent,
    ShipmentTrackingComponent,
    TrackOrder,
    TradeFinance,
    TradeFinanceComponent,
    UploadBulkImportOrderComponent,
    ViewImportOrderComponent,
    ViewLetterOfCreditComponent,
    ViewReceivedBillsComponent,
} from '../components/import-trade';
import {
    EscrowComponent,
    NewEscrow,
    TrackEscrowComponent,
    ViewEscrowComponent,
} from '../components/import-trade/escrow/loadable';
import {
    FinanceImportOrdersComponent,
    GetTradeFinancingComponent,
    ImportLoanTableComponent,
    ImportLoanViewComponent,
    PostShipmentFinanceComponent,
} from '../components/import-trade/import-loan';

// export trade
// import ExportTadeLayout from '../components/export-trade/export-trade-layout';
import {
    CollectionDiscounting,
    ExportLoanTableComponent,
    ExportLoanViewComponent,
    FinanceExportOrders,
    GetExportTradeFinancing,
    GetWorkingCapital,
    LCDiscounting,
} from '../components/export-trade/export-loan';
// import { AddExportProformaInvoice } from '../components/export-trade/trade-finance/letter-of-credit/components';

import {
    ExportServicesComponent,
    ExportShipmentTrackingComponent,
    ExportTradeFinance,
    ExportTradeFinanceComponent,
    RegisterBillsOnCollection,
    TrackExportOrder,
    ViewExportOrderComponent,
} from '../components/export-trade';
// import SecureExports from '../components/export-trade/secure-imports';
import AcceptInvitation from '../bank-components/authentication/accept-invite';
import CompleteRegistration from '../bank-components/authentication/complete-registration';
import { VerifyBonds } from '../bank-components/guarantee-bonds';
import { ApproveLetterOfCredit } from '../bank-components/import-trade';
import {
    BankRegisterBillsOnCollection,
    BillsOnCollectionComponent,
    RegisterBillsOnCollectionComponent,
    ViewBillsOnCollectionComponent,
} from '../bank-components/register-bills';
import { BankSettings, BankSettingsComponent } from '../bank-components/settings';
import { ValidatedBuyers, ViewValidatedBuyers } from '../bank-components/settings/buyer';
import { KYCSettings } from '../bank-components/settings/kyc-documents';
import {
    AddAppliedChargeComponent,
    AddNewTemplateComponent,
    AddProductChargeComponent,
    AppliedChargesComponent,
    ChargesFinancingProductsComponent,
    CollectionStrategiesComponent,
    CreditLimitsComponent,
    CreditLimitUploadComponent,
    GuaranteeSettingsComponent,
    ViewAppliedChargesComponent,
    ViewChargesAndFinancingComponent,
    ViewCollectionStrategiesComponent,
    ViewTemplateComponent,
} from '../bank-components/settings/product-settings';
import { BankAccountsSettings } from '../bank-components/settings/product-settings/accounts';
import AddNewStrategy from '../bank-components/settings/product-settings/collection-strategies/add-new';
import { SupplierGroups, ValidatedSuppliers, ViewValidatedSuppliers } from '../bank-components/settings/supplier';
import {
    AddMemberComponent,
    AddUserGroupsComponent,
    BankTeamSettingsComponent,
    UploadUserGroupsComponent,
    UserGroupsComponent,
    ViewUserGroupsComponent,
} from '../bank-components/settings/team-security-settings';
import AcceptJoinRequest from '../components/authentication/accept-join-business-request/loadable';
import InviteDirectors from '../components/business-accounts/invite-directors';
import IncompleteProfileDashboard from '../components/dashboard';
import {
    ExportEscrowComponent,
    TrackExportEscrowComponent,
    ViewExportEscrowComponent,
} from '../components/export-trade/escrow/loadable';
import MessagesAuth from '../components/messages/components/auth';

// Custom Bank
import {
    FundFromSalesBankComponent,
    FundMyPurchasesBankComponent,
    ValidationRequestsViewComponent,
    ValidationRequestsViewSalesComponent,
} from '../bank-components/fund-my-purchase';
import { TermsAndConditionDocs } from '../bank-components/settings/terms-and-conditions';
import BotSignIn from '../components/authentication/bot-sign-in';
import VerifyPhone from '../components/authentication/verify-phone';
import SetUpPinComponent from '../components/business-accounts/Components/SetUpPin';
import VerifyMandate from '../components/business-accounts/helpers/verifyMandateRequest';
import {
    BusinessForgotPassword,
    BusinessSignIn,
    BusinessSignUp,
    BusinessVerifyEmail,
    NewGuaranteeBond,
    NewGuaranteeBondSubmit,
} from '../components/custom-bank';
import ExternalVerifyBidBond from '../components/custom-bank/guarantee-bond/verify';
import VerifyBondResults from '../components/custom-bank/guarantee-bond/verify/results';
import { EditSingleUploadedInvoice } from '../components/sales/invoices/edit-invoice/edit-upload';
import ValidateDocument from '../utils/Document/ValidateDocument';

// Accounts
import { AccountsTableViewComponent } from '../components/accounts';
const COMPONENTS: { [key in RoutesType]: any } = {
    //auths
    SIGN_IN: SignIn,
    BOT_SIGN_IN: BotSignIn,
    VALIDATE_DOCUMENT: ValidateDocument,
    SIGN_UP: SignUp,
    NEW_USER_SIGN_UP: SignUp,
    FORGOT_PASSWORD: ForgotPassword,
    CONFIRM_USER: BusinessConfirmation,
    ADD_ACCOUNT: AddAccount,
    CONFIRM_ACCOUNT: BankAccountConfirmation,
    VERIFY_DOC: VerifyDocuments,
    ACTIVATE_ACCOUNT: VerifyEmail,
    RESET_PASSWORD: ResetPassword,
    TWO_FACTOR_LOGIN: TwoFactorLogin,
    ACCEPT_BUSINESS_INVITATION: AcceptBusinessInvitation,
    INITIALISE_ANOTHER_ACCOUNT: Initialization,
    ACCEPT_COLLABORATION_REQUEST: AcceptJoinRequest,
    INVITE_DIRECTORS: InviteDirectors,
    VERIFY_MANDATE: VerifyMandate,
    LANDING_PAGE: LandingPage,
    EXTERNAL_VERIFY_BOND: ExternalVerifyBidBond,
    EXTERNAL_VERIFY_BOND_RESULTS: VerifyBondResults,
    COMPLETE_SIGN_UP: ResetPassword,
    VERIFY_PHONE: VerifyPhone,
    SET_UP_USSD_PIN: SetUpPinComponent,

    // Custom Bank
    CUSTOM_BANK_SIGN_IN: BusinessSignIn,
    CUSTOM_BANK_SIGN_UP: BusinessSignUp,
    CUSTOM_BANK_ACTIVATE_ACCOUNT: BusinessVerifyEmail,
    CUSTOM_BANK_FORGOT_PASSWORD: BusinessForgotPassword,

    //Bank -auths
    VERIFY_ACCOUNT: AcceptInvitation,
    COMPLETE_REGISTRATION: CompleteRegistration,

    //business account and business settings
    SETTINGS: SettingsComponent,
    TEAM_SETTINGS: TeamSettingsComponent,
    SECURITY_HISTORY: SecurityHistoryComponent,
    CONNECTED_APPLICATIONS: ConnectedApplicationsComponent,
    ACCOUNT_SETTINGS: AccountSettingsComponent,
    BUSINESS_INFORMATION: BusinessInformationComponent,
    FINANCIAL_INFORMATION: FinancialInformationComponent,
    CREDIT_REPORT: CreditReportComponent,
    TERMS_CONDITIONS: TermsAndConditionsComponent,
    BRAND_SETTINGS: BrandSettingsComponent,
    COMPLETE_KYC: CompleteProfileWithLayout,

    //Bank- Business accounts and settings
    BUSINESS: BusinessViewComponent,
    BUSINESS_ONBOARD: OnboardBusinessComponent,
    BUSINESS_ACTIVATE_ACCOUNT: VerifyEmail,
    BUSINESS_COMPLETE_KYC: CompleteProfileWithLayout,
    VERIFY_BUSINESS_ACCOUNT: VerifyBusiness,
    BANK_SETTINGS: BankSettingsComponent,
    TEAMS: BankTeamSettingsComponent,
    USER_GROUPS: UserGroupsComponent,
    CREATE_USER_GROUPS: AddUserGroupsComponent,
    UPLOAD_USER_GROUPS: UploadUserGroupsComponent,
    VIEW_USER_GROUPS: ViewUserGroupsComponent,
    ADD_MEMBER: AddMemberComponent,
    COLLECTION_ACCOUNT_SETTINGS: BankAccountsSettings,
    KYC_SETTINGS: KYCSettings,
    TERMS_AND_CONDITIONS_DOCS: TermsAndConditionDocs,

    VALIDATED_BUYERS: ValidatedBuyers,
    VIEW_VALIDATED_BUYER: ViewValidatedBuyers,

    VALIDATED_SUPPLIERS: ValidatedSuppliers,
    VIEW_VALIDATED_SUPPLIER: ViewValidatedSuppliers,
    SUPPLIER_GROUPS: SupplierGroups,

    COLLECTION_STRATEGY: CollectionStrategiesComponent,
    ADD_STRATEGY: AddNewStrategy,
    VIEW_COLLECTION_STRATEGY: ViewCollectionStrategiesComponent,
    CREDIT_LIMITS: CreditLimitsComponent,
    ADD_CREDIT_UPLOAD: CreditLimitUploadComponent,
    CHARGES_FINANCING_PRODUCTS: ChargesFinancingProductsComponent,
    GUARANTEE_SETTINGS: GuaranteeSettingsComponent,
    APPLIED_CHARGES: AppliedChargesComponent,
    ADD_APPLIED_CHARGES: AddAppliedChargeComponent,
    VIEW_APPLIED_CHARGES: ViewAppliedChargesComponent,
    ADD_NEW_FINANCE_PRODUCT: AddProductChargeComponent,
    VIEW_FINANCING_PRODUCTS: ViewChargesAndFinancingComponent,

    UNAUTHORIZED: Unauthorized,
    MESSAGES: Messages,
    MESSAGES_AUTH: MessagesAuth,
    TRACKER: TrackerTable,
    NOTIFICATIONS: Notifications,

    //products
    PRODUCTS: ProductsComponent,
    VIEW_PRODUCT: ViewProductComponent,
    EDIT_PRODUCT: EditProductComponent,
    ADD_NEW_PRODUCT: AddNewProductComponent,
    CONFIRM_BULK_UPLOAD_PRODUCTS: ConfirmBulkUploadProducts,

    //dashboard
    INITIALIZATION: Initialization,
    INITIALIZATION_BANK: Initialization,
    DASHBOARD: IncompleteProfileDashboard,
    DASHBOARD_BANK: IncompleteProfileDashboard,

    //purchases
    SUPPLIER_CENTRAL: PurchaseSupplierTableComponent,
    VIEW_SUPPLIER: ViewPurchaseComponent,
    ADD_PURCHASE_SUPPLIER: AddPurchaseSupplierComponent,
    INVITE_PURCHASE_SUPPLIER: InvitePurchaseSupplierComponent,
    INVITE_BULK_STEP_TWO: InviteStepTwoComponent,
    PENDING_SUPPLIERS: PendingSuppliersComponent, // TODO Remove
    ONBOARD_SUPPLIERS: OnboardComponentComponent,

    //purchase orders
    PURCHASE_ORDER: PurchaseOrderTableComponent,
    VIEW_PURCHASE_ORDER: PurchaseOrderViewComponent,
    VIEW_UPLOADED_PURCHASE_ORDER: UploadPurchaseOrderViewComponent,
    ADD_PURCHASE_ORDER: AddPurchaseOrderComponent,
    CREATE_PURCHASE_ORDER: CreatePurchaseOrderComponent,
    UPLOAD_PURCHASE_ORDER: UploadPurchaseOrderComponent,
    CREATE_PURCHASE_ORDER_STEP_2: CreatePurchaseOrderStep2Component,
    BULK_PURCHASE_ORDER: BulkPurchaseOrder,
    EDIT_PURCHASE_ORDER: EditPurchaseOrderComponent,
    COPY_PURCHASE_ORDER: EditPurchaseOrderComponent,
    CREATE_PURCHASE_ORDER_FROM_QUOTATION: EditPurchaseOrderComponent,
    UPLOAD_RECEIVED_QUOTATION: CreateReceivedQuotationComponent,

    //purchase invoices
    INVOICES: PurchaseInvoiceTableComponent,
    VIEW_INVOICE: ViewPurchaseInvoiceComponent,
    VIEW_UPLOADED_RECEIVED_INVOICE: UploadedInvoiceViewComponent,
    ADD_INVOICE: AddPurchaseInvoiceComponent,
    BULK_PURCHASE_INVOICES_TABLE: BulkUploadPurchaseInvoice,
    VALIDATE_INVOICE: ValidatePurchaseInvoice,

    // Received Quotations
    RECEIVED_QUOTATIONS: ReceivedQuotationsComponent,
    VIEW_RECEIVED_QUOTATION: ViewQuotationsComponent,
    VIEW_UPLOADED_RECEIVED_QUOTATION: ViewUploadedReceivedQuotationComponent,
    VALIDATE_RECEIVED_QUOTATION: ValidateReceivedQuotation,

    //buyer
    BUYER_CENTRAL: BuyerCentralComponent,
    VIEW_BUYER: ViewBuyerComponent,
    ADD_SALES_BUYER: AddSalesBuyerComponent,
    INVITE_SALES_BUYER: InviteSalesBuyerComponent,
    INVITE_BULK_STEP_TWO_BUYER: InviteStepTwoBuyerComponent,
    PENDING_BUYERS: PendingBuyersComponent, // TODO Remove
    ONBOARD_BUYERS: OnboardComponentBuyerComponent,

    //sales order
    SALES_ORDER: SalesOrderComponent,
    ADD_SALES_ORDER: AddSalesOrderComponent,
    ADD_SALES_ORDER_TABLE: BulkSaleOrderTable,
    VIEW_SINGLE_RECEIVED_PURCHASE_ORDER: ViewPurchaseOrderComponent,
    VIEW_UPLOADED_RECEIVED_PURCHASE_ORDER: UploadPurchaseOrderViewComponent,
    VALIDATE_PURCHASE_ORDER: ValidatePurchaseOrder,

    //sales invoices
    SALES_INVOICES: SalesInvoicesComponent,
    VIEW_COMMERCIAL_INVOICE: ViewCommercialInvoiceComponent,
    VIEW_PROFORMA_INVOICE: ViewCommercialInvoiceComponent,
    ADD_SALES_INVOICES: AddNewInvoiceComponent,
    ADD_SALES_INVOICES_FROM_PURCHASE_ORDER: AddNewInvoiceComponent,
    CREATE_COMMERCIAL_INVOICE_STEP_ONE: CreateCommercialInvoiceStepOneComponent,
    UPLOAD_BULK_INVOICE: UploadInvoiceOrderComponent,
    BULK_UPLOAD_INVOICES: BulkUploadOrderComponent,
    CREATE_COMMERCIAL_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER: CreateCommercialInvoiceStepOneComponent,
    CREATE_COMMERCIAL_INVOICE_STEP_TWO: CreateCommercialInvoiceStepTwoComponent,
    CREATE_PROFORMA_INVOICE_STEP_ONE: CreateProformaInvoiceStepOneComponent,
    CREATE_PROFORMA_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER: CreateProformaInvoiceStepOneComponent,
    CREATE_PROFORMA_INVOICE_STEP_TWO: CreateCommercialInvoiceStepTwoComponent,
    EDIT_COMMERCIAL_INVOICE: EditCommercialInvoiceComponent,
    COPY_COMMERCIAL_INVOICE: EditCommercialInvoiceComponent,
    EDIT_PROFORMA_INVOICE: EditProformaInvoiceComponent,
    COPY_PROFORMA_INVOICE: EditProformaInvoiceComponent,
    VIEW_UPLOADED_INVOICE: UploadedInvoiceViewComponent,
    EDIT_UPLOADED_INVOICE: EditSingleUploadedInvoice,

    QUOTATIONS: QuotationsComponent,
    CREATE_QUOTATION_STEP_ONE: CreateQuotationStepOneComponent,
    CREATE_QUOTATION_STEP_TWO: CreateQuotationStepTwoComponent,
    VIEW_QUOTATION: ViewQuotationComponent,
    EDIT_QUOTATION: EditQuotationComponent,
    COPY_QUOTATION: EditQuotationComponent,
    VIEW_UPLOADED_QUOTATION: ViewUploadedReceivedQuotationComponent,
    ADD_SALES_QUOTATIONS: AddNewQuotationComponent,
    UPLOAD_BULK_QUOTATION: UploadQuotationComponent,
    BULK_UPLOAD_QUOTATIONS: BulkUploadQuotationComponent,

    //Ecosystem
    ECOSYSTEM: EcosystemComponent,
    SYNCHRONIZE: SynchronizeData,
    VIEW_CONNECTION: ViewConnectionComponent,

    //Connected Accounts
    CONNECTED_ACCOUNTS: ConnectedAccountComponent,
    ADD_NEW_ACCOUNT: AddNewAccountComponent,
    VIEW_CONNECTED_ACCOUNT: ViewConnectedAccountComponent,
    VIEW_CONNECTED_ACCOUNT_TRANSACTIONS: ViewAccountTransactionComponent,
    EDIT_CONNECTED_ACCOUNT: EditAccountComponent,
    MAKE_PAYMENTS_ACCOUNT: MakeAccountPaymentComponent,
    RECEIVE_PAYMENTS_ACCOUNT: ReceivePaymentsComponent,
    RECONCILE_CONNECTED_ACCOUNT_TRANSACTION: ReconcileTransactionComponent,
    ACCOUNT_STATEMENT: AccountStatementComponent,
    RECONCILED_TRANSACTIONS: ReconciledTransactionsComponent,

    //Fund My Purchases
    FUND_MY_PURCHASES: FundMyPurchasesViewComponent,
    VIEW_FINANCING_REQUEST: ViewFinancingRequestComponent,
    GET_FINANCING: GetFinancingComponent,
    WORKING_CAPITAL: WorkingCapitalComponent,
    FINANCE_INVOICES: FinanceInvoicingComponent,
    DISTRIBUTOR_ECOSYSTEM: Distributor_EcosystemComponent,
    EDIT_WORKING_CAPITAL: WorkingCapitalComponent,
    EDIT_FINANCE_INVOICES: FinanceInvoicingComponent,
    EDIT_DISTRIBUTOR_ECOSYSTEM: Distributor_EcosystemComponent,
    FUND_MY_PURCHASES_BANK: FundMyPurchasesBankComponent,
    VIEW_FINANCING_REQUEST_BANK: ViewFinancingRequestComponent,
    VIEW_VALIDATION_REQUEST_BANK: ValidationRequestsViewComponent,

    //Profile
    PROFILE: Profile,
    BANK_PROFILE: Profile,
    //Fund From Sales
    FUND_FROM_SALES: FundFromSalesViewComponent,
    VIEW_FINANCING_ORDER: ViewFinancingOrderComponent,
    GET_FINANCING_SALES: GetFinancingSalesComponent,
    FINANCE_INVOICES_SALES: FinanceInvoicingSalesComponent,
    WORKING_CAPITAL_SALES: WorkingCapitalSalesComponent,
    DISCOUNT_INVOICES: DiscountInvoicesComponent,
    EDIT_FINANCE_INVOICES_SALES: FinanceInvoicingSalesComponent,
    EDIT_WORKING_CAPITAL_SALES: WorkingCapitalSalesComponent,
    EDIT_DISCOUNT_INVOICES: DiscountInvoicesComponent,
    FUND_FROM_SALES_BANK: FundFromSalesBankComponent,
    VIEW_VALIDATION_REQUEST_BANK_SALES: ValidationRequestsViewSalesComponent,
    VIEW_FINANCING_REQUEST_BANK_SALES: ViewFinancingOrderComponent,
    APPLICATION_SUMMARY: ApplicationSummaryComponent,

    //Payments
    PAYMENTS: PaymentsComponent,
    VIEW_PAYMENTS: ViewPaymentsComponent,
    RECEIVE_PAYMENTS: ReceivePaymentsComponent,
    MAKE_PAYMENTS: MakePaymentsComponent,
    PAY_INVOICE: PayInvoiceComponent,
    PAY_BILL: PayBillComponent,
    REPAY_LOAN: RepayLoanComponent,

    //Accounts
    ACCOUNTS: AccountsTableViewComponent,

    //Business - Guarantees/Bonds
    BONDS: BondsComponent,
    VIEW_BOND: ViewGuaranteeBondComponent,
    EDIT_BOND: EditGuaranteeBondComponent,
    BONDS_FINANCING: BondsFinancingComponent,
    ADD_NEW_GUARANTEE: NewGuaranteeComponent,
    CREATE_NEW_GUARANTEE: CreateNewGuaranteeComponent,
    VERIFY_BOND: VerifyBonds,

    // Custom Bank
    NEW_GUARANTEE: NewGuaranteeBond,
    NEW_GUARANTEE_SUBMIT: NewGuaranteeBondSubmit,

    //Developer
    DEVELOPER: Developer,

    BLACKLIST: Blacklist,
    INSIGHTS: Insights,

    //Import Trade
    MAKE_IMPORT_PAYMENTS: MakePaymentsFinanceComponent,
    RECEIVED_BILLS_ON_COLLECTION: ReceivedBillsComponent,
    UPLOAD_INVOICE: AddPurchaseInvoiceComponent,
    VIEW_RECEIVED_BILLS_ON_COLLECTION: ViewReceivedBillsComponent,
    // IMPORT_TRADE: ImportTadeLayout,
    IMPORT_LOANS: ImportLoanTableComponent,
    VIEW_TRADE_LOAN: ImportLoanViewComponent,
    GET_TRADE_FINANCING: GetTradeFinancingComponent,
    FINANCE_IMPORT_ORDERS: FinanceImportOrdersComponent,
    EDIT_FINANCE_IMPORT_ORDERS: FinanceImportOrdersComponent,
    POST_SHIPMENT_FINANCEING: PostShipmentFinanceComponent,
    EDIT_POST_SHIPMENT_FINANCING: PostShipmentFinanceComponent,
    ADD_PERFORMA_INVOICE: AddProformaInvoice,
    IMPORT_SERVICES: ImportServicesComponent,
    SHIPMENT_TRACKING: ShipmentTrackingComponent,
    TRACK_SHIPMENT: TrackOrder,
    ADD_NEW_IMPORT_ORDER: AddNewImportOrderComponent,

    SECURE_IMPORTS: SecureImportsComponent,
    TRACK_SECURE_IMPORT: TrackOrder,
    ESCROW: EscrowComponent,
    NEW_ESCROW: NewEscrow,
    VIEW_ESCROW: ViewEscrowComponent,
    TRACK_ESCROW: TrackEscrowComponent,

    // Import Trade --> Trade Finance
    TRADE_FINANCE: TradeFinanceComponent,
    ADD_IMPORT_ORDER: CreatePurchaseOrderComponent,
    BULK_IMPORT_ORDER: BulkImportOrder,
    LETTER_OF_CREDIT: LetterOfCredit,
    VIEW_LETTER_OF_CREDIT: ViewLetterOfCreditComponent,
    EDIT_LETTER_OF_CREDIT: EditLetterOfCredit,
    APPROVE_LETTER_OF_CREDIT: ApproveLetterOfCredit,
    CLOSE_LETTER_OF_CREDIT: CloseLetterOfCredit,
    VIEW_IMPORT_ORDER: ViewImportOrderComponent,
    TRACK_IMPORT_ORDER: TrackOrder,
    BULK_UPLOAD_IMPORT_ORDER: UploadBulkImportOrderComponent,

    // --------------> Export Trade <--------------
    EXPORT_TRADE_FINANCE: ExportTradeFinanceComponent,
    EXPORT_BILLS_ON_COLLECTION: RegisterBillsOnCollection,
    EDIT_EXPORT_BILLS_ON_COLLECTION: RegisterBillsOnCollection,
    VIEW_EXPORT_BILLS_ON_COLLECTION: ViewExportOrderComponent,
    ADD_EXPORT_INVOICE: AddNewInvoiceComponent,
    VIEW_EXPORT_COMMERCIAL_INVOICE: ViewCommercialInvoiceComponent,
    VIEW_EXPORT_PROFORMA_INVOICE: ViewCommercialInvoiceComponent,
    CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_ONE: CreateCommercialInvoiceStepOneComponent,
    CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_TWO: CreateCommercialInvoiceStepTwoComponent,
    CREATE_EXPORT_PROFORMA_INVOICE_STEP_ONE: CreateProformaInvoiceStepOneComponent,
    CREATE_EXPORT_PROFORMA_INVOICE_STEP_TWO: CreateCommercialInvoiceStepTwoComponent,
    EDIT_EXPORT_COMMERCIAL_INVOICE: EditCommercialInvoiceComponent,
    COPY_EXPORT_COMMERCIAL_INVOICE: EditCommercialInvoiceComponent,
    EDIT_EXPORT_PROFORMA_INVOICE: EditProformaInvoiceComponent,
    COPY_EXPORT_PROFORMA_INVOICE: EditProformaInvoiceComponent,
    VIEW_EXPORT_UPLOADED_INVOICE: UploadedInvoiceViewComponent,

    //Register bills on collection
    VIEW_ALL_REGISTER_BILLS: BillsOnCollectionComponent,
    ADD_BILLS_ON_COLLECTION: RegisterBillsOnCollectionComponent,
    EDIT_BILLS_ON_COLLECTION: RegisterBillsOnCollectionComponent,
    VIEW_SINGLE_BILLS_ON_COLLECTION: ViewBillsOnCollectionComponent,
    ADD_NEW_TEMPLATE: AddNewTemplateComponent,
    EDIT_TEMPLATE: AddNewTemplateComponent,
    DUPLICATE_TEMPLATE: AddNewTemplateComponent,
    VIEW_TEMPLATE: ViewTemplateComponent,

    TRACK_EXPORT_ORDER: TrackExportOrder,
    // EXPORT_TRADE: ExportTadeLayout,
    EXPORT_LOANS: ExportLoanTableComponent,
    VIEW_EXPORT_TRADE_LOAN: ExportLoanViewComponent,
    GET_EXPORT_TRADE_FINANCING: GetExportTradeFinancing,
    FINANCE_EXPORT_ORDERS: FinanceExportOrders,
    EDIT_FINANCE_EXPORT_ORDERS: FinanceExportOrders,
    LC_DISCOUNTING: LCDiscounting,
    GET_WORKING_CAPITAL: GetWorkingCapital,
    EDIT_GET_WORKING_CAPITAL: GetWorkingCapital,
    COLLECTION_DISCOUNTING: CollectionDiscounting,
    EDIT_COLLECTION_DISCOUNTING: CollectionDiscounting,
    // ADD_EXPORT_PERFORMA_INVOICE: AddExportProformaInvoice,
    EXPORT_SERVICES: ExportServicesComponent,
    EXPORT_SHIPMENT_TRACKING: ExportShipmentTrackingComponent,
    TRACK_EXPORT_SHIPMENT: TrackExportOrder,
    // SECURE_EXPORTS: SecureExports,
    // TRACK_SECURE_EXPORT: TrackExportOrder,

    EXPORT_ESCROW: ExportEscrowComponent,
    VIEW_EXPORT_ESCROW: ViewExportEscrowComponent,
    TRACK_EXPORT_ESCROW: TrackExportEscrowComponent,
};

const preLoadComponent = (components) => {
    const loadableComponents = [
        { name: 'SignIn', component: SignIn },
        { name: 'Initialization', component: Initialization },
        { name: 'Dashboard', component: Dashboard },
        { name: 'IncompleteProfileDashboard', component: IncompleteProfileDashboard },
        { name: 'EcosystemComponent', component: Ecosystem },
        { name: 'FundMyPurchasesViewComponent', component: FundMyPurchasesView },
        { name: 'FundFromSalesViewComponent', component: FundFromSalesView },
        { name: 'BondsComponent', component: Bonds },
        { name: 'PurchaseSupplierTableComponent', component: PurchaseSupplierTable },
        { name: 'BuyerCentralComponent', component: BuyerCentral },
        { name: 'QuotationsComponent', component: Quotations },
        { name: 'SalesOrderComponent', component: SalesOrder },
        { name: 'SalesInvoicesComponent', component: SalesInvoices },
        { name: 'PurchaseOrderTableComponent', component: PurchaseOrderTable },
        { name: 'ReceivedQuotationsComponent', component: ReceivedQuotations },
        { name: 'PurchaseInvoiceTableComponent', component: PurchaseInvoiceTable },
        { name: 'ProductsComponent', component: Products },
        { name: 'ConnectedAccountComponent', component: ConnectedAccount },
        { name: 'PaymentsComponent', component: Payments },
        { name: 'TradeFinanceComponent', component: TradeFinance },
        { name: 'ExportTradeFinanceComponent', component: ExportTradeFinance },
        { name: 'SettingsComponent', component: Settings },
        { name: 'BankSettingsComponent', component: BankSettings },
        { name: 'BusinessViewComponent', component: BusinessView },
        { name: 'BillsOnCollectionComponent', component: BankRegisterBillsOnCollection },
    ];

    // find if the component is in the list of loadableComponents
    components.forEach((component) => {
        const foundIndex = loadableComponents.findIndex((item) => item.name.toLowerCase() == component.toLowerCase());
        if (foundIndex !== -1) {
            const foundComponent = loadableComponents[foundIndex];
            foundComponent.component.preload();
        }
    });
};

export { COMPONENTS, preLoadComponent };
