import { css } from 'styled-components';
import { COLORS } from '../../../../../../../../config/theme';

const style = () => css`
    .form-container {
        padding: 10px 20px;
        border-top: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .label {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: flex-start;
            margin-bottom: 0;
            .ant-form-item-label {
                width: 100%;
                text-align: left;
            }
            .ant-form-item-control {
                width: 100%;
            }
            .ant-row {
                width: 100%;
            }
        }
    }
`;

export default style();
