export const ACTIONS = {
    FETCH_CONNECTION_REQUEST_START: 'FETCH_CONNECTION_REQUEST_START',
    FETCH_CONNECTION_REQUEST: 'FETCH_CONNECTION_REQUEST',
    FETCH_CONNECTION_REQUEST_FAILURE: 'FETCH_CONNECTION_REQUEST_FAILURE',

    //New connection requests
    FETCH_NEW_CONNECTION_REQUEST_START: 'FETCH_NEW_CONNECTION_REQUEST_START',
    FETCH_NEW_CONNECTION_REQUEST: 'FETCH_NEW_CONNECTION_REQUEST',
    FETCH_NEW_CONNECTION_REQUEST_FAILURE: 'FETCH_NEW_CONNECTION_REQUEST_FAILURE',

    //Fetch Invites
    FETCH_INVITES_START: 'FETCH_INVITES_START',
    FETCH_INVITES: 'FETCH_INVITES',
    FETCH_INVITES_FAILURE: 'FETCH_INVITES_FAILURE',

    //Ignore request
    IGNORE_CONNECTION_REQUEST: 'IGNORE_CONNECTION_REQUEST',

    //Accept Request
    ACCEPT_CONNECTION_REQUEST: 'ACCEPT_CONNECTION_REQUEST',
};
