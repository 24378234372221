import React from 'react';
import SelectCountry from '../FormSelects/Countries';
import SelectCounty from '../FormSelects/Counties';

type Country = any;

type CountryContextType = {
    country?: Country;
    setCountry?: (country: Country) => void;
};

export const CountryContext = React.createContext<CountryContextType | null>(null);

export const CountryProvider = ({ children }: { children: React.ReactNode }) => {
    const [country, setCountry] = React.useState<Country>({});

    return <CountryContext.Provider value={{ country, setCountry }}>{children}</CountryContext.Provider>;
};

export const CountryInput = (props) => {
    const { setCountry } = React.useContext(CountryContext) as CountryContextType;

    return <SelectCountry {...props} onCountrySelected={setCountry} />;
};

export const CountyInput = (props) => {
    const { country } = React.useContext(CountryContext) as CountryContextType;

    return <SelectCounty {...props} countryId={country.country_id} />;
};
