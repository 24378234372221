import { ROUTES } from '../../../../../router/urls';
import { getUser, Users } from '../../../../../config';
import { UnAuthenticatedBankRoutes, UnAuthenticatedRoutes } from '../../../../../router/routes';
import { getCurrentMenu } from '../../../../../config/menus/getMenu';

import { menuOne, gapstack } from '../../../../../config/menus';
import { tsc } from '../../../../../config/menus/tsc';

let menu: any = gapstack;
if (getCurrentMenu === 'menuOne') {
    menu = menuOne;
}

if (getCurrentMenu === 'tsc') {
    menu = tsc;
}

interface subMenuItemsType {
    pathNames: string[];
    key: string;
    link: string;
    title: string;
    canDisable?: boolean;
    enabled?: boolean;
}

interface sideItemsType {
    key: string;
    activeImage: any;
    inactiveImage: any;
    title: string;
    link: string;
    pathNames: string[];
    subMenu?: boolean;
    canDisable?: boolean;
    subMenuItems?: subMenuItemsType[];
    disabledImage?: any;
    onhover?: string[];
    enabled?: boolean;
}

const firstItems = () => {
    const isBank = getUser() == Users.BANK;

    if (isBank) {
        return menu.firstItemsForBank;
    } else {
        return menu.firstItems;
    }
};

interface sideBarItemsType {
    name: string | null;
    items: sideItemsType[];
    canDisable?: boolean;
    menuType?: 'bank' | 'business';
    enabled?: boolean;
}

const SideBarItems: (user: Users, isAdmin?: boolean) => sideBarItemsType[] = () => {
    const sideBarConfig: string = process.env.REACT_APP_SIDE_BAR_CONFIG || '11-111[11111][11111]-[11111][11111]1-1-111';
    const sideBarArray = sideBarConfig.split('-');
    return [
        {
            name: null,
            items: firstItems(),
            enabled: (sideBarArray[0] ?? '11')?.includes('1'),
        },
        {
            name: 'FINANCING & TRADE',
            items: menu.secondItems,
            canDisable: true,
            menuType: 'business',
            enabled: (sideBarArray[1] ?? '111[11111][11111]')?.includes('1'),
        },
        {
            name: 'YOUR BUSINESS',
            items: menu.thirdItems,
            canDisable: true,
            menuType: 'business',
            enabled: (sideBarArray[2] ?? '[11111][11111]1')?.includes('1'),
        },
        {
            name: 'ACCOUNTS & PAYMENTS',
            items: menu.fourthItems,
            canDisable: true,
            menuType: 'business',
            enabled: (sideBarArray[3] ?? '1')?.includes('1'),
        },
        {
            name: 'TOOLS',
            items: menu.fifthItems,
            enabled: (sideBarArray[4] ?? '111')?.includes('1'),
        },
    ];
};

export const isEnabledFromSideBar = (arrayIndex: number, subMenuNumber: number) => {
    const sideBarConfig: string = process.env.REACT_APP_SIDE_BAR_CONFIG || '11-111[11111][11111]-[11111][11111]1-1-111';
    const sideBarArray = sideBarConfig.split('-');
    const enabledRouteArray =
        sideBarArray[arrayIndex] && sideBarArray[arrayIndex].replaceAll('[', '').replaceAll(']', '').split('');
    return !!Number(enabledRouteArray[subMenuNumber]) ?? true;
};

export const isEnabledFromDashboardMenu = (arrayIndex: number) => {
    const dashboardMenuConfig: string = process.env.REACT_APP_DASHBOARD_MENU_CONFIG || '11111';
    return !!Number(dashboardMenuConfig[arrayIndex]) ?? true;
};

const getDisabledPaths = (user: Users) => {
    //Get all possible authenticated routes
    const allPaths: string[] = [];
    Object.keys(ROUTES).map((route) => {
        if (
            Object.keys(UnAuthenticatedRoutes).indexOf(route) < 0 &&
            Object.keys(UnAuthenticatedBankRoutes).indexOf(route) < 0
        ) {
            allPaths.push(ROUTES[route]);
        }
    });

    //Get prefix of modules and subModules
    let disabledPrefixes: string[] = [];
    let disabledSubPrefixes: string[] = [];
    SideBarItems(user).map((section) => {
        section?.items?.map((menu) => {
            if (!menu.enabled) {
                disabledPrefixes = disabledPrefixes.concat(menu.pathNames);
            }
            if (menu.subMenu) {
                menu.subMenuItems?.map((submenu) => {
                    if (!submenu.enabled) {
                        disabledSubPrefixes = disabledSubPrefixes.concat(submenu.pathNames);
                    }
                });
            }
        });
    });

    //Filter the routes that includes the submenu and main menu disabled prefixes
    const disabledPaths: string[] = [];
    allPaths.map((path) => {
        const tempPath = path.replace('/:businessId', '');
        const tempPathArray = tempPath.split('/');
        if (tempPathArray[0] && disabledPrefixes.indexOf(tempPathArray[0]) > -1) {
            disabledPaths.push(path);
        }
        if (tempPathArray[1] && disabledSubPrefixes.indexOf(tempPathArray[1]) > -1) {
            disabledPaths.push(path);
        }
    });

    return disabledPaths;
};

const getSideBarLocksProperty = () => {
    return process.env.REACT_APP_LOCKS_CONFIG == 'VISIBLE';
};

export { SideBarItems, getDisabledPaths, getSideBarLocksProperty };
