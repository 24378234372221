import { css } from 'styled-components';

const style = () => css`
    .ant-modal-header {
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: none !important;
        border-radius: 2px 2px 0 0;
    }
    .ant-modal-footer {
        padding: 20px;
    }
`;

export default style;
