import { Form, Input, notification, Select } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { osName } from 'react-device-detect';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import { unAuthenticatedRequest } from '../../../api';
import style from './styles';
const { Option } = Select;

const Wrapper = styled.div`
    ${style}
`;

interface Props {
    phone?: string;
    countryCode?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    disableCountryCode?: boolean;
    countryFieldName?: string | (number | string)[];
    countryFieldNamePath?: string | (number | string)[];
    inputFieldName?: string | (number | string)[];
    readOnly?: boolean;
    formItemClassName?: string;
    formItemLabel?: string;
}

const PhoneNumber: React.FC<Props> = ({
    phone,
    countryCode,
    validateStatus,
    formItemLabel = 'Phone Number',
    countryFieldName = 'countryCode',
    inputFieldName = 'phone',
    disableCountryCode = false,
    readOnly,
    formItemClassName,
    countryFieldNamePath,
}: Props) => {
    const mounted = useRef(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<boolean>(true);

    const fetchCountries = () => {
        setLoading(true);
        unAuthenticatedRequest()
            .get(`data-provider/data-provider`)
            .then((res) => {
                const countries = res.data.map((country) => {
                    // check if phone code as plus at the begining
                    if (country.phonecode.charAt(0) !== '+') {
                        return {
                            countryName: country.countryName,
                            currency: country.currency,
                            country_id: country.country_id,
                            countryCode: country.countryCode,
                            phonecode: `+${country.phonecode}`,
                        };
                    }

                    return country;
                });
                setCountries(countries);
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            })
            .finally(() => {
                if (mounted.current) {
                    setLoading(false);
                }
            });
    };

    const checkIfPhoneNumberIsValid = (number, phoneCode) => {
        // get the country code
        const country = countries.filter((country) => country.phonecode === phoneCode);
        return isValidPhoneNumber(`${phoneCode}${number}`, country[0]?.countryCode);
    };

    useEffect(() => {
        mounted.current = true;
        fetchCountries();
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <Wrapper
            selected={countrySelected}
            hasFormItemLabel={!!formItemLabel}
            style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', position: 'relative' }}
        >
            <Form.Item
                noStyle
                name={countryFieldName}
                label="Test"
                className={`label label-code ${formItemClassName}`}
                initialValue={countryCode ? countryCode : process.env.REACT_APP_DEFAULT_COUNTRY_CODE}
                rules={[{ required: true, message: 'Select code' }]}
                validateStatus={validateStatus}
            >
                <Select
                    style={{ width: '100%' }}
                    onFocus={() => setCountrySelected(false)}
                    onBlur={() => setCountrySelected(true)}
                    loading={loading}
                    allowClear={false}
                    disabled={readOnly || disableCountryCode}
                    className="phone-number-select"
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    dropdownMatchSelectWidth={320}
                    showSearch
                    showArrow={!countrySelected}
                    onSelect={() => setCountrySelected(true)}
                    onDeselect={() => setCountrySelected(false)}
                    aria-label={'select-number'}
                    filterOption={(input, option) => {
                        let label = '';
                        if (option?.children.length) {
                            label = option?.children[2];
                        }

                        console.log(option?.value.toLowerCase());
                        return (
                            option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                    }}
                >
                    {countries.map((country) => (
                        <Option key={country.country_id} className="options" value={country.phonecode}>
                            {osName.toLowerCase() === 'windows' ? (
                                <ReactCountryFlag
                                    countryCode={country?.countryCode}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    title={country?.countryCode}
                                />
                            ) : (
                                <ReactCountryFlag countryCode={country?.countryCode} />
                            )}
                            &nbsp;&nbsp;
                            {`${country.countryName} ${country.phonecode}`}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name={inputFieldName}
                label={formItemLabel}
                className="label label-phone"
                initialValue={phone}
                rules={[
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value) {
                                return Promise.reject(new Error('Enter phone number'));
                            }
                            if (
                                checkIfPhoneNumberIsValid(
                                    value,
                                    getFieldValue(countryFieldNamePath || countryFieldName),
                                ) ||
                                readOnly
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Phone number is not valid'));
                        },
                    }),
                ]}
            >
                <Input className="gapstack-input" placeholder="700333000" readOnly={readOnly} type="text" />
            </Form.Item>
        </Wrapper>
    );
};

export default PhoneNumber;
