import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

interface Props {
    selected: boolean;
    hasFormItemLabel: boolean;
}
const style = ({ selected, hasFormItemLabel }: Props) => css`
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 45px;
    }
    .ant-select-arrow {
        top: 45%;
    }
    .phone-number-select {
        width: ${selected ? '40px' : '110px'} !important;
        height: 45px;
        border: none;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        //top: 50%;
        //bottom: 50%;
        //transform: translate(0%, 35%);
        //position: absolute;
        left: 1px;
        top: ${hasFormItemLabel ? '32px' : '0px'};
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        z-index: 10;
        .ant-select-selector {
            outline: none;
            width: ${selected ? '40px' : '110px'};
            height: 40px;
            border: none;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                line-height: 40px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
        .options {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-select-focused:not(.ant-select-disabled),
        .ant-select-single:not(.ant-select-customize-input),
        .ant-select-selector {
            box-shadow: none !important;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
    }
    .gapstack-input {
        height: 45px;
        padding-left: ${selected ? '50px' : '120px'};
        width: 100%;
    }
    .label-code {
        width: 80px !important;
    }
    .label-phone {
        position: absolute;
        width: 100% !important;
        z-index: 1;
        top: -1px;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        margin: auto;
    }
`;

export default style;
