import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f4f8f9;
    .main-image {
        width: 50%;
        max-width: 400px;
    }
    .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 37px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .description {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        text-align: center;
    }
    .content-container {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .action-container {
        margin-top: 10px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        .go-back {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;

export default style;
