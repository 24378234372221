import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .card-wrapper {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-head {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-card-body {
            border-radius: 5px;
        }
        .ant-select-selection-placeholder {
            line-height: 42px;
        }
        .disabled-phone-select {
            .phone-number-select {
                top: 33px;
                left: 3px;
            }
        }
    }
    .card-margin-top {
        margin-top: 20px;
    }
`;

export default style();
