import React from 'react';
import styled from 'styled-components';
import { Divider, Row, Col, Form, Input, Button } from 'antd';
import UnauthenticatedLayout from '../../Layouts/Unauthenticated';
import help from '../../../assets/Images/help.svg';
import { Link, useHistory } from 'react-router-dom';
import style from './styles';
import { ArrowLeft } from 'react-feather';
import { appIcon } from '../../../config/appIcon';
const VerifyDocWrapper = styled.div`
    ${style}
`;

const VerifyDocuments = () => {
    const history = useHistory();
    const onFinish = () => {
        // console.log('Received values of form:', values);
    };

    return (
        <UnauthenticatedLayout>
            <Row>
                <Col
                    xs={{ span: 20, offset: 4 }}
                    sm={{ span: 20, offset: 4 }}
                    md={{ span: 20, offset: 4 }}
                    lg={{ span: 12, offset: 6 }}
                    xl={{ span: 12, offset: 6 }}
                >
                    <VerifyDocWrapper>
                        <Button className="back" onClick={() => history.goBack()}>
                            <ArrowLeft className="back-arrow" />
                            Back
                        </Button>
                        <Row className="logo-container">
                            <img className="logo" src={appIcon} alt="Logo" />
                        </Row>
                        <div className="container">
                            <Row style={{ padding: '12px 50px' }}>
                                <span className="welcome">Verify Documents</span>
                            </Row>
                            <Row style={{ padding: '0px 50px' }}>
                                <p className="sub-heading">Enter the following information.</p>
                            </Row>
                            <Row style={{ padding: '0px 50px' }}>
                                <Divider type="horizontal" className="gapstack-divider" />
                            </Row>
                            <Row>
                                <Form
                                    name="sign-in-form"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    style={{ width: '100%' }}
                                >
                                    <Row gutter={20} style={{ padding: '0px 50px' }}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Enter guarantee ID/invoice ID/order ID"
                                                name="invoiceId"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input Invoice/Order/gaurantee ID!',
                                                    },
                                                ]}
                                            >
                                                <Input className="gapstack-input" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Enter Amount"
                                                name="amount"
                                                rules={[{ required: true, message: 'Please input amount!' }]}
                                            >
                                                <Input className="gapstack-input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20} style={{ padding: '0px 50px' }}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Certificate of Incorporation/ Business Reg. No."
                                                name="businessId"
                                                rules={[{ required: true, message: 'Please input business reg. no.!' }]}
                                            >
                                                <Input className="gapstack-input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider style={{ borderTop: '1.5px solid #e5e5e5', margin: 0 }} />

                                    <Row className="button-container">
                                        <Col span={24} className="right-button-container">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="gapstack-button">
                                                    Verify
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                        </div>
                    </VerifyDocWrapper>
                </Col>
            </Row>
            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </UnauthenticatedLayout>
    );
};

export default VerifyDocuments;
