import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

// import Ecosystem from './table';
// import SynchronizeData from './synchronize-data';
// import ViewConnection from './view-connection/components';

const EcosystemComponent = (props: any) => (
    <AuthenticatedLayout>
        <Ecosystem {...props} />
    </AuthenticatedLayout>
);
const Ecosystem = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Ecosystem" */),
    loading() {
        return <Loader />;
    },
});

const SynchronizeData = Loadable({
    loader: () => import('./synchronize-data' /* webpackChunkName: "Ecosystem" */),
    loading() {
        return <Loader />;
    },
});

const ViewConnectionComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewConnection {...props} />
    </AuthenticatedLayout>
);
const ViewConnection = Loadable({
    loader: () => import('./view-connection/components' /* webpackChunkName: "Ecosystem" */),
    loading() {
        return <Loader />;
    },
});

export { EcosystemComponent, SynchronizeData, ViewConnectionComponent, Ecosystem };
