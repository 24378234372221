import React from 'react';
import Loadable from 'react-loadable';
import AuthenticatedLayout from '../../../components/Layouts/Authenticated';
import Loader from '../../../utils/Loader';

const SecurityHistoryComponent = (props: any) => (
    <AuthenticatedLayout>
        <SecurityHistory {...props} />
    </AuthenticatedLayout>
);
const SecurityHistory = Loadable({
    loader: () => import('./security-history' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});

const BankTeamSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <TeamSettings {...props} />
    </AuthenticatedLayout>
);
const TeamSettings = Loadable({
    loader: () => import('./team-settings' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});

const UserGroupsComponent = (props: any) => (
    <AuthenticatedLayout>
        <UserGroups {...props} />
    </AuthenticatedLayout>
);

const UserGroups = Loadable({
    loader: () => import('./user-groups' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});

const AddUserGroupsComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddUserGroups {...props} />
    </AuthenticatedLayout>
);

const AddUserGroups = Loadable({
    loader: () => import('./user-groups/add-group' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});
const UploadUserGroupsComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <UploadUserGroups {...props} />
    </AuthenticatedLayout>
);

const UploadUserGroups = Loadable({
    loader: () => import('./user-groups/upload-group' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});
const ViewUserGroupsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewUserGroups {...props} />
    </AuthenticatedLayout>
);

const ViewUserGroups = Loadable({
    loader: () => import('./user-groups/view-group' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});
const AddMemberComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddMember {...props} />
    </AuthenticatedLayout>
);

const AddMember = Loadable({
    loader: () => import('./user-groups/view-group/add-member' /* webpackChunkName: "Team-Security-Settings" */),
    loading() {
        return <Loader />;
    },
});
export {
    SecurityHistory,
    SecurityHistoryComponent,
    BankTeamSettingsComponent,
    UserGroupsComponent,
    AddUserGroupsComponent,
    UploadUserGroupsComponent,
    ViewUserGroupsComponent,
    AddMemberComponent,
};
