import React from 'react';

import styled from 'styled-components';

const DisabledWrapper = styled.div`
    position: relative;
    opacity: 0.55;
    cursor: not-allowed;
    pointer-events: none;

    &:before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
`;

const Disabled = ({ children }: any) => {
    return <DisabledWrapper>{children}</DisabledWrapper>;
};

export default Disabled;
