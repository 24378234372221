import { css } from 'styled-components';
import { hex2rgba } from '../../Random';

const style = ({ color }) => css`
    .status-tag {
        background-color: ${hex2rgba(color, 0.2)};
        //min-height: 22px;
        //min-width: 80px;
        border-radius: 3px;
        display: inline-flex;
        padding: 1px 6px;
        text-align: center;
        .text {
            color: ${color};
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90px;
        }
    }
    .status-normal {
    }
`;

export default style;
