import React, { useRef, useState } from 'react';
import { Tag, Input, Button, Row, Tooltip } from 'antd';
import add from '../../../../../assets/Images/add-circle.svg';
import styled from 'styled-components';
import style from './styles';
import { X } from 'react-feather';
import AuthorizationWrapper from '../../../../../Authorization/AuthorizationWrapper';

const BlacklistWrapper = styled.div`
    ${style}
`;

const EditableTag = () => {
    const [tags, setTags] = useState<any[]>(['jackngare@gmail.com']);
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const editInputRef = useRef<any>();

    const handleClose = (removedTag) => {
        const filteredTags = tags.filter((tag) => tag !== removedTag);
        setTags(filteredTags);
    };

    const showInput = async () => {
        await setInputVisible(true);
        setTimeout(() => {
            editInputRef.current?.focus();
        }, 0);
    };

    const handleInputChange = (e) => {
        console.log(e.target.value);
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            const newTags = [...tags, inputValue];
            setTags(newTags);
        }
        setInputVisible(false);
        setInputValue('');
    };

    return (
        <BlacklistWrapper>
            {tags.map((tag) => {
                const isLongTag = tag.length > 30;

                const tagElem = (
                    <Tag
                        className="edit-tag"
                        key={tag}
                        closable
                        onClose={() => handleClose(tag)}
                        closeIcon={
                            <AuthorizationWrapper restrictedRoles={['View', 'Analyst']}>
                                <X className="multi-select-icon" size="14" strokeWidth="2.5" />
                            </AuthorizationWrapper>
                        }
                    >
                        <div className="closable-tag">{isLongTag ? `${tag.slice(0, 20)}...` : tag}</div>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <Input
                    ref={editInputRef}
                    type="text"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    className="gapstack-input"
                    style={{ width: 270 }}
                />
            )}

            <AuthorizationWrapper restrictedRoles={['View', 'Analyst', 'Administrator']}>
                <Row onClick={showInput} gutter={20} style={{ padding: '5px 10px' }}>
                    <Button className="add-button">
                        <img src={add} alt="add" className="plus-circle" />
                        <span className="add-tier">New key word</span>
                    </Button>
                </Row>
            </AuthorizationWrapper>
        </BlacklistWrapper>
    );
};
export default EditableTag;
