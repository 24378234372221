import React, { useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { get_industries } from '../../../components/business-accounts/api';
import { authenticatedRequest } from '../../../api';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store/reducers/rootReducers';

interface Props {
    formItemLabel: string;
    formItemName: string | string[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
    valueType?: 'label' | 'value';
    onOptionSelect?: (option: any) => void;
    disabled?: boolean;
}

const { Option } = Select;

const IndustryWrapper = styled.div`
    ${style}
`;

const SelectIndustry = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    onChange,
    hasFeedback = false,
    valueType = 'value',
    onOptionSelect,
    disabled,
}: Props) => {
    // const businessAccount: any = useSelector((state: RootState) => state.businessAccount);
    const [loading, setLoading] = useState<boolean>(false);
    const [industries, setIndustries] = useState<any>([]);

    const fetchIndustries = () => {
        setLoading(true);
        authenticatedRequest()
            .get(get_industries)
            .then((res) => {
                setLoading(false);
                setIndustries(res.data);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchIndustries();
    }, []);

    return (
        <IndustryWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className="label"
                rules={[{ required, message }]}
                initialValue={initialValue}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    showSearch
                    allowClear
                    loading={loading}
                    className="form-select select"
                    placeholder="Industry"
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    onChange={(e, option) => {
                        onChange?.(e, formItemName);
                        onOptionSelect?.(option);
                    }}
                    optionFilterProp="children"
                    disabled={disabled}
                    filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {industries?.map((industry, index) => (
                        <Option key={index} value={valueType === 'value' ? industry?.id : industry?.name}>
                            {industry?.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </IndustryWrapper>
    );
};

export default SelectIndustry;
