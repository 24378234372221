import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${(props: any) => props.theme[COLORS.TRANSPARENT]};
        height: 48px;
        padding: 5px 10px;
        border: none;
    }
    .select-icon {
        color: ${(props: any) => props.theme[COLORS.ICONS]};
        width: 16px !important;
        height: 16px !important;
        top: 18%;
    }
    .norm-select {
        .ant-select-arrow {
            top: 24px !important;
            right: 8%;
        }
    }

    .gapstack-input .ant-input-number-input {
        height: 50px;
    }

    textarea.gapstack-input {
        font-size: 13px;
        padding: 8px 10px;
    }
`;

export default style;
