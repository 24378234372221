import React from 'react';
import { Empty, notification, Pagination } from 'antd';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import style from './styles';
import { goToRoute, ROUTES } from '../../../../router/urls';
import NotificationItem from '../notification-item';
import { authenticatedRequest } from '../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers/rootReducers';
import {
    delete_single_notification,
    dismiss_all_notifications,
    dismiss_single_notification,
} from '../../../../apis/notifications';
import { authenticatedLayoutActions } from '../../../Layouts/Authenticated/store/actions';

const NotificationsCardWrapper = styled.div`
    ${style}
`;

interface Props {
    title?: string;
    data: any[];
    disabled?: boolean;
    seeAll?: boolean;
    dismissText?: boolean;
    showDismissAll?: boolean;
    pagination?: boolean;
    paginationObject?: any;
    changePagination?: (page: number) => void;
}
function itemRender(_, type, originalElement) {
    if (type === 'prev') {
        return <a>Previous</a>;
    }
    if (type === 'next') {
        return <a>Next</a>;
    }
    return originalElement;
}

const NotificationsCard: React.FC<Props> = ({
    title,
    data,
    disabled,
    seeAll,
    dismissText,
    showDismissAll = true,
    pagination = false,
    paginationObject,
    changePagination,
}: Props) => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    const dismissHandle = () => {
        authenticatedRequest({ 'x-user-id': auth?.user?.userId })
            .put(dismiss_all_notifications)
            .then((res) => res.data)
            .then(() => {
                dispatch(authenticatedLayoutActions.clearUnreadNotifications());
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onMarkRead = ({ id, read, url }) => {
        if (!!read) return;
        authenticatedRequest({ 'x-user-id': auth?.user?.userId })
            .put(dismiss_single_notification(id))
            .then((res) => res.data)
            .then(() => {
                dispatch(authenticatedLayoutActions.readNotification(id));
                url && history.push({ pathname: goToRoute(url) });
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onClose = ({ id, read }) => {
        authenticatedRequest({ 'x-user-id': auth?.user?.userId })
            .delete(delete_single_notification(id))
            .then((res) => res.data)
            .then(() => {
                if (!!read) {
                    dispatch(authenticatedLayoutActions.deleteReadNotification(id));
                } else {
                    dispatch(authenticatedLayoutActions.deleteUnreadNotification(id));
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <NotificationsCardWrapper className={`notifications ${seeAll ? 'dense' : ''}`}>
            <div className="header">
                <span>{title || 'Recent Notifications'}</span>

                {showDismissAll && (
                    <span className="btn" onClick={dismissHandle}>
                        {data.length > 0 && seeAll ? 'Clear all' : !disabled && data.length > 0 && 'Dismiss all'}
                    </span>
                )}
            </div>

            <div className="body">
                <TransitionGroup>
                    {data.map((notification, key) => (
                        <CSSTransition key={notification.id} timeout={seeAll ? 10 : 800} classNames="item">
                            <NotificationItem
                                data={notification}
                                onClose={() => onClose(notification)}
                                onMarkRead={() => onMarkRead(notification)}
                                dense={!notification.read}
                                disabled={disabled}
                                style={{ transitionDelay: `${(key + 1) * 100}ms` }}
                                dismissText={dismissText}
                            />
                        </CSSTransition>
                    ))}

                    {data.length === 0 && (
                        <CSSTransition timeout={seeAll ? 10 : 800} classNames="empty-item">
                            <div className="empty">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications" />
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>

                {seeAll && (
                    <div className="footer">
                        <Link to={goToRoute(ROUTES.NOTIFICATIONS)}>
                            <span>See All</span>
                        </Link>
                    </div>
                )}
            </div>
            {pagination && (
                <Pagination
                    current={paginationObject?.page}
                    total={paginationObject?.total}
                    pageSize={5}
                    itemRender={itemRender}
                    onChange={(page) => {
                        changePagination?.(page);
                    }}
                />
            )}
        </NotificationsCardWrapper>
    );
};

export default NotificationsCard;
