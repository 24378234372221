import { Row, Col, Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commercialBlacklistData, technologyBlacklistData } from './components/data';
import CardData from './components/card-component';
import AuthenticatedLayout from '../../../Layouts/Authenticated';
import { goToRoute, ROUTES } from '../../../../router/urls';
import style from './styles';

const BlacklistWrapper = styled.div`
    ${style}
`;

const Blacklist = () => {
    return (
        <AuthenticatedLayout>
            <BlacklistWrapper>
                <Row className="header-table">
                    <Col span={12}>
                        <Breadcrumb separator="|">
                            <Breadcrumb.Item>
                                <Link to={goToRoute(ROUTES.SETTINGS)}>Settings</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={goToRoute(ROUTES.SETTINGS)}>Fraud Central</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Blacklist</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <CardData data={commercialBlacklistData} />
                <CardData data={technologyBlacklistData} />
            </BlacklistWrapper>
        </AuthenticatedLayout>
    );
};

export default Blacklist;
