import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Form, Input, Modal, notification, Row } from 'antd';
import style from './styles';
import { authenticatedRequest } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducers';
import { actions } from '../../authentication/sign-in/store/actions';
import { post_enable_two_factor, two_factor_generate } from '../api';
import { getUser, Users } from '../../../config';
import { bank_post_enable_two_factor, bank_two_factor_generate } from '../../../apis/bank-authentication';
import { LoadingOutlined } from '@ant-design/icons';
import { RiShieldCheckFill } from 'react-icons/ri';
import { COLORS, generateTheme } from '../../../config/theme';

const EnableTwoFactorAuthenticationWrapper = styled.div`
    ${style}
`;

interface Props {
    visible: boolean;
    handleCancel?: () => void;
    setTwoStep?: (value) => void;
    closable?: boolean;
}

const EnableTwoFactorAuthentication = ({ visible, handleCancel, closable = true }: Props) => {
    const isBank = getUser() == Users.BANK;
    const dispatch = useDispatch();
    const [enablingTwoFactor, setEnablingTwoFactor] = useState(false);
    const auth: any = useSelector((state: RootState) => state.auth);
    const [qrCodeLoading, setQrCodeLoading] = useState<boolean>(false);
    const [qrCode, setQRCode] = useState<any>();
    const [codes, setCodes] = useState([]);
    const [showBackUpCodes, setShowBackUpCodes] = useState(false);
    const userId = auth?.user?.userId;

    const getUserQRCode = () => {
        setQrCodeLoading(true);
        authenticatedRequest()
            .get(`${isBank ? bank_two_factor_generate : two_factor_generate}/${userId}`, {
                responseType: 'arraybuffer',
            })
            .then((res) => {
                setQrCodeLoading(false);
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                const image = URL.createObjectURL(blob);
                setQRCode(image);
            })
            .catch((error) => {
                setQrCodeLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        if (visible) {
            getUserQRCode();
        }
    }, [visible]);

    const onFinish = (values) => {
        setEnablingTwoFactor(true);
        authenticatedRequest()
            .post(`${isBank ? bank_post_enable_two_factor : post_enable_two_factor}/${userId}`, values)
            .then((res) => {
                setEnablingTwoFactor(false);
                notification.success({
                    description: 'Success',
                    message: 'Two factor has been enabled for your account',
                });
                setShowBackUpCodes(true);
                setCodes(res.data.data.backUpCodes);
                // update the twoStep flag
                const data = Object.assign({}, auth.user);
                data.twoStep = true;
                dispatch(actions.updateTwoStep(data));
            })
            .catch((error) => {
                setEnablingTwoFactor(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const downloadTxtFile = () => {
        let str = 'Recovery codes:';
        codes.forEach((code) => {
            str += '\n' + code;
        });
        const element = document.createElement('a');
        const file = new Blob([str], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'recovery-codes.txt';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        handleCancel?.();
    };

    return (
        <Modal
            title="Enable Two Factor Authentication"
            transitionName=""
            visible={visible}
            onCancel={closable ? () => handleCancel?.() : () => false}
            footer={false}
            closable={closable}
        >
            <EnableTwoFactorAuthenticationWrapper>
                {!showBackUpCodes ? (
                    <div>
                        <p>
                            1. To enable 2FA, you need to install an authenticator app like Authy, or Google
                            Authenticator.
                        </p>
                        <p>2. Scan the QR Code below with your authenticator app.</p>
                        <div className="qr-code-container">
                            {qrCodeLoading ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 20,
                                    }}
                                >
                                    <LoadingOutlined
                                        size={24}
                                        style={{ fontSize: 34, color: `${generateTheme()[COLORS.PRIMARY]}` }}
                                        spin
                                    />
                                </div>
                            ) : qrCode !== undefined ? (
                                <img width="70%" src={qrCode} />
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 20,
                                    }}
                                >
                                    <p>Unable to create qr code</p>
                                    <Button className="gapstack-button" onClick={getUserQRCode}>
                                        Generate QR Code
                                    </Button>
                                </div>
                            )}
                        </div>
                        <p>3. Enter the TOTP generated by the app and click enable.</p>
                        <Form name="Enable-2FA" onFinish={onFinish}>
                            <Form.Item
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input the TOTP generated by the authentication app!',
                                    },
                                ]}
                            >
                                <Input className="gapstack-input" />
                            </Form.Item>

                            <Divider type="horizontal" />

                            <Row className="button-container">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="gapstack-button"
                                    loading={enablingTwoFactor}
                                >
                                    Enable
                                </Button>
                            </Row>
                        </Form>
                    </div>
                ) : (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <RiShieldCheckFill size={60} color="#2CCC71" />
                            <p className="title">2 Factor Authentication Enabled</p>
                        </div>

                        <p className="description">
                            Download and store these backup codes somewhere safe. If you lose access to your
                            authentication device, you can use any of these codes to login to your account.
                        </p>
                        <div style={{ columns: '2 auto', textAlign: 'center', marginBottom: 20 }}>
                            {codes.map((code, index) => (
                                <p className="description" key={index}>
                                    {code}
                                </p>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button className="gapstack-button" onClick={downloadTxtFile}>
                                Download Codes
                            </Button>
                        </div>
                    </div>
                )}
            </EnableTwoFactorAuthenticationWrapper>
        </Modal>
    );
};

export default EnableTwoFactorAuthentication;
