import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../../components/Layouts/Authenticated';

const BusinessViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <BusinessView {...props} />
    </AuthenticatedLayout>
);

const BusinessView = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Business" */),
    loading() {
        return (
            <div>
                <Loader />
            </div>
        );
    },
});

const VerifyBusiness = (props: any) => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <VerifyBusinessAccount {...props} />
    </AuthenticatedLayout>
);

const VerifyBusinessAccount = Loadable({
    loader: () => import('./kyc/KYCWrapper' /* webpackChunkName: "Business" */),
    loading() {
        return (
            <div>
                <Loader />
            </div>
        );
    },
});

const OnboardBusinessComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <OnboardBusiness {...props} />
    </AuthenticatedLayout>
);

const OnboardBusiness = Loadable({
    loader: () => import('./onboard/OnboardBusiness' /* webpackChunkName: "Business Onboard" */),
    loading() {
        return (
            <div>
                <Loader />
            </div>
        );
    },
});

export { BusinessViewComponent, BusinessView, VerifyBusiness, OnboardBusinessComponent };
