import React from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Divider, Col, Form, Input, Button as AntButton, notification, Button } from 'antd';
import { ArrowLeft, X } from 'react-feather';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import style from './styles';
import { goToRoute, ROUTES } from '../../../../../router/urls';
import { strategyData } from './data';
import addIcon from '../../../../../assets/Images/add-circle.svg';
import StrategyActions from './components/strategy-actions/index';
import getDuplicate from './getDuplicates';
import useStrategiesActions from './hooks/useStrategiesActions';
import { authenticatedRequest } from '../../../../../api';
import { collection_strategy_prefix } from '../../../../../apis/bank-products';

const AddNewStrategyWrapper = styled.div`
    ${style}
`;

const AddNewStrategy: React.FC = () => {
    const history = useHistory();
    const [formRef] = Form.useForm();
    const [errorKey, setErrorKey] = React.useState<number[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const user: any = useSelector<any>(({ auth }) => auth.user);

    const strategiesActionData = useStrategiesActions();

    const onFinish = React.useCallback(
        async (values) => {
            try {
                setLoading(true);
                values['createdBy'] = user?.firstName + ' ' + user?.lastName;
                await authenticatedRequest().post(collection_strategy_prefix, values);
                notification.success({
                    message: 'Success',
                    description: 'Collection Strategy added successfully.',
                });
                setLoading(false);
                history.goBack();
            } catch (e: any) {
                const resp = e?.response || {};
                console.warn(e, e.response);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
                setLoading(false);
            } finally {
            }
        },
        [history, user],
    );

    const onFormSubmit = () => formRef.submit();

    const changeHandler = (values) => {
        const duplication: number[] = getDuplicate(values);
        setErrorKey(duplication.slice(1));
    };

    return (
        <AddNewStrategyWrapper>
            <div className="container">
                <div>
                    <Row justify="center">
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                            <Row className="top-bar">
                                <ArrowLeft
                                    className="back"
                                    onClick={() => history.push(goToRoute(ROUTES.COLLECTION_STRATEGY))}
                                />
                                <span className="heading">New Strategy</span>
                                <X className="close" onClick={() => history.goBack()} />
                            </Row>
                            <Divider style={{ marginTop: '15px' }} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row justify="center">
                        <Col xs={{ span: 20 }} sm={{ span: 20 }} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 16 }}>
                            <Form onFinish={onFinish} name="strategy-form" form={formRef}>
                                <div className="strategy-container">
                                    {strategyData.map((item) => (
                                        <div key={item.id}>
                                            {item.type === 'text' && (
                                                <Row>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            marginBottom: 20,
                                                            borderBottom: '1px solid #F3F4F8',
                                                        }}
                                                    >
                                                        <Form.Item
                                                            label={item.name}
                                                            name={item.props}
                                                            className="label"
                                                            rules={[
                                                                {
                                                                    required: item.required,
                                                                    message: item.message,
                                                                },
                                                            ]}
                                                        >
                                                            <Input className="gapstack-input" name={item.props} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )}
                                            {item.type === 'textarea' && item.props === 'description' && (
                                                <Row>
                                                    <Col span={13} style={{ marginBottom: -10 }}>
                                                        <Form.Item
                                                            label={item.name}
                                                            name={item.props}
                                                            className="label"
                                                            rules={[
                                                                {
                                                                    required: item.required,
                                                                    message: item.message,
                                                                },
                                                            ]}
                                                        >
                                                            <Input.TextArea
                                                                className="gapstack-input"
                                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                                                name={item.props}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    ))}

                                    <Form.List
                                        name={'collectionStrategyActions'}
                                        initialValue={[{}]}
                                        rules={[
                                            {
                                                validator: (_, value) => {
                                                    changeHandler(Array.isArray(value) ? value : [value]);
                                                    return Promise.resolve();
                                                },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    <div className="strategyActions">
                                                        {fields.map((field, index) => {
                                                            return (
                                                                <StrategyActions
                                                                    form={formRef}
                                                                    key={field.key}
                                                                    index={index}
                                                                    actionsData={strategiesActionData}
                                                                    delRow={() => remove(field.name)}
                                                                    error={errorKey?.includes(index)}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                    <AntButton className="add-button" onClick={() => add()}>
                                                        <img src={addIcon} alt="add" className="plus-circle" />
                                                        <span className="add-tier">Add Action</span>
                                                    </AntButton>
                                                </>
                                            );
                                        }}
                                    </Form.List>
                                </div>
                            </Form>
                            <div className="footer-container">
                                <div className="footer">
                                    <Button className="cancel-button" onClick={() => formRef.resetFields()}>
                                        Cancel
                                    </Button>
                                    <Button
                                        className="save-button"
                                        onClick={onFormSubmit}
                                        loading={loading}
                                        disabled={errorKey.length > 0}
                                    >
                                        Complete
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </AddNewStrategyWrapper>
    );
};

export default AddNewStrategy;
