import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authenticatedRequest } from '../../../../api';
import { useSearchParam } from '../../../../router/routeHooks';
import Loader from '../../../../utils/Loader';
import { generate_token } from '../../apis';

const MessagesAuth = () => {
    const searchParam = useSearchParam();
    const authUser = useSelector((state: any) => state.auth?.user || {});

    console.log(searchParam);
    useEffect(() => {
        (async () => {
            try {
                await authenticatedRequest({
                    'x-user-id': authUser.userId,
                }).post(generate_token, {
                    code: searchParam.code,
                });
                const customEvent = new CustomEvent(`mail-auth-${authUser.userId}`, {
                    detail: {
                        success: true,
                    },
                });
                window.opener.dispatchEvent(customEvent);
            } catch (e) {
            } finally {
                window.close();
            }
        })();
    }, []);
    return <Loader logo={true} />;
};

export default MessagesAuth;
