import { findIndex } from 'lodash';
import { bank_accounts, collection_strategy_prefix, edit_collection_strategy } from '../../../../apis/bank-products';

enum AccountType {
    CAPITAL,
    COLLECTION,
}

const ModalColumnData = {
    editDetails: {
        title: 'Edit Collection Strategy Details',
        api: collection_strategy_prefix,
        responseMap: (value) => {
            try {
                return value;
            } catch (e) {
                return {};
            }
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Collection Strategy Name:',
                        type: 'text',
                        props: 'name',
                        required: true,
                        message: 'Please enter Strategy Name!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 2,
                        name: 'Collection Strategy Description:',
                        type: 'textarea',
                        props: 'description',
                        required: true,
                        message: 'Please enter Strategy Description!',
                    },
                ],
            },
        ],
    },
    editStrategy: {
        title: 'Edit Strategy Action',
        responseMap: (value, id) => {
            const strategies = value?.collectionStrategyActions || [];
            const index = findIndex(strategies, ['id', id]);
            try {
                return strategies[index];
            } catch (e) {
                return {};
            }
        },
        api: 'not_used_now',
        generateApi: (_, value) => {
            const id = value.id;
            return edit_collection_strategy(id);
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Bank Name:',
                        type: 'bank',
                        props: 'bankName',
                        required: true,
                        message: 'Please enter Bank Name!',
                    },
                    {
                        id: 2,
                        name: 'Account Number:',
                        type: 'text',
                        props: 'accountNumber',
                        required: true,
                        message: 'Please enter Account Number!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 3,
                        name: 'Account Name:',
                        type: 'text',
                        props: 'accountName',
                        required: true,
                        message: 'Please enter Account Name!',
                    },
                    {
                        id: 3,
                        name: 'Account Type:',
                        type: 'hidden',
                        props: 'accountType',
                        initialValue: AccountType.CAPITAL,
                    },
                ],
            },
        ],
    },
    editCapitalAccount: {
        title: 'Capital/Disbursement Account',
        api: bank_accounts,
        responseMap: (value, id) => {
            try {
                const data = value.capitalData || [];
                const index = findIndex(data, ['id', id]);
                return data[index];
            } catch (e) {
                return {};
            }
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Bank Name:',
                        type: 'bank',
                        props: 'bankName',
                        required: true,
                        message: 'Please enter Bank Name!',
                    },
                    {
                        id: 2,
                        name: 'Account Number:',
                        type: 'text',
                        props: 'accountNumber',
                        required: true,
                        message: 'Please enter Account Number!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 3,
                        name: 'Account Name:',
                        type: 'text',
                        props: 'accountName',
                        required: true,
                        message: 'Please enter Account Name!',
                    },
                ],
            },
        ],
    },
    editCollectionAccount: {
        title: 'Collection Account',
        api: bank_accounts,
        responseMap: (value, id) => {
            try {
                const data = value.collectionData || [];
                const index = findIndex(data, ['id', id]);
                return data[index];
            } catch (e) {
                return {};
            }
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Bank Name:',
                        type: 'bank',
                        props: 'bankName',
                        required: true,
                        message: 'Please enter Bank Name!',
                    },
                    {
                        id: 2,
                        name: 'Account Number:',
                        type: 'text',
                        props: 'accountNumber',
                        required: true,
                        message: 'Please enter Account Number!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 3,
                        name: 'Account Name:',
                        type: 'text',
                        props: 'accountName',
                        required: true,
                        message: 'Please enter Account Name!',
                    },
                ],
            },
        ],
    },
    addCapitalAccount: {
        title: 'Add Capital/Disbursement Account',
        method: 'POST',
        api: bank_accounts,
        modalProps: {
            okText: 'Add',
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Bank Name:',
                        type: 'bank',
                        props: 'bankName',
                        required: true,
                        message: 'Please enter Bank Name!',
                    },
                    {
                        id: 2,
                        name: 'Account Number:',
                        type: 'text',
                        props: 'accountNumber',
                        required: true,
                        message: 'Please enter Account Number!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 3,
                        name: 'Account Name:',
                        type: 'text',
                        props: 'accountName',
                        required: true,
                        message: 'Please enter Account Name!',
                    },
                    {
                        id: 3,
                        name: 'Account Type:',
                        type: 'hidden',
                        props: 'accountType',
                        initialValue: AccountType.CAPITAL,
                    },
                ],
            },
        ],
    },
    addCollectionAccount: {
        title: 'Add Collection Account',
        method: 'POST',
        api: bank_accounts,
        modalProps: {
            okText: 'Add',
        },
        data: [
            {
                id: '1',
                fields: [
                    {
                        id: 1,
                        name: 'Bank Name:',
                        type: 'bank',
                        props: 'bankName',
                        required: true,
                        message: 'Please enter Bank Name!',
                    },
                    {
                        id: 2,
                        name: 'Account Number:',
                        type: 'text',
                        props: 'accountNumber',
                        required: true,
                        message: 'Please enter Account Number!',
                    },
                ],
            },
            {
                id: '2',
                fields: [
                    {
                        id: 3,
                        name: 'Account Name:',
                        type: 'text',
                        props: 'accountName',
                        required: true,
                        message: 'Please enter Account Name!',
                    },
                    {
                        id: 3,
                        name: 'Account Type:',
                        type: 'hidden',
                        props: 'accountType',
                        initialValue: AccountType.COLLECTION,
                    },
                ],
            },
        ],
    },
};

export default ModalColumnData;
