import { Breadcrumb, Col } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { goToRoute, ROUTES } from '../../../../router/urls';
import PageHeader from '../../../../utils/PageHeader/index';
import style from './styles';

const SupplierGroupWrapper = styled.div`
    ${style}
`;

const SupplierGroups = () => {
    return (
        <SupplierGroupWrapper>
            <PageHeader>
                <Col span={12}>
                    <Breadcrumb separator="|">
                        <Breadcrumb.Item>
                            <Link to={goToRoute(ROUTES.BANK_SETTINGS)}>Settings</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={goToRoute(ROUTES.BANK_SETTINGS)}>Supplier Settings</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Supplier Groups</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </PageHeader>
        </SupplierGroupWrapper>
    );
};

export default SupplierGroups;
