import moment from 'moment';
import numeral from 'numeral';
import { titleCase } from '../../../../utils/TitleCase';
import { GUARANTEE_STATES } from '../../../../utils/enums';
import { LIGHT_THEME } from '../../../../config/theme';
import StatusTag from '../../../../utils/StatusTag';
import React from 'react';
import { Edit, Eye } from 'react-feather';
import AuthorizationWrapper from '../../../../Authorization/AuthorizationWrapper';
import { Divider } from 'antd';

const columns_list = (
    isBusinessContext: boolean,
    goToViewBonds?: (record) => void,
    goToEditBond?: (record) => void,
) => {
    const columns: any[] = [
        {
            key: '1',
            title: 'Application Date',
            dataIndex: 'createdAt',
            render: (val) => (val ? moment(val).format('DD/MM/YYYY') : '-'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            isBusinessContext: true,
            ellipsis: true,
        },
        {
            key: '2',
            title: 'Guarantee ID',
            dataIndex: 'guaranteeId',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            isBusinessContext: true,
            ellipsis: true,
        },
        {
            key: '4',
            title: 'Guarantee Type',
            dataIndex: 'guaranteeType',
            render: (val) => titleCase(val?.split('_')?.join(' ')),
            isBusinessContext: true,
            ellipsis: true,
        },
        {
            key: '10',
            title: 'Third Party Name',
            dataIndex: 'thirdPartyName',
            render: (val: string) => (val ? titleCase(val?.split('_')?.join(' ')) : '-'),
            ellipsis: true,
        },
        {
            key: '5',
            title: 'Procuring Entity',
            dataIndex: 'buyer',
            render: (buyer) => buyer?.companyName ?? '-',
            isBusinessContext: true,
            ellipsis: true,
        },
        {
            key: '6',
            title: 'Guarantee Amount',
            dataIndex: ['facility', 'amountRequired'],
            render: (value) => numeral(value).format('0,0.00'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            isBusinessContext: true,
            ellipsis: true,
        },

        {
            key: '7',
            title: 'Expiry Date',
            dataIndex: ['tender', 'expiryDate'],
            render: (expiryDate) => (expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : '-'),
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            isBusinessContext: true,
            ellipsis: true,
            // width: 150,
        },
        {
            key: '8',
            title: 'Status',
            dataIndex: 'status',
            // eslint-disable-next-line react/display-name
            render: (status) => {
                const color = [
                    GUARANTEE_STATES.WAITING_FOR_APPROVAL,
                    GUARANTEE_STATES.CHANGES_REQUIRED,
                    GUARANTEE_STATES.SENT_FOR_APPROVAL,
                ].includes(status)
                    ? '#FE9902'
                    : status === GUARANTEE_STATES.ISSUED
                    ? '#2CCC71'
                    : status === GUARANTEE_STATES.DECLINED || status === GUARANTEE_STATES.EXPIRED
                    ? '#E74C3C'
                    : '#6C7392';
                return <StatusTag value={titleCase(status, '_')} color={color} />;
            },
        },
        {
            key: '9',
            title: 'Action',
            dataIndex: 'status',
            width: 80,
            // eslint-disable-next-line react/display-name
            render: (status, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Eye
                                onClick={(e) => {
                                    e.stopPropagation();
                                    goToViewBonds?.(record);
                                }}
                                size="16"
                                style={{ color: '#6C7392' }}
                            />
                        </div>

                        {status?.toLowerCase() == 'draft' && isBusinessContext ? (
                            <AuthorizationWrapper restrictedRoles={['View', 'Analyst']} disabled>
                                <div>
                                    <Divider type="vertical" />
                                    <Edit
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            goToEditBond?.(record);
                                        }}
                                        size="14"
                                        className="edit-icon"
                                        style={{ color: '#1890ff' }}
                                    />
                                </div>
                            </AuthorizationWrapper>
                        ) : null}
                    </div>
                );
            },
        },
    ];

    if (!isBusinessContext) {
        columns.splice(2, 0, {
            key: '3',
            title: 'Business Name',
            dataIndex: 'businessName',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            isBusinessContext: false,
        });
    }

    return columns;
};

const summaryData = (summary) => {
    const obj: any = {};
    summary.map((val) => {
        obj[val?.type] = parseInt(val?.total);
    });
    const currency = process.env.REACT_APP_DEFAULT_CURRENCY || 'KES';
    const format = (val) => currency + ' ' + numeral(val || 0).format('0,0.00');

    return [
        { value: format(obj['Issued Bid Bonds']), description: 'Issued Bid Bonds' },
        { value: format(obj['Issued Performance Bonds']), description: 'Issued Performance Bonds' },
        { value: format(obj['Issued Advanced Payment Bonds']), description: 'Issued Advanced Payment Bonds' },
        { value: format(obj['Awaiting Payment Bonds']), description: 'Total Bonds Awaiting Payment' },
    ];
};

const filterOptions = [
    {
        name: 'Status',
        dataIndex: 'status',
        type: 'multiple',
        options: [
            {
                label: 'Issued',
                value: GUARANTEE_STATES.ISSUED,
            },
            {
                label: 'Declined',
                value: GUARANTEE_STATES.DECLINED,
            },
            {
                label: 'Draft',
                value: GUARANTEE_STATES.DRAFT,
            },
            {
                label: 'Upload Documents',
                value: GUARANTEE_STATES.UPLOAD_DOCUMENTS,
            },
            {
                label: 'Awaiting Payment',
                value: GUARANTEE_STATES.AWAITING_PAYMENT,
            },
            {
                label: 'Called',
                value: GUARANTEE_STATES.CALLED,
            },
            {
                label: 'Cancelled',
                value: GUARANTEE_STATES.CANCELLED,
            },
            {
                label: 'Expired',
                value: GUARANTEE_STATES.EXPIRED,
            },
        ],
    },
    {
        name: 'Type',
        dataIndex: 'guaranteeType',
        type: 'single',
        options: [
            {
                label: 'Bid Bond',
                value: 'BID_BOND',
            },
            {
                label: 'Advance Payment Bond',
                value: 'ADVANCED_PAYMENT_BOND',
            },
            {
                label: 'Performance Bond',
                value: 'PERFORMANCE_BOND',
            },
        ],
    },
    {
        name: 'Application Date',
        dataIndex: 'applicationDate',
        type: 'dateRange',
    },
];

const mapGuaranteeStatus = (status) => {
    switch (status) {
        case GUARANTEE_STATES.WAITING_FOR_APPROVAL:
            return {
                status: 'Waiting For Approval',
                color: LIGHT_THEME.ORANGE,
            };
        case GUARANTEE_STATES.CHANGES_REQUIRED:
            return {
                status: 'Changes Required',
                color: LIGHT_THEME.ORANGE,
            };
        case GUARANTEE_STATES.ISSUED:
            return {
                status: 'Issued',
                color: LIGHT_THEME.SUCCESS,
            };
        case GUARANTEE_STATES.EXPIRED:
            return {
                status: 'Expired',
                color: LIGHT_THEME.DECLINED,
            };
        case GUARANTEE_STATES.CANCELLED:
            return {
                status: 'Cancelled',
                color: LIGHT_THEME.APPROVED,
            };
        case GUARANTEE_STATES.CALLED:
            return {
                status: 'Called',
                color: LIGHT_THEME.APPROVED,
            };
        default:
            return {
                status: status,
                color: LIGHT_THEME.APPROVED,
            };
    }
};

export { columns_list, summaryData, filterOptions, mapGuaranteeStatus };
