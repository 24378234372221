import { prefix } from '../bank-products';
const financing_prefix = process.env.NODE_ENV === 'development' ? 'scf' : 'scf';
const invoices_prefix = process.env.NODE_ENV === 'development' ? 'sales-invoices' : 'sales-invoices';
const purchase_prefix = process.env.NODE_ENV === 'development' ? 'purchase-orders' : 'purchase-orders';
const erp_prefix = process.env.NODE_ENV === 'development' ? 'quotation-po-invoice' : 'quotation-po-invoice';

export const fetch_service_type = (fundingType) =>
    `${prefix}/bank-product/attach-product/service-type/for-business?fundingType=${fundingType}`;
export const fetch_duration__for_product = (fundingType, serviceType) =>
    `${prefix}/bank-product/duration/for-business?fundingType=${fundingType}&serviceType=${serviceType}`;
export const fetch_repayment_details = (serviceType, fundingType, loanAmount, durationUnit, duration) =>
    `${prefix}/loan-hub/repayment-details?fundingType=${fundingType}&serviceType=${serviceType}&loanAmount=${loanAmount}&durationUnit=${durationUnit}&duration=${duration}`;

export const fetch_purchase_financing = (currentPage, pageSize) =>
    `${financing_prefix}/business/Finance Purchases?page=${currentPage}&limit=${pageSize}`;
export const fetch_purchase_financing_summary = `${financing_prefix}/summary/Finance Purchases`;
export const import_loans_summary = (type) => `${financing_prefix}/summary/${type}`;
export const create_financing = `${financing_prefix}/funding/fund-my-purchase`;
export const get_single_financing = (id) => `${financing_prefix}/${id}`;
export const delete_single_financing = (id) => `${financing_prefix}/${id}`;
export const edit_single_financing = (id) => `${financing_prefix}/funding/fund-my-purchase/${id}`;
export const change_status_purchases = `${financing_prefix}/change-status/business`;
export const request_validation = (id) => `${financing_prefix}/resend/validation/${id}`;

export const search_documents_for_financing = (supplierId, value) => {
    if (value) {
        return `${erp_prefix}/search/buyer?supplierId=${supplierId}&shortId=${value}&page=1&limit=10&status=ACCEPTED,VALIDATED,APPROVED`;
    }
    return `${erp_prefix}/search/buyer?supplierId=${supplierId}&page=1&limit=10&status=ACCEPTED,VALIDATED,APPROVED`;
};

export const validate_document_for_scf = `${financing_prefix}/validate/document`;

// ===needs to be updated for post shipment financing======//
export const search_purchase_order_for_financing = (supplierId, value) =>
    `${purchase_prefix}/purchase-orders/search/${supplierId}?shortId=${value}`;
export const search_received_invoices_for_financing = (supplierId, value) => {
    if (value?.trim() == '') {
        return `${invoices_prefix}/received-invoices?supplierId=${supplierId}&page=0&limit=5`;
    }
    return `${invoices_prefix}/received-invoices?supplierId=${supplierId}&invoiceNumber=${value}&page=0&limit=5`;
};

//Bank Routes
export const fetch_purchase_financing_bank = (type, currentPage, pageSize) =>
    `${financing_prefix}/bank/${type}?page=${currentPage}&limit=${pageSize}`;
export const fetch_supplier_validation_requests = (type, currentPage, pageSize) =>
    `${financing_prefix}/bank/validation-pending/${type}?page=${currentPage}&limit=${pageSize}`;
export const fetch_single_supplier_validation_request = (id) => `${financing_prefix}/bank/${id}`;
