import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .ant-modal-header {
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-bottom: none !important;
        border-radius: 2px 2px 0 0;
    }

    .qr-code-container {
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        padding: 20px 0px;
    }

    .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        color: ${(props: any) => props.theme[COLORS.SECONDARY]};
    }
`;

export default style;
