import { Col } from 'antd';
import React, { FunctionComponent } from 'react';
import { CheckCircle } from 'react-feather';
import { IoBusiness, IoBriefcase, IoDocumentText, IoClipboard, IoCheckmarkDoneCircle } from 'react-icons/io5';
import { Constant } from '../../../config';

export enum KYCStatuses {
    BUSINESS = 'Business Profile',
    DOCUMENTS = 'Business Documents',
    CONFIRM_DETAILS = 'Confirm Business Details',
    TERMS_AND_CONDITIONS = 'Terms & Conditions',
    VERIFICATION_PENDING = 'Financing Verification & Approval',
}
export const SortedKYCStatuses: KYCStatuses[] = [
    KYCStatuses.BUSINESS,
    KYCStatuses.DOCUMENTS,
    KYCStatuses.CONFIRM_DETAILS,
    KYCStatuses.TERMS_AND_CONDITIONS,
    KYCStatuses.VERIFICATION_PENDING,
];

const KYCObject = (isTermsRequired, isAccountPresent) => {
    const tempObj = isTermsRequired
        ? {
              [KYCStatuses.TERMS_AND_CONDITIONS]: {
                  index: 3,
                  name: KYCStatuses.TERMS_AND_CONDITIONS,
                  icon: <IoDocumentText size={16} style={{ marginRight: 10 }} />,
              },
          }
        : {};
    return !isAccountPresent
        ? {
              [KYCStatuses.BUSINESS]: {
                  index: 0,
                  name: KYCStatuses.BUSINESS,
                  icon: <IoBusiness size={16} style={{ marginRight: 10 }} />,
              },
              [KYCStatuses.DOCUMENTS]: {
                  index: 1,
                  name: KYCStatuses.DOCUMENTS,
                  icon: <IoBriefcase size={16} style={{ marginRight: 10 }} />,
              },
              [KYCStatuses.CONFIRM_DETAILS]: {
                  index: 2,
                  name: KYCStatuses.CONFIRM_DETAILS,
                  icon: <IoClipboard size={16} style={{ marginRight: 10 }} />,
              },
              ...tempObj,
              [KYCStatuses.VERIFICATION_PENDING]: {
                  index: 4,
                  name: KYCStatuses.VERIFICATION_PENDING,
                  icon: <IoCheckmarkDoneCircle size={16} style={{ marginRight: 10 }} />,
              },
          }
        : {
              [KYCStatuses.BUSINESS]: {
                  index: 0,
                  name: KYCStatuses.BUSINESS,
                  icon: <IoBusiness size={16} style={{ marginRight: 10 }} />,
              },
              ...tempObj,
              [KYCStatuses.VERIFICATION_PENDING]: {
                  index: 4,
                  name: KYCStatuses.VERIFICATION_PENDING,
                  icon: <IoCheckmarkDoneCircle size={16} style={{ marginRight: 10 }} />,
              },
          };
};
export type OptionsFlags<Type> = {
    [Property in keyof Type]: boolean;
};

interface KYCSideBarProps {
    status: KYCStatuses;
    setStatus: (status: KYCStatuses) => void;
    username: string;
    completedStatuses: any;
    isAccountPresent?: boolean;
    isTermsRequired: boolean;
}

const KYCSideBar: FunctionComponent<KYCSideBarProps> = ({
    status,
    setStatus,
    username,
    completedStatuses,
    isAccountPresent = false,
    isTermsRequired,
}: KYCSideBarProps) => {
    const activeIndex = KYCObject(isTermsRequired, isAccountPresent)[status]?.index;
    return (
        <Col md={{ span: 8 }}>
            <div style={{ position: 'fixed', width: '25%' }}>
                {activeIndex == 0 || isAccountPresent ? (
                    <>
                        <h3>Hi {username},</h3>
                        <h3>Welcome to {Constant.title}!</h3>
                        <p className="sub-heading">
                            You can now automate all your business financial transactions. Create purchase orders,
                            invoices and much more. Access funding for your business by completing the registration
                            process.
                        </p>
                    </>
                ) : (
                    <>
                        <h3>Great Progress {username}!</h3>
                        <h3>Just add a few more documents and you’re in.</h3>
                    </>
                )}
                <div className="complete-progress-container">
                    {Object.values<any>(KYCObject(isTermsRequired, isAccountPresent)).map((obj) => (
                        <p
                            key={obj.index}
                            onClick={() => {
                                if (status == KYCStatuses.VERIFICATION_PENDING) return;
                                (completedStatuses[obj.name] == 'COMPLETE' ||
                                    completedStatuses[obj.name] == 'ACTIVE') &&
                                    setStatus(obj.name);
                            }}
                            className={
                                obj.index == activeIndex
                                    ? 'item active'
                                    : completedStatuses[obj.name] == 'COMPLETE'
                                    ? 'item complete'
                                    : 'item'
                            }
                        >
                            {obj.icon}
                            {obj.name}
                            {completedStatuses[obj.name] == 'COMPLETE' ? (
                                <CheckCircle size={16} style={{ marginLeft: 10 }} />
                            ) : null}
                        </p>
                    ))}
                </div>
            </div>
        </Col>
    );
};
export default KYCSideBar;
