import BusinessDetails from './BusinessDetails';
import CompanyRegistration from './CompanyRegistration';
import PhysicalAddress from './PhysicalAddress';
import ConfirmDirectors from './ConfirmDirectors';
import AboutBusiness from './AboutBusiness';
import ContactInformation from './ContactInformation';
import PaymentsInformation from './PaymentInformation';
export {
    BusinessDetails,
    CompanyRegistration,
    PhysicalAddress,
    ConfirmDirectors,
    AboutBusiness,
    ContactInformation,
    PaymentsInformation,
};

export const loaderData = [
    { title: 'Business Details', rows: 3 },
    { title: 'Contact Information', rows: 4 },
    { title: 'Company Registration', rows: 1 },
    { title: 'Physical Address', rows: 2 },
    { title: 'Confirm Directors', rows: 5 },
    { title: 'About Business', rows: 1 },
];
