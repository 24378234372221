import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .submit-docs-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 25px;
        .docs-heading {
            font-size: 18px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            font-weight: bold;
        }
        .docs-image {
            width: 40%;
            height: auto;
            margin-top: 15px;
        }
        .document-details {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 10px;
            .document-text {
                font-size: 14px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
    }
`;

export default style();
