import { combineReducers } from 'redux';
import registerReducer from '../../components/authentication/sign-up/store/reducer';
import authReducer from '../../components/authentication/sign-in/store/reducer';
import businessAccountReducer from '../../components/business-accounts/store/reducer';
import connectionsReducer from '../../components/ecosystem/store/reducers';
import authenticatedLayoutReducer from '../../components/Layouts/Authenticated/store/reducer';
import dashboardReducer from '../../components/dashboard/store/reducer';
import accountsReducer from '../../components/accounts/store/reducer';

export const rootReducer = combineReducers({
    register: registerReducer,
    auth: authReducer,
    businessAccount: businessAccountReducer,
    connections: connectionsReducer,
    dashboard: dashboardReducer,
    authenticatedLayoutReducer: authenticatedLayoutReducer,
    accounts: accountsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
