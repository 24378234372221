import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    size: number;
    style?: any;
}

const LoadingIcon = ({ size, style }: Props) => (
    <div
        style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
            ...style,
        }}
    >
        <LoadingOutlined style={{ fontSize: size, color: '#7C60FF' }} spin />
    </div>
);

export default LoadingIcon;
