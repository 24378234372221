import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = ({ showDropDown }) => css`
    position: relative;
    .ant-collapse-ghost > .ant-collapse-item {
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 0px;
    }

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 12px 0px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
        background-color: #f4f8f9;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 16px 0px;
        margin: 0px 15px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        font-size: 14px;
        border-radius: 0px;
        margin: 0px 15px;
    }

    .ant-collapse.ant-collapse-icon-position-right.ant-collapse-ghost.accordion {
        padding: 15px 0px !important;
    }

    .filter-button {
        height: 35px;
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border: 0.5px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-width: 96px;
        cursor: pointer;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        font-weight: 400;
        position: relative;

        &:hover {
            border: 0.5px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        }
        img {
            width: 15px;
            height: 16px;
            margin-right: 10px;
        }
        .filter-number {
            position: absolute;
            top: -10px;
            right: -7px;
            padding: 0px 5px;
            background: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
            border-radius: 3px;
        }
    }
    .table-filter-dropdown-container {
        flex: 1;
        flex-direction: column;
        display: ${showDropDown ? 'flex' : 'none'};
        border-radius: 5px;
        position: absolute;
        top: 5px;
        right: 0;
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        min-width: 330px;
        min-height: 150px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        z-index: 10;
        .container {
            display: flex;
            flex-direction: column;
            .span {
                min-width: 40px;
            }
            .option-container {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                .add {
                    color: #777c93;
                }
                .tag {
                    border-radius: 5px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    background: ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
                    border: 0px;
                    color: #ffffff;
                }
                .check-box {
                    margin-left: 0px;
                }
                .autocomp {
                    box-shadow: none;
                    line-height: 43px;
                    position: relative;
                    width: 100%;
                    .ant-select-selector {
                        height: 43px;
                        box-shadow: none;
                        outline: none;
                        padding-left: 10px;
                    }
                    .ant-select-selection-placeholder {
                        height: 43px;
                    }
                    .ant-select-selection-search {
                        left: 10px;
                    }
                }
                .select {
                    width: 100%;
                    min-height: 45px;
                    height: auto;
                    border-radius: 5px;
                    .ant-select-selector {
                        min-height: 50px !important;
                        background: #fafafa !important;
                        border-radius: 5px !important;
                    }

                    .ant-select-selection-item {
                        display: flex;
                        align-items: center;
                        row-gap: 5px;
                    }
                    .ant-select-arrow {
                        top: 50% !important;
                    }
                    .select-icon {
                        color: ${(props) => props.theme[COLORS.ICONS]};
                        width: 15px;
                    }
                    .ant-select-selection-search-input {
                        height: 45px !important;
                        font-size: 13px;
                        line-height: 45px;
                    }
                    .ant-select-selection-overflow {
                        align-items: center;
                        row-gap: 5px;
                    }
                    .ant-select-selection-item {
                        height: 35px;
                        background: rgba(30, 135, 240, 0.2);
                        border: 1px solid ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                        border-radius: 5px;
                        padding: 5px 10px;
                        margin-top: -2px;
                        margin-inline-end: 6px;
                        .ant-select-selection-item-content {
                            color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                            margin-right: 5px;
                        }
                        .ant-select-selection-item-remove {
                            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
                        }
                    }
                }

                .single {
                    .ant-select-selection-item {
                        height: 45px;
                        background: none;
                        border: none;
                        border-radius: 5px;
                        padding: 0px;
                        margin-top: 0;
                        .ant-select-selection-item-content {
                            color: ${(props) => props.theme[COLORS.SECOND_BUTTON]};
                            margin-right: 5px;
                        }
                        .ant-select-selection-item-remove {
                            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
                        }
                    }
                }
            }
            .action-container {
                display: flex;
                flex: 1;
                flex-direction: row;
                justify-content: space-between;
                background: #f4f8f9;
                padding: 15px;
            }
        }
        .business-container {
            margin-top: 10px;
            .business-item {
                padding: 4px 0px;
                display: flex;
                align-items: center;
                .name {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                button {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                }
            }
            .active {
                background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
                border-radius: 3px;
                margin: 0px -5px;
                padding: 5px;
                display: flex;
                justify-content: space-between;
                .tick {
                    margin-right: 10px;
                }
            }
        }

        .account-container {
            padding: 4px 0px;
            .title {
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            .user-email {
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: ${(props: any) => props.theme[COLORS.EDIT_BUTTON]};
            }
            button {
                display: flex;
                align-items: center;
                font-size: 13px;
            }
        }

        .link-button {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            justify-content: center;
            img {
                margin-right: 10px;
            }
        }

        .terms-privacy {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .clear-button {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            box-sizing: border-box;
            border: 0.6px solid rgba(0, 0, 0, 0.15);
            border-radius: 5px;
            height: 35px;
            padding: 0px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                font-weight: 600;
                font-size: 13px;
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            }
            &:hover {
                outline: none;
                border: 0.6px solid rgba(0, 0, 0, 0.2);
            }
        }
        .ant-collapse-item:last-child {
            border: none !important;
        }
    }
`;

export { style };
