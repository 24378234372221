import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
 margin-top: 15px;
    .account-settings {
        .ant-card-extra {
            padding: 20px 0 16px 0 !important;
        }
        
    .status {
        min-width: 90px;
    }
    .edit-icon {
        left: -3px;
        margin-right: 5px;
        cursor: pointer;
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
    }
`;

export default style;
