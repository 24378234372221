import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, notification } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Trash2 } from 'react-feather';
import { unionBy } from 'lodash';

import MessageInput from './MessageInput';
import { send_new_mail } from '../../apis';
import { authenticatedRequest } from '../../../../api';

interface IProps {
    value?: Array<any>;
    onChange?: (value: Array<any>) => void;
}

const MessageWithAttachment = ({ value = [], onChange }: IProps) => {
    const _onMediaInsert = (args: Array<any>) => {
        const newArgs = args.map((file) => {
            return {
                ...file,
                filename: file.name,
                encoding: 'base64',
                content: file.url.split(',')[1],
            };
        });
        const newVal = unionBy(value, newArgs, 'id');
        onChange?.(newVal);
        return [];
    };

    function base64ToArrayBuffer(_base64Str: string) {
        const binaryString = atob(_base64Str);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    const _onMediaDelete = (id: string) => {
        onChange?.(value?.filter((file) => file.id !== id) || []);
    };

    const _onPreview = (file: any) => {
        const [content, fileUrl] = file.url.split(',');
        const byte = base64ToArrayBuffer(fileUrl);
        const blob = new Blob([byte], { type: content.split(':')[1] });
        window.open(URL.createObjectURL(blob), '_blank');
    };

    return (
        <>
            <Form.Item name={'message'}>
                <MessageInput
                    hooks={{
                        'insert-medias': _onMediaInsert,
                    }}
                />
            </Form.Item>

            <div className={'attachment-list'}>
                <TransitionGroup>
                    {value?.map((file) => (
                        <CSSTransition key={file.id} classNames={'item'} timeout={100}>
                            <div className={'attachment'}>
                                <div className={'link'} onClick={() => _onPreview(file)}>
                                    {file.name}
                                </div>
                                <Trash2 size={16} className={'delete'} onClick={() => _onMediaDelete(file.id)} />
                            </div>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        </>
    );
};

const ComposeMessageForm = ({ onSuccess, onCancel }: any) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const authUser = useSelector((state: any) => state.auth?.user || {});

    const onFinish = useCallback(
        async (values) => {
            setLoading(true);
            try {
                const message = JSON.parse(values.message || {});
                const data = {
                    to: values.to,
                    subject: values.subject,
                    text: message.text,
                    html: message.html,
                    attachments: values.attachments?.map((file) => ({
                        filename: file.filename,
                        encoding: file.encoding,
                        content: file.content,
                    })),
                };
                await authenticatedRequest({
                    'x-user-id': authUser.userId,
                }).post(send_new_mail, data);
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'Email sent successfully!',
                });
                onSuccess?.();
            } catch (err) {
                const resp = err?.response;
                setLoading(false);
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Unexpected Error Occurred',
                });
            }
        },
        [authUser.userId],
    );

    return (
        <Form form={form} onFinish={onFinish}>
            <Form.Item
                name={'to'}
                rules={[
                    {
                        required: true,
                        type: 'email',
                        message: 'recipient email required',
                    },
                ]}
            >
                <Input className="gapstack-input" placeholder={'To'} />
            </Form.Item>

            <Form.Item
                name={'subject'}
                rules={[
                    {
                        required: true,
                        message: 'email subject required',
                    },
                ]}
            >
                <Input className="gapstack-input" autoComplete="off" placeholder={'Subject'} />
            </Form.Item>

            <Form.Item name={'attachments'}>
                <MessageWithAttachment />
            </Form.Item>

            <div className={'action-button'}>
                <Button type="link">Save as draft</Button>
                <div className={'collection'}>
                    <Button className="cancel-button" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button loading={loading} type="primary" htmlType={'submit'} className="compose-button">
                        Compose
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default ComposeMessageForm;
