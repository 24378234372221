const prefix = process.env.NODE_ENV === 'development' ? 'guarantee' : 'guarantee';

import { prefix as bank_product_prefix } from '../bank-products';

export const get_invoices = (currentPage, pageSize, sortBy, sortDirection) =>
    `${prefix}/guarantee?page=${currentPage}&limit=${pageSize}&sortBy=${sortBy}&sortDirection=${sortDirection}`;

export const get_update_guarantee = `${prefix}/guarantee`;
export const bank_update_guarantee = `${prefix}/guarantee/as-bank-user`;
export const bank_patch_guarantee = `${prefix}/guarantee/as-bank-user`;
export const get_all_guarantees = `${prefix}/bank`;
export const get_guarantee_summary = `${prefix}/guarantee/summary`;
export const get_all_guarantees_summary = `${prefix}/bank/summary`;
export const change_guarantee = (id) => `${prefix}/guarantee/change/${id}`;

export const change_guarantee_to_draft = (id) => `${prefix}/guarantee/change-status-to-draft/${id}`;

export const fetch_repayment_details_for_bonds = (amount, fundingType, validity) =>
    `${bank_product_prefix}/loan-hub/repayment-details/local-trade-finance?loanAmount=${amount}&fundingType=${fundingType}${
        validity ? `&validity=${validity}` : ''
    }`;

export const fetch_repayment_details_for_express_bonds = (
    amount,
    fundingType,
    validity,
    allowToExceedTheLimit = false,
) => {
    if (amount == 0) {
        return `${bank_product_prefix}/loan-hub/repayment-details/express-local-trade-finance?fundingType=${fundingType}`;
    }
    if (allowToExceedTheLimit) {
        return `${bank_product_prefix}/loan-hub/repayment-details/express-local-trade-finance?loanAmount=${amount}&duration=${validity}&fundingType=${fundingType}&allowToExceedTheLimit=true`;
    }

    return `${bank_product_prefix}/loan-hub/repayment-details/express-local-trade-finance?loanAmount=${amount}&duration=${validity}&fundingType=${fundingType}`;
};
// export const get_buyers = `${prefix}/bank/buyer`;

export const get_buyers = `${prefix}/guarantee/buyer`;
export const get_buyers_unauth = `${prefix}/guarantee/valid-buyer`;

export const create_procuring_entity = `${prefix}/guarantee/buyer`;
export const get_allowed_documents = (id) => `${prefix}/guarantee/allowed-documents/${id}`;
export const get_fee_type = (guaranteeType) => `${prefix}/guarantee/fee-types?guaranteeType=${guaranteeType}`;
export const upload_guarantee_document = `${prefix}/guarantee/document`;

export const get_assessment = (id) => `${prefix}/bank/assessment/${id}`;
export const create_assessment = `${prefix}/bank/assessment`;
export const create_assessment_bidbond = `${prefix}/bank/assessment/bidbond`;
export const decline_assessment = (id: string) => `${prefix}/bank/decline/guarantee/${id}`;
export const create_issuance = `${prefix}/bank/issuance`;
export const get_condition_precedent = `${prefix}/bank/condition-precedent`;

export const get_insurance_partner = `${prefix}/bank/insurance-partner`;
export const bank_get_buyers = `${prefix}/bank/buyers`;
export const validate_buyer = (id) => `${prefix}/bank/buyer/${id}`;
export const deactivate_buyer = (id) => `${prefix}/bank/deactivate/${id}`;
export const reactivate_buyer = (id) => `${prefix}/bank/reactivate/${id}`;
export const verify_bond = (bondNumber) => `${prefix}/guarantee/bond/${bondNumber}`;
export const cancel_bond = (bondNumber) => `${prefix}/guarantee/cancel-guarantee/${bondNumber}`;
export const call_bond = (bondNumber) => `${prefix}/bank/call-guarantee/${bondNumber}`;
export const generate_bond_pdf = (id, templateId) => `${prefix}/guarantee/generate-pdf/${id}/template/${templateId}`;
export const get_bond_samples = `${prefix}/guarantee/all/templates`;
export const get_bond_html_sample = (id, templateId) =>
    `${prefix}/guarantee/generate-html/${id}/template/${templateId}`;
export const get_all_bond_templates = `${prefix}/template/all`;
export const create_bond_template = `${prefix}/template/create`;
export const get_bond_template = (id) => `${prefix}/template/single/${id}`;
export const update_bond_template = (id) => `${prefix}/template/${id}`;
export const delete_bond_template = (id) => `${prefix}/template/${id}`;
export const publish_bond_template = (id) => `${prefix}/template/publish/${id}`;
export const template_placeholders = `${prefix}/template/placeholders`;
export const set_template = (id, templateId) => `${prefix}/guarantee/set/${id}/template/${templateId}`;
export const make_guarantee_payment_via_account = (id) => `${prefix}/guarantee/bank-payment/${id}`;
export const get_bond_validity_periods = `${prefix}/guarantee/validity/periods`;
export const get_product_limit_express_bonds = (fundingType) =>
    `${bank_product_prefix}/loan-hub/ltf-product-limit?fundingType=${fundingType}`;
// export const return_invoice_for_changes = (id) => `${prefix}/guarantee/return-for-changes/${id}`;
// export const approve_invoice = (id) => `${prefix}/guarantee/approve/${id}`;
// export const send_invoice_to_buyer = (id) => `${prefix}/guarantee/send-to-buyer/${id}`;
// export const send_invoice_via_email = (id) => `${prefix}/guarantee/send/custom/email/${id}`;
// export const validate_received_invoice = (id) => `${prefix}/guarantee/validate/${id}`;
// export const get_received_invoices = (currentPage, pageSize) =>
//     `${prefix}/guarantee/buyer/guarantee?page=${currentPage}&limit=${pageSize}`;
// export const upload_received_invoice = `${prefix}guarantee/upload`;
