import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Divider, Row, Col, Form, Input, Button } from 'antd';
import UnauthenticatedLayout from '../../Layouts/Unauthenticated';
import help from '../../../assets/Images/help.svg';
import { Link } from 'react-router-dom';
import style from './styles';
import { RefreshCw } from 'react-feather';
import { appIcon } from '../../../config/appIcon';
const BankAccConfirmationWrapper = styled.div`
    ${style}
`;

const BankAccountConfirmation = () => {
    const history = useHistory();

    const onFinish = () => {
        history.push('/business/account/complete/registration');
    };

    // const onFinishFailed = () => {};
    return (
        <UnauthenticatedLayout>
            <Row>
                <Col
                    xs={{ span: 20, offset: 4 }}
                    sm={{ span: 20, offset: 4 }}
                    md={{ span: 20, offset: 4 }}
                    lg={{ span: 9, offset: 8 }}
                    xl={{ span: 9, offset: 8 }}
                >
                    <BankAccConfirmationWrapper>
                        <Row className="logo-container">
                            <img className="logo" src={appIcon} alt="Logo" />
                        </Row>

                        <div className="container">
                            <Row style={{ margin: 'auto' }}>
                                <h3 className="heading">Account Confirmation</h3>
                            </Row>
                            <Row style={{ margin: 'auto' }}>
                                <p className="sub-heading">Please enter the the 6 digit code sent to *********815</p>
                            </Row>
                            <Row>
                                <Form
                                    name="sign-in-form"
                                    layout="vertical"
                                    onFinish={onFinish}
                                    style={{ width: '100%' }}
                                >
                                    <Row gutter={20} style={{ padding: '0px 40px' }}>
                                        <Col span={24} style={{ marginTop: '10px' }}>
                                            <Form.Item
                                                label="Enter code"
                                                name="code"
                                                rules={[{ required: true, message: 'Please input Code!' }]}
                                            >
                                                <Input className="gapstack-input" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Divider style={{ borderTop: '1.5px solid #e5e5e5', margin: 0 }} />

                                    <Row className="button-container">
                                        <Col span={12} className="left-button-container">
                                            {/*<Button type="link" className="button-link">*/}
                                            {/*    Skip*/}
                                            {/*</Button>*/}
                                        </Col>
                                        <Col span={12} className="right-button-container">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="gapstack-button">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                        </div>
                        <Row>
                            <div className="resend-code">
                                <RefreshCw className="refresh" />
                                Resend Code
                            </div>
                        </Row>
                    </BankAccConfirmationWrapper>
                </Col>
            </Row>

            <Link to="/">
                <Button
                    type="primary"
                    style={{
                        borderRadius: '22.5px',
                        padding: '20px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '50px',
                        right: '50px',
                    }}
                >
                    <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                    Help
                </Button>
            </Link>
        </UnauthenticatedLayout>
    );
};

export default BankAccountConfirmation;
