import { Button, Card, Col, FormInstance, notification, Radio, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import DownloadFile from '../../../../utils/DownloadFile';
import FormUploads from '../../../../utils/FormUploads';
import SuccessModal from '../../../../utils/SuccessModal';
import {
    complete_existing_customer,
    get_documents_business_profile,
    mobile_indemnity,
    submit_pick_up_information,
    submit_terms,
    upload_documents_business_profile,
} from '../../../../apis/business-accounts';
import { KYCStatuses } from '../../helpers';
import { DocumentPageType, DocumentType, DocumentNameAndType } from '../business-documents/types';
import PickUpModal from './PickUpModal';
import style from './styles';
import { useParams } from 'react-router';
import { getUser, Users } from '../../../../config';
import { get_terms_for_business } from '../../../../apis/business-accounts/kyc_settings';
import { LoadingOutlined } from '@ant-design/icons';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
import { CardLoader } from '../../../../utils/Loader';

const TermsWrappper = styled.div`
    ${style}
`;

interface TermsProps {
    form: FormInstance;
    changeStep: (value: KYCStatuses) => void;
    activeData: any;
    isAccountPresent?: boolean;
    cardLoading: boolean;
}

const TermsAndConditions: FunctionComponent<TermsProps> = ({
    changeStep,
    activeData,
    form,
    isAccountPresent,
    cardLoading,
}: TermsProps) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [documents, setDocuments] = useState<any>({});
    const [uploadDocs, setUploadDocs] = useState<any[]>([]);
    const [pickUp, setPickUp] = useState<any>();
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const [isPhysicalRequired, setIsPhysicalRequired] = useState<boolean>(false);

    const disabled = activeData?.status == BusinessStatuses.OnHold;

    useEffect(() => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .get(get_documents_business_profile)
            .then((res) => res.data)
            .then((res) => {
                if (Array.isArray(res)) {
                    const ob = {};
                    res.map((val) => {
                        ob[val.documentName] = val;
                    });
                    setDocuments({ ...ob });
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    }, []);

    const catchErr = (err) => {
        const resp = err?.response?.data ?? {};
        let backErr;
        if (resp?.errors && Array.isArray(resp?.errors) && resp?.errors?.length > 0 && resp?.errors?.[0]) {
            backErr = resp?.errors?.[0]?.key + ' ' + resp?.errors?.[0]?.message;
        }
        notification.error({
            message: 'Error',
            description: backErr || resp?.message || 'Oops! An unexpected error occurred.',
        });
    };

    const uploadFilesToBusinessAccount = (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType,
        pageType: DocumentPageType = DocumentPageType.STANDARD,
        fileId: string,
        physicalDocumentRequired: boolean,
    ) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(upload_documents_business_profile, {
                documentId,
                url,
                documentName,
                pageType,
                fileId,
                documentLabel: physicalDocumentRequired ? 'TermsAndConditions' : undefined,
            })
            .then((res) => res.data)
            .then(() => {
                const temp_docs = { ...documents };
                if (temp_docs[documentName]) {
                    temp_docs[documentName]['reasonForReturn'] = undefined;
                }
                setDocuments({ ...temp_docs });
            })
            .catch((err) => catchErr(err));
    };

    const onAccountSubmit = () => {
        setLoading(true);
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(complete_existing_customer)
            .then(() => {
                setLoading(false);
                setShowSuccess(true);
            })
            .catch((err) => {
                setLoading(false);
                catchErr(err);
            });
    };

    const onComplete = () => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_terms, {})
            .then(() => true)
            .catch((err) => catchErr(err));
    };

    const onSelfDrop = () => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        setPickUp(2);
        authenticatedRequest(isBank ? businessHeader : {})
            .post(submit_pick_up_information, { dropOff: true })
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'The bank will be expecting the documents',
                });
            })
            .catch((err) => {
                setPickUp(undefined);
                catchErr(err);
            });
    };
    const getErr = (documentName) => {
        return documents[documentName]?.reasonForReturn;
    };

    const getTermsAndConditionDocs = () => {
        if (!activeData?.businessType?.name) {
            return;
        }
        setInitialLoading(true);
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .get(get_terms_for_business(activeData?.businessType?.name))
            .then((res) => res.data)
            .then((res) => {
                setInitialLoading(false);
                if (Array.isArray(res)) {
                    setUploadDocs(res);
                    let tempIsPhysical = false;
                    res.map((val) => {
                        if (val?.physicalDocumentRequired == 'Yes' && !tempIsPhysical) {
                            tempIsPhysical = true;
                        }
                    });
                    setIsPhysicalRequired(tempIsPhysical);
                }
            })
            .catch((err) => {
                setInitialLoading(false);
                catchErr(err);
            });
    };

    const submitMobileIndemnity = async () => {
        setLoading(true);
        const values = form.getFieldsValue();
        const resp = await authenticatedRequest()
            .post(mobile_indemnity, { ussdPhone: values?.countryCode + values?.phone })
            .then((res) => res.data)
            .then(() => {
                setLoading(false);
                return true;
            })
            .catch((err) => {
                setLoading(false);
                catchErr(err);
                return false;
            });
        return resp;
    };

    useEffect(() => {
        getTermsAndConditionDocs();
        setPickUp(activeData?.documentPickUp ? (activeData?.documentPickUp?.dropOff ? 2 : 1) : undefined);
    }, [activeData]);

    return (
        <TermsWrappper>
            {cardLoading || initialLoading ? (
                <CardLoader rows={3} title="Terms & Conditions" loading={cardLoading || initialLoading} fullRow />
            ) : (
                <Card className="card-wrapper" title="Terms & Conditions" style={{ paddingBottom: 10 }}>
                    {initialLoading ? (
                        <LoadingOutlined style={{ color: '#7C60FF' }} size={60} spin />
                    ) : (
                        <>
                            <Row gutter={20} style={{ marginBottom: 50 }}>
                                <Col span={12}>
                                    <PhoneNumber
                                        countryFieldName={'countryCode'}
                                        inputFieldName={'phone'}
                                        formItemLabel="Enter mobile number to link to USSD & App."
                                    />
                                </Col>
                            </Row>
                            {uploadDocs.map((document, index) => (
                                <div key={index}>
                                    <div className="info">{`${index + 1}) ${document?.name}`}</div>
                                    <DownloadFile
                                        name={document?.name}
                                        downloadFileLink={document?.documentURL}
                                        readOnly
                                    />
                                    <Col span={24} style={{ padding: 0 }}>
                                        <FormUploads
                                            label={`${document?.description} ${document.isRequired ? '*' : ''}`}
                                            formItemName={document?.name}
                                            documentConfig={{
                                                documentName: document?.name,
                                                documentType: DocumentType.STANDARD,
                                                pageType: DocumentPageType.STANDARD,
                                            }}
                                            getResponse={(response) => {
                                                uploadFilesToBusinessAccount(
                                                    response?.metadata?.id || response?.documentId,
                                                    response?.path || response?.downloadURl,
                                                    document?.name,
                                                    DocumentPageType.STANDARD,
                                                    response?.metadata?.id || response?.fileId,
                                                    document?.physicalDocumentRequired == 'Yes',
                                                );
                                            }}
                                            initialValue={documents[document?.name]?.['url']}
                                            err={getErr(document?.name)}
                                            validateStatus={getErr(document?.name) ? 'error' : 'success'}
                                            required={document?.isRequired ?? false}
                                            disabled={disabled}
                                        />
                                    </Col>
                                </div>
                            ))}
                            {isPhysicalRequired ? (
                                <>
                                    <div className="info">
                                        We would need the physical copies of the following documents:
                                    </div>
                                    {uploadDocs
                                        .filter((val) => val?.physicalDocumentRequired == 'Yes')
                                        .map((document, index) => (
                                            <div className="info" key={index}>{`${index + 1}) ${document?.name}`}</div>
                                        ))}
                                    <div className="info"> What’s the best way we can get them?</div>
                                    <Radio.Group value={pickUp} disabled={disabled}>
                                        <Radio onClick={() => setShowModal(true)} value={1}>
                                            Pick them from me
                                        </Radio>
                                        <Radio
                                            onClick={() => {
                                                onSelfDrop();
                                            }}
                                            value={2}
                                            style={{ display: 'block' }}
                                        >
                                            I will drop them off
                                        </Radio>
                                    </Radio.Group>
                                </>
                            ) : null}
                        </>
                    )}
                </Card>
            )}
            {showModal && (
                <PickUpModal showModal={showModal} hideModal={setShowModal} data={activeData} setPickUp={setPickUp} />
            )}
            {showSuccess && (
                <SuccessModal
                    visible={showSuccess}
                    onCancel={() => setShowSuccess(false)}
                    heading="KYC Completed Successfully"
                    name=""
                    closeText="Complete"
                    onComplete={() => {
                        if (isAccountPresent) {
                            changeStep(KYCStatuses.VERIFICATION_PENDING);
                        } else {
                            onComplete();
                            changeStep(KYCStatuses.VERIFICATION_PENDING);
                        }
                    }}
                    description="The KYC has been sent to the bank for approval. We will notify you once the approval is complete."
                />
            )}
            <div className="button-container">
                <Button
                    className="gapstack-button"
                    onClick={async () => {
                        const isValidated = await form.validateFields();
                        if (isValidated) {
                            const resp = await submitMobileIndemnity();
                            if (resp) {
                                setShowSuccess(true);
                                if (isAccountPresent) {
                                    onAccountSubmit();
                                    return;
                                }
                            }
                        } else {
                            notification.error({
                                message: 'Error',
                                description: 'Please upload all required fields.',
                            });
                        }
                    }}
                    loading={loading}
                >
                    Complete
                </Button>
            </div>
        </TermsWrappper>
    );
};

export default TermsAndConditions;
