import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';

const LandingPageLoadable = Loadable({
    loader: () => import('../index' /* webpackChunkName: "Landing-Page" */),
    loading() {
        return <Loader />;
    },
});

export default LandingPageLoadable;
