import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const globalTable = () => css`
    .ant-table {
        font-size: 13px;
    }
    .ant-table-tbody > tr.ant-table-placeholder > td,
    .ant-table-tbody > tr:last-child > td,
    .ant-card-body .ant-table-tbody > tr:only-child > td {
        border: none;
    }

    .ant-table-footer {
        border-top: 1px solid #f0f0f0;
    }

    .datatable {
        .ant-spin-nested-loading {
            padding: 0;
        }
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        margin-top: 20px;
        .ant-table {
            border-radius: 5px !important;
        }
        .ant-table-thead > tr > th {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            padding: 12px;
        }
        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            padding: 12px;
        }

        .ant-table-tbody > tr {
            background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]} !important;
        }

        .ant-table-title {
            border-bottom: 1px solid #f0f0f0;
        }
        .ant-table-footer {
            background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
        }
        .ant-table-pagination.ant-pagination {
            margin: 16px !important;
        }
    }

    .datatable-with-border {
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-shadow: none;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        font-family: 'sofia-pro';
    }

    .bordered-datatable {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        .ant-table {
            border-radius: 5px !important;
        }
        .ant-table-thead > tr > th {
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }

        .ant-table-tbody > tr {
            background: ${(props: any) => props.theme[COLORS.CARD_CONTENT_COLOR]} !important;
        }

        .ant-table-title {
            border-bottom: 1px solid #f0f0f0;
        }
        .ant-table-footer {
            background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
        }
        .ant-table-pagination.ant-pagination {
            margin: 16px !important;
        }
    }
    .ant-table-column-sorters {
        padding: 0;
    }

    .sort-tablerow {
        flex-direction: row;
        display: flex;
        width: 100%;
        position: relative;
        align-items: center;
        .normal-col {
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            width: 20%;
            height: 55px;
            font-weight: 500;
            display: flex;
            align-items: flex-end;
            padding: 0px 5px;
            text-transform: capitalize;
            &:nth-child(5) {
                border-right: none;
            }
            .rec {
                width: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 45px;
                cursor: move;
                img {
                    width: 2px;
                    height: 45px;
                    margin-right: 3px;
                }
            }
            .amount-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 45px;
                padding-left: 10px;
                span {
                    font-weight: 500;
                    font-size: 13px;
                    color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
                }
                .delete-icon {
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                    position: absolute;
                    right: 15px;
                    width: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .table-wrapper {
        overflow: hidden;
        border-radius: 5px;
    }

    .ant-table .ant-table-thead > tr > th.ant-table-selection-column,
    .ant-table .ant-table-tbody > tr > td.ant-table-selection-column {
        padding-left: 15px !important;
    }

    .ant-table-tbody > tr > td {
        padding: 14px;
    }

    .datatable .ant-table-thead > tr > td {
        padding: 14px;
    }
    .header-table {
        .add-product-button {
            height: 35px;
            width: 143px;
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border: none;
            box-shadow: ${(props: any) => props.theme[COLORS.ACTION_BOX_SHADOW]};
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            .icon {
                /* width: 11px; */
                margin-right: 8px;
                color: ${(props: any) => props.theme[COLORS.ICONS]};
            }
            span {
                font-size: 13px;
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                font-weight: 600;
            }
        }
    }

    .datatable .ant-table-tbody > tr:nth-child(even) {
        background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
    }
    .datatable .ant-table-tbody > tr {
        cursor: pointer;
    }
    .datatable .ant-table-tbody > tr > td,
    .datatable .ant-table-thead > tr > th {
        padding: 14px 12px;
    }
    .datatable .ant-table-pagination.ant-pagination {
        margin: 5px !important;
        box-shadow: none !important;
        padding: 15px;
    }
    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0px;
        .select-wrapper {
            span {
                font-size: 13px;
                margin: 0px 10px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                line-height: 40px !important;
            }
            .ant-select-selector {
                border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
                border-radius: 5px;
                height: 40px;
                width: 100px;
            }
            .ant-select-selection-item {
                color: ${(props: any) => props.theme[COLORS.ICONS]};
            }

            .ant-select-arrow {
                margin-top: 0px;
                top: 14%;
            }
        }
        .footer-span {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-left: auto;
        }
        .ant-pagination {
            margin: 5px !important;
            box-shadow: none !important;
        }
    }
`;

export default globalTable;
