import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../utils/Loader';
import ContentLayout from '../../../Layouts/Content';

const CreateNewGuaranteeBond = Loadable({
    loader: () => import('./NewGuaranteeBond' /* webpackChunkName: "New-Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const CreateNewGuaranteeBondSubmit = Loadable({
    loader: () => import('./GuaranteeBondSubmit' /* webpackChunkName: "Submit-Guarantees-bonds" */),
    loading() {
        return <Loader />;
    },
});

const NewGuaranteeBond = () => (
    <ContentLayout>
        <CreateNewGuaranteeBond />
    </ContentLayout>
);

const NewGuaranteeBondSubmit = () => (
    <ContentLayout>
        <CreateNewGuaranteeBondSubmit />
    </ContentLayout>
);

export { NewGuaranteeBond, NewGuaranteeBondSubmit };
