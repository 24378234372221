import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .select {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 40px;
            }
        }
    }
    .id-label {
        color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
        font-size: 13px;
        margin-bottom: 10px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 45px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 45px;
    }
    .ant-select .ant-select-arrow {
        top: 50%;
        right: 12px;
    }
    .select-icon {
        color: ${(props: any) => props.theme[COLORS.ICONS]};
        width: 16px !important;
        height: 16px !important;
    }
`;
export default style();
