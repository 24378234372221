import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import { FundingTypeEnum } from '../../fund-my-purchase/types';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const ImportLoanTableComponent = (props: any) => (
    <AuthenticatedLayout>
        <ImportLoanTable {...props} />
    </AuthenticatedLayout>
);
const ImportLoanTable = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Import loan" */),
    loading() {
        return <Loader />;
    },
});
const ImportLoanViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <ImportLoanView title={'Import Loans'} fundingType={FundingTypeEnum.IMPORT_TRADE} {...props} />
    </AuthenticatedLayout>
);
const ImportLoanView = Loadable({
    loader: () => import('../../fund-my-purchase/view-financing-request' /* webpackChunkName: "Import loan" */),
    loading() {
        return <Loader />;
    },
});
const GetTradeFinancing = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Import loan" */),
    loading() {
        return <Loader />;
    },
});
const GetTradeFinancingComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <GetTradeFinancing {...props} />
    </AuthenticatedLayout>
);
const FinanceImportOrdersComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <FinanceImportOrders {...props} />
    </AuthenticatedLayout>
);
const FinanceImportOrders = Loadable({
    loader: () => import('./add-new/import-orders' /* webpackChunkName: "Import loan" */),
    loading() {
        return <Loader />;
    },
});
const PostShipmentFinanceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <PostShipmentFinance {...props} />
    </AuthenticatedLayout>
);
const PostShipmentFinance = Loadable({
    loader: () => import('./add-new/post-shipment' /* webpackChunkName: "Import loan" */),
    loading() {
        return <Loader />;
    },
});

export {
    ImportLoanTableComponent,
    ImportLoanViewComponent,
    GetTradeFinancing,
    FinanceImportOrders,
    PostShipmentFinanceComponent,
    GetTradeFinancingComponent,
    FinanceImportOrdersComponent,
};
