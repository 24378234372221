import { css } from 'styled-components';
import GreenTick from '../../icons/Svg/green-check.svg';

const formItemStyles = () => css`
    .ant-form-vertical .ant-form-item-label {
        padding: 0px;
    }
    .ant-form-vertical .ant-form-item-label > label {
        font-size: 13px;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        content: unset;
    }
    .ant-form-item-has-success .anticon-check-circle svg {
        display: none;
    }

    .ant-form-item-has-success .anticon-check-circle::before {
        content: ' ';
        display: inline-block;
        background: transparent url(${GreenTick}) center / 18px 18px no-repeat;
        width: 18px;
        height: 18px;
    }
`;

export default formItemStyles;
