import React, { useEffect, useState } from 'react';
import { Row, Col, notification } from 'antd';
import styled from 'styled-components';

import style from './styles';
import PageTopBar from '../../utils/PageTopBar';
import NotificationsCard from './components/notification-card/index';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';
import AuthenticatedLayout from '../Layouts/Authenticated';
import { authenticatedRequest } from '../../api';
import { get_read_notifications, get_unread_notifications } from '../../apis/notifications';
import { authenticatedLayoutActions } from '../Layouts/Authenticated/store/actions';
import { getUser, Users } from '../../config';

const NotificationsWrapper = styled.div`
    ${style}
`;

const Notifications: React.FC<any> = () => {
    const unreadNotifications: any[] = useSelector(
        (state: RootState) => state.authenticatedLayoutReducer.unreadNotifications || [],
    );
    const readNotifications: any[] = useSelector(
        (state: RootState) => state.authenticatedLayoutReducer.readNotifications || [],
    );
    const auth: any = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const userId = auth?.user?.userId;
    const [readPagination, setReadPagination] = useState<any>({ page: 1, total: 0 });
    const [unreadPagination, setUnreadPagination] = useState<any>({ page: 1, total: 0 });

    const isBank = getUser() == Users.BANK;

    const fetchReadNotifications = (page?) => {
        if (isBank) return;
        authenticatedRequest({ 'x-user-id': userId })
            .get(get_read_notifications(), { params: { page: page ?? readPagination?.page } })
            .then((res) => res.data)
            .then((res) => {
                dispatch(authenticatedLayoutActions.fetchReadNotifications(res?.data));
                setReadPagination({ page: res?.pagination?.currentPage, total: res?.pagination?.count });
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    const fetchUnreadNotifications = (page?) => {
        if (isBank) return;
        authenticatedRequest({ 'x-user-id': userId })
            .get(get_unread_notifications(), { params: { page: page ?? unreadPagination?.page } })
            .then((res) => res.data)
            .then((res) => {
                dispatch(authenticatedLayoutActions.fetchUnreadNotifications(res?.data));
                setUnreadPagination({
                    page: res?.pagination?.currentPage,
                    total: res?.pagination?.count,
                });
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        fetchReadNotifications();
        fetchUnreadNotifications();
        dispatch(authenticatedLayoutActions.seenNotifications());
    }, []);

    return (
        <AuthenticatedLayout isContentLayout>
            <NotificationsWrapper style={{ minHeight: '100vh' }}>
                <div className="container">
                    <PageTopBar lightTitle="Notifications" pageTitle="" />

                    <Row justify="center" gutter={[0, 20]}>
                        <Col span={17}>
                            <NotificationsCard
                                data={unreadNotifications}
                                pagination
                                paginationObject={unreadPagination}
                                changePagination={(page) =>
                                    page != unreadPagination?.page && fetchUnreadNotifications(page)
                                }
                            />
                        </Col>
                        <Col span={17}>
                            <NotificationsCard
                                title="Older Notifications"
                                data={readNotifications}
                                showDismissAll={false}
                                pagination
                                paginationObject={readPagination}
                                changePagination={(page) =>
                                    page != readPagination?.page && fetchReadNotifications(page)
                                }
                            />
                        </Col>
                    </Row>
                </div>
            </NotificationsWrapper>
        </AuthenticatedLayout>
    );
};

export default Notifications;
