import React from 'react';
import AuthorizationWrapper from '../../../../../Authorization/AuthorizationWrapper';
interface ExtraProps {
    onClick: (any) => void;
    name: string;
}

const Extra: React.FC<ExtraProps> = ({ onClick, name }: ExtraProps) => {
    switch (name) {
        case 'Technology Blacklist':
            return (
                <AuthorizationWrapper restrictedRoles={['View', 'Analyst']}>
                    <span className="actions" onClick={onClick}>
                        Add To Blacklist
                    </span>
                </AuthorizationWrapper>
            );
        default:
            return null;
    }
};
const commercialBlacklistData = [
    {
        card: 'Commercial Blacklist',
        id: 1,
        class: 'full-margin',
    },
];
const technologyBlacklistData = [
    {
        card: 'Technology Blacklist',
        id: 2,
        class: 'full-margin',
    },
];

export { Extra, commercialBlacklistData, technologyBlacklistData };
