import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const globalButton = () => css`
    .gapstack-button {
        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.POP_UP]};
        text-shadow: none;
        font-size: 13px;
        min-width: 70px;
        &:hover {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
        &:focus {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
        display: flex;
        align-items: center;
        column-gap: 5px;
        justify-content: center;
    }

    .gapstack-button-outline {
        background: ${(props) => props.theme[COLORS.POP_UP]};
        border: 1px solid ${(props) => props.theme[COLORS.PRIMARY]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 10px;
        height: 45px;
        color: ${(props) => props.theme[COLORS.SECONDARY]};
        text-shadow: none;
        font-size: 13px;
        min-width: 70px;
        &:hover {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
        &:focus {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
    }
    .gapstack-button-primary {
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            margin-left: 5px;
        }
    }

    .gapstack-blue-button {
        background: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        box-sizing: border-box;
        border-radius: 5px;
        padding: 5px 30px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.POP_UP]};
        text-shadow: none;
        &:hover {
            background: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
        &:focus {
            background: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            color: ${(props: any) => props.theme[COLORS.POP_UP]};
        }
    }

    .gapstack-cancel-button {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
        height: 45px;
        min-width: 88px;
        &:hover {
            border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
        }
        &:focus {
            border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
        }
    }

    .gapstack-default-button {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-sizing: border-box;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        height: 35px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-weight: 600;
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
        &:hover {
            outline: none;
            border-color: transparent;
        }
        border: none;
    }

    .gapstack-button-link {
        border: none;
        font-weight: 600;
        font-size: 13px;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]} !important;
        margin-top: 10px;
        cursor: pointer;
        text-align: left;
        padding-right: 20px;
        //width: 250px;
        margin-left: auto;
        :hover {
            text-decoration: underline !important;
        }
    }

    .gapstack-button-link-danger {
        font-weight: 600;
        font-size: 13px;
        color: ${(props: any) => props.theme[COLORS.DECLINED]} !important;
        cursor: pointer;
        padding-right: 20px;
        margin-left: auto;
        :hover {
            text-decoration: underline;
        }
    }

    .button-link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
    }

    .button-container {
        display: flex;
        .left-button-container {
            display: flex;
        }
        .right-button-container {
            display: flex;
            justify-content: flex-end;
        }
    }

    .resend-buttons-wrapper {
        .resend-invite-wrapper,
        .cancel-invite-wrapper {
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            cursor: pointer;
            .icon {
                width: 16px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
            span {
                font-size: 12px;
                font-weight: 400;
            }
        }
        .cancel-invite-wrapper {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
            .icon {
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
            }
        }
    }

    .edit-button {
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        width: 16px;
        height: 16px;
    }

    .cancel-red {
        color: ${(props: any) => props.theme[COLORS.DECLINED]};
    }
    .new-button {
        background: #7c60ff;
        border: 1px solid #7c60ff;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 500;
        font-size: 15px;
        color: ${(props: any) => props.theme[COLORS.POP_UP]};
        height: 45px;
        width: 74px;
        margin: 20px 15px 20px auto;
    }

    .form-button-container {
        flex-direction: row;
        justify-content: flex-end;
        margin: 10px 20px;
        display: flex;
    }
`;

export default globalButton;
