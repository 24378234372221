import React from 'react';
import axios from 'axios';
import { authenticatedRequest as request } from '../../../../../../api';
import { prefix } from '../../../../../../apis/bank-products';

const PREFIX = prefix + '/collection-strategy/data/';

const RequestList = () => {
    const stageRequest = request().get(`${PREFIX}stages`);
    const operandRequest = request().get(`${PREFIX}operands`);
    const actionRequest = request().get(`${PREFIX}actions`);
    return [stageRequest, operandRequest, actionRequest];
};

const responseDataMap = (res) => {
    return res.data?.map?.((d) => ({ label: d, value: d }));
};

interface StrategyType {
    name: string;
    id: string | number;
}

export interface StrategyCollection {
    stages: StrategyType[];
    operands: StrategyType[];
    actions: StrategyType[];
}

const useStrategiesActions = (): StrategyCollection => {
    const [strategies, setStrategies] = React.useState<StrategyCollection>({
        actions: [],
        operands: [],
        stages: [],
    });

    const fetchStrategiesData = React.useCallback(() => {
        axios
            .all(RequestList())
            .then((res) => res.map(responseDataMap))
            .then((data) => {
                const operands = data[1] || [];
                const stages = data[0].map((a) => {
                    if (a.name === 'Days Past Due') {
                        return {
                            ...a,
                            operands: [],
                        };
                    } else if (a.name === 'Repayment') {
                        return {
                            ...a,
                            operands: operands?.filter((b) => b.name == 'After' || b.name == 'Equal'),
                        };
                    }
                    return { ...a, operands: operands };
                });
                return [stages, operands, data[2]];
            })
            .then((data) => {
                setStrategies({
                    stages: data[0] || [],
                    operands: data[1] || [],
                    actions: data[2] || [],
                });
            })
            .catch(console.warn);
    }, []);

    React.useEffect(() => {
        fetchStrategiesData();
    }, [fetchStrategiesData]);

    // console.log(strategies);

    return strategies;
};

export default useStrategiesActions;
