import React from 'react';
import { Divider } from 'antd';
import { Trash2 } from 'react-feather';
import StrategyActionForm from '../../../Form/StrategyActionForm';

const StrategyActions: React.FC<any> = ({ delRow, actionsData, error, index, form }: any) => {
    const onChangeValidate = React.useCallback(() => {
        form?.validateFields?.(['collectionStrategyActions']);
    }, [form]);

    return (
        <div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                <span style={{ display: 'inline-block' }}>
                    <h6 className="content-heading">Strategy Actions:</h6>
                </span>
                <span>
                    <Divider className="divider" />
                </span>
            </div>
            <div className="icon-wrapper">
                <Trash2 className="delete-icon" onClick={delRow} />
            </div>
            <StrategyActionForm
                actionsData={actionsData}
                formIndex={index}
                error={error}
                onChangeValidate={onChangeValidate}
            />
        </div>
    );
};

export default StrategyActions;
