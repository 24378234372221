import React from 'react';
import Loadable from 'react-loadable';
import AuthenticatedLayout from '../../../components/Layouts/Authenticated';
import Loader from '../../../utils/Loader';

const AppliedChargesComponent = (props: any) => (
    <AuthenticatedLayout>
        <AppliedCharges {...props} />
    </AuthenticatedLayout>
);
const AppliedCharges = Loadable({
    loader: () => import('./applied-charges/table' /* webpackChunkName: "Applied-Charges-Settings" */),
    loading() {
        return <Loader />;
    },
});
const ViewAppliedChargesComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewAppliedCharges {...props} />
    </AuthenticatedLayout>
);
const ViewAppliedCharges = Loadable({
    loader: () => import('./applied-charges/view-applied-charges' /* webpackChunkName: "Applied-Charges-Settings" */),
    loading() {
        return <Loader />;
    },
});
const AddAppliedChargeComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddAppliedCharge {...props} />
    </AuthenticatedLayout>
);
const AddAppliedCharge = Loadable({
    loader: () => import('./applied-charges/add-applied-charge' /* webpackChunkName: "Applied-Charges-Settings" */),
    loading() {
        return <Loader />;
    },
});
const ChargesFinancingProductsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ChargesFinancingProducts {...props} />
    </AuthenticatedLayout>
);
const ChargesFinancingProducts = Loadable({
    loader: () => import('./charges-financing-products/table' /* webpackChunkName: "Charge-Financing-Settings" */),
    loading() {
        return <Loader />;
    },
});
const AddProductChargeComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddProductCharge {...props} />
    </AuthenticatedLayout>
);
const AddProductCharge = Loadable({
    loader: () =>
        import('./charges-financing-products/add-product-charge' /* webpackChunkName: "Add-Product-Settings" */),
    loading() {
        return <Loader />;
    },
});
const ViewChargesAndFinancingComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewChargesAndFinancing {...props} />
    </AuthenticatedLayout>
);
const ViewChargesAndFinancing = Loadable({
    loader: () =>
        import(
            './charges-financing-products/view-charges-financing-prodcts' /* webpackChunkName: "Add-Product-Settings" */
        ),
    loading() {
        return <Loader />;
    },
});
const AddNewStrategy = Loadable({
    loader: () => import('./collection-strategies/add-new' /* webpackChunkName: "Collection-Strategy-Settings" */),
    loading() {
        return <Loader />;
    },
});
const CollectionStrategiesComponent = (props: any) => (
    <AuthenticatedLayout>
        <CollectionStrategies {...props} />
    </AuthenticatedLayout>
);
const CollectionStrategies = Loadable({
    loader: () => import('./collection-strategies/table' /* webpackChunkName: "Collection-Strategy-Settings" */),
    loading() {
        return <Loader />;
    },
});
const ViewCollectionStrategiesComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewCollectionStrategies {...props} />
    </AuthenticatedLayout>
);
const ViewCollectionStrategies = Loadable({
    loader: () =>
        import(
            './collection-strategies/view-collection-strategies' /* webpackChunkName: "Collection-Strategy-Settings" */
        ),
    loading() {
        return <Loader />;
    },
});
const CreditLimitUploadComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreditLimitUpload {...props} />
    </AuthenticatedLayout>
);
const CreditLimitUpload = Loadable({
    loader: () => import('./credit-limits/add-new' /* webpackChunkName: "Credit-Limits-Settings" */),
    loading() {
        return <Loader />;
    },
});
const CreditLimitsComponent = (props: any) => (
    <AuthenticatedLayout>
        <CreditLimits {...props} />
    </AuthenticatedLayout>
);
const CreditLimits = Loadable({
    loader: () => import('./credit-limits/table' /* webpackChunkName: "Credit-Limits-Settings" */),
    loading() {
        return <Loader />;
    },
});
const GuaranteeSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <GuaranteeSettings {...props} />
    </AuthenticatedLayout>
);
const GuaranteeSettings = Loadable({
    loader: () => import('./guarantee-settings' /* webpackChunkName: "Guarantee-Settings" */),
    loading() {
        return <Loader />;
    },
});

const AccountsSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <AccountsSettings {...props} />
    </AuthenticatedLayout>
);
const AccountsSettings = Loadable({
    loader: () => import('./accounts/AccountsSettings' /* webpackChunkName: "Account-Settings" */),
    loading() {
        return <Loader />;
    },
});

const AddNewTemplateComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout isFullScreen>
        <AddNewTemplate {...props} />
    </AuthenticatedLayout>
);
const AddNewTemplate = Loadable({
    loader: () =>
        import('./guarantee-settings/add-new-template/add-new-template' /* webpackChunkName: "Guarantee-Settings" */),
    loading() {
        return <Loader />;
    },
});

const ViewTemplateComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <ViewTemplate {...props} />
    </AuthenticatedLayout>
);

const ViewTemplate = Loadable({
    loader: () =>
        import('./guarantee-settings/components/view-bond-template' /* webpackChunkName: "Guarantee-Settings" */),
    loading() {
        return <Loader />;
    },
});

export {
    AddNewTemplateComponent,
    ViewTemplateComponent,
    AccountsSettingsComponent,
    AddAppliedChargeComponent,
    AppliedChargesComponent,
    ViewAppliedChargesComponent,
    AddProductChargeComponent,
    CollectionStrategiesComponent,
    AddNewStrategy,
    CollectionStrategies,
    ViewCollectionStrategiesComponent,
    CreditLimitsComponent,
    CreditLimitUploadComponent,
    GuaranteeSettingsComponent,
    ChargesFinancingProductsComponent,
    ViewChargesAndFinancingComponent,
};
