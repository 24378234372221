import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    .card {
        margin-top: 20px;
        background: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        min-height: 150px;
        .card-header {
            padding: 10px 15px;
            border-bottom: 1px solid #e5e5e5;
            min-height: 50px;
            display: flex;
            .title-container {
                flex: 1;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                // padding: 10px 0px;
                .title {
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                .sub-title {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
            }
            .action-container {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        .card-body {
            padding: 15px;
            display: flex;
            .content-row {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                .title {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                }
                .description {
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                    padding-left: 8px;
                }
            }
        }
    }

    .datatable .ant-table-tbody > tr:nth-child(even) {
        background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
    }
    .datatable .ant-table-tbody > tr {
        cursor: pointer;
    }
`;

export default style;
