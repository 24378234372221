import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';

const VerifyBonds = Loadable({
    loader: () => import('./verify-bond'),
    loading() {
        return <Loader />;
    },
});

export { VerifyBonds };
