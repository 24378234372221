import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import SalesOrder from './table';
// import AddSalesOrder from './add-new/upload';
// import BulkSaleOrderTable from './add-new/upload/bulk-upload-table';
// import ViewSingleReceivedPurchaseOrder from './received-purchase-order/single-view';
// import UploadPurchaseOrderView from './uploaded-order';
// import ValidatePurchaseOrder from './components/validate';

const SalesOrderComponent = (props: any) => (
    <AuthenticatedLayout>
        <SalesOrder {...props} />
    </AuthenticatedLayout>
);
const SalesOrder = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});

const AddSalesOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddSalesOrder {...props} />
    </AuthenticatedLayout>
);
const AddSalesOrder = Loadable({
    loader: () => import('./add-new/upload' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});

const BulkSaleOrderTable = Loadable({
    loader: () => import('./add-new/upload/bulk-upload-table' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});

const ViewPurchaseOrderComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewSingleReceivedPurchaseOrder {...props} />
    </AuthenticatedLayout>
);
const ViewSingleReceivedPurchaseOrder = Loadable({
    loader: () => import('./received-purchase-order/single-view' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});
const UploadPurchaseOrderViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <UploadPurchaseOrderView {...props} />
    </AuthenticatedLayout>
);
const UploadPurchaseOrderView = Loadable({
    loader: () => import('./uploaded-order' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});

const ValidatePurchaseOrder = Loadable({
    loader: () => import('./components/validate' /* webpackChunkName: "Received-Orders" */),
    loading() {
        return <Loader />;
    },
});

export {
    SalesOrderComponent,
    AddSalesOrderComponent,
    BulkSaleOrderTable,
    ViewPurchaseOrderComponent,
    UploadPurchaseOrderViewComponent,
    ValidatePurchaseOrder,
    SalesOrder,
};
