import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../config/theme';
import pin from '../../assets/icons/Svg/pin.svg';
import { Form, Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { MapPin } from 'react-feather';
import { updateObject } from '../../utils/Helper';

const style = css`
    .gps-container {
        width: 100%;
        margin-top: 20px;
        .gps-heading {
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PARAGRAPH]};
            font-weight: normal;
        }
        .pick-location {
            display: flex;
            align-items: center;

            .form-label:not(:empty) {
                padding-right: 8px;
            }

            span:last-child {
                padding-left: 8px;
            }
        }
    }

    .gps-maps-button {
        position: absolute;
        right: 0;
        height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        bottom: 0;
        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
        border-radius: 5px;
        cursor: pointer;
        .map-icon {
            width: 15px;
            height: 15px;
            color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
            margin: 0 12px;
        }
    }
`;

const Wrapper = styled.div`
    ${style}
`;

interface GPSProps {
    form: FormInstance<any>;
    name: string | (number | string)[];
    initialValue?: string;
    required?: boolean;
}

// const getGPS = () => {
//     return (
//         <div>
//             <MapPin size="14" style={{ color: '#0B95FA' }} className="edit-icon" />
//             <span style={{ color: '#0B95FA', cursor: 'pointer' }}>View on Maps</span>
//         </div>
//     );
// };

const GPSField: React.FC<GPSProps> = ({ form, name, initialValue, required = true }: GPSProps) => {
    const zoom = 10;
    const [center, setCenter] = useState<any>();
    const [locationString, setLocationString] = useState<string>();
    const Marker: React.FC<any> = () => {
        return <img src={pin} alt="map-marker" className="marker" />;
    };

    const handleClick = (prop: any) => {
        setCenter({ lat: prop.lat, lng: prop.lng });
    };

    useEffect(() => {
        const values = form?.getFieldValue?.(name);
        values && setLocationString(values);
    }, []);

    useEffect(() => {
        if (initialValue !== undefined) {
            setLocationString(initialValue);
            const formObject = {};
            updateObject(formObject, name, initialValue);
            form.setFieldsValue(formObject);
        }
    }, [initialValue]);

    useEffect(() => {
        if (center) {
            const val = center.lat.toFixed(2).toString() + ',' + center.lng.toFixed(2).toString();
            // form.setFieldsValue({ [name]: val });
            const formObject = {};
            updateObject(formObject, name, val);
            form.setFieldsValue(formObject);
            setLocationString(val);
        }
    }, [center]);

    const [modal, showModal] = useState<boolean>(false);

    return (
        <Wrapper>
            <div className="gps-container">
                <Modal
                    visible={modal}
                    okText="Ok"
                    cancelText="Cancel"
                    onOk={() => showModal(false)}
                    onCancel={() => showModal(false)}
                    okButtonProps={{
                        style: {
                            background: '#7C60FF',
                            color: '#FFF',
                            fontSize: '15px',
                            borderRadius: '5px',
                            outline: 'none',
                            border: '1px solid #7C60FF',
                            width: '60px',
                            height: '45px',
                            margin: '5px',
                        },
                    }}
                    cancelButtonProps={{
                        style: {
                            background: '#FFF',
                            color: '#E74C3C',
                            fontSize: '15px',
                            borderRadius: '5px',
                            outline: 'none',
                            border: '1px solid #E74C3C',
                            width: '90px',
                            height: '45px',
                            margin: '5px',
                        },
                    }}
                    centered
                    width={'60vw'}
                    title="GPS (Pick Exact location from the map)"
                >
                    <div className="map">
                        <div style={{ width: '40%', marginBottom: 20 }}>
                            <label style={{ marginBottom: 8 }}>Coordinates (latitude, longitude)</label>
                            <Input className="gapstack-input" value={locationString} placeholder="Coordinates" />
                        </div>
                        <GoogleMapReact
                            defaultZoom={zoom}
                            defaultCenter={{ lat: -1.289691, lng: 36.822025 }}
                            yesIWantToUseGoogleMapApiInternals
                            options={{ fullscreenControl: false }}
                            bootstrapURLKeys={{ key: 'AIzaSyC9gdtZophdhMIgX5-hGQR3rNFPwNFSyMg' }}
                            onClick={(props: any) => handleClick(props)}
                        >
                            <Marker lat={center?.lat || -1.289691} lng={center?.lng || 36.822025} />
                        </GoogleMapReact>
                    </div>
                </Modal>
                <Form.Item
                    name={name}
                    className="label"
                    rules={[{ required: required, message: 'Please select GPS location' }]}
                >
                    {/*<Input className="gapstack-input" />*/}
                    {locationString !== '' ? (
                        <div className={'pick-location'} onClick={() => showModal(true)}>
                            <span className="form-label">{locationString}</span>
                            <MapPin size="14" style={{ color: '#0B95FA' }} className="edit-icon" />
                            <span style={{ color: '#0B95FA', cursor: 'pointer' }}>Pick location on Maps</span>
                        </div>
                    ) : (
                        <div className={'pick-location'} onClick={() => showModal(true)}>
                            <MapPin size="14" style={{ color: '#0B95FA' }} className="edit-icon" />
                            <span style={{ color: '#0B95FA', cursor: 'pointer' }}>Pick location on Maps</span>
                        </div>
                    )}
                </Form.Item>
                {/*<div className="gps-maps-button" onClick={() => showModal(true)}>*/}
                {/*    <Map className="map-icon" />*/}
                {/*</div>*/}
            </div>
        </Wrapper>
    );
};
export default GPSField;
