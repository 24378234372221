import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    display: grid;

    &,
    &:only-child {
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .btn {
        cursor: pointer;
    }

    .notification-header {
        .title {
            font-weight: bold;
            font-size: 13px;
            line-height: 20px;
            margin-top: 5px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }

        .actions {
            display: flex;
            align-items: center;
            font-size: 11px;
            line-height: 17px;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};

            .close {
                display: flex;
                align-items: center;
                margin-left: auto;
                svg {
                    color: ${(props: any) => props.theme[COLORS.DECLINED]};
                }
            }
        }
    }
    &.dense {
        .notification-header {
            .title {
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            }
        }
    }

    .notification-body {
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.SECONDARY]};

        .btn-group {
            display: none;
        }

        &.show-actions {
            background: #f3f4f8;
            padding: 10px;
            border-radius: 5px;

            & > div:first-child {
                border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
                padding-bottom: 10px;
                margin-bottom: 10px;
            }

            .btn-group {
                display: grid;
                justify-content: end;
                grid-template-columns: auto auto;
                grid-gap: 5px;

                button {
                    border-radius: 5px;
                    font-size: 11px;
                    line-height: 17px;

                    &.igone {
                        background: rgba(231, 76, 60, 0.2);
                        color: #e74c3c;
                    }

                    &.accept {
                        background: rgba(124, 96, 255, 0.2);
                        color: #7c60ff;
                    }
                }
            }
        }
    }

    &.disabled {
        &.show-actions {
            filter: grayscale(92%);
        }

        .title {
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
    }
`;

export default style();
