import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = ({ showMessage = false, type = 'default' }) => css`
    .top-bar {
        margin: 30px 0px 20px;
        align-items: center;
        .back {
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: ${(props) => props.theme[COLORS.PARAGRAPH]};
        }
        .light-heading {
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: ${(props) => props.theme[COLORS.SECONDARY]};
        }
        .normal-heading {
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .bold-heading {
            margin: 0px 0px 0px 4px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
            span {
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                font-weight: 500;
            }
        }
        .close {
            margin-left: auto;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }
    }
    .divider {
        margin-top: 15px;
        border-top: 1px solid
            ${type == 'info' ? '#1890ff' : type === 'error' ? '#E96D60' : type === 'success' ? '#2CCC71' : '#e5e5e5'};
        margin-bottom: ${showMessage ? '0px' : '24px'};
    }
    .message-container {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        .message {
            border-radius: 0px 0px 15px 15px;
            background: ${type == 'info'
                ? '#1890ff'
                : type === 'error'
                ? '#E96D60'
                : type === 'success'
                ? '#2CCC71'
                : '#e5e5e5'};
            padding: 0px 50px 5px 50px;
            color: #ffffff;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
`;
export default style;
