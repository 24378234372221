import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .title {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }
`;

export default style;
