import React from 'react';
import { Form, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';

const { Option } = Select;

interface Props {
    formItemLabel: string;
    formItemName: string | (number | string)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
}

const WalletAccountTypesWrapper = styled.div`
    ${style}
`;

const SelectWalletAccountType = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    onChange,
    hasFeedback = false,
}: Props) => {
    const ref = React.useRef<any>();

    return (
        <WalletAccountTypesWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className="label"
                rules={[{ required, message }]}
                initialValue={initialValue}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    ref={ref}
                    className="form-select select"
                    allowClear
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    onChange={(e) => (onChange ? onChange(e, formItemName) : null)}
                >
                    <Option value="PhoneNumber">Phone Number</Option>
                    <Option value="TillNumber">Till Number</Option>
                    <Option value="PayBill">Pay Bill</Option>
                </Select>
            </Form.Item>
        </WalletAccountTypesWrapper>
    );
};

export default SelectWalletAccountType;
