import { Button, Divider, Form, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PODetails from './components/poDetails';
import style from './components/styles';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/reducers/rootReducers';
import { authenticatedRequest } from '../../../../../../api';
import SuccessModal from './components/successModal';
import { useHistory } from 'react-router-dom';
import { goToRoute, ROUTES } from '../../../../../../router/urls';
import FormUploads from '../../../../../../utils/FormUploads';
import { edit_invoice, upload_discount_invoice } from '../../../../../../apis/invoices';
import { getUser, Users } from '../../../../../../config';
import { get_business_brand_information, get_contact_information } from '../../../../../business-accounts/api';
import moment from 'moment';
const SingleUploadWrapper = styled.div`
    ${style}
`;

export function singleInvoiceDataMap(
    values,
    businessAccount: any,
    type: 'Proforma' | 'Commercial',
    buyer,
    contact,
    logo,
) {
    const submitObj = { ...values };
    submitObj['totalAmount'] = parseFloat(submitObj['totalAmount']?.toString());
    if (values.image && Array.isArray(values.image) && values.image.length > 0) {
        submitObj['documentURL'] = values.image[0]['response']?.downloadURl;
    }
    delete submitObj.image;
    delete submitObj.supplier;
    const temp_contact = {
        address: contact?.address,
        postalCode: contact?.postalCode,
        county: contact?.county,
        country: contact?.country,
    };

    submitObj['business'] = {
        address: temp_contact,
        contact: temp_contact,
        pinNumber: businessAccount?.business?.pinNumber?.toString?.() ?? 'PIN',
        logoURL: logo,
        name: businessAccount?.business?.businessName,
    };
    submitObj['type'] = type;
    submitObj['buyerId'] = buyer?.id;
    submitObj['category'] = 'Local';
    delete submitObj['supplier'];
    return submitObj;
}

interface Props {
    invoice?: any;
}

const SingleUploadComponent: React.FunctionComponent<Props> = ({ invoice }: Props) => {
    const [buyer, setBuyer] = useState<any>(null);
    const [form] = useForm();
    const businessAccount: any = useSelector((state: RootState) => state.businessAccount);
    const [loading, setLoading] = useState<string>('default');
    const [visible, setVisible] = useState<boolean>(false);
    const [contact, setContact] = useState<any>({});
    const [logo, setLogo] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [id, setId] = useState<string>('');
    const history = useHistory();
    const isEdit = invoice?.id;
    const isBank = getUser() == Users.BANK;

    const getContactInformation = () => {
        authenticatedRequest()
            .get(get_business_brand_information)
            .then((res) => {
                if (res.data.length) {
                    const brand = res.data[0];
                    setLogo(brand?.logo || null);
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
        authenticatedRequest()
            .get(`${get_contact_information}?limit=10&page=0`)
            .then((res) => {
                if (res.data.data?.length) {
                    const contact = res.data.data?.reduce((prev, curr) =>
                        prev.createdAt > curr.createdDate ? prev : curr,
                    );
                    setContact(contact);
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        getContactInformation();
    }, []);

    useEffect(() => {
        if (invoice?.id) {
            const dateFormat = 'YYYY/MM/DD';
            form.setFieldsValue({
                documentDate: invoice.documentDate ? moment(invoice.documentDate, dateFormat) : undefined,
                shortId: invoice?.shortId,
                parentNumber: invoice?.parentNumber,
                paymentDate: moment(invoice?.paymentDate, dateFormat),
                totalAmount: invoice?.totalAmount,
            });
            setBuyer({
                ...invoice?.buyer,
                buyerId: invoice?.buyer?.businessAccountId,
                id: invoice?.buyer?.businessAccountId,
                businessName: invoice?.buyer?.name,
            });
        }
    }, [invoice]);

    const onFinish = async (type) => {
        try {
            setLoading(type);
            setType(type);
            await form
                .validateFields()
                .then((values) => {
                    const submitObj = singleInvoiceDataMap(values, businessAccount, type, buyer, contact, logo);

                    if (isBank) {
                        setLoading('default');
                        setId('sampleId');
                        setVisible(true);
                        return;
                    }
                    let request: any;
                    if (isEdit) {
                        request = authenticatedRequest().put(edit_invoice(invoice?.id), submitObj);
                    } else {
                        request = authenticatedRequest().post(upload_discount_invoice, submitObj);
                    }

                    request
                        .then((res) => {
                            setLoading('default');
                            if (res.data) {
                                if (res.data && res.data?.id) {
                                    setId(res.data?.id);
                                    setVisible(true);
                                }
                                if (isEdit) {
                                    setId(invoice?.id);
                                    setVisible(true);
                                }
                            }
                        })
                        .catch((err) => {
                            setLoading('default');
                            const resp = err?.response;
                            notification.error({
                                message: 'Error',
                                description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                            });
                        });
                })
                .catch((err) => {
                    setLoading('default');
                    console.log(err);
                });
        } catch (err: any) {
            setLoading('default');
            notification.error({
                message: 'Error',
                description: err,
            });
        }
    };

    const handleClose = () => {
        setVisible(false);
        if (isBank) {
            history.push({ pathname: goToRoute(ROUTES.INVOICES) });
            return;
        }
        history.push({
            pathname: `${goToRoute(ROUTES.VIEW_COMMERCIAL_INVOICE).split(':').shift()}${id}`,
        });
    };

    return (
        <SingleUploadWrapper>
            <div className="single-upload-wrapper">
                <Form name="productInfo" onFinish={onFinish} layout="vertical" style={{ width: '100%' }} form={form}>
                    <PODetails buyer={isEdit ? buyer?.businessName : undefined} setBuyer={setBuyer} form={form} />

                    <div style={{ padding: '10px 20px' }}>
                        <FormUploads formItemName="image" label="Upload Invoice" initialValue={invoice?.documentURL} />
                    </div>
                    <Divider style={{ marginBottom: 0 }} />
                    <div className="save-purchase">
                        <Button
                            type={'primary'}
                            className="save-button"
                            onClick={() => onFinish('Commercial')}
                            loading={loading == 'Commercial'}
                            style={{ marginLeft: 'auto' }}
                        >
                            {isEdit ? 'Update' : 'Upload'}
                        </Button>
                    </div>
                </Form>
                <SuccessModal
                    visible={visible}
                    _id={form.getFieldValue('shortId')}
                    businessName={buyer?.businessName}
                    onClose={handleClose}
                    type={type}
                    isEdit={isEdit}
                />
            </div>
        </SingleUploadWrapper>
    );
};

export default SingleUploadComponent;
