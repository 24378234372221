import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './store';
import AppRouter from './router';
import './App.scss';
import GlobalStyle from './assets/styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import { LIGHT_THEME } from './config/theme';
import NetworkDetector from './utils/NetworkDetector';
import ErrorBoundary from './utils/ErrorBoundary';
import UserConfirmation from './utils/PreventTransisitonPrompt/index';
import withClearCache from './utils/ClearCache';
import { Helmet } from 'react-helmet';
const ClearCacheComponent = withClearCache(MainApp);

function App() {
    return <ClearCacheComponent />;
}

function MainApp() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={LIGHT_THEME}>
                <Helmet>
                    <title>{process.env.REACT_APP_APP_TITLE}</title>
                    <link rel="icon" href={`/favicons/${process.env.REACT_APP_FAVICON}`} />
                    <link rel="apple-touch-icon" href={`/apple-touch-icons/${process.env.REACT_APP_TOUCH_ICON}`} />
                </Helmet>
                <Router getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}>
                    <ErrorBoundary>
                        <GlobalStyle />
                        <NetworkDetector />
                        <PersistGate persistor={persistor}>
                            <AppRouter />
                        </PersistGate>
                    </ErrorBoundary>
                </Router>
            </ThemeProvider>
        </Provider>
    );
}

export default App;
