import React, { useEffect, useState } from 'react';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';
import Verify from '../../Validate';
import { unAuthenticatedRequest } from '../../../api';
import { verify_token } from '../../../apis/verification';
import { useParams, useHistory } from 'react-router';
import ContentLayout from '../../../components/Layouts/Content';
import { Button, Col, List, notification } from 'antd';
import { validate_document_for_scf } from '../../../apis/fund-my-purchases';
import { ROUTES } from '../../Helper';
import WarningModal from '../../Modals/Warning';
import ViewUploadedDocument from '../ViewUploadedDocument';
import styled from 'styled-components';
import style from './styles';

const ValidateDocumentWrapper = styled.div`
    ${style}
`;

const ValidateDocument = () => {
    const history = useHistory();
    const { token }: any = useParams();
    const [currentItem, setCurrentItem] = useState<number>(1);
    const [data, setData] = useState<any>({});
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isVerifyingToken, setIsVerifyingToken] = useState<boolean>(false);
    const [isValidatingDocument, setIsValidatingDocument] = useState<boolean>(false);
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);

    const verifyToken = () => {
        setIsVerifyingToken(true);
        unAuthenticatedRequest()
            .post(verify_token, {
                token: token,
                userId: null,
                check: true,
                type: 'link',
            })
            .then((res) => {
                const verificationData = {
                    id: res?.data?.data?.id,
                    businessAccountId: res?.data?.data?.businessAccountId,
                    data: JSON.parse(res?.data?.data?.data),
                };
                setData(verificationData);
                setIsVerifyingToken(false);
                setHasError(false);
                setErrorMessage(null);
            })
            .catch((error) => {
                const resp = error?.response;
                setIsVerifyingToken(false);
                setHasError(true);
                setErrorMessage(resp?.data?.message || 'Oops!. An unexpected error occurred!');
            });
    };

    const validateDocument = (status: 'BUYER_APPROVED' | 'BUYER_DECLINED') => {
        setIsValidatingDocument(true);
        unAuthenticatedRequest()
            .post(validate_document_for_scf, {
                token,
                status,
            })
            .then(() => {
                setIsValidatingDocument(false);
                notification.success({
                    message: 'Success',
                    description: `The document(s) have been successfully ${
                        status === 'BUYER_APPROVED' ? 'approved' : 'declined'
                    }`,
                });

                history.push(ROUTES.SIGN_IN);
            })
            .catch((error) => {
                setIsValidatingDocument(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const onNextItem = () => {
        if (data?.data.length === currentItem) return;
        setCurrentItem(currentItem + 1);
    };

    const onPreviousItem = () => {
        if (currentItem === 1) return;
        setCurrentItem(currentItem - 1);
    };
    useEffect(() => {
        verifyToken();
    }, []);

    if (isVerifyingToken || hasError) {
        return (
            <Verify
                text={hasError ? 'Invalid link' : 'Verifying link'}
                loading={isVerifyingToken}
                status={hasError ? 'error' : null}
                description={hasError ? errorMessage : 'This will only take a short while.'}
            />
        );
    }

    return (
        <ValidateDocumentWrapper>
            <ContentLayout>
                <WarningModal
                    title={`Approve document(s)`}
                    description={`Are you sure you want to approve these documents`}
                    visible={showApproveModal}
                    onCancel={() => setShowApproveModal(false)}
                    onOK={() => validateDocument('BUYER_APPROVED')}
                    onOKLoading={isValidatingDocument}
                />
                <WarningModal
                    title={`Decline document(s)`}
                    description={`Are you sure you want to decline these documents`}
                    visible={showDeclineModal}
                    onCancel={() => setShowDeclineModal(false)}
                    onOK={() => validateDocument('BUYER_DECLINED')}
                    onOKLoading={isValidatingDocument}
                />
                <Col
                    span={24}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        margin: '24px 24px 0px 24px',
                    }}
                >
                    <span className="pagination-container">
                        <span className="left-arrow">
                            <RiArrowLeftLine
                                style={{ cursor: currentItem === 1 ? 'not-allowed' : 'pointer' }}
                                onClick={onPreviousItem}
                                size={18}
                            />
                        </span>
                        <span className="item">Invoice:&nbsp;</span>{' '}
                        <span className="active-item">{currentItem} &nbsp;</span>of {data?.data?.length}
                        <span className="right-arrow">
                            <RiArrowRightLine
                                style={{ cursor: data?.data?.length === currentItem ? 'not-allowed' : 'pointer' }}
                                onClick={onNextItem}
                                size={18}
                            />
                        </span>
                    </span>
                    {data?.data?.length === currentItem ? (
                        <>
                            <Button
                                className="gapstack-button"
                                onClick={() => setShowApproveModal(true)}
                                style={{ marginRight: 10 }}
                            >
                                Approve
                            </Button>
                            <Button className="gapstack-cancel-button" onClick={() => setShowDeclineModal(true)}>
                                Decline
                            </Button>
                        </>
                    ) : null}
                </Col>
                <List
                    style={{ height: '100vh' }}
                    dataSource={data.data}
                    header={null}
                    footer={null}
                    bordered={false}
                    pagination={{
                        pageSize: 1,
                        current: currentItem,
                        itemRender: () => null,
                        hideOnSinglePage: true,
                    }}
                    renderItem={(document: any) => {
                        return <ViewUploadedDocument url={document?.documentURL} />;
                    }}
                />
            </ContentLayout>
        </ValidateDocumentWrapper>
    );
};

export default ValidateDocument;
