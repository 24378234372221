import CompanyDocuments from './CompanyDocuments';
import TaxInformation from './TaxInformation';
import Directors from './Directors';
import AuditedAccounts from './AuditedAccounts';
import BankAccounts from './BankAccounts';
export { CompanyDocuments, TaxInformation, Directors, AuditedAccounts, BankAccounts };

export const loaderData = [
    { title: 'Company Documents', rows: 3 },
    { title: 'Tax Information', rows: 2 },
    { title: 'Audited Accounts', rows: 4 },
    { title: 'Directors', rows: 1 },
    { title: 'Liveness Detection', rows: 1 },
    { title: 'Bank Accounts', rows: 4 },
];
