import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const globalInput = () => css`
    input:-webkit-autofill {
        background-color: ${(props: any) => props.theme[COLORS.FORM_COLOR]} !important;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]} !important;
        -webkit-box-shadow: 0 0 0 1000px ${(props: any) => props.theme[COLORS.FORM_COLOR]} inset;
        :focus,
        .ant-input-affix-wrapper > input.ant-input:focus {
            -webkit-box-shadow: 0 0 0 1000px white inset !important;
        }
    }

    .gapstack-input {
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        border-radius: 5px;
        height: 45px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};

        input[type='password'] {
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};

            :focus {
                background: white;
            }
        }

        .ant-input-password-icon {
            color: ${(props: any) => props.theme[COLORS.ICONS]};
        }
    }

    .gapstack-combined-input {
        border-left-width: 0 !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }

    .gapstack-input:focus {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: none;
    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: none;
    }

    .prefix-input {
        height: 50px;
    }

    .ant-input-number-input-wrap {
        height: inherit;
        .ant-input-number-input {
            height: inherit;
        }
    }
`;

export default globalInput;
