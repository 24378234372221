import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import HourClock from '../../assets/icons/Svg/not-found.svg';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../router/urls';
import PlainLayout from '../../components/Layouts/PlainLayout';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';

const NotFound = () => {
    const auth: any = useSelector((state: RootState) => state.auth);
    const history = useHistory();

    const goBack = () => {
        if (history.action !== 'POP') window.history.back();
        history.push({ pathname: ROUTES.SIGN_IN });
    };

    useEffect(() => {
        Sentry.captureMessage(`Page not found: ${history.location?.pathname}`, {
            user: { username: auth?.user?.firstName, email: auth?.user?.email },
        });
    }, []);

    return (
        <PlainLayout>
            <img src={HourClock} alt="Alert" className="main-image" />
            <div className="content-container">
                <p className="title">Not Found</p>
                <span className="description">
                    The page you are looking for might be unavailable or you don’t have access due to invalid
                    credentials.
                </span>
            </div>
            <div className="action-container">
                <span onClick={goBack} className="go-back">
                    Go back
                </span>{' '}
                or you may also try reloading the page.
            </div>
        </PlainLayout>
    );
};

export default NotFound;
