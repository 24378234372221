export default function getDuplicate(tempRow): number[] {
    // validation
    const stringArr: string[] = [];

    tempRow.forEach((_row) => {
        const row = Object.assign({}, _row);
        delete row?.['message'];

        const data = Object.values({
            actionId: row?.actionId,
            operandId: row?.operandId,
            stageId: row?.stageId,
            whenInDays: row?.whenInDays,
        }).join('-check-');

        data && stringArr.push(data);
    });

    const counts = {};
    let el = [];

    stringArr.forEach((row, key) => {
        if (counts[row]) {
            counts[row].push(key);

            return (el = counts[row]);
        } else {
            counts[row] = [key];
        }
    });

    return el;
}
