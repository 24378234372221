import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .card-wrapper {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .ant-card {
            border-radius: 5px;
        }
        .ant-card-head {
            border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }

        .ant-card-head-title {
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .info {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-bottom: 10px;
        }
        .ant-card-body {
            border-radius: 5px;
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            margin-top: 15px;
            .terms-and-conditions-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 10px 20px;
                background: ${(props) => props.theme[COLORS.PRIMARY]};
                border: none;
                border-radius: 5px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
                margin-right: 10px;
            }
        }
    }
    .card-margin-top {
        margin-top: 20px;
    }
`;

export default style();
