import { Card, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import FormUploads from '../../../../utils/FormUploads';
import style from './styles';
import { DocumentPageType, DocumentType, DocumentNameAndType } from './types';
import { ConditionalRenderDocs } from './components/ConditionalRender';
import { titleCase } from '../../../../utils/TitleCase';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const BusinessDocumentsWrapper = styled.div`
    ${style}
`;
interface CompanyDocumentsProps {
    setDirectors: (val: any[]) => void;
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType,
        pageType: DocumentPageType,
        fileId: string,
    ) => void;
    documents: any;
    errorObj: any;
    directors: any[];
    docTypes: any[];
    docsArray: any[];
    setUploadingObj: (val: any) => void;
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType?: DocumentPageType,
    ) => void;
    documentErrObj: any;
    activeData?: any;
}

const CompanyDocuments: FunctionComponent<CompanyDocumentsProps> = ({
    setDirectors,
    uploadFilesToBusinessAccount,
    documents,
    errorObj,
    directors,
    docTypes,
    docsArray,
    setUploadingObj,
    handleErrorFromDocsManager,
    documentErrObj,
    activeData,
}: CompanyDocumentsProps) => {
    const getErr = (documentName) => {
        return (
            errorObj[documentName]?.error ||
            documentErrObj[documentName]?.error ||
            documents[documentName]?.reasonForReturn
        );
    };
    const docsObj: any = {};
    docsArray?.map((val) => {
        docsObj[val?.documentType] = val;
    });
    return (
        <BusinessDocumentsWrapper>
            <Card title="Business Documents" className="card-wrapper">
                <div className="card-description-wrapper">
                    <div className="card-description">Please upload the business registration documents.</div>
                    <AiOutlineInfoCircle size={16} className="info-icon" />
                </div>

                <Row gutter={20}>
                    <ConditionalRenderDocs shouldRender={docTypes.includes(DocumentNameAndType.CR12)}>
                        <FormUploads
                            label="Upload Latest CR12"
                            formItemName="cr12"
                            documentConfig={{
                                documentName: DocumentNameAndType.CR12,
                                documentType: DocumentType.KYC,
                                pageType: DocumentPageType.STANDARD,
                            }}
                            getResponse={(response) => {
                                if (response?.documentId) {
                                    uploadFilesToBusinessAccount(
                                        response?.documentId,
                                        response?.downloadURl,
                                        DocumentNameAndType.CR12,
                                        DocumentPageType.STANDARD,
                                        response?.fileId,
                                    );
                                    const tempDirs = response?.extractedInformation?.directors;
                                    if (tempDirs && Array.isArray(tempDirs)) {
                                        if (directors.length == 0) {
                                            setDirectors(tempDirs);
                                        } else {
                                            const tempObj: any = {};
                                            tempDirs.map((val) => {
                                                tempObj[val?.name] = { ...val };
                                            });
                                            setDirectors(
                                                directors.map((val) => {
                                                    if (tempObj[val?.name] || tempObj[val?.documentLabel]) {
                                                        return tempObj[val?.name] || tempObj[val?.documentLabel];
                                                    }
                                                    return val;
                                                }),
                                            );
                                        }
                                    } else {
                                        return;
                                    }
                                } else {
                                    handleErrorFromDocsManager(
                                        response,
                                        DocumentNameAndType.CR12,
                                        DocumentPageType.STANDARD,
                                    );
                                }
                            }}
                            getFileStatus={(status) => {
                                setUploadingObj((previousObj: any) => {
                                    if (status) {
                                        previousObj[DocumentNameAndType.CR12] = true;
                                    } else {
                                        delete previousObj[DocumentNameAndType.CR12];
                                    }
                                    return previousObj;
                                });
                            }}
                            initialValue={documents[DocumentNameAndType.CR12]?.['url']}
                            err={getErr(DocumentNameAndType.CR12)}
                            validateStatus={getErr(DocumentNameAndType.CR12) ? 'error' : 'success'}
                            required={docsObj[DocumentNameAndType.CR12]?.isRequired ?? false}
                            disabled={
                                !getErr(DocumentNameAndType.CR12) && activeData?.status == BusinessStatuses.OnHold
                            }
                        />
                    </ConditionalRenderDocs>
                </Row>
                {[
                    DocumentNameAndType.COI,
                    DocumentNameAndType.BUSINESS_LICENSE,
                    DocumentNameAndType.BUSINESS_PERMIT,
                    DocumentNameAndType.BUSINESS_REGISTRATION,
                    DocumentNameAndType.BUSINESS_REGISTRATION_CERT,
                    DocumentNameAndType.MEMORANDOM_AND_ARTICLES_OF_ASSOCIATION,
                    DocumentNameAndType.PARTNER_REGISTRATION_CERT,
                ].map((val, ind) => (
                    <Row gutter={20} key={ind}>
                        <ConditionalRenderDocs shouldRender={docTypes.includes(val)}>
                            <FormUploads
                                label={`Upload ${titleCase(val)} ${docsObj[val]?.isRequired ? '*' : ''}`}
                                formItemName={val}
                                documentConfig={{
                                    documentName: val,
                                    documentType: DocumentType.KYC,
                                    pageType: DocumentPageType.STANDARD,
                                }}
                                getResponse={(response) => {
                                    if (response?.metadata?.id || response?.documentId) {
                                        uploadFilesToBusinessAccount(
                                            response?.metadata?.id || response?.documentId,
                                            response?.metadata?.id ? response?.path : response?.downloadURl,
                                            val,
                                            DocumentPageType.STANDARD,
                                            response?.metadata?.id || response?.fileId,
                                        );
                                    } else {
                                        handleErrorFromDocsManager(response, val, DocumentPageType.STANDARD);
                                    }
                                }}
                                getFileStatus={(status) => {
                                    setUploadingObj((previousObj: any) => {
                                        if (status) {
                                            previousObj[val] = true;
                                        } else {
                                            delete previousObj[val];
                                        }
                                        return { ...previousObj };
                                    });
                                }}
                                initialValue={documents[val]?.['url']}
                                err={getErr(val)}
                                validateStatus={getErr(val) ? 'error' : 'success'}
                                required={docsObj[val]?.isRequired ?? false}
                                disabled={
                                    !getErr(DocumentNameAndType.CR12) && activeData?.status == BusinessStatuses.OnHold
                                }
                            />
                        </ConditionalRenderDocs>
                    </Row>
                ))}
            </Card>
        </BusinessDocumentsWrapper>
    );
};

export default CompanyDocuments;
