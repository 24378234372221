export function getTheme(defaultTheme = 'gapstack') {
    try {
        let themeName = (process.env.REACT_APP_THEME_NAME as string) || defaultTheme;

        if (process.env.NODE_ENV === 'development') {
            themeName = localStorage?.getItem('REACT_APP_THEME_NAME') || themeName;
        }

        return themeName;
    } catch (e) {
        return defaultTheme;
    }
}
