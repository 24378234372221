import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .logo-container {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .logo {
            width: 200px;
            height: 120px;
        }
    }
    .back {
        position: fixed;
        left: 90px;
        top: 50px;
        border: none;
        outline: none;
        background: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        .back-arrow {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        flex: 1;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 42px 0px 0px 0px;
        justify-content: center;

        .welcome {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 37px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .gapstack-divider {
            min-width: 15%;
            width: 15%;
            border-bottom: 4px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            border-radius: 2px;
        }

        .forgot-password-button-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            padding: 0 85px;
        }

        .button-container {
            padding: 20px 40px 0 40px;
            display: flex;
            .left-button-container {
                display: flex;
                .button-link {
                    padding: 0;
                }
                .link-icon {
                    margin-right: 10px;
                }
            }
            .right-button-container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;

export default style();
