import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    background: ${(props: any) => props.theme[COLORS.POP_UP]};
    border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
    box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
    border-radius: 5px;
    overflow-x: hidden;

    .btn {
        cursor: pointer;
    }

    .item-enter {
        opacity: 0;
        transform: translateX(-100%);
    }

    .item-enter-active {
        opacity: 1;
        transform: translateX(0%);
        transition: opacity 800ms, transform 800ms;
    }

    .item-exit {
        opacity: 1;
    }

    .item-exit-active {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 800ms, transform 800ms;
    }

    .empty-item-enter {
        opacity: 0;
        transform: translateX(-100%);
        bottom: -15px;
    }

    .empty-item-enter-active {
        opacity: 1;
        bottom: 0;
        transition: opacity 800ms, bottom 800ms;
    }

    .empty-item-exit {
        opacity: 1;
    }

    .empty-item-exit-active {
        opacity: 0;
        bottom: -15px;
        transition: opacity 800ms, bottom 800ms;
    }

    .header {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px 10px;

        span:last-child {
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
            font-size: 11px;
            line-height: 17px;
        }
    }

    .body {
        padding: 15px 20px 20px;
        min-height: 250px;

        .empty {
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;

            .ant-empty-description {
                color: ${(props: any) => props.theme[COLORS.SECONDARY]};
                font-size: 13px;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        font-size: 13px;
        line-height: 20px;
    }

    &.dense {
        border: none;

        .header {
            padding: 13px 15px 10px;
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};

            span:last-child {
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
            }
        }

        .body {
            padding: 10px 15px 50px;
        }
    }
    .ant-pagination {
        margin-top: 0;
        border-top: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};

        .ant-pagination-total-text {
            margin-right: auto;
            span {
                color: ${(props: any) => props.theme[COLORS.TERMS_AND_CONDITIONS_BORDER]};
            }
        }
    }
`;

export default style();
