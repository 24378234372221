import { Button, Col, Divider, Form, Input, Modal, notification, Row } from 'antd';
import { useSelector } from 'react-redux';
import { Eye, EyeOff } from 'react-feather';
import React, { useState } from 'react';
import styled from 'styled-components';
import PasswordInput from '../../../utils/FormInputs/Password';
import style from './styles';
import { authenticatedRequest } from '../../../api';
import { change_password } from '../../../apis/authentication';
import { RootState } from '../../../store/reducers/rootReducers';
import { getUser } from '../../../config';
import { bank_change_password } from '../../../apis/bank-authentication';

const EditPasswordWrapper = styled.div`
    ${style}
`;

interface Values {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface EditPasswordProps {
    visible: boolean;
    handleCancel: () => void;
    onEditPassword: (values: Values) => void;
}
const EditPassword: React.FunctionComponent<EditPasswordProps> = ({ visible, handleCancel }: EditPasswordProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const auth: any = useSelector((state: RootState) => state.auth);
    const isBank = getUser() == 'BANK';

    const onFinish = async (values: Values) => {
        const formData: any = { ...values };
        try {
            if (!isBank) delete formData?.confirmPassword;
            setLoading(true);
            const data = await authenticatedRequest(
                isBank
                    ? { 'x-business-account-id': undefined, 'x-bank-user-id': auth?.user?.userId }
                    : { 'x-user-id': auth?.user?.userId },
            )
                .post(
                    isBank ? bank_change_password : change_password,
                    isBank ? { ...formData, userId: auth?.user?.userId } : formData,
                )
                .then((resp) => resp.data);
            await handleCancel();
            notification.success({
                message: 'Success',
                description: data?.message || 'Password successfully changed!',
            });
        } catch (error: any) {
            notification.error({
                message: 'Error',
                description: error?.response?.data?.message || 'Oops! An unexpected error occurred.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <EditPasswordWrapper>
            <Modal
                title="Change Password"
                visible={visible}
                onCancel={handleCancel}
                width="40%"
                footer={null}
                maskClosable={false}
                destroyOnClose
            >
                <Form layout="vertical" name="Edit-Password" onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                name="currentPassword"
                                label="Enter Current Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your current password!',
                                    },
                                ]}
                                className="label"
                            >
                                <Input.Password
                                    className="gapstack-input"
                                    placeholder="Current Password"
                                    iconRender={(visible) => (visible ? <Eye size="14px" /> : <EyeOff size="14px" />)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <PasswordInput
                                formItemName="newPassword"
                                formItemLabel="Create Password"
                                placeholder="Password"
                                required={true}
                                disableAutoFill
                                passwordType="password"
                            />
                        </Col>
                        <Col span={12}>
                            <PasswordInput
                                formItemName="confirmPassword"
                                formItemLabel="Confirm Password"
                                placeholder="Confirm Password"
                                required={true}
                                disableAutoFill
                                passwordType="confirmPassword"
                                dependencies={['newPassword']}
                                confirmPasswordOn={'newPassword'}
                            />
                        </Col>
                    </Row>

                    <Divider type="horizontal" />
                    <Row className="button-container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCancel} className="gapstack-cancel-button" style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" className="gapstack-button" loading={loading}>
                            Save Changes
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </EditPasswordWrapper>
    );
};

export default EditPassword;
