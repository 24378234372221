import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';
import { Constant } from '../../../../config';

const style = () => css`
    .add-wrapper {
        min-height: 100vh;
        max-width: 620px;
        margin: auto;
    }
    .logo {
        margin: 60px auto 30px;
        width: ${Constant.logoWidth};
        height: ${Constant.logoHeight};
    }
    .back {
        position: fixed;
        left: 90px;
        top: 50px;
        border: none;
        outline: none;
        background: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        .back-arrow {
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
    }

    .container {
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        flex: 1;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 42px 0px 0px 0px;
        justify-content: center;
        .success-logo {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }

        .button-container {
            padding: 20px 40px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            .right-button {
                margin-left: auto;
            }
        }
    }
`;

export default style();
