import React, { useEffect, useState } from 'react';
import { Row, Col, Button, List, notification } from 'antd';
import styled from 'styled-components';
import style from './styles';
import { authenticatedRequest } from '../../../../../api';
import { get_banks } from '../../../../../apis/business-accounts';

const Card1Wrapper = styled.div`
    ${style}
`;

interface Props {
    title: string;
    loading?: boolean;
    accounts: any[] | any;
    onEdit?: (financeId) => void;
    onNew?: () => void;
}

const FinancialInformation = ({ title, accounts, onEdit, onNew, loading }: Props) => {
    const financeRef = React.useRef<string | null>(null);
    const [banks, setBanks] = useState<any>([]);

    const onEditPress = React.useCallback(() => {
        onEdit?.(financeRef.current);
    }, [onEdit]);

    const canEdit = onEdit && Array.isArray(accounts) && accounts?.length > 0;
    const canAdd = onNew && Array.isArray(accounts) && accounts?.length == 0;

    const fetchBanks = () => {
        authenticatedRequest()
            .get(get_banks)
            .then((res) => {
                setBanks(res.data.sort((a, b) => a.name > b.name));
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        fetchBanks();
    }, []);
    const getBankName = (financial: any) => {
        const foundIndex = banks.findIndex((bank) => bank['id'] === financial.bankName);
        if (foundIndex > -1) return banks[foundIndex]?.name;
        return financial?.bankName ?? '-';
    };

    return (
        <Card1Wrapper>
            <div className="account-settings">
                <List
                    loading={loading}
                    itemLayout="vertical"
                    dataSource={accounts}
                    header={
                        <>
                            <div className="list-title">{title}</div>
                            <div className="list-action">
                                {canAdd && (
                                    <Button className={'actions'} onClick={onNew}>
                                        Add
                                    </Button>
                                )}
                                {canEdit && (
                                    <Button className={'actions'} onClick={onEditPress}>
                                        Edit
                                    </Button>
                                )}
                            </div>
                        </>
                    }
                    renderItem={(financial: any) => {
                        financeRef.current = financial.id;
                        return (
                            <List.Item>
                                <Row>
                                    <Col span={11} className="col-wrapper">
                                        <span className="col-title">Bank Name:</span>
                                        <div className="col-value">{getBankName(financial)}</div>
                                    </Col>
                                    <div className="line" />
                                    <Col span={12} className="col-wrapper col-right">
                                        <span className="col-title">Account Number:</span>
                                        <div className="col-value">{financial.accountNumber}</div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={11} className="col-wrapper">
                                        <span className="col-title">Account Name:</span>
                                        <div className="col-value">{financial.accountName}</div>
                                    </Col>
                                    <div className="line" />
                                    <Col span={12} className="col-wrapper col-right">
                                        <span className="col-title">Created On:</span>
                                        <div className="col-value">
                                            {new Date(financial.createdAt).toLocaleDateString()}
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>
                        );
                    }}
                />
            </div>
        </Card1Wrapper>
    );
};
export default FinancialInformation;
