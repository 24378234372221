import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const globalSelect = () => css`
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
        color: ${(props: any) => props.theme[COLORS.PRIMARY]};
        font-weight: 500;
    }
    .ant-menu-item:hover,
    .ant-menu-item-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
        color: ${(props: any) => props.theme[COLORS.PRIMARY]};
    }
    .country-code-selector {
        .ant-select-selector {
            height: 50px !important;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]} !important;
            border-right-width: 0 !important;
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-select-selector {
        font-weight: 500;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        .ant-select-selection-placeholder {
            line-height: 43px !important;
        }
    }
    .autocomp {
        border: none;
        height: 45px;
        background: transparent;
        .ant-select-selector {
            height: 45px !important;
            line-height: 43px !important;
            border-radius: 5px !important;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        }
    }
    .select {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 40px;
            }
            .ant-select-arrow {
                top: 33% !important;
            }
        }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 43px;
        border-radius: 5px;
    }

    .ant-select-selector > .ant-select-selection-placeholder {
        line-height: 43px;
    }
    .select-icon {
        color: ${(props) => props.theme[COLORS.ICONS]};
        width: 15px;
    }
    //.ant-select-arrow {
    //    top: 33%;
    //}

    .form-select {
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
        width: 100%;
        height: 45px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 45px !important;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]} !important;
            .ant-select-selection-item {
                line-height: 45px !important;
            }
        }
    }
`;

export default globalSelect;
