import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

const TradeFinanceComponent = (props: any) => (
    <AuthenticatedLayout>
        <TradeFinance {...props} />
    </AuthenticatedLayout>
);
const TradeFinance = Loadable({
    loader: () => import('./trade-finance/table' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const NewImportOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <NewImportOrder {...props} />
    </AuthenticatedLayout>
);
const NewImportOrder = Loadable({
    loader: () => import('./trade-finance/add-import-order' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

const AddNewImportOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <AddImportOrder {...props} />
    </AuthenticatedLayout>
);
const AddImportOrder = Loadable({
    loader: () => import('./trade-finance/add-new' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
export const LetterOfCredit = Loadable({
    loader: () => import('./trade-finance/letter-of-credit/create' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const MakePaymentsFinanceComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <MakePaymentsFinance {...props} />
    </AuthenticatedLayout>
);
const MakePaymentsFinance = Loadable({
    loader: () => import('./trade-finance/make-payments' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
export const EditLetterOfCredit = Loadable({
    loader: () =>
        import('./trade-finance/letter-of-credit/edit-letter-of-credit' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

const ViewLetterOfCredit = Loadable({
    loader: () => import('./trade-finance/letter-of-credit/view-details' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

export const ViewLetterOfCreditComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewLetterOfCredit {...props} />
    </AuthenticatedLayout>
);

export const CloseLetterOfCredit = Loadable({
    loader: () => import('./trade-finance/close-lc' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

export const BulkImportOrder = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <BulkImportOrderTable {...props} />
    </AuthenticatedLayout>
);

export const BulkImportOrderTable = Loadable({
    loader: () =>
        import('./trade-finance/add-new/upload-import-order/bulk-upload-table' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

const UploadBulkImportOrderComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <UploadImportOrder {...props} />
    </AuthenticatedLayout>
);
const UploadImportOrder = Loadable({
    loader: () => import('./trade-finance/add-new/upload-import-order' /* webpackChunkName: "Purchase-Orders" */),
    loading() {
        return <Loader />;
    },
});

const ViewImportOrderComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewImportOrder {...props} />
    </AuthenticatedLayout>
);
const ViewImportOrder = Loadable({
    loader: () => import('./trade-finance/view-trade-finance' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
export const TrackOrder = Loadable({
    loader: () => import('./components/view-track-trade' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const ReceivedBillsComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <ReceivedBills {...props} />
    </AuthenticatedLayout>
);
const ReceivedBills = Loadable({
    loader: () => import('./trade-finance/received-bills' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const ViewReceivedBillsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewReceivedBills {...props} />
    </AuthenticatedLayout>
);
const ViewReceivedBills = Loadable({
    loader: () => import('./trade-finance/received-bills/view-trade-finance' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
// export const ImportTadeLayout = Loadable({
//     loader: () => import('./import-trade-layout' /* webpackChunkName: "Import trade" */),
//     loading() {
//         return <Loader />;
//     },
// });
const ImportServicesComponent = (props: any) => (
    <AuthenticatedLayout>
        <ImportServices {...props} />
    </AuthenticatedLayout>
);
const ImportServices = Loadable({
    loader: () => import('./import-services' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const ShipmentTrackingComponent = (props: any) => (
    <AuthenticatedLayout>
        <ShipmentTracking {...props} />
    </AuthenticatedLayout>
);
const ShipmentTracking = Loadable({
    loader: () => import('./import-services/shipment-tracking' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});
const SecureImportsComponent = (props: any) => (
    <AuthenticatedLayout>
        <SecureImports {...props} />
    </AuthenticatedLayout>
);
const SecureImports = Loadable({
    loader: () => import('./secure-imports' /* webpackChunkName: "Import trade" */),
    loading() {
        return <Loader />;
    },
});
export const AddProformaInvoice = Loadable({
    loader: () => import('./trade-finance/letter-of-credit/create/components' /* webpackChunkName: "Import-trade" */),
    loading() {
        return <Loader />;
    },
});

export {
    TradeFinanceComponent,
    ViewImportOrderComponent,
    SecureImportsComponent,
    ImportServicesComponent,
    ShipmentTrackingComponent,
    TradeFinance,
    NewImportOrderComponent,
    MakePaymentsFinanceComponent,
    ReceivedBillsComponent,
    ViewReceivedBillsComponent,
    AddNewImportOrderComponent,
    UploadBulkImportOrderComponent,
};
