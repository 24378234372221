import React, { ReactNode } from 'react';
import styled from 'styled-components';
import style from './styles';

const PlainWrapper = styled.div`
    ${style}
`;

interface IProps {
    children: ReactNode;
}

const PlainLayout = ({ children }: IProps) => {
    return <PlainWrapper>{children}</PlainWrapper>;
};

export default PlainLayout;
