import { FormInstance, Form, Input, Button, Divider } from 'antd';
import React, { FunctionComponent } from 'react';
import { AlertCircle, Eye, EyeOff } from 'react-feather';
import { BusinessWrapper, Constant, getUser, Users } from '../../../../config';

interface Props {
    title?: string;
    subtitle?: string;
    form: FormInstance;
    onFinish: (values: any) => void;
    goToForgotPassword: () => void;
    signingIn: boolean;
    goToSignUp: () => void;
    onMouseOver?: () => void;
    err?: string;
    passwordLabel?: string;
}

const CustomSignInForm: FunctionComponent<Props> = ({
    title,
    subtitle,
    form,
    onFinish,
    goToForgotPassword,
    signingIn,
    goToSignUp,
    onMouseOver,
    err,
    passwordLabel = 'Password',
}: Props) => {
    const isBank = getUser() == Users.BANK;

    return (
        <div className="form-container">
            <div className="title-container">
                {title ? <div className="sign-in-title">{title}</div> : null}
                {subtitle ? <span className="sign-in-subtitle">{subtitle}</span> : null}
            </div>
            <Form form={form} style={{ width: '100%', marginTop: 20 }} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Email/Phone Number"
                    name={isBank ? 'email' : 'emailOrPhone'}
                    style={{ marginBottom: 15 }}
                    rules={[{ required: true, message: 'Please input email or phone number' }]}
                >
                    <Input placeholder="Email/Phone Number" className="gapstack-input" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label={passwordLabel}
                    name="password"
                    style={{ marginBottom: 15 }}
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        placeholder={passwordLabel}
                        className="gapstack-input"
                        style={{ width: '100%' }}
                        iconRender={(status) => (status ? <Eye size="14px" /> : <EyeOff size="14px" />)}
                    />
                </Form.Item>
                {err && err != '' && (
                    <div className="sign-in-err-wrapper">
                        <AlertCircle className="icon" />
                        <span>{err}</span>
                    </div>
                )}
                <div style={{ textAlign: 'right', marginTop: -10 }}>
                    <Button type="link" className="button-forgot" onClick={goToForgotPassword}>
                        Forgot Password?
                    </Button>
                </div>
                <Button type="primary" loading={signingIn} htmlType="submit" className="gapstack-button" block>
                    Sign In
                </Button>
            </Form>
            <BusinessWrapper>
                <Divider className="form-divider-sign-in" />
                <div>
                    <Button
                        style={{
                            backgroundColor: Constant.secondaryColor || '#003B4D',
                            borderColor: Constant.secondaryColor || '#003B4D',
                        }}
                        block
                        className="gapstack-button"
                        onClick={goToSignUp}
                        onMouseOver={onMouseOver}
                    >
                        Create Your Business Account
                    </Button>
                </div>
            </BusinessWrapper>
        </div>
    );
};
export default CustomSignInForm;
