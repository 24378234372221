import { DEFAULT_COLORS } from './defaultColors';
import getEnvTheme from './themes/getEnvTheme';

const generateTheme = () => {
    const eveColors = getEnvTheme();
    return Object.assign({}, DEFAULT_COLORS, eveColors);
};

const LIGHT_THEME = generateTheme();

const COLORS = {
    PRIMARY: 'PRIMARY',
    PRIMARY_TEXT: 'PRIMARY_TEXT',
    SECONDARY: 'SECONDARY',
    PARAGRAPH: 'PARAGRAPH',
    BACKGROUND: 'BACKGROUND',
    BACKGROUND_LIGHT: 'BACKGROUND_LIGHT',
    SECOND_BUTTON: 'SECOND_BUTTON',
    EDIT_BUTTON: 'EDIT_BUTTON',
    BACKGROUND_STEPS: 'BACKGROUND_STEPS',

    //FORMS
    ICONS: 'ICONS',
    POP_UP: 'POP_UP',
    FORM_COLOR: 'FORM_COLOR',
    BORDER: 'BORDER',
    PLACEHOLDER: 'PLACEHOLDER',
    SECOND_BORDER: 'SECOND_BORDER',
    UPLOAD_COLOR: ' UPLOAD_COLOR',
    SHADOW: 'SHADOW',
    SHADOW_LIGHT: 'SHADOW_LIGHT',
    ACTION_BOX_SHADOW: 'ACTION_BOX_SHADOW',
    DOCUMENT_UPLOADER: 'DOCUMENT_UPLOADER',
    CLOUD_BACKGROUND_UPLOADER: 'CLOUD_BACKGROUND_UPLOADER',
    CLOUD_BACKGROUND_UPLOADER_ERROR: 'CLOUD_BACKGROUND_UPLOADER_ERROR',
    DOCUMENT_UPLOADER_ERROR: 'DOCUMENT_UPLOADER_ERROR',
    TRANSPARENT: 'TRANSPARENT',
    PROFILE_ICON: 'PROFILE_ICON',
    PROFILE_ICON_ERROR: 'PROFILE_ICON_ERROR',
    COLOR_PICKER: 'COLOR_PICKER',
    COLOR_PICKER_BORDER: 'COLOR_PICKER_BORDER',
    COLOR_PICKER_SHADOW: 'COLOR_PICKER_SHADOW',
    TERMS_AND_CONDITIONS_COLOR: 'TERMS_AND_CONDITIONS_COLOR',
    TERMS_AND_CONDITIONS_BORDER: 'TERMS_AND_CONDITIONS_BORDER',
    CARD_CONTENT_COLOR: 'CARD_CONTENT_COLOR',
    CARD_SHADOW: 'CARD_SHADOW',
    SECONDARY_BACKGROUND: 'SECONDARY_BACKGROUND',
    ANCHOR: 'ANCHOR',
    TABLE_HEADER_BACKGROUND: 'TABLE_HEADER_BACKGROUND',

    AI_VALIDATION: 'AI_VALIDATION',
    AI_YELLOW: 'AI_YELLOW',

    //STATE
    DECLINED: 'DECLINED',
    SUCCESS: 'SUCCESS',
    APPROVED: 'APPROVED',
    BLACK: 'BLACK',

    ORANGE: 'ORANGE',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

const DEVICE = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`,
};

const DEVICE_MAX = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
};

export { LIGHT_THEME, COLORS, DEVICE, DEVICE_MAX, generateTheme };

export type ColorType = keyof typeof COLORS;
