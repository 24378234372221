import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    .card-wrapper {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]};
        border-radius: 5px;
        .ant-card-head {
            padding: 0px 15px;
        }
        .ant-card-body {
            padding: 15px;
        }
        .ant-card-head-title {
            font-size: 13px;
            font-weight: 600;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .card-description {
            font-size: 13px;
            font-weight: 400;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .margin-top {
            margin-top: 15px;
            .ant-card-body {
                padding: 0;
            }
        }
        .action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            box-shadow: none;
            font-weight: 400;
            font-size: 13px;
            border: none;
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            box-sizing: border-box;
            padding: 10px 15px;
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            letter-spacing: 0.2px;
            .add-icon {
                margin-right: 5px;
            }
        }
        .ant-card-extra {
            padding: 0;
        }
        .document-modified {
            margin-top: 0;
            border-top: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            box-shadow: none;
            .ant-table-thead > tr > th {
                padding: 10px 16px;
            }
        }
        .datatable .ant-table-tbody > tr:nth-child(even) {
            background: ${(props: any) => props.theme[COLORS.POP_UP]} !important;
        }
        .datatable .ant-table-tbody > tr {
            cursor: pointer;
        }
        .datatable .ant-table-tbody > tr > td {
            padding: 10px 16px;
        }
        .status-border {
            border-right: 1px solid ${(props: any) => props.theme[COLORS.BACKGROUND_STEPS]};
            min-width: 25px;
            margin-right: 10px;
        }
        .status {
            min-width: 25px;
            margin-right: 10px;
        }

        .edit-icon {
            position: relative;
            left: -3px;
            top: 2px;
            margin-right: 5px;
            cursor: pointer;
            color: ${(props: any) => props.theme[COLORS.EDIT_BUTTON]};
        }
        .del-icon {
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
        }
        .select {
            width: 100%;
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            width: 100%;

            .ant-select {
                border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
            }
            .ant-form-item-label {
                width: 100%;
            }
            .ant-select-selector {
                height: 43px !important;
                background: #fafafa !important;
                border-radius: 5px !important;
            }

            .ant-select-selection-item {
                display: flex;
                align-items: center;
            }
            .ant-select-arrow {
                top: 40% !important;
            }
            .select-icon {
                color: ${(props) => props.theme[COLORS.ICONS]};
                width: 15px;
            }
            .ant-select-selection-placeholder {
                line-height: 30px !important;
            }
        }
        .inside-number {
            .ant-select-selector {
                height: 35px !important;
                background: none !important;
                border-radius: 0px !important;
                border: none;
                border-left: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
            }
            .ant-select {
                margin-bottom: 5px;
                border: none;
            }
        }
        .custom-select {
            align-items: flex-end;
            position: relative;
            .custom-col {
                position: absolute;
                left: 25%;
                width: 25%;
                top: 42px;
            }
        }
    }
    .label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0px;

        .ant-form-item-label {
            width: 100%;
            text-align: left;
        }
        .ant-form-item-control {
            width: 100%;
        }
        .ant-row {
            width: 100%;
        }
    }
    .heading {
        font-size: 14px;
        font-weight: 600;
        color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
    }

    .line {
        flex: 0.9;
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        margin-left: 10px;
        height: 27px;
    }

    .alert-wrapper {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        box-shadow: ${(props: any) => props.theme[COLORS.CARD_SHADOW]};
        border-radius: 5px;
        padding: 15px;
        .alert-text {
            font-size: 13px;
            font-weight: 600;
            color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .button-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .gapstack-button {
            height: 30px;
            padding: 0px 15px;
            min-width: 40px;
            margin-top: 10px;
            margin-left: 10px;
        }
        .no-button {
            color: ${(props) => props.theme[COLORS.DECLINED]};
            background: ${(props: any) => props.theme[COLORS.POP_UP]};
            border: 1px solid ${(props) => props.theme[COLORS.DECLINED]};
            margin-left: auto;
        }
    }
`;

export default style();
