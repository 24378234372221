import { useLayoutEffect, useState } from 'react';

export default function useMediaQuery(mediaQuery: string) {
    const isSsr = typeof window === 'undefined';

    const [matches, setMatches] = useState(() => (isSsr ? false : window.matchMedia(mediaQuery).matches));
    useLayoutEffect(() => {
        if (isSsr) {
            return;
        }
        const mediaQueryList = window.matchMedia(mediaQuery);
        const listener = (e: any) => setMatches(e.matches);
        try {
            // Chrome & Firefox
            mediaQueryList.addEventListener('change', listener);
        } catch (e1) {
            try {
                // Safari
                mediaQueryList.addListener(listener);
            } catch (e2) {
                console.error(e2);
            }
        }
        return () => {
            try {
                mediaQueryList.removeEventListener('change', listener);
            } catch (err) {
                try {
                    mediaQueryList.removeListener(listener);
                } catch (err) {
                    console.log(err);
                }
            }
        };
    }, [mediaQuery]);
    return matches;
}

export const getObjectValue = (obj: any, dataIndex: string | number | (string | number)[], returnType: string) => {
    if (typeof dataIndex == 'string' || typeof dataIndex == 'number') {
        return obj[dataIndex];
    } else if (Array.isArray(dataIndex)) {
        let schema = JSON.parse(JSON.stringify(obj));
        const len = dataIndex.length;
        for (let i = 0; i < len - 1; i++) {
            const elem = dataIndex[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }

        return schema[dataIndex[len - 1]];
    } else {
        return returnType == 'obj' ? undefined : '-';
    }
};
