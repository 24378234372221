import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const ExportEscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <ExportEscrow {...props} />
    </AuthenticatedLayout>
);
const ExportEscrow = Loadable({
    loader: () => import('./index' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});
const TrackExportEscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <TrackExportEscrow {...props} />
    </AuthenticatedLayout>
);
const TrackExportEscrow = Loadable({
    loader: () => import('./view-escrow' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});
const ViewExportEscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewExportEscrow {...props} />
    </AuthenticatedLayout>
);
const ViewExportEscrow = Loadable({
    loader: () => import('./view-escrow' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});

export { ExportEscrowComponent, TrackExportEscrowComponent, ViewExportEscrowComponent };
