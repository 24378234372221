const data = [
    {
        card: 'Restricted API Keys',
        id: 6,
        class: 'table',
        data: [
            {
                type: '',
                name: '',
                prop: '',
                type2: '',
                name2: '',
                prop2: '',
                class: 'full-row-table',
                add_slash: 'string',
            },
        ],
    },
];
export { data };
