import { titleCase } from '../../../../utils/TitleCase';

export const mapGuaranteeData = (data): any => {
    try {
        const keys = Object.keys(data);
        const returnData = keys.reduce(
            (prev, key) => {
                const _data = data[key];

                if (typeof _data == 'object') {
                    if (!Array.isArray(_data) && _data) {
                        const InnerKeys = Object.keys(_data);
                        InnerKeys.forEach((_key) => {
                            prev[`${key}_${_key}`] = _data[_key];
                        });
                    } else {
                        prev[key] = _data;
                    }
                } else {
                    prev[key] = _data;
                }
                return prev;
            },
            {
                status: data?.status,
            },
        );

        returnData['guaranteeType'] = titleCase(returnData['guaranteeType']?.split('_')?.join(' '));
        returnData['creatorType'] = 'Business User';

        return returnData;
    } catch (e: any) {
        throw new Error(e);
    }
};
