import ContactInformation from './ContactInformation';
import BusinessInformation from './BusinessInformation';
import PaymentsInformation from './PaymentInformation';
import PhysicalAddress from './PhysicalAddress';
export { ContactInformation, BusinessInformation, PaymentsInformation, PhysicalAddress };

export const loaderData = [
    { title: 'Contact Information', rows: 4 },
    { title: 'Business Information', rows: 4 },
    { title: 'Physical Address', rows: 2 },
];
