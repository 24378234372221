import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = () => css`
    margin-top: 10px;
    display: flex;
    align-items: center;
    .account-button {
        background: ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        width: 200px;
        height: 50px;
        max-width: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        .account-button-content-container {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 100%;
            .profile-icon {
                background: ${(props: any) => props.theme[COLORS.PROFILE_ICON]};
                box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
                height: 30px;
                width: 30px;
                min-width: 30px;
                min-height: 30px;
                border-radius: 15px;
                margin-right: 10px;
            }

            .business-name {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 70%;
            }
        }
    }
    .search-icon {
        margin-left: auto;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        width: 20px;
        height: 20px;
    }
    .mobile-menu {
        margin-left: 10px;
        width: 20px;
        height: 20px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }
    .red-circle {
        width: 10px;
        height: 10px;
        background: ${(props: any) => props.theme[COLORS.DECLINED]};
        border-radius: 10px;
        position: absolute;
        right: 15px;
        top: 25px;
    }
`;
const sideDropDownStyle = () => css`
    background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    height: 100vh;
    width: 100vw;
    margin-top: -49px;
    display: flex;
    .side-bar-extra {
        flex: 0.1;
    }
    .side-bar {
        flex: 1;
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border-radius: 5px;
        padding-bottom: 30px;
        overflow-y: auto;
        overflow-x: hidden;
        .profile-icon-wrapper {
            display: flex;
            align-items: center;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            width: 90%;
            margin: 20px auto 5px;
            padding: 20px;
            border-radius: 5px;
            box-shadow: ${(props: any) => props.theme[COLORS.SHADOW_LIGHT]};
        }
        .user-name {
            font-size: 14px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            overflow: hidden;
            text-overflow: ellipsis;
            width: 60%;
            margin-left: 10px;
            font-weight: 600;
        }
        .right-icon {
            margin-left: auto;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .gapstack-menu {
            padding: 0 !important;
            width: 95%;
        }
    }
`;
export const sideDropDown = sideDropDownStyle();

export default style();
