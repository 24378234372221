import { css } from 'styled-components';
// import { COLORS } from '../../../config/theme';

const style = () => css`
    .container {
        flex: 1;
        padding-top: 10px;
        padding-bottom: 30px;
    }
`;

export default style();
