import React from 'react';
import Loadable from 'react-loadable';
import AuthenticatedLayout from '../../components/Layouts/Authenticated';
import Loader from '../../utils/Loader';

const BankSettings = Loadable({
    loader: () => import('./home' /* webpackChunkName: "Settings" */),
    loading() {
        return <Loader />;
    },
});
const BankSettingsComponent = (props: any) => (
    <AuthenticatedLayout>
        <BankSettings {...props} />
    </AuthenticatedLayout>
);

export { BankSettingsComponent, BankSettings };
