import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import AuthenticatedLayout from '../Layouts/Authenticated';

export const AccountsTableViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <AccountsTableView {...props} />
    </AuthenticatedLayout>
);

const AccountsTableView = Loadable({
    loader: () => import('./table'),
    loading() {
        return <Loader />;
    },
});

export default {
    AccountsTableViewComponent,
};
