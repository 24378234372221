import { Button, Card, Col, Input, Row, Form } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useEffect } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { mapConfirmData } from '../../helpers/mapData';
import STKCharges from './STKCharges';
import style from './styles';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;

const defaultList = [
    'Coffee',
    'Youth Owned Business',
    'Horticulture',
    'Agriculture',
    'Online Retail',
    'Woman Owned',
    'EPZ',
    'Curios Export',
    'Refugee',
    'Intra Africa Trade',
    'Education',
    'Hospitality',
    'Family Owned',
    'Welfare Business',
    'Import Business',
    'FMCG',
    'Export Business',
];

interface AboutBusinessProps {
    data: any;
    buttonLoading: boolean;
}

const AboutBusiness: FunctionComponent<AboutBusinessProps> = ({ data, buttonLoading }: AboutBusinessProps) => {
    const [businesses, setBusinesses] = useState<string[]>([...defaultList]);
    const [values, setValues] = useState<string>('');
    const [form] = Form.useForm();
    const addBusiness = () => {
        if (values?.trim() == '') return;
        setBusinesses([...businesses, ...values?.split(',').map((val) => val + '__')]);
        setValues('');
    };

    const toggleActive = (business: string, index: number) => {
        const temp_list = [...businesses];
        if (business.includes('__')) {
            temp_list[index] = business.replace('__', '');
        } else {
            temp_list[index] = business + '__';
        }
        setBusinesses([...temp_list]);
        form.setFieldsValue({
            aboutBusiness: [...temp_list]
                .filter((val) => val.includes('__'))
                .map((val) => val.replace('__', ''))
                .join(','),
        });
    };
    useEffect(() => {
        if (data?.aboutBusiness) {
            const activeTags = data?.aboutBusiness?.split(',') || [];
            const newTags: string[] = [];
            [...businesses].map((val) => {
                if (activeTags.indexOf(val) > -1) {
                    newTags.push(val + '__');
                } else {
                    newTags.push(val);
                }
            });
            const exemptedTags = activeTags.filter((val) => defaultList.indexOf(val) < 0).map((val) => val + '__');
            setBusinesses(
                [...newTags, ...exemptedTags].reduce((a, b) => {
                    if (a.indexOf(b) < 0) {
                        a.push(b);
                    }
                    return a;
                }, []),
            );
            data && form.setFieldsValue(mapConfirmData(data)?.['contact']);
            form.setFieldsValue({ aboutBusiness: data?.aboutBusiness });
        }
    }, [data]);

    return (
        <ConfirmDetailsWrapper>
            <Form name="submitbutton" form={form}>
                <Card title="About Business" className="card-wrapper card-margin-top" style={{ paddingBottom: 20 }}>
                    <Form.Item name="aboutBusiness" hidden />
                    <div className="card-description">Which of the following best describes your buiness?</div>
                    <div className="businesses-wrapper">
                        {businesses.map((business, index) => (
                            <div
                                className={`business-wrapper ${business.includes('__') ? 'active' : ''}`}
                                key={index}
                                onClick={() => toggleActive(business, index)}
                            >
                                {business.replace('__', '')}
                            </div>
                        ))}
                    </div>

                    <Row gutter={20}>
                        <div className="about-business-label">
                            Don’t see what best describes your business? Add it below. You can add more than one
                            category by separating them with a coma.
                        </div>
                        <Col span={12}>
                            <Input
                                className="gapstack-input"
                                value={values}
                                placeholder="FinTech, Corporation, Non-profit"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setValues(e.currentTarget.value)}
                            />
                        </Col>
                        <Col span={12}>
                            <Button className="add-button" onClick={addBusiness}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Card>
                {data?.businessType?.name == 'Company' &&
                    process.env.REACT_APP_PAY_FOR_CR12_SEARCH?.toLowerCase() !== 'false' && <STKCharges />}
                <div className="button-container">
                    <Button className="gapstack-button" loading={buttonLoading} htmlType="submit">
                        Next
                    </Button>
                </div>
            </Form>
        </ConfirmDetailsWrapper>
    );
};

export default AboutBusiness;
