import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    background-color: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    flex: 1;
    flex-direction: column;
    display: flex;

    .heading {
        margin: 0 0 0 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};

        span {
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
    }
`;

export default style();
