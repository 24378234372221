import React, { useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import { authenticatedRequest } from '../../../api';
import styled from 'styled-components';
import style from './styles';
import uniqBy from 'lodash/uniqBy';

const { Option } = Select;

interface Props {
    formItemClassName?: string;
    wrapperWidth?: string;
    formItemLabel: string;
    formItemName: string | (string | number)[];
    required: boolean;
    message: string;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    hasFeedback?: boolean;
    disabled?: boolean;
}

const CurrencyWrapper = styled.div`
    ${style}
`;

const SelectCurrency = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    validateStatus,
    formItemClassName = 'label',
    hasFeedback = false,
    wrapperWidth,
    disabled,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currencies, setCurrencies] = useState<any>([]);

    const fetchCountries = () => {
        setLoading(true);
        authenticatedRequest()
            .get(`data-provider/data-provider`)
            .then((res) => {
                setCurrencies(res.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <CurrencyWrapper width={wrapperWidth}>
            <Form.Item
                initialValue={initialValue ? initialValue : process.env.REACT_APP_DEFAULT_CURRENCY}
                label={formItemLabel}
                name={formItemName}
                className={formItemClassName}
                rules={[{ required, message }]}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    showSearch
                    defaultValue={process?.env?.REACT_APP_DEFAULT_CURRENCY}
                    loading={loading}
                    className="form-select select"
                    allowClear
                    disabled={disabled}
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    data-testid="select-currency"
                >
                    {uniqBy(currencies, 'currency').map((country) => (
                        <Option key={country?.country_id} value={country?.currency}>
                            {country?.currency}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </CurrencyWrapper>
    );
};

export default SelectCurrency;
