export enum DocumentName {
    STANDARD = 'Standard',
    COI = 'COI',
    CR12 = 'CR12',
    BUSINESS_LICENSE = 'Business License',
    MEMORANDOM_AND_ARTICLES_OF_ASSOCIATION = 'MAOA',
    PIN_CERTIFICATE = 'PIN Certificate',
    TAX_COMPLIANCE = 'Tax Compliance',
    DIRECTOR_ID = 'Director Id',
    BUSINESS_REGISTRATION = 'Business Registration',
    SIGNED_TERMS_AND_CONDITION = 'Signed Terms&Condition',
    BOARD_RESOLUTION = 'Board Resolution',
    AUDITED_ACCOUNT = 'Audited Account',
    BANK_STATEMENT = 'Bank Statement',
}
export enum DocumentType {
    KYC = 'KYC',
    STANDARD = 'Standard',
}
export enum DocumentPageType {
    STANDARD = 'Standard',
    FRONT = 'Front',
    BACK = 'Back',
}

export enum DocumentNameAndType {
    COI = 'Certificate of Incorporation',
    CR12 = 'CR12',
    BUSINESS_LICENSE = 'Business License',
    MEMORANDOM_AND_ARTICLES_OF_ASSOCIATION = 'Memorandum & Articles of Association',
    PIN_CERTIFICATE = 'PIN Certificate',
    TAX_COMPLIANCE = 'Tax Compliance',
    DIRECTOR_ID = "Director's Id",
    BUSINESS_REGISTRATION = 'Business Registration',
    SIGNED_TERMS_AND_CONDITION = 'Signed Terms&Condition',
    BOARD_RESOLUTION = 'Board Resolution',
    AUDITED_ACCOUNT = 'Audited Accounts',
    MANAGEMENT_ACCOUNTS = 'Management Accounts',
    BANK_STATEMENT = 'Bank Statement',
    BUSINESS_PERMIT = 'Business Permit',
    BUSINESS_REGISTRATION_CERT = 'Business Registration Certificate',
    NATIONAL_ID = 'National Id',
    PASSPORT = 'Passport Id',
    PARTNER_REGISTRATION_CERT = 'Partnership Registration Certificate',
    LIVE_ID = 'Live Id',
}
