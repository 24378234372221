import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    border-radius: 5px;
    .ant-modal-content {
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    }

    //.ant-modal-content > button.ant-modal-close {
    //    display: none;
    //}

    .ant-modal-content > .ant-modal-header {
        padding: 18px 20px 20px;
        border-bottom-color: #e5e5e5;
    }

    .ant-modal-content > .ant-modal-body {
        padding: 20px 20px 0;

        .ant-form-item {
            margin-bottom: 15px;
        }

        .attachment-list {
            .attachment {
                padding: 5px 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .link {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 10px;
                    color: ${(props: any) => props.theme[COLORS.ANCHOR]};
                }
                .delete {
                    min-width: 32px;
                    color: ${(props: any) => props.theme[COLORS.DECLINED]};
                }

                .link,
                .delete {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .ant-modal-content > .ant-modal-body {
        .action-button {
            display: flex;
            padding: 10px 0 20px;
            border-top: none;

            .collection {
                display: flex;
                flex: 1;
                justify-content: flex-end;
            }

            button {
                box-sizing: border-box;
                border-radius: 5px;
                font-weight: 500;
                font-size: 15px;
                height: 45px;
                padding-left: 20px;
                padding-right: 20px;
            }

            .ant-btn-link {
                color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            }

            .cancel-button {
                background: #fff;
                border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
            }

            .compose-button {
                background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
                color: ${(props: any) => props.theme[COLORS.POP_UP]};
            }

            button + button {
                margin-left: 10px;
            }
        }
    }
`;

export default style;
