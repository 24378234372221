import { ON_SHOW_MODAL, ON_CLOSE_MODAL } from '../types';

const initialState = {
    showDashboardModal: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ON_SHOW_MODAL:
            return {
                showDashboardModal: true,
            };
        case ON_CLOSE_MODAL:
            return {
                showDashboardModal: false,
            };
        default:
            return state;
    }
};
