import React, { useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import { unAuthenticatedRequest } from '../../../api';
import { FormInstance } from 'antd/lib/form';
import { usePrevious } from '../../UsePrevious';
import styled from 'styled-components';
import style from './styles';
import { updateObject } from '../../Helper';

const { Option } = Select;

interface Props {
    formItemLabel: string;
    formItemName: string | (string | number)[];
    required: boolean;
    message: string;
    countryId: string;
    form: FormInstance;
    initialValue?: string;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    onChange?: (e, formName) => void;
    hasFeedback?: boolean;
    formItemClassName?: string;
    onClear?: () => void;
    disabled?: boolean;
}

const CountyWrapper = styled.div`
    ${style}
`;

const SelectCounty = ({
    formItemLabel,
    formItemName,
    required,
    message,
    countryId,
    form,
    initialValue,
    validateStatus,
    onChange,
    hasFeedback = false,
    formItemClassName,
    onClear,
    disabled,
}: Props) => {
    const ref = React.useRef<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [counties, setCounties] = useState<any>([]);

    const previousCountryId = usePrevious(countryId ? countryId : null);

    const fetchCounties = (countryId) => {
        setLoading(true);
        unAuthenticatedRequest()
            .get(`data-provider/data-provider/${countryId}`)
            .then((res) => {
                const counties = res.data.sort((a, b) => a.countyName > b.countyName);
                setCounties(counties);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const clearSelectedCounty = () => {
        if (onClear) {
            onClear();
            return;
        }
        const formObject = {};
        updateObject(formObject, formItemName, null);
        form.setFieldsValue(formObject);
    };

    useEffect(() => {
        if (countryId) {
            if (previousCountryId && previousCountryId !== countryId) {
                clearSelectedCounty();
            }
            fetchCounties(countryId);
            return;
        }
        // clearSelectedCounty();
    }, [countryId]);

    return (
        <CountyWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className={`label ${formItemClassName}`}
                rules={[{ required, message }]}
                initialValue={initialValue}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    ref={ref}
                    showSearch
                    loading={loading}
                    className="form-select select"
                    allowClear
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    onChange={(e) => (onChange ? onChange(e, formItemName) : null)}
                    placeholder="Select county"
                    disabled={disabled}
                    data-testid="select-counties"
                >
                    {counties.map((county) => (
                        <Option key={county?.id} value={county?.countyName}>
                            {county?.countyName}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </CountyWrapper>
    );
};

export default SelectCounty;
