import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Divider, Form, Input, Row, Col, Button, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import help from '../../../assets/Images/help.svg';
import style from './styles';
import { Eye, EyeOff } from 'react-feather';
import { unAuthenticatedRequest } from '../../../api';
import { ROUTES } from '../../../router/urls';
import UnauthenticatedLayout from '../../../components/Layouts/Unauthenticated';
import { put_complete_registration } from '../../../components/authentication/sign-in/api';
import { appIcon } from '../../../config/appIcon';

const SignUpWrapper = styled.div`
    ${style}
`;

const CompleteRegistration = (props: any) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [completingRegistration, setCompletingRegistration] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<any>(null);
    const [type, setType] = React.useState(true);
    const [type2, setType2] = React.useState(true);

    const passwordStrengthChecker = (password) => {
        /*
         * Checking the following
         * at least 1 lowercase alphabetical character
         * at least 1 uppercase alphabetical character
         * at least 1 numeric character
         * at least one special character
         * between 8 and 15 characters long for strong strength
         */
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

        return !!password.match(regex);
    };

    const onFinish = (values) => {
        setCompletingRegistration(true);
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
            confirmPassword: values.confirmPassword,
        };

        unAuthenticatedRequest()
            .put(`${put_complete_registration}/${user.userId}`, data)
            .then(() => {
                setCompletingRegistration(false);
                notification.success({
                    message: 'Completed account registration. Redirecting you to sign in',
                });
                setTimeout(() => {
                    history.push({
                        pathname: ROUTES.SIGN_IN,
                    });
                }, 2000);
            })
            .catch((error) => {
                setCompletingRegistration(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        const newUser = props.location?.state?.user;

        if (newUser !== undefined && Object.keys(newUser).length) {
            const mappedData = {
                email: newUser.email,
                userId: newUser.id,
            };
            setUser(mappedData);
            form.setFieldsValue(mappedData);
        } else {
            setUser(null);
        }
    }, [props.location.state]);

    return (
        <UnauthenticatedLayout>
            <SignUpWrapper>
                <div className="sign-up-wrapper">
                    <Row className="logo-container">
                        <img className="logo" src={appIcon} alt="Logo" />
                    </Row>

                    <div className="container">
                        <Row style={{ padding: '0px 85px' }}>
                            <p className="welcome" style={{ marginBottom: 0 }}>
                                Complete Registration
                            </p>
                        </Row>
                        {/*<Row style={{ padding: '0px 85px' }}>*/}
                        {/*  <span className="info">*/}
                        {/*    {`Over 60,000 businesses of all sizes use Gapstack to accept payments online,*/}
                        {/*                    including some of Africa's biggest brands. This is a placeholder`}*/}
                        {/*  </span>*/}
                        {/*</Row>*/}
                        <div className="top-divider" />
                        <Form
                            form={form}
                            name="sign-in-form"
                            initialValues={user}
                            layout="vertical"
                            onFinish={onFinish}
                            style={{ width: '100%' }}
                        >
                            <Row gutter={20} style={{ padding: '0px 85px' }}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message: 'Please input your email!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            readOnly={true}
                                            type="email"
                                            className="gapstack-input"
                                            placeholder="john@gapstack.com"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={20} style={{ padding: '0px 85px' }}>
                                <Col span={12}>
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your first name!',
                                            },
                                        ]}
                                    >
                                        <Input className="gapstack-input" placeholder="John" />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your last name!',
                                            },
                                        ]}
                                    >
                                        <Input className="gapstack-input" placeholder="Doe" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={20} style={{ padding: '0px 85px' }}>
                                <Col span={12} className="pass">
                                    <Form.Item
                                        label="Create Password"
                                        name="password"
                                        className="label"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            },
                                            () => ({
                                                validator(_rule, value) {
                                                    // check password strength
                                                    if (!value || passwordStrengthChecker(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('Password is not strong!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            type={type ? 'password' : 'text'}
                                            className="gapstack-input"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    {type ? (
                                        <EyeOff className="eye" onClick={() => setType(false)} />
                                    ) : (
                                        <Eye className="eye" onClick={() => setType(true)} />
                                    )}
                                </Col>
                                <Col span={12} className="pass">
                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(
                                                        'The two passwords that you entered do not match!',
                                                    );
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            type={type2 ? 'password' : 'text'}
                                            className="gapstack-input"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    {type2 ? (
                                        <EyeOff className="eye" onClick={() => setType2(false)} />
                                    ) : (
                                        <Eye className="eye" onClick={() => setType2(true)} />
                                    )}
                                </Col>
                            </Row>

                            {/*<Row className="remember-me">*/}
                            {/*  <Form.Item*/}
                            {/*    name="subscribe"*/}
                            {/*    valuePropName="checked"*/}
                            {/*    className="check"*/}
                            {/*  >*/}
                            {/*    <Checkbox />*/}
                            {/*  </Form.Item>*/}
                            {/*  <div className="get-email">*/}
                            {/*    <span className="email">*/}
                            {/*      Get emails from Gapstack and its partner about latest*/}
                            {/*      product updates, industry  news and events*/}
                            {/*    </span>*/}
                            {/*    <span className="unsubs">*/}
                            {/*      If you change your mind, you can unsubscribe at any time.*/}
                            {/*    </span>*/}
                            {/*    <span className="privacy">Privacy policy</span>*/}
                            {/*  </div>*/}
                            {/*</Row>*/}

                            <Divider style={{ borderTop: '1px solid #e5e5e5', margin: 0 }} />

                            <Row className="button-container">
                                <Col span={12} className="left-button-container">
                                    <span className="normal">{`Already have an account?`}</span>
                                    <Button
                                        type="link"
                                        className="button-sign"
                                        style={{ paddingLeft: 1 }}
                                        onClick={() => history.push('/sign-in')}
                                    >
                                        Sign in
                                    </Button>
                                </Col>
                                <Button
                                    loading={completingRegistration}
                                    type="primary"
                                    htmlType="submit"
                                    className="gapstack-button"
                                >
                                    Complete
                                </Button>
                            </Row>
                        </Form>
                    </div>
                </div>
            </SignUpWrapper>
            <Button
                type="primary"
                style={{
                    borderRadius: '22.5px',
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px',
                    textShadow: 'none',
                }}
            >
                <img src={help} alt="help" style={{ marginRight: '5px', top: '1px', position: 'relative' }} />
                Help
            </Button>
        </UnauthenticatedLayout>
    );
};

export default CompleteRegistration;
