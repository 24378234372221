import { Card, Col, DatePicker, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { FunctionComponent, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import Loader from '../../../../utils/Loader';
import { update_business_information } from '../../../../apis/business-accounts';
import { getErrorsList, mapConfirmData } from '../../helpers/mapData';
import moment from 'moment';
import style from './styles';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;
interface CompanyRegistrationProps {
    data: any;
    errorData?: any;
}
export function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
}

export function notBeforeToday(current) {
    return current < moment().add(1, 'day').startOf('day');
}
const CompanyRegistration: FunctionComponent<CompanyRegistrationProps> = ({
    data,
    errorData,
}: CompanyRegistrationProps) => {
    const [form] = useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const onComplete = () => {
        const values = form.getFieldsValue();
        authenticatedRequest()
            .put(update_business_information, values)
            .then((res) => res.data)
            .catch((err) => {
                console.log(err);
                // const resp = err?.response;
                // notification.error({
                //     message: 'Error',
                //     description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                // });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    useEffect(() => {
        data && form.setFieldsValue(mapConfirmData(data)?.['companyRegistration']);
        errorData && form.setFields(getErrorsList(errorData?.companyRegistration || {}));
        setLoading(false);
    }, [data]);
    return (
        <ConfirmDetailsWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form name="companyRegistration" form={form}>
                    <Card title="Company Registration" className="card-wrapper card-margin-top">
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Company Registration Number"
                                    name={'incorporationNumber'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input your registration number!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="9049034" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Registration Date"
                                    name={'registrationDate'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input registration date!' }]}
                                >
                                    <DatePicker
                                        className="gapstack-input"
                                        placeholder="Select document date"
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </ConfirmDetailsWrapper>
    );
};

export default CompanyRegistration;
