import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import style from './styles';

const ContentWrapper = styled.div`
    ${style}
`;

interface IProps {
    children: ReactNode;
    isFullScreen?: boolean;
    custom?: boolean;
}

const ContentLayout = ({ children, isFullScreen, custom }: IProps) => {
    if (custom) {
        return <ContentWrapper>{children}</ContentWrapper>;
    }

    return (
        <ContentWrapper>
            <Row>
                {isFullScreen ? (
                    <Col span={22} offset={1}>
                        {children}
                    </Col>
                ) : (
                    <Col
                        xs={{ span: 20, offset: 2 }}
                        sm={{ span: 20, offset: 2 }}
                        md={{ span: 20, offset: 2 }}
                        lg={{ span: 16, offset: 4 }}
                        xl={{ span: 16, offset: 4 }}
                    >
                        {children}
                    </Col>
                )}
            </Row>
        </ContentWrapper>
    );
};

export default ContentLayout;
