import { Card, Col, Form, notification, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import SelectCounty from '../../../../utils/FormSelects/Counties';
import SelectCountry from '../../../../utils/FormSelects/Countries';
import Loader from '../../../../utils/Loader';
import { create_physical_address, update_physical_address } from '../../../../apis/business-accounts';
import { PlacesField } from '../../../helpers';
import { getErrorsList, mapConfirmData } from '../../helpers/mapData';
import style from './styles';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;
interface PhysicalAddressProps {
    data: any;
    errorData?: any;
}

const PhysicalAddress: FunctionComponent<PhysicalAddressProps> = ({ data, errorData }: PhysicalAddressProps) => {
    const [countrySelected, setSelectedCountry] = useState<any>({});
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);

    const disabled = data?.status == BusinessStatuses?.OnHold;
    const physicalAddress =
        (data?.address && Array.isArray(data?.address) && data?.address.length > 0 && data?.address[0]) || {};
    const onComplete = () => {
        const values = form.getFieldsValue();
        let request: any;
        const id = values?.id;
        if (id) {
            const ob = { ...values };
            delete ob['id'];
            request = authenticatedRequest().put(update_physical_address(id), ob);
        } else {
            request = authenticatedRequest().post(create_physical_address, values);
        }
        request
            .then((res) => res.data)
            .then((res) => {
                const id = values?.id;
                if (!id) {
                    form.setFieldsValue({ id: res?.id });
                }
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    useEffect(() => {
        data && form.setFieldsValue(mapConfirmData(data)?.['physicalAddress']);
        errorData && form.setFields(getErrorsList(errorData?.physicalAddress || {}));
        setLoading(false);
    }, [data]);
    return (
        <ConfirmDetailsWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form name="physicalAddress" form={form}>
                    <Card title="Physical Address" className="card-wrapper card-margin-top">
                        <Form.Item name={'id'} hidden />
                        <Row gutter={20}>
                            <Col span={12}>
                                <SelectCountry
                                    formItemLabel="Country"
                                    formItemName={'country'}
                                    required
                                    onChange={() => true}
                                    message="Please select country"
                                    onCountrySelected={(country) => setSelectedCountry(country)}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col span={12}>
                                <SelectCounty
                                    formItemLabel="County"
                                    formItemName={'county'}
                                    required
                                    onChange={() => true}
                                    message="Please select county"
                                    countryId={countrySelected?.country_id}
                                    form={form}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <PlacesField
                                    form={form}
                                    label={'GPS'}
                                    initialValue={physicalAddress?.gps}
                                    name={'gps'}
                                    required
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </ConfirmDetailsWrapper>
    );
};

export default PhysicalAddress;
