import { Button, Divider, Form, Input, notification, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { unAuthenticatedRequest } from '../../../../api';
import { resend_otp_for_pin, set_up_ussd_pin } from '../../../../apis/authentication';
import { goToRoute, ROUTES } from '../../../../router/urls';
import PageTopBar from '../../../../utils/PageTopBar';
import { actions } from '../../../authentication/sign-in/store/actions';
import ContentLayout from '../../../Layouts/Content';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './styles';
import getEnvTheme from '../../../../config/themes/getEnvTheme';

const SetUpPinWrapper = styled.div`
    ${styles}
`;

const SetUpPinComponent = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [pinLoading, setPinLoading] = useState<boolean>(false);
    const { id }: any = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occured',
        });
    };

    const antIcon = (
        <LoadingOutlined style={{ fontSize: 13, color: getEnvTheme()?.PRIMARY ?? '#7C60FF', marginLeft: 10 }} spin />
    );

    const onSubmit = (values: any) => {
        setLoading(true);
        unAuthenticatedRequest()
            .post(set_up_ussd_pin, { ...values, businessAccountId: id })
            .then((res) => res.data)
            .then(() => {
                setLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'USSD PIN set up successfully',
                });
                history.push({ pathname: goToRoute(ROUTES.DASHBOARD, id) });
                dispatch(actions.onChangePinStatus(true));
            })
            .catch((err) => {
                setLoading(false);
                catchErr(err);
            });
    };

    const onResendOTP = () => {
        setPinLoading(true);
        unAuthenticatedRequest({ 'x-business-account-id': id })
            .post(resend_otp_for_pin)
            .then((res) => res.data)
            .then(() => {
                setPinLoading(false);
                notification.success({
                    message: 'Success',
                    description: 'OTP has been resent successfully',
                });
            })
            .catch((err) => {
                setPinLoading(false);
                catchErr(err);
            });
    };

    return (
        <ContentLayout>
            <SetUpPinWrapper style={{ minHeight: '100vh' }}>
                <PageTopBar lightTitle="Confirm Phone Number" pageTitle="" pathName={goToRoute(ROUTES.DASHBOARD, id)} />
                <div className="container">
                    <div className="card">
                        <div className="heading">Phone Number Confirmation</div>
                        <Divider type="horizontal" className="divider" />
                        <div className="normal-text" style={{ marginTop: 20 }}>
                            Please enter the four digit code sent to {search?.replace('?phoneNumber=', '')} and then
                            set-up your PIN.
                        </div>
                        <div className="normal-text">
                            This is the phone number that will be used by the business when transacting on Whatsapp/
                            Telegram and USSD.
                        </div>
                        <div className="normal-text">
                            {`Didn't receive the code? `}
                            {pinLoading ? (
                                <span>
                                    <Spin indicator={antIcon} />
                                </span>
                            ) : (
                                <span className="resend-code" onClick={onResendOTP}>
                                    Resend code
                                </span>
                            )}
                        </div>
                        <div style={{ height: 20 }} />
                        <Form layout="vertical" onFinish={onSubmit}>
                            <Form.Item
                                label={'Enter Code'}
                                name="otp"
                                rules={[
                                    { required: true, message: 'Please enter PIN' },
                                    () => ({
                                        validator(_rule, value) {
                                            if (/^[0-9]{6}$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('OTP should consist of 6 numbers');
                                        },
                                    }),
                                ]}
                            >
                                <Input className="gapstack-input" placeholder="213123" />
                            </Form.Item>
                            <div className="heading-wrapper">
                                <div className="create-pin">Create PIN</div>
                                <div className="line" />
                            </div>
                            <div style={{ height: 15 }} />
                            <Form.Item
                                label={'PIN'}
                                name="pin"
                                rules={[
                                    { required: true, message: 'Please enter PIN' },
                                    () => ({
                                        validator(_rule, value) {
                                            if (/^[0-9]{4}$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject('PIN should consist of 4 numbers');
                                        },
                                    }),
                                ]}
                            >
                                <Input className="gapstack-input" placeholder="2133" />
                            </Form.Item>
                            <Form.Item
                                label={'Confirm PIN'}
                                name="confirmPin"
                                dependencies={['pin']}
                                rules={[
                                    { required: true, message: 'Please enter PIN' },
                                    ({ getFieldValue }) => ({
                                        validator(_rule, value) {
                                            if (value != getFieldValue('pin')) {
                                                return Promise.reject("PIN doesn't match");
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input className="gapstack-input" placeholder="2133" />
                            </Form.Item>
                            <Divider type="horizontal" className="divider-2" />
                            <div className="button-wrapper">
                                <Button className="gapstack-button" htmlType="submit" loading={loading}>
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </SetUpPinWrapper>
        </ContentLayout>
    );
};

export default SetUpPinComponent;
