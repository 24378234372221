import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import numeral from 'numeral';
import CustomLayout from '../../Layout/CustomLayout';
import { useHistory, useParams } from 'react-router-dom';
import { unAuthenticatedRequest } from '../../../../api';
import { verify_bond } from '../../../../apis/guarantee';
import moment from 'moment';
import { mapGuaranteeData } from '../../../guarantee-bonds/bonds/view-bond/mapGuaranteeData';
import styled from 'styled-components';
import style from './styles';
import { mapGuaranteeStatus } from '../../../guarantee-bonds/bonds/table/data';
import { ROUTES } from '../../../../router/urls';
import { RiFileLine, RiFileList3Line } from 'react-icons/ri';
import { createSearchParams } from '../../../../router/routeHooks';
import WarningModal from '../../../../utils/Modals/Warning';
import getEnvTheme from '../../../../config/themes/getEnvTheme';

const VerifyBondResultsWrapper = styled.div`
    ${style}
`;

const quickLinks = [
    {
        title: 'Get a Bid bond',
        icon: <RiFileLine size="18px" />,
        iconColor: '#7C60FF',
        path: ROUTES.NEW_GUARANTEE,
        params: {
            guaranteeType: 'BID_BOND',
        },
    },
    {
        title: 'Get a Performance Bond',
        icon: <RiFileList3Line size="18px" />,
        iconColor: '#2CCC71',
        path: ROUTES.CUSTOM_BANK_SIGN_UP,
    },
    {
        title: 'Get an Advanced Payment Guarantee',
        icon: <RiFileLine size="18px" />,
        iconColor: '#7C60FF',
        path: ROUTES.CUSTOM_BANK_SIGN_UP,
    },
];

const VerifyBondResults = (props: any) => {
    const history = useHistory();
    const [data, setData] = useState<any>();
    const [noBondFound, setNoBondFound] = useState<boolean>(false);
    const { id }: any = useParams();

    const goToPath = (quickLink) => {
        if (quickLink) {
            const routeData = {
                pathname: quickLink?.path,
            };
            if (quickLink?.params) {
                routeData['search'] = createSearchParams(quickLink.params);
            }
            history.push(routeData);
        }
    };

    const mapData = (data) => {
        const mappedData = mapGuaranteeData(data);
        const validityPeriod = mappedData?.facility_validityPeriod?.split(' ');
        mappedData['validityPeriod'] = moment(data?.facility?.effectiveDate).add(
            validityPeriod?.[0],
            validityPeriod?.[1],
        );
        setData(mappedData);
    };

    useEffect(() => {
        const bondData = props?.location?.state?.bidBond;

        if (bondData && Object.keys(bondData).length) {
            mapData(bondData);
        } else {
            unAuthenticatedRequest()
                .get(verify_bond(id))
                .then((res) => {
                    mapData(res?.data);
                })
                .catch(() => setNoBondFound(true));
        }
    }, []);

    return (
        <VerifyBondResultsWrapper>
            <WarningModal
                visible={noBondFound}
                onOK={() => history.push(ROUTES.EXTERNAL_VERIFY_BOND)}
                onCancel={() => history.push(ROUTES.EXTERNAL_VERIFY_BOND)}
                title="No Bid Bond Found"
                description={`Sorry. We couldn’t find the Bid Bond No. ${id}. Please ensure you have the right Bid Bond number
                    and try again.`}
            />
            <Row>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 24, offset: 0 }}
                    lg={{ span: 24, offset: 0 }}
                    xl={{ span: 20, offset: 2 }}
                    xxl={{ span: 16, offset: 4 }}
                >
                    <CustomLayout>
                        <Row gutter={16}>
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={{ offset: 4, span: 16 }}
                                xl={{ offset: 4, span: 16 }}
                                xxl={{ offset: 4, span: 16 }}
                            >
                                <div className="results-container">
                                    <div className="title-container">
                                        <div>
                                            <span className="results-title">Results for Bid Bond - {id}</span>
                                        </div>
                                    </div>
                                    <Row gutter={16} className="results-content-container">
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <p className="title">
                                                Requestor: <span className="text">{data?.businessName}</span>
                                            </p>
                                            <p className="title">
                                                Type: <span className="text">{data?.guaranteeType}</span>
                                            </p>
                                            <p className="title">
                                                Amount:{' '}
                                                <span className="text">
                                                    {numeral(data?.facility_amountRequired).format('0,0.00')}
                                                </span>
                                            </p>
                                            <p className="title">
                                                Status:{' '}
                                                <span
                                                    className="text"
                                                    style={{ color: `${mapGuaranteeStatus(data?.status)?.color}` }}
                                                >
                                                    {mapGuaranteeStatus(data?.status)?.status}
                                                </span>
                                            </p>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                            <p className="title">
                                                Start Date:{' '}
                                                <span className="text">
                                                    {moment(data?.facility_effectiveDate).format('DD/MM/YYYY HH:mm')}
                                                </span>
                                            </p>
                                            <p className="title">
                                                Expiry Date:{' '}
                                                <span className="text">
                                                    {data?.validityPeriod.format('DD/MM/YYYY HH:mm')}
                                                </span>
                                            </p>
                                            <p className="title">
                                                Validity: <span className="text">{data?.facility_validityPeriod}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="results-container" style={{ marginTop: 20 }}>
                                    <div className="title-container">
                                        <div>
                                            <span className="normal-title">Procuring Entity</span>
                                        </div>
                                    </div>
                                    <Row gutter={16} className="results-content-container">
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className="title">
                                                Name: <span className="text">{data?.buyer_companyName}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>

                                <Row
                                    style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}
                                >
                                    <Button
                                        className="gapstack-button"
                                        style={{
                                            backgroundColor: getEnvTheme()?.PRIMARY ?? '#7C60FF',
                                            borderColor: getEnvTheme()?.PRIMARY ?? '#7C60FF',
                                        }}
                                        onClick={() => history.push(ROUTES.EXTERNAL_VERIFY_BOND)}
                                    >
                                        Done
                                    </Button>
                                </Row>

                                <Row style={{ marginTop: 20 }}>
                                    {quickLinks.map((quickLink, idx) => (
                                        <span
                                            onClick={() => goToPath(quickLink)}
                                            style={{
                                                color: '#39426a',
                                                borderColor: 'transparent',
                                                backgroundColor: 'transparent',
                                                display: 'inline-flex',
                                                padding: '0px 10px',
                                                justifyContent: 'center',
                                                cursor: 'pointer',
                                            }}
                                            key={idx}
                                        >
                                            <span
                                                style={{
                                                    display: 'inline-flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginRight: 10,
                                                    position: 'relative',
                                                }}
                                            >
                                                {quickLink.icon}
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: 7,
                                                        height: 14,
                                                        width: 14,
                                                        borderRadius: '50%',
                                                        opacity: '0.45',
                                                        backgroundColor: quickLink.iconColor,
                                                        zIndex: 100,
                                                    }}
                                                />
                                            </span>
                                            {quickLink.title}
                                        </span>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </CustomLayout>
                </Col>
            </Row>
        </VerifyBondResultsWrapper>
    );
};

export default VerifyBondResults;
