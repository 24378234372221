import { AuthenticatedRoutesType } from './routes';
import { RolesType } from '../Authorization/AuthorizationWrapper';

export type RoutePermission = {
    allowedRoles?: RolesType | RolesType[];
    restrictedRoles?: RolesType | RolesType[];
};

type RoutePermissions = {
    [key in AuthenticatedRoutesType]?: RoutePermission;
};

export const Permissions: RoutePermissions = {
    DASHBOARD: undefined,
};
