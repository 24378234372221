import React from 'react';
import { Modal, Row, Col, Button } from 'antd';
import alertCircleIcon from '../../../assets/icons/Svg/alertCircle.svg';
import ModalClose from '../components/close';
import styled from 'styled-components';
import style from './styles';
import getEnvTheme from '../../../config/themes/getEnvTheme';

interface Props {
    title: string;
    description: string;
    visible: boolean;
    onCancel: () => void;
    onOK: () => void;
    onOKLoading?: boolean;
}

const WarningModalWrapper = styled.div`
    ${style}
`;

const WarningModal = ({ visible, onCancel, onOK, title, description, onOKLoading = false }: Props) => {
    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            closeIcon={<ModalClose />}
            onCancel={() => onCancel()}
            closable={false}
        >
            <WarningModalWrapper>
                <img className="icon" src={alertCircleIcon} alt="warning-icon" />
                <p className="title">{title}</p>
                <p className="description">{description}</p>
                <Row gutter={16}>
                    <Col>
                        <Button className="gapstack-cancel-button" onClick={onCancel}>
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="gapstack-button"
                            style={{
                                borderColor: getEnvTheme()?.PRIMARY ?? '#7C60FF',
                                backgroundColor: getEnvTheme()?.PRIMARY ?? '#7C60FF',
                            }}
                            onClick={onOK}
                            loading={onOKLoading}
                        >
                            Ok
                        </Button>
                    </Col>
                </Row>
            </WarningModalWrapper>
        </Modal>
    );
};

export default WarningModal;
