import React, { ReactNode } from 'react';
import AuthenticatedLayout from '../../Layouts/Authenticated';
import styled from 'styled-components';
import style from '../components/styles';
interface IProps {
    children: ReactNode;
}
const DeveloperViewWrapper = styled.div`
    ${style}
`;
const DeveloperWrapper = ({ children }: IProps) => {
    return (
        <AuthenticatedLayout>
            <DeveloperViewWrapper>{children}</DeveloperViewWrapper>
        </AuthenticatedLayout>
    );
};
export default DeveloperWrapper;
