import {
    IS_FETCHING_BUSINESS_INFORMATION,
    FETCH_BUSINESS_INFORMATION_SUCCESS,
    FETCH_BUSINESS_INFORMATION_FAILURE,
    IS_UPDATING_BUSINESS_INFORMATION,
    ON_UPDATE_BUSINESS_INFORMATION_SUCCESS,
    ON_UPDATE_BUSINESS_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION,
    ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION,
    ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE,
    UPDATE_REGISTRATION_STATUS,
    IS_UPDATING_TERMS_AND_CONDITIONS,
    ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS,
    ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE,
    IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION,
    ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE,
    IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION,
    ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE,
    ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS,
    IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION,
    ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS,
    ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE,
    UPDATE_WALLET,
    IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES,
    ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS,
    ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE,
    IS_FETCHING_INDUSTRIES,
    FETCH_INDUSTRIES_SUCCESS,
    FETCH_INDUSTRIES_FAILURE,
} from '../types';
import { getUser } from '../../../../config';

const initialState = {
    isFetchingBusinessAccount: false,
    isUpdatingBusinessInformation: false,
    isCreatingOrUpdatingBusinessContactInformation: false,
    isCreatingOrUpdatingBusinessBrandInformation: false,
    isUpdatingTermsAndConditions: false,
    isCreatingOrUpdatingMobileIndemnity: false,
    isCreatingOrUpdatingDirector: false,
    isCreatingOrUpdatingFinancialInformation: false,
    isCreatingOrUpdatingBusinessCertificates: false,
    isFetchingIndustries: false,
    business: {
        businessName: getUser() === 'BANK' ? 'Admin' : '',
    },
    address: {},
    contact: {},
    brand: {},
    director: {},
    financial: {},
    mobileIndemnity: {},
    businessCertificates: {},
    wallet: {},
    registrationStatus: {},
    industries: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case IS_FETCHING_BUSINESS_INFORMATION:
            return {
                ...state,
                isFetchingBusinessAccount: true,
            };
        case UPDATE_REGISTRATION_STATUS:
            return {
                ...state,
                registrationStatus: action.data,
            };
        case FETCH_BUSINESS_INFORMATION_SUCCESS:
            return {
                ...state,
                isFetchingBusinessAccount: false,
                business: action.data.business,
                businessType: action.data.businessType,
                kycCompletionPercentage: action.data.kycCompletionPercentage,
                profilePercentage: action.data.profilePercentage,
                registrationStatus: action.data.registrationStatus,
            };
        case FETCH_BUSINESS_INFORMATION_FAILURE:
            return {
                ...state,
                isFetchingBusinessAccount: false,
            };
        case IS_UPDATING_BUSINESS_INFORMATION:
            return {
                ...state,
                isUpdatingBusinessInformation: true,
            };
        case ON_UPDATE_BUSINESS_INFORMATION_SUCCESS:
            return {
                ...state,
                business: action.data.business,
                address: action.data.address,
                isUpdatingBusinessInformation: false,
            };
        case ON_UPDATE_BUSINESS_INFORMATION_FAILURE:
            return {
                ...state,
                isUpdatingBusinessInformation: false,
            };
        case IS_CREATING_OR_UPDATING_BUSINESS_CONTACT_INFORMATION:
            return {
                ...state,
                isCreatingOrUpdatingBusinessContactInformation: true,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingBusinessContactInformation: false,
                contact: action.data,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_CONTACT_INFORMATION_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingBusinessContactInformation: false,
            };
        case IS_CREATING_OR_UPDATING_BUSINESS_BRAND_INFORMATION:
            return {
                ...state,
                isCreatingOrUpdatingBusinessBrandInformation: true,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingBusinessBrandInformation: false,
                brand: action.data,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_BRAND_INFORMATION_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingBusinessBrandInformation: false,
            };
        case IS_UPDATING_TERMS_AND_CONDITIONS:
            return {
                ...state,
                isUpdatingTermsAndConditions: true,
            };
        case ON_UPDATE_TERMS_AND_CONDITIONS_SUCCESS:
            return {
                ...state,
                isUpdatingTermsAndConditions: false,
                business: action.data,
            };
        case ON_UPDATE_TERMS_AND_CONDITIONS_FAILURE:
            return {
                ...state,
                isUpdatingTermsAndConditions: false,
            };
        case IS_CREATING_OR_UPDATING_MOBILE_INDEMNITY_INFORMATION:
            return {
                ...state,
                isCreatingOrUpdatingMobileIndemnity: true,
            };
        case ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingMobileIndemnity: false,
                mobileIndemnity: action.data,
            };
        case ON_CREATE_OR_UPDATE_MOBILE_INDEMNITY_INFORMATION_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingMobileIndemnity: false,
            };
        case IS_CREATING_OR_UPDATING_DIRECTOR_INFORMATION:
            return {
                ...state,
                isCreatingOrUpdatingDirector: true,
            };
        case ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingDirector: false,
                director: action.data,
            };
        case ON_CREATE_OR_UPDATE_DIRECTOR_INFORMATION_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingDirector: false,
            };
        case IS_CREATING_OR_UPDATING_FINANCIAL_INFORMATION:
            return {
                ...state,
                isCreatingOrUpdatingFinancialInformation: true,
            };
        case ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingFinancialInformation: false,
                financial: action.data,
            };
        case ON_CREATE_OR_UPDATE_FINANCIAL_INFORMATION_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingFinancialInformation: false,
            };
        case UPDATE_WALLET:
            return {
                ...state,
                wallet: action.data,
            };
        case IS_CREATING_OR_UPDATING_BUSINESS_CERTIFICATES:
            return {
                ...state,
                isCreatingOrUpdatingBusinessCertificates: true,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_SUCCESS:
            return {
                ...state,
                isCreatingOrUpdatingBusinessCertificates: false,
                businessCertificates: action.data,
            };
        case ON_CREATE_OR_UPDATE_BUSINESS_CERTIFICATES_FAILURE:
            return {
                ...state,
                isCreatingOrUpdatingBusinessCertificates: false,
            };
        case IS_FETCHING_INDUSTRIES:
            return {
                ...state,
                isFetchingIndustries: true,
            };
        case FETCH_INDUSTRIES_SUCCESS:
            return {
                ...state,
                isFetchingIndustries: false,
                industries: action.data,
            };
        case FETCH_INDUSTRIES_FAILURE:
            return {
                ...state,
                isFetchingIndustries: false,
                industries: [],
            };
        default:
            return state;
    }
};
