import React from 'react';
import {
    PurchaseSupplierTableComponent,
    ViewPurchaseComponent,
} from '../../../../components/purchases/supplier-central';

const ValidatedSuppliers = () => {
    return <PurchaseSupplierTableComponent isValidated={true} />;
};
const ViewValidatedSuppliers = () => {
    return <ViewPurchaseComponent isValidated={true} />;
};

export { ValidatedSuppliers, ViewValidatedSuppliers };
