import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    background: transparent;
    min-width: 210px;
    width: 18%;
    overflow-y: hidden;

    .sidebar-header {
        padding: 30px;
    }

    .sidebar-body {
        height: calc(100% - 100px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 5px;
        padding-top: 5px;

        & > div {
            display: grid;
            grid-template-columns: 15px 1fr 20px;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            grid-gap: 16px;
            padding: 10px 30px;
            cursor: pointer;
            background: transparent;
            transition: background 0.2s, font-weight 0.2s, color 0.2s;
            &:hover,
            &:focus {
                background: ${(props) => props.theme[COLORS.BACKGROUND]};
                outline: 0.5px dotted #f0edff;
            }

            span:last-child {
                text-align: end;
            }
        }

        & > div.active {
            font-weight: 600;
            color: ${(props) => props.theme[COLORS.PRIMARY]};
        }
    }
`;

export default style();
