const prefix = process.env.NODE_ENV === 'development' ? 'email' : 'email';

export const authenticate = `${prefix}/authenticate/status`;
export const get_authentication_code = `${prefix}/authenticate`;
export const generate_token = `${prefix}/authenticate/tokens`;

export const send_new_mail = `${prefix}/mail/send`;

export const get_sent_mails = `${prefix}/mail/sent`;
export const get_inbox_mails = `${prefix}/mail/inbox`;
export const get_trash_mails = `${prefix}/mail/trash`;
export const get_draft_mails = `${prefix}/mail/draft`;
export const get_mail_by_id = (id) => `${prefix}/mail/${id}`;
export const delete_mail = `${prefix}/mail/move-message`;
