import React, { useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { RiFileLine, RiFileList3Line, RiSearch2Line, RiShipLine } from 'react-icons/ri';
import style from '../../../landing-page/styles';
import ManCheckingBond from '../../../../assets/Images/ManCheckTab.jpg';
import { ROUTES } from '../../../../router/urls';
import { useHistory } from 'react-router-dom';
import { unAuthenticatedRequest } from '../../../../api';
import { verify_bond } from '../../../../apis/guarantee';
import { createSearchParams } from '../../../../router/routeHooks';
import WarningModal from '../../../../utils/Modals/Warning';
import secondaryAppIcon from '../../../../config/secondaryAppIcon';
import { Constant } from '../../../../config';

const VerifyBondWrapper = styled.div`
    ${style}
`;

const quickLinks = [
    {
        title: 'Get a Bid bond',
        icon: <RiFileLine size="18px" />,
        iconColor: '#7C60FF',
        path: ROUTES.NEW_GUARANTEE,
        params: {
            guaranteeType: 'BID_BOND',
        },
    },
    {
        title: 'Get a Performance Bond',
        icon: <RiFileList3Line size="18px" />,
        iconColor: '#2CCC71',
        path: ROUTES.CUSTOM_BANK_SIGN_UP,
    },
    {
        title: 'Get an Advanced Payment Guarantee',
        icon: <RiFileLine size="18px" />,
        iconColor: '#7C60FF',
        path: ROUTES.CUSTOM_BANK_SIGN_UP,
    },
    {
        title: 'Get an Import LC',
        icon: <RiShipLine size="18px" />,
        iconColor: '#1E87F0',
        path: ROUTES.CUSTOM_BANK_SIGN_UP,
    },
];

const ExternalVerifyBidBond = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [noBondFound, setNoBondFound] = useState<boolean>(false);
    const goToPath = (quickLink) => {
        if (quickLink) {
            const routeData = {
                pathname: quickLink?.path,
            };
            if (quickLink?.params) {
                routeData['search'] = createSearchParams(quickLink.params);
            }
            history.push(routeData);
        }
    };

    const onFinish = (values) => {
        setLoading(true);
        unAuthenticatedRequest()
            .get(verify_bond(values?.bidBondNumber))
            .then((res) => {
                setLoading(false);
                history.push({
                    pathname: `/verify-bond-results/${values?.bidBondNumber}`,
                    state: { bidBond: res?.data },
                });
            })
            .catch(() => {
                setLoading(false);
                setNoBondFound(true);
            });
    };

    return (
        <VerifyBondWrapper backgroundImage={ManCheckingBond}>
            <WarningModal
                visible={noBondFound}
                onOK={() => setNoBondFound(false)}
                onCancel={() => setNoBondFound(false)}
                title="No Bid Bond Found"
                description={`Sorry. We couldn’t find the Bid Bond No. ${form.getFieldValue(
                    'bidBondNumber',
                )}. Please ensure you have the right Bid Bond number
                    and try again.`}
            />
            <div className="container">
                <div className="overlay" />
                <Row style={{ height: '100%' }}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{ span: 24, offset: 0 }}
                        xl={{ span: 20, offset: 2 }}
                        xxl={{ span: 16, offset: 4 }}
                    >
                        <div className="top-bar">
                            <img
                                src={secondaryAppIcon}
                                alt="app-icon"
                                onClick={() => history.push(ROUTES.LANDING_PAGE)}
                            />
                        </div>
                        <Row className="content">
                            <Col
                                xs={{ span: 24, offset: 0 }}
                                sm={{ span: 24, offset: 0 }}
                                md={{ span: 24, offset: 0 }}
                                lg={{ span: 24, offset: 0 }}
                                xl={{ span: 16, offset: 4 }}
                                xxl={{ span: 16, offset: 4 }}
                            >
                                <div className="landing-title-container">
                                    <span className="verify-bond-title">Verify a bid bond or Guarantee</span>
                                    <Form form={form} onFinish={onFinish} layout="vertical" style={{ marginTop: 20 }}>
                                        <Form.Item
                                            name="bidBondNumber"
                                            rules={[{ required: true, message: 'Please enter bid bond number' }]}
                                        >
                                            <Row>
                                                <Col span={22}>
                                                    <Input
                                                        placeholder="Enter Bid Bond or Guarantee Number"
                                                        className="gapstack-input"
                                                        style={{
                                                            borderTopRightRadius: 0,
                                                            borderBottomRightRadius: 0,
                                                        }}
                                                        suffix={<RiSearch2Line color="#8d8d8d" size="18px" />}
                                                    />
                                                </Col>
                                                <Col span={2}>
                                                    <Button
                                                        htmlType="submit"
                                                        className="gapstack-button"
                                                        style={{
                                                            backgroundColor: Constant.buttonColor,
                                                            borderColor: Constant.buttonColor,
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 0,
                                                        }}
                                                        loading={loading}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <div>
                                            {quickLinks.map((quickLink, idx) => (
                                                <span
                                                    onClick={() => goToPath(quickLink)}
                                                    style={{
                                                        color: '#ffffff',
                                                        borderColor: 'transparent',
                                                        backgroundColor: 'transparent',
                                                        display: 'inline-flex',
                                                        padding: '0px 10px',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={idx}
                                                >
                                                    <span
                                                        style={{
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginRight: 10,
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        {quickLink.icon}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                top: 7,
                                                                height: 14,
                                                                width: 14,
                                                                borderRadius: '50%',
                                                                opacity: '0.45',
                                                                backgroundColor: quickLink.iconColor,
                                                                zIndex: 100,
                                                            }}
                                                        />
                                                    </span>
                                                    {quickLink.title}
                                                </span>
                                            ))}
                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </VerifyBondWrapper>
    );
};

export default ExternalVerifyBidBond;
