import React from 'react';
import { Row, Col, Breadcrumb, notification } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../../../router/urls';
import { authenticatedRequest } from '../../../../api';
import Card1 from './Card1';
import DataEditModal from '../../../../utils/DataEditModal/DataEditModal';
import ModalColumnData from './ModalColumnData';
import { fetch_collection_account, fetch_disbursement_account } from '../../../../apis/bank-products';
import AuthenticatedLayout from '../../../../components/Layouts/Authenticated';

const Wrapper = styled.div`
    padding-top: 20px;
    margin: 0 10px;
`;

const Accounts = () => {
    const editRef = React.useRef<any>();
    const [selected, setSelected] = React.useState<any>(null);
    const [loadingCollection, setLoadingCollection] = React.useState<boolean>(false);
    const [loadingCapital, setLoadingCapital] = React.useState<boolean>(false);

    const [collectionData, setCollectionData] = React.useState<{
        capitalData: any[];
        collectionData: any[];
    }>({
        capitalData: [],
        collectionData: [],
    });

    const fetchCollection = React.useCallback(() => {
        setLoadingCollection(true);
        authenticatedRequest()
            .get(fetch_collection_account)
            .then((res) => (res.data ? [res.data] : []))
            .then((data) => {
                setCollectionData((s) => ({
                    ...s,
                    collectionData: data,
                }));
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            })
            .finally(() => {
                setLoadingCollection(false);
            });
    }, []);

    const fetchCapital = React.useCallback(() => {
        setLoadingCapital(true);
        authenticatedRequest()
            .get(fetch_disbursement_account)
            .then((res) => (res.data ? [res.data] : []))
            .then((data) => {
                setCollectionData((s) => ({
                    ...s,
                    capitalData: data,
                }));
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            })
            .finally(() => {
                setLoadingCapital(false);
            });
    }, []);

    React.useEffect(() => {
        fetchCollection();
    }, [fetchCollection]);

    React.useEffect(() => {
        fetchCapital();
    }, [fetchCapital]);

    // const goToViewCollectionStrategies = (record) => {
    //   history.push({
    //     pathname: goToRoute(ROUTES.VIEW_COLLECTION_STRATEGY),
    //     search: createSearchParams({ strategyId: record.id }),
    //   });
    // };

    const onSuccess = React.useCallback(() => {
        return selected?.toLowerCase()?.includes('capital') ? fetchCapital() : fetchCollection();
    }, [fetchCapital, fetchCollection, selected]);

    const editDetails = React.useCallback((name: string, callback?: any) => {
        setSelected(name);
        setTimeout(() => editRef.current?.openModal(callback), 0);
    }, []);

    const onCancel = React.useCallback(() => {
        editRef.current?.closeModal();
    }, []);

    const afterClosed = React.useCallback(async () => {
        setSelected(null);
    }, []);

    return (
        <AuthenticatedLayout>
            <Wrapper>
                <Row className="header-table">
                    <Col span={18}>
                        <Breadcrumb separator="|">
                            <Breadcrumb.Item>
                                <Link to={ROUTES.BANK_SETTINGS}>Settings</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={ROUTES.BANK_SETTINGS}>Product Settings</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Collection & Disbursement accounts</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div>
                    <Card1
                        title={'Capital Account/Disbursement Account'}
                        loading={loadingCapital}
                        accounts={collectionData.capitalData}
                        onEdit={(id) => editDetails('editCapitalAccount', id)}
                        onNew={() => editDetails('addCapitalAccount')}
                    />
                    <Card1
                        title={'Collection Account'}
                        loading={loadingCollection}
                        accounts={collectionData.collectionData}
                        onEdit={(id) => editDetails('editCollectionAccount', id)}
                        onNew={() => editDetails('addCollectionAccount')}
                    />
                </div>
            </Wrapper>
            <DataEditModal
                ref={editRef}
                data={collectionData}
                selected={selected}
                columnList={ModalColumnData}
                onCancel={onCancel}
                afterClosed={afterClosed}
                onSuccess={onSuccess}
            />
        </AuthenticatedLayout>
    );
};

export default Accounts;
