import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';

export const BusinessSignIn = Loadable({
    loader: () => import('./sign-in/CustomSignIn' /* webpackChunkName: "Authentication" */),
    loading() {
        return <Loader />;
    },
});

export const BusinessSignUp = Loadable({
    loader: () => import('./sign-up/CustomSignUp' /* webpackChunkName: "Authentication" */),
    loading() {
        return <Loader />;
    },
});

export const BusinessVerifyEmail = Loadable({
    loader: () => import('./verify-email/CustomVerifyEmail' /* webpackChunkName: "Authentication" */),
    loading() {
        return <Loader />;
    },
});

export const BusinessForgotPassword = Loadable({
    loader: () => import('./forgot-password/CustomForgotPassword' /* webpackChunkName: "Authentication" */),
    loading() {
        return <Loader />;
    },
});
