import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .ant-list-pagination {
        display: none;
    }
    .pagination-container {
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .left-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            &:hover {
                color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            }
        }
        .right-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            cursor: pointer;
            &:hover {
                color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            }
        }
        .item {
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .active-item {
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            font-weight: bold;
        }
    }
`;

export default style;
