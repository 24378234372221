import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../utils/Loader';
import { FundingTypeEnum } from '../fund-my-purchase/types';
import AuthenticatedLayout from '../Layouts/Authenticated';

const FundFromSalesViewComponent = (props: any) => (
    <AuthenticatedLayout>
        <FundFromSalesView {...props} />
    </AuthenticatedLayout>
);
const FundFromSalesView = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});

const ViewFinancingOrderComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewFinancingOrder title={'Fund From Sales'} fundingType={FundingTypeEnum.FINANCE_SALES} {...props} />
    </AuthenticatedLayout>
);
const ViewFinancingOrder = Loadable({
    loader: () => import('../fund-my-purchase/view-financing-request' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});

const GetFinancingSalesComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <GetFinancingSales {...props} />
    </AuthenticatedLayout>
);
const GetFinancingSales = Loadable({
    loader: () => import('./add-new-financing' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});

const FinanceInvoicingSalesComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <FinanceInvoicingSales {...props} />
    </AuthenticatedLayout>
);
const FinanceInvoicingSales = Loadable({
    loader: () => import('./add-new-financing/finance-invoicing' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});
const WorkingCapitalSalesComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <WorkingCapitalSales {...props} />
    </AuthenticatedLayout>
);
const WorkingCapitalSales = Loadable({
    loader: () => import('./add-new-financing/working-capital' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});
const DiscountInvoicesComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <DiscountInvoices {...props} />
    </AuthenticatedLayout>
);
const DiscountInvoices = Loadable({
    loader: () => import('./add-new-financing/discount-invoices' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});

const ApplicationSummaryComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <ApplicationSummary {...props} />
    </AuthenticatedLayout>
);

const ApplicationSummary = Loadable({
    loader: () => import('../../utils/Financing/ApplicationSummary' /* webpackChunkName: "Fund-From-Sales" */),
    loading() {
        return <Loader />;
    },
});

export {
    FundFromSalesViewComponent,
    ViewFinancingOrderComponent,
    GetFinancingSalesComponent,
    FinanceInvoicingSalesComponent,
    WorkingCapitalSalesComponent,
    DiscountInvoicesComponent,
    FundFromSalesView,
    ApplicationSummaryComponent,
};
