import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

// import ReceivedQuotations from './table';
// import ViewSingleReceivedQuotation from './view-quotation';
// import ViewUploadedReceivedQuotation from './uploaded-quotation';
// import CreateReceivedQuotation from './upload-received-quotation/create-sale-step-1';
// import ValidateReceivedQuotation from './components/validate';

const ReceivedQuotationsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ReceivedQuotations {...props} />
    </AuthenticatedLayout>
);
const ReceivedQuotations = Loadable({
    loader: () => import('./table' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const ViewQuotationsComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewSingleReceivedQuotation {...props} />
    </AuthenticatedLayout>
);
const ViewSingleReceivedQuotation = Loadable({
    loader: () => import('./view-quotation' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});
const ViewUploadedReceivedQuotationComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewUploadedReceivedQuotation {...props} />
    </AuthenticatedLayout>
);
const ViewUploadedReceivedQuotation = Loadable({
    loader: () => import('./uploaded-quotation' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});
const CreateReceivedQuotationComponent = (props: any) => (
    <AuthenticatedLayout isContentLayout>
        <CreateReceivedQuotation {...props} />
    </AuthenticatedLayout>
);
const CreateReceivedQuotation = Loadable({
    loader: () => import('./upload-received-quotation/create-sale-step-1' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

const ValidateReceivedQuotation = Loadable({
    loader: () => import('./components/validate' /* webpackChunkName: "Invoice" */),
    loading() {
        return <Loader />;
    },
});

export {
    ReceivedQuotationsComponent,
    ViewQuotationsComponent,
    CreateReceivedQuotationComponent,
    ViewUploadedReceivedQuotationComponent,
    ValidateReceivedQuotation,
    ReceivedQuotations,
};
