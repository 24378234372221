import React from 'react';

import styled from 'styled-components';

import style from './styles';
import NotificationsCard from '../../../../../notifications/components/notification-card/index';
import { RootState } from '../../../../../../store/reducers/rootReducers';
import { useSelector } from 'react-redux';

const NotificationPanelWrapper = styled.div`
    ${style}
`;

const NotificationPanel = () => {
    const notifications: any[] = useSelector(
        (state: RootState) => state.authenticatedLayoutReducer.unreadNotifications || [],
    );
    return (
        <NotificationPanelWrapper className="notification-panel">
            <NotificationsCard title="Notifications" seeAll data={notifications} dismissText />
        </NotificationPanelWrapper>
    );
};

export default NotificationPanel;
