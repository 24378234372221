import { goToRoute, RouteKey, ROUTES } from '../../router/urls';

export function getRoutePath(route: RouteKey, params: Record<string, string> = {}) {
    const _path = goToRoute(ROUTES[route], params.businessId);

    return Object.keys(params || {}).reduce((acc, key) => {
        return acc.replace(`:${key}`, params[key]);
    }, _path);
}

export { ROUTES, goToRoute };
export type { RouteKey };
