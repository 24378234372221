import React from 'react';
import styled from 'styled-components';

import Card1 from './card-1';
import style from './styles';
import Card2 from './card-2';
import Card3 from './card-3';
import DeveloperWrapper from '../data/developer.layout';
import PageHeader from '../../../utils/PageHeader';
import { Row, Col, Breadcrumb } from 'antd';

const ViewWrapper = styled.div`
    ${style}
`;

const Developer = () => {
    return (
        <DeveloperWrapper>
            <ViewWrapper>
                <PageHeader>
                    <Row className="header-table">
                        <Col span={12}>
                            <Breadcrumb separator="|">
                                <Breadcrumb.Item>Developer</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </PageHeader>

                <Card1 />
                <Card2 />
                <Card3 />
            </ViewWrapper>
        </DeveloperWrapper>
    );
};

export default Developer;
