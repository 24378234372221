import { getUser, Users } from '../../config';
import { goToRoute, ROUTES } from '../../router/urls';
import { ServiceType } from '../fund-from-sales/types';

export enum ServiceTypeEnum {
    WORKING_CAPITAL = 'Working Capital',
    PO_FINANCING = 'PO Financing',
    PO_INVOICE_FINANCING = 'PO/Invoice Financing',
    DISTRIBUTOR_RETAIL_FINANCING = 'Distributor/Retail Financing',
    IMPORT_LC = 'Import LC',
    IMPORT_DC = 'Import DC',
    EXPORT_LC = 'Export LC',
    EXPORT_DC = 'Export DC',
    DISCOUNTING = 'Discounting',
}

export enum FundingTypeEnum {
    FINANCE_SALES = 'Finance Sales',
    FINANCE_PURCHASES = 'Finance Purchases',
    IMPORT_TRADE = 'Import Loan',
    EXPORT_TRADE = 'Export Loan',
    IMPORT_ESCROW = 'Import Escrow',
    IMPORT_SERVICE = 'Import Service',
    BID_BOND = 'Bid Bonds',
    PERFORMANCE_BOND = 'Performance Bonds',
    ADVANCED_PAYMENT_BOND = 'Advance Payment Guarantee',
}

export enum RequestType {
    WORKING_CAPITAL = 'Working Capital',
    FINANCING = 'PO/Invoice Financing', // local
    POST_SHIPMENT = 'Post Shipment Financing',
    IMPORT_FINANCE = 'Import Orders', // international
    DISTRIBUTOR_FINANCING = 'Distributor/Retail Financing',
}

export enum SalesRequestType {
    WORKING_CAPITAL = 'Working Capital',
    FINANCING = 'Purchase Order',
    INVOICE_DISCOUNT = 'Invoice Discounting',
    EXPORT_FINANCE = 'Export Orders/Invoice', // international
    EXPORT_WORKING_CAPITAL = 'Export Working Capital', // international
    LC_Discount = 'LC Discounting',
    COLLECTION_BILLS_DISCOUNTING = 'Bills On Collection Discounting',
}

// const FundMyPurchasesArr = [RequestType.WORKING_CAPITAL, RequestType.FINANCING, RequestType.DISTRIBUTOR_FINANCING];
// const ImportLoansArr = [RequestType.IMPORT_FINANCE, RequestType.POST_SHIPMENT];
// const FundFromSalesArr = [
//     SalesRequestType.WORKING_CAPITAL,
//     SalesRequestType.FINANCING,
//     SalesRequestType.INVOICE_DISCOUNT,
// ];
// const ExportLoansArr = [
//     SalesRequestType.EXPORT_FINANCE,
//     SalesRequestType.EXPORT_WORKING_CAPITAL,
//     SalesRequestType.LC_Discount,
//     SalesRequestType.COLLECTION_BILLS_DISCOUNTING,
// ];

export const getTableParams = (fundingType: FundingTypeEnum, type: any, isAdmin?: boolean) => {
    const isBank = getUser() == Users.BANK;
    let url = ROUTES.FUND_MY_PURCHASES; // Route to go back to
    let title = 'PO Financing'; // Title of first card
    let editUrl = ROUTES.FINANCE_INVOICES; // Edit button URL
    if (fundingType == FundingTypeEnum.FINANCE_PURCHASES) {
        url = isBank && isAdmin ? ROUTES.FUND_MY_PURCHASES_BANK : ROUTES.FUND_MY_PURCHASES;
        if (type == RequestType.WORKING_CAPITAL) editUrl = ROUTES.WORKING_CAPITAL;
        if (type == RequestType.DISTRIBUTOR_FINANCING) editUrl = ROUTES.DISTRIBUTOR_ECOSYSTEM;
    } else if (fundingType == FundingTypeEnum.FINANCE_SALES) {
        url = isBank && isAdmin ? ROUTES.FUND_FROM_SALES_BANK : ROUTES.FUND_FROM_SALES;
        title = 'Order Financing';
        if (type == ServiceType.PurchaseOrder) editUrl = ROUTES.FINANCE_INVOICES_SALES;
        if (type == ServiceType.WorkingCapital) editUrl = ROUTES.WORKING_CAPITAL_SALES;
        if (type == ServiceType.Discounting) editUrl = ROUTES.DISCOUNT_INVOICES;
        if (type == SalesRequestType.EXPORT_FINANCE) editUrl = ROUTES.FINANCE_EXPORT_ORDERS;
    } else if (fundingType == FundingTypeEnum.IMPORT_TRADE) {
        if (type == RequestType.IMPORT_FINANCE) editUrl = ROUTES.FINANCE_IMPORT_ORDERS;
        if (type == RequestType.POST_SHIPMENT) editUrl = ROUTES.POST_SHIPMENT_FINANCEING;
        url = ROUTES.IMPORT_LOANS;
    } else if (fundingType == FundingTypeEnum.EXPORT_TRADE) {
        if (type == SalesRequestType.WORKING_CAPITAL) editUrl = ROUTES.GET_WORKING_CAPITAL;
        if (type == SalesRequestType.COLLECTION_BILLS_DISCOUNTING) editUrl = ROUTES.COLLECTION_DISCOUNTING;
        url = ROUTES.EXPORT_LOANS;
    }
    return { url: goToRoute(url), title, editUrl };
};
