import React, { ReactElement, useEffect, useState } from 'react';
import { Row, Col, Form, notification } from 'antd';
import styled from 'styled-components';
import style from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducers';
import NoKYCPlaceholder from '../../utils/NoKYCPlaceholder';
import KYCSideBar, { KYCStatuses } from './helpers';
import BusinessInformationWrapper from './cards/business-profile/index';
import KYCDocuments from './cards/business-documents/index';
import ConfirmDetails from './cards/confirm-details/index';
import { TermsAndConditions } from './cards/terms-and-conditions';
import { VerficationPending } from './cards/verification-pending';
import { mapInitialData, mapConfirmData, mapConfirmDataErrors } from './helpers/mapData';
import { authenticatedRequest } from '../../api';
import { fetch_business_details } from '../../apis/business-accounts';
import AuthenticatedLayout from '../Layouts/Authenticated';
import { useHistory } from 'react-router-dom';
import { goToRoute, ROUTES } from '../../router/urls';
import { useParams } from 'react-router';
import { getUser, Users } from '../../config';
import IsAccountPresent from './Components/IsAccountPresent';
import { get_terms_for_business } from '../../apis/business-accounts/kyc_settings';
import { BusinessStatuses } from '../../bank-components/business/table/data';
const BusinessAccountWrapper = styled.div`
    ${style}
`;

interface Props {
    children: ReactElement;
}

const CompleteProfile = () => {
    const history = useHistory();
    const { businessId } = useParams<{ businessId: string }>();
    const auth: any = useSelector((state: RootState) => state.auth);
    const [form] = Form.useForm();

    const [isAccountPresent, setIsAccountPresent] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isTermsRequired, setIsTermsRequired] = useState<boolean>(true);

    const [activeStatus, setActiveStatus] = useState<KYCStatuses>(KYCStatuses.BUSINESS);
    const [completedStatuses, setCompletedStatuses] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [activeData, setActiveData] = useState<any>({});
    const [businessDetails, setBusinessDetails] = useState<any>({});

    const isBank = getUser() == Users.BANK;

    const getStatus = (registrationStatus: any, termStatus, businessData) => {
        let tempActiveStatus: KYCStatuses = KYCStatuses.BUSINESS;
        const obj: any = {};
        if (businessData?.status == BusinessStatuses.OnHold) {
            obj[KYCStatuses.BUSINESS] = 'COMPLETE';
            obj[KYCStatuses.DOCUMENTS] = 'ACTIVE';
            tempActiveStatus = KYCStatuses.DOCUMENTS;
            if (!registrationStatus?.confirmDetails) {
                obj[KYCStatuses.DOCUMENTS] = 'COMPLETE';
                obj[KYCStatuses.CONFIRM_DETAILS] = 'ACTIVE';
                tempActiveStatus = KYCStatuses.CONFIRM_DETAILS;
            }
            if (!registrationStatus?.termsAndCondition && termStatus) {
                obj[KYCStatuses.DOCUMENTS] = 'COMPLETE';
                obj[KYCStatuses.CONFIRM_DETAILS] = 'COMPLETE';
                obj[KYCStatuses.TERMS_AND_CONDITIONS] = 'ACTIVE';
                tempActiveStatus = KYCStatuses.TERMS_AND_CONDITIONS;
            }
            setCompletedStatuses(obj);
            return tempActiveStatus;
        }
        if (registrationStatus?.businessProfile) {
            obj[KYCStatuses.BUSINESS] = 'COMPLETE';
            obj[KYCStatuses.DOCUMENTS] = 'ACTIVE';
            tempActiveStatus = KYCStatuses.DOCUMENTS;
        } else if (businessData?.existingCustomer?.mandateApproved) {
            if (termStatus) {
                obj[KYCStatuses.BUSINESS] = 'COMPLETE';
                obj[KYCStatuses.TERMS_AND_CONDITIONS] = 'ACTIVE';
                tempActiveStatus = KYCStatuses.TERMS_AND_CONDITIONS;
            } else {
                obj[KYCStatuses.BUSINESS] = 'COMPLETE';
                obj[KYCStatuses.VERIFICATION_PENDING] = 'ACTIVE';
                tempActiveStatus = KYCStatuses.VERIFICATION_PENDING;
            }
        }
        if (registrationStatus?.documents) {
            obj[KYCStatuses.DOCUMENTS] = 'COMPLETE';
            obj[KYCStatuses.CONFIRM_DETAILS] = 'ACTIVE';
            tempActiveStatus = KYCStatuses.CONFIRM_DETAILS;
        }
        if (registrationStatus?.confirmDetails && termStatus) {
            obj[KYCStatuses.CONFIRM_DETAILS] = 'COMPLETE';
            obj[KYCStatuses.TERMS_AND_CONDITIONS] = 'ACTIVE';
            tempActiveStatus = KYCStatuses.TERMS_AND_CONDITIONS;
        } else if (registrationStatus?.confirmDetails && !termStatus) {
            obj[KYCStatuses.CONFIRM_DETAILS] = 'COMPLETE';
            obj[KYCStatuses.VERIFICATION_PENDING] = 'ACTIVE';
            tempActiveStatus = KYCStatuses.VERIFICATION_PENDING;
        }
        if (termStatus) {
            if (registrationStatus?.termsAndCondition || businessData?.existingCustomer?.termsAndCondition) {
                obj[KYCStatuses.TERMS_AND_CONDITIONS] = 'COMPLETE';
                obj[KYCStatuses.VERIFICATION_PENDING] = 'ACTIVE';
                tempActiveStatus = KYCStatuses.VERIFICATION_PENDING;
            }
        }
        setCompletedStatuses(obj);
        return tempActiveStatus;
    };

    const getTermsStatus = async (businessData: any) => {
        if (!businessData?.businessType?.name) {
            return true;
        }
        return authenticatedRequest()
            .get(get_terms_for_business(businessData?.businessType?.name))
            .then((res) => {
                if (res.data && res.data?.length == 0) {
                    return false;
                }
                return true;
            })
            .catch((err) => {
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    const fetchDetails = (initial = false) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };

        authenticatedRequest(isBank ? businessHeader : {})
            .get(fetch_business_details)
            .then((res) => res.data)
            .then(async (businessData) => {
                setBusinessDetails(businessData);
                if (initial) {
                    const termStatus = await getTermsStatus(businessData);
                    console.log(
                        'REACT_APP_IS_ACCOUNT_PRESENT',
                        process.env.REACT_APP_IS_ACCOUNT_PRESENT?.trim().toLowerCase(),
                    );
                    if (process.env.REACT_APP_IS_ACCOUNT_PRESENT?.trim().toLowerCase() !== 'false') {
                        if (businessData?.existingCustomer?.id) {
                            setIsAccountPresent(true);
                        } else if (businessData?.registrationStatus?.businessProfile) {
                            setIsAccountPresent(false);
                        } else {
                            setShowModal(true);
                        }
                    }

                    const registrationStatus = getStatus(businessData?.registrationStatus, !!termStatus, businessData);
                    setActiveStatus(registrationStatus);
                    setIsTermsRequired(!!termStatus);
                    setTimeout(() => setLoading(false), 1000);
                    return;
                } else {
                    if (activeStatus == KYCStatuses.BUSINESS) {
                        setActiveData(businessData);
                        form.setFieldsValue(mapInitialData(businessData));
                    } else if (activeStatus == KYCStatuses.CONFIRM_DETAILS) {
                        setActiveData(businessData);
                        form.setFieldsValue(mapConfirmData(businessData));
                    } else {
                        setActiveData(businessData);
                    }
                    setTimeout(() => setLoading(false), 1000);
                }
            })
            .catch((err) => {
                setLoading(false);
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchDetails(true);
    }, []);

    useEffect(() => {
        fetchDetails();
    }, [activeStatus]);

    return (
        <BusinessAccountWrapper>
            <Row className="business-container" gutter={20}>
                <KYCSideBar
                    status={activeStatus}
                    setStatus={setActiveStatus}
                    username={auth?.user?.firstName}
                    completedStatuses={completedStatuses}
                    isAccountPresent={isAccountPresent}
                    isTermsRequired={isTermsRequired}
                />
                <Col md={{ span: 12 }} style={{ padding: '0 10px 10px 10px', minWidth: '620px' }}>
                    {activeData?.existingCustomer?.id ? null : (
                        <>
                            {isAccountPresent ? (
                                <div className="existing-account">
                                    Don’t have an existing account?{' '}
                                    <span onClick={() => setIsAccountPresent(false)}>Add account details here </span>
                                </div>
                            ) : (
                                <div
                                    className="complete-later"
                                    onClick={() => history.push(goToRoute(ROUTES.DASHBOARD))}
                                >
                                    Complete Later
                                </div>
                            )}
                        </>
                    )}
                    <Form layout="vertical" form={form} scrollToFirstError={true}>
                        {/* Components here */}
                        {() => {
                            switch (activeStatus) {
                                case KYCStatuses.BUSINESS:
                                    return (
                                        <BusinessInformationWrapper
                                            activeData={activeData}
                                            fetchAccountDetails={fetchDetails}
                                            form={form}
                                            completedStatuses={completedStatuses}
                                            setCompletedStatuses={setCompletedStatuses}
                                            changeStep={setActiveStatus}
                                            isAccountPresent={isAccountPresent}
                                            loading={loading}
                                        />
                                    );
                                case KYCStatuses.DOCUMENTS:
                                    return (
                                        <KYCDocuments
                                            form={form}
                                            activeData={activeData}
                                            completedStatuses={completedStatuses}
                                            setCompletedStatuses={setCompletedStatuses}
                                            changeStep={setActiveStatus}
                                            cardLoading={loading}
                                        />
                                    );
                                case KYCStatuses.CONFIRM_DETAILS:
                                    return (
                                        <ConfirmDetails
                                            form={form}
                                            fetchAccountDetails={fetchDetails}
                                            activeData={activeData}
                                            completedStatuses={completedStatuses}
                                            setCompletedStatuses={setCompletedStatuses}
                                            changeStep={() => {
                                                setLoading(true);
                                                fetchDetails(true);
                                            }}
                                            errorData={mapConfirmDataErrors(businessDetails)}
                                            isTermsRequired={isTermsRequired}
                                            cardLoading={loading}
                                        />
                                    );
                                case KYCStatuses.TERMS_AND_CONDITIONS:
                                    return (
                                        <TermsAndConditions
                                            form={form}
                                            isAccountPresent={isAccountPresent}
                                            activeData={activeData}
                                            changeStep={(status: KYCStatuses) => {
                                                setActiveStatus(status);
                                                setCompletedStatuses({
                                                    ...completedStatuses,
                                                    [KYCStatuses.TERMS_AND_CONDITIONS]: 'COMPLETE',
                                                    [KYCStatuses.VERIFICATION_PENDING]: 'ACTIVE',
                                                });
                                            }}
                                            cardLoading={loading}
                                        />
                                    );
                                case KYCStatuses.VERIFICATION_PENDING:
                                    return <VerficationPending />;
                                default:
                                    return null;
                            }
                        }}
                    </Form>
                </Col>
            </Row>
            {showModal ? (
                <IsAccountPresent
                    showModal={showModal}
                    hideModal={setShowModal}
                    onAccount={() => {
                        setIsAccountPresent(true);
                        setShowModal(false);
                    }}
                />
            ) : null}
        </BusinessAccountWrapper>
    );
};

export const CompleteProfileWithLayout = (props: any) => (
    <AuthenticatedLayout>
        <CompleteProfile {...props} />
    </AuthenticatedLayout>
);

const NoKYC = ({ children }: Props) => {
    const businessAccount: any = useSelector((state: RootState) => state.businessAccount);

    // check for business status
    if (businessAccount?.business?.status?.toLowerCase() !== 'verified') {
        return <NoKYCPlaceholder accountStatus={businessAccount?.business?.status.toLowerCase()} type="inline" />;
    }

    return children;
};

interface Props {
    children: ReactElement;
}

export { CompleteProfile, NoKYC };
