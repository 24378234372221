import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../utils/Loader';
import AuthenticatedLayout from '../../Layouts/Authenticated';

const EscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <Escrow {...props} />
    </AuthenticatedLayout>
);

const Escrow = Loadable({
    loader: () => import('./index' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});
export const NewEscrow = Loadable({
    loader: () => import('./add-new' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});
const ViewEscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <ViewEscrow {...props} />
    </AuthenticatedLayout>
);
const ViewEscrow = Loadable({
    loader: () => import('./view-escrow' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});
const TrackEscrowComponent = (props: any) => (
    <AuthenticatedLayout>
        <TrackEscrow {...props} />
    </AuthenticatedLayout>
);
const TrackEscrow = Loadable({
    loader: () => import('./view-escrow' /* webpackChunkName: "Escrow" */),
    loading() {
        return <Loader />;
    },
});

export { EscrowComponent, ViewEscrowComponent, TrackEscrowComponent };
