import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { Trash2 } from 'react-feather';
import styled from 'styled-components';

import style from './styles';
import { titleCase } from '../../../../utils/TitleCase';

const NotificationItemWrapper = styled.div`
    ${style}
`;

interface NotificationItemProps {
    dense?: boolean;
    disabled?: boolean;
    data: any;
    onClose: (id: any) => void;
    onMarkRead: () => void;
    style: any;
    dismissText?: boolean;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
    dense,
    disabled,
    data,
    onClose,
    onMarkRead,
    dismissText,
    ...props
}: NotificationItemProps) => {
    const todayDate = moment().format('D/MM/YY');

    const ignoreHandler = () => console.log('ignoreHandler Clicked');

    const acceptHandler = () => console.log('acceptHandler Clicked');
    const date =
        todayDate === moment(data.date).format('D/MM/YY')
            ? moment(data.date).fromNow()
            : moment(data.date).format('D/MM/YY');

    const formatTitle = (name) => {
        name = name?.replaceAll('_', ' ')?.replaceAll(':', ' : ');
        return titleCase(name);
    };
    return (
        <NotificationItemWrapper
            className={`notification-item ${dense ? 'dense' : ''} ${disabled ? 'disabled' : ''}`}
            {...props}
            style={{ cursor: 'pointer' }}
            onClick={onMarkRead}
        >
            <div className="notification-header">
                <div className="actions">
                    <span>{date}</span>
                    {!dense && (
                        <div className="close" onClick={() => onClose(data.id)}>
                            {dismissText ? <span className="btn">Dismiss</span> : <Trash2 className="btn" size="16" />}
                        </div>
                    )}
                </div>
                <div className="title">{formatTitle(data?.title || data?.name)}</div>
            </div>

            <div className={`notification-body ${data.actions ? 'show-actions' : ''}`}>
                <div>
                    <span>{data.content}</span>
                </div>

                <div className="btn-group">
                    <Button className="igone" type="text" onClick={ignoreHandler}>
                        Ignore
                    </Button>
                    <Button className="accept" type="text" onClick={acceptHandler}>
                        Accept
                    </Button>
                </div>
            </div>
        </NotificationItemWrapper>
    );
};

export default NotificationItem;
