import { Button, Col, Divider, Form, Modal, Row, Select } from 'antd';
import React, { FunctionComponent } from 'react';
import { ChevronDown, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { actions } from '../../../../authentication/sign-in/store/actions';
interface Props {
    showModal: boolean;
    hideModal: (val: boolean) => void;
}

const SwitchGroupModal: FunctionComponent<Props> = ({ showModal, hideModal }: Props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const auth: any = useSelector((state: RootState) => state.auth);
    const user = auth?.user;

    const userGroups: any[] = user?.userGroups || [];
    const activeUserGroup: any = user?.activeUserGroup || {};

    const onFinish = (values: any) => {
        const activeGroup = userGroups?.filter((val) => val?.id == values?.userGroupId);
        if (activeGroup.length > 0) {
            dispatch(actions.onChangeActiveUserGroup(activeGroup[0]));
        }
        hideModal(false);
    };

    return (
        <Modal
            title={`Switch Group`}
            visible={showModal}
            onCancel={() => hideModal(false)}
            footer={false}
            getContainer={false}
            closeIcon={<X className="close-modal" />}
            width="40%"
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={20}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Select Group"
                            name="userGroupId"
                            className="label"
                            initialValue={activeUserGroup?.id}
                            rules={[{ required: true, message: 'Please select user group!' }]}
                        >
                            <Select className={`select`} suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}>
                                {userGroups.map((userGroup) => (
                                    <Select.Option value={userGroup?.id} key={userGroup?.id}>
                                        {userGroup?.branchName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider className="divider" />
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => hideModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" htmlType="submit">
                        Switch Group
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default SwitchGroupModal;
