import { css } from 'styled-components';
import { COLORS, DEVICE } from '../../../config/theme';
import addMember from './add-member';
import pickUpStyles from './pick-up';
import notesStyles from './notesStyles';

const modalStyles = () => css`
    ${addMember}
    ${pickUpStyles}
    ${notesStyles}
    .ant-modal {
        width: 90% !important;
        max-width: none;
    }
    @media ${DEVICE.laptop || DEVICE.laptopL || DEVICE.desktop || DEVICE.desktopL} {
        .ant-modal {
            width: 40% !important;
        }
    }

    .ant-modal-header {
        padding: 20px;
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        border-radius: 2px 2px 0 0;
        .ant-modal-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
    }

    .close-modal {
        color: ${(props: any) => props.theme[COLORS.ICONS]};
        width: 20px;
        margin-top: 15px;
        &:hover {
            filter: brightness(65%);
        }
    }
    .request-connect-modal {
        border-radius: 5px;
        .ant-modal-body {
            padding: 24px 20px 15px;
        }
        .ant-modal-header {
            border-bottom: none;
        }
    }
`;

export default modalStyles;
