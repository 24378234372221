import React from 'react';
import PlainLayout from '../../components/Layouts/PlainLayout';
import SystemBreak from '../../assets/icons/Svg/System-Break.svg';
import { withRouter } from 'react-router-dom';
import { goToRoute, ROUTES } from '../Helper';

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<any, IState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // Display fallback UI
        this.setState({ hasError: true });
        console.log(error, errorInfo);
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
    }

    goBack = () => {
        this.setState({ hasError: false });
        this.props.history.push({ pathname: goToRoute(ROUTES.DASHBOARD) });
    };
    render() {
        if (this?.state?.hasError) {
            // You can render any custom fallback UI
            return (
                <PlainLayout>
                    <img src={SystemBreak} alt="Alert" className="main-image" />
                    <div className="content-container">
                        <p className="title">Well, this is embarrassing!</p>
                        <span className="description">
                            Looks like the page you are looking for does not exist or something is broken somewhere or
                            is under development.
                        </span>
                    </div>
                    <div className="action-container">
                        <span onClick={() => this.goBack()} className="go-back">
                            Go back
                        </span>{' '}
                        or you may also try reloading the page.
                    </div>
                </PlainLayout>
            );
        }
        return this?.props?.children;
    }
}

export default withRouter(ErrorBoundary);
