import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const globalList = () => css`
    .list {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
    }
    .ant-list-header {
        padding: 16px 0px;
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .heading-wrapper {
            display: flex;
            flex-direction: row;
            padding-right: 25px;
            .list-heading {
                font-weight: 600;
                font-size: 13px;
                line-height: 25px;
                color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
                margin-right: 10px;
                padding-left: 20px;
            }
        }
    }

    .ant-list-pagination {
        margin-top: 0;
        border-top: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        .ant-pagination {
            padding: 8px 20px;
        }
        .ant-pagination-total-text {
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin-right: auto;
            span {
                color: ${(props: any) => props.theme[COLORS.TERMS_AND_CONDITIONS_BORDER]};
            }
        }
    }
`;

export default globalList;
