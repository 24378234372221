import { Card } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { Extra } from './data';
import style from '../styles';
import EditableTag from './tags';

const BuyerGroupWraper = styled.div`
    ${style}
`;

interface CardDataProps {
    data: Array<any>;
}
const CardData: React.FunctionComponent<CardDataProps> = ({ data }: CardDataProps) => {
    const action = () => {
        // console.log(val);
    };
    return (
        <BuyerGroupWraper>
            {data.map((value) => (
                <>
                    <div className="account-settings" key={value.id}>
                        <Card
                            title={value.card}
                            className={value.class}
                            extra={<Extra name={value.card} onClick={action} />}
                        >
                            <div style={{ padding: '10px 15px 15px 15px' }}>
                                <EditableTag />
                            </div>
                        </Card>
                    </div>
                </>
            ))}
        </BuyerGroupWraper>
    );
};

export default CardData;
