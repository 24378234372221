import React from 'react';
import { Row, Col, Divider, Menu, Breadcrumb } from 'antd';
import AuthenticatedLayout from '../Layouts/Authenticated';
import { Link } from 'react-router-dom';
import style from './styles';
import styled from 'styled-components';
// import product from '../../assets/icons/Svg/Inactive/products.svg';
import business from '../../assets/Images/business.svg';
import person from '../../assets/Images/person.svg';
import team from '../../assets/Images/team.svg';
import fraud from '../../assets/Images/shield-checkmark.svg';
import supplier from '../../assets/Images/add-person.svg';
import trade from '../../assets/Images/trade-insights.svg';
import PageHeader from '../../utils/PageHeader';

const InsightsWrapper = styled.div`
    ${style}
`;

const itemsBusinessLeft = [
    { label: 'KYC Status', key: 1, link: '#' },
    { label: 'Financing Limits', key: 2, link: '#' },
    { label: 'Buyer Financing', key: 3, link: '#' },
    { label: 'Supply Account', key: 4, link: '#' },
];

const itemsBusinessRight = [
    { label: 'Loan Account', key: 6, link: '#' },
    { label: 'Cashflows', key: 7, link: '#' },
    { label: 'Inventory', key: 8, link: '#' },
    { label: 'Credit Reference Bureau', key: 9, link: '#' },
];

const itemsBuyerLeft = [
    { label: 'Onboarded Buyers/ Wholesalers/ Retailers', key: 1, link: '#' },
    { label: 'Onboarded Suppliers', key: 2, link: '#' },
    { label: 'Retailer/ Wholesaler Limits', key: 3, link: '#' },
];

const itemsBuyerRight = [
    { label: 'Supplier Limits', key: 4, link: '#' },
    { label: 'Buyer/ Retailer/ Distributor Financing', key: 5, link: '#' },
    { label: 'Supplier Financing', key: 6, link: '#' },
];

const itemsSupplierLeft = [
    { label: 'Account Summary', key: 1, link: '#' },
    { label: 'Account Statments', key: 2, link: '#' },
];

const itemsSupplierRight = [{ label: 'Payment Insights', key: 3, link: '#' }];

const itemsSecurityLeft = [
    { label: 'Audit Logs', key: 1, link: '#' },
    { label: 'Users & Roles', key: 2, link: '#' },
];

const itemsSecurityRight = [
    { label: 'Supplier Portal Access', key: 3, link: '#' },
    { label: 'Buyer Portal Access', key: 4, link: '#' },
];

const itemsSecurityFraudLeft = [
    { label: 'LC & Bonds Marked Limits', key: 1, link: '#' },
    { label: 'Trade Finance Account', key: 2, link: '#' },
];
const itemsSecurityFraudRight = [{ label: 'Escrow Statement', key: 3, link: '#' }];

interface IProps {
    items: Array<any>;
}

const MenuItems = ({ items }: IProps) => {
    // const handleClick = () => {};
    return (
        <Menu className="gapstack-menu" style={{ width: '100%' }} mode="inline">
            {items.map(({ label, key, link }) => (
                <Menu.Item key={key} className="menu-name">
                    <span>{label}</span>
                    <Link to={link} />
                </Menu.Item>
            ))}
        </Menu>
    );
};

const Insights = () => {
    return (
        <AuthenticatedLayout>
            <InsightsWrapper>
                <PageHeader>
                    <Row className="header-table">
                        <Col span={12}>
                            <Breadcrumb separator="|">
                                <Breadcrumb.Item>Insights</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </PageHeader>

                <Row className="business-container">
                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={business} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Business Insights</h4>
                        </span>
                    </Col>
                    <Divider />
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsBusinessLeft} />
                    </Col>
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsBusinessRight} />
                    </Col>
                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={person} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Anchor & Value Chain Insights</h4>
                        </span>
                    </Col>
                    <Divider />
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsBuyerLeft} />
                    </Col>
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsBuyerRight} />
                    </Col>

                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={supplier} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Account & Payment Insights</h4>
                        </span>
                    </Col>
                    <Divider />
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsSupplierLeft} />
                    </Col>
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsSupplierRight} />
                    </Col>

                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={team} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Platform Insights</h4>
                        </span>
                    </Col>
                    <Divider />
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsSecurityLeft} />
                    </Col>
                    <Col md={{ span: 12 }}>
                        <MenuItems items={itemsSecurityRight} />
                    </Col>

                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={trade} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Trade Financing</h4>
                        </span>
                    </Col>
                    <Divider />
                    <Col md={{ span: 12 }} style={{ paddingBottom: 20 }}>
                        <MenuItems items={itemsSecurityFraudLeft} />
                    </Col>
                    <Col md={{ span: 12 }} style={{ paddingBottom: 20 }}>
                        <MenuItems items={itemsSecurityFraudRight} />
                    </Col>
                    <Col md={{ span: 24 }} className="business-title">
                        <span>
                            <img src={fraud} alt="" className="header-icon" />
                        </span>
                        <span>
                            <h4>Run Your Own Reports</h4>
                        </span>
                    </Col>
                    <Divider />
                    {/* <div>
                        <Button
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            type="link"
                            className="gapstack-button"
                        >
                            Generate Keys
                        </Button>
                    </div> */}
                </Row>
            </InsightsWrapper>
        </AuthenticatedLayout>
    );
};

export default Insights;
