import React, { useEffect, useState } from 'react';
import { Form, notification, Select } from 'antd';
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import style from './styles';
import { unAuthenticatedRequest } from '../../../api';
import { get_business_types } from '../../../apis/business-accounts';

interface Props {
    formItemLabel: string;
    formItemName: string | string[];
    required: boolean;
    message: string;
    initialValue?: string;
    onChange(value): void;
    validateStatus?: 'error' | 'success' | undefined | 'validating' | 'warning' | '';
    hasFeedback?: boolean;
    valueType?: 'label' | 'value';
    disabled?: boolean;
}

const { Option } = Select;

const BusinessTypeWrapper = styled.div`
    ${style}
`;

const SelectBusinessType = ({
    formItemLabel,
    formItemName,
    required,
    message,
    initialValue,
    onChange,
    validateStatus,
    hasFeedback = false,
    valueType = 'value',
    disabled,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [businessTypes, setBusinessTypes] = useState<any[]>([]);

    const fetchBusinessTypes = () => {
        setLoading(true);
        unAuthenticatedRequest({}, false)
            .get(get_business_types)
            .then((res) => {
                setLoading(false);
                setBusinessTypes(
                    (res.data || [])?.map((val) => {
                        return { label: val.name, value: val.id };
                    }),
                );
            })
            .catch((error) => {
                setLoading(false);
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    useEffect(() => {
        fetchBusinessTypes();
    }, []);

    const onChangeBusinessType = (value) => {
        const foundIndex = businessTypes.findIndex((businessType) => businessType.value === value);

        onChange(businessTypes[foundIndex]);
    };
    return (
        <BusinessTypeWrapper>
            <Form.Item
                label={formItemLabel}
                name={formItemName}
                className="label"
                initialValue={initialValue}
                rules={[{ required, message }]}
                validateStatus={validateStatus}
                hasFeedback={hasFeedback}
            >
                <Select
                    allowClear
                    loading={loading}
                    className="form-select select"
                    placeholder="Individual"
                    onChange={onChangeBusinessType}
                    suffixIcon={<ChevronDown color="#A3A3B4" size="16" />}
                    disabled={disabled}
                    data-testid="select-types"
                >
                    {businessTypes.map((businessType, index) => (
                        <Option key={index} value={valueType === 'value' ? businessType.value : businessType.label}>
                            {businessType.label}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        </BusinessTypeWrapper>
    );
};

export default SelectBusinessType;
