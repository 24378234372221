import { checkPermittedRoles, checkRestrictedRoles, RolesType } from './AuthorizationWrapper';
import { RoutePermission } from '../router/permissions';

export const checkRoutePermission = (user: any, permission?: RoutePermission) => {
    if (!permission) return true;
    const { allowedRoles = [], restrictedRoles = [] } = permission;
    if (allowedRoles.length === 0 && restrictedRoles.length === 0) return true;

    // TODO change this a/c to authUser Reducer
    const userRoles: RolesType | RolesType[] = user.roles || [];

    const _restrictedRoles: RolesType[] = Array.isArray(restrictedRoles) ? restrictedRoles : [restrictedRoles];

    const restricted: boolean = checkRestrictedRoles(userRoles, _restrictedRoles);

    if (restricted) return false;

    const _allowedRoles: RolesType[] = Array.isArray(allowedRoles) ? allowedRoles : [allowedRoles];

    return checkPermittedRoles(userRoles, _allowedRoles);
};
