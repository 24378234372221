import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = () => css`
    background: ${(props: any) => props.theme[COLORS.BACKGROUND]};
    padding-bottom: 60px;
    .container {
        flex: 1;
        padding-top: 10px;
    }
    .strategy-container {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border-left: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        border-right: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        border-top: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.07);
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 20px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        background-color: ${(props: any) => props.theme[COLORS.TRANSPARENT]};
        height: 48px;
        padding: 5px 10px;
        border: none;
    }
    .select-icon {
        color: ${(props: any) => props.theme[COLORS.ICONS]};
        width: 16px !important;
        height: 16px !important;
        top: 18%;
    }
    .ship-select {
        width: 100%;
        margin-bottom: 0px !important;
        height: 47px;
        .ant-select-selector {
            height: 45px !important;
            background: #fafafa !important;
            border-radius: 5px !important;
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
        .ant-select-arrow {
            top: 50% !important;
        }
        .select-icon {
            color: ${(props) => props.theme[COLORS.ICONS]};
            width: 15px;
        }
    }

    .ant-form-item-with-help {
        margin-bottom: -25px;
    }

    .strategyActions .gapstack-input .ant-input-number-input {
        height: 50px;
    }

    .content-heading {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: rgb(57, 66, 106);
        display: flex;
        justify-content: flex-start;
    }
    .divider {
        width: calc(100% - 120px);
        min-width: 78%;
        display: inline-block;
        margin: 0 0 0 12px;
    }
    .footer-container {
        background: ${(props: any) => props.theme[COLORS.POP_UP]};
        border-top: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
        border-right: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        border-left: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        border-bottom: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
        box-sizing: border-box;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 20px;
    }
    .footer {
        display: flex;
        justify-content: flex-end;
        .save-button {
            background: ${(props: any) => props.theme[COLORS.PRIMARY]};
            border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            color: #ffffff;
            height: 45px;
            padding: 0 20px;
            margin-left: 10px;
        }
        .cancel-button {
            background: #fff;
            border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 500;
            font-size: 15px;
            color: ${(props: any) => props.theme[COLORS.DECLINED]};
            height: 45px;
            width: 88px;
            margin-left: auto;
        }
    }
    .ant-form-item {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .ant-form-item-control {
            width: 100%;
        }
    }
    .footer .ant-form-item {
        margin-bottom: 0 !important;
    }
    .gapstack-button:first-child {
        margin-right: 10px;
    }
    textarea.gapstack-input {
        font-size: 13px;
        padding: 8px 10px;
    }
    .add-button {
        border: none;
        outline: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0;
        margin-top: 15px;
        box-shadow: none;
        .plus-circle {
            width: 15px;
            height: 15px;
        }
        .add-tier {
            padding-left: 8px;
            font-size: 13px;
            font-weight: 400;
            color: #1e87f0;
        }
    }
    .icon-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
        span {
            font-weight: 500;
            font-size: 13px;
            color: ${(props) => props.theme[COLORS.PARAGRAPH]};
        }
        .delete-icon {
            color: ${(props) => props.theme[COLORS.SECONDARY]};
            position: absolute;
            right: 22px;
            width: 16px;
            cursor: pointer;
            z-index: 10;
        }
    }

    .strategyActions > div:only-child {
        .icon-wrapper {
            display: none;
        }
    }
`;

export default style;
