import { Button, notification } from 'antd';
import { FormInstance } from 'antd/es';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { authenticatedRequest } from '../../../../api';
import {
    get_all_invites_director,
    get_documents_business_profile,
    get_documents_status,
    upload_documents_business_profile,
} from '../../../../apis/business-accounts';
import { get_file_information } from '../../../../apis/uploads';
import { get_kyc_docs_for_business } from '../../../../apis/business-accounts/kyc_settings';
import { KYCStatuses } from '../../helpers';
import { DocType } from './components/SIngleDirector';
import SubmitDocsModal from './components/SubmitDocs';
import { CompanyDocuments, TaxInformation, Directors, BankAccounts, AuditedAccounts, loaderData } from './export';
import { DocumentPageType, DocumentNameAndType } from './types';
import {
    checkDocsInArray,
    companyDocsArray,
    ConditionalRenderMain,
    getCustomDocs,
    taxDocsArray,
} from './components/ConditionalRender';
import CustomDocs from './CustomDocs';
import { useParams } from 'react-router';
import { getUser, Users } from '../../../../config';
import LivenessDetection from './LivenessDetection';
import { CardLoader } from '../../../../utils/Loader';

interface KYCDocumentsProps {
    form?: FormInstance;
    completedStatuses: any;
    setCompletedStatuses: (val: any) => void;
    changeStep: (status: KYCStatuses) => void;
    activeData?: any;
    cardLoading: boolean;
}
const KYCDocuments: FunctionComponent<KYCDocumentsProps> = ({
    form,
    completedStatuses,
    setCompletedStatuses,
    changeStep,
    activeData,
    cardLoading,
}: KYCDocumentsProps) => {
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    const [directors, setDirectors] = useState<any[]>([]);
    const [documents, setDocuments] = useState<any>({});
    const [errObj, setErrorObj] = useState<any>({});
    const [documentErrObj, setDocumentErrorObj] = useState<any>({});
    const [sectionErrObj, setSectionErrorObj] = useState<any>({});
    const [dirErrObj, setDirErrorObj] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [submitModal, setSubmitModal] = useState<boolean>(false);
    const [docTypes, setDocTypes] = useState<any[]>([]); // Docs array with only doc names
    const [docsArray, setDocsArray] = useState<any[]>([]); // Docs array with validity period
    const [uploadingObj, setUploadingObj] = useState<any>({});

    useEffect(() => {
        if (directors && directors.length > 0) {
            form?.setFieldsValue({ directors });
        }
    }, [directors]);

    const catchErr = (err) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };
    const showErr = (err) => {
        notification.error({
            message: 'Error',
            description: err || 'Oops! An unexpected error occurred.',
        });
    };

    useEffect(() => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        fetchDocTypes();
        //get directors invite status
        authenticatedRequest(isBank ? businessHeader : {})
            .get(get_all_invites_director)
            .then((res) => res.data)
            .then((res) => {
                const invites = (Array.isArray(res) && res.length > 0 && res) || [];
                //Get all documents
                authenticatedRequest(isBank ? businessHeader : {})
                    .get(get_documents_business_profile)
                    .then((res) => res.data)
                    .then((res) => {
                        if (Array.isArray(res)) {
                            const ob = {};
                            let tempDirectorsOb: any = [];
                            const addedDirectors: any = {};
                            res.map((val) => {
                                if (val.documentName == DocumentNameAndType.DIRECTOR_ID) {
                                    tempDirectorsOb = {
                                        ...tempDirectorsOb,
                                        [val?.documentLabel]: {
                                            ...tempDirectorsOb[val.documentLabel],
                                            [val.pageType ? val.pageType : DocumentPageType.STANDARD]: val.url,
                                            documentId: val.documentId,
                                            docType:
                                                val.pageType == DocumentPageType.STANDARD
                                                    ? DocType.PASSPORT
                                                    : DocType.NATIONAL_ID,
                                            name: val?.documentLabel,
                                            [`reasonForReturn_${
                                                val.pageType ? val.pageType : DocumentPageType.STANDARD
                                            }`]: val.reasonForReturn,
                                        },
                                    };
                                    addedDirectors[val?.documentLabel] = true;
                                } else {
                                    if (val.documentName == DocumentNameAndType.LIVE_ID) {
                                        ob[`${val.documentLabel}_${val.documentName}`] = val;
                                    } else {
                                        ob[val.documentName] = val;
                                    }
                                }
                            });

                            invites.map((val) => {
                                tempDirectorsOb[val?.documentLabel] = {
                                    ...tempDirectorsOb[val?.documentLabel],
                                    inviteId: val?.id,
                                    email: val?.email,
                                    firstName: val?.firstName,
                                    status: val?.status,
                                    documentLabel: val?.documentLabel,
                                    name: val?.documentLabel,
                                };
                            });

                            if (ob[DocumentNameAndType.CR12]?.fileId) {
                                //get extracted data for directors
                                authenticatedRequest(isBank ? businessHeader : {})
                                    .get(
                                        get_file_information(
                                            ob[DocumentNameAndType.CR12]?.fileId,
                                            DocumentPageType.STANDARD,
                                        ),
                                    )
                                    .then((res) => res.data)
                                    .then((res) => {
                                        let extractedInfo = res?.data?.directors;

                                        if (Array.isArray(extractedInfo) && extractedInfo.length > 0) {
                                            const directorsName = extractedInfo.map((val) => val?.name);
                                            let isExtracted = false;
                                            directorsName.map((val) => {
                                                if (addedDirectors[val] && !isExtracted) {
                                                    isExtracted = true;
                                                }
                                            });
                                            if (isExtracted) {
                                                extractedInfo = extractedInfo.map((val) => {
                                                    let tempObj: any = { ...val };
                                                    if (tempDirectorsOb[val?.name]) {
                                                        tempObj = {
                                                            ...tempObj,
                                                            ...tempDirectorsOb[val?.name],
                                                        };
                                                    }
                                                    return tempObj;
                                                });
                                                setDirectors(extractedInfo);
                                            } else {
                                                setDirectors(Object.values(tempDirectorsOb));
                                            }
                                        } else {
                                            setDirectors(Object.values(tempDirectorsOb));
                                        }

                                        setTimeout(() => {
                                            setLoading(false);
                                        }, 500);

                                        setDocuments({ ...ob });
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        catchErr(err);
                                    });
                            } else {
                                setDirectors(Object.values(tempDirectorsOb));

                                setDocuments({ ...ob });
                                setTimeout(() => {
                                    setLoading(false);
                                }, 500);
                            }
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        catchErr(err);
                    });
            })
            .catch((err) => catchErr(err))
            .then(() => fetchDocsStatus());
    }, []);

    const handleErrorFromDocsManager = (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType = DocumentPageType.STANDARD,
        documentLabel?: string,
    ) => {
        if (!err) return;
        const ob = JSON.parse(JSON.stringify(documentErrObj));
        if (documentName != DocumentNameAndType.DIRECTOR_ID) {
            ob[documentName] = { error: err };
        } else if (documentName == DocumentNameAndType.DIRECTOR_ID) {
            if (documentLabel) {
                ob[documentLabel] = {
                    [`${pageType}Error`]: err,
                };
            }
        }
        setDocumentErrorObj({ ...documentErrObj, ...ob });
    };
    const uploadFilesToBusinessAccount = (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType = DocumentPageType.STANDARD,
        fileId: string,
        documentLabel?: string,
        docType?: string,
    ) => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .post(upload_documents_business_profile, {
                documentId,
                url,
                documentName,
                pageType,
                fileId,
                documentLabel: documentLabel ? documentLabel : undefined,
                documentType: docType ? docType : undefined,
            })
            .then((res) => res.data)
            .then(() => {
                const ob = {};
                const docs = JSON.parse(JSON.stringify(documents));
                const docErrorObj = JSON.parse(JSON.stringify(documentErrObj));
                if (documentName != DocumentNameAndType.DIRECTOR_ID) {
                    ob[documentName] = undefined;
                    if (docs[documentName]) {
                        docs[documentName]['reasonForReturn'] = undefined;
                    }
                    docErrorObj[documentName] = { error: undefined };
                } else if (documentName == DocumentNameAndType.DIRECTOR_ID) {
                    if (documentLabel) {
                        ob[documentLabel] = {
                            [`${pageType}Error`]: undefined,
                        };
                    }
                }
                setErrorObj({ ...errObj, ...ob });
                setDocumentErrorObj({ ...docErrorObj });

                //Update error bcz of returned from back
                setDocuments({ ...docs });
            })
            .catch((err) => {
                catchErr(err);
            });
    };

    const moveToNextStep = () => {
        setCompletedStatuses({
            ...completedStatuses,
            [KYCStatuses.DOCUMENTS]: 'COMPLETE',
            [KYCStatuses.CONFIRM_DETAILS]: 'ACTIVE',
        });
        changeStep(KYCStatuses.CONFIRM_DETAILS);
    };

    const fetchDocsStatus = () => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .get(get_documents_status)
            .then((res) => res.data)
            .then((res) => {
                if (Array.isArray(res.error)) {
                    const ob = {};
                    let tempDirectorsOb: any = {};
                    res.error?.map((val) => {
                        if (val.documentName == DocumentNameAndType.DIRECTOR_ID) {
                            tempDirectorsOb = {
                                ...tempDirectorsOb,
                                [val?.documentLabel]: {
                                    ...tempDirectorsOb[val.documentLabel],
                                    systemError: res?.systemError,
                                    ...val,
                                },
                            };
                        } else {
                            ob[val.documentName] = { ...val, systemError: res?.systemError };
                        }
                    });
                    setDirErrorObj(Object.values(tempDirectorsOb));
                    setErrorObj({ ...ob });
                }
                if (Array.isArray(res.sectionError)) {
                    const ob = {};
                    res.sectionError?.map((val) => {
                        ob[val?.sectionName] = val;
                    });
                    setSectionErrorObj({ ...ob });
                }
            })
            .catch((err) => {
                catchErr(err);
            });
    };

    const fetchDocTypes = () => {
        const businessHeader = {
            'x-business-account-id': businessId,
        };
        authenticatedRequest(isBank ? businessHeader : {})
            .get(get_kyc_docs_for_business)
            .then((res) => res.data)
            .then((res) => {
                if (Array.isArray(res)) {
                    setDocTypes(res?.map((val) => val.documentType));
                    setDocsArray(res);
                }
            })
            .catch((err) => {
                catchErr(err);
            });
    };

    useEffect(() => {
        const timeOut: any = setInterval(() => {
            fetchDocsStatus();
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    return loading || cardLoading ? (
        <>
            {loaderData.map((cardData, key) => (
                <CardLoader
                    key={key}
                    rows={cardData?.rows}
                    title={cardData?.title}
                    loading={loading}
                    fullRow
                    className={key > 0 ? 'card-margin-top' : undefined}
                />
            ))}
        </>
    ) : (
        <>
            <ConditionalRenderMain shouldRender={checkDocsInArray(docTypes, companyDocsArray)}>
                <CompanyDocuments
                    setDirectors={setDirectors}
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    documents={documents}
                    errorObj={errObj}
                    directors={directors}
                    docTypes={docTypes}
                    docsArray={docsArray}
                    setUploadingObj={setUploadingObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                    activeData={activeData}
                />
            </ConditionalRenderMain>

            <ConditionalRenderMain shouldRender={checkDocsInArray(docTypes, taxDocsArray)}>
                <TaxInformation
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    documents={documents}
                    errorObj={errObj}
                    docTypes={docTypes}
                    docsArray={docsArray}
                    setUploadingObj={setUploadingObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                    activeData={activeData}
                />
            </ConditionalRenderMain>

            <ConditionalRenderMain shouldRender={checkDocsInArray(docTypes, [DocumentNameAndType.AUDITED_ACCOUNT])}>
                <AuditedAccounts
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    documents={documents}
                    docsArray={docsArray}
                    errorObj={errObj}
                    setUploadingObj={setUploadingObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                    activeData={activeData}
                />
            </ConditionalRenderMain>
            <ConditionalRenderMain
                shouldRender={checkDocsInArray(docTypes, [
                    DocumentNameAndType.DIRECTOR_ID,
                    DocumentNameAndType.NATIONAL_ID,
                ])}
            >
                <Directors
                    directors={directors}
                    setDirectors={setDirectors}
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    errorObj={dirErrObj}
                    activeData={activeData}
                    form={form}
                    businessType={activeData?.businessType?.name || 'Individual'}
                    docsArray={docsArray}
                    sectionErrorObj={sectionErrObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                />
            </ConditionalRenderMain>
            {process.env.REACT_APP_SHOW_LIVENESS_DETECTION?.toLowerCase() !== 'false' ? (
                <LivenessDetection
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    directors={directors}
                    activeData={activeData}
                    documents={documents}
                />
            ) : null}
            <ConditionalRenderMain shouldRender={checkDocsInArray(docTypes, [DocumentNameAndType.BANK_STATEMENT])}>
                <BankAccounts
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    documents={documents}
                    docsArray={docsArray}
                    errorObj={errObj}
                    setUploadingObj={setUploadingObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                    activeData={activeData}
                />
            </ConditionalRenderMain>
            <ConditionalRenderMain shouldRender={checkDocsInArray(docTypes, getCustomDocs(docTypes))}>
                <CustomDocs
                    uploadFilesToBusinessAccount={uploadFilesToBusinessAccount}
                    documents={documents}
                    errorObj={errObj}
                    docTypes={docTypes}
                    docsArray={docsArray}
                    setUploadingObj={setUploadingObj}
                    handleErrorFromDocsManager={handleErrorFromDocsManager}
                    documentErrObj={documentErrObj}
                    activeData={activeData}
                />
            </ConditionalRenderMain>
            <div className="button-container">
                <Button
                    className="gapstack-button"
                    onClick={async () => {
                        try {
                            const isNationalIdRequired = checkDocsInArray(docTypes, [
                                DocumentNameAndType.DIRECTOR_ID,
                                DocumentNameAndType.NATIONAL_ID,
                            ]);
                            if (isNationalIdRequired && directors?.length == 0) {
                                showErr('Please upload the national Id');
                                return;
                            } else {
                                if (Object.keys(uploadingObj).length > 0) {
                                    showErr('Please wait to finish upload of document');
                                    return;
                                }
                                directors.map((val) => {
                                    if (val?.isUploadingBack || val?.isUploadingFront) {
                                        showErr('Please wait to finish upload of document');
                                        return;
                                    } else if (
                                        val[`reasonForReturn_${DocumentPageType.FRONT}`] ||
                                        val[`reasonForReturn_${DocumentPageType.BACK}`] ||
                                        val[`reasonForReturn_${DocumentPageType.STANDARD}`]
                                    ) {
                                        showErr('Please change the document returned for changes');
                                        return;
                                    }
                                });
                            }
                            let isErr = false;
                            Object.values(documents).map((document: any) => {
                                if (document?.reasonForReturn) {
                                    showErr('Please change the ' + document?.documentName);
                                    isErr = true;
                                    return;
                                }
                            });
                            Object.keys(documentErrObj).map((errorkey: any) => {
                                const errorValues = documentErrObj[errorkey];
                                if (
                                    errorValues?.error ||
                                    errorValues[`${DocumentPageType.FRONT}Error`] ||
                                    errorValues[`${DocumentPageType.BACK}Error`] ||
                                    errorValues[`${DocumentPageType.STANDARD}Error`]
                                ) {
                                    showErr('Please change the ' + errorkey);
                                    isErr = true;
                                    return;
                                }
                            });
                            if (isErr) return;
                            const isValidated = await form?.validateFields();
                            isValidated && setSubmitModal(true);
                        } catch (err) {
                            console.log(err);
                            return;
                        }
                    }}
                >
                    Next
                </Button>
            </div>
            {submitModal && (
                <SubmitDocsModal showModal={submitModal} hideModal={setSubmitModal} moveToNextStep={moveToNextStep} />
            )}
        </>
    );
};

export default KYCDocuments;
