import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';

import SideBar from './components/sidebar';
import MessagesList from './components/messages-list';
import MessageView from './components/message-view';
import sideMenu from './data/sideMenu.json';
import { authenticatedRequest } from '../../api';

import { get_draft_mails, get_inbox_mails, get_sent_mails, get_trash_mails } from './apis';

import { mapMailData, MessageSource } from './data/mappedData';

const MailApi = {
    inbox: get_inbox_mails,
    sent: get_sent_mails,
    draft: get_draft_mails,
    trash: get_trash_mails,
};

const getSourceKey = (value: string) => Object.keys(MessageSource).find((key) => MessageSource[key] === value);

const EmailMessages = () => {
    const { businessId }: any = useParams();
    const [data, setData] = useState<any>({});
    const [active, setActive] = useState<string>(sideMenu[0].dataIndex);
    const [messageView, setMessageView] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);

    const authUser = useSelector((state: any) => state.auth?.user || {});

    const fetchMailData = React.useCallback(
        (actionType) => {
            const header = {
                'x-business-account-id': businessId,
                'x-user-id': authUser.userId,
            };
            authenticatedRequest(header)
                .get(MailApi[actionType], {
                    params: {
                        limit: 15,
                    },
                })
                .then((res) => res.data)
                .then((response) => {
                    const resData = mapMailData(response[actionType], actionType);
                    setData((prevState) => {
                        return {
                            ...prevState,
                            [actionType]: resData,
                        };
                    });
                })
                .catch((err) => {
                    setLoading(false);
                    const error = err?.response?.data?.message || err?.response?.message;
                    notification.error({
                        message: error || 'Unexpected error',
                        type: 'error',
                    });
                });
        },
        [businessId],
    );

    useEffect(() => {
        fetchMailData(active);
    }, [active, fetchMailData]);

    const clickHandler = (i) => {
        // check if menu is already active
        if (active !== sideMenu[i].dataIndex) {
            setActive(sideMenu[i].dataIndex);
            setMessageView({});
        }
    };

    const onDelete = useCallback(
        (message: any) => {
            const source = getSourceKey(message.source) as string;
            setData((prevState) => {
                const prevData = prevState[source] || [];
                const newData = prevData.filter((x) => x.id !== message.id);
                return {
                    ...prevState,
                    [source]: newData,
                };
            });
            setMessageView({});
        },
        [active],
    );

    return (
        <Fragment>
            <SideBar active={active} menuItems={sideMenu} onClick={clickHandler} messages={data} />
            <MessagesList
                data={data[active]}
                activeTab={active}
                selectedMessage={messageView}
                setMessageView={setMessageView}
                loading={loading}
            />
            <MessageView
                data={messageView}
                // getEmailData={() => fetchMailData(active)}
                onDelete={onDelete}
                loading={loading}
            />
        </Fragment>
    );
};

export default EmailMessages;
