export enum PAYMENT_METHODS {
    DEBIT = 'DEBIT',
    MPESA = 'MPESA',
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    AMX = 'AMX',
    BANK = 'BANK',
}

export enum PAYMENT_TYPES {
    PAY_LOAN = 'PAY_LOAN',
    PAY_BID_BOND = 'PAY_BID_BOND',
}

export enum PAYMENT_STATES {
    INITIATED = 'INITIATED',
    PROCESSING = 'PROCESSING',
    SUCCESSFUL = 'SUCCESSFUL',
    FAILED = 'FAILED',
}

export enum GUARANTEE_STATES {
    DRAFT = 'DRAFT',
    AWAITING_PAYMENT = 'AWAITING_PAYMENT',
    WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
    SENT_FOR_APPROVAL = 'SENT_FOR_APPROVAL',
    SENT_FOR_VERIFICATION = 'SENT_FOR_VERIFICATION',
    KYC_WAITING_APPROVAL = 'KYC_WAITING_APPROVAL',
    CHANGES_REQUIRED = 'CHANGES_REQUIRED',
    UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
    DOCUMENTS_PENDING = 'DOCUMENTS_PENDING', //SAVED FOR LATER IN UPLOAD DOCUMENTS PAGE
    WAITING_FOR_DOCUMENTS_APPROVAL = 'WAITING_FOR_DOCUMENTS_APPROVAL',
    DOCUMENT_CHANGES_REQUIRED = 'DOCUMENT_CHANGES_REQUIRED',
    DECLINED = 'DECLINED',
    ISSUED = 'ISSUED',
    EXPIRED = 'EXPIRED',
    CALLED = 'CALLED',
    CANCELLED = 'CANCELLED',
}

export enum SIGN_UP_FORM_TYPES {
    BANK_BUSINESS_SIGN_UP = 'BANK_BUSINESS_SIGN_UP',
    NORMAL_SIGN_UP = 'NORMAL_SIGN_UP',
}

export enum PROCURING_ENTITY_STATES {
    VALIDATED = 'VALIDATED',
    DEACTIVATED = 'DEACTIVATED',
    PENDING = 'PENDING',
}

export enum FINANCING_STATES {
    DRAFT = 'DRAFT',
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    SENT = 'SENT',
    REQUESTED_FOR_APPROVAL = 'REQUESTED_FOR_APPROVAL',
    RETURNED_FOR_CHANGES = 'RETURNED_FOR_CHANGES',
    AWAITING_BUYER_APPROVAL = 'AWAITING_BUYER_APPROVAL',
    BUYER_APPROVED = 'BUYER_APPROVED',
    BUYER_DECLINED = 'BUYER_DECLINED',
}
