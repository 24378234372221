import { Col } from 'antd';
import React from 'react';
import { DocumentNameAndType } from '../types';

interface ConditionalRenderProps {
    children: any;
    shouldRender: boolean;
}

export const ConditionalRenderDocs = ({ children, shouldRender }: ConditionalRenderProps) => {
    if (shouldRender) {
        return <Col span={24}>{children}</Col>;
    }
    return null;
};
export const ConditionalRenderMain = ({ children, shouldRender }: ConditionalRenderProps) => {
    if (shouldRender) {
        return children;
    }
    return null;
};

export const companyDocsArray = [
    DocumentNameAndType.CR12,
    DocumentNameAndType.COI,
    DocumentNameAndType.BUSINESS_LICENSE,
    DocumentNameAndType.MEMORANDOM_AND_ARTICLES_OF_ASSOCIATION,
    DocumentNameAndType.BUSINESS_PERMIT,
    DocumentNameAndType.PARTNER_REGISTRATION_CERT,
    DocumentNameAndType.BUSINESS_REGISTRATION,
    DocumentNameAndType.BUSINESS_REGISTRATION_CERT,
];
export const taxDocsArray = [
    DocumentNameAndType.TAX_COMPLIANCE,
    DocumentNameAndType.PIN_CERTIFICATE,
    DocumentNameAndType.MANAGEMENT_ACCOUNTS,
];

export const checkDocsInArray = (docTypes: any[], values: DocumentNameAndType[]) => {
    let shouldRender = false;
    values.map((val) => {
        if (docTypes.indexOf(val) > -1 && !shouldRender) {
            shouldRender = true;
        }
    });
    return shouldRender;
};

export const getCustomDocs = (docTypes: any[]) => {
    const arr: any[] = [];
    docTypes.map((value) => {
        if (Object.values(DocumentNameAndType).indexOf(value) < 0) {
            arr.push(value);
        }
    });
    return arr;
};
