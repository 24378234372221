import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .select {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        .ant-select-selector {
            width: 100%;
            height: 45px;
            border-radius: 5px;
            background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
            .ant-select-selection-item {
                line-height: 40px;
            }
        }
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 45px;
    }

    .ant-select-selector > .ant-select-selection-placeholder {
        line-height: 43px;
    }
`;
export default style();
