export const UnAuthenticatedRoutes = [
    'LANDING_PAGE',
    'EXTERNAL_VERIFY_BOND',
    'EXTERNAL_VERIFY_BOND_RESULTS',
    'SIGN_IN',
    'SIGN_UP',
    'NEW_USER_SIGN_UP',
    'CONFIRM_USER',
    'ADD_ACCOUNT',
    'CONFIRM_ACCOUNT',
    'FORGOT_PASSWORD',
    'VERIFY_PHONE',
    'VERIFY_DOC',
    'ACTIVATE_ACCOUNT',
    'RESET_PASSWORD',
    'TWO_FACTOR_LOGIN',
    'ACCEPT_BUSINESS_INVITATION',
    'INITIALISE_ANOTHER_ACCOUNT',
    'ACCEPT_COLLABORATION_REQUEST',
    'INVITE_DIRECTORS',
    'COMPLETE_SIGN_UP',
    'VERIFY_MANDATE',
    'SET_UP_USSD_PIN',
    'BOT_SIGN_IN',
    'VALIDATE_DOCUMENT',

    // Custom Bank
    'CUSTOM_BANK_SIGN_IN',
    'CUSTOM_BANK_SIGN_UP',
    'CUSTOM_BANK_ACTIVATE_ACCOUNT',
    'CUSTOM_BANK_FORGOT_PASSWORD',
    //Business - Guarantees/Bonds
    'NEW_GUARANTEE',
] as const;

export const UnAuthenticatedBankRoutes = [
    'SIGN_IN',
    'FORGOT_PASSWORD',
    'RESET_PASSWORD',
    'TWO_FACTOR_LOGIN',
    'VERIFY_PHONE',
    'VERIFY_ACCOUNT',
    'COMPLETE_REGISTRATION',
] as const;

export const onlyBankRoutes = [
    'INITIALIZATION_BANK',

    //Bank - Business accounts
    'BUSINESS',
    'BUSINESS_ONBOARD',
    'BUSINESS_ACTIVATE_ACCOUNT',
    'BUSINESS_COMPLETE_KYC',
    'VERIFY_BUSINESS_ACCOUNT',

    //Bank - Financing
    'FUND_MY_PURCHASES_BANK',
    'VIEW_FINANCING_REQUEST_BANK',
    'VIEW_VALIDATION_REQUEST_BANK',
    'FUND_FROM_SALES_BANK',
    'VIEW_FINANCING_REQUEST_BANK_SALES',
    'VIEW_VALIDATION_REQUEST_BANK_SALES',

    //Bank - Register bills on collection
    'VIEW_ALL_REGISTER_BILLS',
    'ADD_BILLS_ON_COLLECTION',
    'EDIT_BILLS_ON_COLLECTION',
    'VIEW_SINGLE_BILLS_ON_COLLECTION',

    //Bank settings
    'BANK_SETTINGS',
    'KYC_SETTINGS',
    'TERMS_AND_CONDITIONS_DOCS',

    //Collection and disburement account
    'COLLECTION_ACCOUNT_SETTINGS',

    //Teams
    'TEAMS',
    'USER_GROUPS',
    'CREATE_USER_GROUPS',
    'UPLOAD_USER_GROUPS',
    'VIEW_USER_GROUPS',
    'ADD_MEMBER',

    //Buyer Groups
    'VALIDATED_BUYERS',
    'VIEW_VALIDATED_BUYER',

    //Supplier Groups
    'VALIDATED_SUPPLIERS',
    'VIEW_VALIDATED_SUPPLIER',
    'SUPPLIER_GROUPS',

    //Guarantee-settings
    'GUARANTEE_SETTINGS',
    'ADD_NEW_TEMPLATE',
    'EDIT_TEMPLATE',
    'DUPLICATE_TEMPLATE',
    'VIEW_TEMPLATE',

    //Collection strategy
    'COLLECTION_STRATEGY',
    'ADD_STRATEGY',
    'VIEW_COLLECTION_STRATEGY',
    'CREDIT_LIMITS',
    'ADD_CREDIT_UPLOAD',

    //Charging and Financing
    'CHARGES_FINANCING_PRODUCTS',
    'VIEW_FINANCING_PRODUCTS',

    // Applied Charges
    'APPLIED_CHARGES',
    'ADD_APPLIED_CHARGES',
    'VIEW_APPLIED_CHARGES',

    //Product Settings
    'ADD_NEW_FINANCE_PRODUCT',

    //Letter of credit
    'APPROVE_LETTER_OF_CREDIT',

    //Bonds
    'VERIFY_BOND',
    'BANK_PROFILE',
] as const;

export const onlyBusinessRoutes = [
    'SETTINGS',
    'TEAM_SETTINGS',
    'SECURITY_HISTORY',
    'CONNECTED_APPLICATIONS',
    'ACCOUNT_SETTINGS',
    'BUSINESS_INFORMATION',
    'FINANCIAL_INFORMATION',
    'CREDIT_REPORT',
    'TERMS_CONDITIONS',
    'COMPLETE_KYC',
    'BRAND_SETTINGS',
    'PROFILE',
    'VALIDATE_DOCUMENT',
] as const;

export const AuthenticatedRoutes = [
    //Products
    'PRODUCTS',
    'VIEW_PRODUCT',
    'EDIT_PRODUCT',
    'ADD_NEW_PRODUCT',
    'CONFIRM_BULK_UPLOAD_PRODUCTS',

    'INITIALIZATION',
    'UNAUTHORIZED',
    'DASHBOARD',
    'DASHBOARD_BANK',

    //Supplier
    'SUPPLIER_CENTRAL',
    'VIEW_SUPPLIER',
    'ADD_PURCHASE_SUPPLIER',
    'INVITE_PURCHASE_SUPPLIER',
    'INVITE_BULK_STEP_TWO',
    'PENDING_SUPPLIERS',
    'ONBOARD_SUPPLIERS',

    'MESSAGES',
    'TRACKER',
    'NOTIFICATIONS',

    'PURCHASE_ORDER',
    'VIEW_PURCHASE_ORDER',
    'VIEW_UPLOADED_PURCHASE_ORDER',
    'ADD_PURCHASE_ORDER',
    'CREATE_PURCHASE_ORDER',
    'UPLOAD_PURCHASE_ORDER',
    'CREATE_PURCHASE_ORDER_STEP_2',
    'BULK_PURCHASE_ORDER',
    'EDIT_PURCHASE_ORDER',
    'COPY_PURCHASE_ORDER',
    'CREATE_PURCHASE_ORDER_FROM_QUOTATION',

    'RECEIVED_QUOTATIONS',
    'VIEW_RECEIVED_QUOTATION',
    'VIEW_UPLOADED_RECEIVED_QUOTATION',
    'UPLOAD_RECEIVED_QUOTATION',
    'VALIDATE_RECEIVED_QUOTATION',
    'VIEW_UPLOADED_QUOTATION',

    'INVOICES',
    'VIEW_INVOICE',
    'VIEW_UPLOADED_RECEIVED_INVOICE',
    'ADD_INVOICE',
    'BULK_PURCHASE_INVOICES_TABLE',
    'VALIDATE_INVOICE',

    'BUYER_CENTRAL',
    'VIEW_BUYER',
    'ADD_SALES_BUYER',
    'INVITE_SALES_BUYER',
    'INVITE_BULK_STEP_TWO_BUYER',
    'PENDING_BUYERS',
    'ONBOARD_BUYERS',

    'SALES_ORDER',
    'ADD_SALES_ORDER',
    'ADD_SALES_ORDER_TABLE',
    'VIEW_SINGLE_RECEIVED_PURCHASE_ORDER',
    'VIEW_UPLOADED_RECEIVED_PURCHASE_ORDER',
    'VALIDATE_PURCHASE_ORDER',

    'SALES_INVOICES',
    'VIEW_COMMERCIAL_INVOICE',
    'VIEW_PROFORMA_INVOICE',
    'ADD_SALES_INVOICES',
    'ADD_SALES_INVOICES_FROM_PURCHASE_ORDER',
    'CREATE_COMMERCIAL_INVOICE_STEP_ONE',
    'CREATE_COMMERCIAL_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER',
    'CREATE_COMMERCIAL_INVOICE_STEP_TWO',
    'CREATE_PROFORMA_INVOICE_STEP_ONE',
    'CREATE_PROFORMA_INVOICE_STEP_ONE_CREATE_FROM_PURCHASE_ORDER',
    'CREATE_PROFORMA_INVOICE_STEP_TWO',
    'EDIT_COMMERCIAL_INVOICE',
    'EDIT_PROFORMA_INVOICE',
    'COPY_COMMERCIAL_INVOICE',
    'COPY_PROFORMA_INVOICE',
    'UPLOAD_BULK_INVOICE',
    'BULK_UPLOAD_INVOICES',
    'VIEW_UPLOADED_INVOICE',
    'EDIT_UPLOADED_INVOICE',

    'ADD_EXPORT_INVOICE',
    'VIEW_EXPORT_COMMERCIAL_INVOICE',
    'VIEW_EXPORT_PROFORMA_INVOICE',
    'CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_ONE',
    'CREATE_EXPORT_COMMERCIAL_INVOICE_STEP_TWO',
    'CREATE_EXPORT_PROFORMA_INVOICE_STEP_ONE',
    'CREATE_EXPORT_PROFORMA_INVOICE_STEP_TWO',
    'EDIT_EXPORT_COMMERCIAL_INVOICE',
    'EDIT_EXPORT_PROFORMA_INVOICE',
    'COPY_EXPORT_COMMERCIAL_INVOICE',
    'COPY_EXPORT_PROFORMA_INVOICE',
    'VIEW_EXPORT_UPLOADED_INVOICE',

    'QUOTATIONS',
    'CREATE_QUOTATION_STEP_ONE',
    'CREATE_QUOTATION_STEP_TWO',
    'EDIT_QUOTATION',
    'COPY_QUOTATION',
    'VIEW_QUOTATION',
    'ECOSYSTEM',
    'SYNCHRONIZE',
    'CONNECTED_ACCOUNTS',
    'ADD_NEW_ACCOUNT',
    'VIEW_CONNECTED_ACCOUNT',
    'VIEW_CONNECTED_ACCOUNT_TRANSACTIONS',
    'EDIT_CONNECTED_ACCOUNT',
    'RECONCILE_CONNECTED_ACCOUNT_TRANSACTION',
    'FUND_MY_PURCHASES',
    'VIEW_FINANCING_REQUEST',
    'ADD_SALES_QUOTATIONS',
    'UPLOAD_BULK_QUOTATION',
    'BULK_UPLOAD_QUOTATIONS',
    'ACCOUNT_STATEMENT',
    'RECONCILED_TRANSACTIONS',

    'FUND_FROM_SALES',
    'VIEW_FINANCING_ORDER',
    'PAYMENTS',
    'MAKE_PAYMENTS_ACCOUNT',
    'RECEIVE_PAYMENTS_ACCOUNT',

    'MAKE_PAYMENTS',
    'VIEW_PAYMENTS',
    'RECEIVE_PAYMENTS',
    'PAY_INVOICE',
    'PAY_BILL',
    'REPAY_LOAN',

    'ACCOUNTS',
    //Business - Guarantees/Bonds
    'BONDS',
    'VIEW_BOND',
    'EDIT_BOND',
    'BONDS_FINANCING',
    'ADD_NEW_GUARANTEE',
    'CREATE_NEW_GUARANTEE',

    // Custom Bank
    'NEW_GUARANTEE_SUBMIT',

    'DEVELOPER',
    'VIEW_CONNECTION',
    'GET_FINANCING',
    'FINANCE_INVOICES',
    'WORKING_CAPITAL',
    'DISTRIBUTOR_ECOSYSTEM',
    'EDIT_FINANCE_INVOICES',
    'EDIT_WORKING_CAPITAL',
    'EDIT_DISTRIBUTOR_ECOSYSTEM',
    'GET_FINANCING_SALES',
    'FINANCE_INVOICES_SALES',
    'WORKING_CAPITAL_SALES',
    'DISCOUNT_INVOICES',
    'APPLICATION_SUMMARY',
    'EDIT_FINANCE_INVOICES_SALES',
    'EDIT_WORKING_CAPITAL_SALES',
    'EDIT_DISCOUNT_INVOICES',

    'BLACKLIST',
    'INSIGHTS',

    // import
    'MAKE_IMPORT_PAYMENTS',
    'RECEIVED_BILLS_ON_COLLECTION',
    'VIEW_RECEIVED_BILLS_ON_COLLECTION',
    // 'IMPORT_TRADE',
    'IMPORT_LOANS',
    'VIEW_TRADE_LOAN',
    'GET_TRADE_FINANCING',
    'FINANCE_IMPORT_ORDERS',
    'EDIT_FINANCE_IMPORT_ORDERS',
    'POST_SHIPMENT_FINANCEING',
    'EDIT_POST_SHIPMENT_FINANCING',
    'ADD_PERFORMA_INVOICE',
    // 'FUND_IMPORTS',
    'IMPORT_SERVICES',
    'SHIPMENT_TRACKING',
    'TRACK_SHIPMENT',
    'SECURE_IMPORTS',
    'TRACK_SECURE_IMPORT',
    'ESCROW',
    'NEW_ESCROW',
    'VIEW_ESCROW',
    'TRACK_ESCROW',
    'ADD_NEW_IMPORT_ORDER',
    'BULK_UPLOAD_IMPORT_ORDER',

    // Import Trade --> Trade Finance
    'TRADE_FINANCE',
    'ADD_IMPORT_ORDER',
    'BULK_IMPORT_ORDER',
    'LETTER_OF_CREDIT',
    'VIEW_LETTER_OF_CREDIT',
    'EDIT_LETTER_OF_CREDIT',
    'CLOSE_LETTER_OF_CREDIT',
    'VIEW_IMPORT_ORDER',
    'TRACK_IMPORT_ORDER',
    'UPLOAD_INVOICE',

    // export
    'EXPORT_TRADE_FINANCE',
    'EXPORT_BILLS_ON_COLLECTION',
    'EDIT_EXPORT_BILLS_ON_COLLECTION',
    'VIEW_EXPORT_BILLS_ON_COLLECTION',

    'TRACK_EXPORT_ORDER',
    // 'EXPORT_TRADE',
    'EXPORT_LOANS',
    'VIEW_EXPORT_TRADE_LOAN',
    'GET_EXPORT_TRADE_FINANCING',
    'FINANCE_EXPORT_ORDERS',
    'EDIT_FINANCE_EXPORT_ORDERS',
    'LC_DISCOUNTING',
    'GET_WORKING_CAPITAL',
    'EDIT_GET_WORKING_CAPITAL',
    'COLLECTION_DISCOUNTING',
    'EDIT_COLLECTION_DISCOUNTING',
    // 'ADD_EXPORT_PERFORMA_INVOICE',
    // 'FUND_EXPORTS',
    'EXPORT_SERVICES',
    'EXPORT_SHIPMENT_TRACKING',
    'TRACK_EXPORT_SHIPMENT',
    // 'SECURE_EXPORTS',
    // 'TRACK_SECURE_EXPORT',

    'EXPORT_ESCROW',
    'VIEW_EXPORT_ESCROW',
    'TRACK_EXPORT_ESCROW',
    'MESSAGES_AUTH',
] as const;

export type AuthenticatedRoutesType = typeof AuthenticatedRoutes[number];
export type OnlyBankRoutesType = typeof onlyBankRoutes[number];
export type OnlyBusinessRoutesType = typeof onlyBusinessRoutes[number];
export type UnAuthenticatedRoutesType = typeof UnAuthenticatedRoutes[number];
export type UnAuthenticatedBankRoutesType = typeof UnAuthenticatedBankRoutes[number];

export type RoutesType =
    | UnAuthenticatedRoutesType
    | AuthenticatedRoutesType
    | UnAuthenticatedBankRoutesType
    | OnlyBankRoutesType
    | OnlyBusinessRoutesType;
