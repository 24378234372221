import { Card, Col, Collapse, Form, Input, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import SelectCounty from '../../../../utils/FormSelects/Counties';
import SelectCountry from '../../../../utils/FormSelects/Countries';
import SelectIdDocType from '../../../../utils/FormSelects/IdDocTypes';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import Loader from '../../../../utils/Loader';
import { update_directors } from '../../../../apis/business-accounts';
import { getDirectors, getErrorsListDirectors } from '../../helpers/mapData';
import style from './styles';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;
interface ConfirmDirectorsProps {
    data: any;
    businessType: string;
}

const ConfirmDirectors: FunctionComponent<ConfirmDirectorsProps> = ({ data, businessType }: ConfirmDirectorsProps) => {
    const [directors, setDirectors] = useState<any[]>([]);
    const [countrySelected, setSelectedCountry] = useState<any>({});
    const [activeKey, setActiveKey] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [form] = Form.useForm();
    useEffect(() => {
        if (data?.directors && Array.isArray(data?.directors)) {
            setDirectors(getDirectors(data?.directors));
            setActiveKey(data?.directors?.length > 0 && data?.directors[0]?.id);
            form.setFieldsValue({ directors: getDirectors(data?.directors) });
            form.setFields(getErrorsListDirectors(data?.directors));
            setLoading(false);
        }
    }, [data]);

    const onComplete = () => {
        const values = form.getFieldsValue();
        authenticatedRequest()
            .put(update_directors, { directors: values['directors'] })
            .then((res) => res.data)
            .catch((err) => {
                console.log(err);
                // const resp = err?.response;
                // notification.error({
                //     message: 'Error',
                //     description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                // });
            });
    };

    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);
    return (
        <ConfirmDetailsWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form name="directors" form={form}>
                    <Card
                        title={`${
                            businessType == 'Company'
                                ? 'Directors'
                                : businessType == 'Partnership'
                                ? 'Partners'
                                : 'Individual Details'
                        }`}
                        className="card-wrapper card-margin-top"
                    >
                        <Collapse
                            className="director-collapse"
                            activeKey={activeKey}
                            accordion
                            ghost
                            expandIconPosition={'right'}
                            expandIcon={({ isActive }) =>
                                isActive ? (
                                    <ChevronUp className="accordion-expand" />
                                ) : (
                                    <ChevronDown className="accordion-expand" />
                                )
                            }
                        >
                            {directors.map((director, index) => (
                                <Collapse.Panel
                                    key={director?.id}
                                    header={
                                        <div
                                            className="director-panel-heading"
                                            onClick={() =>
                                                activeKey == director?.id
                                                    ? setActiveKey('')
                                                    : setActiveKey(director?.id)
                                            }
                                        >
                                            {director?.firstName + ' ' + director?.lastName}:
                                            <div className="line" />
                                        </div>
                                    }
                                    forceRender
                                >
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Form.Item name={['directors', index, 'id']} hidden />
                                            <Form.Item
                                                label="First Name"
                                                name={['directors', index, 'firstName']}
                                                className="label"
                                                rules={[{ required: true, message: 'Please input your first name!' }]}
                                            >
                                                <Input className="gapstack-input" placeholder="John" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Last Name"
                                                name={['directors', index, 'lastName']}
                                                className="label"
                                                rules={[{ required: true, message: 'Please input your last name!' }]}
                                            >
                                                <Input className="gapstack-input" placeholder="Doe" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <SelectIdDocType
                                                formItemLabel="Select ID Doc Type"
                                                formItemName={['directors', index, 'docType']}
                                                required
                                                onChange={() => true}
                                                message="Please select document type"
                                                disabled
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={['directors', index, 'docNumber']}
                                                label="ID Doc Number"
                                                className="label"
                                                rules={[{ required: true, message: 'Please input your ID number!' }]}
                                            >
                                                <Input className="gapstack-input" placeholder="PAS1234" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <PhoneNumber
                                                countryFieldName={['directors', index, 'countryCode']}
                                                inputFieldName={['directors', index, 'phone']}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={['directors', index, 'email']}
                                                label="Email"
                                                className="label"
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'email',
                                                        message: 'Please input your email!',
                                                    },
                                                ]}
                                            >
                                                <Input className="gapstack-input" placeholder="john@gapstack.com" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Address"
                                                name={['directors', index, 'address']}
                                                className="label"
                                                rules={[{ required: true, message: 'Please input address!' }]}
                                            >
                                                <Input className="gapstack-input" placeholder="P.O Box 777" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Post Code"
                                                name={['directors', index, 'postalCode']}
                                                className="label"
                                                rules={[{ required: true, message: 'Please input postal code!' }]}
                                            >
                                                <Input className="gapstack-input" placeholder="012345" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <SelectCountry
                                                formItemLabel="Country"
                                                formItemName={['directors', index, 'country']}
                                                required
                                                onChange={() => true}
                                                message="Please select country"
                                                onCountrySelected={(country) => setSelectedCountry(country)}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <SelectCounty
                                                formItemLabel="County"
                                                formItemName={['directors', index, 'county']}
                                                required
                                                onChange={() => true}
                                                message="Please select county"
                                                countryId={countrySelected?.country_id}
                                                form={form}
                                                onClear={() => {
                                                    const temp_directors = form.getFieldValue('directors');
                                                    temp_directors[index]['county'] = null;
                                                    setDirectors(temp_directors);
                                                    form.setFieldsValue({ directors: temp_directors });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </Card>
                </Form>
            )}
        </ConfirmDetailsWrapper>
    );
};

export default ConfirmDirectors;
