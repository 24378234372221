import { css } from 'styled-components';
import { COLORS } from '../../../../../../config/theme';

const style = ({ showDropDown }) => css`
    .logout-container {
        .drop-arrow-up {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid ${(props: any) => props.theme[COLORS.POP_UP]};
            // box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
            position: absolute;
            top: -8px;
            right: 10px;
        }
        flex: 1;
        flex-direction: column;
        display: ${showDropDown ? 'flex' : 'none'};
        border-radius: 5px;
        position: absolute;
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        right: -11px;
        min-width: 200px;
        padding: 10px 0;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        z-index: 10;
        .switch {
            color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        }

        .role {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 11px;
            position: relative;
            line-height: 17px;
            overflow: hidden;
            width: 100%;
            color: ${(props: any) => props.theme[COLORS.SECONDARY]};
        }
        .text-concat {
            position: relative;
            display: inline-block;
            word-wrap: break-word;
            overflow: hidden;
            max-height: 17px;
            line-height: 17px;
            text-align: justify;
        }
        .ellipsis::after {
            content: '...';
            position: absolute;
            right: 8px;
            bottom: 4px;
        }

        .user {
            padding: 0px 20px;
            font-weight: 500;
            font-size: 15px;
            margin: 0;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }

        .profile {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            align-items: flex-start;
            flex: 1;
            display: flex;
            margin-bottom: 5px;
        }

        .sign-out {
            padding: 0px 20px;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            align-items: flex-start;
            flex: 1;
            display: flex;
        }
    }
`;

export { style };
