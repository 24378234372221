import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import { authenticatedRequest } from '../../../../../api';
import { create_new_business } from '../../../../../apis/authentication';
import { RootState } from '../../../../../store/reducers/rootReducers';
import SelectBusinessType from '../../../../../utils/FormSelects/BusinessTypes';
interface Props {
    showModal: boolean;
    hideModal: (val: boolean) => void;
    signInWithBusinessId: (businessId: string) => void;
    fetchBusinesses: () => void;
}

const CreateBusinessModal: FunctionComponent<Props> = ({
    showModal,
    hideModal,
    signInWithBusinessId,
    fetchBusinesses,
}: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const auth: any = useSelector((state: RootState) => state.auth);
    const userId = auth?.user?.userId;

    const onFinish = (values: any) => {
        setLoading(true);
        authenticatedRequest({ 'x-user-id': userId })
            .post(create_new_business, { ...values, businessTypeId: values?.businessTypeId?.toString() })
            .then((res) => res.data)
            .then((res) => {
                setLoading(false);
                hideModal(false);
                notification.success({
                    message: 'Success',
                    description: `${values?.businessName} has been created successfully`,
                });
                fetchBusinesses();
                signInWithBusinessId(res?.businessAccountId);
            })
            .catch((err: any) => {
                setLoading(false);
                const resp = err?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <Modal
            title={`Create Business`}
            visible={showModal}
            onCancel={() => hideModal(false)}
            footer={false}
            getContainer={false}
            closeIcon={<X className="close-modal" />}
            width="40%"
            className="pickup-wrapper"
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={20}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            label="Business Name"
                            name="businessName"
                            className="label"
                            rules={[{ required: true, message: 'Please enter business name!' }]}
                        >
                            <Input className="gapstack-input" placeholder="Gapstack Limited" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <SelectBusinessType
                            formItemLabel="Business Type"
                            formItemName={'businessTypeId'}
                            required
                            onChange={() => true}
                            message="Please select business type"
                        />
                    </Col>
                </Row>
                <div className="button-wrapper">
                    <Button className="cancel-button" onClick={() => hideModal(false)}>
                        Cancel
                    </Button>
                    <Button className="terms-and-conditions-button" htmlType="submit" loading={loading}>
                        Create Business
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default CreateBusinessModal;
