import moment from 'moment';
import { unAuthenticatedRequest } from '../../api';
import { getUser, Users } from '../../config';
import { getBusinessId } from '../../router/urls';

// inMemoryJwt.js
const InMemoryJWTManager = () => {
    let logoutEventName = 'ra-logout';
    let isRefreshing;
    let inMemoryJWT = null;
    let inMemoryTwoFactorJWT = null;
    let refreshTimeOutId;
    const user: Users = getUser();

    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    const waitForRefreshToken = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }

        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };
    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(getRefreshedToken, delay * 1000 - 5000); // Validity period of the token in seconds, minus 5 seconds
    };

    const abortRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    // The method makes a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will return a fresh jwt.
    const getRefreshedToken = (businessId = '') => {
        const prefix = getUser() == Users.BANK ? 'bank-authentication' : `authentication/${getBusinessId()}`;
        const url = process.env.NODE_ENV === 'development' ? `/${prefix}/refresh-token` : `/${prefix}/refresh-token`;
        isRefreshing = unAuthenticatedRequest()
            .get(url)
            .then((res) => {
                setToken(res.data.accessToken.token, parseInt(res.data.accessToken.expiresIn ?? 5) * 60);
                return true;
            })
            .catch(() => {
                sessionExpired(businessId);
                return false;
            });

        return isRefreshing;
    };

    const sessionExpired = (businessId: string) => {
        eraseToken();
        window.localStorage.setItem(
            'gs-session-expired-' + (user == Users.BANK ? '' : businessId),
            moment().format('DD-M-YYYY HH:mm'),
        );
        window.dispatchEvent(new Event('sessionExpired' + (user == Users.BANK ? '' : businessId)));
    };

    const getToken = () => inMemoryJWT;

    const setToken = (token, delay) => {
        inMemoryJWT = token;
        refreshToken(delay);
        clearStorage();
        return true;
    };

    const getTwoFactorToken = () => inMemoryTwoFactorJWT;

    const setTwoFactorToken = (token) => {
        inMemoryTwoFactorJWT = token;
        return true;
    };

    const eraseToken = () => {
        inMemoryJWT = null;
        abortRefreshToken();
        window.localStorage.setItem('ra-logout', moment().format('DD-M-YYYY HH:mm'));
        return true;
    };

    const setLogoutEventName = (name) => (logoutEventName = name);

    const checkForLogout = () => {
        if (window.localStorage.getItem(logoutEventName)) {
            return true;
        }
        return false;
    };

    const clearStorage = () => {
        window.localStorage.removeItem(logoutEventName);
    };

    return {
        eraseToken,
        setLogoutEventName,
        waitForRefreshToken,
        getRefreshedToken,
        getToken,
        setToken,
        getTwoFactorToken,
        setTwoFactorToken,
        sessionExpired,
        checkForLogout,
    };
};

export default InMemoryJWTManager();
