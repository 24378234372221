import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    .confirm-wrapper {
        min-height: 100vh;
        max-width: 750px;
        margin: auto;
    }
    .logo {
        margin: 60px auto 30px;
        //width: 232px;
        height: 40px;
    }

    .container {
        position: relative;
        background-color: ${(props: any) => props.theme[COLORS.POP_UP]};
        display: flex;
        flex-direction: column;
        flex: 1;
        box-shadow: ${(props: any) => props.theme[COLORS.SHADOW]};
        border-radius: 5px;
        padding: 40px 30px;
        justify-content: center;
        .overlay {
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .success-logo {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
        }

        .link-heading {
            font-size: 14px;
            line-height: 22px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            display: flex;
            justify-content: center;
            text-align: center;
            margin-bottom: 0;
        }
        .activate {
            font-size: 14px;
            line-height: 22px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            margin: 0 auto 1rem auto;
        }
    }
    .tick-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .success {
        width: 50px;
        height: 50px;
    }
    .heading {
        font-size: 24px;
        margin-top: 10px;
    }
    .sub-heading {
        font-size: 13px;
    }
    .resend {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: ${(props: any) => props.theme[COLORS.ANCHOR]};
    }
    .otpContainer {
        margin: 20px auto;
    }

    .otpInput {
        width: 80px;
        height: 60px;
        margin: 0 10px;
        text-align: center;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        background: ${(props: any) => props.theme[COLORS.FORM_COLOR]};
        border: 1px solid #c4c4c4;
        box-sizing: border-box;
        border-radius: 5px;
        &:focus {
            border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BORDER]};
            transition: ease-in-out 0.3s;
        }
    }
    .otpInput:focus {
        outline: none;
        border: 1px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        box-shadow: none;
    }
`;

export default style();
