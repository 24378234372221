import { Card } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import style from './styles';
import doodle from '../../../../assets/Images/verification.svg';
import { goToRoute, ROUTES } from '../../../../router/urls';
import { useSearchParam } from '../../../../router/routeHooks';
import { authenticatedRequest } from '../../../../api';
import { complete_existing_customer } from '../../../../apis/business-accounts';
import { getUser, Users } from '../../../../config';

const VerificationWrapper = styled.div`
    ${style}
`;

// const statuses = ['Confirming your company details', 'Checking your credit application', 'Setting your loan limits'];
const statuses = ['Confirming your company details'];
const VerificationPending = ({ activeData }: any) => {
    const activeStep = 0;
    const history = useHistory();
    const { next } = useSearchParam<{ next: string }>();
    const { businessId } = useParams<{ businessId: string }>();
    const isBank = getUser() == Users.BANK;

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (next) {
                history.push(goToRoute(next));
            } else {
                history.push(goToRoute(ROUTES.DASHBOARD));
            }
        }, 5000);
        return () => clearTimeout(timeOut);
    }, [next]);

    useEffect(() => {
        if (activeData?.existingCustomer?.mandateApproved && !activeData?.existingCustomer?.termsAndCondition) {
            const businessHeader = {
                'x-business-account-id': businessId,
            };
            authenticatedRequest(isBank ? businessHeader : {})
                .post(complete_existing_customer)
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    return (
        <VerificationWrapper>
            <Card title="Financing Verification & Approval" className="card-wrapper">
                <img src={doodle} className="doodle" alt={''} />
                <div className="info">You will receive an email/SMS once your application has been approved.</div>
                {/*<div className="info">This can take a few hours to a day</div>*/}
                <div className="statuses-wrapper">
                    {statuses.map((_, index) => (
                        <div className="status-wrapper" key={index}>
                            <div className="background-line" />
                            <div
                                className={`progress-line 
                                    ${index == activeStep ? 'progress active' : index < activeStep ? 'complete' : ''}`}
                            >
                                {index == activeStep ? <div className="active-line-animation" /> : null}
                            </div>
                        </div>
                    ))}
                </div>
            </Card>
        </VerificationWrapper>
    );
};

export default VerificationPending;
