import moment from 'moment';

const convertDate = (date: string) => {
    return moment(date).format('D/M/YYYY HH:mm A');
};

const columns_list = [
    {
        key: '1',
        title: 'Business Name',
        dataIndex: 'businessName',
    },
    {
        key: '3',
        title: 'Request Date',
        dataIndex: 'requestDate',
        render: (_, record) => convertDate(record.requestDate),
    },
    {
        key: '4',
        title: 'Request Type',
        dataIndex: 'requestType',
    },
    {
        key: '5',
        title: 'Your Request',
        dataIndex: 'yourRequest',
    },
    {
        key: '6',
        title: 'Current Stage',
        dataIndex: 'currentStage',
    },
    {
        key: '7',
        title: 'Expected Completion',
        dataIndex: 'expectedCompletion',
        render: (_, record) => convertDate(record.expectedCompletion),
    },
];

export { columns_list };
