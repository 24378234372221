import { Card, Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { authenticatedRequest } from '../../../../api';
import SelectBusinessType from '../../../../utils/FormSelects/BusinessTypes';
import SelectIndustry from '../../../../utils/FormSelects/Industries';
import Loader from '../../../../utils/Loader';
import { update_business_information } from '../../../../apis/business-accounts';
import { getErrorsList, mapConfirmData } from '../../helpers/mapData';
import style from './styles';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
const ConfirmDetailsWrapper = styled.div`
    ${style}
`;
interface BusinessInformationProps {
    data: any;
    errorData?: any;
}

const BusinessDetails: FunctionComponent<BusinessInformationProps> = ({
    data,
    errorData,
}: BusinessInformationProps) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const disabled = data?.status == BusinessStatuses?.OnHold;
    const onComplete = () => {
        const values = form.getFieldsValue();
        authenticatedRequest()
            .put(update_business_information, values)
            .then((res) => res.data)
            .catch((err) => {
                console.log(err);
                // const resp = err?.response;
                // notification.error({
                //     message: 'Error',
                //     description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                // });
            });
    };
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(false)) {
                onComplete();
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);

    useEffect(() => {
        data && form.setFieldsValue(mapConfirmData(data)?.['business']);
        errorData && form.setFields(getErrorsList(errorData?.business || {}));
        setLoading(false);
    }, [data]);
    return (
        <ConfirmDetailsWrapper>
            {loading ? (
                <Loader />
            ) : (
                <Form form={form} name="business">
                    <Card title="Business Details" className="card-wrapper">
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Business Name"
                                    name={'businessName'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input your business name!' }]}
                                >
                                    <Input
                                        className="gapstack-input"
                                        placeholder="Crowdstage Enterprises"
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Trading Name"
                                    name={'tradingName'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input trading name!' }]}
                                >
                                    <Input
                                        className="gapstack-input"
                                        placeholder="Crowdstage Enterprises LLC."
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <SelectBusinessType
                                    formItemLabel="Business Type"
                                    formItemName={'businessTypeId'}
                                    required
                                    onChange={() => true}
                                    message="Please select business type"
                                    disabled
                                />
                            </Col>
                            <Col span={12}>
                                <SelectIndustry
                                    formItemLabel="Industry(What does your business do?)"
                                    formItemName={'industryId'}
                                    required
                                    onChange={() => true}
                                    valueType="value"
                                    message="Please select industry type"
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="PIN Number"
                                    name={'pinNumber'}
                                    className="label"
                                    rules={[{ required: true, message: 'Please input Pin number!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="9049034" disabled={disabled} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="VAT Number"
                                    name={'vatNumber'}
                                    className="label"
                                    rules={[{ required: false, message: 'Please input VAT number!' }]}
                                >
                                    <Input className="gapstack-input" placeholder="9049034" disabled={disabled} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            )}
        </ConfirmDetailsWrapper>
    );
};

export default BusinessDetails;
