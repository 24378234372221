import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const pickUpStyles = () => css`
    .pickup-wrapper {
        .info {
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
        }
        .ant-modal-content {
            overflow-x: hidden;
        }

        .label {
            width: 100%;
            min-height: 45px;
            height: auto;
            border-radius: 5px;
            .ant-select-selector {
                min-height: 45px !important;
                background: ${(props: any) => props.theme[COLORS.FORM_COLOR]} !important;
                border-radius: 5px !important;
            }

            .ant-select-selection-item {
                display: flex;
                align-items: center;
                row-gap: 5px;
            }
            .ant-select-arrow {
                top: 50% !important;
            }
            .select-icon {
                color: ${(props) => props.theme[COLORS.ICONS]};
                width: 15px;
            }
            .ant-select-selection-search-input {
                height: 45px !important;
                font-size: 13px;
                line-height: 45px;
            }
            .ant-select-selection-overflow {
                align-items: center;
                row-gap: 5px;
            }
            .ant-select-selection-item {
                height: 40px;
            }
            .ant-row {
                width: 100%;
            }
        }
        .divider {
            width: 120%;
            margin: 0px 0px 20px -40px;
        }
        .button-wrapper {
            display: flex;
            align-items: center;
            margin-top: 15px;
            .cancel-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 10px 20px;
                background: ${(props: any) => props.theme[COLORS.POP_UP]};
                border: none;
                border-radius: 5px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
                border: 1px solid ${(props: any) => props.theme[COLORS.DECLINED]};
                margin-left: auto;
            }
            .terms-and-conditions-button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                padding: 10px 20px;
                background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                border: none;
                border-radius: 5px;
                font-weight: 400;
                color: ${(props: any) => props.theme[COLORS.POP_UP]};
                margin-left: 10px;
            }
        }
    }
`;

export default pickUpStyles;
