import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, notification } from 'antd';
import { useSelector } from 'react-redux';

import { authenticatedRequest } from '../../api';
import { get_authentication_code } from './apis';
import { useSearchParam } from '../../router/routeHooks';

function popUpWindow(url, windowName, win: Window, w, h) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
        url,
        windowName,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`,
    );
}

interface IProps {
    checkAutStatus: () => Promise<any>;
}

const MessageLoading: FC<IProps> = ({ checkAutStatus }: IProps) => {
    const { businessId }: any = useParams();
    const authUser = useSelector((state: any) => state.auth?.user || {});
    const [loading, setLoading] = useState(false);
    const winRefInterval = useRef<any>();

    const { email } = useSearchParam();

    useEffect(() => {
        if (!email) {
            notification.info({
                message: 'Info',
                description: 'Please add email query para in url',
            });
        }
    }, []);

    useEffect(() => {
        async function authListener(event: any) {
            if (event.detail?.success) {
                await checkAutStatus();
            }
        }

        window?.addEventListener(`mail-auth-${authUser.userId}`, authListener);

        return () => {
            window?.removeEventListener(`mail-auth-${authUser.userId}`, authListener);
        };
    }, [checkAutStatus]);

    async function checkAuthentication() {
        setLoading(true);
        try {
            const header = {
                'x-business-account-id': businessId,
                'x-user-id': authUser.userId,
            };
            const data = await authenticatedRequest(header)
                .post(get_authentication_code, {
                    // email: authUser.email,
                    email: email, // TODO
                })
                .then((res) => res.data.url);
            const popup = popUpWindow(data, 'email-authentication', window, 700, 750);
            if (popup) {
                winRefInterval.current = setInterval(function () {
                    if (popup?.closed) {
                        clearInterval(winRefInterval.current);
                        setLoading(false);
                    }
                }, 500);
            }
        } catch (error) {
            const res = error?.response;
            notification.error({
                message: 'Error',
                description: res?.data?.message || 'Unexpected Error Occurred',
            });
            setLoading(false);
        }
    }

    return (
        <div className={'auth-cont'}>
            <Button className={'gapstack-button'} loading={loading} onClick={checkAuthentication}>
                Login to email service
            </Button>
        </div>
    );
};

export default MessageLoading;
