import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';
import { hex2rgba } from '../../../utils/Random';

const style = () => css`
    min-height: 100vh;
    .business-container {
        margin: 20px auto 100px;
        .ant-card-body {
            padding-bottom: 5px;
            padding-top: 15px;
        }
        h3 {
            font-weight: bold;
            font-size: 24px;
            line-height: 30px;
            color: #39426a;
        }
        .sub-heading {
            font-size: 14px;
            line-height: 22px;
            color: #39426a;
            margin-top: 25px;
            text-align: left;
            justify-content: left;
        }

        .accordion-expand {
            color: ${(props: any) => props.theme[COLORS.ICONS]} !important;
            width: 15px !important;
        }

        .button-container {
            display: flex;
            justify-content: flex-end;
            margin-top: 15px;
        }
        .complete-later {
            color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            margin-left: auto;
            width: 100px;
            margin-bottom: 10px;
            font-size: 14px;
            cursor: pointer;
        }
        .existing-account {
            margin-left: 0;
            margin-bottom: -10px;
            font-size: 14px;
            span {
                cursor: pointer;
                color: ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            }
        }

        .complete-progress-container {
            margin-top: 30px;
            .item {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                width: fit-content;
                color: ${(props: any) => hex2rgba(props.theme[COLORS.SECONDARY], 0.5)};
                &.active {
                    color: ${(props: any) => props.theme[COLORS.PRIMARY]};
                    font-weight: bold;
                }
                &:hover {
                    cursor: pointer;
                }
                &.inactive {
                    font-weight: 500;
                    color: ${(props: any) => props.theme[COLORS.ICONS]};
                }
            }
            .complete {
                color: ${(props: any) => props.theme[COLORS.SUCCESS]};
            }
            .incomplete {
                color: ${(props: any) => props.theme[COLORS.DECLINED]};
            }

            .progress-text {
                margin-left: 5px;
                font-weight: normal;
                font-size: 14px;
                line-height: 135.2%;
                color: #39426a;
            }
        }
    }

    .info {
        font-weight: 600;
        font-size: 13px;
        color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
    }
`;

export default style;
