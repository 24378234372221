import React, { useEffect, useState } from 'react';
import { Button, Divider, notification } from 'antd';
import styled from 'styled-components';
import { style } from './styles';
import InMemoryJWTManager from '../../../../../utils/InMemoryJWT';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { actions } from '../../../../authentication/sign-in/store/actions';
import { goToRoute, ROUTES } from '../../../../../router/urls';
import { authenticatedRequest } from '../../../../../api';
import { bank_log_out, log_out } from '../../../../authentication/sign-in/api';
import { BankWrapper, getUser } from '../../../../../config';
import { RootState } from '../../../../../store/reducers/rootReducers';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_groups } from '../../../../../apis/user-groups';
import { actions } from '../../../../authentication/sign-in/store/actions';
import { LoadingOutlined } from '@ant-design/icons';
import { titleCase } from '../../../../../utils/TitleCase';
import SwitchGroupModal from './swithGroup';

const LogoutWrapper = styled.div`
    ${style}
`;

interface Props {
    showDropDown: boolean;
    user?: any;
}

const LogoutComponent = ({ showDropDown, user }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showSwitchGroup, setShowSwitchGroup] = useState<boolean>(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const isBank = getUser() == 'BANK';
    const auth: any = useSelector((state: RootState) => state.auth);
    const userId = auth?.user?.userId;
    const userGroups = auth?.user?.userGroups;
    const activeUserGroup = auth?.user?.activeUserGroup;

    const catchErr = (err: any) => {
        const resp = err?.response;
        notification.error({
            message: 'Error',
            description: resp?.data?.message || 'Oops! An unexpected error occurred.',
        });
    };

    const logout = () => {
        const url = isBank ? bank_log_out : log_out;
        authenticatedRequest(isBank ? { 'x-bank-user-id': userId } : {})
            .get(url)
            .then(() => {
                history.push(ROUTES.SIGN_IN);
                InMemoryJWTManager.eraseToken();
            })
            .catch((error) => catchErr(error));
        // dispatch(actions.userLogout());
    };

    const getUserGroups = () => {
        setLoading(true);
        authenticatedRequest({ 'x-user-id': userId })
            .get(get_user_groups)
            .then((res) => res.data)
            .then((res) => {
                dispatch(actions.onFetchUserGroups(res));
                const activeGroup = (Array.isArray(res) && res.length > 0 && res[0]) ?? null;
                dispatch(actions.onChangeActiveUserGroup(activeGroup));
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                catchErr(err);
            });
    };

    useEffect(() => {
        if (!userGroups && isBank) {
            getUserGroups();
        }
    }, []);

    const goToProfile = () => {
        history.push(isBank ? ROUTES.BANK_PROFILE : goToRoute(ROUTES.PROFILE));
    };

    return (
        <LogoutWrapper showDropDown={showDropDown}>
            <div className="logout-container">
                <div className="drop-arrow-up" />
                <p className="user">{user?.firstName + ' ' + user?.lastName}</p>
                <div className={`role ${user?.roles.length > 2 ? 'ellipsis' : ''}`}>
                    <span className="text-concat">{Array.isArray(user?.roles) ? user?.roles?.join(' | ') : ''}</span>
                </div>
                <Divider style={{ margin: '5px 0px 5px' }} />
                <BankWrapper>
                    <Button type="link" className="profile">
                        {loading ? (
                            <LoadingOutlined className="loader" spin />
                        ) : (
                            <span>
                                {titleCase(activeUserGroup?.branchName) ?? 'No Group'} |{' '}
                                <span
                                    className="switch"
                                    onClick={() => activeUserGroup?.branchName && setShowSwitchGroup(true)}
                                >
                                    Switch
                                </span>
                            </span>
                        )}
                    </Button>
                </BankWrapper>
                <Button type="link" className="profile" onClick={goToProfile}>
                    Profile
                </Button>
                <Button type="link" className="sign-out" onClick={logout}>
                    Sign Out
                </Button>
            </div>
            {showSwitchGroup && <SwitchGroupModal showModal={showSwitchGroup} hideModal={setShowSwitchGroup} />}
        </LogoutWrapper>
    );
};

export default LogoutComponent;
