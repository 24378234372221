import React from 'react';
import { Status } from './table-status';
import { third_party_api_keys, general_api_keys, restricted_api_keys } from './table-data';

const action = () => {
    // console.log('abcd');
};

const columns: any = [
    {
        key: '7',
        title: 'Status',
        dataIndex: 'status',
        // eslint-disable-next-line react/display-name
        render: (_, record) => {
            return (
                <div style={{ display: 'flex' }}>
                    <span
                        className="circle-rh"
                        style={{
                            backgroundColor:
                                record.status === 'Pending'
                                    ? '#FE9902'
                                    : record.status === 'Active'
                                    ? '#2CCC71'
                                    : record.status === 'InActive'
                                    ? '#E74C3C'
                                    : '#A3A3B4',
                        }}
                    ></span>
                    <span
                        style={{
                            color:
                                record.status === 'Pending'
                                    ? '#FE9902'
                                    : record.status === 'Active'
                                    ? '#2CCC71'
                                    : record.status === 'InActive'
                                    ? '#E74C3C'
                                    : '#A3A3B4',
                        }}
                    >
                        {record.status}
                    </span>
                </div>
            );
        },
    },
];
const columnsData = (name: any) => {
    // console.log(name);
    switch (name) {
        case 'General API Keys':
            return [
                ...general_api_keys,
                ...columns,
                {
                    key: '8',
                    title: 'Action',
                    dataIndex: 'action',
                    // eslint-disable-next-line react/display-name
                    render: () => {
                        return (
                            <>
                                <Status name={name} onClick={action} />
                            </>
                        );
                    },
                },
            ];
        case 'Restricted API Keys':
            return [
                ...restricted_api_keys,
                ...columns,
                {
                    key: '8',
                    title: 'Action',
                    dataIndex: 'action',
                    // eslint-disable-next-line react/display-name
                    render: () => {
                        return (
                            <>
                                <Status name={name} onClick={action} />
                            </>
                        );
                    },
                },
            ];
        case '3rd Party API Keys':
            return [
                ...third_party_api_keys,
                ...columns,
                {
                    key: '8',
                    title: 'Action',
                    dataIndex: 'action',
                    // eslint-disable-next-line react/display-name
                    render: () => {
                        return (
                            <>
                                <Status name={name} onClick={action} />
                            </>
                        );
                    },
                },
            ];
    }
};

export { columnsData };
