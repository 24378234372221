import React, { Key, PropsWithChildren, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { BreadcrumbItemProps } from 'antd/lib/breadcrumb/BreadcrumbItem';
import { getRoutePath, RouteKey } from '../Helper';

const Item = Breadcrumb.Item as any;

interface Path {
    name: RouteKey;
    params: Record<string, string>;
}

interface PageBreadcrumbItem extends BreadcrumbItemProps {
    key?: Key;
    title?: string;
    path?: Path | RouteKey;
}

const PageBreadcrumbItem = (props: PropsWithChildren<PageBreadcrumbItem>) => {
    const { children, title, path, ...rest } = props;
    const history = useHistory();

    const link = useMemo(() => {
        if (path) {
            if (typeof path === 'object') {
                return getRoutePath(path.name, path.params);
            }
            return getRoutePath(path);
        }
    }, [path]);

    const onClick = useCallback(() => {
        if (link) {
            history.push(link);
        }
    }, [link]);

    return (
        <Item path={link} onClick={onClick} {...rest}>
            {children || title}
        </Item>
    );
};

PageBreadcrumbItem.__ANT_BREADCRUMB_ITEM = true;

export default PageBreadcrumbItem;
