export const prefix = process.env.NODE_ENV === 'development' ? 'bank-products' : 'bank-products';

export const attach_product = `${prefix}/bank-product/attach-product/for-business`;

export const approve_product = (id) => `${prefix}/bank-product/approve-product/${id}`;
export const activate_product = (id) => `${prefix}/bank-product/activate/${id}`;
export const deactivate_product = (id) => `${prefix}/bank-product/de-activate/${id}`;

export const fetch_bank_product = (id) => `${prefix}/bank-product/${id}`;
export const create_bank_product = () => `${prefix}/bank-product`;
export const fetch_all_bank_products = () => `${prefix}/bank-product`;

export const search_bank_products = (fundingType: string, serviceType: string | null, name: string) => {
    let url = `${prefix}/bank-product/search/for-business?fundingType=${fundingType}`;
    if (serviceType) {
        url += `&serviceType=${serviceType}`;
    }
    if (name.trim() != '') {
        url += `&name=${name}`;
    }
    return url;
};
export const get_attached_products = (accountId, fundingType, serviceType) => {
    if (!serviceType) {
        return `${prefix}/bank-product/attach-product/for-business/${accountId}?fundingType=${fundingType}`;
    }
    return `${prefix}/bank-product/attach-product/for-business/${accountId}?fundingType=${fundingType}&serviceType=${serviceType}`;
};
export const de_attach_product = (accountId, productId) =>
    `${prefix}/bank-product/de-attach-product/for-business/${accountId}/${productId}`;

export const charging_data_prefix = `${prefix}/product-data`;

//collection accounts
export const fetch_collection_account = `${prefix}/bank-accounts/collection-accounts`;
export const fetch_disbursement_account = `${prefix}/bank-accounts/capital-account-or-disbursement-acccount`;
export const bank_accounts = `${prefix}/bank-accounts/`;

//collection strategy
export const collection_strategy_prefix = `${prefix}/collection-strategy`;
export const edit_collection_strategy = (id) => `${prefix}/collection-strategy/${id}/strategyAction/`;
export const fetch_collection_strategies = `${prefix}/collection-strategy`;
export const fetch_collection_strategy = (id) => `${prefix}/collection-strategy/${id}`;
export const approve_collection_strategy = (id) => `${prefix}/collection-strategy/${id}/approve`;
export const search_collection_strategy_prefix = `${prefix}/collection-strategy/cs/search`;
export const get_at_values = `${prefix}/collection-strategy/data/template-token`;

//express settings
export const add_express_limit = (productId: string) => `${prefix}/bank-product/express-product/${productId}`;
export const delete_express_limit = (productId: string) => `${prefix}/bank-product/express-product/${productId}`;
export const update_express_limit = (productId: string) =>
    `${prefix}/bank-product/express-product/update-limit/${productId}`;
