import { css } from 'styled-components';
import { COLORS } from '../../../../config/theme';

const style = () => css`
    width: 100%;
    .progress-bar-names {
        display: flex;
        flex-direction: row;
        align-items: center;
        .step-name {
            font-size: 13px;
            color: ${(props: any) => props.theme[COLORS.PRIMARY_TEXT]};
            width: 50%;
            min-width: 100px;
            font-weight: 400;
            cursor: pointer;
            text-align: center;
        }
        .Active {
            color: ${(props: any) => props.theme[COLORS.PRIMARY]};
            font-weight: 600;
        }
        .Completed {
            color: ${(props: any) => props.theme[COLORS.SUCCESS]};
            font-weight: 600;
        }
    }
    .progress-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin: 20px 0px;
        .step {
            display: flex;
            flex-direction: row;
            align-items: center;
            .line {
                height: 3px;
                width: 100%;
                background: ${(props: any) => props.theme[COLORS.BORDER]};
                transition: background 0.6s ease-in-out;
                &.active {
                    background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                }
            }
            .dot {
                width: 8px;
                height: 8px;
                min-width: 8px;
                min-height: 8px;
                max-width: 8px;
                max-height: 8px;
                border-radius: 5px;
                border: 1px solid ${(props: any) => props.theme[COLORS.BORDER]};
                background: ${(props: any) => props.theme[COLORS.POP_UP]};
                transition: background, border 0.6s ease-in-out;
                &.Active {
                    width: 12px;
                    height: 12px;
                    min-width: 12px;
                    min-height: 12px;
                    max-width: 12px;
                    max-height: 12px;
                    border-radius: 6px;
                    border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    transition: background, border 0.6s ease-in-out;
                    .circle {
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        transition: all 0.6s ease-in-out;
                        background: ${(props: any) => props.theme[COLORS.PRIMARY]};
                    }
                }
                &.Completed {
                    width: 12px;
                    height: 12px;
                    min-width: 12px;
                    min-height: 12px;
                    max-width: 12px;
                    max-height: 12px;
                    border-radius: 6px;
                    border: 1px solid ${(props: any) => props.theme[COLORS.PRIMARY]};
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    transition: background, border 0.6s ease-in-out;
                    .circle {
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        transition: all 0.6s ease-in-out;
                        background: ${(props: any) => props.theme[COLORS.SUCCESS]};
                    }
                }
            }
            &.zeroth {
                width: 17%;
            }
            &.first {
                width: 33%;
            }
            &.second {
                width: 35%;
            }
            &.fourth {
                width: 17%;
            }
        }
    }
`;

export default style();
