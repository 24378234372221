import React from 'react';
import { PlusCircle } from 'react-feather';
interface AddNewDocumentProps {
    text?: string;
    onClick?: (e: any) => void;
}

export const AddNewDocumentButton: React.FC<AddNewDocumentProps> = ({
    text = 'Add custom document',
    onClick,
}: AddNewDocumentProps) => {
    return (
        <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
            onClick={(e) => onClick?.(e)}
        >
            <PlusCircle style={{ width: '16px', color: '#1E87F0' }} />
            <span style={{ color: '#1E87F0', fontSize: '13px', marginLeft: '10px' }}>{text}</span>
        </div>
    );
};
