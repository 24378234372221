import { css } from 'styled-components';
import { COLORS } from '../../../../../config/theme';

const style = () => css`
    background: transparent;
    min-height: 40vh;
    width: 50%;
    min-width: 525px;
    color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
    font-size: 13px;

    .view-header {
        padding: 30px 20px 10px;

        & > div {
            display: grid;
            grid-template-columns: 40px 1fr auto;
            grid-gap: 10px;
        }

        .message-details {
            span {
                display: block;
            }
        }
        .light-text {
            line-height: 20px;
            color: ${(props) => props.theme[COLORS.SECONDARY]};
        }

        .avatar span {
            font-size: 11px;
        }
    }

    .view-body {
        padding: 20px 20px 22px 70px;
        height: calc(100% - 80px);
        overflow-y: auto;
        overflow-x: hidden;

        .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
        }

        .download-attachment {
            margin-top: 20px;
            position: relative;
            display: flex;
            align-items: center;
            background: ${(props: any) => props.theme[COLORS.DOCUMENT_UPLOADER]};
            border: 1px dashed ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
            box-sizing: border-box;
            border-radius: 5px;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: ${(props: any) => props.theme[COLORS.PROFILE_ICON]};
            width: 365px;
            max-width: 100%;
            min-height: 46px;

            &:hover {
                cursor: pointer;
            }

            .cloud {
                padding: 12px;
                background: ${(props: any) => props.theme[COLORS.CLOUD_BACKGROUND_UPLOADER]};
                height: 44px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                margin-left: auto;
            }

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                padding: 12px;
                &.filename {
                    flex: 1;
                }
            }
        }

        .actions,
        .actions > button {
            display: flex;
            align-items: center;
        }

        .actions {
            grid-gap: 35px;
            margin-top: 30px;

            & > button {
                grid-gap: 5px;
                color: ${(props) => props.theme[COLORS.SECONDARY]};
                cursor: pointer;

                :hover,
                :active,
                :focus {
                    background: transparent;
                }

                span {
                    color: ${(props) => props.theme[COLORS.PRIMARY_TEXT]};
                }
            }
        }

        .ant-divider-horizontal {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .reply-mail {
            .reply-input {
                height: 60px;
                border-radius: 3px;
                border: 1px solid ${(props) => props.theme[COLORS.BORDER]};
                font-size: 13px;
                padding: 20px;
                margin-bottom: 20px;
            }

            .reply-actions {
                display: grid;
                grid-template-columns: auto auto;
                justify-content: end;
                align-items: center;
                grid-gap: 30px;
                cursor: pointer;

                .attachment {
                    display: grid;
                    grid-template-columns: auto auto;
                    justify-content: start;
                    align-items: center;
                    grid-gap: 10px;
                    cursor: pointer;
                }
            }
        }
        .attach {
            position: relative;
            top: -2px;
            left: 5px;
        }
    }
`;

export default style();
