import { css } from 'styled-components';
import { COLORS } from '../../../config/theme';

const style = () => css`
    position: relative;
    margin-top: 10px;
    background: #ffffff;
    box-shadow: ${(props) => props.theme[COLORS.SHADOW]};
    border-radius: 5px;
    height: 84vh;
    /* max-height: 756px; */
    display: flex;

    .auth-cont {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .ant-empty-description {
            color: ${(props) => props.theme[COLORS.SECONDARY]};
            font-size: 13px;
        }
    }

    & > div {
        height: 100%;
    }

    .primary-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 10px 20px;
        background: ${(props) => props.theme[COLORS.PRIMARY]};
        border: none;
        border-radius: 5px;
    }

    .icon-btn-text {
        margin-left: 10px;
    }
`;

export default style();
