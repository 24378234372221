import { Card, Col, Row } from 'antd';
import React from 'react';
import { FunctionComponent } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { BusinessStatuses } from '../../../../bank-components/business/table/data';
import FormUploads from '../../../../utils/FormUploads';
import style from './styles';
import { DocumentPageType, DocumentType, DocumentNameAndType } from './types';
const BusinessDocumentsWrapper = styled.div`
    ${style}
`;
interface AuditedAccountsProps {
    uploadFilesToBusinessAccount: (
        documentId: string,
        url: string,
        documentName: DocumentNameAndType | string,
        pageType: DocumentPageType,
        fileId: string,
        documentLabel?: string,
    ) => void;
    documents: any;
    docsArray: any[];
    errorObj: any;
    setUploadingObj: (val: any) => void;
    handleErrorFromDocsManager: (
        err: string,
        documentName: DocumentNameAndType | string,
        pageType?: DocumentPageType,
    ) => void;
    documentErrObj: any;
    activeData?: any;
}

const AuditedAccounts: FunctionComponent<AuditedAccountsProps> = ({
    uploadFilesToBusinessAccount,
    documents,
    docsArray,
    errorObj,
    setUploadingObj,
    handleErrorFromDocsManager,
    documentErrObj,
    activeData,
}: AuditedAccountsProps) => {
    const audArray = docsArray.filter((val) => val.documentType == DocumentNameAndType.AUDITED_ACCOUNT);
    if (audArray.length == 0) return null;
    const auditedAccountSettings = audArray[0];
    const getErr = (documentName) => {
        return (
            errorObj[documentName]?.error ||
            documentErrObj[documentName]?.error ||
            documents[documentName]?.reasonForReturn
        );
    };

    const docsObj: any = {};
    docsArray?.map((val) => {
        docsObj[val?.documentType] = val;
    });
    if (!auditedAccountSettings?.years || auditedAccountSettings?.years?.length == 0) return null;
    return (
        <BusinessDocumentsWrapper>
            <Card title="Audited Accounts" className="card-wrapper  card-margin-top">
                <div className="card-description-wrapper">
                    <div className="card-description">
                        Upload the audited account{auditedAccountSettings?.years?.length > 1 ? 's' : ''} for the last{' '}
                        {auditedAccountSettings?.years?.length || 0} year
                        {auditedAccountSettings?.years?.length > 1 ? 's' : ''}.
                    </div>
                    <AiOutlineInfoCircle size={16} className="info-icon" />
                </div>
                {auditedAccountSettings?.years?.map((val, index) => (
                    <Row gutter={20} key={index}>
                        <Col span={24}>
                            <FormUploads
                                label={`Year ${val} ${docsObj[val]?.isRequired ? '*' : ''}`}
                                formItemName={`year${val}`}
                                documentConfig={{
                                    documentName: `${DocumentNameAndType.AUDITED_ACCOUNT}`,
                                    documentType: DocumentType.KYC,
                                    pageType: DocumentPageType.STANDARD,
                                }}
                                getResponse={(response) => {
                                    if (response?.documentId) {
                                        uploadFilesToBusinessAccount(
                                            response?.documentId,
                                            response?.downloadURl,
                                            DocumentNameAndType.AUDITED_ACCOUNT,
                                            DocumentPageType.STANDARD,
                                            response?.fileId,
                                            `${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`,
                                        );
                                    } else {
                                        handleErrorFromDocsManager(
                                            response,
                                            `${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`,
                                            DocumentPageType.STANDARD,
                                        );
                                    }
                                }}
                                getFileStatus={(status) => {
                                    setUploadingObj((previousObj: any) => {
                                        if (status) {
                                            previousObj[`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`] = true;
                                        } else {
                                            delete previousObj[`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`];
                                        }
                                        return { ...previousObj };
                                    });
                                }}
                                initialValue={documents[`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`]?.['url']}
                                err={getErr(`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`)}
                                validateStatus={
                                    getErr(`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`) ? 'error' : 'success'
                                }
                                required={docsObj[val]?.isRequired ?? false}
                                disabled={
                                    !getErr(`${DocumentNameAndType.AUDITED_ACCOUNT}_${val}`) &&
                                    activeData?.status == BusinessStatuses.OnHold
                                }
                            />
                        </Col>
                    </Row>
                ))}
            </Card>
        </BusinessDocumentsWrapper>
    );
};

export default AuditedAccounts;
