import { css } from 'styled-components';
import { COLORS } from '../../../../../../../config/theme';

const style = () => css`
    width: 325px;
    margin-top: 25px;
    position: relative;
    margin-right: -20px;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: -14px;
        right: 20px;
        width: 23px;
        height: 4px;
        border-top: 2px solid ${(props: any) => props.theme[COLORS.SECOND_BUTTON]};
        border-radius: 2px;
    }

    &:after {
        content: ' ';
        display: block;
        position: absolute;
        top: -20px;
        right: 20px;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
    }
`;

export default style();
