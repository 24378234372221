import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import style from './styles';
import BackIcon from '../../../assets/Images/arrow.svg';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const UnauthenticatedWrapper = styled.div`
    ${style}
`;

interface IProps {
    children: ReactNode;
}

interface LocationState {
    from: {
        pathname: string;
    };
}

const UnauthenticatedLayout = ({ children }: IProps) => {
    const location = useLocation<LocationState>();
    // useEffect(() => {
    //     console.log(location);
    // }, []);
    return (
        <UnauthenticatedWrapper>
            {location.pathname === '/confirm-account' ||
            location.pathname === '/forgot-password' ||
            location.pathname === '/verify-doc' ? (
                <Row>
                    <Col md={{ span: 20, offset: 2 }}>
                        <p style={{ position: 'absolute' }}>
                            <Link to="/">
                                <img
                                    src={BackIcon}
                                    alt="Back"
                                    style={{ position: 'relative', top: -2, marginRight: '5px' }}
                                />
                                Back
                            </Link>
                        </p>
                    </Col>
                </Row>
            ) : null}
            {children}
        </UnauthenticatedWrapper>
    );
};

export default UnauthenticatedLayout;
