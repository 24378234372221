import React, { useEffect, useState } from 'react';
import useMediaRecorder from '@wmik/use-media-recorder';
import gifshot from 'gifshot';

import face from '../../../../../assets/icons/Svg/face.svg';
import Loader from '../../../../../utils/Loader';
import { document_manager_url } from '../../../../../apis/uploads';

function PlayerComponent({ srcBlob }: any) {
    if (!srcBlob) {
        return null;
    }

    return <video src={URL.createObjectURL(srcBlob)} width="100%" height={400} controls />;
}
export const Player = React.memo(PlayerComponent);

export function LiveStreamPreview({ stream }: any) {
    const videoPreviewRef: any = React.useRef();

    React.useEffect(() => {
        if (videoPreviewRef?.current && stream) {
            (videoPreviewRef?.current as any).srcObject = stream;
        }
    }, [stream]);

    if (!stream) {
        return null;
    }

    return <video ref={videoPreviewRef} width="100%" height={400} autoPlay />;
}

export function captureUserMedia(callback) {
    const params = { audio: false, video: true };

    (navigator as any).getUserMedia?.(params, callback, (error) => {
        alert(JSON.stringify(error));
    });
}

export const Timer = ({ initialSeconds, onComplete }: any) => {
    const [seconds, setSeconds] = useState(initialSeconds ?? 0);
    useEffect(() => {
        const myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                clearInterval(myInterval);
                onComplete?.();
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return <span>{seconds} seconds</span>;
};

export const VideoElement = ({
    imageBlob,
    setImageBlob,
    openCamera,
    livenessResponse,
    setLivenessResponse,
    setStartTimer,
    url,
}: any) => {
    const { error, mediaBlob, liveStream, stopRecording, startRecording, clearMediaStream, clearMediaBlob } =
        useMediaRecorder({
            recordScreen: false,
            blobOptions: { type: 'video/webm' },
            mediaStreamConstraints: { audio: false, video: true },
        });
    const [converting, setConverting] = useState<boolean>(false);

    const handleMedia = async () => {
        if (openCamera == 'start') {
            startRecording();
        } else if (openCamera == 'timer' || openCamera == 'retry-initial') {
            stopRecording();
            clearMediaBlob();
            clearMediaStream();
            setImageBlob(undefined);
            setLivenessResponse({});
            if (openCamera == 'timer') {
                setStartTimer(true);
                startRecording();
            }
        } else if (openCamera == 'retry') {
            stopRecording();
            setConverting(true);
        }
    };

    useEffect(() => {
        if (converting) {
            convertoGIF(mediaBlob);
        }
    }, [mediaBlob]);

    const convertoGIF = async (mediaB) => {
        if (mediaB) {
            gifshot.createGIF(
                {
                    video: URL.createObjectURL(mediaB),
                    numFrames: 60,
                    interval: 0.1,
                },
                function (obj) {
                    if (!obj.error) {
                        fetch(obj.image)
                            .then((res) => res.blob())
                            .then((blob) => {
                                setImageBlob(URL.createObjectURL(blob));
                                setConverting(false);
                            })
                            .catch((err) => {
                                console.log(err);
                                setConverting(false);
                            });
                    } else {
                        setConverting(false);
                    }
                },
            );
        } else {
            setConverting(false);
        }
    };

    useEffect(() => {
        handleMedia();
    }, [openCamera]);

    useEffect(() => {
        clearMediaStream();
        clearMediaBlob();
    }, []);

    return (
        <div className="camera-wrapper">
            {openCamera == 'initial' || openCamera == 'retry-initial' ? (
                <div className="dummy-wrapper">
                    <img src={face} className="face-image" />
                </div>
            ) : openCamera == 'retry' ? (
                <>
                    {url ? (
                        <img src={document_manager_url(url)} alt="preview" />
                    ) : converting || !imageBlob ? (
                        <>
                            <Loader size={30} height="200px" />
                            <div className="primary-message">Processing video ...</div>
                        </>
                    ) : (
                        <Player srcBlob={mediaBlob} />
                    )}
                </>
            ) : (
                <LiveStreamPreview stream={liveStream} />
            )}
            <div className="err-message">{error ? `${error.message}` : ''}</div>
            {!livenessResponse?.facelivedetect && livenessResponse?.headposemsgs && (
                <div className="err-message">{`We were not able to verify your face. Please ensure you move your head from left to right, tilt your head to the left, and then to the right and the video is not blurry`}</div>
            )}
            {livenessResponse?.facelivedetect && livenessResponse?.headposemsgs && (
                <div className="success-message">Verified</div>
            )}
        </div>
    );
};

export default Timer;
