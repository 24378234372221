import { Dropdown, notification } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ChevronDown, ChevronRight, Search } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { getUser, Users } from '../../../../../config';
import { RootState } from '../../../../../store/reducers/rootReducers';
import BankDropDown from '../bank-dropdown';
import BusinessDropdown from '../business-dropdown';
import mobileMenu from '../../../../../assets/icons/Svg/mobile-menu.svg';
import style, { sideDropDown } from './styles/mobile';
import SideBar from '../sidebar';
import UserAvatar from '../../../../../utils/Avatar';
import { goToRoute, ROUTES } from '../../../../../router/urls';
import { authenticatedRequest } from '../../../../../api';
import { bank_log_out, log_out } from '../../../../authentication/sign-in/api';
import InMemoryJWTManager from '../../../../../utils/InMemoryJWT';

const MobileBarWrapper = styled.div`
    ${style}
`;
const SideBarWrapper = styled.div`
    ${sideDropDown}
`;
interface Props {
    businessName: string;
}

const MobileBar: FunctionComponent<Props> = ({ businessName }: Props) => {
    const [showDropdown, setShowDropDown] = useState(false);
    const [sideMenu, setSideMenu] = useState(false);
    const user = getUser();
    const isBank = user == Users.BANK;
    const { businessId }: any = useParams();
    const auth: any = useSelector((state: RootState) => state.auth);
    const email = auth?.user?.email;
    const history = useHistory();
    const notificationSeen = useSelector((state: RootState) => state?.authenticatedLayoutReducer?.notificationSeen);

    const showProfileDropDown = () => {
        setShowDropDown(true);
    };

    const closeDropdown = () => {
        setShowDropDown(false);
    };

    useEffect(() => {
        if (sideMenu) document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [sideMenu]);

    const logout = () => {
        const url = isBank ? bank_log_out : log_out;
        authenticatedRequest()
            .get(url)
            .then(() => {
                history.push(ROUTES.SIGN_IN);
                InMemoryJWTManager.eraseToken();
            })
            .catch((error) => {
                const resp = error?.response;
                notification.error({
                    message: 'Error',
                    description: resp?.data?.message || 'Oops! An unexpected error occurred.',
                });
            });
    };

    return (
        <MobileBarWrapper>
            <Dropdown
                overlay={
                    isBank ? (
                        <BankDropDown showDropDown={showDropdown} closeDropDown={setShowDropDown} />
                    ) : (
                        <BusinessDropdown
                            showDropDown={showDropdown}
                            closeDropdown={closeDropdown}
                            businessId={businessId}
                            email={email}
                        />
                    )
                }
                placement="bottomLeft"
                visible={showDropdown}
                trigger={['click']}
                onVisibleChange={(visible) => setShowDropDown(visible)}
            >
                <div className="account-button" onClick={showProfileDropDown}>
                    <div className="account-button-content-container">
                        <div className="profile-icon" />
                        <span className="business-name">{businessName}</span>
                        <ChevronDown color="#6C7392" size="20" />
                    </div>
                </div>
            </Dropdown>
            <Search className="search-icon" />
            <Dropdown
                overlay={
                    <SideBarWrapper>
                        <div className="side-bar-extra" onClick={() => setSideMenu(false)} />
                        <div className="side-bar">
                            <div
                                className="profile-icon-wrapper"
                                onClick={() => (!isBank ? history.push(goToRoute(ROUTES.PROFILE)) : true)}
                            >
                                <UserAvatar type="topbar" />
                                <div className="user-name">
                                    {auth?.user?.firstName} {auth?.user?.lastName}
                                </div>
                                <ChevronRight className="right-icon" size="20" />
                            </div>
                            <SideBar
                                businessName={businessName}
                                collapsed={false}
                                hideProfileIcon
                                showNotification
                                notificationSeen={notificationSeen}
                                logout={logout}
                            />
                        </div>
                    </SideBarWrapper>
                }
                placement="bottomLeft"
                visible={sideMenu}
                trigger={['click']}
                onVisibleChange={(visible) => setSideMenu(visible)}
            >
                <img src={mobileMenu} alt="menu" className="mobile-menu" />
            </Dropdown>
            {!notificationSeen && <div className="red-circle" onClick={() => setSideMenu(true)} />}
        </MobileBarWrapper>
    );
};
export default MobileBar;
