const general_api_keys = [
    {
        key: '1',
        title: 'Date Created',
        dataIndex: 'createdDate',
    },
    {
        key: '2',
        title: 'Name',
        dataIndex: 'name',
    },
    {
        key: '3',
        title: 'Last Used',
        dataIndex: 'lastUsed',
    },
    {
        key: '4',
        title: 'Last Activity',
        dataIndex: 'lastActivity',
    },
];

const restricted_api_keys = [
    {
        key: '1',
        title: 'Date Created',
        dataIndex: 'createdDate',
    },
    {
        key: '2',
        title: 'Name',
        dataIndex: 'name',
    },
    {
        key: '3',
        title: 'Last Used',
        dataIndex: 'lastUsed',
    },
    {
        key: '4',
        title: 'Last Activity',
        dataIndex: 'lastActivity',
    },
];

const third_party_api_keys = [
    {
        key: '1',
        title: 'Date Created',
        dataIndex: 'createdDate',
    },
    {
        key: '2',
        title: 'Organization',
        dataIndex: 'organization',
    },
    {
        key: '3',
        title: 'Organization Type',
        dataIndex: 'organizationType',
    },
    {
        key: '4',
        title: 'Connection Type',
        dataIndex: 'connectionType',
    },
    {
        key: '5',
        title: 'Expiry Date',
        dataIndex: 'expiryDate',
    },
    {
        key: '6',
        title: 'Last Used',
        dataIndex: 'lastUsed',
    },
];

export { third_party_api_keys, general_api_keys, restricted_api_keys };
