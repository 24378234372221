import React, { FunctionComponent } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Skeleton from 'react-loading-skeleton';
import { appIcon } from '../../config/appIcon';
import getEnvTheme from '../../config/themes/getEnvTheme';
import { Constant } from '../../config';
import styled from 'styled-components';
import styles from './styles';

interface LoaderProps {
    logo?: boolean;
    height?: string;
    size?: any;
}

const Loader: FunctionComponent<LoaderProps> = ({ logo, height, size }: LoaderProps) => {
    const antIcon = (
        <LoadingOutlined style={{ fontSize: size || 50, color: getEnvTheme()?.PRIMARY ?? '#7C60FF' }} spin />
    );
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                height: height || '95vh',
            }}
        >
            {logo ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <img
                        style={{ width: Constant.loaderIconWidth, height: Constant.loaderIconHeight }}
                        src={appIcon}
                        alt="Logo"
                    />
                    <Spin style={{ marginTop: 20 }} indicator={antIcon} />
                </div>
            ) : (
                <Spin indicator={antIcon} />
            )}
        </div>
    );
};

const CardWrapper = styled.div`
    ${styles}
`;
interface CardLoaderProps {
    loading: boolean;
    className?: string;
    title?: string;
    rows?: number;
    fullRow?: boolean;
    noCard?: boolean;
}
export const CardLoader: FunctionComponent<CardLoaderProps> = ({
    loading,
    className,
    title,
    rows,
    fullRow,
}: CardLoaderProps) => {
    const mapArray = Array.from({ length: rows ?? 3 }, (_, i) => i);
    return loading ? (
        <CardWrapper>
            <Card
                className={'card-wrapper ' + className}
                title={title}
                bodyStyle={{ paddingTop: 0, display: 'flex', flexDirection: 'column', rowGap: '30px' }}
            >
                {mapArray.map((value) => (
                    <Row key={value} gutter={fullRow ? 0 : 20} style={{ width: '100%', rowGap: '50px' }}>
                        <Col span={fullRow ? 24 : 12}>
                            <Skeleton style={{ width: '100%', height: '43px' }} count={1} />
                        </Col>
                        {fullRow ? null : (
                            <Col span={12}>
                                <Skeleton style={{ width: '100%', height: '43px' }} count={1} />
                            </Col>
                        )}
                    </Row>
                ))}
            </Card>
        </CardWrapper>
    ) : null;
};

export default Loader;
