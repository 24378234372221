import { Card, Col, Form, FormInstance, Input, Row } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import PhoneNumber from '../../../../utils/FormSelects/PhoneNumber';
import SelectBankAndTelco from '../../../../utils/FormSelects/SelectBanksAndTelco';
import SelectWalletAccountType from '../../../../utils/FormSelects/WalletAccountTypes';
import { CardName } from '../../types';
import style from './styles';
const BusinessProfileWrapper = styled.div`
    ${style}
`;
interface PaymentsInformationProps {
    form: FormInstance;
    data: any;
    cardName?: string;
    onComplete: (next: boolean, cardName: CardName) => void;
    isAccountPresent?: boolean;
}

const PaymentsInformation: FunctionComponent<PaymentsInformationProps> = ({
    form,
    data,
    cardName,
    onComplete,
}: PaymentsInformationProps) => {
    const [group, setGroup] = useState<string>('bank');
    const [selectedWalletAccountType, setSelectedWalletAccountType] = useState<string>('PhoneNumber');
    const handleAccountTypeChange = (value) => {
        setSelectedWalletAccountType(value);
    };
    useEffect(() => {
        const wallet =
            (data?.bankWallets &&
                Array.isArray(data?.bankWallets) &&
                data?.bankWallets.length > 0 &&
                data?.bankWallets[0]) ||
            {};
        setGroup(wallet?.type);
        setSelectedWalletAccountType(wallet?.walletAccountType);
    }, [data]);
    useEffect(() => {
        const timeOut: any = setInterval(() => {
            if (form.isFieldsTouched(['wallet'], false)) {
                onComplete(false, CardName.PAYMENTS_INFORMATION);
            }
        }, parseInt(process.env.AUTO_SAVE_TIMEOUT || '30000'));
        return () => clearInterval(timeOut);
    }, []);
    return (
        <BusinessProfileWrapper>
            <Card
                title={cardName || 'Create Invoices, POs and Receive Payments'}
                className="card-wrapper card-margin-top"
            >
                <Row gutter={20}>
                    <Form.Item name={['wallet', 'type']} initialValue={'bank'} hidden />
                    <Form.Item name={['wallet', 'id']} hidden />
                    <Col span={12}>
                        <SelectBankAndTelco
                            formItemLabel="Select Bank / Telco"
                            formItemName={['wallet', group == 'bank' ? 'bankId' : 'mobileProviderId']}
                            required
                            onChange={(value, __, group) => {
                                setGroup(group);
                                form.setFieldsValue({
                                    wallet: {
                                        type: group,
                                        [group == 'bank' ? 'bankId' : 'mobileProviderId']: value,
                                    },
                                });
                            }}
                            message="Please select bank/telco type"
                        />
                    </Col>
                    {group == 'bank' ? (
                        <Col span={12}>
                            <Form.Item
                                label={'Account Number'}
                                name={['wallet', 'bankAccountNumber']}
                                className="label"
                                rules={[{ required: true, message: 'Please input account number!' }]}
                            >
                                <Input className="gapstack-input" placeholder="9049034" />
                            </Form.Item>
                        </Col>
                    ) : (
                        <Col span={12}>
                            <SelectWalletAccountType
                                formItemLabel="Select wallet type"
                                formItemName={['wallet', 'walletAccountType']}
                                required={true}
                                message="Please select an account type"
                                initialValue={selectedWalletAccountType}
                                onChange={handleAccountTypeChange}
                            />
                        </Col>
                    )}
                </Row>
                {group != 'bank' && (
                    <Row gutter={20}>
                        {selectedWalletAccountType === 'PhoneNumber' ? (
                            <Col span={12} style={{ marginBottom: '45px' }} className="disabled-phone-select">
                                <PhoneNumber disableCountryCode={true} inputFieldName={['wallet', 'phone']} />
                            </Col>
                        ) : selectedWalletAccountType === 'TillNumber' ? (
                            <Col span={12}>
                                <Form.Item
                                    label="Till Number"
                                    name={['wallet', 'mpesaTillNumber']}
                                    rules={[{ required: true, message: 'Please input the till number' }]}
                                >
                                    <Input className="gapstack-input" />
                                </Form.Item>
                            </Col>
                        ) : selectedWalletAccountType === 'PayBill' ? (
                            <Col span={12}>
                                <Form.Item
                                    label="Pay Bill No."
                                    name={['wallet', 'mpesaBusinessNo']}
                                    rules={[{ required: true, message: 'Please input the till number' }]}
                                >
                                    <Input className="gapstack-input" />
                                </Form.Item>
                            </Col>
                        ) : null}
                        {selectedWalletAccountType === 'PayBill' && (
                            <Col span={12}>
                                <Form.Item
                                    label="Account No."
                                    name={['wallet', 'mpesaAccountNo']}
                                    rules={[{ required: true, message: 'Please input the account number' }]}
                                >
                                    <Input className="gapstack-input" />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                )}{' '}
            </Card>
        </BusinessProfileWrapper>
    );
};

export default PaymentsInformation;
